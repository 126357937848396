import { Dayjs } from "dayjs";

export enum DatePickerMode {
  Date = 'DATE',
  Month = 'MONTH'
}

export interface WhenDate {
  type: DatePickerMode;
  date: Dayjs;
}
