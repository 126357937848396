import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseHeaderComponent } from '../../modules/shared/base-header/base-header.component';
import { State } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { InfoService, MetaTagsService, SsrService } from '../../services';
import { formLink, getLink } from '../../libraries';
import { SetCityTo } from '../../store/layer';
import { HttpParams } from '@angular/common/http';
import { CityNew, Country } from '../../interfaces';
import { StaticService } from "../../services/static.service";

const LIST_CITIES_KEY = 'list-cities';

@Component({
  selector: 'app-list-cities',
  templateUrl: 'list-cities.component.html',
  styleUrls: [
    'list-cities.component.scss',
    '../../styles/padding-margin-0.scss',
  ]
})
export class ListCitiesComponent extends BaseHeaderComponent implements OnInit {
  public countries: Country[];
  public popularCities: CityNew[];

  constructor(
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    private infoService: InfoService,
    private metaTagsService: MetaTagsService,
    protected ssrService: SsrService,
    public staticService: StaticService,
  ) {
    super(store$, cdRef, ssrService);
    this.store$.dispatch(new SetCityTo(null));
  }

  public ngOnInit() {
    const storedData = this.ssrService.getState(LIST_CITIES_KEY);
    if (storedData) {
      this.countries = storedData.countries;
      this.popularCities = storedData.popularCities;
      this.addCountryAndCitiesUrl();
    } else {
      const httpParams = (new HttpParams())
        .set('expand', 'cities')
        .set('popular-cities', 'true');
      this.infoService.getCountryList(httpParams).subscribe(result => {
        this.countries = result.items;
        this.popularCities = result._extra.popularCities ? result._extra.popularCities : [];
        this.ssrService.setState(LIST_CITIES_KEY, {countries: this.countries, popularCities: this.popularCities});
        this.addCountryAndCitiesUrl();
      });
    }

    this.metaTagsService.resolver({
      route: 'list-cities',
      name: 'List of cities',
      description: 'Complete list of cities from Explorow, 1000s of great travel ideas. Look through all the activities you can do in each destination'
    });
  }

  private addCountryAndCitiesUrl(): void {
    if (this.countries) {
      this.countries.forEach(country => {
        const countryNameEncoded = getLink(country, country.name.toLowerCase().replace(/ /g, '_'));
        country['url'] = formLink('country', [countryNameEncoded, country.id && country.id.toLowerCase()]);
        country.cities.forEach(city => {
          const cityNameEncoded = getLink(city, city.name.toLowerCase().replace(/ /g, '_'));
          city['url'] = formLink('city', [cityNameEncoded, city.id]);
        });
      });
    }
  }
}
