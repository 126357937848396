import { Component, Input, OnInit } from '@angular/core';
import { ContactInfo } from "../../../interfaces/contact-info";
import { constructBaseUrl } from "../../../libraries";

@Component({
  selector: 'app-contact-info-display',
  templateUrl: './contact-info-display.component.html',
  styleUrls: ['./contact-info-display.component.scss']
})
export class ContactInfoDisplayComponent implements OnInit {
  @Input() contactInfo: ContactInfo;

  isBusinessHoursExists: boolean;

  ngOnInit() {
    this.isBusinessHoursExists = Array.isArray(this.contactInfo?.businessHours) && this.contactInfo?.businessHours?.some(a => a.length);
  }

  isContactInfoFilled(): boolean {
    return !!this.contactInfo?.webSite
      || !!this.contactInfo?.address
      || this.isBusinessHoursExists
      || !!this.contactInfo?.phone
      || !!this.contactInfo?.email
      || !!this.contactInfo?.whatsapp;
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
