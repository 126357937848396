import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthModalService {
  private open$ = new BehaviorSubject<boolean>(false);
  public isLoggedUser$ = new Subject<boolean>();
  public showWinWelcome$ = new Subject<boolean>();

  get isOpen(): Observable<boolean> {
    return this.open$.asObservable();
  }

  constructor() {
  }

  public open(): void {
    this.open$.next(true);
  }

  public close(): void {
    this.open$.next(false);
  }
}
