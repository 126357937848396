<div class='search-fields reset-styles'
     #elementToBind
     appChangeBorderStyle
     [change]="opened">
  <div class="icon-to" *ngIf="!isTablet"><img [appBasesrc]="'/assets/search/to.svg'" alt=""></div>
  <input
    name="destForm"
    #inputField
    (blur)="blurInput()"
    (click)="openPanel(inputField)"
    autocomplete="off"
    [formControl]="destFormControl"
    [placeholder]="destFormPlaceholder"
    i18n="labels.where_to"
  >
</div>
