import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { clearObservables, consoleDebug, getWindow, showMatch, waitObservable, } from '../../../libraries';
import { CountryInterface, CountryService, SearchService, SsrService } from '../../../services';
import { select, Store } from '@ngrx/store';
import { getCityFrom, State } from '../../../store/reducers';
import { Subject } from 'rxjs/internal/Subject';
import { StaticService } from "../../../services/static.service";
import { NgScrollbar } from "ngx-scrollbar";

@Component({
  selector: 'app-dest-search-field',
  templateUrl: 'dest-search-field.component.html',
  styleUrls: [
    '../../../styles/mat-styles.scss',
    'dest-search-field.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DestSearchFieldComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() cityFrom: any = null;
  @Input() destPlaceConfig;
  @Input() destPlaceName: string = null;
  @Input() destTo: any;
  @Input() label;
  @Input() poi: string;
  @Input() showOnMapFirst: boolean = null;

  @Output() destChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output() focusEmitter = new EventEmitter();

  @ViewChild('autocomplete') autocomplete;
  @ViewChild('elementToBind', {static: true}) elementToBind;
  @ViewChild('inputField', {static: true}) inputField: ElementRef;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  public clearTimerObservable: any[] = [];
  public coordinates = {
    DF: {top: '0px', left: '0px'},
    AC: {top: '0px', left: '0px'},
    DW: {top: '0px', left: '0px'}
  };
  public countries: CountryInterface[];
  public destFormControl = new UntypedFormControl();
  public destinationInputFocused: boolean = false;
  public destList: any;
  public disabled: boolean = true;
  public displayedCity: boolean = false;
  public errorText = '';
  public focused;
  public inputSubscription$: Subscription;
  public left: number = 0;
  public opened: boolean = false;
  public selectedCityId: any = null;
  public selectedItemIndex = -1;
  public showOnMapPlace = null;
  public top: number = 0;
  public window;
  public isTablet: boolean;

  protected destFormPlaceholder: string;

  private $destroyed = new Subject<void>();

  @HostListener('window:resize')
  onResize() {
    this.onRenderDestFeatures();
    this.isTablet = this.staticService.isTabletDesign();
  }

  @HostListener('window:scroll')
  onScroll() {
    this.onRenderDestFeatures();
  }

  constructor(
    private searchService: SearchService,
    private countryService: CountryService,
    private store$: Store<State>,
    private cdRef: ChangeDetectorRef,
    protected ssrService: SsrService,
    protected staticService: StaticService,
  ) {
    this.isTablet = ssrService.isTablet() || staticService.isTabletDesign();
    this.countries = this.countryService.getAllCountries();
    this.store$.pipe(
      select(getCityFrom),
      takeUntil(this.$destroyed)
    ).subscribe((city) => {
      this.cityFrom = city;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.destTo) {
      if (!!changes.destTo.currentValue) {
        this.setValue(changes.destTo.currentValue && changes.destTo.currentValue.name);
        this.displayedCity = true;
      } else {
        this.setValue('');
        this.displayedCity = false;
      }
    }
  }

  ngAfterViewInit() {
    this.clearTimerObservable.push(
      waitObservable(
        () => this.elementToBind,
        () => this.onRenderDestFeatures(),
        100
      )
    );
    this.inputSubscription$ = this.destFormControl.valueChanges.pipe(
      debounceTime(500),
      filter((newValue: string) => (!!newValue.trim() && newValue.length >= 3))
    ).subscribe((newValue: string) => {
      this.destInputValueChanges(newValue);
    });
    this.destFormPlaceholder = this.staticService.isMobileDesign() ? 'Where to?' : 'Search';
  }

  public onRenderDestFeatures() {
    if (!getWindow()) {
      return;
    }
    if (!this.elementToBind) {
      return;
    }

    const dTop = 44;
    const rect = this.elementToBind.nativeElement.getBoundingClientRect();

    this.coordinates.DF.top = `${rect.top + dTop}px`;
    this.coordinates.DF.left = `${rect.x}px`;
    this.coordinates.AC.top = `${rect.top + dTop}px`;
    this.coordinates.AC.left = `${rect.x}px`;
    this.coordinates.DW.top = `${rect.top + dTop}px`;
    this.coordinates.DW.left = `${rect.x}px`;
  }

  public setValue(newValue: string): void {
    newValue = newValue ? newValue.trim() : '';
    this.destFormControl.setValue(newValue, {emitEvent: false});
  }

  public selectDest(dest: any, inputField?): void {
    if (inputField) {
      inputField.value = dest && dest.place ? dest.place.name : (dest instanceof String) ? dest.trim() : '';
    }
    this.opened = false;
    this.selectedItemIndex = -1;
    if (this.cdRef && !this.cdRef['destroyed']) {
      this.cdRef.detectChanges();
    }
    if (!dest) {
      return;
    }
    if (dest instanceof String) {
      dest = dest.trim();
    } else if (dest?.value) {
      dest.value = dest.value.trim();
    }
    if (dest && (dest.place || dest.value)) {
      this.destChanges.emit(dest.value || dest);
    }
  }

  public focus(inputFieldRef): void {
    this.focusInput();
    inputFieldRef.focus();
  }

  public focusInput(): void {
    this.focused = true;
    this.open();
    this.focusEmitter.emit();
  }

  public openPanel(inputFieldRef): void {
    this.open();
    this.focused = true;
    this.focusEmitter.emit();
    inputFieldRef.focus();
    if (
      this.destFormControl &&
      this.destFormControl.value &&
      this.ssrService.isBrowser() &&
      localStorage.getItem('destArray')
    ) {
      const items = localStorage.getItem('destArray');
      this['destList'] = JSON.parse(items);
      this.searchDest(this.destFormControl.value, 'destList');
    }
  }

  public blurInput(): void {
    this.focused = false;
  }

  public destInputValueChanges(value: string): void {
    if (!value) {
      this.destChanges.emit(null);
    }
    this.inputValueChanges(value, 'destList');
  }

  public searchDest(q: string, optionReference: string): void {
    if (!!this.cityFrom) {
      const httpParams = new HttpParams()
        .append(
          'filter[cityFrom]',
          this.cityFrom.id || this.cityFrom.selectedCityId
        )
        .append('filter[query]', q)
        .append('expand', 'userPictures,pictures');

      this.searchService.searchDest(httpParams).subscribe(
        success => {
          const options: any = showMatch(success, q);
          if (!!options && !!options.error) {
            this.errorText = options.innerHTML;
            this.destList = [];
          } else {
            this[optionReference] = options;

            if (this.ssrService.isBrowser()) {
              localStorage.setItem('destArray', JSON.stringify(this.destList));
            }
            this.errorText = null;
          }
          this.filterDest(this[optionReference]);
        },
        error => {
          consoleDebug('error dest-search-field => 325', error);
        }
      );
    }
  }

  public filterDest(success): void {
    if (this.showOnMapPlace) {
      const m = success.find(c => c.place.id == this.showOnMapPlace.id);
      if (m) {
        this.selectDest(m, this.inputField);
      }

      this.showOnMapPlace = null;
      return m;
    }
  }

  public inputValueChanges(val, optionReference): void {
    if (val && val.length >= 3) {
      this.filterDestByType(val, optionReference);
    } else {
      this.destList = null;
    }
  }

  public filterDestByType(val: string, optionReference: string): void {
    this.searchDest(val, optionReference);
  }

  public clear() {
    this.destList = null;
    this.destinationInputFocused = false;
    this.destFormControl.setValue('');
    this.inputField.nativeElement.focus();
  }

  public open(): void {
    this.opened = true;
    this.destinationInputFocused = true;
    this.selectedItemIndex = -1;
    this.onRenderDestFeatures();
  }

  ngOnDestroy() {
    clearObservables(this.clearTimerObservable);
    this.inputSubscription$.unsubscribe();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public onKeydown(event): any {
    if (!this.scrollbarRef?.viewport || !this.destList) {
      return;
    }
    const previousIndex = this.selectedItemIndex;
    const scrollElement = this.scrollbarRef.viewport.nativeElement;
    const scrollHeight = scrollElement.scrollHeight / this.destList.length;

    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        this.selectedItemIndex = ((this.selectedItemIndex - 1) < 0 ? (this.destList.length - 1) : (this.selectedItemIndex - 1));
        break;
      case 40: // this is the ascii of arrow down
        this.selectedItemIndex = ((this.selectedItemIndex + 1) > (this.destList.length - 1) ? 0 : (this.selectedItemIndex + 1));
        break;
      case 13: // this is the ascii of enter
        return this.selectDest(this.destList[this.selectedItemIndex], this.inputField);
    }

    if (this.selectedItemIndex >= 1) {
      if (previousIndex > this.selectedItemIndex) {
        if (scrollHeight * this.selectedItemIndex < scrollElement.scrollTop) {
          this.scrollbarRef.scrollTo({top: scrollElement.scrollTop - scrollHeight});
        }
      } else if (previousIndex < this.selectedItemIndex && this.selectedItemIndex >= 5) {
        if (this.selectedItemIndex === this.destList.length - 1) {
          this.scrollbarRef.scrollTo({top: this.selectedItemIndex * scrollHeight});
        } else if (this.selectedItemIndex * scrollHeight + scrollHeight > scrollElement.scrollTop + scrollElement.clientHeight) {
          this.scrollbarRef.scrollTo({top: scrollElement.scrollTop + scrollHeight});
        }
      }
    } else if (this.selectedItemIndex === 0) {
      this.scrollbarRef.scrollTo({top: 0});
    }
  }
}
