<div class="year-selectors">
  <div (click)="updateYear(-1)" class="arrow prev"></div>
  <div class="year">{{year}}</div>
  <div (click)="updateYear(1)" class="arrow next"></div>
</div>
<div class="months">
  <div class="month-r"
       *ngFor="let chunk of months">
    <div class="month"
         [ngClass]="{'isBefore':month.isBefore, 'selected':month.selected}"
         *ngFor="let month of chunk" (click)="selectMonth(month)">
      {{month.title}}
    </div>
  </div>
</div>
