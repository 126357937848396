import { Pipe, PipeTransform } from '@angular/core';
import { JourneyDate } from "../interfaces/journey";
import dayjs from 'dayjs';

@Pipe({
  name: 'minMaxDate'
})
export class MinMaxDatePipe implements PipeTransform {

  transform(value: JourneyDate): unknown {
    if (value) {
      const min = dayjs(value.min).format("D MMM YYYY");
      if (value.min === value.max) {
        return min;
      }
      let max = dayjs(value.max).format("D MMM YYYY");

      if(dayjs(value.min).format("YYYY") === dayjs(value.max).format("YYYY")) {
        max = dayjs(value.max).format("D MMM");
      }

      return `${max} - ${min}`;
    }
    return null;
  }

}
