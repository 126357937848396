import { defineGoogle } from './define-google';
import { normalizeNWPoint } from './get-normalized-nw-point';
import { latLngToPoint } from './lat-lng-to-point';

export let normalizePoints = (map, places: any, noWrap = false) => {
  const google: any = defineGoogle();
  const indexedNormalizedPoints = {};

  for (let i = 0; i < places.length; i++) {
    const coordinates = latLngToPoint(map, new google.maps.LatLng(
      places[i].latitude || places[i].lat,
      ((places[i].longitude || places[i].lng) + (noWrap ? 360 : 0)),
      noWrap
    ), google);
    indexedNormalizedPoints[places[i].id] = {
      p: places[i],
      c: coordinates,
      cnw: normalizeNWPoint(coordinates)
    };
  }

  return indexedNormalizedPoints;
};
