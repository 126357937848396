import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { getLinkByObject } from '../../../../../libraries/get-link-by-object';

@Component({
  selector: 'app-static-top-places',
  templateUrl: './static-top-places.component.html',
  styleUrls: ['./static-top-places.component.scss']
})
export class StaticTopPlacesComponent implements OnChanges {
  @Input() data: Array<any>;
  @Input() resizeWindow: string | number;

  @Output() changeVisibleTooltipPlaceEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pin = new EventEmitter<any>();

  public renderData: Array<any> = [];
  public readonly REGULAR_BLOCK = 99;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      changes['data'].currentValue.forEach(item => {
        this.renderData.push(item);
      });
    }
  }

  public getUrl(place): string {
    return getLinkByObject(place.object || {
      ...place,
      otype: place.type,
      country: place.countryId ? {id: place.countryId} : null
    });
  }

  public changeVisibleTooltipPlace(event: any): void {
    this.changeVisibleTooltipPlaceEvent.emit(event);
  }

  public pinPlace(item): void {
    this.pin.emit(item);
  }
}
