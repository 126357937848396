import { UserCitiesActionsType, UserCitiesActionsUnion } from './user-cities.actions';
import isArray from 'lodash/isArray';
import { PinInterface } from "../../interfaces";

export interface State {
  pinned: PinInterface[];
}

const initialState: State = {
  pinned: [],
};

export function reducer(state: State = initialState, action: UserCitiesActionsUnion): State {
  switch (action.type) {
    case UserCitiesActionsType.UpdatePinDate:
      // if place is already in pinned list, just update date, else add it as new
      // because when pin date is saving and place was not pinned, it is added to pinned list automatically
      const dataFromStorage = state.pinned.find((item) => item.oid === action.payload.oid && item.otype === action.payload.otype);
      if (dataFromStorage) {
        return {
          ...state,
          pinned: state.pinned.reduce((result: PinInterface[], item) => {
            if (item.oid !== action.payload.oid || item.otype !== action.payload.otype) {
              result.push(item);
            } else {
              result.push({
                ...item,
                dateFrom: action.payload.dateFrom,
                dateTo: action.payload.dateTo,
                dateType: action.payload.dateType,
                _pinTypes: [action.payload.type],
              });
            }
            return result;
          }, [])
        };
      } else {
        const data = {
          dateFrom: action.payload.dateFrom,
          dateTo: action.payload.dateTo,
          dateType: action.payload.dateType,
          creationDate: new Date().toISOString(),
          createDate: new Date().toISOString(),
          otype: action.payload.otype,
          oid: action.payload.oid,
          _pinTypes: [action.payload.type],
          _sync: true
        };
        if (action.payload.city) {
          data['city'] = action.payload.city;
        }
        if (action.payload.place) {
          data['place'] = action.payload.place;
        }
        if (action.payload.countries) {
          data['countries'] = action.payload.countries;
        }
        if (action.payload.restaurant) {
          data['restaurant'] = action.payload.restaurant;
        }
        return {
          ...state,
          pinned: [
            ...state.pinned,
            data
          ]
        };
      }
    case UserCitiesActionsType.SetCityState:
      return {
        ...state,
        pinned: action.payload && action.payload.cities && action.payload.cities.map((item) => ({...item, _sync: true}))
      };
    case UserCitiesActionsType.MarkSyncCity:
      const targetCityToMark = state.pinned.find((item) => item.oid === action.payload.oid && item.otype === action.payload.otype);
      if (!targetCityToMark) {
        return state;
      }

      const clonedObj = {
        ...targetCityToMark,
        _sync: true,
      };

      return {
        ...state,
        pinned: [
          ...state.pinned.filter((item) => item.oid !== targetCityToMark.oid || item.otype !== targetCityToMark.otype),
          clonedObj
        ]
      };
    case UserCitiesActionsType.ResetState:
      return initialState;
    case UserCitiesActionsType.RestoreCities:
      return {
        ...state,
        pinned: action.payload
      };
    case UserCitiesActionsType.AddCity:
      const payload = !isArray(action.payload) ? [action.payload] : action.payload;
      return payload.reduce((state_, payload_) => {
        const targetCityToAdd = state_.pinned.find((item) => item.oid === payload_.oid && item.otype === payload_.otype);
        if (targetCityToAdd) {
          const clonedObj = {
            ...targetCityToAdd,
            collectionId: payload_.collectionId,
            dateFrom: payload_.dateFrom,
            dateTo: payload_.dateTo,
            dateType: payload_.dateType,
            _sync: targetCityToAdd._sync || payload_._sync
          };
          clonedObj._pinTypes = [
            targetCityToAdd._pinTypes ?
              targetCityToAdd._pinTypes.map(v => v).filter(v => v !== payload_.type) :
              (!targetCityToAdd._pinTypes && targetCityToAdd.type) ?
                targetCityToAdd.type : null,
            payload_.type
          ];
          return {
            ...state_,
            pinned: [
              ...state_.pinned.filter((city) => city.oid !== targetCityToAdd.oid || city.otype !== targetCityToAdd.otype),
              clonedObj
            ],
          };
        } else {
          const data = {
            dateFrom: payload_.dateFrom,
            dateTo: payload_.dateTo,
            dateType: payload_.dateType,
            otype: payload_.otype,
            oid: payload_.oid,
            _pinTypes: [payload_.type],
            _sync: payload_._sync
          };
          if (payload_.city) {
            data['city'] = payload_.city;
          }
          if (payload_.place) {
            data['place'] = payload_.place;
          }
          if (payload_.countries) {
            data['countries'] = payload_.countries;
          }
          if (payload_.restaurant) {
            data['restaurant'] = payload_.restaurant;
          }
          return {
            ...state_,
            pinned: [...state_.pinned, data],
          };
        }
      }, {...state});
    case UserCitiesActionsType.RemoveCity:
      const removedCity = action.payload;
      return {
        ...state,
        pinned: state.pinned.filter(item => item.oid !== removedCity.oid || item.otype !== removedCity.otype)
      };
    case UserCitiesActionsType.RemovePinsFromCollection:
      const removedCollection = action.payload;
      let removedPins = [];
      if (removedCollection.cities.length) {
        removedPins = removedPins.concat(removedCollection.cities);
      }
      if (removedCollection.places.length) {
        removedPins = removedPins.concat(removedCollection.places);
      }
      if (removedCollection.countries.length) {
        removedPins = removedPins.concat(removedCollection.countries);
      }
      let newPins = state.pinned;
      removedPins.forEach(removedPin => {
        newPins = newPins.filter(pin => pin.oid !== removedPin.oid);
      });

      return {
        ...state,
        pinned: newPins
      };
    default:
      return state;
  }
}

export const _getPinned = (state: State) => state.pinned;
