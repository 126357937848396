<div class="container" [class.map-page]="mapPage" *ngIf="!isLoadForm">
  <div class="add-place__container"
       id="mainDiv">
    <ng-container *ngIf="!externalPlace">
      <h1 class="add-place__title">Review a Place</h1>
      <p class="add-place__subtitle">Please, fill the form below. All fields marked with asterisks are required</p>
    </ng-container>

    <form [formGroup]="form" class="add-place__form">
      <div #placeContainer class="add-place__form-item" *ngIf="!externalPlace">
        <h3>1. What Place would you like to review? <span class="symb">&#42;</span></h3>
        <label>Type a name of the place in the search box, then press enter</label>
        <mat-form-field subscriptSizing="dynamic" class="m-b-16">
          <input type="text"
                 matInput
                 autocomplete="off"
                 [value]="selectPlace && selectPlace.name"
                 [matAutocomplete]="placeAuto"
                 (input)="this.query.setValue($event)"
                 placeholder="Place">
          <mat-autocomplete #placeAuto="matAutocomplete"
                            [displayWith]="displayFn"
                            (optionSelected)="getSelectPlace($event)">
            <mat-option *ngFor="let list of destList" [value]="list" [innerHTML]="list.innerHTML"></mat-option>

            <mat-option *ngIf="placeErrors.place_id.message" class="p-0">
              <div class="option not-found">
                <img class="not-found__icon" src="/assets/info/warning-5-24.png" alt="">
                <div class="not-found__info">
                  Sorry, we couldn't find "{{placeErrors.place_id.message}}"
                </div>
              </div>
            </mat-option>

          </mat-autocomplete>
        </mat-form-field>

        <div class="preview" *ngIf="selectPlace">
          <ul class="file-list">
            <li>
              <div class="image-preview">
                <span>
                  <img class="preview-img cursor-default"
                       [src]="constructMediaURL( selectPlace?.pictures && selectPlace.pictures[0]?.thumb)"
                       alt="preview image of {{selectPlace?.name}}">
                </span>

                <div class="name-container">
                  <div class="place-name">{{selectPlace?.name}}</div>
                  <div
                    class="country-name">{{selectedLocationName}}</div>
                </div>
              </div>

              <span class="delete-file" (click)="removeSelectedPlace()">
                <img alt="close" class="close-popup-img" title="close" src="/assets/cross.svg">
              </span>
            </li>
          </ul>
        </div>
        <ng-container *ngTemplateOutlet="placeErrors.place.message && errorContainer;
          context: {text: placeErrors.place.message}">
        </ng-container>
      </div>

      <div #textContainer class="add-place__form-item">
        <ng-container *ngIf="!externalPlace; then defaultTitle; else externalTitle"></ng-container>
        <ng-template #defaultTitle>
          <h3>2. Your Review <span class="symb">&#42;</span></h3>
        </ng-template>
        <ng-template #externalTitle>
          <h3>Review {{externalPlace.name}}</h3>
        </ng-template>
        <label>The maximum text length is {{maxTextLength | number}} characters</label>
        <editor formControlName="text" required
                (onInit)="handleDescEditorInit($event)"
                [id]="'wysiwyg-desc'"
                [init]="{
           height: 400,
           menubar: false,
           plugins: 'lists wordcount link autolink',
           block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
           base_url: '/tinymce',
           suffix: '.min',
           paste_as_text: true,
           entity_encoding: 'raw',
           toolbar: 'undo redo | blocks | bold italic | bullist numlist | removeformat | link unlink',
           link_target_list: false,
           link_title: false,
           contextmenu: 'linkchecker image editimage table spellchecker configurepermanentpen'
         }"
        ></editor>
        <ng-container *ngTemplateOutlet="placeErrors.text.message && errorContainer;
          context: {text: placeErrors.text.message}">
        </ng-container>
      </div>

      <div #mediaContainer class="add-place__form-item">
        <h3><span *ngIf="!externalPlace"> 3.</span> Media <i>(optional)</i></h3>
        <label>
          {{'Attach media of this place. Minimum one media required. ' +
        'The limit of images is ' + maxImages + ' maximum. ' +
        'The limit of videos is ' + maxVideos + ' maximum'}}
        </label>

        <div class="add-place__form-item">
          <div #photoContainer>
            <app-upload-form [length]="maxImages"
                             [accept]="photoAccept"
                             [uploadFiles]="uploadPhotoFiles"
                             [mobileView]="true"
                             [multiple]="true"
                             [addToProgress]="true"
                             [isShowProgress]="true"
                             [type]="typeMedia.photo"
                             (errorMessageEmitter)="setErrorMessageEmitter($event, 'photo')"
                             (deleteFileEmitter)="deleteFile($event, typeMedia.photo)">
            </app-upload-form>
            <ng-container *ngTemplateOutlet="placeErrors.photo.message && errorContainer;
          context: {text: placeErrors.photo.message, withBackground: true}">
            </ng-container>
          </div>

          <div #videoContainer>
            <app-upload-form [length]="maxVideos"
                             [accept]="videoAccept"
                             [uploadFiles]="uploadVideoFiles"
                             [mobileView]="true"
                             [multiple]="false"
                             [addToProgress]="true"
                             [type]="typeMedia.video"
                             (errorMessageEmitter)="setErrorMessageEmitter($event, 'video')"
                             (deleteFileEmitter)="deleteFile($event, typeMedia.video)">
            </app-upload-form>
            <ng-container *ngTemplateOutlet="placeErrors.video.message && errorContainer;
          context: {text: placeErrors.video.message, withBackground: true}">
            </ng-container>
          </div>

        </div>
      </div>

      <div #policyContainer class="add-place__form-item">
        <label class="add-place__privacy-policy">
          <input (change)="checkValidationByName('checkboxCreate')"
                 class="input-checkbox"
                 type="checkbox"
                 formControlName="checkboxCreate"
                 required/>
          <span>By clicking Submit Review, you agree to our</span>
          <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>
          <span>and</span>
          <a [routerLink]="['/terms-of-use']" class="margin-none" target="_blank">Terms and Conditions</a>
          <span>.</span>
          <br/>
          <span>Please note that it may take some time to be uploaded and fully approved</span>
        </label>

        <ng-container *ngTemplateOutlet="placeErrors.checkboxCreate.message && errorContainer;
         context: {text: placeErrors.checkboxCreate.message}">
        </ng-container>
      </div>

      <div #publishContainer class="add-place__form-item" *ngIf="!isAccountPublic">
        <label class="add-place__privacy-policy">
          <input (change)="checkValidationByName('checkboxPublish')"
                 class="input-checkbox"
                 type="checkbox"
                 formControlName="checkboxPublish"
                 required/>
          <span>Make my Personal Page public.</span>
          <span>Turning this 'on' means your
            <a [routerLink]="[getLinkByObject(userInfo)]"
               style="font-weight: bold;"
               target="_blank">Personal Page</a>
            will be visible to Explorow website visitors.</span>
        </label>

        <ng-container *ngTemplateOutlet="placeErrors.checkboxPublish.message && errorContainer;
         context: {text: placeErrors.checkboxPublish.message}">
        </ng-container>
      </div>

      <div class="add-place__form-item submit">
        <button class="btn btn-default"
                [disabled]="isSubmit"
                (click)="onSubmit()">
          Submit Review
        </button>
        <button
          class="btn btn-default discard"
          (click)="clickDiscard()">
          discard
        </button>
      </div>
    </form>
  </div>

  <div class="popup-toastr"
       *ngIf="isShowToastr" [ngClass]="{'alert-error': errorSubmit, 'alert-info': !errorSubmit}">
    <span *ngFor="let err of alertMessage; let i = index">{{ i + 1 }}. {{ err }}</span>
  </div>
</div>

<ng-template #errorContainer let-text="text" let-withBackground="withBackground">
  <div class="alert alert-danger" [class.withBackground]="withBackground">{{text}}</div>
</ng-template>

<app-fly-modal-notice *ngIf="isModalNoticeData?.isShow"
                      [title]="isModalNoticeData?.title"
                      [text]="isModalNoticeData?.text"
                      (closeEmit)="goToProfileReviews()"></app-fly-modal-notice>
