import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { HttpParams } from "@angular/common/http";
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from "../../../../store/layer";
import { take } from "rxjs/operators";
import { parseCoords } from "../../../../libraries/map";
import { InfoService, MapService, RouterHistoryService, SsrService, WindowRef } from "../../../../services";
import { PageService } from "../../../../services/page.service";
import { of } from 'rxjs/internal/observable/of';
import { StaticService } from "../../../../services/static.service";
import { ActivatedRoute } from "@angular/router";
import { SeeDoMapClass } from "../../../../abstracts/right-side-map-class";
import { PageScrollService } from "ngx-page-scroll-core";
import { getSearchData, State } from "../../../../store/reducers";
import { DOCUMENT } from "@angular/common";
import { HelperService } from "../../../../services/helper.service";

const HOME_PAGE_DATA = 'data-home-page';

@UntilDestroy()
@Component({
  selector: 'app-static-home',
  templateUrl: './static-home.component.html',
  styleUrls: ['./static-home.component.scss'],
})
export class StaticHomeComponent extends SeeDoMapClass implements OnInit, AfterViewInit {
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  @Output() pin = new EventEmitter<any>();

  public data: any;
  protected showSignupConfirmWin: boolean = false;

  constructor(
    public staticService: StaticService,
    protected store$: Store<State>,
    protected activatedRoute: ActivatedRoute,
    protected infoService: InfoService,
    protected mapService: MapService,
    protected cdRef: ChangeDetectorRef,
    protected pageScrollService: PageScrollService,
    protected windowRef: WindowRef,
    private ssrService: SsrService,
    @Inject(DOCUMENT) protected document: any,
    private pageService: PageService,
    protected helperService: HelperService,
    // do not remove! is used for previousUrl$ logic in FlyAddButtonComponent
    private readonly routerHistoryService: RouterHistoryService,
  ) {
    super(
      infoService,
      mapService,
      cdRef,
      store$,
      pageScrollService,
      document,
      windowRef,
      staticService,
      helperService,
    );
  }

  ngOnInit(): void {
    this.staticService.setStaticType(this.activatedRoute.snapshot.data['static']);
    this.getData();
  }

  ngAfterViewInit(): void {
    if (this.tryToGetDataFromState('signupConfirmed') === true) {
      history.pushState('signupConfirmed', null);
      this.showSignupConfirmWin = true;
    }
  }

  private tryToGetDataFromState(stateParam: string) {
    if (this.ssrService.isBrowser() && history.state?.[stateParam]) {
      return history.state?.[stateParam];
    }

    return null;
  }

  // INIT StaticRouteType.HOME_PAGE:
  private getData(): void {
    this.staticService.resetListParams();
    if (!this.staticService.searchData) {
      this.store$.pipe(
        untilDestroyed(this),
        select(getSearchData)
      ).subscribe(searchData => this.staticService.searchData = searchData);
    }
    const {cityFrom, passengers, tripType, date} = this.staticService.searchData;
    let storedData = this.ssrService.getState(HOME_PAGE_DATA);
    let subscription;
    if (!storedData && cityFrom) {
      const httpParams = new HttpParams({
        fromObject: {
          'expand': 'pictures,iataCodeFrom,tags,user,city.flights,city.country,city.hasPage,pinned,country,date,' +
            'user.urlSetting,video',
          'filter[cityFrom]': cityFrom.id.toString(),
          'filter[paxCount]': passengers.toString(),
          'filter[flightType]': transformTripTypeToApi(tripType),
          'filter[dateType]': transformDateToApiDateType(date).toString(),
          'filter[when]': transformWhenDateToApiDateType(date),
          'filter[return]': transformReturnToApiDateType(date) || ''
        }
      });
      subscription = this.pageService.getHomePageData(httpParams).pipe(take(1));
    } else {
      subscription = of(storedData);
      this.ssrService.removeState(HOME_PAGE_DATA);
    }

    this.staticService.setSeoDataSubPages();

    subscription.subscribe((data) => {
      this.ssrService.setState(HOME_PAGE_DATA, data);
      if (data) {
        const place = this.getCurrentPlace();
        if (place) {
          this.staticService.setCurrentPlace(place);
          this.staticService.showPlace = place;
        }
        this.data = data;
      }
    });
  }

  public pinPlace(item): void {
    this.pin.emit(item);
    this.staticService.toggleBottomSheet(true, item.pinned);
  }

  private getCurrentPlace(): any {
    let place = null;
    if (this.staticService.homeData?.coordinates) {
      const coordinates = parseCoords(this.staticService.homeData.coordinates);
      place = {...this.staticService.homeData, lat: coordinates.lat, lng: coordinates.lng};
    } else if (this.staticService.cityFrom) {
      place = {
        ...this.staticService.cityFrom,
        lat: this.staticService.cityFrom.lat,
        lng: this.staticService.cityFrom.lng
      };
    }
    if (place && !this.staticService.isMobileDesign()) {
      // by default, we set center to global map center
      place.lat = 33.918534;
      place.lng = 8.122933;
      this.staticService.zoom = 2;
    }

    return place;
  }
}
