import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';

if (environment.sentryUrl) {
  Sentry.init({
    dsn: environment.sentryUrl,
    normalizeDepth: 10,
    environment: environment.sentryEnvironment || (environment.production ? 'production' : 'staging'),
    release: environment.version,
    beforeBreadcrumb(breadcrumb) {
      //fix of 413 error: https://github.com/getsentry/sentry-javascript/issues/2798
      if (breadcrumb.category === 'console') {
        return null;
      }
      return breadcrumb;
    },
  });
}

//TODO: check if it works without document.readyState === 'complete', maybe there were some problems with state
platformBrowserDynamic().bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));

// function bootstrap() {
//   platformBrowserDynamic().bootstrapModule(AppBrowserModule)
//     .catch(err => console.error(err));
// }
//
// if (document.readyState === 'complete') {
//   bootstrap();
// } else {
//   document.addEventListener('DOMContentLoaded', bootstrap);
// }
