import { Component } from '@angular/core';
import { FlyCookieService, SsrService } from '../../services';

@Component({
  selector: 'app-cookies-law-policy',
  templateUrl: 'cookies-law-policy.component.html',
  styleUrls: ['cookies-law-policy.component.scss'],
})
export class CookiesLawPolicyComponent {
  public cookiesPolicyAccepted: boolean = true;

  constructor(
    private cookieService: FlyCookieService,
    ssrService: SsrService,
  ) {
    if (ssrService.isBrowser() && this.cookieService.get('cookieLawSeen') !== 'true') {
      this.cookiesPolicyAccepted = false;
    }
  }

  protected acceptCookiePolicy(): void {
    this.cookieService.set('cookieLawSeen', 'true', 30, '/');
    this.cookiesPolicyAccepted = true;
  }
}
