import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import { UploadProgressInterface } from "../../../interfaces/upload-progress";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public uploadPopupData$ = new BehaviorSubject<[UploadProgressInterface[]]>([[]]);
  public successUploadPopupData$ = new BehaviorSubject<boolean[]>([]);

  constructor(private http: HttpService) {
  }

  public post(data) {
    return this.http.post(`copyright`, data);
  }

  public upload(files, reportProgress = false) {
    const formData = new FormData();
    for (const file in files) {
      formData.append('file', files[file]);
    }
    if (reportProgress) {
      return this.http.uploadPostWithProgress(environment.uploadUrl, formData);
    }

    return this.http.uploadPost(environment.uploadUrl, formData);
  }

  public delete(id) {
    return this.http.delete(`upload/${id}`);
  }
}
