<div
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollThrottle]="throttle"
  [alwaysCallback]="true"
  (scrolled)="onLoadCountryPlaces()">
  <div class="static__search-panel"
       [ngStyle]="{'display': !staticService.isMobileDesign() && staticService.isTabletDesign() && isOpenMap ? 'none': 'block'}"
       [class.search-panel-up]="isOpenMap || staticService.isMobileDesign()"
       [class.home-page]="staticService.staticType === staticRouteType.HomePage">
  </div>

  <app-fly-bottom-sheet *ngIf="staticService.isMobileDesign() && staticService.isShowBottomSheet"
                        [isCollectionInfo]="staticService.isCollectionInfo"
                        (closeBottomSheetEvent)="staticService.toggleBottomSheet(false)">
  </app-fly-bottom-sheet>


  <ng-content select="[pageContent]"></ng-content>

  <!-- Infinite Static Places -->
  <div
    [class.hidden]="isOpenMap && staticService.isTabletDesign()"
    *ngIf="staticService.placeItemsList.length > 0"
    infinite-scroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="staticContentService.onScrollDown()">
    <div class="static__places-system-title" *ngIf="isEmptyPlacesVisible()">
      Sorry, we don't have any places yet
    </div>
    <div class="static__places-items static-grid-container">
      <div *ngIf="staticService.isMobileDesign()" class="line-more last"></div>

      <ng-container *ngFor="let item of staticService.currentPlaceItemsList; last as last; index as index">
        <app-static-place-item
          [resizeWindow]="staticService.resizeWindow"
          [placeInfo]="item"
          [hostPlace]="staticService.showPlace"
          [pageType]="staticService.staticType"
          [isLast]="last"
          [placeIndex]="index"
          (pin)="onPinItem($event)"
          (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)"
        ></app-static-place-item>
      </ng-container>
      <ng-container
        *ngIf="
        (!staticService.isMobileDesign() && staticService.currentPlaceItemsList.length % dynamicCountOfColumns !== 0)
        || isEmptyPlacesVisible()">

        <a [routerLink]="'/place/add'" *ngIf="userIsLogged">
          <ng-container *ngTemplateOutlet="staticPlaceItemAdd"></ng-container>
        </a>

        <app-sign-in-buttons *ngIf="!userIsLogged" [isNotSignInButton]="staticPlaceItemAdd"></app-sign-in-buttons>

        <ng-template #staticPlaceItemAdd>
          <app-static-place-item-add></app-static-place-item-add>
        </ng-template>

      </ng-container>
    </div>

    <ng-container *ngIf="staticService.subPlaceItemsList.length > 0">
      <div class="static__places-system-title">Explore more places related to this search:</div>
      <div class="static__places-items static-grid-container">
        <app-static-place-item *ngFor="let item of staticService.subPlaceItemsList; last as last; index as index"
                               [placeInfo]="item"
                               (pin)="onPinItem($event)"
                               [isLast]="last"
                               [hostPlace]="staticService.showPlace"
                               [pageType]="staticService.staticType"
                               (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)"
        ></app-static-place-item>
      </div>
    </ng-container>
  </div>
</div>
