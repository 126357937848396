import { Action } from "@ngrx/store";
import { FollowedItem, FollowedList } from "../../interfaces/following";

export enum UserFollowingActionsType {
  Follow = '[UserFollowing] Follow',
  Unfollow = '[UserFollowing] Unfollow',
  LoadFollowed = '[UserFollowing] Load followed',
  SuccessLoadFollowed = '[UserFollowing] Success on load followed',
  SuccessFollow = '[UserFollowing] Success on follow',
  SuccessUnfollow = '[UserFollowing] Success on unfollow',
  RestoreFollowed = '[UserFollowing] Restore followed',
  ResetFollowed = '[UserFollowing] Reset followed',
  SynchronizeFollowed = '[UserFollowing] Synchronize followed',
}

export class LoadFollowed implements Action {
  readonly type = UserFollowingActionsType.LoadFollowed;

  constructor(public isListExtended = false) {
  }
}

export class RestoreFollowed implements Action {
  readonly type = UserFollowingActionsType.RestoreFollowed;

  constructor(public payload?: FollowedItem | any) {
  }
}

export class SuccessLoadFollowed implements Action {
  readonly type = UserFollowingActionsType.SuccessLoadFollowed;

  constructor(
    public payload?: FollowedItem | FollowedItem[] | any,
    public isUserLogged = false,
    public isListExtended = false
  ) {
  }
}

export class Follow implements Action {
  readonly type = UserFollowingActionsType.Follow;

  constructor(public payload: FollowedItem) {
  }
}

export class SynchronizeFollowed implements Action {
  readonly type = UserFollowingActionsType.SynchronizeFollowed;

  constructor(public payload: FollowedList) {
  }
}

export class SuccessFollow implements Action {
  readonly type = UserFollowingActionsType.SuccessFollow;

  constructor(public payload: FollowedItem, public isUserLogged: boolean = false) {
  }
}

export class Unfollow implements Action {
  readonly type = UserFollowingActionsType.Unfollow;

  constructor(public userId: string) {
  }
}

export class ResetFollowed implements Action {
  readonly type = UserFollowingActionsType.ResetFollowed;
}

export class SuccessUnfollow implements Action {
  readonly type = UserFollowingActionsType.SuccessUnfollow;

  constructor(public userId: string) {
  }
}

export type UserFollowingActionsUnion =
  LoadFollowed |
  SuccessFollow |
  SuccessLoadFollowed |
  SuccessUnfollow |
  Unfollow |
  RestoreFollowed |
  ResetFollowed |
  SynchronizeFollowed |
  Follow;

export const UserFollowingActions = {
  LoadFollowed,
  Follow,
  Unfollow,
  RestoreFollowed,
  ResetFollowed,
  SuccessFollow,
  SuccessLoadFollowed,
  SynchronizeFollowed,
  SuccessUnfollow
};
