<div class="modal">
  <div class="auth-container" #authContainer *ngIf="!showComponentWithName">
    <div class="close-button">
      <app-close-btn (click)="close()"></app-close-btn>
    </div>

    <section class="auth-with-email">
      <app-login
        *ngIf='showLoginForm'
        [showLoginForm]="showLoginForm"
        [auth]="auth"
        [searchMode]="searchMode"
        (resPasswordSuccessEmitter)="resPasswordSuccess($event)"
        (showUserAccountEmitter)="showUserAccount($event)"
        (closeLoginFormEmitter)="changeAuthForm()"
        (completeEmitter)="completeAuth()">
      </app-login>

      <app-register
        *ngIf='!showLoginForm'
        [loggedIn]="loggedIn"
        [month]="month"
        [searchMode]="searchMode"
        [auth]="auth"
        (closeRegisterFormEmitter)="changeAuthForm()"
        (registrationCompleted)="registrationCompleted($event)"
        (visibleTermsPrivacyPolicy)="showTermsPrivacy($event)"
        (completeEmitter)="completeAuth()"
        (showUserAccount)="showUserAccount()">
      </app-register>
    </section>
  </div>

  <div class="privacy-terms" *ngIf="showComponentWithName" #authContainer>
    <app-terms-of-service
      *ngIf="showComponentWithName === 'terms-of-service'"
      (closeTermsOfService)="hideTermsPrivacyPolicy()">
    </app-terms-of-service>

    <app-privacy-policy *ngIf="showComponentWithName === 'privacy-policy'">
    </app-privacy-policy>
  </div>
</div>
