import { HostListener, Injectable } from '@angular/core';
import { SsrService } from "./ssr.service";
import { FlyCookieService } from "./fly-cookie";
import { select, Store } from "@ngrx/store";
import {
  getCityFrom,
  getHomeData,
  getSearchData,
  isFavoritePanelVisible,
  isFollowingPanelVisible,
  isPinnedPanelVisible,
  State
} from "../store/reducers";
import { WindowRef } from "./window.service";
import {
  ResetSearchData,
  SetCityTo,
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from "../store/layer";
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { StaticRouteType } from "../modules/static/enums/route-type";
import { MapCoordinates, Place, PlaceGalleryMedia, PlaceNew, SearchStateForm } from "../interfaces";
import { map, mergeMap, take } from "rxjs/operators";
import { searchData, UserService } from "./user-token.service";
import { AuthModalService } from "./auth-modal.service";
import { MetaTagsService } from "./meta-tags";
import { Router } from "@angular/router";
import { UserCollectionService } from "./user-collection.service";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { parseCoords } from "../libraries/map";
import { DestinationType } from "../enums/destination-type";
import { HttpParams } from "@angular/common/http";
import { InfoService } from "./info.service";
import { StaticInitService } from "../modules/static/services/static-init.service";
import { ResponseErrorMessageCode } from "../enums/response-error-message-code";
import { ToggleFavoritePanel, ToggleFollowingPanel, TogglePinnedPanel } from "../store/panels/panels.actions";
import { getLinkByObject } from "../libraries/get-link-by-object";
import { getUserNameByObject } from "../libraries/get-user-name";
import { MobilePanelAction } from "../enums/mobile-panel-action.enum";
import { PanelType } from "../enums/panel-type.enum";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class StaticService {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
    if (event.target.innerWidth < 896) {
      this.withFollowLine = this.staticType === this.staticRouteType.Journey;
    }
  }

  //TODO: refactoring of this params
  public isOpenMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentPlace$ = new BehaviorSubject<any>(null);
  public resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  public window;
  public zoom: number;
  public oldZoom: number;
  public showPlace: any;
  public isOpenPinned: boolean;
  public staticType: StaticRouteType = StaticRouteType.HomePage;
  public visibleTooltipPlace: PlaceNew;
  public lat = 0;
  public lng = 0;
  public oldLat: number;
  public oldLng: number;
  public isInit: boolean;
  public routeParams: any;
  public routeQueryParams: any;
  public placesData: any;
  public isOpenAccount: boolean;
  public placeItemsList: Place[] = [];
  public isShowBottomSheet: boolean = false;
  public isCollectionInfo: boolean = false;
  public searchData: SearchStateForm;
  public homeData: any;
  public cityFrom: any;
  public totalCount = 100;
  public pageCount = 1;
  public placeInfo: PlaceNew;
  public isCollectionError = false;
  public currentPlaceItemsList: Place[] = []; // Infinite Static Places
  public subPlaceItemsList: Place[] = []; // Infinite Static Places
  public isDeactivatedPage = false;
  public userInfo: any;
  public searchQuery: string;
  public isPlaceInfo: boolean;
  public isLoading = true;
  public readonly placesLimitMobile = 10;
  public pageSimilar = 0;
  public page = 1;
  public userPlacesLength: number;
  public similarPlaces = [];
  public allPlaceCount = 10;
  public allCollectionCount = 10;
  public blockQueryParamsRequest: boolean;
  public subPlaceId: string | number;
  public tagGroupId: string;
  public currentPage: number;
  public mainPageCount: number;
  public similarPageCount: number;
  public currentThirdRequestCount = 0;
  public searchMapExtra: any;
  public groupRouteQuery: any;
  public smallCountryData = false;
  public isUpdateTravelParams = false;
  public currentViewportCoordinates: MapCoordinates = null;
  public withFollowLine: boolean;
  public isRememberCollection: boolean;
  public editPlace: any;
  public errorMessage: string;
  // Processed only for place, city, country, user pages. If it needs - set in other places.
  public isMainInfoLoaded = false;

  private uniquePlaceItemsList: any = [];
  private oldPlaceItemsList: any = [];
  private staticRouteType = StaticRouteType;
  private isExtraSimilarPlaces = false;
  public isPinnedPlaceDisplay: boolean = false;
  public isFavoritesPlaceDisplay: boolean = false;
  public isFollowingPanelVisible: boolean = false;

  constructor(
    private ssrService: SsrService,
    private cookieService: FlyCookieService,
    private store$: Store<State>,
    private router: Router,
    windowRef: WindowRef,
    private authModalService: AuthModalService,
    private userService: UserService,
    private metaTagsService: MetaTagsService,
    private userCollectionService: UserCollectionService,
    private staticInitService: StaticInitService,
    private infoService: InfoService,
  ) {
    this.window = windowRef.getNativeWindow();

    if (this.isMobileDesign()) {
      this.withFollowLine = this.staticType === this.staticRouteType.Journey;
    }

    combineLatest([
      this.store$.pipe(select(getHomeData)),
      this.store$.pipe(select(getSearchData)),
      this.store$.pipe(select(getCityFrom)),
    ])
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(([home, search, city]) => {
        this.homeData = JSON.parse(JSON.stringify(home));
        if (this.homeData?.coordinates) {
          this.homeData = Object.assign(this.homeData, parseCoords(this.homeData.coordinates));
        }
        this.searchData = search;
        this.cityFrom = city;
      });

    combineLatest([
      this.store$.select(isPinnedPanelVisible),
      this.store$.select(isFavoritePanelVisible),
      this.store$.select(isFollowingPanelVisible),
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(([isShowPanel, isShowFavoritePanel, isFollowingPanelVisible]) => {
      this.isPinnedPlaceDisplay = isShowPanel;
      this.isFavoritesPlaceDisplay = isShowFavoritePanel;
      this.isFollowingPanelVisible = isFollowingPanelVisible && !isShowPanel && !isShowFavoritePanel;
    });
  }

  public setStaticType(staticType) {
    this.staticType = staticType; // get STATIC-TYPE PAGE
  }

  public isMobileDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile()
      || this.ssrService.isBrowser() && this.resizeWindow <= 896;
  }

  public isTabletDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isTablet() ||
      this.ssrService.isBrowser() && this.resizeWindow < 1024;
  }

  public isMobileOrTablet(): boolean {
    return this.isMobileDesign() || this.isTabletDesign();
  }

  public clearFilter(): void {
    this.cookieService.delete(searchData);
    this.store$.dispatch(new SetCityTo(null));
    this.store$.dispatch(new ResetSearchData());
  }

  /**
   * Method for parsing deep encoded URI params with loop limit
   * @param string
   * @private
   */
  public decodeURI(string: string): string {
    let result = string;
    let i = 0;
    const loopLimit = 30;
    while (!result.includes('?') && i < loopLimit) {
      result = decodeURIComponent(result);
      i++;
    }
    if (i === loopLimit) {
      return null;
    }

    return result;
  }

  public togglePanel(type: PanelType, isOpen: boolean): void {
    switch (type) {
      case PanelType.Favourite:
        this.toggleFavoritePanel(isOpen);
        break;
      case PanelType.Pinned:
        this.togglePinnedPanel(isOpen);
        break;
      case PanelType.Following:
        this.toggleFollowingPanel(isOpen);
        break;
    }
  }

  private togglePinnedPanel(isOpen: boolean): void {
    this.store$.dispatch(new TogglePinnedPanel({display: isOpen}));
  }

  private toggleFavoritePanel(isOpen: boolean): void {
    this.store$.dispatch(new ToggleFavoritePanel({display: isOpen}));
  }

  private toggleFollowingPanel(isOpen: boolean): void {
    this.store$.dispatch(new ToggleFollowingPanel({display: isOpen}));
  }

  public openUserAccount(value): void {
    this.isOpenAccount = value;
  }

  public mobilePanelAction(action: MobilePanelAction): void {
    switch (action) {
      case MobilePanelAction.TogglePinnedPanel:
        this.togglePanel(PanelType.Pinned, !this.isOpenPinned);
        break;
      case MobilePanelAction.ToggleFavoritePanel:
        this.togglePanel(PanelType.Favourite, !this.isOpenPinned);
        break;
      case MobilePanelAction.ToggleFollowingPanel:
        this.togglePanel(PanelType.Following, !this.isOpenPinned);
        break;
      case MobilePanelAction.ActivateMap:
        this.isOpenMap.next(true);
        break;
      case MobilePanelAction.CancelMap:
        this.isOpenMap.next(false);
        break;
      case MobilePanelAction.OpenPersonalPage:
        this.userService.isUserAuth
          .pipe(take(1))
          .subscribe(value => {
            if (value) {
              this.router.navigate([getLinkByObject(this.homeData?.user)]);
            } else {
              this.authModalService.open();
            }
          });
        break;
      case MobilePanelAction.NavigateAccount:
        this.userService.isUserAuth
          .pipe(take(1))
          .subscribe(value => {
            if (value) {
              this.router.navigate(['/account']);
            } else {
              this.authModalService.open();
            }
          });
        break;
    }
  }

  public setSeoDataSubPages(response?: any, countries?: any[]): void {
    switch (this.staticType) {
      case StaticRouteType.Place:
        this.metaTagsService.resolver({
          route: 'places',
          name: response.name,
          city: response.city?.name || response?.country?.name,
          description: response.description,
        });
        break;
      case StaticRouteType.Review:
        this.metaTagsService.resolver({
          route: 'review',
          name: response.name,
          user: response?.reviews[0]?.user,
          description: response?.reviews[0]?.text,
        });
        break;
      case StaticRouteType.City:
        this.metaTagsService.resolver({
          route: 'city',
          city: response.name,
          country: response?.country?.name,
          countries: countries,
        });
        break;
      case StaticRouteType.Country:
        this.metaTagsService.resolver({
          route: 'country',
          country: response.name,
          tagsRenderKey: response.name + 'Tags',
        });
        break;
      case StaticRouteType.Experiences:
        this.metaTagsService.resolver({
          route: 'experiences',
          country: response.country?.name || '',
          tag: response.tag?.name || '',
        });
        break;
      case StaticRouteType.ThingsToDo:
        this.metaTagsService.resolver({
          route: 'things-to-do',
          city: response.city?.name || '',
          tags: response.tags?.map((tag) => tag.name),
        });
        break;
      case StaticRouteType.TagCity:
        this.metaTagsService.resolver({
          route: 'tag-city',
          tag: response.tag?.name,
          city: response.city?.name,
          country: response.city?.country?.name,
          placesName: this.placeItemsList.length >= 2 ? [
            this.placeItemsList[0] && this.placeItemsList[0].name,
            this.placeItemsList[1] && this.placeItemsList[1].name,
          ] : [],
        });
        break;
      case StaticRouteType.CategoryCity:
        this.metaTagsService.resolver({
          route: 'category-city',
          placesName: response?.category?.name,
          city: response?.city?.name,
          tags: response?.tags?.map((tag) => tag.name),
        });
        break;
      case StaticRouteType.CategoryCountry:
        this.metaTagsService.resolver({
          route: 'category-country',
          placesName: response?.category?.name,
          country: response?.country?.name,
          tags: response?.tags?.map((tag) => tag.name),
        });
        break;
      case StaticRouteType.Travel:
        this.metaTagsService.resolver({
          route: 'travel',
          name: this.placesData.name,
          country: response.country && response.country.name,
          type: this.routeParams.typeId ? Number(this.routeParams.typeId) : null,
          items: response.cities ? response.cities.map((city) => city.name) : [],
          total: this.placesData._extra.cities?.length || 0,
          city: this.placesData.city,
          poi: response.poi ? response.poi : null,
          dest: response.dest ? response.dest : null,
          tag: response.tag ? response.tag : null,
        });
        break;
      case StaticRouteType.Search:
        this.metaTagsService.resolver({
          route: 'search',
        });
        break;
      case StaticRouteType.UserPersonalPage:
        this.metaTagsService.resolver({
          route: 'user',
          name: response.name,
          user: response
        });
        break;
      case StaticRouteType.HomePage:
        this.metaTagsService.resolver({
          route: 'home'
        });
        break;
      case StaticRouteType.Journey:
        this.metaTagsService.resolver({
          route: 'journey',
          name: response?.collection?.name,
          data: {
            collection: response.collection,
            places: this.placesData.items
          }
        });
        break;
      default:
        this.metaTagsService.resolver({
          route: 'user',
          name: response.name,
        });
        break;
    }
  }

  public toggleBottomSheet(isShow = false, isCollectionInfo = true) {
    this.userCollectionService.isShowBottomSheet$.next(isShow);
    this.isShowBottomSheet = isShow;
    this.isCollectionInfo = isCollectionInfo;
  }

  // INIT Infinite Static Places
  public initInfiniteStaticPlaces(placeItemsList: Place[]): void {
    // loading content
    switch (this.staticType) {
      case StaticRouteType.Place:
      case StaticRouteType.Review:
        if (!this.placeInfo) {
          setTimeout(() => this.initInfiniteStaticPlaces(this.placeItemsList), 700);
          return;
        }
        break;
      case StaticRouteType.City:
      case StaticRouteType.Country:
        if (!this.showPlace) {
          setTimeout(() => this.initInfiniteStaticPlaces(this.placeItemsList), 700);
          return;
        }
        break;
      case StaticRouteType.TagCity:
      case StaticRouteType.CategoryCountry:
      case StaticRouteType.Experiences:
      case StaticRouteType.CategoryCity:
      case StaticRouteType.ThingsToDo:
      case StaticRouteType.Search:
      case StaticRouteType.Travel:
      case StaticRouteType.Journey:
        this.userCollectionService.isErrorCollection$
          .pipe(untilDestroyed(this))
          .subscribe(error => this.isCollectionError = error);
        if (!this.placesData && !this.isCollectionError) {
          setTimeout(() => this.initInfiniteStaticPlaces(this.placeItemsList), 700);
          return;
        }
        break;
    }

    const isCurrentPlace = (item: any): boolean => {
      let name: string;

      switch (this.staticType) {
        case StaticRouteType.Place:
          name = this.placeInfo?.city?.name || this.placeInfo?.country?.name;
          break;
        case StaticRouteType.City:
        case StaticRouteType.Country:
          name = this.showPlace.name;
          break;
        case StaticRouteType.TagCity:
        case StaticRouteType.CategoryCountry:
        case StaticRouteType.Experiences:
        case StaticRouteType.CategoryCity:
        case StaticRouteType.ThingsToDo:
        case StaticRouteType.Search:
        case StaticRouteType.Travel:
          const {_extra: dataExtra} = this.placesData;

          if (dataExtra.city) {
            name = dataExtra.city.name;
          } else if (dataExtra.country) {
            name = dataExtra.country.name;
          }
          break;
        case StaticRouteType.Journey:
          return !!item.isCollectionPlace;
        default:
          return true;
      }

      return name
        ? (item.city && (item.city?.name === name || item.city?.country?.name === name)
          || item.country && item.country?.name === name)
        : true;
    };
    if (this.staticType === StaticRouteType.Place) {
      this.subPlaceItemsList = placeItemsList;
    } else {
      // filter content
      this.currentPlaceItemsList = placeItemsList.filter(item => isCurrentPlace(item));
      this.subPlaceItemsList = placeItemsList.filter(item => !isCurrentPlace(item));
    }
  }

  public setDeactivatedPageMode(displayName, messageCode, id = null) {
    this.isDeactivatedPage = true;
    const coordinates = parseCoords(this.homeData.coordinates);
    this.userInfo = {
      id: id ? id : (this.homeData.user ? this.homeData.user.id : null),
      displayName: displayName,
      messageCode: messageCode,
      lat: coordinates.lat,
      lng: coordinates.lng
    };
    this.metaTagsService.resolver({
      route: 'default'
    });
    this.setCurrentPlace(this.userInfo);
    this.userCollectionService.isErrorCollection$.next(true);
  }

  // help CITY/PLACE :
  public mapData(place: any): void {
    this.isLoading = false;
    this.showPlace = place;
    this.setCurrentPlace(place);

    if (this.showPlace.similarPlaces) {
      this.placeItemsList = this.showPlace.similarPlaces;
      this.initInfiniteStaticPlaces(this.placeItemsList);
    }

    if (place.otype === DestinationType.Place) {
      this.placeInfo = place;
      this.placeInfo['typePage'] = this.staticType;
      if (this.placeInfo['similarPlaces']) {
        this.placeItemsList = this.placeInfo['similarPlaces'];
        this.initInfiniteStaticPlaces(this.placeItemsList);
      }
      this.window?.scrollTo(0, 0);
      this.isPlaceInfo = true;
    }
    this.isMainInfoLoaded = true;
  }

  public getErrorMessage() {
    this.errorMessage = '';
    if (this.staticType === StaticRouteType.Place
      && this.userInfo?.messageCode === ResponseErrorMessageCode.CODE_DISABLED) {
      this.errorMessage = 'Sorry, this page is under consideration at this moment. Please, come back soon';
    } else if (this.userInfo?.messageCode === ResponseErrorMessageCode.CODE_DELETED
      || this.userInfo?.messageCode === ResponseErrorMessageCode.CODE_DISABLED) {
      this.errorMessage = 'Sorry, this page has been removed or no longer exists';
    } else if (this.userInfo?.messageCode === ResponseErrorMessageCode.CODE_NOT_PUBLIC) {
      const user =
        (this.staticType === StaticRouteType.UserPersonalPage
          || this.staticType === StaticRouteType.Journey)
        && getUserNameByObject(this.userInfo);
      const page = this.staticType === StaticRouteType.UserPersonalPage ? 'Personal Page' : 'journey';
      const userName = this.userInfo?.displayname ? this.userInfo.displayname : user;
      this.errorMessage = userName + ` prefers to keep this ${page} private`;
    } else if (this.staticType === StaticRouteType.Search) {
      this.errorMessage = `Sorry, we couldn\'t find "${this.searchQuery}". Please, try another search`;
    }
  }

  public async getSimilarPlaces(): Promise<void> {
    let httpParams = new HttpParams();
    this.pageCount++;

    httpParams = httpParams.set(
      'expand',
      'pictures,user,city.country,country,tags,city.flights,pinned,video,city.hasPage,user.urlSetting'
    );
    httpParams = httpParams.set(
      'filter[cityFrom]',
      this.searchData.cityFrom.id.toString()
    );
    httpParams = httpParams.set(
      'filter[paxCount]',
      this.searchData.passengers.toString()
    );
    httpParams = httpParams.set(
      'filter[flightType]',
      transformTripTypeToApi(this.searchData.tripType)
    );
    httpParams = httpParams.set(
      'filter[dateType]',
      transformDateToApiDateType(this.searchData.date).toString()
    );
    httpParams = httpParams.set(
      'filter[when]',
      transformWhenDateToApiDateType(this.searchData.date)
    );
    httpParams = httpParams.set(
      'filter[return]',
      transformReturnToApiDateType(this.searchData.date) || ''
    );

    httpParams = httpParams.set('page', this.pageCount.toString());
    if (
      this.showPlace &&
      this.showPlace.hasOwnProperty('lat') &&
      this.showPlace.hasOwnProperty('lng')
    ) {
      httpParams = httpParams.set(
        'filter[sourceLat]',
        this.showPlace.lat.toString() || this.placeInfo.lat.toString()
      );
      httpParams = httpParams.set(
        'filter[sourceLng]',
        this.showPlace.lng.toString() || this.placeInfo.lng.toString()
      );
    }

    switch (this.staticType) {
      case StaticRouteType.Place:
        if (this.showPlace) {
          httpParams = httpParams
            .append('filter[placeId][neq]', this.showPlace.id.toString());

          if (this.showPlace.category) {
            httpParams = httpParams
              .append('filter[tagGroupId]', this.showPlace.category.id.toString());
          }
        }
        break;
      case StaticRouteType.City:
        httpParams = httpParams.append(
          'filter[cityId][neq]',
          this.showPlace.id.toString()
        );
        break;
      case StaticRouteType.UserPersonalPage:
        httpParams = httpParams.append(
          'filter[userId][neq]',
          this.routeParams.id.toString()
        );
        break;
      case StaticRouteType.ThingsToDo:
        httpParams = httpParams.append(
          'filter[cityId][neq]',
          this.showPlace.id.toString()
        );
        break;
      case StaticRouteType.TagCity:
        const id = this.showPlace.id ? this.showPlace.id?.toString() : this.routeParams.id?.toString();
        httpParams = httpParams.append('filter[cityId][neq]', id);
        if (this.placesData._extra.tag) {
          httpParams = httpParams.append('filter[tagId]', this.placesData._extra.tag.id.toString());
        }
        break;
      case StaticRouteType.CategoryCity:
        httpParams = httpParams
          .append('filter[cityId][neq]', this.showPlace.id.toString())
          .append(
            'filter[tagGroupId]',
            this.placesData._extra.category && this.placesData._extra.category.id.toString()
          );
        break;
      case StaticRouteType.Journey:
        if (this.userInfo.messageCode !== ResponseErrorMessageCode.CODE_NOT_PUBLIC) {
          httpParams = httpParams
            .append('filter[collectionId][neq]', this.routeParams.id);
        }
        break;
      default:
        break;
    }
    if (this.isMobileDesign()) {
      httpParams = httpParams.append('pageSize', this.placesLimitMobile);
    }

    let result: any;

    if (!this.isExtraSimilarPlaces) {
      try {
        result = await this.infoService.getPlaceList(httpParams).toPromise();
      } catch (error) {
      }
    }

    if (this.staticType === StaticRouteType.TagCity) {
      if (!result || (result && result.items.length === 0)) {
        this.isExtraSimilarPlaces = true;
        result = await this.getCityExtraSimilarPlaces();
      }
    }
    if (result) {
      this.setSimilarPlaces(result);
    }
  }

  private getCityExtraSimilarPlaces(): Promise<any> {
    const {city, tag} = this.placesData._extra;
    const {cityFrom, passengers, tripType, date} = this.searchData;

    const httpParams = new HttpParams({
      fromObject: {
        'expand': 'pictures,city.country,country,city.flights,pinned,user.urlSetting',
        'filter[cityFrom]': cityFrom.id.toString(),
        'filter[paxCount]': passengers.toString(),
        'filter[flightType]': transformTripTypeToApi(tripType),
        'filter[dateType]': transformDateToApiDateType(date).toString(),
        'filter[when]': transformWhenDateToApiDateType(date),
        'filter[return]': transformReturnToApiDateType(date) || '',
        'filter[cityId][neq]': city.id,
        'filter[tagId][neq]': tag.id,
        'filter[sourceLat]': city.lat,
        'filter[sourceLng]': city.lng,
        'page': this.pageCount.toString(),
        'pageSize': this.isMobileDesign() ? this.placesLimitMobile : null
      },
    });

    return this.infoService.getPlaceList(httpParams).toPromise();
  }

  private setSimilarPlaces(response: any): void {
    if (response) {
      this.uniquePlaceItemsList = this.sortingListPlaces(response.items);
      this.similarPageCount = response?._meta?.pageCount ? response?._meta?.pageCount : 1;
      this.totalCount = response?._meta?.totalCount;
      this.placeItemsList = this.placeItemsList.concat(this.uniquePlaceItemsList);
      this.initInfiniteStaticPlaces(this.placeItemsList);
    }
  }

  public getSubSimilarPlaces(): void {
    this.pageCount++;
    this.store$
      .pipe(
        select(getSearchData),
        take(1),
        map((searchState) => {
          this.searchData = searchState;
          // What is this?
          return {
            ...searchState,
            countryId:
              (this.placesData &&
                this.placesData._extra &&
                ((this.placesData._extra.country &&
                    this.placesData._extra.country.id) ||
                  (this.placesData._extra.city &&
                    this.placesData._extra.city.country &&
                    this.placesData._extra.city.country.id))) ||
              this.showPlace?.country?.id ||
              this.showPlace?.id || null,
            cityId:
              this.placesData &&
              this.placesData._extra &&
              this.placesData._extra.city &&
              this.placesData._extra.city.id ||
              this.showPlace?.city?.id || null,
            isSimilarPlaces: true,
            tagId: this.tagGroupId,
            collectionId: this.routeParams.id,
            page: this.pageCount.toString(),
            type: this.staticType,
            routeId: this.routeParams.id,
          };
        }),
        mergeMap((categoryData) =>
          this.staticInitService.getCategoryData(categoryData, this.isMobileDesign() ? this.placesLimitMobile : null)
        ),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        this.uniquePlaceItemsList = this.sortingListPlaces(data.data.items);
        this.similarPageCount = data.data._meta ? data.data._meta.pageCount : 0;
        this.smallCountryData = this.similarPageCount === 0;
        this.totalCount = data.data._meta ? data.data._meta.totalCount : 0;
        this.placeItemsList = this.placeItemsList.concat(this.uniquePlaceItemsList);
        this.initInfiniteStaticPlaces(this.placeItemsList);
      });
  }

  public sortingListPlaces(newData) {
    this.oldPlaceItemsList = this.searchMapExtra;

    if (this.oldPlaceItemsList && this.oldPlaceItemsList.length > 0) {
      return this.noRepListPlaceItems(newData, this.oldPlaceItemsList);
    } else if (this.placeItemsList.length > 0) {
      return this.noRepListPlaceItems(newData, this.placeItemsList);
    } else {
      return newData;
    }
  }

  public noRepListPlaceItems(newMass, oldMass) {
    const notRep = [];
    let result = 0;
    for (let i = 0; i < newMass.length; i++) {
      const idx = newMass[i].id;
      for (let j = 0; j < oldMass.length; j++) {
        if (oldMass[j].id === idx) {
          result = 0;
          break;
        } else if (oldMass[j].id !== idx) {
          result = 1;
        }
      }
      if (result === 1) {
        notRep.push(newMass[i]);
      }
    }
    return notRep;
  }

  public getSimilarPlacesRequest() {
    this.currentThirdRequestCount++;
    this.store$
      .pipe(select(getSearchData))
      .pipe(
        take(1),
        map((searchState) => {
          this.searchData = searchState;
          return {
            ...searchState,
            countryId:
              (this.placesData &&
                this.placesData._extra &&
                ((this.placesData._extra.country &&
                    this.placesData._extra.country.id) ||
                  (this.placesData._extra.city &&
                    this.placesData._extra.city.country &&
                    this.placesData._extra.city.country.id))) ||
              this.showPlace.id,
            cityId:
              this.placesData &&
              this.placesData._extra &&
              this.placesData._extra.city &&
              this.placesData._extra.city.id,
            isSimilarPlaces: true,
            tagId: this.tagGroupId,
            collectionId: this.routeParams.id,
            page: this.currentThirdRequestCount,
            type: this.staticType,
          };
        }),
        mergeMap((categoryData) => {
          return this.staticInitService.fetchGetSimilarPlaces(categoryData, this.showPlace, this.isMobileDesign() ? this.placesLimitMobile : null)
        })
      )
      .subscribe((data) => {
        if (!data || !data.data) {
          return;
        }
        this.pageCount = data.data._meta && data.data._meta.pageCount ? data.data._meta.pageCount : 1;
        this.placeItemsList = this.placeItemsList.concat(data.data.items);
        this.initInfiniteStaticPlaces(this.placeItemsList);
      });
  }

  public setCurrentPlace(place): void {
    this.currentPlace$.next(place);
  }

  getGalleryByPageType(place, index = 0): PlaceGalleryMedia {
    let result: PlaceGalleryMedia = {
      galleryMedia: [],
      previewSliderImages: [],
      galleryImages: [],
      galleryVideos: [],
      allGalleryMedia: [],
    };
    if (place) {
      if (this.staticType === this.staticRouteType.City) {
        if (place && place.pictures && place.pictures['head']) {
          result.previewSliderImages.push(place.pictures['head']);
          result.galleryImages.push(place.pictures['head']);
        }

        if (place.pictures) {
          result.previewSliderImages = result.previewSliderImages.concat((place.pictures['other'] ?? place.pictures)?.slice(0, 5));
          result.galleryImages = result.galleryImages.concat(place.pictures['other']);
        }
      }

      if (this.staticType === StaticRouteType.Review) {
        if (place) {
          if (place?.video?.length) {
            let previewSliderImages = [];
            place.video.forEach(video => {
              Object.assign(video.poster, {
                isPortrait: video.isPortrait,
                isReady: video.isReady,
                isVideo: true,
              });
              Object.assign(video, {
                isVideo: true,
              });
              previewSliderImages = previewSliderImages.concat(video.poster);
              result.galleryVideos = result.galleryVideos.concat(video);
            });
            result.previewSliderImages = result.previewSliderImages.concat(previewSliderImages);
          }
          if (place?.pictures?.length) {
            result.galleryImages = result.galleryImages.concat(place.pictures);
            result.previewSliderImages = result.previewSliderImages.concat(place.pictures);
          }

          if (place?.reviews?.length) {
            let reviewGalleryVideo = [];
            let reviewGalleryImage = [];
            if (place) {
              place.reviews.forEach(review => {
                review.gallery.forEach(gallery => {
                  if (gallery?.isVideo) {
                    result.galleryVideos.unshift(gallery);
                    reviewGalleryVideo = reviewGalleryVideo.concat(gallery.poster);
                  } else {
                    reviewGalleryImage = reviewGalleryImage.concat(gallery);
                    result.galleryImages.unshift(gallery);
                  }
                });
              });
            }
            result.previewSliderImages = [...reviewGalleryVideo, ...reviewGalleryImage, ...result.previewSliderImages];
          }
          result.previewSliderImages = result.previewSliderImages.slice(0, 6);
        }
      }
      if (this.staticType === this.staticRouteType.Place) {
        if (place?.pictures?.length) {
          place.pictures.forEach(picture => {
            Object.assign(picture, {
              index
            });
          });
        }
        if (place?.video?.length) {
          let previewSliderImages = [];
          place.video.forEach(video => {
            if (video) {
              Object.assign(video.poster, {
                isPortrait: video.isPortrait,
                isReady: video.isReady,
                isVideo: true,
              });
              Object.assign(video, {
                index,
                isVideo: true,
              });
              previewSliderImages = previewSliderImages.concat(video.poster);
              result.galleryVideos = result.galleryVideos.concat(video);
            }
          });
          result.previewSliderImages = previewSliderImages;
        }
        result.galleryImages = result.galleryImages.concat(place.pictures);
        result.previewSliderImages = result.previewSliderImages.concat(place.pictures);
        result.previewSliderImages = result.previewSliderImages.slice(0, 6);
      }

      result.galleryMedia = [];
      result.galleryMedia = result.galleryMedia.concat(result.galleryVideos);
      result.galleryMedia = result.galleryMedia.concat(result.galleryImages);
      result.allGalleryMedia = [];
      result.allGalleryMedia = result.galleryMedia;
    }
    return result;
  }

  public close(): void {
    this.router.navigate([this.getUrlToRedirect()]);
  }

  private getUrlToRedirect(): string {
    let url = this.ssrService.isBrowser() && localStorage.getItem('previousUrl');
    if (
      !url ||
      url === '/account' ||
      url === '/account/profile' ||
      url === '/account/info' ||
      url === '/place/add' ||
      url.indexOf('/place/edit') >= 0 ||
      url.indexOf('/help/') >= 0
    ) {
      url = '/';
    }

    return url;
  }

  public resetListParams() {
    this.page = 1;
    this.pageSimilar = 0;
    this.currentPlaceItemsList = [];
    this.placeItemsList = [];
    this.subPlaceItemsList = [];
    this.similarPlaces = [];
    this.isMainInfoLoaded = false;
  }
}
