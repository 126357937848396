import { DestinationType } from "../enums/destination-type";
import { Points } from "./points";

export const FAVORITE_TITLE = 'Liked List';
export const FAVORITE_ID = 0;

export interface LikeInterface {
  oid: number;
  otype: DestinationType;
}

export interface LikesListInterface {
  id?: number;
  name?: string;
  isFavorite?: boolean;
  cities: Points[];
  places: Points[];
  countries: Points[];
  collections: Points[];
  _extra?: any;
  _sync: boolean;
}
