import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { UrlParseService } from '../url-parse.service';
import { Place } from '../../interfaces';
import { DestinationType } from '../../enums/destination-type';
import { getUserNameByObject } from "../../libraries/get-user-name";
import { DEFAULT_TITLE_JOURNEY } from "../../../constants";

@Injectable()
export class MetaTagsService {

  public isBrowser;
  public renderer: Renderer2;
  public resolve;
  public maxDescriptionLength = 155;
  explorowEndl = 'Explorow.com';

  constructor(
    private title: Title,
    private meta: Meta,
    rendererFactory: RendererFactory2,
    private urlParseService: UrlParseService,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public resolver(config) {
    let route = config.route ? config.route : this.urlParseService.getCurrentRoute();

    new Promise(resolve => resolve(config))
      .then(config => this.caseCurrentPage(route, config));
  }

  public caseCurrentPage(route, config: ConfigInterface) {
    let title;
    let description;
    switch (route) {
      case 'city':
        title = `${config.city}, ${config.country} - find more on ${this.explorowEndl}`;
        description = `Find pictures, events, things to do and activities. ${config.countries} `;

        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'country':
        title = `${config.country} - Cities, Places, Best Things on ${this.explorowEndl}`;
        description = `All about ${config.country}: cities you must see, currency, things to do and places to visit. ${config.country} on the travel map with ${this.explorowEndl}`;

        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'places':
        title = `${config.name}, ${config.city} | ${this.explorowEndl}`;
        description = `More about ${config.name}: pictures, location on the map, similar places. ${config.description}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'review':
        const username = getUserNameByObject(config?.user);
        title = `A Review of ${config.name} by ${username} - Explorow.com`;
        description = `${config.description}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'experiences':
        title = `${config.tag}, ${config.country} | ${this.explorowEndl}`;
        description = `Explore ${config.country}: Sights, Activities, Parks, ${config.tag} for most amazing Cities in ${config.country} on the travel map with ${this.explorowEndl}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'things-to-do':
        let tags = config.tags.join(', ');
        title = `What to Do and See in ${config.city} - ${this.explorowEndl}`;
        description = `Top things to do in ${config.city}: ${tags} on the map with ${this.explorowEndl}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'tag-city':
        title = `${config.tag || ''}, ${config.city} | ${this.explorowEndl}`;
        description = config.placesName.length > 0 ?
          `${config.placesName[0]}, ${config.placesName[1]}, discover top ${config.tag} in ${config.city}, ${config.country} on the map, things to do,attractions, activities and more` :
          `Discover top ${config.tag} in ${config.city}, ${config.country} on the map, things to do,attractions, activities and more`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'list-cities':
        description = config.description;
        this.createTitle(config.name + ' | ' + this.explorowEndl);
        this.createMetaDescription(description);
        break;
      case 'category-city':
        title = `${config.placesName} in ${config.city} | ${this.explorowEndl}`;
        description = `${config.city} - ${[...config.tags || []].join(', ')} on the map, ${this.explorowEndl}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'category-country':
        title = `${config.placesName} in ${config.country} | ${this.explorowEndl}`;
        description = `${config.country} - ${[...config.tags || []].join(', ')} on the map, ${this.explorowEndl}`;
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'search':
        this.createTitle(`${this.explorowEndl} | Travel Map`);
        this.createMetaDescription('Find your perfect travel destination with Explorow. Activities, cities, what to see and what to do. Unique design of the map, you will have entire world to explore!');
        break;
      case 'travel':
        title = this.generateTravelPageTitle(config);
        description = this.generateTravelPageDescription(config);
        this.createTitle(title);
        this.createMetaDescription(description);
        break;
      case 'policy-pages':
        this.createTitle(config.name + ' | ' + this.explorowEndl);
        this.createMetaDescription(config.description);
        break;
      case 'journey':
        const descriptionStart = 'Find more about ';
        let descriptionData = '';
        const places = config.data.places;
        const collection = config.data.collection;
        const userName = ' by ' + getUserNameByObject(collection?.user);
        const name = config.name || (DEFAULT_TITLE_JOURNEY + userName);
        places.forEach((data, idx) => {
          const placeCity = data.name + ', ' + data?.city?.name || data?.country?.name;

          if (descriptionStart.length + descriptionData.length + placeCity.length + this.explorowEndl.length < this.maxDescriptionLength) {
            descriptionData += placeCity;
            descriptionData += (places.length - 1 !== idx) ? '; ' : '';
          }
        });
        description = `${descriptionStart + descriptionData}- on ${this.explorowEndl}`;
        this.createTitle(name + ' - ' + this.explorowEndl);
        this.createMetaDescription(description);
        break;
      case 'user':
        title = `${getUserNameByObject(config.user)}${(config.user && config.user.homeCity) ? (', ' + config.user.homeCity.name) : ''} - Personal Travel Page on ${this.explorowEndl}`;
        if (config.user && config.user.about) {
          description = config.user.about;
        } else {
          description = `${config.user && config.user?.places?.map(p => p.name).join(', ')}`;
        }
        this.createTitle(title);
        this.createMetaDescription(description.slice(0, 170));
        break;
      case 'place-add':
      case 'photo-add':
        this.createTitle(config.name + ' | ' + this.explorowEndl);
        this.createMetaDescription(config.description);
        break;
      case 'promo':
        this.createTitle('Sign up for Explorow | Travel Map');
        break;
      default:
        this.createTitle(this.explorowEndl + ' | Travel Map');
        this.createMetaDescription('Find your perfect travel destination with Explorow. Activities, cities, what to see and what to do. Unique design of the map, you will have entire world to explore!');
        break;
    }
    this.destroyLinkForCanonicalURL();
    this.createLinkForCanonicalURL();
  }

  public generateTravelPageTitle(config): string {
    let title = '';
    switch (config.type) {
      case DestinationType.Tag:
        title = config?.tag?.name
          ? `${config.tag.name} | ${this.explorowEndl}`
          : this.explorowEndl;
        break;
      case DestinationType.PoI:
        title = config?.poi?.name
          ? `${config.poi.name} | ${this.explorowEndl}`
          : this.explorowEndl;
        break;
      case DestinationType.Destination:
        title = config?.dest?.name
          ? `${config.dest.name} | ${this.explorowEndl}`
          : this.explorowEndl;
        break;
      case DestinationType.AllPlaceCity:
        title = (config?.poi?.name && config?.city?.name)
          ? `${config.poi.name} in ${config.city.name} | ${this.explorowEndl}`
          : this.explorowEndl;
        break;
      case DestinationType.AllPlaceCountry:
        title = (config?.poi?.name && config?.country)
          ? `${config.poi.name} in ${config.country} | ${this.explorowEndl}`
          : this.explorowEndl;
        break;
      default:
        break;
    }

    return title;
  }

  public generateTravelPageDescription(config): string {
    let desc = '';

    switch (config.type) {
      case DestinationType.Tag:
        desc = this.getDescriptionData(config?.tag?.name, config);
        break;
      case DestinationType.PoI:
        desc = this.getDescriptionData(config?.poi?.name, config);
        break;
      case DestinationType.Destination:
        desc = this.getDescriptionData(config?.dest?.name, config);
        break;
      case DestinationType.AllPlaceCity:
        desc = this.getDescriptionData(config?.city?.name, config);
        break;
      case DestinationType.AllPlaceCountry:
        desc = this.getDescriptionData(config?.city?.country?.name, config);
        break;
      default:
        break;
    }

    return desc;
  }

  private getDescriptionData(value, config) {
    const descriptionData = `${[...config.items || []].join(', ')}`;
    return (value ? `${value}: ` : '') + descriptionData;
  }

  createTitle(value: string) {
    this.title.setTitle(value);
  }

  createMetaDescription(value: string) {
    this.meta.updateTag({name: 'description', content: value});
  }

  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }

  /**
   * Destroys existing link. To not duplicate elements during navigation
   */
  destroyLinkForCanonicalURL() {
    const els = this.document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }
}

interface ConfigInterface {
  name?: string;
  city?: string;
  country?: string;
  total?: string | number;
  items?: Place[];
  countries?: string;
  tags?: string[];
  tag?: string;
  titleForCountry?: string;
  description?: string;
  placesName?: string[];
  user?: any;
  data?: any;
}
