import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactUsService } from '../../../../../services/contact-us.service';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngrx/store';
import { getAjaxState, State } from '../../../../../store/reducers';
import { delay } from 'rxjs/operators';
import { CONTACT_US_ACTION } from "../../../../../enums/contact-us-action";

@Component({
  selector: 'app-contact-us',
  templateUrl: 'contact-us.component.html',
  styleUrls: [
    '../../../../../shared-components/fly-search-panel/registration/registration.component.scss',
    '../../../../user-account/components/profile-pass-change/profile-pass-change.component.scss',
    './contact-us.component.scss',
  ]
})
export class ContactUsComponent implements OnChanges {

  @Input() contactType: string;

  @Output() closeContactUs: EventEmitter<any> = new EventEmitter<any>();

  public ajaxState$: Observable<any>;
  public askQuestionFocused = false;
  public contactForm: UntypedFormGroup;
  public emailFocused = false;
  public ErrorResponse = false;
  public ErrorResponseMessage: any = '';
  public nameFocused = false;
  public resOfEmailValidation: any = '';
  public resOfMessageValidation: any = '';
  public resOfNameValidation: any = '';
  public SuccessResponse = false;
  public isSubmit = false;
  public title: string;
  public subText: string;
  public textInput: string;

  constructor(
    private fb: UntypedFormBuilder,
    protected store$: Store<State>,
    private _contactUsService: ContactUsService
  ) {
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
    this.contactForm = fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      name: ['', [Validators.required]],
      message: ['', [Validators.required]]
    }, {updateOn: 'blur'});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.contactType?.currentValue) {
      switch (this.contactType) {
        case CONTACT_US_ACTION.leave_feedback:
          this.title = 'Give us feedback';
          this.subText = 'Please share your experience, send us your ideas or make a suggestion';
          this.textInput = 'Feedback';
          break;
        case CONTACT_US_ACTION.general_enquiries:
          this.title = 'How can we help?';
          this.textInput = 'Ask a Question';
          break;
      }
    }
  }

  closeWindow() {
    this.closeContactUs.emit(null);
  }

  toggleFocus(type, isFocus = false) {
    if (!isFocus && this.contactForm.controls[type]?.value?.length > 0) {
      return;
    }
    if (type == 'email') {
      this.emailFocused = isFocus;
    } else if (type == 'name') {
      this.nameFocused = isFocus;
    } else if (type == 'message') {
      this.askQuestionFocused = isFocus;
    }
  }

  valueUpdated(propName, event = null) {
    this.contactForm.controls[propName].patchValue(event);
  }

  caseErrorMessage(error) {
    for (let i = 0; i < error.error.length; i++) {
      switch (error.error[i].field) {
        case 'email':
          this.resOfEmailValidation = error.error[i].message;
          break;
        case 'name':
          this.resOfNameValidation = error.error[i].message;
          break;
        case 'message':
          this.resOfMessageValidation = error.error[i].message;
          break;
        default:
          break;
      }
    }
  }

  contact(data) {
    this.isSubmit = true;
    if (this.contactForm.invalid) {
      this.SuccessResponse = false;
      return;
    }

    this.resOfEmailValidation = '';
    this.resOfNameValidation = '';
    this.resOfMessageValidation = '';
    this.ErrorResponseMessage = '';
    this._contactUsService.contactUs(data.value, this.contactType).subscribe(
      () => {
        this.SuccessResponse = true;
        this.contactForm.reset();
        this.isSubmit = false;
      },
      error => {
        if (error.status == 429) {
          this.ErrorResponse = true;
          this.ErrorResponseMessage = error.error.message;
        } else {
          this.caseErrorMessage(error);
        }
        this.isSubmit = false;
      }
    );
  }

}
