import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpService } from '../../services/sign-up.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { SsrService, TransferKeys } from '../../services/ssr.service';
import { StaticService } from "../../services/static.service";
import { BaseHeaderComponent } from "../shared/base-header/base-header.component";

@Component({
  selector: 'app-signup',
  templateUrl: '../auth-external/auth-external.component.html',
  styleUrls: ['../auth-external/auth-external.component.scss']
})
export class SignupComponent extends BaseHeaderComponent implements OnInit {

  @ViewChild('pinnedPanelContainer') pinnedPanelContainer: ElementRef;

  public error: any;
  public isPinnedPlaceDisplay: boolean;

  constructor(
    private route: ActivatedRoute,
    private signUpService: SignUpService,
    protected store$: Store<State>,
    protected ssrService: SsrService,
    public staticService: StaticService,
    protected cdRef: ChangeDetectorRef,
    private router: Router,
  ) {
    super(store$, cdRef, ssrService);
  }

  ngOnInit() {

    if (!this.ssrService.isSSR() && this.ssrService.hasState(TransferKeys.Status)) {
      this.error = this.ssrService.getState('error');
      return;
    }

    const token = this.route.snapshot.queryParams.token;
    const url = this.route.snapshot.routeConfig.path;

    if (url === 'email/confirm') {
      this.signUpService.validateEmail(token)
        .subscribe(
          () => {
            this.ssrService.redirect(301, '/account');
          },
          (error) => {
            this.error = error.error.message || error?.error[0]?.message;
            this.ssrService
              .setResponseStatusCode(401)
              .setState('error', this.error);
          }
        );
    } else {
      this.signUpService.confirm(token).subscribe(
        () => {
          this.router.navigateByUrl('/', {state: {signupConfirmed: true}});
        },
        (error) => {
          this.error = error.error?.message || error?.error[0]?.message;
          this.ssrService
            .setResponseStatusCode(401)
            .setState('error', this.error);
        }
      );
    }
  }
}
