import { Component, Input, OnInit } from '@angular/core';
import { WindowRef } from '../../../../services/window.service';
import { UserService } from '../../../../services/user-token.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-link-social-account',
  templateUrl: 'link-social-account.component.html',
  styleUrls: ['link-social-account.component.scss']
})
export class LinkSocialAccountComponent implements OnInit {
  @Input() auth: any;

  public facebookLabel: string;
  public googleLabel: string;
  public labels: any = {
    google: ['buttons.continue.google', 'buttons.link.google'],
    facebook: ['buttons.continue.facebook', 'buttons.link.facebook']
  };
  public socialFB: boolean;
  public socialGoogle: boolean;
  public window: any;

  constructor(
    private userService: UserService,
    private windowRef: WindowRef,
  ) {
    this.window = windowRef.getNativeWindow();
  }

  ngOnInit() {
    this.socialGoogle = this.findAuthSocial('google');
    this.socialFB = this.findAuthSocial('facebook');
    let index = 1;
    if (this.auth[0]) {
      index = 0;
    }
    this.saveTranslationIntoProperty(this.labels['google'][index], 'googleLabel');
    this.saveTranslationIntoProperty(this.labels['facebook'][index], 'facebookLabel');
  }

  public signInWithGoogle(): void {
    let google = this.getSocial('google');
    this.window.open(google.url, '_self');
  }

  public signInWithFB(): void {
    let facebook = this.getSocial('facebook');
    this.window.open(facebook.url, '_self');
  }

  public getSocial(social: any): any {
    return this.auth[social] || this.auth.find((param) => {
      return param.name == social;
    });
  }

  public findAuthSocial(social: any): boolean {
    if (this.auth[0]) return true;

    return this.auth[social]
      ? (this.auth[social].status == 0)
      : this.auth.some((s) => {
        return s.status == 0 && s.name == social;
      });
  }

  public saveTranslationIntoProperty(id: string, whereToSave: string): void {
    this.userService.getTranslation(id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this[whereToSave] = res;
        }
      );
  }
}
