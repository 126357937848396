<ng-container
  *ngIf="staticService.isPinnedPlaceDisplay
  || staticService.isFavoritesPlaceDisplay
  || staticService.isFollowingPanelVisible">
  <div class="pinned-panel-outer-container"
       [class.static-container-highest-priority-header]="staticService.isMobileDesign()">
    <div class="progress-bar">
      <mat-progress-bar
        *ngIf="ajaxState$ | async"
        mode="indeterminate">
      </mat-progress-bar>
    </div>

    <div class="empty-places" (click)="togglePanel(false)"></div>

      <div #pinnedPanelContainer
           class="pinned-panel-container"
           (scroll)="staticService.isPinnedPlaceDisplay && loadMovementPinPanelData()">
        <app-pinned-panel *ngIf="staticService.isPinnedPlaceDisplay"
                          [mode]="PinnedModeIdx.Journeys"
                          (initPinnedPanelEmit)="loadMovementPinPanelData()"></app-pinned-panel>
        <app-favorites-panel *ngIf="staticService.isFavoritesPlaceDisplay"></app-favorites-panel>
        <app-following-panel *ngIf="staticService.isFollowingPanelVisible"></app-following-panel>
      </div>
  </div>
</ng-container>
