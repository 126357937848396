import { Action } from "@ngrx/store";
import { NotificationItem, NotificationList, NotificationPagination } from "../../interfaces/notifications";

export enum UserNotificationsActionsType {
  LoadNotifications = '[UserNotifications] Load notifications',
  SuccessLoadNotifications = '[UserNotifications] Success on load notifications',
  RestoreNotifications = '[UserNotifications] Restore notifications',
  ResetNotifications = '[UserNotifications] Reset notifications',
}

export class LoadNotifications implements Action {
  readonly type = UserNotificationsActionsType.LoadNotifications;

  constructor(public payload?: NotificationPagination) {
  }
}

export class RestoreNotifications implements Action {
  readonly type = UserNotificationsActionsType.RestoreNotifications;

  constructor(public payload?: NotificationItem | any) {
  }
}

export class SuccessLoadNotifications implements Action {
  readonly type = UserNotificationsActionsType.SuccessLoadNotifications;

  constructor(public payload?: NotificationList | any, public isUserLogged = false) {
  }
}

export class ResetNotifications implements Action {
  readonly type = UserNotificationsActionsType.ResetNotifications;
}


export type UserNotificationsActionsUnion =
  LoadNotifications |
  SuccessLoadNotifications |
  RestoreNotifications |
  ResetNotifications;

export const UserNotificationsActions = {
  LoadNotifications,
  RestoreNotifications,
  ResetNotifications,
  SuccessLoadNotifications,
};
