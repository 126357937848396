<ng-container *ngIf="mobileMode; then mobileContainer; else desktopContainer"></ng-container>

<ng-template #mobileContainer>
  <div class="close" (click)="clickRow(togglePopupMethod)">
    <figure class="close-popup fly-pointer">
      <img [src]="constructBaseUrl('/assets/cross.svg')" class='close-popup-img' alt="close" title="close">
    </figure>
  </div>

  <div class="empty-places empty-places__mobile">
    <div class="user-sidebar__sub-items">
      <div class="user-sidebar__item"
           *ngFor="let item of optionData; let i=index; let last=last"
           (click)="clickRow(item.action, item.value, item.pageType)">
        <span [ngClass]="{ 'border-top-radius': i===0,  'border-bottom-radius': last}">
          <div class="popup-row popup-row__mobile">
            <div class="popup-row-icon popup-row-icon__mobile" [class.add-popup]="isAddPopup"
                 [class.full-img]="item?.fullImg">
              <ng-container
                *ngTemplateOutlet="item.icons?.length > 0 ? multiIconContainer : singleIconContainer; context: {item: item}"></ng-container>
              <span class="badge" *ngIf="item.badgeText">{{ item.badgeText }}</span>
            </div>
            <div class="popup-row-text">{{ item.title }}</div>
          </div>
          <span class="user-sidebar__arrow">
            <img alt="" [src]="constructBaseUrl('/assets/arrow-next.svg')">
          </span></span>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #desktopContainer>
  <div class="empty-places" (click)="clickRow(togglePopupMethod)"></div>
  <div id="popup"
       class="popup"
       [ngClass]="{'moveToRight': moveToRight}">

    <div class="popup-content" [class.w-max]="maxWidth">
      <div class="popup-row"
           *ngFor="let item of optionData; let i=index"
           (click)="clickRow(item.action, item.value, item.pageType)"
           [class.active]="item.title === selectedOption"
           [class.upperLine]="item?.upperLine"
           (mouseenter)="setActive(item.title)">
        <div class="popup-row-icon" [class.add-popup]="isAddPopup" [class.full-img]="item?.fullImg">
          <ng-container
            *ngTemplateOutlet="item.icons?.length > 0 ? multiIconContainer : singleIconContainer; context: {item: item}"></ng-container>
          <span class="badge" *ngIf="item.badgeText">
            <span class="badge-content">{{ item.badgeText }}</span>
          </span>
        </div>
        <div class="popup-row-text" [style.color]="item?.color">{{ item.title }}</div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiIconContainer let-item="item">
  <div class="gallery-grid-container" [class.one-picture]="item.icons.length === 1">
    <div class="image-item grid-container-item" *ngFor="let icon of item.icons">
      <img alt="pic" src="{{constructMediaURL(icon)}}">
    </div>
  </div>
</ng-template>

<ng-template #singleIconContainer let-item="item">
  <img class="popup-icon" [ngClass]="item.class || ''"
       [src]="constructBaseUrl(item.icon.indexOf('/') > -1 ? item.icon : '/assets/icons/'+item.icon)"
       alt="{{item.title}}">
</ng-template>
