import { AuthActionsTypes, AuthActionsUnion } from './auth.actions';

export interface AuthState {
  currentCoordinates: {
    lat: number;
    lng: number;
  };
  loggedIn: boolean;
  token: string;
  home: {
    id: number;
    name: string;
    user?: any;
    coordinates: string;
    population: number;
    popular?: any;
    countryCode?: string;
    iataCode?: string;
    wasPlaceFormOpened?: boolean;
  };
  social: { name: string; url: string }[];
}

const initialState: AuthState = {
  currentCoordinates: null,
  loggedIn: false,
  token: null,
  home: null,
  social: null
};

export function reducer(state: AuthState = initialState, action: AuthActionsUnion) {
  switch (action.type) {
    case AuthActionsTypes.SetSessionData:
      return {
        ...state,
        ...action.payload,
        // TODO: should be refactored! Move data mapper to service
        currentCoordinates: action.payload.home.coordinates.split(',').reduce((acc, cur, i) => {
          i === 0 ? acc['lat'] = Number(cur) : acc['lng'] = Number(cur);
          return acc;
        }, Object.create(null)),
      };
    case AuthActionsTypes.SetSessionDataUserSettings:
      return {
        ...state,
        home: {
          ...state.home,
          user: {
            ...state.home.user,
            settings: action.payload
          }
        }
      };
    case AuthActionsTypes.SetAuthData:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        token: action.payload.token
      };
    case AuthActionsTypes.SetCurrentCoordinate:
      return {
        ...state,
        currentCoordinates: action.payload
      };
    case AuthActionsTypes.RestoreCurrentCoordinate:
      return {
        ...state,
        currentCoordinates: action.payload
      };
    case AuthActionsTypes.RestoreCurrentHome:
      if (action.payload?.user?.createdPlacesPics === undefined) {
        return {
          ...state,
          home: {
            ...action.payload,
            user: {
              ...action.payload.user,
              createdPlacesPics: state.home.user?.createdPlacesPics || []
            }
          }
        };
      }
      return {
        ...state,
        home: action.payload
      };
    default:
      return state;
  }
}

export const _getCurrentCoordinates = (state: AuthState) =>
  state.currentCoordinates;
export const _getPopularState = (state: AuthState) => state?.home?.popular;
export const _loggedIn = (state: AuthState) => state.loggedIn;
export const _getHomeData = (state: AuthState) => state.home;
