import { Component, EventEmitter, Input, Output } from '@angular/core';
import { constructBaseUrl } from "../../../libraries";

@Component({
  selector: 'app-fly-popup-option',
  templateUrl: './fly-popup-option.component.html',
  styleUrls: ['./fly-popup-option.component.scss']
})
export class FlyPopupOptionComponent {

  @Input() data;
  @Input() titleCollection: string;
  @Input() isPin: boolean;
  @Input() isBottomSheet: boolean;
  @Input() isFavorites: boolean;
  @Input() isProfile: boolean;
  @Input() classNameBySide: string;

  @Output() emitAction = new EventEmitter();

  public isShow: boolean = true;

  constructor() {
  }

  public clickRow(action) {
    this.emitAction.emit(action);
    this.toggleShow();
  }

  public toggleShow() {
    this.emitAction.emit('toggleOpenPopup');
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
