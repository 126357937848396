import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { FormArray, FormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { InfoService, MetaTagsService, SsrService } from '../../../services';
import {
  SetCityTo,
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../../../store/layer';
import { getAjaxState, getCurrentCoordinates, getHomeData, getSearchData, State } from '../../../store/reducers';
import { IUploadFile } from '../../upload/interface/interface';
import { PlaceService } from '../../../services/place.service';
import { doAsync } from '../../../libraries';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { Observable } from 'rxjs/internal/Observable';
import { delay, filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import { CityNew, Country, KeyValueInterface, PlacePinType, WhenDate } from '../../../interfaces';
import { UserSettingsNames } from '../../../interfaces/user';
import { Subject } from 'rxjs/internal/Subject';
import { GoogleMapsService } from '../../../services/google-maps.service';
import dayjs from 'dayjs';
import { ContactInfo } from "../../../interfaces/contact-info";
import { StaticService } from "../../../services/static.service";
import { of } from "rxjs/internal/observable/of";
import { PlaceStatusType } from "../../user-account/enums/place-status.enum";
import { UserCollectionsActions } from "../../../store/user-collections";
import { HelperService } from "../../../services/helper.service";
import * as fromAuth from "../../../store/auth/auth.actions";
import cloneDeep from 'lodash/cloneDeep';
import { getLinkByObject } from 'src/app/libraries/get-link-by-object';
import { DatePickerMode } from "../../../interfaces/datepicker-date";
import { StaticTransferStateKey } from "../../../enums/static-transfer-state-key.enum";

export interface ICity {
  googleCityId?: string;
  cityId?: number;
  name?: string;
  lat: number;
  lng: number;
}

interface IMediaContent {
  text?: string;
  pictures?: IUploadFile[];
  video?: IUploadFile[];
  isVisible?: boolean;
}

enum Step {
  StepOne = 1,
  StepTwo = 2,
  StepThree = 3,
}

export const typeMedia = {
  photo: 'photo',
  video: 'video'
};

declare var google;

@Component({
  selector: 'app-your-own-place',
  templateUrl: './your-own-place.component.html',
  styleUrls: ['./your-own-place.component.scss']
})
export class YourOwnPlaceComponent implements OnInit, OnDestroy {

  public readonly typeFormControlName = 'type';
  public readonly maxDescriptionLength = 4000;
  public readonly maxImages = 12;
  public readonly maxVideos = 3;
  public address;
  public ajaxState$: Observable<any>;
  public alertMessage: string[];
  public allCity: CityNew[];
  public cannotFindCity = false;
  public city: ICity;
  public coordinates: any;
  public countries: Country[];
  public createdPlace: any;
  public errorSubmit: boolean;
  public googleCityId: string;
  public homeCity: any;
  public homeCityId = null;
  public isAccountPublic: any;
  public isLoadForm = true;
  public isShowToastr = false;
  public isSubmit = false;
  public lat = 0;
  public lng = 0;
  public mapApiLoaded$: Observable<boolean>;
  public placeErrorsArray: string[] = [];
  public placeForm: UntypedFormGroup;
  public selectCountry;
  public sessionToken: any;
  public contactInfo: ContactInfo;
  public visible = true;
  public videoAccept = this.placeService.videoAccept;
  public photoAccept = this.placeService.photoAccept;
  public isShowDateContainer = true;
  public isReviewsDisabledContainer = false;
  public isShowContactInfoContainer = false;
  public tagGroup: any;
  public tags: any[];
  public isSetMarker: boolean;
  public isSetPlace: boolean;
  public typeMedia = typeMedia;
  public datePickerMode = [DatePickerMode.Date];
  public description: IMediaContent[];
  public canAddMediaBlock: boolean;
  public canRemoveMediaBlock: boolean[] = [];
  public limitImages: number;
  public limitVideos: number;
  public isModalNoticeData: {
    isShow: boolean,
    title: string,
    text: string
  };
  public datePickerValue: WhenDate = {
    type: DatePickerMode.Date,
    date: dayjs()
  };
  public placeErrors = {
    name: {
      id: 'nameContainer',
      message: '',
      example: 'Title is required.',
      step: Step.StepOne
    },
    description: {
      id: 'descriptionContainer',
      message: '',
      example: `Description is required.`,
      step: Step.StepOne
    },
    descriptions: [],
    date: {
      id: 'dateContainer',
      message: '',
      example: `Date is required.`,
      step: Step.StepThree
    },
    cityId: {
      id: 'locationContainer',
      message: '',
      example: 'Location is required.',
      step: Step.StepTwo
    },
    googleCityId: {
      id: 'locationContainer',
      message: '',
      example: 'Please agree public Personal Page.',
      step: Step.StepTwo
    },
    media: {
      id: 'mediaContainer',
      message: '',
      example: 'Media files (photos or videos) are required.',
      step: Step.StepOne
    },
    photo: {
      id: 'photoContainer',
      message: '',
      example: 'Files is required (max. ' + this.maxVideos + ').',
      step: Step.StepOne
    },
    video: {
      id: 'videoContainer',
      message: '',
      example: 'Files is required (max. ' + this.maxVideos + ').',
      step: Step.StepOne
    },
    tag: {
      id: 'tagContainer',
      message: '',
      step: Step.StepThree
    },
    journey: {
      id: 'journeyContainer',
      message: '',
      step: Step.StepThree
    },
    setting: {
      id: 'settingContainer',
      message: '',
      step: Step.StepThree
    },
    contactInfo: {
      id: 'contactContainer',
      message: '',
      example: 'Contact info is required.',
      step: Step.StepThree
    },
    checkboxCreate: {
      id: 'policyContainer',
      message: '',
      example: 'Please agree Privacy Policy and Terms and Conditions.',
      step: Step.StepThree
    },
    checkboxPublish: {
      id: 'publishContainer',
      message: '',
      example: 'Please agree public Personal Page.',
      step: Step.StepThree
    },
    place_id: {
      message: ''
    },
    place: {
      message: ''
    }
  };
  public getLinkByObject = getLinkByObject;
  protected Step = Step;

  protected descriptionValidation = Validators.compose([
    this.maxLengthTinymceValidator(this.maxDescriptionLength)
  ]);
  protected $destroyed = new Subject<void>();

  private descEditor: any;
  private currentStep = Step.StepOne;

  @Input() typeFormValue: string;

  @Output() removeTypeFormValue = new EventEmitter<void>();
  @Output() discard = new EventEmitter<void>();

  @ViewChild('titleRef') titleRef: ElementRef;
  @ViewChild('contactContainer') contactContainer: ElementRef;
  @ViewChild('nameContainer') nameContainer: ElementRef;
  @ViewChild('locationContainer') locationContainer: ElementRef;
  @ViewChild('tagContainer') tagContainer: ElementRef;
  @ViewChild('settingContainer') settingContainer: ElementRef;
  @ViewChild('policyContainer') policyContainer: ElementRef;
  @ViewChild('publishContainer') publishContainer: ElementRef;
  @ViewChild('photoContainer') photoContainer: ElementRef;
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('journeyContainer') journeyContainer: ElementRef;
  @ViewChildren('descriptionContainer', {read: ViewContainerRef}) descriptionContainer: QueryList<ViewContainerRef>;

  constructor(
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    protected el: ElementRef,
    protected infoService: InfoService,
    protected readonly googleMapsService: GoogleMapsService,
    protected placeService: PlaceService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected ssrService: SsrService,
    protected metaTagService: MetaTagsService,
    public staticService: StaticService,
    protected formBuilder: FormBuilder,
    protected helperService: HelperService,
  ) {
    this.mapApiLoaded$ = this.googleMapsService.isApiLoaded$;
  }

  ngOnInit() {
    this.staticService.editPlace = false;
    const placeId = this.route.snapshot.params['id'];
    this.limitImages = this.maxImages;
    this.limitVideos = this.maxVideos;

    this.createForm();
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));

    const httpParams = new HttpParams();
    httpParams.append('filter[expand]', 'tags');

    if (!this.isUpdateForm()) {
      this.store$.pipe(
        select(getCurrentCoordinates),
        takeUntil(this.$destroyed)
      ).subscribe((result) => {
        if (result.lat && result.lng) {
          this.coordinates = result;
          this.lat = +result.lat;
          this.lng = +result.lng;
        }
      });
    }

    combineLatest([
      this.infoService.getCountryList((new HttpParams()).set('expand', 'cities'))
    ]).pipe(
      takeUntil(this.$destroyed)
    ).subscribe(([data]) => {
      this.countries = data.items;
      this.isLoadForm = false;

      this.metaTagService.resolver({
        route: 'place-add',
        name: 'Add a New Place',
        description: ''
      });

      if (placeId) {
        this.metaTagService.resolver({
          route: 'place-add',
          name: 'Update Place',
          description: ''
        });
        this.getPlaceById(placeId);
        this.removeTypeControlToPlaceForm();
        this.checkMediaCount();
      }
    });

    this.store$.dispatch(new SetCityTo(null));
    this.getSessionToken();
    if (!placeId) {
      this.addItemToDescription();
    }

    this.store$.pipe(
      select(getHomeData),
      takeUntil(this.$destroyed)
    ).subscribe((result) => {
      if (result && result.user) {
        if (result?.user?.contactInfo) {
          this.contactInfo = result.user.contactInfo;
          for (let item in this.contactInfo) {
            if (this.contactInfo[item]) {
              this.setFormControl({key: item, value: this.contactInfo[item]});
            }
          }
        } else {
          this.contactInfo = {};
        }
        this.staticService.userInfo = cloneDeep(result.user);
        if (result.user.settings) {
          const setting = result.user.settings.find(s => s.id === UserSettingsNames.IsAccountPublic);
          this.isAccountPublic = setting.value;
          this.placeForm.patchValue({
              checkboxPublish: !!this.isAccountPublic,
            },
            {
              emitEvent: false
            });
        }

        if (result.user.homeCity) {
          this.homeCityId = result.user.homeCity.id;
          this.homeCity = result.user.homeCity;
        }
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  get getDescription(): FormArray {
    return this.placeForm.get('description') as FormArray;
  }

  public createForm(fields: any = {}): void {
    this.placeForm = new UntypedFormGroup({
      placeId: new UntypedFormControl(''),
      tagId: new UntypedFormControl(''),
      cityId: new UntypedFormControl(''),
      tagName: new UntypedFormControl(''),
      tagGroup: new UntypedFormControl(''),
      tagGroupId: new UntypedFormControl(null),
      collectionId: new UntypedFormControl(''),
      date: new UntypedFormControl(fields.date || ''),
      name: new UntypedFormControl('', Validators.required),
      description: new FormArray([], Validators.required),
      isReviewsDisabled: new UntypedFormControl(0),
      checkbox: new UntypedFormControl(''),
      checkboxCreate: new UntypedFormControl('', Validators.required),
      checkboxPublish: new UntypedFormControl(this.isAccountPublic ? true : '', Validators.requiredTrue),
      webSite: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      whatsapp: new UntypedFormControl(''),
      isContactInfoEnabled: new UntypedFormControl(0),
      businessHours: new UntypedFormControl(''),
    });
  }

  public maxLengthTinymceValidator(maxLength: number): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return this.descEditor && this.descEditor.plugins.wordcount && this.descEditor.plugins.wordcount.body.getCharacterCount() > maxLength
        ? {maxLengthTinymce: {maxLength: maxLength}}
        : null;
    };
  }

  public getSessionToken(): void {
    if (this.ssrService.isBrowser()) {
      this.mapApiLoaded$
        .pipe(
          filter((value: boolean) => value),
          take(1)
        )
        .subscribe(() => {
          this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        });
    }
  }

  public setGoogleCityIdPlace(data: any, key?: string): void {
    if (data) {
      const {googleCityId} = data;
      (key) ?
        this[key] = googleCityId :
        this.googleCityId = googleCityId;
    } else {
      this.googleCityId = null;
    }
  }

  public getGeocodeMarker(data: any): void {
    if (data) {
      this.lat = data.lat;
      this.lng = data.lng;
      if (data.name &&
        this.cannotFindCity && !this.googleCityId ||
        !this.cannotFindCity && !this.placeForm.controls.cityId.value) {
        this.city = {
          name: data.name,
          lat: data.lat,
          lng: data.lng
        };
      }
      if (data.cityId) {
        this.placeForm.controls.cityId.patchValue(data.cityId);
      }
    }
    if (this.staticService.editPlace || !data) {
      this.placeForm.controls.cityId.patchValue(null);
    }
    this.checkValidationByName('location');
    this.setGoogleCityIdPlace(data);
  }

  public getGoogleCity(data: any): void {
    this.getGeocodeMarker(data);
    this.setGoogleCityIdPlace(data);
  }

  public deleteFile(file: IUploadFile, index: number, type: string = this.typeMedia.photo): any {
    if (file?.id) {
      if (type === this.typeMedia.photo) {
        this.description[index].pictures = this.description[index].pictures.filter(item => file.id !== item.id);
      } else {
        this.description[index].video = this.description[index].video.filter(item => file.id !== item.id);
      }
    } else {
      if (type === this.typeMedia.photo) {
        this.description[index].pictures = this.description[index].pictures.filter(item => file.thumb !== item.thumb);
      } else {
        this.description[index].video = this.description[index].video.filter(item => file.path !== item.path);
      }
    }
    this.checkMediaCount();
  }

  public onCannotFindCityEmit(cannotFindCity) {
    this.cannotFindCity = cannotFindCity;
  }

  public onSubmit(): void {
    if (!this.validate()) {
      return;
    }
    const data = this.createRequestData();
    let subscription;

    this.isSubmit = true;
    // It's disabled initially in upload form to not show two progress bars on loading files
    this.helperService.ajaxLoaderVisible$.next(true);

    if (this.staticService.editPlace) {
      const editPlace = {
        description: data.description,
        name: data.name,
        date: data.date,
        tagName: data.tagName,
        tagId: data.tagId,
        tagGroup: data.tagGroup,
        tagGroupId: data.tagGroupId,
        collectionId: data.collectionId,
        isReviewsDisabled: data.isReviewsDisabled,
        cityId: data.cityId,
        lat: data.lat,
        lng: data.lng,
        googleCityId: data.googleCityId,
        googleSessionToken: data.googleSessionToken,
        webSite: data.webSite,
        address: data.address,
        phone: data.phone,
        email: data.email,
        whatsapp: data.whatsapp,
        isContactInfoEnabled: data.isContactInfoEnabled ? 1 : 0,
        businessHours: data.businessHours,
      };
      subscription = this.saveData('edit', editPlace);
    } else {
      subscription = this.saveData('add', data);
    }

    subscription
      .pipe(takeUntil(this.$destroyed))
      .subscribe(
        (result: HttpResponse<any>) => {
          if (this.ssrService.isBrowser()) {
            localStorage.setItem('previousUrl', location.pathname);
          }
          this.createdPlace = result.body;
          if (!this.isAccountPublic) {
            this.updateIsPublicSettings();
          }
          this.updateCollectionsState(
            this.createdPlace,
            +data.collectionId,
            this.staticService.editPlace ? +this.staticService.editPlace.collectionId : 0,
          );
          this.store$.dispatch(
            new UserCollectionsActions.UpdatePinInfo(
              this.createdPlace,
              +data.collectionId
            )
          );
          this.makePostProcessing();
        },
        (error) => {
          this.isSubmit = false;
          this.helperService.ajaxLoaderVisible$.next(false);
          this.staticService.editPlace = false;
          this.errorSubmit = true;
          this.isSetMarker = false;
          this.handleServerError(error);
        }
      );
  }

  public clickDiscard(): void {
    this.discard.emit();
    this.staticService.editPlace = false;
  }

  public showAlert(text: string[]): void {
    this.isShowToastr = true;
    this.alertMessage = text;
    doAsync(() => this.isShowToastr = false, 6000);
  }

  public setRequestErrors(errors: any[]): void {
    let messages = [];
    if (errors && errors.length > 0) {
      errors.forEach(err => {
        this.setErrorMessageEmitter(err.message, err.field);
        messages = messages.concat(err.message)
      });
      this.showAlert(messages);
    }
  }

  public handleDescEditorInit({editor}) {
    this.descEditor = editor;
  }

  public getFormControl(name: string): UntypedFormControl {
    return <UntypedFormControl>this.placeForm.get(name);
  }

  public setFormControl(data: KeyValueInterface): void {
    this.placeForm.patchValue({[data.key]: data.value});
  }

  public setFormControlArray(event: KeyValueInterface[]) {
    event.forEach(item => {
      if (item.key === "tagName") {
        this.setLimitToTags(item);
      }
      this.setFormControl({key: item.key, value: item.value})
    })
  }

  public toggleContainer(value: string) {
    this[value] = !this[value];

    if (value === 'isShowDateContainer') {
      if (this.isShowDateContainer) {
        this.onSelectDate([this.datePickerValue]);
      } else {
        this.placeForm.controls.date.patchValue(null);
      }
    }
    if (value === 'isReviewsDisabledContainer') {
      this.placeForm.controls.isReviewsDisabled.patchValue(!!this.isReviewsDisabledContainer ? 1 : 0);
    }
    if (value === 'isShowContactInfoContainer') {
      this.placeForm.controls.isContactInfoEnabled.patchValue(!!this.isShowContactInfoContainer ? 1 : 0);
    }
  }

  public onSelectDate(event) {
    this.placeForm.controls.date.patchValue(event[0]?.date.format('YYYY-MM-DD'));
    this.datePickerValue = {
      type: DatePickerMode.Date,
      date: event[0]?.date
    };
  }

  public setMarkerEmit(event: boolean) {
    this.checkValidationByName('location');
    this.isSetMarker = event;
  }

  public setPlaceEmit(event: boolean) {
    this.checkValidationByName('location');
    this.isSetPlace = event;
  }

  public setErrorMessageEmitter(text: string, idError: string, index?: number) {
    if (idError && index !== undefined
      && (idError === typeMedia.photo || idError === typeMedia.video)
      && this.placeErrors.descriptions[index]) {
      this.placeErrors.descriptions[index][idError].message = text;
      if (this.currentStep > this.placeErrors.description.step) {
        this.currentStep = this.placeErrors.description.step;
      }
    }
    if (idError && this.placeErrors[idError]) {
      this.placeErrors[idError].message = text;
      if (text) {
        this.scrollToFirstInvalidControl();
      }
      if (this.placeErrors[idError].step && this.currentStep > this.placeErrors[idError].step) {
        this.currentStep = this.placeErrors[idError].step;
      }
    }
  }

  public removeError(idError: string) {
    this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== idError);
    this.setErrorMessageEmitter('', idError);
  }

  public validate(step: Step = null): boolean {
    this.alertMessage = [];
    this.placeErrorsArray = [];
    this.errorSubmit = false;
    if ((step === null || step === this.placeErrors.name.step) && this.placeForm.controls['name']?.invalid) {
      this.placeErrors.name.message = this.placeErrors.name.example;
      this.placeErrorsArray.push('name');
      if (this.currentStep > this.placeErrors.name.step) {
        this.currentStep = this.placeErrors.name.step;
      }
    }
    if ((step === null || step === this.placeErrors.description.step) && this.placeForm.controls['description']?.invalid) {
      this.description.forEach((description, index) => {
        this.checkDescriptionValidation(index)
      });
      if (this.currentStep > this.placeErrors.description.step) {
        this.currentStep = this.placeErrors.description.step;
      }
    }
    if ((step === null || step === this.placeErrors.checkboxCreate.step) && this.placeForm.controls['checkboxCreate']?.invalid) {
      this.placeErrors.checkboxCreate.message = this.placeErrors.checkboxCreate.example;
      this.placeErrorsArray.push('checkboxCreate');
      if (this.currentStep > this.placeErrors.checkboxCreate.step) {
        this.currentStep = this.placeErrors.checkboxCreate.step;
      }
    }
    if ((step === null || step === this.placeErrors.checkboxPublish.step) && this.placeForm.controls['checkboxPublish']?.invalid) {
      this.placeErrors.checkboxPublish.message = this.placeErrors.checkboxPublish.example;
      this.placeErrorsArray.push('checkboxPublish');
      if (this.currentStep > this.placeErrors.checkboxPublish.step) {
        this.currentStep = this.placeErrors.checkboxPublish.step;
      }
    }
    if ((step === null || step === this.placeErrors.cityId.step) && (!this.lat || !this.lng)) {
      this.placeErrors.cityId.message = this.placeErrors.cityId.example;
      this.placeErrorsArray.push('cityId');
      if (this.currentStep > this.placeErrors.cityId.step) {
        this.currentStep = this.placeErrors.cityId.step;
      }
    }
    if (step === null || step === this.placeErrors.media.step) {
      this.checkMediaValidation();
    }
    if (step === null || step === this.placeErrors.cityId.step) {
      this.checkLocationValidation();
    }
    if (
      this.placeForm.invalid && (step === null || step === this.placeErrors.checkboxCreate.step)
      || this.placeErrorsArray?.length
    ) {
      console.log(this.placeForm.errors);
      this.scrollToFirstInvalidControl();
      return false;
    } else {
      this.resetPlaceErrors();
      return true;
    }
  }

  public checkValidationByName(idError: string, indexValidation?: number) {
    if (idError === 'file' || idError === 'media' || idError === 'photo' || idError === 'video') {
      this.checkMediaCount();
      this.checkMediaValidation(indexValidation);
    } else if (idError === 'cityId') {
      this.checkLocationValidation();
    } else {
      if (this.placeForm.controls[idError]?.invalid) {
        this.placeErrors[idError].message = this.placeErrors[idError].example;
        this.placeErrorsArray.push(idError);
        this.placeForm.controls[idError].invalid
      } else {
        this.removeError(idError);
      }
    }
  }

  protected get placeId() {
    return this.placeForm.controls.placeId as UntypedFormControl;
  }

  protected createRequestData(): any {
    const {
      name,
      cityId,
      tagId,
      collectionId,
      tagGroup,
      tagGroupId,
      tagName,
      date,
      isReviewsDisabled,
      webSite,
      address,
      phone,
      email,
      whatsapp,
      isContactInfoEnabled,
      businessHours,
    } = this.placeForm.value;
    const data = {
      description: JSON.parse(JSON.stringify(this.description)),
      name: name,
      date: date,
      isReviewsDisabled: +isReviewsDisabled,
      cityId:
        this.cannotFindCity
          ? null
          : cityId,
      googleCityId: this.googleCityId,
      googleSessionToken: this.sessionToken[Object.keys(this.sessionToken)[0]],
      tagId: tagId,
      collectionId: collectionId,
      tagGroup: tagGroup,
      tagGroupId: tagGroupId,
      tagName: tagName,
      lat: this.lat,
      lng: this.lng,
      webSite: webSite,
      address: address,
      phone: phone,
      email: email,
      whatsapp: whatsapp,
      isContactInfoEnabled: isContactInfoEnabled,
      businessHours: businessHours,
    };

    data.description = data.description.map(description => {
      delete description['galleries'];

      return {
        ...description,
        pictures: description.pictures?.length ? description.pictures.map(file => file.id || file.url || file.thumb) : [],
        video: description.video?.length ? description.video.map(file => file.id || file.poster?.thumb) : []
      }
    })
    return data;
  }

  protected resetPlaceErrors(): void {
    this.placeErrorsArray = [];
    for (const item in this.placeErrors) {
      this.placeErrors[item].message = '';
    }
  }

  protected scrollToFirstInvalidControl() {
    this.placeForm.markAsTouched();
    let firstErrorParam = '';

    for (const item in this.placeErrors) {
      if (this.placeErrors[item]?.message) {
        firstErrorParam = this.placeErrors[item].id;
        break;
      }
    }

    if (this[firstErrorParam]?.nativeElement) {
      let top = YourOwnPlaceComponent.getTopOffset(this[firstErrorParam].nativeElement);

      if ((!this.lat && !this.lng) || this.placeErrors.cityId.message) {
        const geoTop = this.locationContainer?.nativeElement?.offsetTop;

        if (geoTop < top) {
          top = geoTop;
        }
      }

      if (this.ssrService.isBrowser()) {
        window.scroll({top: top - this.getAdditionalOffset(), left: 0, behavior: 'smooth'});
      }
    }
  }

  protected checkMediaValidation(indexValidation?: number) {
    this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== 'media');
    let error = false;
    this.description.forEach((description, index) => {
      if (this.placeErrors.descriptions[index]) {
        this.placeErrors.descriptions[index].video.message = '';
        this.placeErrors.descriptions[index].photo.message = '';
        this.placeErrors.descriptions[index].media.message = '';
      }
      const isMoveToDescriptionError = (indexValidation && indexValidation >= index || !indexValidation === undefined);
      if (!(description.pictures?.length || description.video?.length)) {
        if (!(description.pictures?.length && description.video?.length)) {
          this.placeErrors.descriptions[index].media.message = this.placeErrors.media.example;
          error = true;
        } else if (!description.video?.length) {
          this.placeErrors.descriptions[index].video.message = 'Files is required (max. ' + this.maxVideos + ').';
          error = true;
        } else if (!description.pictures?.length) {
          this.placeErrors.descriptions[index].photo.message = 'Files is required (max. ' + this.maxImages + ').';
          error = true;
        }
        if (error) {
          this.currentStep = Step.StepOne;
        }
        if (error && isMoveToDescriptionError) {
          this.moveToDescriptionError(+index);
        }
        if (error) {
          this.placeErrorsArray.push('media');
        }
      }
    })
  }

  private static getTopOffset(controlEl: HTMLElement): number {
    if (controlEl) {
      return controlEl.getBoundingClientRect().top + window.scrollY;
    } else {
      return 0;
    }
  }

  private getAdditionalOffset(): number {
    if (this.staticService.isMobileOrTablet()) {
      const header = document.querySelector('app-static-header .static__search-panel');

      return header.clientHeight;
    } else {
      const header = document.querySelector('app-static-header .header__content');
      const labelOffset = 70;

      return labelOffset + header.clientHeight;
    }
  }

  private removeTypeControlToPlaceForm(): void {
    this.placeForm && this.placeForm.removeControl(this.typeFormControlName);
  }

  private setFormValues(place): void {
    const country = this.countries?.find(c => c.id === place.city?.country.id);
    if (!place.city.hasPage && country) {
      country.cities.push(place.city);
    }

    this.selectCountry = country && country.id;
    this.allCity = country && country.cities;
    const city = this.allCity?.find(c => c.id === place.city.id);
    if (city) {
      this.city = {
        name: city.name + ', ' + country.name,
        lat: city.lat,
        lng: city.lng,
      };
    }

    this.tagGroup = place.tagGroup;
    this.tags = place.tags;
    this.isReviewsDisabledContainer = place.isReviewsDisabled;
    this.description = place.descriptionBlocks;

    this.placeForm.patchValue({
      tagId: this.tags?.map(tag => tag.id),
      collectionId: place.collectionId,
      tagGroup: place.tagGroup,
      tagName: place.tagName || null,
      name: place.name,
      date: place.date,
      description: place.descriptionBlocks,
      url: place.url,
      cityId: place.city.id,
      isReviewsDisabled: +place.isReviewsDisabled,
      checkbox: false,
      checkboxCreate: false,
      checkboxPublish: !!this.isAccountPublic,
      contactInfo: place.contactInfo,
      isContactInfoEnabled: place.isContactInfoEnabled,
      webSite: place?.user?.contactInfo?.webSite,
      address: place?.user?.contactInfo?.address,
      phone: place?.user?.contactInfo?.phone,
      email: place?.user?.contactInfo?.email,
      whatsapp: place?.user?.contactInfo?.whatsapp,
      businessHours: place?.user?.contactInfo?.businessHours,
    });

    this.homeCityId = place.homeCityId;
    this.lat = place.lat;
    this.lng = place.lng;
    this.cannotFindCity = false;

    this.isShowDateContainer = !!place.date;

    if (this.isShowDateContainer) {
      this.datePickerValue = {
        type: DatePickerMode.Date,
        date: dayjs(place.date)
      };
    }
    this.checkToAddMediaBlock();
  }

  private getPlaceById(placeId): void {
    const stateKey = `${StaticTransferStateKey.PlaceInfoKey}_${placeId}`;
    this.store$
      .pipe(
        take(1),
        select(getSearchData),
        mergeMap((categoryData) => {
          const ssrCacheData = this.ssrService.getState(stateKey);
          if (!ssrCacheData) {
            let httpParams = new HttpParams();
            httpParams = httpParams.set(
              'expand',
              'pictures,city.hasPage,tags,iataCodeFrom,city.country,user,descriptionBlocks,pinned,video,country,' +
              'tagGroup,tagGroupId,authorialCollection'
            );
            httpParams = httpParams.set('filter[cityFrom]', categoryData.cityFrom.id.toString());
            httpParams = httpParams.set('filter[paxCount]', categoryData.passengers.toString());
            httpParams = httpParams.set('filter[flightType]', transformTripTypeToApi(categoryData.tripType));
            httpParams = httpParams.set('filter[dateType]', transformDateToApiDateType(categoryData.date).toString());
            httpParams = httpParams.set('filter[when]', transformWhenDateToApiDateType(categoryData.date));
            httpParams = httpParams.set('filter[return]', transformReturnToApiDateType(categoryData.date) || '');

            return this.infoService.getPlace(placeId, httpParams);
          } else {
            this.ssrService.removeState(stateKey);
            return of(ssrCacheData);
          }
        }),
        takeUntil(this.$destroyed)
      )
      .subscribe((place) => {
        this.ssrService.setState(stateKey, place);
        this.staticService.editPlace = place;
        this.setFormValues(place);
        this.placeId.patchValue(placeId);
      });
  }

  private checkLocationValidation() {
    this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== 'cityId');
    if (!this.lat || !this.lng) {
      this.placeErrors.cityId.message = this.placeErrors.cityId.example;
      if (this.currentStep > this.placeErrors.cityId.step) {
        this.currentStep = this.placeErrors.cityId.step;
      }
      this.placeErrorsArray.push('cityId');
    }
  }

  private saveData(action: string, data): Observable<any> {
    let httpParams = new HttpParams();
    //to use data in place info module
    httpParams = httpParams.set(
      'expand',
      'similarPlacesLimit,descriptionBlocks,pictures,iataCodeFrom,city.country,city.flights,tags,category,pinned,' +
      'similarPlaces.city.country,similarPlaces.user,similarPlaces.city,similarPlaces.country.flights,' +
      'similarPlaces.pictures,user.homeCity.country,similarPlaces.tags,similarPlaces.pinned,reviews.user.picture,' +
      'reviews.user.homeCity.country,reviews.pictures,reviews.video,reviewsTotalCount,canAddReview,country,date,' +
      'video,similarPlaces.video,similarPlaces.city.hasPage,tagGroup,authorialCollections,collection,moderationStatus,' +
      'user.picture'
    );

    if (action === 'add') {
      return this.placeService.createPlace(data, httpParams);
    } else {
      return this.placeService.updatePlace(this.placeId.value, data, httpParams);
    }
  }

  public removeMediaBlock(index: number) {
    this.removeItemFromDescription(index);
    this.checkToAddMediaBlock();
  }

  public removeItemFromDescription(index: number) {
    this.description.splice(index, 1);
    this.placeErrors.descriptions.splice(index, 1);
    this.removeDescriptionForm(index);
    if (this.description?.length === 0) {
      this.addMediaBlock();
    }
  }

  protected removeDescriptionForm(index: number) {
    const description = this.getDescription
    description.removeAt(index);
  }

  public addMediaBlock(): void {
    this.addItemToDescription();
    this.checkToAddMediaBlock();
  }

  checkDescriptionValidation(index: number) {
    this.addDescriptionForm();
    const errName = 'descriptions' + index;
    if (!(this.placeErrors.descriptions && this.placeErrors.descriptions[index])) {
      this.placeErrors.descriptions[index] = this.getEmptyErrorDescription();
      this.checkMediaCount();
    }
    if (this.placeForm.controls['description']?.invalid &&
      (this.placeForm.controls['description'].value?.length)) {
      const descErrors = (<FormArray>(<FormArray>this.getDescription).controls[index])?.controls['text']?.errors;
      if (descErrors?.required) {
        this.placeErrors.descriptions[index].message = this.placeErrors.description.example;
      }
      if (descErrors?.maxLengthTinymce) {
        this.placeErrors.descriptions[index].message =
          `Sorry, the text length should not exceed  ${this.maxDescriptionLength} characters limit.`;
      }
      this.checkMediaCount();
      this.placeErrorsArray.push(errName);
      this.moveToDescriptionError(+index)
    } else {
      this.placeErrors.descriptions[index].message = '';
      this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== errName);
    }

    this.checkToAddMediaBlock();
  }

  checkToAddMediaBlock(): void {
    this.description.forEach((item, key) => {
      this.canRemoveMediaBlock[key] = !!(
        this.description?.length > 1 ||
        item &&
        (item.text ||
          (item?.video?.length || item?.pictures?.length)))
    });

    const lastDescription = this.description?.length && this.description[this.description?.length - 1];
    this.canAddMediaBlock = !!((lastDescription?.video?.length || lastDescription?.pictures?.length) &&
      this.limitImages > 0
    );
  }

  public addItemToDescription() {
    if (!this.description) {
      this.description = [];
    }

    this.description.push({
      text: '',
      pictures: [],
      video: [],
    });
    this.placeErrors.descriptions.push(this.getEmptyErrorDescription());
    this.addDescriptionForm();
  }

  private getEmptyErrorDescription() {
    return {
      message: '',
      video: {message: ''},
      photo: {message: ''},
      media: {message: ''},
    };
  }

  protected addDescriptionForm() {
    const description = this.getDescription
    while (description.length) {
      description.removeAt(0);
    }
    this.description.forEach((data: IMediaContent) => {
      const group = this.formBuilder.group({
        text: [
          data.text,
          this.descriptionValidation,
        ],
        pictures: [data.pictures],
        video: [data.video]
      });
      this.getDescription.push(group);
    });
  }

  private checkMediaCount() {
    let videoCount = 0;
    let imagesCount = 0;
    if (!this.description) {
      this.addItemToDescription();
    }
    this.description.forEach(description => {
      videoCount += description?.video?.length;
      imagesCount += description?.pictures?.length;
    });

    this.limitVideos = this.maxVideos - videoCount;
    this.limitImages = this.maxImages - imagesCount;
  }

  private moveToDescriptionError(index: number) {
    const currentElement = this.descriptionContainer.get(+index).element.nativeElement;
    if (currentElement) {
      const top = YourOwnPlaceComponent.getTopOffset(currentElement);
      if (this.ssrService.isBrowser()) {
        window.scroll({
          top: top - this.getAdditionalOffset(),
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  public handleServerError(error) {
    if (error.error && error.error.length > 0) {
      error.error.forEach(err => {
        if (err.field === 'description') {
          if (this.currentStep > this.placeErrors.description.step) {
            this.currentStep = this.placeErrors.description.step;
          }
          for (let index in err.message) {
            const message = err.message[index];
            if (message?.length) {
              const messageKeys = Object.keys(message[0]);
              if (messageKeys?.length) {
                messageKeys.forEach(key => {
                  const currentErrorMessage = message[0][key][0];
                  this.moveToDescriptionError(+index)
                  if (!(this.placeErrors.descriptions && this.placeErrors.descriptions[index])) {
                    this.placeErrors.descriptions[index] = this.getEmptyErrorDescription();
                  }
                  if (key === 'text') {
                    this.placeErrors.descriptions[index].message = currentErrorMessage;
                  } else if (key === 'pictures') {
                    this.placeErrors.descriptions[index].photo.message = currentErrorMessage;
                  } else {
                    this.placeErrors.descriptions[index][key].message = currentErrorMessage;
                  }
                  this.placeErrorsArray.push('descriptions' + index);
                })
              }
            }
          }
        } else {
          const messages = error.error.map(err => err.message);
          this.showAlert(messages);
          this.setRequestErrors(error.error);
        }
      });
    }
    this.scrollToFirstInvalidControl();
  }

  private setLimitToTags(data: KeyValueInterface, limit = 255) {
    data.value = data.value.map(item => {
      return item.substring(0, limit);
    })
  }

  public goToProfilePlaces() {
    this.router.navigate(['/account'], {queryParams: {view: 'your-places'}});
  }

  public goToPlacesPage() {
    this.router.navigate(['/places/' + this.createdPlace?.urlKeywords + '/' + this.createdPlace?.id],
      {fragment: this.staticService.editPlace ? null : 'new', state: {place: this.createdPlace}});
  }

  protected makePostProcessing() {
    if (this.createdPlace.moderationStatus === PlaceStatusType.ClientStatusOnline) {
      this.goToPlacesPage();
    } else {
      this.isModalNoticeData = {
        isShow: true,
        title: `Thank you for ${this.staticService.editPlace ? 'updating ' : 'adding'} a new place!`,
        text: 'Please note that copyright and user-friendly checks will take some time. We\'ll inform you when it is approved shortly.'
      };
    }
  }

  protected updateCollectionsState(place, newCollectionId: number, oldCollectionId: number) {
    if (newCollectionId === oldCollectionId) {
      return;
    }
    //Place will be removed from pinned for old collection on the server side
    //In order to not call server just update state
    switch (true) {
      case oldCollectionId !== 0 && newCollectionId !== 0:
        this.store$.dispatch(
          new UserCollectionsActions.ChangePinCollection({
            pin: {oid: place.id, otype: place.otype},
            newCollectionId,
            oldCollectionId
          }));
        break;
      case oldCollectionId !== 0 && newCollectionId === 0:
        this.store$.dispatch(
          new UserCollectionsActions.RemovePin({
            oid: place.id,
            otype: place.otype,
            type: PlacePinType.WannaGo,
            collectionId: oldCollectionId,
          })
        );
        break;
      case oldCollectionId === 0 && newCollectionId !== 0:
        this.store$.dispatch(
          new UserCollectionsActions.AddPin(
            {oid: place.id, otype: place.otype, place: place},
            newCollectionId
          )
        );
        break;
    }
  }

  protected updateIsPublicSettings() {
    this.isAccountPublic = true;
    this.staticService.userInfo.settings.find(s => s.id === UserSettingsNames.IsAccountPublic).value = 1;
    this.store$.dispatch(new fromAuth.AuthActions.SetSessionDataUserSettings(this.staticService.userInfo.settings));
  }

  protected isUpdateForm() {
    return !!this.route.snapshot.params['id'];
  }

  protected getTitle(): string {
    if (this.staticService.isMobileOrTablet()) {
      return this.isUpdateForm() ? 'Update place' : 'Add a new place';
    } else {
      return this.isUpdateForm() ? 'Update place' : 'Add to Explorow';
    }
  }

  protected isStepForm(): boolean {
    return this.staticService.isMobileOrTablet() && !this.isUpdateForm();
  }

  protected isBlockVisible(step: Step): boolean {
    return !this.isStepForm() || this.currentStep === step;
  }

  protected increaseStep(): void {
    if (this.validate(this.currentStep)) {
      this.currentStep++;
      window.scrollTo(0, 0);
    }
  }

  protected decreaseStep(): void {
    this.currentStep--;
    window.scrollTo(0, 0);
  }

  protected isTextEditorVisible(item: IMediaContent, index: number): boolean {
    return !this.staticService.isMobileDesign() && index === 0 || (this.isUpdateForm() && item.text.trim() !== '') || item.isVisible;
  }

  protected uploadFile(file: IUploadFile, i: number, idError: string): void {
    this.checkValidationByName(idError, i);
    this.checkToAddMediaBlock();
  }

  protected updateFiles(files: IUploadFile[], index: number, type: string = this.typeMedia.photo): void {
    if (type === this.typeMedia.photo) {
      this.description[index].pictures = files;
    } else {
      this.description[index].video = files;
    }
  }
}
