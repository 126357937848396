<div class="add-wrapper position-relative tooltip-event-item">
  <div class="place-button decorate-b-b "
       [ngClass]="{'active': true}">
    <img [appBasesrc]="'/assets/icons/plus-round-gr.svg'"
         class="place-img"
         alt="active-place"
         (click)="openAddLink()">
  </div>
  <span class="tooltip-event" *ngIf="!isGuideVisible">Add a travel place or attraction to the map</span>
  <app-guide-tooltip [pageObject]="addPlaceType" (changeStateEmitter)="changeGuideState($event)"></app-guide-tooltip>
</div>
