import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DestinationType } from '../../../../enums/destination-type';
import { constructBaseUrl, doAsync } from '../../../../libraries';
import { StaticRouteType } from '../../enums/route-type';
import { Journey } from "../../../../interfaces/journey";
import { StaticService } from "../../../../services/static.service";
import { HelperService } from "../../../../services/helper.service";
import { SsrService } from "../../../../services";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { getCountryFlag } from "../../../../libraries/get-coutnry-flag";

export enum CategoryModeType {
  All = 'all',
  City = 'city',
  Categories = 'categories',
  Countries = 'countries',
  Place = 'place',
  Trends = 'trends'
}

export enum SelectMode {
  Single,
  Multiple,
}

@Component({
  selector: 'app-static-categories',
  templateUrl: './static-categories.component.html',
  styleUrls: ['./static-categories.component.scss'],
})
export class StaticCategoriesComponent implements AfterViewInit, OnChanges {

  @Input() categories: any[] = [];
  @Input() className = 'default';
  @Input() collectionId: number;
  @Input() currentPinCollection: Journey;
  @Input() groupRouteQuery: any;
  @Input() isShowMoreDots = true;
  @Input() isThirdLines: boolean;
  @Input() selectMode: SelectMode = null;
  @Input() mode: CategoryModeType;
  @Input() pageType: StaticRouteType;
  @Input() place: any;
  @Input() staticType: StaticRouteType;
  @Input() visibilityDelay = 700;

  @Output() clickCategoryEmit = new EventEmitter();

  @ViewChild('categoriesElem', {static: true}) public categoriesElem: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event && event.target && event.target.innerWidth) {
      this.setVisibleItems(this.categoriesElem);
    }
  }

  public openedMode: 'close' | 'thirdLines' | 'part' | 'all' = 'close';
  public isAllOpened = false;
  public isCategoriesLoad = false;
  public isShowMore = true;
  public leftShow: number;
  public staticRouteType = StaticRouteType;
  public topShow: string;
  public readonly categoryModeType = CategoryModeType;
  public symbol: string;

  protected isUserDomain: boolean;

  constructor(
    private renderer: Renderer2,
    private readonly route: ActivatedRoute,
    private helperService: HelperService,
    private staticService: StaticService,
    private cdRef: ChangeDetectorRef,
    private ssrService: SsrService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isThirdLines']?.currentValue) {
      this.openedMode = 'thirdLines';
    }
    if (changes['isShowMoreDots']) {
      this.isShowMore = this.isShowMoreDots;
    }
    if (changes['staticType']?.currentValue) {
      this.staticService.setStaticType(changes['staticType'].currentValue);
      this.cdRef.detectChanges();
    }
    if (changes['groupRouteQuery']?.currentValue) {
      this.staticService.setStaticType(changes['groupRouteQuery'].currentValue);
      this.cdRef.detectChanges();
    }
    if (changes['categories'] && changes['categories'].currentValue) {

      this.checkPinnedCities();

      if (this.isCategoriesLoad) {
        this.isCategoriesLoad = false;
        this.isAllOpened = false;
        doAsync(
          () => this.setVisibleItems(this.categoriesElem),
          this.getVisibilityDelay(changes['categories'].currentValue)
        );
      }
    }
    if (changes['className']?.currentValue) {
      this.symbol =
        this.className === 'categories'
        || this.className === 'tags'
        || this.mode === CategoryModeType.Categories
          ? '#'
          : '';
    }
    this.isUserDomain = this.ssrService.getIsUserDomain();
  }

  ngAfterViewInit(): void {
    doAsync(() => this.setVisibleItems(this.categoriesElem), 700);
  }

  private getVisibilityDelay(categories): number {
    return categories.length === 1 ? 0 : this.visibilityDelay;
  }

  protected calculateThreeDots(totalRowElementsWidth: number, item: HTMLElement) {
    this.leftShow = totalRowElementsWidth;
    this.topShow = `${item.offsetTop - 4}px`;
    this.renderer.removeClass(item, 'hide-category');
  };

  public setVisibleItems(elem: ElementRef): void {
    if (this.ssrService.isBrowser() && elem?.nativeElement?.clientWidth) {
      const elements = document.querySelectorAll('.categories__item.' + this.className + '.' + this.pageType);
      const containerWidth = elem.nativeElement.clientWidth;
      //TODO: make it dynamic = summ of rows (tags have less height in all static mode on collection page, for instance)
      let oneOffsetTop = 38;
      const threeDotsWidth = 23;
      let hiddenCount = 0;
      let totalRowElementsWidth = 0;
      let topHeight = 0;

      (elements as any).forEach((item, key) => {
        const style = item.currentStyle || window.getComputedStyle(item);
        const itemMargin = +style.marginRight.replace('px', '');
        if ((this.openedMode === 'part' || this.openedMode === 'thirdLines') && topHeight !== item.offsetTop) {
          topHeight = item.offsetTop;
          totalRowElementsWidth = 0;
        }
        const currentWidth = item.offsetWidth + itemMargin;
        totalRowElementsWidth += currentWidth;
        switch (this.openedMode) {
          case 'close':
            if ((totalRowElementsWidth + threeDotsWidth > containerWidth) && key > 0) {
              hiddenCount++;
              this.renderer.addClass(item, 'hide-category');
            } else {
              this.calculateThreeDots(totalRowElementsWidth, item);
            }
            break;
          case 'thirdLines':
            if (item.offsetTop >= oneOffsetTop * 3) {
              hiddenCount++;
              this.renderer.addClass(item, 'hide-category');
            } else {
              this.calculateThreeDots(totalRowElementsWidth, item);
            }
            break;
          case 'part':
            if (item.offsetTop >= oneOffsetTop * 6) {
              hiddenCount++;
              this.renderer.addClass(item, 'hide-category');
            } else {
              this.calculateThreeDots(totalRowElementsWidth, item);
            }
            break;
          case 'all':
            hiddenCount = 0;
            this.calculateThreeDots(totalRowElementsWidth, item);
            break;
        }
      });

      this.isAllOpened = hiddenCount <= 0;
      doAsync(() => this.isCategoriesLoad = true, 0);
    }
  }

  public routeToItemPlace(item: any, mode: CategoryModeType): string {
    let url = '';

    switch (this.staticService.staticType) {
      case StaticRouteType.Place:
        url = `/travel/${item.urlKeywords}/${item.id}/${DestinationType.Tag}`;
        break;
      case StaticRouteType.City:
        if (item.isTagGroup) {
          url = `/category/${StaticRouteType.City}/${item.urlKeywords}/${item.id}/${this.place.urlKeywords}/${this.place.id}`;
        } else {
          url = `/tag-city/${item.urlKeywords}/${item.id}/${this.place.urlKeywords}/${this.place.id}`;
        }
        break;
      case StaticRouteType.Country:
        if (item.isTagGroup) {
          url = `/category/${StaticRouteType.Country}/${item.urlKeywords}/${item.id}/${this.place.urlKeywords}/${this.place.id}`;
        } else {
          switch (mode) {
            case CategoryModeType.City:
              url = `/city/${item.urlKeywords}/${item.id}`;
              break;
            case CategoryModeType.Categories:
              url = `/experiences/${item.urlKeywords}/${item.id}/${this.place.id?.toLowerCase()}`;
              break;
          }
        }
        break;
      case StaticRouteType.Experiences:
        switch (mode) {
          case CategoryModeType.City:
            url = `/tag-city/${this.place.tag.urlKeywords}/${this.place.tag.id}/${item.urlKeywords}/${item.id}`;
            break;
          case CategoryModeType.Categories:
          case CategoryModeType.Countries:
            url = `/experiences/${item.urlKeywords}/${item.id}/${this.place.id?.toLowerCase()}`;
            break;
        }
        break;
      case StaticRouteType.ThingsToDo:
      case StaticRouteType.TagCity:
      case StaticRouteType.CategoryCity:
        url = `/tag-city/${item.urlKeywords}/${item.id}/${this.place.urlKeywords}/${this.place.id}`;
        break;
      case StaticRouteType.CategoryCountry:
        switch (mode) {
          case CategoryModeType.City:
            url = `/category/city/${StaticCategoriesComponent.getCategoryKeywords(this.place.category.name)}/${this.place.category.id}/${item.urlKeywords}/${item.id}`;
            break;
          case CategoryModeType.Categories:
          case CategoryModeType.Countries:
            url = `/experiences/${item.urlKeywords}/${item.id}/${this.place.id.toLowerCase()}`;
            break;
        }
        break;
      case StaticRouteType.Search:
        url = '/search';
        break;
      case StaticRouteType.Travel:
        url = this.getTravelSubTypeLinks(item, mode);
        break;
      case StaticRouteType.UserJourneyPersonalPage:
        switch (true) {
          case item.mode === CategoryModeType.City || item.otype === DestinationType.City:
            url = `/city/${item.urlKeywords}/${item.id}`;
            break;
          case item.mode === CategoryModeType.Categories || item.otype === DestinationType.Tag:
            url = `/travel/${item.urlKeywords}/${item.id}/${DestinationType.Tag}`;
            break;
          case item.mode === CategoryModeType.Countries || item.otype === DestinationType.Country:
            url = `/country/${item['urlKeywords']}/${item['id']}`;
            break;
        }
        break;
      case StaticRouteType.Journey:
        if (this.collectionId) {
          url = `/journey/${this.collectionId}`;
        } else {
          url = `/journey/${this.route.snapshot.params.id}`;
        }
        break;
      case StaticRouteType.UserPersonalPage:
        url = getLinkByObject(this.staticService.userInfo);
        break;
      default:
        break;
    }

    return url;
  }

  public showAllCategories(): void {
    switch (this.openedMode) {
      case 'close':
        this.openedMode = 'part';
        break;
      case 'thirdLines':
        this.openedMode = 'part';
        break;
      case 'part':
        this.openedMode = 'all';
        break;
      case 'all':
        if (this.isThirdLines) {
          this.openedMode = 'thirdLines';
        } else {
          this.openedMode = 'close';
        }
        break;
    }
    this.setVisibleItems(this.categoriesElem);
  }

  public close() {
    this.showAllCategories();
  }

  public getLinkCategory(item, mode: CategoryModeType | DestinationType): boolean {
    let result = true;
    const query = this.route.snapshot.queryParams;

    switch (this.staticService.staticType) {
      case StaticRouteType.Search:
        if (mode === CategoryModeType.City || mode === DestinationType.City) {
          if (query['cityId'] && query['cityId'].toString() === item.id.toString()) {
            result = false;
          }
        } else if (mode === CategoryModeType.Categories || mode === DestinationType.Tag) {
          if (query['tagId[]'] && this.helperService.checkIsNumeric(query['tagId[]']) && query['tagId[]'].toString() === item.id.toString()) {
            result = false;
          }
        }
        break;
      case StaticRouteType.Journey:
        if (mode === CategoryModeType.City || mode === DestinationType.City) {
          if (query['cityId'] && query['cityId'].toString() === item.id.toString()) {
            result = false;
          }
        } else if (mode === CategoryModeType.Countries || mode === DestinationType.Country) {
          if (query['countryId'] && query['countryId'].toString() === item.id.toString()) {
            result = false;
          }
        } else if (mode === CategoryModeType.Categories || mode === DestinationType.Tag) {
          if (query['tagId'] && this.helperService.checkIsNumeric(query['tagId']) && query['tagId'].toString() === item.id.toString()) {
            result = false;
          }
        }
        break;
    }

    return result;
  }

  public getParams(item: any, mode: CategoryModeType): any {
    const params = Object.assign({}, this.staticService.groupRouteQuery);
    if (params.clusterHash && params.zoom) {
      delete params.clusterHash;
      delete params.zoom;
    }

    let response: any = '';
    if (this.staticService.staticType === StaticRouteType.Journey
      || this.staticService.staticType === StaticRouteType.UserPersonalPage) {

      response = {};
      const query = this.route.snapshot.queryParams;

      switch (mode) {
        case CategoryModeType.City:
          if (!(this.staticService?.routeQueryParams && this.staticService.routeQueryParams['cityId'] == item.id)) {
            response['cityId'] = item.id;
          }
          if (query && query['tagId'] && this.helperService.checkIsNumeric(query['tagId'] === item.id)) {
            response['tagId'] = query['tagId'];
          }
          if (query && query.countryId) {
            response['countryId'] = query.countryId.toLowerCase();
          }
          break;
        case CategoryModeType.Categories:
          if (!(this.staticService?.routeQueryParams && this.staticService.routeQueryParams['tagId'] == item.id)) {
            if (item.id && this.helperService.checkIsNumeric(item.id)) {
              response['tagId'] = item.id;
            }
          }
          if (query && query.cityId) {
            response['cityId'] = query.cityId;
          }
          if (query && query.countryId) {
            response['countryId'] = query.countryId.toLowerCase();
          }
          break;
        case CategoryModeType.Countries:
          if (!(this.staticService?.routeQueryParams
            && this.staticService.routeQueryParams['countryId']?.toLowerCase() == item.id.toLowerCase())) {
            response['countryId'] = item.id.toLowerCase();
          }
          if (query && query['tagId'] && this.helperService.checkIsNumeric(query['tagId'])) {
            response['tagId'] = query['tagId'];
          }
          if (query && query.cityId) {
            response['cityId'] = query.cityId;
          }
          break;
      }
    }
    if (this.staticService.staticType === StaticRouteType.Search) {
      response = {};
      const query = this.route.snapshot.queryParams;
      response['q'] = query && query.q;

      switch (mode) {
        case CategoryModeType.City:
          response['cityId'] = item.id;
          if (query && query['tagId[]'] && this.helperService.checkIsNumeric(query['tagId[]'])) {
            response['tagId[]'] = query['tagId[]'];
          }
          if (query && query.countryId) {
            response['countryId'] = query.countryId.toLowerCase();
          }
          break;
        case CategoryModeType.Categories:
          if (item.id && this.helperService.checkIsNumeric(item.id)) {
            response['tagId[]'] = item.id;
          }
          if (query && query.cityId) {
            response['cityId'] = query.cityId;
          }
          if (query && query.countryId) {
            response['countryId'] = query.countryId.toLowerCase();
          }
          break;
        case CategoryModeType.Countries:
          response['countryId'] = item.id.toLowerCase();
          break;
      }
    }
    if (this.staticService.staticType === StaticRouteType.Travel) {
      response = {};
      const type: DestinationType = +this.route.snapshot.params['typeId'];

      switch (type) {
        case DestinationType.PoI:
          switch (mode) {
            case CategoryModeType.City:
              response['cityId'] = item.id;
              break;
            case CategoryModeType.Categories:
              if (item.id && this.helperService.checkIsNumeric(item.id)) {
                response['tagId'] = item.id;
              }
              break;
            case CategoryModeType.Countries:
              response = {};
              break;
          }
          break;
        case DestinationType.Destination:
          switch (mode) {
            case CategoryModeType.City:
              response['cityId'] = item.id;
              break;
            case CategoryModeType.Categories:
              if (item.id && this.helperService.checkIsNumeric(item.id)) {
                response['tagId'] = item.id;
              }
              break;
            case CategoryModeType.Countries:
              response['countryId'] = item.id.toLowerCase();
              break;
          }
          break;
      }
    }
    return response;
  }

  public checkPinnedCities(): void {
    if (this.mode === CategoryModeType.City && this.currentPinCollection) {
      this.categories = this.categories.map(category => ({
        ...category,
        isTagGroup: this.currentPinCollection.cities.some(city => city.oid === category.id)
      }));
    }
  }

  public clickCategory(item: any, categories: any[]): void {
    if (this.selectMode !== null && !item.isDisabled) {
      item.isSelected = !item.isSelected;
      if (this.selectMode === SelectMode.Single) {
        categories.forEach(category => {
          if (category.id !== item.id) {
            category.isSelected = false;
          }
        });
      }
    }
    if (this.openedMode === 'all') {
      this.close();
    }
    if (this.isClickable() && !item.isDisabled) {
      this.clickCategoryEmit.emit({selectedTag: {...item, staticType: this.staticService.staticType, mode: this.mode}});
    }
  }

  public isClickable(): boolean {
    return [
      StaticRouteType.UserJourneyPersonalPage,
    ].indexOf(this.staticService.staticType) !== -1;
  }

  private getTravelSubTypeLinks(item: any, mode: CategoryModeType): string {
    let url = '/';
    const params = this.route.snapshot.params;
    switch (+params['typeId']) {
      case DestinationType.Tag:
        switch (mode) {
          case CategoryModeType.City:
            url = `/tag-city/${params['name']}/${params['id']}/${item.urlKeywords}/${item.id}`;
            break;
          case CategoryModeType.Categories:
            url = `/travel/${item.urlKeywords}/${item.id}/${params['typeId']}`;
            break;
          case CategoryModeType.Countries:
            url = `/experiences/${params['name']}/${params['id']}/${item.id.toLowerCase()}`;
            break;
        }
        break;
      case DestinationType.PoI:
        switch (mode) {
          case CategoryModeType.City:
          case CategoryModeType.Categories:
            url = `/travel/${params['name']}/${params['id']}/${params['typeId']}`;
            break;
          case CategoryModeType.Countries:
            url = `/travel/${params['name']}/${params['id']}/${DestinationType.AllPlaceCountry}/${item.id.toLowerCase()}`;
            break;
        }
        break;
      case DestinationType.Destination:
        switch (mode) {
          case CategoryModeType.City:
          case CategoryModeType.Categories:
            url = `/travel/${params['name']}/${params['id']}/${params['typeId']}`;
            break;
          case CategoryModeType.Countries:
            url = `/travel/${params['name']}/${params['id']}/${DestinationType.Destination}`;
            break;
        }
        break;
      case DestinationType.AllPlaceCity:
        switch (mode) {
          case CategoryModeType.City:
            url = `/`;
            break;
          case CategoryModeType.Categories:
            url = `/tag-city/${item.urlKeywords}/${item.id}/${this.place.urlKeywords}/${params['allPlacesId']}`;
            break;
          case CategoryModeType.Countries:
            url = `/travel/${params['name']}/${params['id']}/${DestinationType.AllPlaceCity}/${item.id.toLowerCase()}`;
            break;
        }
        break;
      case DestinationType.AllPlaceCountry:
        switch (mode) {
          case CategoryModeType.City:
            url = `/city/${item.urlKeywords}/${item.id}`;
            break;
          case CategoryModeType.Categories:
            url = `/experiences/${item.urlKeywords}/${item.id}/${params['allPlacesId'].toLowerCase()}`;
            break;
          case CategoryModeType.Countries:
            url = `/travel/${params['name']}/${params['id']}/${DestinationType.AllPlaceCountry}/${item.id.toLowerCase()}`;
            break;
        }
        break;
      default:
        break;
    }

    return url;
  }

  private static getCategoryKeywords(name: string): string {
    const tags = name.split(', ');
    return tags.map(item => item.toLowerCase()).join('-');
  }

  public getCategoriesClassName(item): string {
    return this.className + ' ' + this.getExtraClassName(item) + ' '
      + (this.mode === CategoryModeType.All ? 'all-mode' : '') + ' ' + this.pageType;
  }

  public getExtraClassName(object: any): string {
    if (this.mode != CategoryModeType.All) {
      return '';
    }
    let className: string;
    switch (object?.otype) {
      case DestinationType.City:
        className = 'cities';
        break;
      case DestinationType.Country:
        className = 'countries';
        break;
      default:
        className = 'tags';
    }

    return className;
  }

  public getSymbol(className: string): '#' | '' {
    return className === 'categories' || className === 'tags' ? '#' : '';
  }

  protected readonly getCountryFlag = getCountryFlag;
  protected readonly constructBaseUrl = constructBaseUrl;
}
