import { SsrService } from "../services";
import { inject } from "@angular/core";
import { CanMatchFn, Route } from "@angular/router";

export const userDomainGuard: CanMatchFn = (route: Route): boolean => {
  const ssrService = inject(SsrService);

  const userDomain = ssrService.getUserDomain();
  if (userDomain === null) {
    return false;
  }

  if (route.children) {
    route.children[0].data.userName = userDomain;
  }

  return true;
}
