import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { consoleDebug } from '../../libraries/console-debug';
import { Meta, Title } from '@angular/platform-browser';
import { InfoService, SsrService } from '../../services';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { ReplaceLinkService } from '../../services/replace-link.service';
import { SetCityTo } from '../../store/layer';
import { Country } from '../../interfaces';
import { BaseHeaderComponent } from '../../modules/shared/base-header/base-header.component';
import { StaticService } from "../../services/static.service";
import { TogglePinnedPanel } from "../../store/panels/panels.actions";

const SITE_MAP_KEY = 'site-map';

@Component({
  selector: 'app-site-map',
  templateUrl: 'site-map.component.html',
  styleUrls: ['site-map.component.scss']
})
export class SiteMapComponent extends BaseHeaderComponent implements OnInit, OnDestroy {

  public countryLists: Country[] = [];

  private siteMapFirstRun = true;

  constructor(
    public replaceLinkService: ReplaceLinkService,
    private meta: Meta,
    private titleService: Title,
    protected ssrService: SsrService,
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    private infoService: InfoService,
    public staticService: StaticService,
  ) {
    super(store$, cdRef, ssrService);
    this.titleService.setTitle('Sitemap - Explorow.com');
    this.meta.updateTag({name: 'description', content: 'Find the sitemap for the most popular Explorow countries'});
    this.store$.dispatch(new SetCityTo(null));
  }

  ngOnInit() {
    if (this.siteMapFirstRun) {
      this.siteMapFirstRun = false;
      const storedData = this.ssrService.getState(SITE_MAP_KEY);
      if (storedData) {
        this.ssrService.removeState(SITE_MAP_KEY);
        this.countryLists = storedData;
      } else {
        this.infoService.getCountryList()
          .pipe(
            map(countryList => {
              this.ssrService.setState(SITE_MAP_KEY, countryList.items.map((item: Country) => {
                return {...item, label: item.name.trim().replace(/ /g, '_')};
              }));
              return countryList;
            })
          )
          .subscribe(
            response => {
              this.countryLists = response.items;
              this.countryLists = this.countryLists.map((item: Country) => {
                return {...item, label: item.name.trim().replace(/ /g, '_')};
              });
            },
            error => consoleDebug('error', error)
          );
      }
    } else {
      this.infoService.getCountryList().subscribe(
        response => {
          this.countryLists = response.items;
          this.countryLists = this.countryLists.map((item: Country) => {
            return {...item, label: item.name.trim().replace(/ /g, '_')};
          });
        },
        error => consoleDebug('error', error)
      );
    }
  }

  ngOnDestroy(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: null}));
  }
}
