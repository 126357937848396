import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { DatePickerMode } from "../../../../../../interfaces/datepicker-date";

@Component({
  selector: 'app-date-picker-mode',
  templateUrl: './date-picker-mode.component.html',
  styleUrls: ['./date-picker-mode.component.scss']
})
export class DatePickerModeComponent implements OnInit, OnChanges {

  @Input() availableMode: DatePickerMode[] = [DatePickerMode.Date, DatePickerMode.Month];
  @Input() to: any;
  @Input() state: DatePickerMode = DatePickerMode.Date;
  @Output() changeMode: EventEmitter<DatePickerMode> = new EventEmitter<DatePickerMode>();

  DatePickerMode = DatePickerMode;
  mode: DatePickerMode;

  constructor() {
  }

  ngOnInit() {
    this.mode = this.state;
    this.to.setMode(this.state);
  }

  setMode(mode: DatePickerMode) {
    this.mode = mode;
    this.to.setMode(mode);
    this.changeMode.emit(mode);
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

}
