import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { getFavoritesList, State } from "../reducers";
import { HostListener, Injectable, OnDestroy } from "@angular/core";
import { SsrService, UserService } from "../../services";
import { Subject } from 'rxjs/internal/Subject';
import { UserLikesService } from "../../services/user-likes.service";
import {
  AddLikes,
  LoadLikes,
  RemoveLike,
  RestoreLike,
  SuccessLoadLikes,
  SynchronizeLikes,
  UserLikesActions,
  UserLikesActionsType
} from "./user-likes.actions";
import { HttpParams } from "@angular/common/http";
import { UserCollectionService } from "../../services/user-collection.service";
import { NGX_USER_LIKES } from "../../../constants";

@Injectable()
export class UserLikesEffects implements OnDestroy {

  $loadLikes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadLikes>(UserLikesActionsType.LoadLikes),
    map(() => {
      if (this.userService.loggedIn() && !this.likesLoaded && !this.likesLoading) {
        this.likesLoading = true;
        const httpParams = new HttpParams()
          .append(
            'expand',
            'cities.city.pictures,cities.city.flights,cities.city.country,cities.city.topPlace.pictures,' +
            'cities.city.topPlace.video,places.place.pictures,places.place.city.flights,places.place.city.country,' +
            'places.place.country,places.place.pictures,places.place.video,' +
            'collections.collectionPinned.places.place.pictures,collections.collectionPinned.places.place.video'
          );
        this.userLikesService.getLikesList(httpParams)
          .subscribe((response) => {
            this.likesLoading = false;
            response._sync = this.userService.loggedIn();
            this.store$.dispatch(new UserLikesActions.SuccessLoadLikes(response, this.userService.loggedIn()));
          });
      }
    })
  ), {dispatch: false});

  $addLikes$ = createEffect(() => this.actions$.pipe(
    ofType<AddLikes>(UserLikesActionsType.AddLikes),
    map((action) => {
      if (this.userService.loggedIn()) {

        const httpParams = new HttpParams()
          .append('expand', 'pictures');

        this.userLikesService.addLikes({
          oid: action.payload.id || action.payload.oid,
          otype: action.payload.otype,
        },  httpParams)
          .subscribe(() => {
            this.store$.dispatch(new UserLikesActions.SuccessAddLikes(action.payload, this.userService.loggedIn()));
          }, () => {
          });
      } else {
        this.store$.dispatch(new UserLikesActions.SuccessAddLikes(action.payload, this.userService.loggedIn()));
      }
      this.userLikesService.isLikeAnimation$.next();
      this.userCollectionService.checkLastAdd(true);
    })
  ), {dispatch: false});

  $synchronizeLikes$ = createEffect(() => this.actions$.pipe(
    ofType<SynchronizeLikes>(UserLikesActionsType.SynchronizeLikes),
    map((action) => {
      if (this.userService.loggedIn()) {
        // @ts-ignore
        action.payload?.places.forEach(like => this.store$.dispatch(new UserLikesActions.AddLikes(like)));
        // @ts-ignore
        action.payload?.cities.forEach(like => this.store$.dispatch(new UserLikesActions.AddLikes(like)));
      }
    })
  ), {dispatch: false});

  $removeLike = createEffect(() => this.actions$.pipe(
    ofType<RemoveLike>(UserLikesActionsType.RemoveLike),
    map((action) => {
      if (this.userService.loggedIn()) {
        this.userLikesService.deleteLikes(
          action.payload.oid,
          action.payload.otype
        )
          .subscribe(() => {
            this.store$.dispatch(new UserLikesActions.SuccessRemoveLike({
              oid: action.payload.oid,
              otype: action.payload.otype
            }));
          }, () => {
          });
      } else {
        this.store$.dispatch(new UserLikesActions.SuccessRemoveLike({
          oid: action.payload.oid,
          otype: action.payload.otype
        }));
      }
      this.userCollectionService.checkLastAdd(true);
    })
  ), {dispatch: false});

  $successLoadLikes$ = createEffect(() => this.actions$.pipe(
    ofType<SuccessLoadLikes>(UserLikesActionsType.SuccessLoadLikes),
    map(() => {
      if (this.userService.loggedIn()) {
        this.likesLoaded = true;
      }
    })
  ), {dispatch: false});

  private likesLoaded = false;
  private $destroyed = new Subject<void>();
  private userLikes: any;
  public resizeWindow = 0;
  private likesLoading: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private userService: UserService,
    private userLikesService: UserLikesService,
    private userCollectionService: UserCollectionService,
    private ssrService: SsrService
  ) {
    if (ssrService.isBrowser()) {
      this.userLikes = JSON.parse(localStorage.getItem(NGX_USER_LIKES)) || '';
      if (this.userLikes) {
        this.store$.dispatch(new RestoreLike(this.userLikes));
      }

      this.store$.pipe(
        select(getFavoritesList),
        takeUntil(this.$destroyed)
      ).subscribe((likes) => {
        this.userLikes = likes;
        this.userLikes = JSON.parse(JSON.stringify(this.userLikes));
        this.userLikes['_sync'] = this.userService.loggedIn();

        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_USER_LIKES, JSON.stringify(this.userLikes));
        }
      });
      this.resizeWindow = window.innerWidth;
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  isMobile() {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile()
      || this.ssrService.isBrowser() && this.resizeWindow <= 896;
  }
}
