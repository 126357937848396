import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { CloseService } from '../../../../../../services/close.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

export interface DropDownValue {
  title: string;
  value: any;
  selected?: boolean;
  action?: any;
}

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent implements OnDestroy {
  @ContentChild('content') customContent;

  @Input() disabled: boolean;
  @Input() title = 'Awesome dropdown!';
  @Input() options: DropDownValue[] = [];

  @Output() closeDropDown = new EventEmitter<boolean>();
  @Output() selectEmitter = new EventEmitter<DropDownValue>();

  public isCloseDropDown = false;
  public isOpen = false;

  private $destroyed = new Subject<void>();

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    this.isCloseDropDown = false;
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.isCloseDropDown = true;
      this.closeDropDown.emit(this.isCloseDropDown);
    } else {
      this.isCloseDropDown = false;
      this.closeDropDown.emit(this.isCloseDropDown);
    }
  }

  constructor(
    private eRef: ElementRef,
    private closeService: CloseService
  ) {
    closeService.close$
      .pipe(
        takeUntil(this.$destroyed)
      )
      .subscribe(() => {
        this.toggle();
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  trackByFn(index) {
    return index;
  }

  selectItem(index) {
    this.options = this.options.map((option) => ({...option, selected: false}));
    this.options[index].selected = true;
    this.selectEmitter.emit(this.options[index]);
    this.isOpen = false;
  }

  toggle(isClose = false): void {
    if (!isClose) {
      if (!this.disabled) {
        this.isOpen = !this.isOpen;
      } else {
        this.isOpen = false;
      }
    } else {
      this.isOpen = false;
    }
  }
}
