import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONTACT_US_ACTION } from "../../../../../enums/contact-us-action";
import { maxStandardDesktopWidth } from "../../../../../services/helper.service";
import { constructBaseUrl, getWindow } from "../../../../../libraries";

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  @Input() hasMotto: boolean = true;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  public contactUsOpened = false;
  public height: number;
  public href = '';
  public routeIndex = -1;
  public contactType = CONTACT_US_ACTION.general_enquiries;
  public resizeWindow = 0;

  private readonly routes = ['site-map', 'list-cities', 'terms-of-use', 'cookie-policy', 'privacy-policy'];

  constructor(
    private router: Router
  ) {
    const w = getWindow();
    if (w) {
      this.resizeWindow = w.innerWidth;
    }
  }

  ngOnInit() {
    this.href = this.router.url;

    for (let i = 0; i < this.routes.length; i++) {
      if (this.href === ('/' + this.routes[i])) {
        this.routeIndex = i;
        break;
      }
    }
  }

  changeHeightFooter(): void {
    if (typeof document !== 'undefined' && ['/privacy-policy', '/terms-of-use', '/cookie-policy'].includes(this.href)) {
      const cookieContainer = document.getElementById('cookies-law-policy')?.getElementsByClassName('cookie__container')[0] as HTMLElement;
      if (cookieContainer) {
        this.height = cookieContainer.offsetHeight;
      }
    }
  }

  ngAfterViewInit(): void {
    this.changeHeightFooter();
  }

  openContactUs() {
    this.contactUsOpened = true;
  }

  closeContactUs() {
    this.contactUsOpened = false;
  }

  wideDesktop() {
    return this.resizeWindow > maxStandardDesktopWidth;
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
