<div class="user-places__container">
  <div class="user-places__title">
    <span class="user-places__headers">
      LIST OF PLACES
    </span>

    <div class="sort-container">
      <img (click)="emitFunc('toggleSort')"
           [src]="'/assets/icons/' + (isAscSort ? 'sort-date-up.svg' : 'sort-date-down.svg')"
           alt="Sort img">
      <span class="user-places__headers">Sort by date added</span>
    </div>
  </div>

  <div class="user-places__list">
    <ng-container *ngIf="isRecentlyDeletedPage">
      <div class="returnPlaces" (click)="emitFunc('toggleRecentlyDeleted')">
        <img src="/assets/icons/back-icon-blue.png" alt="">
        <p>return to places</p>
      </div>

      <div>
      <span class="user-places__headers">
        DELETED PLACES
      </span>
        <p class="user-places__text">
          After 90 days deleted places are permanently closed and cannot be recovered
        </p>
      </div>
    </ng-container>

    <div class="user-places__item" *ngFor="let place of places; let i = index">
      <span>{{ (i + 1) }}.</span>

      <div class="user-places__item-content">
        <div class="user-places__item-header">
          <ng-container *ngIf="!isRecentlyDeletedPage">
            <a [routerLink]="getPlaceUrl(place)"
               [queryParams]="(+place?.status === placeStatus.ClientStatusOnline ? {} : {preview:''})"
               class="user-places__link">
              <h3>{{ place?.name }}</h3>
            </a>
            <a [routerLink]="getPlaceUrl(place)"
               [queryParams]="(+place?.status === placeStatus.ClientStatusOnline ? {} : {preview:''})"
               class="user-places__link"
               target="_blank">
              <img [src]="'/assets/icons/icon-exit.png'" alt="">
            </a>
          </ng-container>
          <ng-container *ngIf="isRecentlyDeletedPage">
            <h3>{{ place?.name }}</h3>
          </ng-container>
        </div>

        <div class="user-places__action-link">
          <span class="item-id">
            IDN-{{ place?.id }}
          </span>

          <span class="status tooltip-event-item">
            Date added: {{place?.creationDate | date: 'dd/MM/yy'}}
            <span class="tooltip-event"> {{place?.creationDate | date: 'dd/MM/yy HH:mm'}}</span>
          </span>

          <span class="status">
            Status:
            <span class="online"
                  *ngIf="place?.moderationStatus === placeStatus.ClientStatusOnline">
              Online
            </span>
            <span class="moderation"
                  *ngIf="place?.moderationStatus === placeStatus.ClientStatusOnModeration">
              On Moderation
            </span>
            <span class="rejected"
                  *ngIf="place?.moderationStatus === placeStatus.ClientStatusRejected">
              Rejected
              <span class="info-place" *ngIf="place?.rejectReason">
                <img [src]="'/assets/icons/icon-info.png'" alt="">
                <span>{{ place?.rejectReason }}</span>
              </span>
            </span>
            <span class="moderation"
                  *ngIf="place?.moderationStatus === placeStatus.ClientStatusOnlineChangesOnModeration">
              Changes On Moderation
            </span>
            <span class="rejected"
                  *ngIf="place?.moderationStatus === placeStatus.ClientStatusOnlineChangesRejected">
              Changes Rejected
              <span class="info-place" *ngIf="place?.rejectReason">
                <img [src]="'/assets/icons/icon-info.png'" alt="">
                <span>{{ place?.rejectReason }}</span>
              </span>
            </span>
          </span>
        </div>

        <div class="user-places__actions" *ngIf="!isRecentlyDeletedPage">
            <span class="edit-item"
                  [routerLink]="'/place/edit/' + place?.id">
              Edit
            </span>
          <span class="delete-item" (click)="openDialog(place)">
              Delete
            </span>
        </div>

        <div class="user-places__actions" *ngIf="isRecentlyDeletedPage">
            <span class="edit-item" (click)="recoverPlace(place)">
              Recover
            </span>
        </div>

      </div>
    </div>

    <div class="user-places__not-found" *ngIf="!places?.length">
      <span>You don't have any {{isRecentlyDeletedPage ? 'deleted' : 'created'}}  places</span>
    </div>

    <div *ngIf="places && !isRecentlyDeletedPage"
         class="user-places__recently-deleted"
         [class.no-places]="!places?.length"
         (click)="emitFunc('toggleRecentlyDeleted')">
      <span>Recently deleted</span>
    </div>

    <div class="read-more__container"
         *ngIf="!isRecentlyDeletedPage && placesOtherCount > 0"
         (click)="loadMorePlaces()">Show more ({{placesOtherCount}} places)
      <div class="read-more__arrow-down">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 1000 1000"
             enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g><g><path d="M124.8,197.6L500,572.7l375.2-375.2L990,312.4l-490,490l-490-490L124.8,197.6z"/></g></g>
          </svg>
      </div>
    </div>

    <div class="action-btn-place" [ngClass]="{'empty-places': !places?.length}"
         *ngIf="staticService.isMobileOrTablet()">
      <button class="btn-create" type="submit" [routerLink]="'/place/add'">
        Add a Place
      </button>
    </div>
  </div>
</div>

<app-confirm-dialog
  *ngIf="isConfirm"
  [message]="'Are you sure that you want to delete this place?'"
  (action)="deletePlace($event)">
</app-confirm-dialog>
