import { AfterViewInit, Directive, ElementRef } from '@angular/core';

/**
 * Inspired by {@link http://utrack.flytosomewhere.com/issue/FLY-321#comment=4-1321}
 * Should using for img tag.
 * If src is empty then would be using value from the src-fallback tag if src-fallback is exists.
 * @date 13 sep 18
 */
@Directive({
  selector: '[appSrcFallback]'
})
export class SrcFallbackDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    const src = this.el.nativeElement.src;
    if (!src || /null/.test(src) || /\/default\./.test(src)) {
      const fallback = this.el.nativeElement.getAttribute('src-fallback');
      if (fallback) {
        this.el.nativeElement.setAttribute('src', fallback);
      }
      this.el.nativeElement.style.minWidth = '100%';
      this.el.nativeElement.style.minHeight = '100%';
    }
  }

}
