import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appChangeBorderStyle]'
})
export class ChangeBorderStyleDirective implements OnChanges {

  @Input() color;
  @Input() change;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes) {
    if (changes.change) {
      this.changeStyle(changes.change.currentValue);
    }
  }

  changeStyle(change) {
    if (change) {
      this.el.nativeElement.style.border = '1px solid #3297EF';
    } else {
      this.el.nativeElement.style.border = null;
    }
  }

}
