import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { constructMediaURL } from "../../../libraries";
import {
  DEFAULT_COLLECTION_IMAGE
} from "../../../shared-components/pinned-panel/pinned-panel-collection/pinned-panel-collection.component";
import { StaticService } from "../../../services/static.service";

@Component({
  selector: 'app-image-content',
  templateUrl: './image-content.component.html',
  styleUrls: ['./image-content.component.scss']
})

export class ImageContentComponent implements OnChanges {
  @Input() src: string;
  @Input() alt: string;
  @Input() zoomIn: boolean;
  @Input() playBtn: boolean;
  @Input() isPortrait: boolean;
  @Input() oneElement: boolean;
  @Input() blur: boolean;
  @Input() videoData: any;

  @Output() loadEmitter = new EventEmitter<any>();
  @Output() galleryEmitter = new EventEmitter<any>();

  public contentSrc: string = '/assets/images/' + DEFAULT_COLLECTION_IMAGE;

  constructor(
    public staticService: StaticService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.oneElement && changes['src'].currentValue) {
      this.contentSrc = constructMediaURL(this.src);
    }
  }

  public loadEmit(event): void {
    const imgEl = event?.target;
    if (imgEl && !this.isPortrait) {
      this.isPortrait = imgEl?.offsetHeight > imgEl?.offsetWidth;
    }
    this.contentSrc = constructMediaURL(this.src);
    this.loadEmitter.emit(event);
  }

  showGallery(): void {
    this.galleryEmitter.emit();
  }
}
