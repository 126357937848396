import { LatLng } from '../../interfaces';
import { consoleThrowError } from '../console-debug';

export const parseCoords = (coordinates: string | LatLng): LatLng => {
  if (!coordinates) {
    consoleThrowError(`Coordinates not passed.`);
  }
  if (typeof coordinates === 'string') {
    try {
      const coords: string[] = coordinates.split(',');
      if (coords.length !== 2) {
        consoleThrowError(`Passed string "coordinates" (${coordinates}) has invalid format. Should be (ex:) "12.3455,98.7654"`);
      }
      return coords.reduce((acc, cur, i) => {
        i === 0 ? acc['lat'] = Number.parseFloat(cur) : acc['lng'] = Number.parseFloat(cur);
        return acc;
      }, Object.create(null));

    } catch (error) {
      consoleThrowError(error);
    }
  } else {
    try {
      return {
        lat: coordinates.lat,
        lng: coordinates.lng,
      };
    } catch (error) {
      consoleThrowError(error);
    }

  }

  consoleThrowError('Error parsing coordinates');

  return {lat: null, lng: null};
};
