import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MetaTagsService, SsrService } from '../../../../../services';
import { SetCityTo } from '../../../../../store/layer';
import { State } from '../../../../../store/reducers';
import { BaseHeaderComponent } from '../../../base-header/base-header.component';
import { StaticService } from "../../../../../services/static.service";
import { CONTACT_US_ACTION } from "../../../../../enums/contact-us-action";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: 'privacy-policy.component.html',
  styleUrls: ['privacy-policy.component.scss']
})
export class PrivacyPolicyComponent extends BaseHeaderComponent {
  public isStaticPage = true;
  public contactUsOpened: string;
  public contactType = CONTACT_US_ACTION.general_enquiries;

  constructor(
    private metaTagService: MetaTagsService,
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    protected ssrService: SsrService,
    public staticService: StaticService,
  ) {
    super(store$, cdRef, ssrService);
    this.createMetaTag();
    this.store$.dispatch(new SetCityTo(null));
  }

  private createMetaTag(): void {
    this.metaTagService.resolver({
      route: 'policy-pages',
      name: 'Privacy Policy',
      description: ''
    });
  }

  public toggleContactUs(value: string = null): void {
    this.contactUsOpened = value;
  }
}
