import dayjs from 'dayjs';
import { MapDateType, PlacePin, WhenDate } from '../../interfaces';
import { DatePickerMode } from "../../interfaces/datepicker-date";
import { TripTypeEnum } from "../../enums/trip-type.enum";

const MAP_TRIP_FIELDS = {
  ONE_WAY: 1,
  ROUND_TRIP: 2,
};

export const transformTripTypeToApi = (tripType: TripTypeEnum | string) => {
  return MAP_TRIP_FIELDS[tripType];
};

export const transformApiDatesToWhenDate = (data: PlacePin): WhenDate[] => {
  if (data && data.dateType) {
    switch (data.dateType) {
      case MapDateType.Period:
        return [
          {type: DatePickerMode.Date, date: dayjs(data.dateFrom)},
          {type: DatePickerMode.Date, date: dayjs(data.dateTo)}
        ];
      case MapDateType.Month:
        return [{type: DatePickerMode.Month, date: dayjs(data.dateFrom)}];
      case MapDateType.Date:
        return [{type: DatePickerMode.Date, date: dayjs(data.dateFrom)}];
    }
  }

  return [
    {type: DatePickerMode.Month, date: dayjs().startOf('month').add(1, 'month')}
  ];
};

export const transformDateToApiDateType = (date: WhenDate[]) => {
  if (date.length === 1) {
    if (date.every(val => val.type === DatePickerMode.Date)) {
      return MapDateType.Date;
    }

    if (date.every(val => val.type === DatePickerMode.Month)) {
      return MapDateType.Month;
    }
  }

  return MapDateType.Period;
};

export const transformWhenDateToApiDateType = (date: WhenDate[]) => {
  if (date[0].type === DatePickerMode.Month) {
    return dayjs(date[0].date).startOf('month').format('YYYY-MM-DD');
  } else {
    return dayjs(date[0].date).format('YYYY-MM-DD');
  }
};

export const transformReturnToApiDateType = (date: WhenDate[]) => {
  if (!date[1]) {
    return '';
  }
  if (date[1].type === DatePickerMode.Month) {
    return dayjs(date[1].date).startOf('month').format('YYYY-MM-DD');
  } else {
    return dayjs(date[1].date).format('YYYY-MM-DD');
  }
};
