<div class="wrapper">
  <ng-container *ngIf="!isUserLogged">
    <ng-container *ngIf="isNotSignInButton; then notSignInButton; else signInButton"></ng-container>
    <ng-template #notSignInButton>
      <div class="not-sign-in-container" (click)="!isSignInClickDisabled && checkAction()">
        <ng-template [ngTemplateOutlet]="isNotSignInButton"></ng-template>
      </div>
    </ng-template>
    <ng-template #signInButton>
      <div class="sign-in-button decorate-b-b tooltip-event-item"
           *ngIf="!isText"
           [ngClass]="{'b-b-active': showUserAccount}"
           (click)="checkAction()">
        <img [appBasesrc]="'/assets/icons/user-profile-new.svg'"
             class="user-img"
             alt="user">

        <span class="tooltip-event">Sign in</span>
      </div>

      <div *ngIf="isText" (click)="checkAction()">
        <a class='link-profile-user'>
          User Profile
        </a>
      </div>
    </ng-template>

    <app-registration *ngIf="showRegisterForm"
                      #authModal
                      (click)="changeModal(authModal)"
                      (resPasswordSuccessEmitter)="resPasswordSuccess($event)"
                      (regWinClose)="toggleRegisterForm(); closeUserModal()"
                      (registrationCompletedEmitter)="registrationCompleted()"
                      (showUserAccountEmitter)="showUserProfile($event)"
                      [month]="month"
                      [searchMode]="!!searchMode"
                      [isOpen]="showRegisterForm"
                      [showLoginForm]="showLoginForm && !resetPass"
    ></app-registration>
  </ng-container>
  <ng-container *ngIf="!!isUserLogged">
    <ng-container *ngIf="isNotSignInButton; then notAccount; else account"></ng-container>
    <ng-template #notAccount>
      <ng-template [ngTemplateOutlet]="isNotSignInButton"></ng-template>
    </ng-template>

    <ng-template #account>
      <div class="wrapper" *ngIf="!isNotSignInButton">
        <div class="my-account-button decorate-b-b tooltip-event-item"
             *ngIf="!isText; else testItem"
             [ngClass]="{'b-b-active': showUserAccount}"
             mat-raised-button
             (click)="checkAction()">
          <div class="new-notification-counter"
               *ngIf="!!isUserLogged && newNotifications?._extra?.unreadCount">
            <span class="counter-content">{{newNotifications?._extra?.unreadCount}}</span>
          </div>
          <img [appBasesrc]="getUserIcon()" [class.avatar]="hasIcon()" class="user-img" alt="user"/>
          <span class="tooltip-event">Settings & Help</span>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container
    *ngTemplateOutlet="isOpenProfilePopup && popupProfile; context: {data: !!isUserLogged ? popupDataAuth : popupDataNoAuth}"></ng-container>
  <ng-container *ngTemplateOutlet="helpContentOpened && helpContent"></ng-container>
  <ng-container *ngIf="isNotificationContentVisible">
    <div class="empty-places" (click)="toggleNotificationContent(false)"></div>
    <div class="menu-wrapper notification-wrapper" [class.popup-mobile]="mobileMode">
      <app-notifications (clickOnItem)="toggleNotificationContent(false)"></app-notifications>
    </div>
  </ng-container>
</div>

<div *ngIf="(resetPass || (showWinWelcome && isUserLogged)) && textMessage?.length" class="win-welcome">
  <app-welcome
    [textMessage]="textMessage"
    [showTitle]="userActions === 'register'"
    (emitContinue)="showUserProfile()"
  ></app-welcome>
</div>

<ng-template #testItem>
  <a
    class='link-profile-user'
    (click)="isUserLogged ? toggleUserAccount(true) : toggleRegisterForm()"
  >User Profile</a>
</ng-template>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactUsOpened"
                (closeContactUs)="toggleContactUs()"></app-contact-us>

<ng-template #popupProfile let-data="data">
  <div class="popup-container"
       [class.popup-mobile]="mobileMode">
    <app-fly-popup-profile
      [data]="data"
      (emitAction)="emitAction($event)"></app-fly-popup-profile>
  </div>
</ng-template>

<ng-template #helpContent>
  <div class="empty-places" (click)="toggleHelpContent(false)"></div>
  <div class="menu-wrapper" [class.popup-mobile]="mobileMode">

    <div *ngIf="mobileMode" class="close" (click)="toggleHelpContent(false)">
      <figure class="close-popup fly-pointer">
        <img [appBasesrc]="'/assets/cross.svg'" class='close-popup-img' alt="close" title="close">
      </figure>
    </div>

    <ng-container *ngIf="!contactContentOpened">
      <div class="user-sidebar__content-item">
        <span>HELP CENTER</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item" (click)="toggleContactContent()">
          <span class="border-top-radius">
            <a>Contact Us</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
          <div class="user-sidebar__item"
               [appRouterLink]="item.url"
               *ngFor="let item of burgerLinks; let last=last">
        <span [class.border-bottom-radius]="last">
           <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
        </span>
          </div>
        </div>
      </div>

      <div class="user-sidebar__content-item mb-0">
        <span>EXPLOROW</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item"
               [appRouterLink]="item.url"
               *ngFor="let item of burgerSubLinks; let i=index; let last=last">
          <span [ngClass]="{'border-top-radius': i===0, 'border-bottom-radius': last}">
            <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contactContentOpened">
      <div class="user-sidebar__content-item mb-0">
        <span>CONTACT US</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item"
               (click)="item?.action && toggleContactUs(item?.action)"
               [appRouterLink]="item?.url && item.url"
               *ngFor="let item of contactUsLinks; let i=index; let last=last">
          <span [ngClass]="{'border-top-radius': i===0, 'border-bottom-radius': last}">
            <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
