<ng-container *ngIf="isShow">
  <div class="empty-places" (click)="toggleShow()"></div>
  <div id="popup" class="popup-select">
    <div class="popup-content"
         [class.is-profile-page]="isProfile"
         [class.like]="isFavorites"
         [ngClass]="!!classNameBySide && classNameBySide">
      <div class="popup-header">
        <span class="popup-title">Options</span>
        <span class="close" (click)="toggleShow()">&times;</span>
      </div>
      <div class="popup-row" *ngFor="let item of data" (click)="clickRow(item.action)">
        <div class="popup-row-icon">
          <img [src]="constructBaseUrl('/assets/icons/'+item.icon)" alt="{{item.title}}">
        </div>
        <div class="popup-row-text">{{ item.title }}</div>
      </div>
    </div>
  </div>
</ng-container>
