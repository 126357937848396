export interface ContactInfo {
  webSite?: string,
  address?: string,
  phone?: string,
  email?: string,
  whatsapp?: string,
  businessHours?: any[],
}

export enum ContactInfoEnum {
  webSite = 'webSite',
  address = 'address',
  phone = 'phone',
  email = 'email',
  whatsapp = 'whatsapp',
  businessHours = 'businessHours',
}

export interface ScheduleForm {
  title: string,
  day: number,
  scheduleTimes: ScheduleTime[] | any[],
  isWorkDay: boolean,
  isMoreTime: boolean
}

export interface ScheduleTime {
  from: string,
  to: string
}

export enum TimeTypeEnum {
  from = 'from',
  to = 'to'
}

export const DAYS_OF_WEEK = {
  monday: {
    id: 0,
    title: 'Monday',
    shortTitle: 'Mon'
  },
  tuesday: {
    id: 1,
    title: 'Tuesday',
    shortTitle: 'Tue'
  },
  wednesday: {
    id: 2,
    title: 'Wednesday',
    shortTitle: 'Wed'
  },
  thursday: {
    id: 3,
    title: 'Thursday',
    shortTitle: 'Thu'
  },
  friday: {
    id: 4,
    title: 'Friday',
    shortTitle: 'Fri'
  },
  saturday: {
    id: 5,
    title: 'Saturday',
    shortTitle: 'Sat'
  },
  sunday: {
    id: 6,
    title: 'Sunday',
    shortTitle: 'Sun'
  },
};

export const DAYS_OF_WEEK_ARRAY = [
  DAYS_OF_WEEK.monday,
  DAYS_OF_WEEK.tuesday,
  DAYS_OF_WEEK.wednesday,
  DAYS_OF_WEEK.thursday,
  DAYS_OF_WEEK.friday,
  DAYS_OF_WEEK.saturday,
  DAYS_OF_WEEK.sunday,
];
