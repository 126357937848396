<form name='reset-form'
      class="auth-form"
      [formGroup]="resetForm"
      (ngSubmit)="allowSubmit && sendEmail(resetForm)">
  <div class="errors" *ngIf="generalError">
    <div *ngFor="let message of generalError">
      {{message.value}}
    </div>
  </div>
  <div *ngIf="emailResendSuccessfully" class="email-sent-successfully">
    <span> {{emailResendSuccessfully}} </span>
  </div>
  <div class="form-group">
    <label [ngClass]="{'translate-bottom': !emailFocused}"
           class="pass-label" for="email"
           i18n="labels.email" innerText="'labels.email' | translate"> e-mail </label>

    <span class="pass-v-icon"
          *ngIf="emailUpdated"
          [ngClass]="validationStyles('email')"></span>

    <input type="email"
           (blur)="valueUpdated('email')"
           (focus)="focus('email')"
           id="email"
           class="form-control"
           formControlName="email">
    <div *ngIf='resOfEmailValidation' class="validation-errors">{{resOfEmailValidation}}</div>
  </div>

  <div class="form-group">
    <button
      mat-raised-button
      type="submit"
      class="submit-button "
      i18n="labels.login"
      innerText="'labels.submit' | translate">
      Submit
    </button>
  </div>

</form>
