import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user-token.service';
import { UserAccountService } from '../../../../services/user-account.service';
import { consoleDebug } from '../../../../libraries/console-debug';
import { doAsync } from '../../../../libraries/do-async';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-profile-pass-change',
  templateUrl: 'profile-pass-change.component.html',
  styleUrls: ['profile-pass-change.component.scss']
})
export class ProfilePassChangeComponent implements OnInit {

  @Output() cancelEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  public allowSubmit: boolean = true;
  public confNewPassUpdated: boolean = false;
  public generalError: any;
  public newPassUpdated: boolean = false;
  public oldPassUpdated: boolean = false;
  public passChangeForm;
  public passDoNotMatch: string = null;
  public password_changed: string = 'success.password_changed';
  public passwordChangedSuccessfully: any;
  public passwordsMatch: boolean = false;
  public passwordType: string = 'password';
  public resOfNewPasswordValidation: any;
  public resOfOldPasswordValidation: any;
  public shouldRemember: any = 'checked';
  public showForgotPassword: any = false;
  public success: string;
  public token: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private userAccountService: UserAccountService,
    private fb: UntypedFormBuilder,
  ) {
    this.passChangeForm = fb.group({
      newPass: ['', [Validators.minLength(6), Validators.required]],
      oldPass: ['', [Validators.minLength(6), Validators.required]],
      confNewPass: ['', [Validators.minLength(6), Validators.required]]
    }, {updateOn: 'blur'});
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.token = params.token);
    this.setRememberMe();
  }

  resetPassword(signInForm: any) {
    this.resetRezErrPass();
    if (!signInForm.valid) {
      return;
    }
    this.userService.disableSubmitForWhile(this, 1500);
    this.resetRezPassVal();
    let params = {
      oldPassword: signInForm.controls['oldPass'].value.trim(),
      newPassword: signInForm.controls['newPass'].value.trim()
    };

    this.userAccountService
      .updatePassword(params)
      .subscribe(
        () => {
          this.setRememberMe();
          this.allowSubmit = true;
          this.changed.emit(true);
        },
        (err) => {
          this.allowSubmit = true;
          this.handleErrors(err);
        }
      );
  }

  public cancel(): void {
    this.cancelEmitter.emit();
  }

  public valueUpdated(propName) {
    this[`${propName}Updated`] = true;
  }

  public validationStyling(formCtrl) {
    return this.passChangeForm.controls[formCtrl].valid;
  }

  public validationStyles(formCtrl, resOfOldPasswordValidation: string = null) {
    if (formCtrl == 'oldPass' && resOfOldPasswordValidation)
      return {'fly-pass-invalid': true};
    if (formCtrl == 'oldPass' && !resOfOldPasswordValidation) return null;

    if (this.readVal('newPass') == this.readVal('confNewPass')) {
      this.passwordsMatch = true;
      if (this.passDoNotMatch) doAsync(() => {
        this.passDoNotMatch = null;
      });
    }

    if (formCtrl == 'confNewPass' && this.readVal('newPass') != this.readVal('confNewPass')) {
      this.passwordsMatch = false;
      if (!this.passDoNotMatch) doAsync(() => {
        this.passDoNotMatch = 'Passwords do not match';
      });
      return {'fly-pass-invalid': true};
    }

    return {'fly-pass-valid': this.validationStyling(formCtrl), 'fly-pass-invalid': !this.validationStyling(formCtrl)};
  }

  public readVal(formCtrlName) {
    return this.passChangeForm.controls[formCtrlName].value;
  }

  public resetRezPassVal() {
    this.resOfOldPasswordValidation = null;
    this.resOfNewPasswordValidation = null;
  }

  public resetRezErrPass() {
    this.generalError = null;
    this.passwordChangedSuccessfully = null;
  }

  public handleErrors(err: any) {
    if (err.status == 201) {
      this.saveTranslationIntoProperty(this.password_changed, 'passwordChangedSuccessfully');
      return;
    }
    consoleDebug('err', err);
    if (err.status > 300) {
      this.caseErrorMessage(err.error);
    }

  }

  caseErrorMessage(error: any) {
    let message = error.message || error[0].message;
    let field = error.field || error[0].field;
    consoleDebug('error', field);
    switch (field) {
      case 'oldPassword':
        this.resOfOldPasswordValidation = message;
        break;

      case 'newPassword':
        this.resOfNewPasswordValidation = message;
        break;

      case 'system':
        this.generalError = this.mapResponse(message);
        break;

      default:
        this.generalError = this.mapResponse(message);
        break;
    }
  }

  public setRememberMe() {
    this.userService.shouldRemember(this.shouldRemember);
  }

  public mapResponse(message) {
    return [{value: message}];
  }

  public changePasswordView() {
    this.passwordType = (this.passwordType == 'password') ? 'text' : 'password';
  }

  public saveTranslationIntoProperty(id: string, whereToSave: string) {
    this.userService.getTranslation(id)
      .pipe(take(1))
      .subscribe(
        (res) => this[whereToSave] = res
      );
  }
}
