import { Component, Input, OnInit } from '@angular/core';
import { formLink, getLink } from '../../libraries/formLink';
import { CityNew } from '../../interfaces/place';
import { constructMediaURL } from '../../libraries/form-img-src';

@Component({
  selector: 'app-page-interesting-city',
  templateUrl: 'page-interesting-city.component.html',
  styleUrls: ['page-interesting-city.component.scss']
})
export class PageInterestingCityComponent implements OnInit {

  @Input() city: CityNew;

  public link;

  constructor() {
  }

  ngOnInit() {
    const config = [getLink(this.city, this.city.name), this.city.id];
    this.link = formLink('city', config);
  }

  formMediaUrl(city: CityNew) {
    return constructMediaURL(!!city.pictures.head && city.pictures.head.thumb);
  }
}
