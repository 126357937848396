import { DestinationType } from '../enums/destination-type';

export const showMatch = (filteredList: any[], matched: string) => {
  const addCN = addCountryName;
  const wrapM = wrapMatched;
  const invalid = /[°*^$"§%()\[\]{}=\\?´`'#<>|,;.:+_-]/g;
  matched = matched.replace(invalid, "");
  const matchRegExp = new RegExp(`(${matched.trim()})`, 'i');
  const result: any[] = filteredList.map((dest) => {
    return {
      innerHTML: addCN(wrapM(dest.name, matchRegExp), dest.country ? dest.country.name : null, dest.otype),
      place: dest
    };
  });
  if (!result || !result.length) {
    return {
      error: true,
      innerHTML: matched,
    };
  }
  return result;
};

function wrapMatched(name: string, matchRegExp) {
  const matched = name.match(matchRegExp);
  if (matched) {
    return name.replace(matchRegExp, `<strong>${matched[0]}</strong>`);
  }
  return name;
}

function addCountryName(cityName, countryName, type) {
  if (type === DestinationType.Country) {
    return `<pre class="search-word">${cityName} <small class="ust-silver">Country</small></pre>`;
  }

  if (!countryName || (type !== DestinationType.City && type !== DestinationType.Place)) {
    return cityName;
  }

  return `<pre class="search-word">${cityName}  <small class="ust-silver"> ${countryName} </small></pre>`;
}
