import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-form-input-auto-complete',
  templateUrl: './form-input-auto-complete.component.html',
  styleUrls: ['./form-input-auto-complete.component.scss']
})
export class FormInputAutoCompleteComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  // TODO: check that it works properly
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @ViewChild('input', {static: true}) input: ElementRef;

  @Input() autoFocus = false;
  @Input() filterFn;
  @Input() selectedValue;
  @Input() values = [];

  @Output() selectEmitter = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.items = [];
    }
  }

  public inputSubscription$: Subscription;
  public items = [];
  public query = new UntypedFormControl();
  public value = '';

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    this.inputSubscription$ = this.query.valueChanges
      .pipe(
        debounceTime(500),
        filter((newValue: string) => !!newValue && newValue.length >= 2)
      )
      .subscribe((newValue: string) => {
        this.onChangeValue(newValue);
      });
  }

  onChangeValue(event) {
    if (this.filterFn) {
      this.filterFn(event).then((items) => {
        this.items = items;
      });
    } else {
      this.items = (this.value.length > 0)
        ? this.values.filter(item => item.value.includes(this.value))
        : this.items = [];
    }
  }

  onSelectItem(item) {
    this.selectEmitter.emit({
      item,
      component: this,
    });
    this.items = [];
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.input.nativeElement.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedValue'] && changes['selectedValue'].currentValue) {
      this.query.setValue(changes['selectedValue'].currentValue.name, {emitEvent: false});
    }
  }

  public setValue(content: string) {
    this.value = content;
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

}
