import { Component } from '@angular/core';
import { SsrService } from "../../services";

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss']
})
export class ShareButtonsComponent {
  public isShowTooltip = false;

  constructor(
    public ssrService: SsrService
  ) {
  }

  toggleShowTooltip(isShow = true): void {
    this.isShowTooltip = isShow;
  }
}
