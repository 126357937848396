import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AddCity,
  RemoveCity,
  RestoreCities,
  SetCityState,
  UpdatePinDate,
  UserCitiesActionsType,
  UserCityActions
} from './user-cities.actions';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { UserService } from '../../services/user-token.service';
import { select, Store } from '@ngrx/store';
import { getPinnedList, getSearchData, State } from '../reducers';
import { UserAccountService } from '../../services/user-account.service';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import values from 'lodash/values';
import { CityInterface, PlacePinType, SearchStateForm } from '../../interfaces';
import { DestinationType } from '../../enums/destination-type';
import { doAsync } from '../../libraries';
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../layer';
import { HttpParams } from '@angular/common/http';
import { SsrService } from '../../services';
import { Subject } from 'rxjs/internal/Subject';
import { UserCollectionsActions } from "../user-collections";
import { UserCollectionService } from "../../services/user-collection.service";
import { Observable } from "rxjs";
import { NGX_USER_CITIES } from "../../../constants";

@Injectable()
export class UserCitiesEffects implements OnDestroy {


  $updateCity = createEffect(() => this.actions$.pipe(
    ofType<UpdatePinDate>(UserCitiesActionsType.UpdatePinDate),
    map((action) => action.payload),
    map((payload) => {
      if (this.userService.loggedIn()) {
        this.userAccountService.updatePin({
          oid: payload.oid,
          otype: payload.otype,
          dateType: payload.dateType,
          dateFrom: payload.dateFrom,
          dateTo: payload.dateTo
        }).subscribe(() => {
        });
      }
    })
  ), {dispatch: false});


  $addUserCity = createEffect(() => this.actions$.pipe(
    ofType<AddCity>(UserCitiesActionsType.AddCity),
    map((action) => action.payload),
    withLatestFrom(this.store$.select(getSearchData)),
    map(([payload]) => {
      const arrPayload = Array.isArray(payload) ? payload : [payload];
      const checkSync = arrPayload.every(obj => obj._sync);

      if (this.userService.loggedIn() && !checkSync) {
        payload = (!isArray(payload)) ? [payload] : payload;
        const data = payload
          .filter((payload_) => !payload_._sync)
          .map(payload_ => ({
            oid: payload_.oid,
            otype: payload_.otype,
            type: payload_.type,
            isPlace: payload_.isPlace,
            collectionId: payload_.collectionId,
            oldCollectionId: payload_.oldCollectionId
          }));
        this.userAccountService.isPinLoading = true;

        const isUpdate = payload[0].isUpdate;

        let observable: Observable<any>;
        if (isUpdate) {
          const pin = data[0];
          observable = this.userAccountService.setPinCollection(pin.oid, pin.otype, pin.type, pin.oldCollectionId, pin.collectionId);
        } else {
          observable = this.userAccountService.addPin(data);
        }
        observable
          .subscribe({
            next: (result) => {
              const response = isUpdate ? result.body : result.body[0];
              if (!('errors' in response)) {
                if (!isUpdate) {
                  this.store$.dispatch(new UserCollectionsActions.CheckAddPin(payload));
                }
                this.store$.dispatch(new UserCollectionsActions.LoadedCollections());
                data.forEach((item) => {
                  this.store$.dispatch(new UserCityActions.MarkSyncCity({oid: item.oid, otype: item.otype}));
                });
                doAsync(() => this.userAccountService.isPinLoading = false, 500);
              }
            },
            error: () => {
            }
          });
      }
      this.userCollectionService.isPinnedAnimation$.next();
      this.userCollectionService.checkLastAdd(false);
    })
  ), {dispatch: false});


  $removeUserCity = createEffect(() => this.actions$.pipe(
    ofType<RemoveCity>(UserCitiesActionsType.RemoveCity),
    map((action) => {
      if (this.userService.loggedIn()) {
        this.userAccountService.isPinLoading = true;
        this.userAccountService.deletePin(
          action.payload.oid,
          action.payload.otype,
          action.payload.type,
          action.payload?.collectionId || null
        )
          .subscribe(() => {
            this.store$.dispatch(new UserCityActions.RefreshCitiesApi());
            this.store$.dispatch(new UserCollectionsActions.RemovePin(action.payload));
            doAsync(() => this.userAccountService.isPinLoading = false, 500);
          }, () => {
          });
      } else {
        this.store$.dispatch(new UserCollectionsActions.RemovePin(action.payload));
      }
      this.userCollectionService.checkLastAdd(false);
    })
  ), {dispatch: false});


  $synchronizeCities = createEffect(() => this.actions$.pipe(
    ofType(UserCitiesActionsType.SynchronizeCities),
    map((action) => {
      this.store$.pipe(
        select(getPinnedList),
        take(1),
        map((userCities) => {
          const requests: CityInterface[] = [];
          const nextPayload = userCities
            .filter((item) => !item._sync)
            .map((itemObj) => {
              return itemObj._pinTypes.map((pinnedType) => {
                const payload = {type: pinnedType, oid: itemObj.oid, otype: itemObj.otype};
                switch (itemObj.otype) {
                  case DestinationType.Place:
                    payload['place'] = itemObj.place;
                    break;
                  case DestinationType.City:
                    payload['city'] = itemObj.city;
                    break;
                  case DestinationType.Restaurant:
                    payload['restaurant'] = itemObj.restaurant;
                    break;
                }
                if (action['collectionId']) {
                  payload['collectionId'] = action['collectionId'];
                }
                return payload;
              });
            })
            .map((obj) => obj[0]);
          this.store$.dispatch(new AddCity(nextPayload));
          return requests;
        })
      ).subscribe();
    })
  ), {dispatch: false});

  // load pinned places from api if authorized only on first request, then just working with store state

  $loadCities$ = createEffect(() => this.actions$.pipe(
    ofType(UserCitiesActionsType.LoadCities),
    map(() => {
      if (this.userService.loggedIn() && !this.citiesLoaded) {
        this.citiesLoaded = true;
        this.store$
          .pipe(
            take(1),
            select(getSearchData),
            map((searchState: SearchStateForm) => {
              const httpParams = new HttpParams()
                .append('filter[cityFrom]', (searchState.cityFrom) ? searchState.cityFrom.id.toString() : '')
                .append('filter[paxCount]', searchState.passengers.toString())
                .append('filter[flightType]', transformTripTypeToApi(searchState.tripType))
                .append('filter[dateType]', transformDateToApiDateType(searchState.date).toString())
                .append('filter[when]', transformWhenDateToApiDateType(searchState.date))
                .append('filter[return]', transformReturnToApiDateType(searchState.date) || '')
                .append(
                  'expand',
                  'pinDate,city.pictures,city.flights,city.country,place.pictures,place.city.flights,place.city.country,place.country,restaurant.city.flights,restaurant.city.country,place.video,countries.country.pictures'
                );
              this.userAccountService.getPinList(httpParams)
                .subscribe((response) => {
                  const items = response.items.map((item) => ({
                    ...item,
                    wannaGoCreated: item.type === PlacePinType.WannaGo ? item.creationDate : null,
                    wannaGo: item.type === PlacePinType.WannaGo,
                    alreadyBeenCreated: item.type === PlacePinType.AlreadyBeen ? item.creationDate : null,
                    alreadyBeen: item.type === PlacePinType.AlreadyBeen,
                  }));
                  const cities = flatten(values(groupBy(items, ['oid', 'otype'])))
                    .map((item) => {
                      const _pinTypes = (() => {
                        const types = [];
                        if (item.wannaGo) {
                          types.push(PlacePinType.WannaGo);
                        }
                        if (item.alreadyBeen) {
                          types.push(PlacePinType.AlreadyBeen);
                        }
                        return types;
                      })();
                      delete item.wannaGoCreated;
                      delete item.wannaGo;
                      delete item.alreadyBeenCreated;
                      delete item.alreadyBeen;
                      delete item.type;
                      return {
                        ...item,
                        _sync: true,
                        _pinTypes: _pinTypes
                      };
                    });
                  this.store$.dispatch(new SetCityState({cities: cities}));
                });
            })
          )
          .subscribe();
      }
    })
  ), {dispatch: false});

  private citiesLoaded = false;
  private $destroyed = new Subject<void>();
  private userCities: any[] = [];
  public resizeWindow = 0;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private userService: UserService,
    private userAccountService: UserAccountService,
    private ssrService: SsrService,
    private userCollectionService: UserCollectionService,
  ) {
    if (ssrService.isBrowser()) {
      this.userCities = JSON.parse(localStorage.getItem(NGX_USER_CITIES)) || [];
      if (this.userCities.length) {
        this.store$.dispatch(new RestoreCities(this.userCities));
      }

      this.store$.pipe(
        select(getPinnedList),
        takeUntil(this.$destroyed)
      ).subscribe((cities) => {
        this.userCities = [...cities];

        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_USER_CITIES, JSON.stringify(this.userCities));
        }

        if (!userService.loggedIn()) {
          if (this.userCities?.length) {
            this.store$.dispatch(new UserCollectionsActions.CheckAddPin(this.userCities));
          }
        }
      });
      this.resizeWindow = window.innerWidth;
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  isMobile() {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile()
      || this.ssrService.isBrowser() && this.resizeWindow <= 896;
  }

}
