import { PlaceNew } from '../interfaces';
import { InfoService, MapService, WindowRef } from '../services';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Directive, HostListener, Inject } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, KeyValue } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { AjaxFinish } from '../store/layer';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import { StaticService } from "../services/static.service";
import { HelperService } from "../services/helper.service";
import { untilDestroyed } from "@ngneat/until-destroy";
import { DEFAULT_ZOOM } from "../../constants";
import clone from 'lodash/clone';

@Directive()
export class SeeDoMapClass {
  public flightPlaceInfo: any;
  public pinnedPlace: any;
  public isMapExtend: boolean;
  public isMapBottom: boolean;
  public isMapFixed: boolean;
  public isTablet: boolean;
  public isMobile: boolean;
  public isMobileMap: boolean;
  public idFrom = 560;
  public emptyRequests = 0;

  public tabletWidth = 900;
  public mobileWidth = 500;
  public showPlaces$: Observable<PlaceNew[]>;
  public searchMapExtra: any;

  protected window;

  public oldAdditionParams: any[];

  // map scrolling variables

  constructor(
    protected infoService: InfoService,
    protected mapService: MapService,
    protected cdRef: ChangeDetectorRef,
    protected store$: Store<State>,
    protected pageScrollService: PageScrollService,
    @Inject(DOCUMENT) protected document: any,
    protected windowRef: WindowRef,
    public staticService: StaticService,
    protected helperService: HelperService,
  ) {
    this.window = windowRef.getNativeWindow();
    if (this.window) {
      this.checkScreenVersion();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.checkScreenVersion();
    const rightSideWrapper = document.getElementById('right-side-wrapper');
    const map = document.getElementById('map');
    if (map && rightSideWrapper) {
      map.style.width = rightSideWrapper.offsetWidth + 'px';
    }
  }

  public extendMap($event) {
    this.isMapFixed = false;
    this.isMapBottom = false;
    this.isMapExtend = $event;
  }

  public openPinnedPlaces(configuration) {
    this.pinnedPlace = configuration.place;
  }

  public loaded() {
    if (this.staticService.isLoading) {
      return false;
    }
    return this.emptyRequests <= 1;
  }

  public changeVisibleTooltipPlace(place: PlaceNew) {
    this.flightPlaceInfo = null;
    this.cdRef.detectChanges();

    this.staticService.visibleTooltipPlace = place;
  }

  public updateTooltips(event, additionParams?: KeyValue<string, string>[]) {
    const hasFilters = additionParams.length > 0 || !!event.coordinates;
    if (!hasFilters) {
      return;
    }
    const hasViewPortWidth = additionParams.some(p => p.key === 'filter[viewPortWidth]');
    if (hasViewPortWidth &&
      JSON.stringify(this.oldAdditionParams) === JSON.stringify(additionParams)) {
      return;
    } else {
      this.oldAdditionParams = !!additionParams ? clone(additionParams) : [];
      const coordinates = event.coordinates;
      const zoom = event.zoom;
      this.flightPlaceInfo = null;
      this.cdRef.detectChanges();

      let httpParams = new HttpParams();

      httpParams = httpParams.set('expand', 'country,video');
      if (coordinates && (!additionParams || !hasViewPortWidth)) {
        httpParams = httpParams.append('filter[latNorth]', coordinates.latNorth.toString());
        httpParams = httpParams.append('filter[latSouth]', coordinates.latSouth.toString());
        httpParams = httpParams.append('filter[lngWest]', coordinates.lngWest.toString());
        httpParams = httpParams.append('filter[lngEast]', coordinates.lngEast.toString());
        httpParams = httpParams.append('filter[zoom]', zoom ? zoom?.toString() : DEFAULT_ZOOM?.toString());
      }

      additionParams?.forEach(item => {
        if (
          (item.key !== 'filter[tagId][]' && item.key !== 'filter[countryId]')
          || (item.key === 'filter[tagId][]' && this.helperService.checkIsNumeric(item.value))
          || (item.key === 'filter[countryId]' && this.helperService.checkIsCountry(item.value))
        ) {
          httpParams = httpParams.append(item.key, item.value);
        }
      });

      this.mapService.searchPlaces(httpParams, {withHeaders: true})
        .pipe(untilDestroyed(this))
        .subscribe(response => {
          if (response.__headers.has('x-zoom')) {
            this.staticService.zoom = +response.__headers.get('x-zoom');
          }
          if (response.__headers.has('x-lat')) {
            this.staticService.lat = +response.__headers.get('x-lat');
          }
          if (response.__headers.has('x-lng')) {
            this.staticService.lng = +response.__headers.get('x-lng');
          }
          this.mapService.map.setZoom(this.staticService.zoom);
          if (response.__headers.has('x-lat') || response.__headers.has('x-lng')) {
            this.mapService.map.setCenter({lat: this.staticService.lat, lng: this.staticService.lng});
          }

          response = Object.values(response);
          response.pop();
          this.store$.dispatch(new AjaxFinish({uid: null}));
          this.showPlaces$ = of(response);
        })
    }
  }

  public checkScreenVersion() {
    this.isMobile = window.innerWidth <= this.mobileWidth;
    this.isTablet = window.innerWidth <= this.tabletWidth;
  }

}
