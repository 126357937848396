import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IUploadFile } from '../interface/interface';
import { UploadService } from '../services/upload-service';
import { takeUntil, tap } from "rxjs/operators";
import { SsrService } from "../../../services";
import { DestroyComponent } from "../../../heplers/destroy.component";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { HelperService } from "../../../services/helper.service";
import { typeMedia } from "../../place/your-own-place/your-own-place.component";
import { ErrorEnum } from "../../../enums/error.enum";
import { ToastrService } from "../../../services/toastr.service";
import { UploadProgressInterface } from "../../../interfaces/upload-progress";
import { StaticService } from "../../../services/static.service";
import { StaticRouteType } from "../../static/enums/route-type";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { constructMediaURL } from "../../../libraries";

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent extends DestroyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() accept: string;
  @Input() buttonText: string;
  @Input() buttonImage: string;
  @Input() isProfile = false;
  @Input() length: number;
  @Input() maxLength: number;
  @Input() multiple: boolean;
  @Input() type: string = typeMedia.photo;
  @Input() uploadFiles: IUploadFile[] = [];
  @Input() mobileView: boolean;
  @Input() isEditForm: boolean;
  @Input() isShowProgress: boolean;
  @Input() addToProgress: boolean;
  @Input() isAjaxLoad: boolean;
  @Input() indexProgress = 0;

  @Output() deleteFileEmitter: EventEmitter<IUploadFile> = new EventEmitter<IUploadFile>();
  @Output() updateFilesEmitter: EventEmitter<IUploadFile[]> = new EventEmitter<IUploadFile[]>();
  @Output() errorMessageEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadFile: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('image') imageVariable: ElementRef;

  public activeIndex = 0;
  public files = [];
  public isOpenGallery: boolean;
  public windowSize = 0;
  public maxSizeMb = 512;
  public maxResolutionPx = 4096;
  public maxDurationMinutes = 3;
  public image: any;
  public isWindowFocus = true;
  public isFileLoaded: boolean = true;
  public isPhoto: boolean;
  public isCurrentComponent: boolean;

  public successUploadPopupData: boolean[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowSize = event.target.innerWidth;
  }

  constructor(
    private uploadService: UploadService,
    private ssrService: SsrService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private staticService: StaticService,
  ) {
    super();
    if (this.ssrService.isBrowser()) {
      window.addEventListener('focus', () => {
        this.isWindowFocus = true;
      });
      window.addEventListener('blur', () => {
        this.isWindowFocus = false;
      });
      this.windowSize = typeof window !== 'undefined' ? window.innerWidth : 0;
    }
  }

  ngOnInit(): void {
    if (!this.isAjaxLoad) {
      this.helperService.ajaxLoaderVisible$.next(false);
    }
    this.isFileLoaded = this.uploadFiles.length !== this.maxLength;
    this.successUploadPopupData = this.uploadService.successUploadPopupData$.getValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.type?.currentValue) {
      this.isPhoto = changes.type.currentValue === 'photo';
    }
    if (changes?.uploadFiles?.currentValue
      && Array.isArray(changes?.uploadFiles?.currentValue)
      && changes?.uploadFiles?.currentValue?.length) {
      const generatedVideo = [];
      changes.uploadFiles.currentValue.forEach(x => {
        generatedVideo.push(this.generateVideoData(x));
      });
      this.uploadFiles = generatedVideo;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.helperService.ajaxLoaderVisible$.next(true);
  }

  public addImage(fileInputEvent: any): void {
    this.isCurrentComponent = true;
    this.isFileLoaded = false;
    this.files = [];
    this.showError('');
    const inputFiles = fileInputEvent.target.files;

    if (inputFiles[0]) {
      for (const file of inputFiles) {
        const fileSizeMb = Math.floor(file.size / 1024 / 1024);
        if (fileSizeMb > this.maxSizeMb) {
          this.isFileLoaded = true;
          return this.showError(`The file size cannot be greater than ${this.maxSizeMb} MB`);
        }
      }
      if (this.length === 0) {
        this.isFileLoaded = true;
        return this.showError(`Sorry, you cannot add more than ${this.maxLength} ${this.type}s `);
      }
      for (const file of inputFiles) {
        this.files.push(file);
      }

      if (this.uploadFiles && this.uploadFiles.length) {
        const availableFileName = this.uploadFiles.map(file => file.name);
        this.files = this.files.filter(file => !(availableFileName.includes(file.name)));
      }

      if (!this.isPhoto) {
        let video = document.createElement('video');
        video.preload = 'metadata';
        const that = this;
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          if (video.videoWidth > that.maxResolutionPx || video.videoHeight > that.maxResolutionPx) {
            that.isFileLoaded = true;
            return that.showError(`Maximum 4K resolution is only allowed`);
          }
          if (video.duration / 60 > that.maxDurationMinutes) {
            that.isFileLoaded = true;
            return that.showError(`The video duration cannot be greater than ${that.maxDurationMinutes} minutes`);
          } else {
            for (const file of that.files) {
              that.uploadMedia(file);
            }
          }
        };
        video.src = URL.createObjectURL(fileInputEvent.target.files[0]);
      }
      if (this.isPhoto) {
        for (const file of this.files) {
          this.uploadMedia(file);
        }
      }

      if (!this.files?.length) {
        this.toastrService.activeToastr$.next({
          message: `This file has already been added to this block`,
          timeout: 3000
        });
        this.isFileLoaded = true;
      }

    } else {
      this.isFileLoaded = true;
      this.isCurrentComponent = false;
    }
  }

  public toggleGallery(value: boolean, index?: number): void {
    this.isOpenGallery = value;
    this.activeIndex = index;
  }

  public deleteFile(file) {
    this.uploadFiles = this.uploadFiles.filter(uploadFile => uploadFile.id !== file.id);

    const currentKey = file.size + file.name;

    if (this.addToProgress) {
      let uploadPopupData = this.uploadService.uploadPopupData$.getValue();
      if (uploadPopupData[this.indexProgress]) {
        uploadPopupData[this.indexProgress] = uploadPopupData[this.indexProgress].filter(item => item.id != currentKey);
        this.uploadService.uploadPopupData$.next(uploadPopupData);
      }
    }

    if (file.isUpload || !file.id) {
      this.deleteFileEmitter.emit(file);
    } else {
      this.uploadService.delete(file.id)
        .subscribe(() => {
          this.showError('');
          this.isFileLoaded = true;
          this.deleteFileEmitter.emit(file);
        });
    }
  }

  public onClickInput(event) {
    event.target.value = ''
  }

  private uploadMedia(file) {
    let uploadPopupData: [UploadProgressInterface[]] = [[]];
    let currentKey = null;
    this.uploadService.upload([file], true)
      .pipe(takeUntil(this.destroy$),
        tap(() => {
          if (this.addToProgress) {
            uploadPopupData = this.uploadService.uploadPopupData$.getValue();
          }
          currentKey = file.size + file.name;
        }))
      .subscribe((event: HttpEvent<any>) => {
          const currentFile = uploadPopupData && uploadPopupData[this.indexProgress] &&
            uploadPopupData[this.indexProgress]?.find(item => item.id === currentKey);

          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (this.successUploadPopupData[this.indexProgress]) {
                this.successUploadPopupData[this.indexProgress] = false;
                this.uploadService.successUploadPopupData$.next(this.successUploadPopupData);
              }
              if (currentFile) {
                currentFile.loaded = (event.loaded).toFixed(1);
                currentFile.total = (event.total).toFixed(1);
                currentFile.progress = event.loaded / event.total * 100;
              } else {
                if (!uploadPopupData[this.indexProgress]) {
                  uploadPopupData[this.indexProgress] = [];
                }
                uploadPopupData[this.indexProgress].push({
                  id: currentKey,
                  file: file,
                  type: this.type,
                  loaded: (event.loaded).toFixed(1),
                  total: (event.total).toFixed(1),
                  progress: event.loaded / event.total * 100
                })
              }
              break;
            case HttpEventType.Response:
              const uploadFile = this.generateVideoData(event.body);
              this.uploadFiles.push(uploadFile);
              this.updateFilesEmitter.emit(this.uploadFiles);
              this.uploadFile.emit(uploadFile);
              this.isFileLoaded = this.uploadFiles.length !== this.maxLength;
              this.isCurrentComponent = false;
              break;
          }
          if (this.addToProgress) {
            this.uploadService.uploadPopupData$.next(uploadPopupData);
          }
        },
        (error) => {
          uploadPopupData[this.indexProgress] = uploadPopupData[this.indexProgress].filter(item => item.id != currentKey);
          if (this.addToProgress) {
            this.uploadService.uploadPopupData$.next(uploadPopupData);
          }
          this.isFileLoaded = true;
          this.isCurrentComponent = false;
          if (error?.error && error?.error[0]?.message) {
            if (this.staticService.staticType === StaticRouteType.Account) {
              this.toastrService.activeToastr$.next({message: error.error[0].message});
            } else {
              this.showError(error.error[0].message);
            }
          } else {
            this.toastrService.activeToastr$.next({message: ErrorEnum.Default});
          }
          this.successUploadPopupData[this.indexProgress] = true;
          this.uploadService.successUploadPopupData$.next(this.successUploadPopupData);
        },
        () => {
          this.successUploadPopupData[this.indexProgress] = true;
          this.uploadService.successUploadPopupData$.next(this.successUploadPopupData);
        }
      );
  }

  protected showError(text: string) {
    this.errorMessageEmitter.emit(text);
  }

  private generateVideoData(uploadFile) {
    if (this.type === typeMedia.video && uploadFile && typeof uploadFile === 'object') {
      uploadFile.isVideo = true;
      uploadFile.isReady = true;
      if (!uploadFile.path && uploadFile.url) {
        uploadFile.path = uploadFile.url;
      }
      if (!uploadFile.poster && uploadFile.urlThumbnail) {
        uploadFile.poster = {};
        uploadFile.poster.thumb = uploadFile.urlThumbnail;
      }
      if (!uploadFile.preview && uploadFile.urlThumbnail) {
        uploadFile.preview = {};
        uploadFile.preview.thumb = uploadFile.urlThumbnail;
      }
    }
    return uploadFile;
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.uploadFiles, event.previousContainer.data.index, event.container.data.index);
    this.updateFilesEmitter.emit(this.uploadFiles);
  }

  protected readonly constructMediaURL = constructMediaURL;
}
