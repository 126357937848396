import { Component, OnInit, ViewChild } from '@angular/core';
import { FlyAdDirective } from '../../directives/fly-add.directive';
import { FlyComponentService } from '../../services/fly-component-service/fly-component.service';
import { FlyAdComponent } from '../../services/fly-component-service/fly-add.component';

@Component({
  selector: 'app-fly-modal-component',
  templateUrl: 'fly-modal.component.html',
  styleUrls: ['fly-modal.component.scss']
})
export class FlyModalComponent implements OnInit {

  public interval: any;
  public show: boolean;

  @ViewChild(FlyAdDirective) flyAdHost: FlyAdDirective;

  constructor(
    private componentService: FlyComponentService,
  ) {
  }

  ngOnInit() {
    this.componentService.init(this.boundMethod.bind(this));
  }

  boundMethod(instance) {
    let viewContainerRef = this.flyAdHost.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(instance.component);
    (<FlyAdComponent>componentRef.instance).data = {data: instance.data, boundCallback: this.hideModal.bind(this)};
    this.showModal();
  }

  showModal() {
    this.show = true;
  }

  hideModal() {
    this.show = false;
  }

}
