<div class="modal">
  <div class="modal__container">
    <img src="assets/icons/arrow-right-black.svg"
         alt="prev btn"
         class="prev-btn"
         (click)="cancelCreateCollection()">
    <app-create-collection (emitCreateCollection)="createCollection($event)"
                           (emitCancelCreateCollection)="cancelCreateCollection()"></app-create-collection>
  </div>
</div>
