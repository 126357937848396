import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionInputComponent } from "./collection-input.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CreateCollectionModalComponent } from './create-collection-modal/create-collection-modal.component';
import {
  CreateCollectionModule
} from "../../../shared-components/pinned-panel/create-collection/create-collection.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    CreateCollectionModule
  ],
  declarations: [
    CollectionInputComponent,
    CreateCollectionModalComponent
  ],
  exports: [
    CollectionInputComponent
  ]
})
export class CollectionInputModule {
}
