export const CONTACT_US_ACTION = {
  general_enquiries: 'general_enquiries',
  leave_feedback: 'leave_feedback',
  copyright_complaint: 'copyright_complaint',
};

export const CONTACT_US_LINKS  = [
  {
    name: 'General Enquiries',
    action: CONTACT_US_ACTION.general_enquiries
  },
  {
    name: 'Leave Feedback',
    action: CONTACT_US_ACTION.leave_feedback
  },
  {
    name: 'Copyright Complaint',
    url: '/copyright-infringement'
  },
];
