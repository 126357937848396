import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getSearchData, State } from '../../../../store/reducers';
import { map, take, takeUntil } from 'rxjs/operators';
import { DropDownValue } from '../../../shared/modules/drop-down/containers/drop-down/drop-down.component';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-profile-user-settings',
  templateUrl: 'profile-user-settings.component.html',
  styleUrls: ['profile-user-settings.component.scss']
})
export class ProfileUserSettingsComponent implements OnDestroy, OnChanges {
  @Input() data: any;
  @Input() fields: any;

  @Output() changedPasswordEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeAccountEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() getProfile: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  public auth: any = null;
  public isConfirm = false;
  public options: DropDownValue[] = [{title: 'C', value: 'C', selected: true}, {
    title: 'F',
    value: 'F',
    selected: false
  }];
  public title = 'C';

  private $destroyed = new Subject<void>();

  constructor(
    private store$: Store<State>
  ) {
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.title = this.data.settings.find(item => item.id === 'temperatureUnit').value;
      this.options.forEach(item => (item.value === this.title) ? item.selected = true : item.selected = false);
      const user = changes['data'].currentValue;
      this.auth = user.auth;
    }
  }

  public toggleEditor(key: string, value: boolean): void {
    this.toggle.emit({key, value});
  }

  public closeAccount(value: boolean): void {
    (!value) ?
      this.isConfirm = false :
      this.closeAccountEmitter.emit();
  }

  // public selectTemperature(data: any): void {
  //   this.title = data.value;
  //   this.changeSettings.emit({temperatureUnit: data.value});
  // }

  public openDialog(): void {
    this.isConfirm = true;
  }

  public changedPassword(): void {
    this.changedPasswordEmitter.emit();
  }

  public doOnUnlink(): void {
    this.auth = null;
    this.store$.pipe(
      take(1),
      select(getSearchData),
      map(() => {
        this.getProfile.emit();
      }),
      takeUntil(this.$destroyed)
    ).subscribe();
  }
}
