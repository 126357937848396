import { environment } from '../../environments/environment';

interface ImgFormat {
  large: string;
  thumb: string;
  title?: string;
}

export const formImgSrc = (picture: ImgFormat) => {
  if (picture && picture.large && picture.thumb) return {
    large: constructMediaURL(picture.large),
    title: constructMediaURL(picture.title),
    thumb: constructMediaURL(picture.thumb)
  };
  return null;
};

export const constructMediaURL = (path: string = '/images/default.jpg') => {
  // TODO: add checking is image extension consist in path
  const defaultPath = '/images/default.jpg';
  if (typeof path === 'string' && !!path.length) {
    return path.indexOf('http') === -1 ? `${environment.mediaUrl + path}` : path;
  }

  return constructMediaURL(defaultPath);
};

export const constructBaseUrl = (path: string): string => {
  return path.indexOf('http') === -1 ? `${environment.baseUrl + path}` : path;
}
