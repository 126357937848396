import { Action } from '@ngrx/store';
import { MapDateType, PinInterface, PlacePinType } from '../../interfaces';
import { DestinationType } from '../../enums/destination-type';
import { Journey } from "../../interfaces/journey";

export enum UserCitiesActionsType {
  RefreshCitiesApi = '[UserCities] Refresh places API',
  SynchronizeCities = '[UserCities] Synchronize cities',
  ResetState = '[UserCities] Reset state',
  RestoreCities = '[UserCities] Restore cities',
  AddCity = '[UserCities] Add city',
  RemoveCity = '[UserCities] Remove city',
  RemovePinsFromCollection = '[UserCities] Remove pins from collection',
  MarkSyncCity = '[UserCities] Mark as sync',
  SetCityState = '[UserCities] Set state',
  UpdatePinDate = '[UserCities] Update pin date',
  LoadCities = '[UserCities] Load cities',
}

export class UpdatePinDate implements Action {
  readonly type = UserCitiesActionsType.UpdatePinDate;

  constructor(public payload: PinInterface) {
  }
}

export class SetCityState implements Action {
  readonly type = UserCitiesActionsType.SetCityState;

  constructor(public payload: { cities: PinInterface[] }) {
  }
}

export class MarkSyncCity implements Action {
  readonly type = UserCitiesActionsType.MarkSyncCity;

  constructor(public payload: { oid: number, otype: number }) {
  }
}

export class RefreshCitiesApi implements Action {
  readonly type = UserCitiesActionsType.RefreshCitiesApi;
}

export class SynchronizeCities implements Action {
  readonly type = UserCitiesActionsType.SynchronizeCities;

  constructor(public collectionId?: number) {
  }
}

export class ResetState implements Action {
  readonly type = UserCitiesActionsType.ResetState;
}

export class RestoreCities implements Action {
  readonly type = UserCitiesActionsType.RestoreCities;

  constructor(public payload: PinInterface[]) {
  }
}

export class AddCity implements Action {
  readonly type = UserCitiesActionsType.AddCity;

  constructor(public payload: PinInterface | PinInterface[] | any) {
  }
}

export const createAddCity = (place: any, type: PlacePinType, dateType?: MapDateType, dateFrom?: string | Date, dateTo?: string | Date, defaultCollections?: Journey) => {
  const payload = {
    oid: place.id,
    otype: place.otype,
    type: type,
    collectionId: defaultCollections ? defaultCollections.id : null,
  };
  if (dateType) {
    payload['dateType'] = dateType;
    payload['dateFrom'] = dateFrom;
    payload['dateTo'] = dateTo;
  }
  const commonData = {
    id: place.id,
    name: place.name,
    otype: place.otype,
    pictures: place.pictures,
    lat: place.lat,
    lng: place.lng,
    urlKeywords: place.urlKeywords
  };
  switch (place.otype) {
    case DestinationType.Place:
      payload['place'] = {
        ...commonData,
        ...place.city && {
          city: {
            id: place.city.id,
            name: place.city.name,
            country: {
              id: place.city.country.id,
              name: place.city.country.name,
              shortName: place.city.country.shortName,
            }
          }
        },
        ...place.country && {
          country: place.country
        },
        ...place.video && {
          video: place.video
        },
        isPlace: true
      };
      break;
    case DestinationType.City:
      payload['city'] = {
        ...commonData,
        country: {
          id: place.country.id,
          name: place.country.name,
          shortName: place.country.shortName,
        },
        isPlace: false
      };
      break;
    case DestinationType.Restaurant:
      payload['restaurant'] = {
        ...commonData,
        city: {
          id: place.city.id,
          country: {
            id: place.city.country.id,
            name: place.city.country.name,
            shortName: place.country.shortName,
          }
        },
        isPlace: false
      };
      break;
    case DestinationType.Country:
      payload['country'] = {
        ...commonData,
        isPlace: false
      };
      break;
  }
  return new AddCity(payload);
};

export class RemoveCity implements Action {
  readonly type = UserCitiesActionsType.RemoveCity;

  constructor(public payload: { oid: number, otype: number, type: PlacePinType | number, collectionId?: number }) {
  }
}

export class RemovePinsFromCollection implements Action {
  readonly type = UserCitiesActionsType.RemovePinsFromCollection;

  constructor(public payload: Journey | any) {
  }
}

export class LoadCities implements Action {
  readonly type = UserCitiesActionsType.LoadCities;
}

export type UserCitiesActionsUnion =
  SynchronizeCities
  | RefreshCitiesApi
  | ResetState
  | RestoreCities
  | AddCity
  | RemoveCity
  | RemovePinsFromCollection
  | MarkSyncCity
  | SetCityState
  | UpdatePinDate
  | LoadCities
  ;

export const UserCityActions = {
  RefreshCitiesApi,
  AddCity,
  SynchronizeCities,
  ResetState,
  MarkSyncCity,
  SetCityState,
  UpdateCity: UpdatePinDate,
  RemovePinsFromCollection,
  LoadCities
};
