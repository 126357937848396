import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactInfoDisplayComponent } from './contact-info-display/contact-info-display.component';
import { ContactInfoEditComponent } from './contact-info-edit/contact-info-edit.component';
import { FormsModule } from "@angular/forms";
import { ScheduleFormModule } from "../shared/schedule-form/schedule-form.module";
import { ShowSchedulePipe } from "../../pipes/show-schedule.pipe";
import { AutofocusDirective } from "../../directives/autofocus.directive";
import { EditContactInputComponent } from './contact-info-edit/edit-contact-input/edit-contact-input.component';

@NgModule({
  declarations: [
    ContactInfoDisplayComponent,
    ContactInfoEditComponent,
    ShowSchedulePipe,
    AutofocusDirective,
    EditContactInputComponent,
  ],
  exports: [
    ContactInfoDisplayComponent,
    ContactInfoEditComponent
  ],
    imports: [
        FormsModule,
        CommonModule,
        ScheduleFormModule
    ]
})
export class ContactInfoModule { }
