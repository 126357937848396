<div *ngIf="!emailSuccessfullySent">
  <div class="progress-bar">
    <mat-progress-bar
      *ngIf="ajaxState$ | async"
      mode="indeterminate">
    </mat-progress-bar>
  </div>

  <div class="form-container">

    <header class="fly-text-c">
      <h1 i18n="labels.auth.register" [innerText]="'labels.auth.create_account' | translate"> Create account </h1>
    </header>

    <form name='reset-pass-form' class="auth-form" [formGroup]="registerForm"
          (ngSubmit)="!needResendEmail && registerWithEmail(registerForm)">

      <div class="form-group">
        <div class="validation-errors" *ngIf="generalError">
          <div *ngFor="let message of generalError">
            {{message.value}}
          </div>
        </div>

        <div class="email-sent-successfully" *ngIf='emailResendSuccessfully'>{{emailResendSuccessfully}}</div>

        <div *ngIf="emailSuccessfullySent" class="email-sent-successfully">
                <span i18n="success.email_sended_successfully"
                      [innerText]="'success.email_sended_successfully' | translate">
                    We sent you a confirmation email with a link to activate your registration. Please check your email and click the link.
                </span>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{'translate-bottom': !emailFocused}" class="pass-label" for="email" i18n="labels.email"
               innerText="'labels.email' | translate">
          e-mail </label>

        <span class="pass-v-icon" *ngIf="emailUpdated" [ngClass]="validationStyles('email')"></span>

        <input type="email"
               (blur)="valueUpdated('email')"
               (focus)="focus('email')"
               id="email"
               class="form-control"
               autocomplete="new-password"
               formControlName="email">
        <div *ngIf='resOfEmailValidation' class="validation-errors">{{resOfEmailValidation}}</div>
      </div>

      <div class="form-group">
        <label [ngClass]="{'translate-bottom': !passFocused}" class="pass-label" for="pass"
               i18n="labels.create_password" innerText="'labels.create_password' | translate">
          Create password </label>

        <span class="pass-v-icon" *ngIf="passUpdated" [ngClass]="validationStyles('pass')"></span>

        <input [attr.type]="passwordType"
               (blur)="valueUpdated('pass')"
               (focus)="focus('pass')"
               id='pass'
               class="form-control"
               autocomplete="new-password"
               formControlName="pass">
        <div *ngIf='resOfPasswordValidation' class="validation-errors">{{resOfPasswordValidation}}</div>
      </div>

      <div class="form-group remember-me">
        <mat-checkbox color="blue" (click)="rememberUser()" [checked]="true">
          Remember me
        </mat-checkbox>
        <div (click)="changePasswordView()">
          <label [ngClass]="{'label-active': (passwordType === 'text')}"> {{ passwordType !== 'text' ? 'Show' : 'Hide' }} password </label>
        </div>
      </div>

      <div class="form-group">
        <button mat-raised-button type="submit" class="sign-in-button" i18n="labels.login"
                innerText="'labels.auth.register' | translate"
                [class.disabled]="allowResend">
          Register
        </button>
      </div>

      <div class="form-group remember-me fly-text-c">
        <p class="policy">
          By signing up you agree to Explorow's
          <a [appRouterLink]="'/terms-of-use'" target="_blank">Terms of Service</a> <br/>
          and <a [appRouterLink]="'/privacy-policy'" target="_blank">Privacy Policy</a>
        </p>
      </div>

      <div *ngIf="needResendEmail" class="resend-link account-terms-service fly-text-c" [class.disabled]="!allowResend">
        <label> Email still didn't arrive? Click </label>
        <span (click)="allowResend && resendEmail(registerForm)"> here </span>
        <label> to resend </label>
      </div>
    </form>

    <app-social-net-btn *ngIf="auth" [auth]="auth"></app-social-net-btn>

  </div>
</div>

<div class="account-terms-service fly-text-c">
  <label> Already have an account? </label>
  <div class="create-account" (click)="closeRegisterForm($event)"> Log in</div>
</div>
