import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user-token.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { consoleDebug } from '../../libraries/console-debug';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: [
    '../../modules/user-account/components/profile-pass-change/profile-pass-change.component.scss',
    'reset-password-form.component.scss'
  ]
})
export class ResetPasswordFormComponent {

  public allowSubmit: boolean = true;
  public emailFocused: boolean = false;
  public emailResendSuccessfully: any;
  public emailUpdated;
  public generalError: any;
  public isLoading: boolean = false;
  public resetForm;
  public resOfEmailValidation: any;

  @Output() resPasswordSuccess = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    fb: UntypedFormBuilder
  ) {
    this.resetForm = fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern())]]
    }, {updateOn: 'blur'});
  }

  emailPattern() {
    return new RegExp('^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$');
  }

  validationStyles(formCtrl) {
    if (!this.readVal(formCtrl)) return null;
    return {'fly-pass-valid': this.validationStyling(formCtrl), 'fly-pass-invalid': !this.validationStyling(formCtrl)};
  }

  validationStyling(formCtrl) {
    return this.resetForm.controls[formCtrl].valid;
  }

  readVal(formCtrlName) {
    return this.resetForm.controls[formCtrlName].value;
  }

  sendEmail(resetForm: any) {
    this.generalError = null;
    this.emailValidate(resetForm);
    if (!resetForm.valid) {
      return;
    }
    this.isLoading = true;
    this.userService.disableSubmitForWhile(this);
    this.resOfEmailValidation = null;
    let params = {email: resetForm.controls.email.value};
    this.authService
      .authReset(params)
      .subscribe(
        () => {
          this.userService.disableLoadingBar(this);
          this.resPasswordSuccess.emit(true);
        },
        (err) => {
          this.userService.disableLoadingBar(this);
          if (err.status == 201) {
            // i18n
            this.saveTranslationIntoProperty('success.mail_sended', 'emailResendSuccessfully');
            this.generalError = null;
            return;
          }

          if (err.status == 422) {
            this.emailResendSuccessfully = null;
            this.caseErrorMessage(err.error[0]);
          } else if (err.status > 300) {
            consoleDebug('err.status', err.status);
            let message = err.error.message || err.error[0].message;
            this.emailResendSuccessfully = null;
            this.generalError = this.mapResponse(message);
          }
        });
  }

  caseErrorMessage(error) {
    let message = error.message;
    switch (error.field) {
      case 'email':
        this.resOfEmailValidation = message;
        break;

      case 'system':
        this.generalError = this.mapResponse(message);
        break;

      default:
        this.generalError = this.mapResponse(message);
        break;
    }
  }

  mapResponse(message) {
    return [{
      value: message
    }];
  }

  valueUpdated(propName) {
    this[`${propName}Updated`] = true;
    if (this.resetForm.controls[propName].value.length > 0) return;
    if (propName == 'email') {
      this.emailFocused = false;
    }
  }

  focus(type) {
    if (type == 'email') this.emailFocused = true;
  }


  emailValidate(f) {
    if (f.controls.email.status == 'invalid'.toUpperCase()) {
      // i18n
      let id = 'errors.wrong_email';
      if (f.controls.email.value.length == 0) {
        this.resOfEmailValidation = 'This field is required';
      } else {
        this.saveTranslationIntoProperty(id, 'resOfEmailValidation');
      }
    } else {
      this.resOfEmailValidation = null;
    }
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    // i18n
    this.userService.getTranslation(id).pipe(take(1)).subscribe(
      (res) => {
        this[whereToSave] = res;
      }
    );
  }

}
