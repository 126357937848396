import { Observable } from 'rxjs/internal/Observable';
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType,
  TripTypeEnum
} from '../store/layer';
import { getSearchData, State } from '../store/reducers';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { InfoService } from '../services';
import { BookingPopupComponent } from '../modules/shared/components/booking-popup/booking-popup.component';
import {
  ApplicationRef,
  Injector,
  ViewContainerRef
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { getCityValue } from '../libraries/map-object-helper';
import { MapObject, SearchStateForm } from '../interfaces';

export abstract class FlightFrameBuilder {
  public searchData$: Observable<SearchStateForm> = this.store$.pipe(select(getSearchData));
  private _place: MapObject;

  protected constructor(
    protected applicationRef: ApplicationRef,
    protected injector: Injector,
    protected store$: Store<State>,
    protected infoService: InfoService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
    protected viewContainerRef: ViewContainerRef,
  ) {
  }

  public openFlightsFrame(place) {
    this._place = place;

    this.searchData$.pipe(
      take(1),
      map(data => {
        const cityTo: any = (this._place && this._place.isPlace ? {
          id: this._place.city.id,
          name: this._place.city.name
        } : this._place) || data.cityTo;
        const iataCode = getCityValue(this._place, 'iataCode');
        if (iataCode && this._place.iataCodeFrom) {
          this.readFlightResponse({iataCode: iataCode, iataCodeFrom: this._place.iataCodeFrom}, data, cityTo);
        } else {
          const httpParams = new HttpParams()
            .append('filter[idFrom]', data.cityFrom.id.toString())
            .append('filter[paxCount]', data.passengers.toString())
            .append('filter[flightType]', transformTripTypeToApi(data.tripType))
            .append('filter[dateType]', transformDateToApiDateType(data.date).toString())
            .append('filter[when]', transformWhenDateToApiDateType(data.date))
            .append('filter[return]', transformReturnToApiDateType(data.date));
          this.infoService
            .getCityById(cityTo.id, httpParams)
            .subscribe((response: any) => {
              this.readFlightResponse(response, data, cityTo);
            });
        }
      })
    )
      .subscribe();
  }

  private readFlightResponse(response, data: SearchStateForm, cityTo) {
    const bookingPopupRef = this.viewContainerRef
      .createComponent(BookingPopupComponent, {injector: this.injector});

    const flights = getCityValue(this._place, 'flights');
    let departureDate, returnDate;
    if (
      !flights.minPrice ||
      data.tripType === 'ROUND_TRIP'
    ) {
      departureDate = data.date[0].date.format('YYYY-MM-DD');
      if (data.tripType === TripTypeEnum.RoundTrip) {
        returnDate = get(
          data,
          'date[1].date',
          dayjs(data.date[0].date).add(1, 'month')
        ).format('YYYY-MM-DD');
      }
    } else {
      departureDate = flights.departDate;
      returnDate = flights.returnDate;
      if (!returnDate && data.tripType === TripTypeEnum.RoundTrip) {
        returnDate = get(
          data,
          'date[1].date',
          dayjs(data.date[0].date).add(1, 'month')
        ).format('YYYY-MM-DD');
      }
    }
    bookingPopupRef.instance.bookingData = {
      from: {
        iata: response.iataCodeFrom || data.cityFrom.iataCode,
        name: data.cityFrom.name
      },
      to: {
        iata: response.iataCode,
        name: cityTo.name
      },
      when: {
        departure: departureDate,
        return: returnDate
      },
      passengers: {
        adults: data.passengers
      },
      tripType: data.tripType
    };

    document.body.appendChild(bookingPopupRef.location.nativeElement);
  }
}
