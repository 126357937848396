<div class="search-panel-wrap">
  <img src="/assets/plane_Icon.png" alt="">
  <app-drop-down
    (closeDropDown)="CloseDropDown($event)"
    [title]="'From: ' + cityForm.value">
    <content #content>
      <div class="search-fields reset-styles"
           appChangeBorderStyle
           [change]="fromFocused"
           appFlyBindComponentTo
           [itemOptions]="cityOptions"
           [opened]="opened"
           [selectedCity]="selectedCity"
           (callback)="selectCity($event, inputFrom)"
           (keydown)="keyDown($event)"
      >
        <div class="from-search-field">
          <div class="icon-back" (click)="backButton()">
            <img alt="" src="assets/search/arrow_left.svg">
          </div>
          <div class="icon" appTooltip [startOver]="true" [title]="'Try to determine your location'">
            <mat-icon class="my-location" (click)="getBrowserGeoPosition()">
              my_location
            </mat-icon>
          </div>

          <input type="text" class="departure-input form-control"
                 autocomplete="off"
                 #inputFrom
                 [formControl]="cityForm"
                 (blur)="onBlurFrom()"
                 (focus)="onFocusFrom()">

          <span class="from-label"
                *ngIf="!fromFocused && !inputFrom.value"
                [innerText]="label | translate | capitalize"
                (click)="inputFrom.focus()"
          ></span>
        </div>

        <a class="search-places wrap search-link">
          <div class="search-icon">
            <img src="/assets/search/search_pink.svg" alt="">
          </div>
        </a>
      </div>

      <div class="autocomplete" *ngIf="errorText">
        <div class="option not-found">
          <img class="not-found__icon" src="/assets/info/warning-5-24.png" alt="">
          <div class="not-found__info">
            Sorry, we couldn't find! "{{ errorText }}"
          </div>
        </div>
      </div>

      <ng-content></ng-content>
    </content>
  </app-drop-down>
</div>
