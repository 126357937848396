import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user-token.service';
import { UserAccountService } from '../../services/user-account.service';
import { clearObservable, timerObservable } from '../../libraries/timer-observable';
import { doAsync } from '../../libraries/do-async';
import { HttpParams } from '@angular/common/http';
import { WindowRef } from '../../services/window.service';
import { select, Store } from '@ngrx/store';
import { getAjaxState, getCityFrom, getSearchData, State } from '../../store/reducers';
import { delay, map, take, takeUntil } from 'rxjs/operators';
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../../store/layer/layer.utils';
import { AccountRouteType } from './enums/account-route.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoService } from '../../services/info.service';
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { RestoreCurrentHome } from '../../store/auth';
import { MetaTagsService, RouterHistoryService, SsrService } from '../../services';
import { Subject } from 'rxjs/internal/Subject';
import { StaticRouteType } from '../static/enums/route-type';
import { UserCollectionService } from '../../services/user-collection.service';
import { ReviewInterface, SearchStateForm } from "../../interfaces";
import { StaticService } from "../../services/static.service";
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import {
  ProfileAboutMode,
  ProfileUserAboutComponent
} from "./components/profile-user-about/profile-user-about.component";
import { SignUpService } from "../../services/sign-up.service";
import { Observable } from "rxjs/internal/Observable";
import { maxTabletWidth } from "../../services/helper.service";
import { of } from "rxjs/internal/observable/of";
import { UserSettingsNames } from "../../interfaces/user";
import { prepareUrlKeywords } from "../../libraries/prepare-urlkeywords";
import { StaticTransferStateKey } from "../../enums/static-transfer-state-key.enum";

export enum AccountPageTypesEnum {
  Account = 'account',
  BusinessProfile = 'business-profile',
  StandardProfile = 'standard-profile',
}

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() month: any;

  @ViewChild(ProfileUserAboutComponent)
  private profileUserAboutComponent: ProfileUserAboutComponent;

  public accountMode = AccountRouteType.About;
  public accountRouteType = AccountRouteType;
  public ajaxState$: Observable<any>;
  public alertMessage: string;
  public coordinates = {
    top: null,
    right: null
  };
  public error: any;
  public errorSubmit: boolean;
  public fields = {
    password: false,
    city: false,
    webSite: false,
    name: false,
    nickName: false,
    email: false,
    about: false,
  };

  public isOpenContent = false;
  public isSubmit = false;
  public isShowToastr = false;
  public placesData: any = {};
  public profileInformation: any;
  public resizeWindow = 0;
  public showPlace: any = {};
  public staticType: StaticRouteType = StaticRouteType.Account;
  public timerObservableId;
  public userAccountConfig: any = {'pinned': true};
  public userPlaces: any;
  public cloneUserPlaces: any;
  public cloneUserReviews: any;
  public window: any;
  public cityFrom: any;
  public searchData: SearchStateForm;
  public userIsLogged = false;
  public isAscSort: boolean = true;
  public isRecentlyDeletedPage: boolean = false;
  public userReviews: ReviewInterface[];
  public page: number = 1;
  public placesOtherCount: number;
  public reviewsOtherCount: number;
  public pageType: AccountPageTypesEnum;

  private timer: any;
  private $destroyed = new Subject<void>();
  private dataToSave = {};
  private readonly fieldsToValidate = ['nickName'];

  protected readonly AccountPageTypesEnum = AccountPageTypesEnum;
  protected readonly ProfileAboutMode = ProfileAboutMode;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.resizeWindow = (event && event.target) ? event.target.innerWidth : window.innerWidth;

    if (this.window) {
      doAsync(() => {
        this.coordinates.top = `${this.el.nativeElement.offsetTop + 125}px`;
      });
    }
  }

  constructor(
    private userService: UserService,
    private el: ElementRef,
    private userAccountService: UserAccountService,
    private infoService: InfoService,
    private windowRef: WindowRef,
    private route: ActivatedRoute,
    private cookieService: SsrCookieService,
    private router: Router,
    private metaTagsService: MetaTagsService,
    private userCollectionService: UserCollectionService,
    private store$: Store<State>,
    private ssrService: SsrService,
    public staticService: StaticService,
    private signUpService: SignUpService,
    // do not remove! is used for previousUrl$ logic in FlyAddButtonComponent
    private readonly routerHistoryService: RouterHistoryService,
  ) {
    this.userIsLogged = this.userService.loggedIn();
    if (!this.userIsLogged) {
      this.router.navigate(['/']);
    }

    this.store$.pipe(
      takeUntil(this.$destroyed),
      select(getCityFrom)
    ).subscribe((city) => {
      this.cityFrom = city;
    });
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));

    this.metaTagsService.resolver({});
    this.window = this.windowRef.getNativeWindow();
    this.resizeWindow = this.window?.innerWidth;
    this.staticService.setStaticType(StaticRouteType.Account);
  }

  ngOnInit() {
    this.pageType = this.route.snapshot.data['name'];
    this.isOpenContent = this.isProfileMode();
    if (typeof window !== 'undefined' && this.isProfileMode()) {
      window.scrollTo(0, 0);
    }
    this.store$.pipe(
      take(1),
      takeUntil(this.$destroyed),
      select(getSearchData),
      map((data: SearchStateForm) => {
        if (
          !this.month ||
          this.month.value !== data.date[0].date.format('MM')
        ) {
          this.month = {value: data.date[0].date.format('MM')};
          this.searchData = data;
          this.getProfileData();
          this.userCollectionService.checkCitiesInCookies();
        }
      })
    ).subscribe();

    this.route.queryParams.subscribe(params => {
      if (params && params.view) {
        this.accountMode = params.view;
        if (!this.userPlaces?.length && this.accountMode === AccountRouteType.YourPlaces && this.searchData) {
          this.page = 1;
          this.getUserPlaces();
        }
        if (!this.userReviews?.length && this.accountMode === AccountRouteType.YourReviews) {
          this.page = 1;
          this.getUserReview();
        }
      } else {
        this.accountMode = this.accountRouteType.About;
      }
    });
  }

  ngAfterViewInit() {
    if (this.window) {
      this.timerObservableId = timerObservable(() => {
        this.onResize();
      }, 200, 200, 1000);
    }
  }

  ngOnDestroy() {
    clearObservable(this.timerObservableId);
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public toggleEditor(data: { key: string, value: boolean }): void {
    this.fields[data.key] = data.value;
  }

  public closeContactForm() {
    this.profileUserAboutComponent.contactInfoComponent.closeAllInputs();
  }

  public getProfileData(): void {
    //wtf is this check? O_o
    if (!this.userReviews || this.userReviews && this.userReviews?.length > 0) {
      const httpParams = new HttpParams()
        .append('expand', 'picture,settings,homeCity.country,auth,urlSetting')
        .append('filter[cityFrom]', (this.searchData.cityFrom) ? this.searchData.cityFrom.id.toString() : '')
        .append('filter[paxCount]', this.searchData.passengers.toString())
        .append('filter[flightType]', transformTripTypeToApi(this.searchData.tripType))
        .append('filter[dateType]', transformDateToApiDateType(this.searchData.date).toString())
        .append('filter[when]', transformWhenDateToApiDateType(this.searchData.date))
        .append('filter[return]', transformReturnToApiDateType(this.searchData.date) || '');

      this.userAccountService.getProfileData(httpParams)
        .pipe(takeUntil(this.$destroyed))
        .subscribe(
          (success: any) => {
            this.profileInformation = {
              profile: success,
              month: this.month,
              inner: true
            };
            this.changeStore({...success});
            this.userService.disableLoadingBar(this);
          },
          (error: any) => {
            this.userService.disableLoadingBar(this);
            this.error = error;
            this.userService.signOut();
          });
    }
  }

  public changedPassword(): void {
    this.showAlert({text: 'Your password has been changed', error: false});
    this.fields.password = false;
  }

  public closeUserAccount(): void {
    this.userAccountService.deleteProfile()
      .subscribe(() => {
        this.userService.signOut();
        this.router.navigate(['/']);
      });
  }

  public close(): void {
    this.staticService.close();
  }

  public resendMail(): void {
    this.errorSubmit = false;
    const token = this.cookieService.get(`usertoken`);

    this.signUpService.resend({token})
      .pipe(takeUntil(this.$destroyed))
      .subscribe(
        () => {
          this.showAlert({text: 'We have re-sent you a confirmation email, please check your inbox.', error: false});
        },
        (error) => {
          this.showAlert({text: error.error.message, error: true});
        }
      );
  }

  public recoverPlace(place: any): void {
    this.userAccountService.recoverPlace(place.id)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => {
        this.userPlaces = this.userPlaces.filter(p => p.id !== place.id);
      });
  }

  public deletePlace(place: any): void {
    this.userAccountService.deleteUserPlace(place.id)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => {
        --this.page;
        this.getUserPlaces();
      });
  }

  public deleteReview(review: any): void {
    this.infoService.deleteUserReview(review.id)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => {
        this.userReviews = this.userReviews.filter(p => p.id !== review.id);
        --this.page;
        this.getUserReview();
      });
  }

  public syncReview(reviews: ReviewInterface[]): void {
    this.userReviews = reviews;
  }

  public selectedMode(value: boolean): void {
    this.isOpenContent = value;
    if(value === false && this.isTabletDesign()) {
      this.toggleRecentlyDeleted({data: false, reloadData: true, clearData: false});
    }
  }

  public updateUserSettings(data: any): void {
    this.errorSubmit = false;
    this.alertMessage = '';

    this.userAccountService.updateUserSettings(data)
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: (result) => {
          this.profileInformation.profile.settings = result.body;
          this.profileInformation.profile.urlSetting = +result.body.find(s => s.id === UserSettingsNames.UrlSetting)?.value;
          this.profileInformation.profile.urlKeywords = prepareUrlKeywords(this.profileInformation.profile.nickName);
          this.profileInformation.profile = JSON.parse(JSON.stringify(this.profileInformation.profile));
          this.changeStore({...this.profileInformation.profile});
        },
        error: (error) => {
          this.showAlert({text: error.error[0].message, error: true});
        }
      });
  }

  public saveProfileData(data: any): void {
    const {value, key} = data;
    let updated = {};
    if (key === 'picture') {
      updated[key] = value.id;
    } else {
      updated[key] = value;
    }
    this.errorSubmit = false;
    this.alertMessage = '';

    let httpParams = new HttpParams();
    if (key === 'picture') {
      httpParams = httpParams.set('expand', 'picture');
    }
    this.userAccountService.updateProfile(updated, httpParams)
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: (result) => {
          this.profileInformation.profile = JSON.parse(JSON.stringify(this.profileInformation.profile));
          if (key === 'picture') {
            this.profileInformation.profile.picture = {
              thumb: result.picture.thumb,
              large: result.picture.large
            };
          } else {
            this.profileInformation.profile = JSON.parse(JSON.stringify(this.profileInformation.profile));
            this.profileInformation.profile[key] = value;
          }

          if (key === 'contactInfo') {
            this.closeContactForm();
          } else {
            this.toggleEditor({key, value: false});
          }
          this.changeStore(this.profileInformation.profile);
        },
        error: (error) => this.processError(key, error)
      });
  }

  private processError(key: string, error: any): void {
    if (error.error?.length) {
      let errorText = error.error[0].message;
      if (key === 'contactInfo') {
        const firstErrorKey = Object.keys(error.error[0].message)[0];
        errorText = errorText[firstErrorKey][0] || errorText[firstErrorKey];
      }
      this.showAlert({text: errorText, error: true});
    }
  }

  private validateProfile(key: string, value: any): Observable<any> {
    let updated = {};
    if (key === 'picture') {
      updated[key] = value.id;
    } else {
      updated[key] = value;
    }
    this.errorSubmit = false;
    this.alertMessage = '';

    return this.userAccountService.validateProfile(updated);
  }

  public prepareProfileData(data: any): void {
    let {value, key} = data;

    let observable: Observable<any>;
    if (this.fieldsToValidate.indexOf(key) > -1) {
      observable = this.validateProfile(key, value);
    } else {
      observable = of(null);
    }

    observable.subscribe({
      next: () => {
        if (key === 'picture') {
          this.profileInformation.profile = JSON.parse(JSON.stringify(this.profileInformation.profile));
          this.profileInformation.profile.picture = {
            thumb: value.urlThumbnail,
            large: value.url,
          };
          value = value.id;
        } else {
          this.profileInformation.profile[key] = value;
        }
        this.dataToSave[key] = value;

        if (key === 'contactInfo') {
          this.closeContactForm();
        } else {
          this.toggleEditor({key, value: false});
        }
      },
      error: (error: any) => this.processError(key, error)
    });
  }

  public saveFullProfileData(): void {
    this.errorSubmit = false;
    this.alertMessage = '';
    if (isEmpty(this.dataToSave)) {
      this.close();
      return;
    }
    if (this.pageType === AccountPageTypesEnum.BusinessProfile) {
      this.dataToSave['settings'] = {showContactInfo: 1}
    }
    this.isSubmit = true;
    let httpParams = new HttpParams();
    let expand = [];
    if (typeof this.dataToSave['picture'] !== 'undefined') {
      expand.push('picture');
    }
    if (typeof this.dataToSave['settings'] !== 'undefined') {
      expand.push('settings');
    }
    if (expand.length > 0) {
      httpParams = httpParams.set('expand', expand.join());
    }
    this.userAccountService.updateProfile(this.dataToSave, httpParams)
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: (result) => {
          this.isSubmit = false;
          this.profileInformation.profile = JSON.parse(JSON.stringify(this.profileInformation.profile));
          Object.keys(this.dataToSave).forEach(key => {
            if (key === 'picture') {
              this.profileInformation.profile.picture = {
                thumb: result.picture.thumb,
                large: result.picture.large
              };
            } else if (key === 'settings') {
              this.profileInformation.profile.settings = result.settings;
            } else {
              this.profileInformation.profile[key] = this.dataToSave[key];
            }
          });
          this.changeStore(this.profileInformation.profile);
          this.dataToSave = {};
          this.close();
        },
        error: (error) => {
          this.isSubmit = false;
          if (error.error?.length) {
            let errorText = error.error[0].message;
            if (['settings', 'contactInfo'].indexOf(error.error[0].field) > -1) {
              const firstErrorKey = Object.keys(error.error[0].message)[0];
              errorText = errorText[firstErrorKey][0];
            }
            this.showAlert({text: errorText, error: true});
          }
        }
      });
  }

  public changeEmail(data: any): void {
    this.userAccountService.updateEmail(data)
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: () => {
          this.fields.email = false;
          this.showAlert({
            text: 'We have sent you a confirmation email, please check your inbox to confirm the changes.',
            error: false
          });
        }
        ,
        error: (error) => {
          this.showAlert({text: error.error[0].message, error: true});
        }
      });
  }

  private changeStore(data: any): void {
    this.store$.dispatch(
      new RestoreCurrentHome({
        id: data.homeCity?.id || this.cityFrom.id,
        name: data.homeCity?.name || this.cityFrom.name,
        country: data.homeCity?.country,
        coordinates: (data.homeCity ? `${data.homeCity.lat},${data.homeCity.lng}` : null)
          || this.cityFrom.coordinates || `${this.cityFrom.lat},${this.cityFrom.lng}`,
        population: data.homeCity?.population || this.cityFrom.population,
        popular: data.homeCity?.popular || this.cityFrom.popular,
        user: data
      })
    );
  }

  public loadMorePlaces() {
    ++this.page;
    this.getUserPlaces();
  }

  public loadMoreReview() {
    ++this.page;
    this.getUserReview();
  }

  public getUserReview() {
    const stateKey = `${StaticTransferStateKey.ReviewInfoKey}_${StaticTransferStateKey.UserInfoKey}_${this.page}_${this.isAscSort}_${this.isRecentlyDeletedPage}`;
    const storedData = this.ssrService.getState(stateKey);
    if (storedData) {
      this.ssrService.removeState(stateKey);
      this.handlingUserReview(storedData);
    } else {
      this.fetchUserReview()
        .pipe(takeUntil(this.$destroyed))
        .subscribe(placeDataRes => {
          this.ssrService.setState(stateKey, placeDataRes);
          this.handlingUserReview(placeDataRes);
        });
    }
  }

  public fetchUserReview(pageSize: number = 25) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('expand', 'user.picture,user.homeCity.country,pictures,place');
    httpParams = httpParams.set('filter[isOwner]', 1);
    httpParams = httpParams.set('pageSize', pageSize);
    httpParams = httpParams.set('page', this.page);

    return this.infoService.getReviewList(httpParams)
      .pipe(takeUntil(this.$destroyed),
        map((reviews) => {
          return reviews;
        }));
  }

  private handlingUserReview(reviews): void {
    if (JSON.stringify(reviews) !== JSON.stringify(this.cloneUserReviews)) {
      this.cloneUserReviews = cloneDeep(reviews);
      this.userReviews = this.userReviews ? JSON.parse(JSON.stringify(this.userReviews)) : [];
      this.reviewsOtherCount = this.getOtherCount(reviews?._meta);
      reviews.items.forEach(review => {
        if (!this.userReviews.some(someReview => someReview.id === review.id)) {
          this.userReviews.push(review);
        }
      });
    }
  }

  private getUserPlaces(): void {
    const stateKey = `${StaticTransferStateKey.PlaceInfoKey}_${StaticTransferStateKey.UserInfoKey}_${this.page}_${this.isAscSort}_${this.isRecentlyDeletedPage}`;
    const storedData = this.ssrService.getState(stateKey);
    if (storedData) {
      this.ssrService.removeState(stateKey);
      this.handlingUserPlaces(storedData);
    } else {
      this.fetchUserPlaces()
        .pipe(takeUntil(this.$destroyed))
        .subscribe(placeDataRes => {
          if (this.ssrService.isBrowser()) {
            this.ssrService.removeState(stateKey);
          } else {
            this.ssrService.setState(stateKey, placeDataRes);
          }
          this.handlingUserPlaces(placeDataRes);
        });
    }
  }

  private fetchUserPlaces(pageSize: number = 25): any {
    const httpParams = new HttpParams()
      .append('expand', 'rejectReason,moderationStatus')
      .append('filter[cityFrom]', (this.searchData.cityFrom) ? this.searchData.cityFrom.id.toString() : '')
      .append('filter[paxCount]', this.searchData.passengers.toString())
      .append('filter[flightType]', transformTripTypeToApi(this.searchData.tripType))
      .append('filter[dateType]', transformDateToApiDateType(this.searchData.date).toString())
      .append('filter[when]', transformWhenDateToApiDateType(this.searchData.date))
      .append('filter[return]', transformReturnToApiDateType(this.searchData.date) || '')
      .append('page', this.page)
      .append('pageSize', pageSize)
      .append('sort', this.isAscSort ? '-creationDate' : 'creationDate')
      .append('filter[isDeleted]', +!!this.isRecentlyDeletedPage)
      .append('filter[isOwner]', '1');

    return this.infoService.getPlaceList(httpParams)
      .pipe(takeUntil(this.$destroyed),
        map((places) => {
          return places;
        }));
  }

  private handlingUserPlaces(places): void {
    if (JSON.stringify(places) !== JSON.stringify(this.cloneUserPlaces)) {
      this.cloneUserPlaces = cloneDeep(places);
      this.userPlaces = this.userPlaces ? JSON.parse(JSON.stringify(this.userPlaces)) : [];
      this.placesOtherCount = this.getOtherCount(places?._meta);
      if (this.page === 1) {
        this.userPlaces = places?.items;
      } else {
        places.items.forEach(place => {
          if (!this.userPlaces.some(someReview => someReview.id === place.id)) {
            this.userPlaces.push(place);
          }
        });
      }
    }
  }

  private getOtherCount(_meta) {
    this.page = _meta?.currentPage;
    const placesTotalCount = _meta?.totalCount;
    const placesPerPage = _meta?.perPage;
    return placesTotalCount - (this.page * placesPerPage);
  }

  public showAlert(data: { text: string, error: boolean }): void {
    this.errorSubmit = data.error;
    this.isShowToastr = true;
    this.alertMessage = data.text;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = doAsync(() => {
      this.closeToastr();
    }, 6000);
  }

  public isTabletDesign(): boolean {
    return this.resizeWindow < maxTabletWidth;
  }

  public toggleSort(event: { data: any, reloadData: boolean, clearData: boolean }) {
    if (event?.data !== undefined) {
      this.isAscSort = event.data;
    } else {
      this.isAscSort = !this.isAscSort;
    }
    if (event.clearData) {
      this.userPlaces = [];
    }
    this.page = 1;
    if (event.reloadData) {
      this.getUserPlaces();
    }
  }

  public toggleRecentlyDeleted(event: { data: any, reloadData: boolean, clearData: boolean }) {
    if (event?.data !== undefined) {
      this.isRecentlyDeletedPage = event.data;
    } else {
      this.isRecentlyDeletedPage = !this.isRecentlyDeletedPage;
    }
    if (event.clearData) {
      this.userPlaces = [];
    }
    this.page = 1;
    if (event.reloadData) {
      this.getUserPlaces();
    }
  }

  protected isProfileMode(): boolean {
    return this.pageType === AccountPageTypesEnum.BusinessProfile || this.pageType === AccountPageTypesEnum.StandardProfile;
  }

  protected closeToastr(): void {
    this.isShowToastr = false;
    this.errorSubmit = false;
  }
}
