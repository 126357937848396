<footer [ngStyle]="{'height': height && height > 0 ? height + 'px' : 170 + 'px'}">

  <div *ngIf="hasMotto" class="footer-title">
    <span class="grayColor"> &#169; </span> Explorow is a free and open travel platform based on the interactive map and
    sourced by users
  </div>

  <div class="footer-body cus-explorow">
    <ng-container *ngIf="wideDesktop(); else two_columns">
      <div class="explorow">
        <div class="footer-link cus-exp-border">
          <a href="javascript:void(0);" (click)="openContactUs()">Contact us</a>
        </div>
      </div>
      <div class="divider"></div>
      <div class="explorow">
        <div class="footer-link cus-exp-border">
          <a [href]="constructBaseUrl('/site-map')">Site map</a>
        </div>
        <div class="footer-link sitemap">
          <a *ngIf="routeIndex !== 0" [href]="constructBaseUrl('/list-cities')">List of cities</a>
        </div>
      </div>
    </ng-container>
    <ng-template #two_columns>
      <div class="explorow">
        <div class="footer-link cus-exp-border">
          <a href="javascript:void(0);" (click)="openContactUs()">Contact us</a>
        </div>
        <div class="footer-link">
          <a [href]="constructBaseUrl('/site-map')">Site map</a>
        </div>
        <div class="footer-link sitemap">
          <a *ngIf="routeIndex !== 0" [href]="constructBaseUrl('/list-cities')">List of cities</a>
        </div>
      </div>
    </ng-template>
    <div class="divider"></div>
    <div class="explorow">
      <div class="footer-link" *ngIf="routeIndex !== 1">
        <a [href]="constructBaseUrl('/cookie-policy')">Cookie Policy</a>
      </div>
      <div class="footer-link" *ngIf="routeIndex !== 3">
        <a [href]="constructBaseUrl('/terms-of-use')">Term Of Use</a>
      </div>
      <div class="footer-link" *ngIf="routeIndex !== 2">
        <a [href]="constructBaseUrl('/privacy-policy')">Privacy Policy</a>
      </div>
    </div>
  </div>

</footer>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactType"
                (closeContactUs)="closeContactUs()"></app-contact-us>
