import { replaceAll } from "./repalce-all";

export const prepareUrlKeywords = function (str: string): string {
  if (!str) {
    return '';
  }
  return replaceAll(
    replaceAll(str, /[ \/|?,+\-:_]/, '-'),
    /[()`&’"'.!“«]/u,
    '').toLowerCase();
}
