import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export abstract class DestroyComponent implements OnDestroy {

  protected destroy$ = new Subject();

  protected constructor() {
  }

  ngOnDestroy(): void {
    // @ts-ignore
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
