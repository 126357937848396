<div class="place-item__container" #placeItemContainer>
  <div *ngIf="staticService.withFollowLine && placeInfo?.isCollectionPlace"
       class="place-item-line"
       [style.height.px]="staticService.isMobileDesign() && isLast && lineBranchTop">
    <div class="line-branch"
         *ngIf="staticService.withFollowLine && placeInfo?.isCollectionPlace"
         [style.top.px]="lineBranchTop"></div>
  </div>
  <div class="place__content"
       [class.with-follow-line]="staticService.withFollowLine && placeInfo?.isCollectionPlace"
       (mouseenter)='!staticService.isMobileDesign() && overImage(galleryMedia[0]?.isVideo)'
       (mouseleave)='!staticService.isMobileDesign() && outImage()'>
    <ng-container>
      <ng-container *ngIf="staticService.isMobileDesign() else previewImages">
        <div class="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
          <swiper fxFlex="auto" [config]="config">
            <ng-template swiperSlide *ngFor="let step of galleryMedia; let index = index">
              <ng-container *ngIf="!step?.isVideo; else ShowVideoContainer">
                <div [appRouterLink]="getRoute(staticRouteType.Place)"
                     *ngIf="placeIndex > 0 || placeIndex === undefined"
                     class="img-container"
                     appLazyload="{{constructMediaURL(step.large || '/images/default.jpg')}}"
                     style="">
                </div>
                <div [appRouterLink]="getRoute(staticRouteType.Place)"
                     *ngIf="placeIndex === 0"
                     class="img-container"
                     [style]="'background-image: url(' + constructMediaURL(step?.large || '/images/default.jpg')+');'">
                </div>
              </ng-container>
              <ng-template #ShowVideoContainer>
                <div (click)="onShowVideo(index)"
                     class="play-button-container">
                  <img [appBasesrc]="'/assets/icons/play-button.svg'"
                       class="video-control"
                       alt="video-control">
                </div>
                <div (click)="onShowVideo(index)"
                     *ngIf="placeIndex > 0 || placeIndex === undefined"
                     class="img-container"
                     appLazyload="{{constructMediaURL(step?.poster?.large || '/images/default.jpg')}}"
                     style="">
                </div>
                <div (click)="onShowVideo(index)"
                     *ngIf="placeIndex === 0"
                     class="img-container"
                     [style]="'background-image: url(' + constructMediaURL(step?.poster?.large || '/images/default.jpg')+');'">
                </div>
              </ng-template>
            </ng-template>
          </swiper>
        </div>
      </ng-container>
      <ng-template #previewImages>
        <ng-container *ngIf="!galleryMedia[0]?.isVideo || galleryMedia.length > 1; else ShowVideoContainer">
          <a class="place__image"
             *ngIf="!isUserDomain"
             [class.video]="galleryMedia[0]?.isVideo"
             [routerLink]="getRoute(staticRouteType.Place)">
            <ng-container *ngTemplateOutlet="galleryMedia[0]?.isVideo ? videoContainer : imageContainer"></ng-container>
          </a>
          <a class="place__image"
             *ngIf="isUserDomain"
             [class.video]="galleryMedia[0]?.isVideo"
             [href]="constructBaseUrl(getRoute(staticRouteType.Place))">
            <ng-container *ngTemplateOutlet="galleryMedia[0]?.isVideo ? videoContainer : imageContainer"></ng-container>
          </a>
        </ng-container>
        <ng-template #ShowVideoContainer>
          <a class="place__image"
             [class.video]="galleryMedia[0]?.isVideo"
             (click)="onShowVideo()">
            <ng-container *ngTemplateOutlet="videoContainer"></ng-container>
          </a>
        </ng-template>
      </ng-template>

      <ng-template #imageContainer>
        <ng-container *ngIf="placeInfo?.pictures?.length || placeInfo?.picture">
          <img
            [src]="constructMediaURL(
          hoverImage ||
          (placeInfo?.picture && placeInfo?.picture?.small || placeInfo?.picture?.large) ||
          (placeInfo?.pictures && placeInfo?.pictures.length > 0 &&
          ((placeInfo?.pictures[0]?.small || placeInfo?.pictures[0]?.large)
          ? placeInfo?.pictures[0]?.small || placeInfo?.pictures[0]?.large
          : placeInfo?.pictures[0])) ||
          '/images/default.jpg'
        )" alt="" loading="{{placeIndex < 3 && 'eager' || 'lazy'}}">
        </ng-container>
      </ng-template>

      <ng-template #videoContainer>
        <img [src]="constructMediaURL(hoverVideo || placeInfo?.video[0]?.poster.small)"
             alt=""
             loading="{{placeIndex < 3 && 'eager' || 'lazy'}}">
      </ng-template>

      <app-static-place-item-pin
        [place]="placeInfo"
        (pinPlace)="pinPlace($event)"
        [movePinToRight]="false"
      ></app-static-place-item-pin>
    </ng-container>

    <div class="place__link-item">
      <a
        *ngIf="!isUserDomain && placeInfo?.user?.id && this.userLink.indexOf('http') === -1"
        [routerLink]="this.userLink">
        {{
          (placeInfo?.user && placeInfo?.user?.id)
            ? (placeInfo?.user?.name || 'User-ID-' + placeInfo?.user?.id)
            : staticInitService.getUrlLinkName(placeInfo?.url)
        }}
      </a>
      <a
        *ngIf="isUserDomain || !placeInfo?.user?.id || this.userLink.indexOf('http') !== -1"
        [href]="placeInfo?.user?.id ? this.userLink : placeInfo?.url">
        {{
          (placeInfo?.user && placeInfo?.user?.id)
            ? (placeInfo?.user?.name || 'User-ID-' + placeInfo?.user?.id)
            : staticInitService.getUrlLinkName(placeInfo?.url)
        }}
      </a>
    </div>

    <div class="place__item-info" #placeName>
      <a class="place__title"
         *ngIf="!isUserDomain"
         [routerLink]="getRoute(staticRouteType.Place)">
        {{ placeInfo?.name }}
      </a>
      <a class="place__title"
         *ngIf="isUserDomain"
         [href]="constructBaseUrl(getRoute(staticRouteType.Place))">
        {{ placeInfo?.name }}
      </a>

      <p *ngIf="placeInfo?.date && !staticService.isMobileDesign()" class="place__date">
        {{ placeInfo.date | date: 'd MMMM y' }}
      </p>

      <ng-container *ngIf="placeInfo?.pictures?.length || placeInfo?.video?.length">
        <div class="place__description-wrapper" *ngIf="placeInfo?.description">
          <div class="place__description limit-text"
               [style.-webkit-line-clamp]="!staticService.isMobileDesign() ? countLimitText.desktop : countLimitText.mobile">
            {{ placeInfo?.description }}
          </div>
        </div>
      </ng-container>

      <div #placeItemActions class="place-item-actions">
        <div *ngIf="placeInfo?.city">

          <a class="place-item-action city"
             *ngIf="placeInfo?.city?.hasPage && !isUserDomain"
             [routerLink]="'/city/' + placeInfo.city.urlKeywords + '/' + placeInfo.city.id"
          >
            <ng-container *ngTemplateOutlet="cityContainer"></ng-container>
          </a>
          <a class="place-item-action city"
             *ngIf="placeInfo?.city?.hasPage && isUserDomain"
             [href]="constructBaseUrl('/city/' + placeInfo.city.urlKeywords + '/' + placeInfo.city.id)"
          >
            <ng-container *ngTemplateOutlet="cityContainer"></ng-container>
          </a>
          <span class="place-item-action city cursor-default"
                *ngIf="!placeInfo?.city?.hasPage">
            <ng-container *ngTemplateOutlet="cityContainer"></ng-container>
          </span>
        </div>

        <div *ngIf="!placeInfo?.city && placeInfo?.country">
          <a class="place-item-action city"
             *ngIf="!isUserDomain"
             [routerLink]="getLinkByObject(placeInfo?.country)">
            <ng-container *ngTemplateOutlet="countryContainer"></ng-container>
          </a>
          <a class="place-item-action city"
             *ngIf="isUserDomain"
             [href]="constructBaseUrl(getLinkByObject(placeInfo?.country))">
            <ng-container *ngTemplateOutlet="countryContainer"></ng-container>
          </a>
        </div>

        <div *ngIf="!isMapItDisabled"
             class="place-item-action map"
             (click)="mapItPlace()">
          <img [src]="constructBaseUrl('/assets/icons/pin-blue.png')" alt="Map">
          <div>Map</div>
        </div>
      </div>

      <div class="place__categories">
        <ng-container *ngFor="let tag of placeInfo?.tags">
          <a class="place__category-item category-event"
             *ngIf="!isUserDomain"
             (mouseenter)="setTooltipCoordinates($event)"
             [routerLink]="routeToItemPlace(tag)">
            <span>{{ tag?.name }}</span>
          </a>
          <a class="place__category-item category-event"
             *ngIf="isUserDomain"
             (mouseenter)="setTooltipCoordinates($event)"
             [href]="constructBaseUrl(routeToItemPlace(tag))">
            <span>{{ tag?.name }}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-static-gallery
  *ngIf="showVideo"
  [windowWidth]="staticService.resizeWindow"
  [gallery]="gallery"
  [place]="placeInfo"
  [activeIndex]="activeIndex"
  (closeGallery)="openImage($event)">
</app-static-gallery>

<ng-template #cityContainer>
  <img class="place__country-icon"
       *ngIf="placeInfo?.city?.country"
       alt="City"
       [src]="getCountryFlag(placeInfo?.city?.country)"/>
  <div [ngStyle]="{ 'max-width': getMaxWidthCityName() }">
    {{ placeInfo?.cityName || placeInfo?.city?.name }}
  </div>
</ng-template>

<ng-template #countryContainer>
  <img class="place__country-icon"
       alt="Country"
       *ngIf="placeInfo?.country"
       [src]="getCountryFlag(placeInfo?.country)"/>
  <div [ngStyle]="{ 'max-width': getMaxWidthCityName() }">
    {{ placeInfo?.country?.name }}
  </div>
</ng-template>
