<div class="about__container">

  <div class="about__content">
    <div class="about__label">Name</div>

    <div class="about__item">
      <div class="about__text" *ngIf="!fields.nickName">
        <p *ngIf="data?.nickName">{{ data?.nickName }}</p>
        <span class="edit-item-label" (click)="toggleEditor('nickName', true)">
                    {{ (data?.nickName) ? 'Edit' : 'Add' }}
                </span>
      </div>
      <app-profile-text-field
        *ngIf="fields.nickName"
        [type]="'input'"
        [value]="data?.nickName"
        (saveEmitter)="saveChanges($event, 'nickName')"
        (cancel)="toggleEditor('nickName', false)">
      </app-profile-text-field>
    </div>
  </div>

  <div class="about__content" *ngIf="mode === ProfileAboutMode.Full">
    <div class="about__label">Email</div>

    <div class="about__item email-field">
      <div class="about__text" *ngIf="!fields.email">
        <p *ngIf="data?.email">{{ data?.email }}</p>
        <span class="edit-item-label" (click)="toggleEditor('email', true)">
                    {{ (data?.email) ? 'Edit' : 'Add' }}
                </span>
      </div>
      <app-profile-text-field
        *ngIf="fields.email"
        [type]="'input'"
        [value]="data?.email"
        (saveEmitter)="saveEmail($event)"
        (cancel)="toggleEditor('email', false)">
      </app-profile-text-field>

      <div class="not-confirm" *ngIf="data?.status === 0">
        <span class="not-confirm__text">Not confirmed</span>
        <span class="not-confirm__action" *ngIf="!resent" (click)="resendMail()">
                    Resend
                </span>
      </div>
    </div>
  </div>

  <div class="about__content">
    <div class="about__label">Profile Picture</div>

    <div class="about__item">
      <div class="about__upload-image">
        <div class="about__image">
          <app-upload-form
            [length]="8"
            [isProfile]="true"
            [buttonImage]="userImage"
            [accept]="photoAccept"
            [type]="typeMedia.photo"
            [uploadFiles]="uploadFiles"
            [isAjaxLoad]="true"
            (uploadFile)="uploadNewImage($event)">
          </app-upload-form>
        </div>

        <div class="about__image-action">
          <app-upload-form
            [length]="8"
            [isProfile]="true"
            [buttonText]="data?.picture?.thumb ? 'Change Photo' : 'Upload Photo'"
            [accept]="photoAccept"
            [type]="typeMedia.photo"
            [uploadFiles]="uploadFiles"
            [isAjaxLoad]="true"
            (uploadFile)="uploadNewImage($event)">
          </app-upload-form>

          <span>It would look much better if upload a square shape image. Maximum file size 10 MB</span>
        </div>
      </div>
    </div>
  </div>

  <div class="about__content">
    <div class="about__label">About you</div>

    <div class="about__item">
      <div class="about__textarea" *ngIf="!fields.about">
        <p class="edit-item-label" (click)="toggleEditor('about', true)">
          {{ (data?.about) ? 'Edit text' : 'Add text' }}
        </p>

        <div class="about__data" [innerHTML]="data?.about"></div>
      </div>

      <app-profile-text-field
        *ngIf="fields.about"
        [type]="'wysiwyg'"
        [value]="data?.about"
        (saveEmitter)="saveChanges($event, 'about')"
        (cancel)="toggleEditor('about', false)">
      </app-profile-text-field>
    </div>
  </div>

  <div class="about__content" *ngIf="mode === ProfileAboutMode.Full">
    <div class="about__label">YOUR PAGE</div>

    <div class="about__item personal-link">
      <a class="link-to link-to-page"
         target="_blank"
         [href]="getUserLinkWithHash()">
        <span>{{ getUserLink() }}</span>
        <img [src]="'/assets/icons/icon-exit.png'" alt="exit">
      </a>
      <span class="description">
        This is the address of your Personal Page. You can preview how it appears on the website.
      </span>
    </div>
  </div>

  <div class="about__content" *ngIf="mode === ProfileAboutMode.Full">
    <div class="about__label">Home</div>

    <div class="about__item column-item">
      <div class="about__text" *ngIf="!fields.city">
        <p
          *ngIf="data?.homeCity?.name">{{ data?.homeCity?.name }}{{ ', ' + (data?.homeCity?.country?.shortName || data?.homeCity?.country?.name) }}</p>
        <span class="edit-item-label" (click)="toggleEditor('city', true)">
          {{ data?.homeCity?.name ? 'Change' : 'Add' }} City
        </span>
      </div>

      <app-user-profile
        *ngIf="fields.city"
        [profile]="data"
        (closeEmitter)="toggleEditor('city', false)">
      </app-user-profile>

      <span class="description w-100">
        Has been set as your home city for flight searches.
      </span>
    </div>
  </div>

  <div class="about__content">
    <div class="about__label">Contact info</div>

    <div class="about__item">
      <div class="description-block">
        <img
          *ngIf="mode === ProfileAboutMode.Full || mode === ProfileAboutMode.ShortHidden"
          (click)="toggleContactInfoVisibility()"
          [src]="isContactInfoEnabled ? './assets/icons/toggle-on2.png' : './assets/icons/toggle-off2.png'"
          alt="toggle">
        <span>
        Add basic information to your account. This info will be shown on your Personal Page.
        </span>
      </div>
      <app-contact-info-edit
        [hidden]="mode === ProfileAboutMode.ShortHidden && !isContactInfoEnabled"
        [isProfilePage]="true"
        [isContactInfoEnabled]="isContactInfoEnabled"
        [contactInfo]="data?.contactInfo"
        (setContactInfoEmitter)="saveChanges($event, 'contactInfo')"
      ></app-contact-info-edit>
    </div>
  </div>
</div>
