import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from "@angular/core";

// To support upload progress events. Fetch implementation that is used in other http requests doesn't support it.
@Injectable({
  providedIn: 'root'
})
export class XhrHttpClient extends HttpClient {
  constructor(handler: HttpHandler) {
    super(handler);
  }
}
