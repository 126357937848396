import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { getCityFrom, getCityTo, getHomeData, State } from '../reducers';
import {
  LayerActionsTypes,
  LayerActionsUnion,
  PatchSearchFormState,
  ResetSearchData,
  SetCityFrom,
  SetCityTo,
  SwapDirections,
  SwapDirectionsFailure
} from './layer.actions';
import { AuthActionsUnion, SetCurrentCoordinates } from '../auth/auth.actions';
import { parseCoords } from '../../libraries/map/convert-coords';
import { isEmpty } from '../../libraries/is-empty';
import { DestinationType } from '../../enums/destination-type';
import { City, Point } from "../../interfaces";

@Injectable({providedIn: 'root'})
export class LayerEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
  ) {
  }


  SetCityFrom$: Observable<LayerActionsUnion | void> = createEffect(() => this.actions$.pipe(
    ofType<SetCityFrom>(LayerActionsTypes.SetCityFrom),
    withLatestFrom(this.store$.pipe(select(getCityTo))),
    map(([action, cityTo]: [SetCityFrom, City | true | null | any]) => {
      if (!!cityTo && action.payload.id === cityTo.id) {
        return new SetCityTo(null);
      }
      return new SetCityTo(cityTo);
    }),
  ));


  SwapDirections$: Observable<LayerActionsUnion | void> = createEffect(() => this.actions$.pipe(
    ofType<SwapDirections>(LayerActionsTypes.SwapDirections),
    withLatestFrom(
      this.store$.pipe(select(getCityTo)),
      this.store$.pipe(select(getCityFrom),
      )),
    map(([action, cityTo, cityFrom]: [any, Point, Point]) => {
      if (!isEmpty(cityTo) && !isEmpty(cityFrom) && cityTo.type === DestinationType.City && cityFrom.type === cityTo.type) {
        return new PatchSearchFormState({
          cityTo: cityFrom,
          cityFrom: cityTo,
        });
      }
      return new SwapDirectionsFailure(new Error('Swap allowed between cities only'));
    }),
  ));


  ResetSearchData$: Observable<LayerActionsUnion | AuthActionsUnion | void> = createEffect(() => this.actions$.pipe(
    ofType<ResetSearchData>(LayerActionsTypes.ResetSearchData),
    withLatestFrom(
      this.store$.pipe(select(getCityFrom)),
      this.store$.pipe(select(getHomeData)),
    ),
    map(([action, pointFrom, homeData]: [ResetSearchData, Point, Point]) => {
      if (!!pointFrom && !!pointFrom.coordinates) {
        return new SetCurrentCoordinates(parseCoords(pointFrom.coordinates));
      }
      if (!!homeData && !!homeData.coordinates) {
        return new SetCurrentCoordinates(parseCoords(homeData.coordinates));
      }
      return new SetCurrentCoordinates({lat: 48.30, lng: 23.23});
    }),
  ));

}
