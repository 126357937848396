import { Pipe, PipeTransform } from '@angular/core';
import { DAYS_OF_WEEK_ARRAY } from "../interfaces/contact-info";

@Pipe({
  name: 'showSchedule'
})
export class ShowSchedulePipe implements PipeTransform {

  transform(value: any[]): unknown {
    let resultData = '';
    let sameDays = [];

    if (value) {
      value.forEach((schedule, index) => {
        const key = schedule.join();
        if (key) {
          if (!sameDays[key]) {
            sameDays[key] = [];
          }
          sameDays[key].push({
            day: index,
            times: schedule
          });
        }
      });

      for (let item in sameDays) {
        const days = sameDays[item];
        let shortDays = '';
        let time = this.getTime(days[0].times);
        days.forEach((day, index) => {
          resultData += (resultData && index === 0) ? '<br>' : '';
          shortDays += (index > 0 ? ', ' : '') + DAYS_OF_WEEK_ARRAY[day.day].shortTitle;
        });
        resultData += `${shortDays} <br> ${time}`;
      }
    }
    return resultData;
  }

  private getTime(time: any[]) {
    let resultData = '';
    time.forEach(item => {
      resultData += (resultData?.length ? ', ' : '') + item.join(' - ');
    });
    return resultData;
  }
}
