<ng-container *ngIf="deactivatePage; then deactivatedPage; else activatePage"></ng-container>

<ng-template #deactivatedPage>
  <h1 class="info-name">{{ staticService.errorMessage }}</h1>
</ng-template>

<ng-template #activatePage>
  <ng-container *ngTemplateOutlet="pageWithStaticContent"></ng-container>

  <ng-template #pageWithStaticContent>
    <app-static-content>
      <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
    </app-static-content>
  </ng-template>

  <ng-template #pageContainer>
    <section class="place-container">
      <div #mainContainer>
        <div class="place-container__header">
          <h1 class="info-name" *ngIf="place">{{ place?.name }}</h1>
          <app-static-place-item-pin *ngIf="place"
                                     [place]="place"
                                     [isTitlePosition]="true"
                                     (pinPlace)="pinPlace($event)"
          ></app-static-place-item-pin>
          <app-share-buttons></app-share-buttons>
          <app-static-place-info-edit *ngIf="+place?.user?.id===+staticService.homeData?.user?.id"
                                      [place]="place"
          ></app-static-place-info-edit>
        </div>
        <ng-container *ngIf="place">
          <!--  REVIEW page-->
          <ng-container *ngIf="staticService.staticType === staticRouteType.Review; then reviewsPage"></ng-container>
          <ng-template #reviewsPage>

            <ng-container *ngTemplateOutlet="authorialCollection"></ng-container>
            <ng-container *ngTemplateOutlet="imagesContent"></ng-container>

            <ng-container *ngFor="let review of place.reviews; let i=index; let last=last">
              <div class="review-container" [class.mt]="i > 0">
                <ng-container *ngTemplateOutlet="ownerContainer; context: {data: review}"></ng-container>
                <ng-container *ngTemplateOutlet="textContainer;
               context: {user: review?.user,text: review.text}"></ng-container>
                <ng-container *ngTemplateOutlet="urlContainer; context: {data: review}"></ng-container>
                <ng-container *ngTemplateOutlet="reviewDateContainer;
              context: { data: review, index: i, text: review.date ? '' : 'Written' }"></ng-container>
                <ng-container *ngIf="last; then readMoreButton"></ng-container>
              </div>
            </ng-container>
          </ng-template>

          <!--  PLACE page-->
          <ng-container *ngIf="staticService.staticType === staticRouteType.Place">
            <ng-container *ngIf="place.reviews?.length; then reviewsContainer; else defaultContainer"></ng-container>
          </ng-container>

          <ng-template #defaultContainer>
            <ng-container *ngTemplateOutlet="dateContainer"></ng-container>
            <ng-container *ngTemplateOutlet="authorialCollection"></ng-container>
            <ng-container *ngFor="let oneDescription of place.descriptionBlocks; let i=index">
              <ng-container *ngTemplateOutlet="imagesContent;
          context: {galleryMedia: oneDescription.galleries}"></ng-container>
              <ng-container *ngTemplateOutlet="textContainer; context: {
          user: place?.user,
          text: oneDescription.text
        }"></ng-container>
            </ng-container>
            <ng-container *ngTemplateOutlet="urlContainer; context: {data: place}"></ng-container>
            <ng-container *ngTemplateOutlet="userLinkContainer"></ng-container>
            <ng-container *ngTemplateOutlet="authorialCollectionBottom"></ng-container>
          </ng-template>

          <ng-template #reviewsContainer>
            <div class="reviews-container" #reviewsContainerElem>
              <div class="review-container">
                <ng-container *ngTemplateOutlet="defaultContainer"></ng-container>
              </div>
              <!--          reviews container-->
              <div class="review-container"
                   *ngFor="let review of place.reviews; let i=index; let last=last">
                <ng-container *ngTemplateOutlet="ownerContainer; context: {data: review}"></ng-container>
                <div #descriptionContainer
                     class="description-container"
                     [ngClass]="{'user-description': !!place?.user, 'limit-text':isLimitTextArray[i]  }"
                     style="-webkit-box-orient: vertical;"
                     [innerHTML]="review.text">
                </div>
                <div class="read-link-container" *ngIf="isLimitTextArray[i]"
                     [routerLink]="'/places/'+ place.urlKeywords +'/' + place.id + '/review/' + review.id">
                  <a class="collection-all-action__title">read</a>
                  <img alt="arrow icon" src="/assets/arrow-right-black.png">
                </div>
                <div class="images-container" *ngIf="review.gallery && review.gallery.length">
                  <div *ngFor="let gallery of review.gallery.slice(0,6); let galleryIndex=index"
                       class="image-container">

                    <div *ngIf="gallery?.isVideo"
                         (click)="openImage(true, galleryIndex, review.gallery)"
                         class="play-button-container__review">
                      <img src="/assets/icons/play-button.svg"
                           class="video-control__review"
                           alt="video-control">
                    </div>

                    <img [src]="constructMediaURL(gallery?.isVideo ? gallery?.poster?.thumb : gallery?.thumb)"
                         (click)="openImage(true, galleryIndex, review.gallery)"
                         alt="picture"/>

                    <div class="image-container__count"
                         [routerLink]="'/places/'+ place.urlKeywords +'/' + place.id + '/review/' + review.id"
                         *ngIf="review.gallery.length > 6 && galleryIndex === 5">
                      + {{ review.gallery.length - 6 }}
                    </div>
                  </div>
                </div>
                <ng-container *ngTemplateOutlet="reviewDateContainer;
              context: { data: review, text: review.date ? '' : 'Written' }"></ng-container>

                <ng-container *ngIf="last; then readMoreButton"></ng-container>

              </div>
            </div>
          </ng-template>

          <ng-container [ngTemplateOutlet]="actionContainer"></ng-container>

          <div class="place-container__action-item user-place border-top">
            <div class="place-container__city">
              <img [src]="'/assets/icons/city-dark.svg'" *ngIf="place.cityId || place?.city" alt="">
              <a class="place-container__city-link"
                 *ngIf="place.cityUrlKeywords && place.cityId"
                 [routerLink]="'/city/' + place.cityUrlKeywords + '/' + place.cityId">
                {{ place.cityName }}
              </a>
              <a class="place-container__city-link"
                 *ngIf="place?.city && place?.city?.id"
                 [routerLink]="'/city/' + place?.city?.urlKeywords + '/' + place?.city?.id">
                {{ place?.city?.name }}
              </a>

              <a *ngIf="place.countryName && place.countryId"
                 class="place-container__country-link"
                 [routerLink]="'/country/' + place.countryName + '/' + place.countryId.toLowerCase()">
                <img class="place-container__country-icon" [src]="'assets/flag-24/' + place?.countryId + '.png'"
                     alt=""/>
                {{ place.countryName }}
              </a>

              <a *ngIf="place?.city?.country && place?.city?.country?.id"
                 class="place-container__country-link"
                 [routerLink]="'/country/' + place?.city?.country?.urlKeywords + '/' + place?.city?.country?.id.toLowerCase()">
                <img class="place-container__country-icon"
                     [src]="'assets/flag-24/' + place?.city?.country?.id + '.png'"
                     alt=""/>
                {{ place?.city?.country?.shortName || place?.city?.country?.name }}
              </a>

              <a *ngIf="place?.country && place?.country?.name"
                 class="place-container__country-link"
                 [routerLink]="'/country/' + place?.country?.urlKeywords + '/' + place?.country?.id.toLowerCase()">
                <img class="place-container__country-icon" [src]="'assets/flag-24/' + place?.country?.id + '.png'"
                     alt=""/>
                {{ place?.city?.country?.shortName || place?.country?.name }}
              </a>
            </div>
          </div>

          <section *ngIf="place?.tags && place?.tags.length" class="city__categories">
            <app-static-categories
              [categories]="place.tags"
              [place]="place"
              [staticType]="staticType"
              [isThirdLines]="true"
              [className]="'tags'"
              [pageType]="page"
            ></app-static-categories>
          </section>

          <div class="place-container__actions user-actions" *ngIf="place?.city && place?.city?.flights">
            <div class="place-container__flights">
              <app-popup-city-flights
                class="city-flights"
                [place]="place.city"
                [placeInfo]="place.city"
                [size]="'large'">
              </app-popup-city-flights>
            </div>
          </div>
        </ng-container>
      </div>
    </section>

    <app-your-own-review
      *ngIf="isShowAddPlace"
      [externalPlace]="place"
      [m0]="true"
      (discard)="toggleAddReview()"
    ></app-your-own-review>
  </ng-template>

  <app-static-gallery
    *ngIf="isGalleryOpen"
    [windowWidth]="resizeWindow"
    [gallery]="galleryImages"
    [place]="place"
    [activeIndex]="activeIndex"
    (closeGallery)="openImage($event)">
  </app-static-gallery>

</ng-template>

<ng-template #imagesContent let-galleryMedia="galleryMedia">
  <app-images-content *ngIf="place"
                      [galleryMediaData]="galleryMedia"
                      [place]="place"
                      [page]="page"
                      [typeGallery]="imagesContentType.grid"
                      [resizeWindow]="resizeWindow"
                      (pinPlaceEmitter)="pinPlace($event)">
  </app-images-content>
</ng-template>

<ng-template #readMoreButton>
  <div class="read-more__container"
       *ngIf="isLoadButton"
       (click)="loadMoreReview()">Read more ({{ reviewsOtherCount }} reviews)
    <div class="read-more__arrow-down">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
           y="0px"
           viewBox="0 0 1000 1000"
           enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g><g><path d="M124.8,197.6L500,572.7l375.2-375.2L990,312.4l-490,490l-490-490L124.8,197.6z"/></g></g>
          </svg>
    </div>
  </div>
</ng-template>

<ng-template #dateContainer>
  <div *ngIf="place?.date" class="date-container mt--10">
    <p class="date">{{ place.date | date: 'd MMMM y' }}</p>
  </div>
</ng-template>

<ng-template #urlContainer let-data="data">
  <div class="place-container__link" *ngIf="data?.url">
    <span>Read more info:</span>
    <a [href]="data.url" target="_blank">
      {{ data.url }}
    </a>
  </div>
</ng-template>

<ng-template #userLinkContainer>
  <div class="place-container__created-user-link" *ngIf="place?.user">
    <a *ngIf="!this.isUserLinkNamed" [routerLink]="[this.userLink]">
      <img alt="user" class="create-by__icon" [src]="getUserAvatarByObject(place.user)">
      {{ getUserNameByObject(place.user) }}
    </a>
    <a *ngIf="this.isUserLinkNamed" [href]="this.userLink">
      <img alt="user" class="create-by__icon" [src]="getUserAvatarByObject(place.user)">
      {{ getUserNameByObject(place.user) }}
    </a>
  </div>
</ng-template>

<ng-template #textContainer let-user="user" let-text="text">
  <div class="text-container"
       *ngIf="!!text"
       [ngClass]="{'user-description': !!user}"
       style="-webkit-box-orient: vertical;"
       [innerHTML]="text">
  </div>
</ng-template>

<ng-template #ownerContainer let-data="data">
  <div class="owner-container">
    <div class="owner-container__image">
      <img alt="photo"
           [appRouterLink]="getLinkByObject(data?.user)"
           [src]="getUserAvatarByObject(data?.user)">
    </div>

    <div class="owner-container__user-data">
      <div class="owner-container__user-data__name">
        <a class="user-info__name wrap-lines"
           *ngIf="data.user"
           [appRouterLink]="getLinkByObject(data?.user)">
          {{ getUserNameByObject(data.user) }}
        </a>
      </div>

      <div class="owner-container__user-data__home"
           *ngIf="data.user && data.user.homeCity && data.user.homeCity?.name && data.user.homeCity?.country.name">
        <img class="home-icon"
             alt="photo"
             src="/assets/icons/home-icon.svg">
        <span class="home-text">
                {{ data.user.homeCity.name }}, {{ data.user.homeCity.country.name }}
              </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reviewDateContainer let-data="data" let-text="text" let-index="index">
  <div class="date-container mt--10 pb-20">
    <a *ngIf="index > 0 || index === undefined" class="date"
       [routerLink]="'/places/'+ place.urlKeywords +'/' + place.id + '/review/' + data.id">
      {{ text + ' ' + (data.date || data.creationDate | date: 'd MMMM y') }}</a>
    <p *ngIf="index === 0" class="date">{{ text + ' ' + (data.date || data.creationDate | date: 'd MMMM y') }}</p>
  </div>
</ng-template>

<ng-template #actionContainer>
  <app-contact-info-display *ngIf="place?.isContactInfoEnabled && place?.user?.contactInfo"
                            [contactInfo]="place.user.contactInfo"></app-contact-info-display>

  <div class="action-button-container"
       [ngClass]="{'border-top' : !place?.reviews?.length}">
    <div class="place-container__action-item">

      <app-static-place-item-pin *ngIf="place && (place.otype === 1 || place.otype === 3)"
                                 [place]="place"
                                 [isVisibleTextBtn]="true"
                                 (pinPlace)="pinPlace($event)"
      ></app-static-place-item-pin>
    </div>

    <app-sign-in-buttons [isNotSignInButton]="addReviewContainer"></app-sign-in-buttons>

    <ng-template #addReviewContainer>
      <div class="place-container__action-item"
           *ngIf="canAddReview"
           (click)="toggleAddReview()">
        <img class="place-container__action-item__image" [src]="'/assets/icons/plus_rounded.svg'"
             alt="add review">
        <span class="place-container__action-item__title tooltip-event not-hide">Add a review</span>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #authorialCollection>
  <div class="place-container__collection-link" *ngIf="place?.collection !== null && place?.collection !== undefined">
    <a class="pb-10 upper" [routerLink]="['/journey/' + place?.collection.id]">
      {{ place?.collection.name }}
    </a>
  </div>
</ng-template>

<ng-template #authorialCollectionBottom>
  <div class="place-container__collection-link p-0" *ngIf="place?.authorialCollections?.length">
    <img src="/assets/icons/back-collection.svg" alt="collection" class="collection__icon">
    <ng-container *ngFor="let authorialCollection of place.authorialCollections;let last=last">
      <a [routerLink]="['/journey/' + authorialCollection?.id]">{{ authorialCollection.name }}</a>
      <ng-container *ngIf="!last">,</ng-container>
    </ng-container>
  </div>
</ng-template>
