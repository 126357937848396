<div class="header-container">
  <ng-container *ngIf="isMobileOrTablet; then tablet; else desktop"></ng-container>
</div>
<ng-template #tablet>
  <div class="static__search-panel"
       [ngStyle]="{'display': !isMobileOrTablet && staticService.isTabletDesign() && staticService.isOpenMap ? 'none': 'block'}"
       [class.search-panel-up]="isOpenMap || isMobileOrTablet"
       [class.home-page]="staticService.staticType === staticRouteType.HomePage"
  >
    <!-- fly search -->
    <app-fly-search-panel
      appHiddenSettingsSearchPanelOnScroll
      [isOpenMap]="staticService.isOpenMap"
      [place]="staticService.showPlace"
      [scrollTop]="scrollOffset"
      [resizeWindow]="staticService.resizeWindow"
      [data]="staticService.placesData"
      [staticTypes]="staticService.staticType"
      [searchMode]="searchMode"
      [isVisibleProgressBar]="helperService.ajaxLoaderVisible$ | async"
      (cityMonthChangeEmitter)="cityMonthChange($event)"
      (clearDest)="clearFilter()"
      [isPinnedPlaceOpen]="staticService.isOpenPinned"
      (pinnedChangeState)="changeHeadPinnedPlaceState()"
      (mobilePanelAction)="staticService.mobilePanelAction($event)"
    ></app-fly-search-panel>

  </div>
</ng-template>

<ng-template #desktop>
  <div class="header__content">
    <div class="header close">
      <div class="header__logo">
        <a [routerLink]="'/'" *ngIf="!isUserDomain">
          <img [appBasesrc]="'/assets/images/logo_140.png'" alt=""/>
        </a>
        <a [href]="constructBaseUrl('')" *ngIf="isUserDomain">
          <img [appBasesrc]="'/assets/images/logo_140.png'" alt=""/>
        </a>
      </div>

      <div class="header__search">
        <!-- fly search -->
        <app-fly-search-panel
          appHiddenSettingsSearchPanelOnScroll
          [isOpenMap]="staticService.isOpenMap"
          *ngIf="staticService.staticType && !staticService.isMobileDesign()"
          [place]="staticService.showPlace"
          [scrollTop]="scrollOffset"
          [resizeWindow]="staticService.resizeWindow"
          [data]="staticService.placesData"
          [staticTypes]="staticService.staticType"
          [searchMode]="searchMode"
          [isVisibleProgressBar]="scrollOffset > 0 && staticService.isMobileDesign()"
          (cityMonthChangeEmitter)="cityMonthChange($event)"
          (clearDest)="staticService.clearFilter()"
          [isPinnedPlaceOpen]="staticService.isOpenPinned"
          (pinnedChangeState)="changeHeadPinnedPlaceState()"
          (mobilePanelAction)="staticService.mobilePanelAction($event)"
        ></app-fly-search-panel>
      </div>

      <div class="header__users">
        <app-fly-add-button [userIsLogged]="userIsLogged"
                            (toggleRegisterFormEmit)="toggleRegisterForm()"></app-fly-add-button>

        <div class="pinned-wrapper header__mode-content">
          <div class="pinned-button decorate-b-b tooltip-event-item"
               (click)="toggleFavouritePopup()">
            <img [appBasesrc]="'/assets/icons/favorites2.svg'"
                 [class.animated]="isLikeAnimation"
                 class="pinned-img"
                 alt="active-pin">

            <span class="tooltip-event">Favourites</span>
          </div>

          <div class="popup-container" *ngIf="isFavouritePopupVisible">
            <app-fly-popup-profile
              [data]="popupDataFavourite"
              [togglePopupMethod]="'toggleFavouritePopup'"
              (emitAction)="emitAction($event)"></app-fly-popup-profile>
          </div>
        </div>

        <div class="pinned-wrapper header__mode-content tooltip-event-item">
          <div class="pinned-button decorate-b-b">
            <img [appBasesrc]="'/assets/icons/collections.svg'"
                 [class.animated]="isPinnedAnimation"
                 class="pinned-img"
                 alt="active-pin"
                 (click)="togglePinnedPlaces()">
          </div>
          <span class="tooltip-event" *ngIf="!isGuideVisible">Journeys</span>
          <app-guide-tooltip [pageObject]="journeyType" (changeStateEmitter)="changeGuideState($event)"></app-guide-tooltip>
        </div>

        <app-sign-in-buttons
          #signInButtons
          [userIsLogged]="userIsLogged"
          [month]="month"
          [searchMode]="searchMode"
          (closeUserAccount)="closeFavouritePanels()">
        </app-sign-in-buttons>
      </div>
    </div>

    <div class="progress-bar">
      <mat-progress-bar mode="indeterminate"
                        *ngIf="(ajaxState$| async) && (helperService.ajaxLoaderVisible$ | async)"></mat-progress-bar>
    </div>
  </div>
</ng-template>
