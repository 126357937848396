import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { StaticInitService } from '../../services/static-init.service';
import { constructBaseUrl, constructMediaURL } from '../../../../libraries';
import { StaticRouteType } from '../../enums/route-type';
import { MapService, SsrService } from '../../../../services';
import { Store } from '@ngrx/store';
import { State } from '../../../../store/reducers';
import SwiperCore, { EffectFade, Pagination, Parallax, SwiperOptions } from 'swiper';
import { PlaceGallery } from '../../../../interfaces';
import { StaticService } from '../../../../services/static.service';
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { TogglePinnedPanel } from "../../../../store/panels/panels.actions";
import { getCountryFlag } from "../../../../libraries/get-coutnry-flag";

SwiperCore.use([Pagination, Parallax, EffectFade]);

@Component({
  selector: 'app-static-place-item',
  templateUrl: './static-place-item.component.html',
  styleUrls: ['./static-place-item.component.scss'],
})
export class StaticPlaceItemComponent implements OnChanges, OnDestroy, AfterViewInit {

  @Input() hostPlace;
  @Input() isMapItDisabled = false;
  @Input() isLast: boolean;
  @Input() placeIndex: number;
  @Input() pageType: StaticRouteType;
  @Input() placeInfo;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  @Input() hasPrevArrow: boolean;

  @Output() changeVisibleTooltipPlaceEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pin = new EventEmitter<any>();
  @Output() openPlaceInfoEmitter = new EventEmitter<any>();

  @ViewChild('placeItemActions', {static: true}) placeActions: ElementRef;
  @ViewChild('placeName', {static: true}) placeNamEl: ElementRef;
  @ViewChild('placeItemContainer', {static: true}) placeItemContainer: ElementRef;

  public config: SwiperOptions = {
    modules: [Pagination, Parallax, EffectFade],
    parallax: true,
    effect: 'fade',
    pagination: {
      clickable: true,
      dynamicBullets: true,
      hideOnClick: false,
    },
    centeredSlides: true,
    loop: true,
    loopedSlides: 1,
    slidesOffsetBefore: 100,
    slidesOffsetAfter: 100,
    autoHeight: true,
    spaceBetween: 50,
    autoplay: false,
    preloadImages: false //because of lazyloader
  };

  public constructMediaURL = constructMediaURL;
  public hoverImage: string;
  public hoverVideo: string;
  public interval: any;
  public left: number;
  public offsetWidthPlaceItem: number;
  public top: number;
  public locationName: string;
  public showVideo: boolean;
  public gallery: PlaceGallery[] = [];
  public activeIndex: number = 0;
  public lineBranchTop: number = 0;
  public galleryMedia: any[] = [];
  public staticRouteType = StaticRouteType;
  public readonly countLimitText = {
    desktop: 5,
    mobile: 4
  };

  protected userLink: string;
  protected isUserDomain: boolean;

  constructor(
    public staticInitService: StaticInitService,
    private changeDetectorRef: ChangeDetectorRef,
    public staticService: StaticService,
    protected store$: Store<State>,
    private mapService: MapService,
    ssrService: SsrService,
  ) {
    this.isUserDomain = ssrService.getUserDomain() !== null;
  }

  ngOnChanges(changes): any {
    if (changes['placeInfo'] && changes['placeInfo'].currentValue) {
      this.placeInfo = changes['placeInfo'].currentValue;
      this.galleryMedia = [];
      if (this.placeInfo?.video?.length) {
        this.placeInfo.video.forEach(video => {
          Object.assign(video.poster, {
            isPortrait: video.isPortrait,
            isReady: video.isReady,
            isVideo: true,
          });

          Object.assign(video, {
            isVideo: true,
          });
          this.galleryMedia = this.galleryMedia.concat(video);
        });
      }
      if (this.placeInfo?.pictures?.length) {
        this.galleryMedia = this.galleryMedia.concat(this.placeInfo.pictures);
      }
      this.locationName = this.placeInfo?.city ? this.placeInfo.city.name : this.placeInfo?.country && this.placeInfo.country.name;
      this.userLink = getLinkByObject(changes['placeInfo'].currentValue?.user);
    }
    if (changes['windowSize'] && changes['windowSize'].currentValue) {
      this.staticService.resizeWindow = changes['windowSize'].currentValue;
      this.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.offsetWidthPlaceItem = this.placeActions.nativeElement.offsetWidth;

    if (this.placeNamEl && this.staticService.withFollowLine && this.staticService.isMobileDesign()) {
      this.lineBranchTop = this.placeNamEl.nativeElement.offsetTop + 20;
    }
    this.detectChanges();
  }

  ngOnDestroy(): void {
    this.hoverImage = null;
    clearInterval(this.interval);
  }

  public detectChanges(): void {
    if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public getMaxWidthCityName(): string {
    if (!this.offsetWidthPlaceItem) {
      return '0';
    }
    const value = this.offsetWidthPlaceItem * 0.7;
    return `${value}px`;
  }

  public mapItPlace(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
    this.changeVisibleTooltipPlaceEvent.emit(this.placeInfo);
    this.staticService.isOpenMap.next(true);
    this.mapService.isMapItProcess = true;
  }

  public routeToItemPlace(item: any): string {
    return getLinkByObject(item);
  }

  public setTooltipCoordinates(event): void {
    if (event.target) {
      const elem = event.target.getBoundingClientRect();
      this.top = elem.top - 45;
      this.left = elem.left;
    }
  }

  public overImage(isVideo = false): void {
    if (isVideo) {
      this.hoverVideo = this.placeInfo?.video[0]?.preview.large;
    } else {
      let index = 1;
      this.hoverImage = this.placeInfo.pictures && this.placeInfo.pictures[index] ? (this.placeInfo.pictures[index].small) : null;

      clearInterval(this.interval);
      this.interval = setInterval(() => {
        index++;
        if (index > 3) {
          index = 1;
        }
        this.hoverImage = this.placeInfo.pictures && this.placeInfo.pictures[index] ? (this.placeInfo.pictures[index].small) : null;
      }, 2000);
    }
  }

  public outImage(): void {
    this.hoverImage = null;
    this.hoverVideo = null;
    clearInterval(this.interval);
  }

  public pinPlace(item): void {
    setTimeout(() => {
      this.outImage();
      this.pin.emit(item);
    }, 100);
  }

  public onShowVideo(index: number = 0) {
    this.showVideo = true;
    this.gallery = this.gallery.concat(this.placeInfo?.video || []);
    this.gallery = this.gallery.concat(this.placeInfo?.pictures || []);
    this.activeIndex = index;
  }

  public openImage(value: boolean): void {
    this.showVideo = value;
  }

  public getRoute(routeType: StaticRouteType): string {
    let route = '';
    switch (routeType) {
      case this.staticRouteType.City:
        route = getLinkByObject(this.placeInfo.city);
        break;
      case StaticRouteType.Place:
        route = getLinkByObject(this.placeInfo);
        break;
    }
    return route;
  }

  protected readonly getLinkByObject = getLinkByObject;
  protected readonly getCountryFlag = getCountryFlag;
  protected readonly constructBaseUrl = constructBaseUrl;
}
