<div class="wrapper">
  <app-static-header
    [place]="staticService.showPlace"
    [isOpenMap]="staticService.isOpenMap.value"
    [data]="staticService.placesData"
    (mobilePanelAction)="mobilePanelAction($event)"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <!-- pinned -->
  <app-pinned-panel-container></app-pinned-panel-container>

  <!-- main div -->
  <div class="static__container">

    <!--left side-->
    <div class="static__content">

      <router-outlet></router-outlet>

      <app-footer></app-footer>
    </div>

    <!--right side-->
    <div class="static-container">

      <!-- map -->
      <div class="static-container-map"
           [class.hidden]="!(!staticService.isTabletDesign()
            || (staticService.isOpenMap.value
             && staticService.isTabletDesign()))
            || staticService.isFavoritesPlaceDisplay
            || staticService.isPinnedPlaceDisplay
            || staticService.isFollowingPanelVisible"
      >
        <app-static-map id="map" class="map-wrapper"
                        *ngIf="currentPlace"
                        [ngClass]="{'fixed': isMapFixed, 'fixedBottom': isMapBottom }"
                        [showPlace]="currentPlace"
                        [allPlaces]="showPlaces$ | async"
                        [idFrom]="idFrom"
                        [searchMapExtra]="searchMapExtra"
                        [receivedCity]="city"
                        [nameFrom]="nameFrom"
                        [month]="month"
                        [flightPlaceInfo]="flightPlaceInfo"
                        [userInfo]="staticService.userInfo"
                        [isMapFixed]="isMapFixed"
                        [isMobile]="isMobileMap"
                        [isUpdateTravelParams]="isUpdateTravelParams"
                        [setVisibleOfTooltip]="staticService.visibleTooltipPlace"
                        [staticType]="staticService.staticType"
                        (updateTooltipsEvent)="updateTooltips($event)"
                        (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)"
                        (openPinnedPlacesEvent)="openPinnedPlaces($event)"
                        (popupPlaceOpenedEvent)="routeToTypeStatic($event)"
                        (extendMapEvent)="extendMap($event)"
                        (selectMapItem)="routeToTypeStatic($event)"
        ></app-static-map>
      </div>
    </div>

  </div>
</div>
