import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { debounceTime, switchMap, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { TagInterface, TagsLimitType } from "../../../interfaces/tag";
import { InfoService } from "../../../services";

@Component({
  selector: 'app-hashtag-input',
  templateUrl: './hashtag-input.component.html',
  styleUrls: [
    './hashtag-input.component.scss',
    '../your-own-place/your-own-place.component.scss'
  ]
})
export class HashtagInputComponent implements OnInit, OnDestroy {
  @Input() text: string;
  @Input() existingTags: TagInterface[] = [];

  @Output() errorMessageEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() setFormControlEvent = new EventEmitter<{ key: string, value: any[] }[]>();

  @ViewChild('hashtagInput') hashtagInput: ElementRef<HTMLInputElement>;

  public inputValue = '';
  public tagPrefix = '#';
  public tags: TagInterface[] = [];
  public removable = true;

  private selectedTags: TagInterface[] = [];
  private tagsDelimiter = ' ';
  private tagsLimit: TagsLimitType = TagsLimitType.noLimit;
  private readonly maxCountTags = 6;
  private $destroyed = new Subject<void>();
  private getTagName$ = new Subject<string>();

  constructor(
    private infoService: InfoService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.getTagName$
      .pipe(
        debounceTime(300),
        takeUntil(this.$destroyed),
        switchMap((name) => {
          return this.infoService.getTag({name}, true);
        })
      )
      .subscribe(resultTags => {
        this.tags = resultTags.items;

        let selectedTags = this.getSplitTags;
        selectedTags = selectedTags.map(item => item.trim()).filter(x => x);

        this.tags = this.tags.map(selectedTag => {
          return (!selectedTags.some(tag => tag.toLowerCase() === selectedTag.name.toLowerCase())) && selectedTag;
        }).filter(x => x);
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public pasteData(event) {
    event.preventDefault();
    if (this.isAddingTagAvailable) {
      const clipboardData = event.clipboardData.getData('text');
      this.inputValue += this.tagPrefix + clipboardData + this.tagsDelimiter;
    }
  }

  public inputData(event: any | InputEvent) {
    event.preventDefault();
    const data = event.data;
    if (data) {
      const lastAddedTag = this.getLastAddedTag();
      const hasSpace = this.hasSpace();

      if ((this.isAddingTagAvailable || !hasSpace) && lastAddedTag) {
        this.getTagName$.next(lastAddedTag);
      }

      if (this.isAddingTagAvailable || !hasSpace) {
        if (this.isTagPrefixRequired(data)) {
          this.inputValue += this.tagPrefix;
        }
        // slice for predictive keyboard mode on mobiles,
        // in this mode event.isComposing=true and there is composition of symbols in event.data
        this.inputValue += data.slice(-1);
      } else {
        this.hashtagInput.nativeElement.value = this.inputValue;
      }
    } else {
      this.inputValue = this.hashtagInput.nativeElement.value;
    }

    this.checkCategories();
  }

  public selectCategories(item: any): void {
    const tags = this.getSplitTags;
    const lastTags = tags[tags.length - 1];
    const splitLastTags = lastTags.split(this.tagsDelimiter);
    const hasSpace = splitLastTags[splitLastTags.length - 1] === '';
    if (this.isAddingTagAvailable || !hasSpace) {
      if (hasSpace) {
        this.inputValue += this.tagPrefix + item.option.value?.name;
      } else {
        this.inputValue = this.inputValue.slice(0, -(lastTags.length));
        this.inputValue += item.option.value?.name;
      }
      this.selectedTags.push(item.option.value);
      this.hashtagInput.nativeElement.blur();
      this.tags = [];
      if (this.isAddingTagAvailable) {
        this.inputValue += this.tagsDelimiter;
      }
    } else {
      this.hashtagInput.nativeElement.value = this.inputValue;
    }
    this.cdRef.detectChanges();
  }

  public checkCategories(strictCheck = false) {
    this.isAddingTagAvailable;
    if (this.hashtagInput.nativeElement.value.slice(-1) === this.tagsDelimiter || strictCheck) {

      let tags = this.getSplitTags;
      tags = tags.map(item => item.trim()).filter(x => x);

      this.selectedTags = this.selectedTags.map(selectedTag => {
        return (tags.some(tag => tag.toLowerCase() === selectedTag.name.toLowerCase())) && selectedTag;
      }).filter(x => x);
      this.selectedTags = this.selectedTags.concat(this.existingTags || []);

      const tagName = tags.map(tag => {
        return (!this.selectedTags.some(selectedTag => selectedTag.name.toLowerCase() === tag.toLowerCase())) && tag;
      }).filter(x => x);

      this.setFormControlEvent.emit([
        {
          key: 'tagName',
          value: tagName
        },
        {
          key: 'tagId',
          value: this.selectedTags.map(selectedTag => selectedTag.id)
        }
      ]);
    }
  }

  public get getSplitTags() {
    return this.inputValue.split(this.tagPrefix);
  }

  public get getSplitTagsFromNativeEl() {
    return this.hashtagInput.nativeElement.value.split(this.tagPrefix);
  }

  public get isAddingTagAvailable() {
    const tags = this.getSplitTags;
    const existingTagsCount = this.existingTags ? this.existingTags?.length : 0;
    const tagsCount = tags.filter(x => x).length + existingTagsCount;
    if (tagsCount >= this.maxCountTags) {
      this.tagsLimit = TagsLimitType.limit;
      const tags = this.getSplitTags;
      const lastTags = tags[tags.length - 1];
      const splitLastTags = lastTags.split(this.tagsDelimiter);
      const hasSpace = splitLastTags[splitLastTags.length - 1] === '';
      if (hasSpace || existingTagsCount >= this.maxCountTags) {
        this.tagsLimit = TagsLimitType.limitWithSpace;
        this.showError(` Maximum is ${this.maxCountTags}.`);
      }
    } else {
      this.tagsLimit = TagsLimitType.noLimit;
    }
    return !this.tagsLimit;
  }

  public removeTag(tag: any): void {
    this.tagsLimit = TagsLimitType.noLimit;
    const loadTagsIndex = this.existingTags.indexOf(tag);
    const selectedTagsIndex = this.selectedTags.indexOf(tag);
    if (selectedTagsIndex >= 0) {
      this.selectedTags.splice(selectedTagsIndex, 1);
    }
    if (loadTagsIndex >= 0) {
      this.existingTags.splice(loadTagsIndex, 1);
    }
    this.checkCategories(true);
  }

  protected showError(text: string) {
    this.errorMessageEmitter.emit(text);
  }

  private isTagPrefixRequired(data: string): boolean {
    const lastEl = this.hashtagInput.nativeElement.value.slice(-2, -1);

    return (!this.inputValue || lastEl === ' ') && data !== ' ' && !data.startsWith(this.tagPrefix);
  }

  private getLastAddedTag(): string {
    const tagsNativeEl = this.getSplitTagsFromNativeEl;

    return tagsNativeEl[tagsNativeEl.length - 1];
  }

  private hasSpace(): boolean {
    const tags = this.getSplitTags;
    const lastTags = tags[tags.length - 1];
    const splitLastTags = lastTags.split(this.tagsDelimiter);

    return splitLastTags[splitLastTags.length - 1] === '';
  }
}
