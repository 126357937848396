import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SsrService } from './ssr.service';

declare const gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  private promoPagesUrl: string[] = [
    '/help/travel-business-page',
    '/help/travel-business',
  ];

  constructor(private ssrService: SsrService) {
  }

  private getGaTrackingId() {
    if (this.promoPagesUrl.find(url => window.location.href.includes(url))) {
      return environment.gaTrackingIdBusinessLand;
    }

    return environment.gaTrackingId;
  }

  /**
   * Add Google Analytics Script Dynamically
   * For using environment variables
   */
  public addGAScript(): void {
    if (this.ssrService.isBrowser()) {
      const gaTrackingId = this.getGaTrackingId();
      if (!gaTrackingId) {
        return;
      }
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
      document.head.prepend(gtagScript);
      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', gaTrackingId, {send_page_view: false});
    }
  }

  public logPageView(url: string): void {
    if (this.ssrService.isBrowser() && this.getGaTrackingId()) {
      gtag('event', 'page_view', {page_path: url});
    }
  }
}
