import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { loggedIn, State } from "../../store/reducers";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { StaticService } from "../../services/static.service";
import { MetaTagsService } from "../../services";

@Component({
  selector: 'app-landing-business',
  templateUrl: './landing-business.component.html',
  styleUrls: ['./landing-business.component.scss']
})
export class LandingBusinessComponent implements OnInit, OnDestroy {

  public isLoggedUser = false;
  private $destroyed = new Subject<void>();

  public benefits = [
    {
      title: "List your business",
      text: "Add your own Business Page to attract more customers with a compelling and vibrant presentation.<br><br>Contact details, phone numbers, and your working hours. Give travelers the inside scoop!",
      linkText: 'CREATE A PAGE',
      image: "/assets/images/landing-business/personal.gif"
    },
    {
      title: "Become visible",
      text: "Display your locations on a map. Add photos, videos, and important info with just a few clicks. <br><br>Make it simple for your customers to find you.",
      linkText: 'SIGN-UP FREE',
      image: "/assets/images/landing-business/places.gif"
    },
    {
      title: "Link it together",
      text: "Connect your spots, craft a cool journey page, and let travelers uncover amazing attractions.<br><br>Put your tours and travel experiences on the map!",
      linkText: 'GET STARTED',
      image: "/assets/images/landing-business/trips.gif"
    },
    {
      title: "Upload video",
      text: "Spice up your spots with quick 1-2 minute engaging videos.<br><br>Bite-sized format which easy to consume quickly. It is a great way to express creativity and showcase your travel business.",
      linkText: 'BEGIN',
      image: "/assets/images/landing-business/videos.gif"
    },
  ];

  constructor(
    private store$: Store<State>,
    private readonly router: Router,
    public staticService: StaticService,
    private metaTagsService: MetaTagsService,
  ) {
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.store$.pipe(
      select(loggedIn),
      takeUntil(this.$destroyed)
    ).subscribe(value => this.isLoggedUser = value);
    this.metaTagsService.resolver({
      route: 'promo'
    });
  }

  public redirectToAccount() {
    this.router.navigate(['/account']);
  }
}
