import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { Journey } from '../../../interfaces/journey';
import { UserCityActions } from '../../../store/user-cities';
import { Subscription } from 'rxjs/internal/Subscription';
import { select, Store } from '@ngrx/store';
import { getPinnedList, State } from '../../../store/reducers';
import {
  FlyCookieService,
  MonthService,
  SsrService,
  UserAccountLoadStatus,
  UserAccountService
} from '../../../services';
import { selectNextMonth } from '../../../libraries/select-next-month';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { PinInterface, PlacePinExt, PlacePinType } from '../../../interfaces';
import { DestinationType } from '../../../enums/destination-type';
import { isPlatformBrowser } from '@angular/common';
import { BottomSheetActionsInterface, UserCollectionService } from '../../../services/user-collection.service';
import { AuthModalService } from '../../../services/auth-modal.service';
import { TogglePinnedPanel } from "../../../store/panels/panels.actions";
import { DEFAULT_TITLE_JOURNEY } from "../../../../constants";
import { PinnedModeIdx } from "../../../enums/pinned-mode.enum";

@Component({
  selector: 'app-pinned-panel-collection-info-no-auth',
  templateUrl: './pinned-panel-collection-info-no-auth.component.html',
  styleUrls: ['./../pinned-panel-collection-info/pinned-panel-collection-info.component.scss',
    './pinned-panel-collection-info-no-auth.component.scss']
})
export class PinnedPanelCollectionInfoNoAuthComponent implements OnInit, OnChanges, OnDestroy {

  @Input() mode: PinnedModeIdx;
  @Input() collection: Journey;
  @Input() selectCollection: number;
  @Input() selectCollectionData: any;
  @Input() allCollections: Journey[];
  @Input() allPins: PinInterface[];
  @Input() userIsLogged: boolean;
  @Input() isProfile: boolean;
  @Input() isBottomSheet: boolean;

  @Output() emitOpenBottomSheet = new EventEmitter<any>();
  @Output() deletePin = new EventEmitter<any>();
  @Output() emitCreateCollection = new EventEmitter<any>();
  @Output() openPlaceEmitter = new EventEmitter<any>();

  public tabs = [
    {
      name: 'Places',
      value: PinnedModeIdx.Places,
      type: DestinationType.Place,
      isShow: true
    },
    {
      name: 'Cities',
      value: PinnedModeIdx.Cities,
      type: DestinationType.City,
      isShow: true
    },
  ];

  public changedPinnedMode: PinnedModeIdx;
  public isPinnedLoading: boolean;
  public items: PinInterface[] = [];
  public month: any;
  public subscriptions: Subscription = new Subscription();
  public journeyTitle: string;
  public journeyDescription: string;
  public defaultJourneyTitle = DEFAULT_TITLE_JOURNEY;
  public isEdit: boolean;
  public isBrowser = false;
  public activeTabs = [];
  public allItems: PinInterface[];

  constructor(
    private store$: Store<State>,
    private monthService: MonthService,
    private cookieService: FlyCookieService,
    private userAccountService: UserAccountService,
    private userCollectionService: UserCollectionService,
    private authModalService: AuthModalService,
    private ssrService: SsrService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.month = this.monthService.getMonthByNumber(
      selectNextMonth()
    );

    this.subscriptions.add(
      this.userAccountService.loadingStatusChange
        .subscribe(value => {
          this.isPinnedLoading = value[UserAccountLoadStatus.GetPinned];
        })
    );

    this.store$.dispatch(new UserCityActions.LoadCities());

    this.subscriptions.add(
      this.getFilteredItems(() => true)
        .subscribe(items => {
          this.allItems = items;
          this.changeMode(this.getMode(), true);
        })
    );
    this.checkTitleCollectionEmit();
    this.journeyTitle = this.collection?.name;
    this.journeyDescription = this.collection?.description;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode'] && changes['mode'].currentValue) {
      this.changeMode(changes['mode'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public changeMode(mode: PinnedModeIdx, checkTab?: boolean): void {
    if (mode !== this.changedPinnedMode) {
      this.changedPinnedMode = mode;
    }

    this.subscriptions.add(
      this.getFilteredItems(
        (item: PinInterface) => item && item.otype === PinnedPanelCollectionInfoNoAuthComponent.getType(mode)
      ).subscribe(items => {
        if (checkTab && items) {
          this.checkIsEmptyTabItems(items, mode);
        } else {
          this.items = items;
          this.checkIsEmptyTab();
        }
        this.checkBottomSheet();
      })
    );
  }

  private checkIsEmptyTab() {
    this.tabs.forEach(tab => tab.isShow = true);

    const cityTab = this.tabs.find(tab => tab.type === DestinationType.City);
    const placeTab = this.tabs.find(tab => tab.type === DestinationType.Place);
    const cityPinItems = this.allItems && this.allItems.filter(items => items.otype === DestinationType.City);
    const placePinItems = this.allItems && this.allItems.filter(items => items.otype === DestinationType.Place);

    if (!(cityPinItems && placePinItems)) {
      cityTab.isShow = false;
      placeTab.isShow = true;
    }

    if (cityPinItems && cityPinItems.length === 0) {
      cityTab.isShow = false;
    }
    if (placePinItems && placePinItems.length === 0 && cityTab.isShow) {
      placeTab.isShow = false;
    }

    this.activeTabs = this.tabs.map(tab => ({...tab})).filter(tab => tab.isShow);
  }

  private checkIsEmptyTabItems(items: PinInterface[], mode: PinnedModeIdx): void {
    this.checkIsEmptyTab();
    if (!items.length) {
      if (mode === PinnedModeIdx.Cities) {
        this.changeMode(PinnedModeIdx.Places, true);
      } else {
        this.changeMode(PinnedModeIdx.Cities);
      }
    } else {
      this.items = items;
    }
  }

  public deleteItem(place: PlacePinExt): void {
    this.deletePin.emit(place);
  }

  public close(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
  }

  public getMode(): PinnedModeIdx {
    return this.changedPinnedMode || this.mode || PinnedModeIdx.Places;
  }

  private static getType(mode: PinnedModeIdx): number {
    let type: number = DestinationType.City;

    switch (mode) {
      case PinnedModeIdx.Places:
        type = DestinationType.Place;
        break;
      case PinnedModeIdx.Restaurants:
        type = DestinationType.Restaurant;
        break;
    }

    return type;
  }

  private getFilteredItems(cb: (item: PinInterface) => boolean): Observable<PinInterface[]> {
    return this.store$.pipe(
      select(getPinnedList),
      map((list: PinInterface[]) => list.length && list.filter(item => item._pinTypes && item._pinTypes.includes(PlacePinType.WannaGo))),
      map((list: PinInterface[]) => list.length && list.filter(cb))
    );
  }

  public toggleEditJourneyTitle() {
    if (this.isBottomSheet) {
      this.userCollectionService.bottomSheetActions = {data: {action: 'toggleEditTitlePopup'}};
      this.emitOpenBottomSheet.emit();
    } else {
      if (!this.journeyTitle) {
        this.isEdit = !this.isEdit;
      }
      this.userCollectionService.bottomSheetActions = null;
    }
  }

  public toggleEditJourneyDescription() {
    if (this.isBottomSheet) {
      this.userCollectionService.bottomSheetActions = {data: {action: 'toggleEditJourneyDescription'}};
      this.emitOpenBottomSheet.emit();
    } else {
      if (!this.journeyDescription) {
        this.isEdit = !this.isEdit;
      }
      this.userCollectionService.bottomSheetActions = null;
    }
  }

  public checkBottomSheet() {
    if (this.isBottomSheet && this.items) {
      this.items = this.items
        .filter((item, index, arr) => (arr.length - 1 === index || arr.length - 2 === index))
        .reverse();
    }
  }

  public checkTitleCollectionEmit() {
    if (!this.isBottomSheet) {
      const bottomSheet: BottomSheetActionsInterface = this.userCollectionService.bottomSheetActions;
      if (bottomSheet && bottomSheet.data && !bottomSheet.data.itemId && bottomSheet.data.action) {
        this.emitAction(bottomSheet.data.action);
      }
    }
  }

  public emitAction(funcName) {
    this[funcName]();
  }

  public openBottomSheet() {
    this.emitOpenBottomSheet.emit();
  }

  openPlace(event): void {
    this.openPlaceEmitter.emit(event);
  }
}
