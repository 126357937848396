import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { FlyToastrModule } from "../../../shared-components/fly-toastr/fly-toastr.module";



@NgModule({
  declarations: [
    VideoPlayerComponent
  ],
  exports: [
    VideoPlayerComponent
  ],
  imports: [
    CommonModule,
    FlyToastrModule
  ]
})
export class VideoPlayerModule { }
