<div class="contact-info__container" [class.disable-block]="!isContactInfoEnabled">
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-website.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">Website</div>
      <div class="data-text">
        <a *ngIf="contactInfo?.webSite && !editableContactForm.webSite"
           class="link-info cut-text"
           target="_blank"
           rel="nofollow noopener"
           [href]="contactInfo.webSite">
          {{contactInfo.webSite}}
        </a>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.webSite, type: contactInfoEnum.webSite}"></ng-container>
      </div>

      <ng-container *ngIf="editableContactForm.webSite">
        <app-edit-contact-input [textType]="'url'"
                                [data]="contactInfo?.webSite"
                                [type]="contactInfoEnum.webSite"
                                (setContactInfoEmitter)="setContactInfo($event.type, $event.data)"
                                (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-edit-contact-input>
      </ng-container>
    </div>
  </div>
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-phone.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">Phone</div>
      <div class="data-text">
        <p *ngIf="contactInfo?.phone && !editableContactForm.phone"
           class="data-info">
          {{contactInfo.phone}}
        </p>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.phone, type: contactInfoEnum.phone}"></ng-container>
      </div>

      <ng-container *ngIf="editableContactForm.phone">
        <app-edit-contact-input [textType]="'tel'"
                                [data]="contactInfo?.phone"
                                [type]="contactInfoEnum.phone"
                                (setContactInfoEmitter)="setContactInfo($event.type, $event.data)"
                                (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-edit-contact-input>
      </ng-container>
    </div>
  </div>
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-email.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">Email</div>
      <div class="data-text">
        <p *ngIf="contactInfo?.email && !editableContactForm.email"
           class="data-info">
          {{contactInfo.email}}
        </p>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.email, type: contactInfoEnum.email}"></ng-container>
      </div>

      <ng-container *ngIf="editableContactForm.email">
        <app-edit-contact-input [textType]="'email'"
                                [data]="contactInfo?.email"
                                [type]="contactInfoEnum.email"
                                (setContactInfoEmitter)="setContactInfo($event.type, $event.data)"
                                (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-edit-contact-input>
      </ng-container>
    </div>
  </div>
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-address.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">Address</div>
      <div class="data-text">
        <p *ngIf="contactInfo?.address && !editableContactForm.address"
           class="data-info">
          {{contactInfo.address}}
        </p>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.address, type: contactInfoEnum.address}"></ng-container>
      </div>

      <ng-container *ngIf="editableContactForm.address">
        <app-edit-contact-input [textType]="'text'"
                                [data]="contactInfo?.address"
                                [type]="contactInfoEnum.address"
                                (setContactInfoEmitter)="setContactInfo($event.type, $event.data)"
                                (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-edit-contact-input>
      </ng-container>
    </div>
  </div>
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-hours.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">Opening Hours</div>
      <div class="data-text">
        <p *ngIf="contactInfo?.businessHours?.length && !editableContactForm.businessHours"
           [innerHTML]="contactInfo?.businessHours | showSchedule"
           class="data-info"></p>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.businessHours?.length, type: contactInfoEnum.businessHours}"></ng-container>
      </div>

      <app-schedule-form *ngIf="editableContactForm.businessHours"
                         [scheduleApiData]="contactInfo?.businessHours"
                         (saveEmit)="setContactInfo(contactInfoEnum.businessHours, $event)"
                         (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-schedule-form>
    </div>
  </div>
  <div class="contact-info__item">
    <img class="contact-info__img" src="assets/icons/b-whatsapp.svg" alt="contact-icon">
    <div class="data-container">
      <div class="data-title">WhatsApp</div>
      <div class="data-text">
        <a *ngIf="contactInfo?.whatsapp && !editableContactForm.whatsapp"
           class="link-info"
           target="_blank"
           rel="nofollow noopener"
           [href]="'https://wa.me/' + contactInfo.whatsapp">
          {{contactInfo.whatsapp}}
        </a>
        <ng-container *ngTemplateOutlet="editContactBtnContainer;
        context: {data: contactInfo?.whatsapp, type: contactInfoEnum.whatsapp}"></ng-container>
      </div>

      <ng-container *ngIf="editableContactForm.whatsapp">
        <app-edit-contact-input [textType]="'tel'"
                                [data]="contactInfo?.whatsapp"
                                [type]="contactInfoEnum.whatsapp"
                                (setContactInfoEmitter)="setContactInfo($event.type, $event.data)"
                                (toggleContactFormEmitter)="toggleContactForm($event.type)"></app-edit-contact-input>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #editContactBtnContainer let-data="data" let-type="type">
  <div class="edit-contact-container"
       *ngIf="!editableContactForm[type]"
       [class.m-0]="!data"
       (click)="toggleContactForm(type)">
    <span *ngIf="!isProfilePage" class="edit-contact-info">{{!data ? '(add)' : '(edit)'}}</span>
    <span *ngIf="isProfilePage" class="edit-item-label">{{!data ? 'Add' : 'Edit'}}</span>
  </div>
</ng-template>
