import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import { DatePickerMode } from "../../../../../../interfaces/datepicker-date";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {

  @Input() availableMode: DatePickerMode[] = [DatePickerMode.Month, DatePickerMode.Date];
  @Input() date = dayjs();
  @Input() interval;
  @Input() minDateLimit = null;
  @Input() maxDateLimit = null;
  @Input() mode = DatePickerMode.Date;
  @Input() large = false;

  @Output() selectEmitter = new EventEmitter<any>();

  public DatePickerMode = DatePickerMode;

  constructor() {
  }

}
