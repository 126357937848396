import { GetBrowserGeoPosition } from './../../../store/auth/auth.actions';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { SearchService } from '../../../services/search.service';
import { showMatch } from '../../../libraries/show-match';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { changeVal } from '../../../libraries/change-val';
import { clearObservable, waitObservable } from '../../../libraries/timer-observable';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import { CityMapData } from '../../../interfaces';
import { DestinationType } from '../../../enums/destination-type';
import { Subject } from 'rxjs/internal/Subject';
import { CloseService } from '../../../services/close.service';

@Component({
  selector: 'app-from-search-field',
  templateUrl: 'from-search-field.component.html',
  styleUrls: [
    '../../../styles/mat-styles.scss',
    'from-search-field.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FromSearchFieldComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() label: string = null;
  @Input() startValue: string = '';
  @Input() value: CityMapData | any;

  @Output() selectEmitter = new EventEmitter<any>();
  @Output() closeDropDown = new EventEmitter<{ isClosed: boolean, hasError: boolean }>();

  @ViewChild('inputFrom', {static: true}) inputFrom;

  public cityForm = new UntypedFormControl();
  public cityOptions: any = [];
  public errorText = null;
  public fromFocused: boolean = false;
  public observableId;
  public opened: boolean = false;
  public selectedCity: any;
  public selectedCityIndex = -1;

  private $destroyed = new Subject<void>();

  constructor(
    private searchService: SearchService,
    private store$: Store<State>,
    private closeService: CloseService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value &&
      typeof changes.value.currentValue !== 'boolean' &&
      changes.value.currentValue
    ) {
      this.setValue(changes.value.currentValue.name);
    }
    if (changeVal(changes.startValue)) {
      let value = changes.startValue.currentValue;
      this.clearObservable();
      this.observableId = waitObservable(
        (_) => this.inputFrom,
        (inputFrom) => {
          value = value.replace(/_/g, ' ');
          inputFrom.nativeElement.value = value;
        },
        100
      );
    }
  }

  ngOnInit() {
    if (this.value && this.value.name) {
      this.setValue(this.value.name);
    }
  }

  ngAfterViewInit() {
    this.inputValueChanges('cityOptions');
  }

  ngOnDestroy() {
    this.clearObservable();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public setValue(newValue: string): void {
    this.cityForm.setValue(newValue, {emitEvent: false});
  }

  public clearDestFilters(): void {
    this.errorText = null;
  }

  clearObservable() {
    clearObservable(this.observableId);
  }

  getBrowserGeoPosition() {
    this.store$.dispatch(new GetBrowserGeoPosition());
  }

  selectCity(city: any, inputFrom) {
    this.opened = false;
    if (city) {
      inputFrom.value = city.place.name;
      this.selectEmitter.emit(city);
    }
  }

  inputValueChanges(optionReference: string) {
    this.cityForm.valueChanges
      .pipe(
        debounceTime(600),
        tap((val: string) => {
          if (val && val.length >= 3) {
            this.filterCities(val, optionReference);
          } else {
            this[optionReference] = [];
          }
        }),
        takeUntil(this.$destroyed)
      )
      .subscribe();
  }

  filterCities(q: string, optionReference: string) {
    const httpParams: HttpParams = new HttpParams()
      .append('filter[name]', q)
      .append('expand', 'country');

    this.searchService.searchCity(httpParams).subscribe((response: any) => {
      if (response.length === 0) {
        this.errorText = q;
      } else {
        this.clearDestFilters();
      }

      this[optionReference] = showMatch(response, q);
      this.opened = this[optionReference].length > 0;
      this.selectedCityIndex = -1;
      this.selectedCity = null;

      if (this.cdRef && !this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    });
  }

  onBlurFrom() {
    this.fromFocused = false;
  }

  onFocusFrom() {
    this.fromFocused = true;
    this.opened = true;
  }

  keyDown(event: KeyboardEvent) {
    if (!this.cityOptions || this.cityOptions.length == 0) return;
    if (event.keyCode == 13) {
      this.opened = false;
      this.selectedCity = this.cityOptions[this.selectedCityIndex];
      this.selectEmitter.emit({...this.selectedCity, type: DestinationType.City});
      this.cityOptions = [];
      if (this.cdRef && !this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    } else {
      switch (event.keyCode) {
        case 38: // this is the ascii of arrow up
          this.selectedCityIndex--;
          if (this.selectedCityIndex < 0)
            this.selectedCityIndex = this.cityOptions.length - 1;
          break;
        case 40: // this is the ascii of arrow down
          this.selectedCityIndex++;
          if (this.selectedCityIndex >= this.cityOptions.length)
            this.selectedCityIndex = 0;
          break;
      }
      if (this.selectedCityIndex >= 0) {
        this.cityOptions.forEach((element) => {
          element['isSelected'] = false;
        });
        this.cityOptions[this.selectedCityIndex]['isSelected'] = true;
        if (this.cdRef && !this.cdRef['destroyed']) {
          this.cdRef.detectChanges();
        }
      }
    }
  }

  CloseDropDown(event) {
    this.closeDropDown.emit({
      isClosed: event,
      hasError: !!this.errorText
    });
  }

  backButton() {
    this.closeService.close$.next(true);
  }
}
