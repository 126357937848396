import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsNumGuard  {

  constructor(
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const url = route.url[route.url.length - 1].path;
    if (!isNaN(+url)) {
      return true;
    } else {
      this.router.navigate(['/404']);
      return false;
    }
  }
}
