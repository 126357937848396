import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: [
    'confirm-dialog.component.scss'
  ]
})
export class ConfirmDialogComponent {
  @Input() message: string;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  public save() {
    this.action.emit(true);
  }

  public decline() {
    this.action.emit(false);
  }
}
