<div class="empty-pinned-place" *ngIf="toggleMenu"></div>
<div class="iphone-bottom-fix" *ngIf="toggleMenu"></div>
<div class="menu-wrapper" *ngIf="toggleMenu">
  <app-close-btn class="button-close" (click)="onToggleMenu(false)"></app-close-btn>

  <div class="user-sidebar__container" [class.notification-wrapper]="isNotificationContentVisible">

    <ng-container *ngIf="!contactContentOpened && !isNotificationContentVisible">

      <div class="user-sidebar__content-item">
        <div class="user-sidebar__sub-items">

          <ng-container *ngIf="userInfo">
            <div class="user-sidebar__item">
              <span class="border-top-radius with-icon"
                    (click)="onToggleMenu(false); ssrService.goToRoute(getLinkByObject(userInfo))">
                <div class="popup-row popup-row__mobile">
                  <ng-container
                    *ngTemplateOutlet="userInfo?.createdPlacesPics?.length > 0 ? multiIconContainer : singleIconContainer;
                    context: {pictures: userInfo?.createdPlacesPics}"></ng-container>
                  <div class="popup-row-text">Personal Page</div>
                </div>
                <span class="user-sidebar__arrow">
                  <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
                </span>
              </span>
            </div>
            <div class="user-sidebar__item" (click)="toggleNotificationContent()">
                <span class="with-icon">
                  <div class="popup-row popup-row__mobile">
                    <div class="popup-row-icon popup-row-icon__mobile full-img">
                      <img [appBasesrc]="'/assets/icons/notifications.svg'" alt="Notifications">
                      <span *ngIf="newNotifications?._extra?.unreadCount" class="new-notification-counter menu-badge">
                        <span class="counter-content">{{ newNotifications?._extra?.unreadCount }}</span>
                      </span>
                    </div>
                    <div class="popup-row-text">
                      Notifications</div>
                  </div>
                  <span class="user-sidebar__arrow">
                    <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
                  </span>
                </span>
            </div>
            <div class="user-sidebar__item">
            <span class="border-bottom-radius with-icon"
                  [appRouterLink]="'/account'">
              <div class="popup-row popup-row__mobile">
                <div class="popup-row-icon popup-row-icon__mobile full-img">
                  <img [appBasesrc]="'/assets/icons/my-account.svg'" alt="My Account">
                </div>
                <div class="popup-row-text">My Account</div>
              </div>
              <span class="user-sidebar__arrow">
                <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
              </span>
            </span>
            </div>
          </ng-container>

          <div class="user-sidebar__item" *ngIf="isFlightSettingEnabled" (click)="toggleSettingsFlyPanel(true)">
            <span class="border-bottom-radius" [class.border-top-radius]="!userInfo">
                <div class="popup-row popup-row__mobile">
                  <div class="popup-row-icon popup-row-icon__mobile">
                    <img [appBasesrc]="'/assets/icons/plane-icon.svg'" class="custom-size-flights-img"
                         alt="Flights Settings">
                  </div>
                  <div class="popup-row-text">Flights Settings</div>
                </div>
                <span class="user-sidebar__arrow">
                  <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
                </span>
            </span>
          </div>
          <a appTooltip
             [position]="'above'"
             [title]="title"
             [href]="href"
             *ngIf="href">
            <div class="user-sidebar__item">
          <span class="border-bottom-radius">
              Switch to Map
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
            </div>
          </a>
        </div>
      </div>

      <div class="user-sidebar__content-item">
        <span>HELP CENTER</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item" (click)="toggleContactContent()">
          <span class="border-top-radius">
            <a>Contact Us</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
          <div class="user-sidebar__item"
               [appRouterLink]="item.url"
               *ngFor="let item of burgerLinks; let last=last">
        <span [class.border-bottom-radius]="last">
           <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
        </span>
          </div>
        </div>
      </div>

      <div class="user-sidebar__content-item">
        <span>EXPLOROW</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item"
               [appRouterLink]="item.url"
               *ngFor="let item of burgerSubLinks; let i=index; let last=last">
          <span [ngClass]="{'border-top-radius': i===0, 'border-bottom-radius': last}">
            <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="contactContentOpened">

      <div class="user-sidebar__content-item">
        <span>CONTACT US</span>
        <div class="user-sidebar__sub-items">
          <div class="user-sidebar__item"
               (click)="toggleContactUs(item)"
               *ngFor="let item of contactUsLinks; let i=index; let last=last">
          <span [ngClass]="{'border-top-radius': i===0, 'border-bottom-radius': last}">
            <a>{{ item.name }}</a>
            <span class="user-sidebar__arrow">
              <img alt="" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="isNotificationContentVisible">
      <div class="user-sidebar__content-item" [class.notification-wrapper]="isNotificationContentVisible">
        <app-notifications (clickOnItem)="onToggleMenu(false)"></app-notifications>
      </div>
    </ng-container>
  </div>
</div>


<div class="settings-panel" *ngIf="isOpenSettingsPanel">
  <div class="close-settings-panel">
    <app-close-btn (click)="toggleSettingsFlyPanel(false)"></app-close-btn>
  </div>
  <app-static-settings-panel></app-static-settings-panel>

  <button
    mat-raised-button
    class="btn small"
    (click)="save()">
    Save
  </button>
</div>

<div class="burger-menu decorate-b-b" [class.avatar]="hasIcon()" (click)="onToggleMenu(true)">
  <img class="burger-img" [src]="getUserIcon()" alt="burger">
  <div class="new-notification-counter"
       *ngIf="!!isUserLogged && newNotifications?._extra?.unreadCount">
    <span class="counter-content">{{ newNotifications?._extra?.unreadCount }}</span>
  </div>
</div>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactType"
                (closeContactUs)="toggleContactUs()"></app-contact-us>

<ng-template #multiIconContainer let-pictures="pictures">
  <div class="gallery-grid-container" [class.one-picture]="pictures.length === 1">
    <div class="image-item grid-container-item" *ngFor="let icon of pictures">
      <img alt="pic" src="{{constructMediaURL(icon)}}">
    </div>
  </div>
</ng-template>

<ng-template #singleIconContainer>
  <div class="popup-row-icon popup-row-icon__mobile">
    <img [appBasesrc]="'/assets/icons/personal-page.svg'" alt="Personal Page">
  </div>
</ng-template>
