import { Injectable } from '@angular/core';
import { FlyCookieService } from '../services/fly-cookie/fly-cookie.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from './window.service';

@Injectable()
export class LanguageService {
  public availableLanguages: any = [
    {
      name: 'en-US,en', value: 0.5, label: 'en', fullName: 'English'
    },
    // {
    //   name: 'ru-RU,ru',  value: 0.4, label: 'ru'
    // }
  ];
  public curLangLabel: string;
  public currentLanguage: any;
  public defaultLanguage = this.availableLanguages[0];
  public languageLabelInCookies: string = 'notificationLanguage';
  public setLngToHeaders: string;
  public window: any;

  constructor(
    private cookieService: FlyCookieService,
    private translateService: TranslateService,
    private windowRef: WindowRef
  ) {
    this.window = windowRef.getNativeWindow();
    let languageInCookies: any = this.cookieService.check(`${this.languageLabelInCookies}`);
    let browserLanguage: any = this.getCurrentBrowserLanguage();
    this.translateService.setDefaultLang('en');
    if (!languageInCookies) {
      this.setCurrentLanguage(browserLanguage);
      return;
    }
    let preferredLanguage = this.cookieService.get(`${this.languageLabelInCookies}`);
    this.setCurrentLanguage(preferredLanguage);
  }

  setCurrentLanguage(language: any) {

    this.currentLanguage = this.availableLanguages.find((lng) => {
      return lng.label == language;
    }) || this.defaultLanguage;
    let defLabel = this.defaultLanguage.label;
    let curLabel = this.currentLanguage.label;
    let availLangLength = this.availableLanguages;
    if (this.window) {
      this.cookieService.set(`${this.languageLabelInCookies}`, this.currentLanguage.label);
    }
    if (defLabel == curLabel && availLangLength > 1) {
      this.translateService.use(this.currentLanguage.label);
    }
    this.curLangLabel = this.currentLanguage.label;
    this.setLngToHeaders = this.makeLngString(this.currentLanguage);
  }

  getCurrentBrowserLanguage() {
    return this.translateService.getBrowserLang();
  }

  makeLngString(lng: any) {
    return `${lng.name};q=${lng.value};`;
  }

}
