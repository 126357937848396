export enum PlaceStatusType {
  ClientStatusOnline = 1,
  ClientStatusOnModeration = 2,
  ClientStatusRejected = 3,
  ClientStatusOnlineChangesOnModeration = 4,
  ClientStatusOnlineChangesRejected = 5,
}

export enum ReviewStatusType {
  ClientStatusDisable = 0,
  ClientStatusOnline = 1,
  ClientStatusOnModeration = 2,
  ClientStatusNewFromUser = 3,
  ClientStatusRejected = 5,
}
