<section>
  <header i18n="labels.auth.click_social" [innerText]="'labels.auth.or_continue_with' | translate | capitalize">
    or continue with
  </header>

  <div class="social-net-btn">
    <div *ngIf="socialGoogle">
      <button mat-raised-button
              class="btn google-btn"
              (click)="signInWithGoogle($event)">
        <span> Google </span>
      </button>
    </div>
    <div *ngIf="socialFB">
      <button mat-raised-button
              class="btn fb-btn"
              (click)="signInWithFB($event)">
        <span> Facebook </span>
      </button>
    </div>
  </div>

</section>
