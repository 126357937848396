import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  public responseCache = new Map();

  constructor(private httpService: HttpService) {
  }

  public getHomePageData(params: HttpParams): Observable<any> {
    const uniqueCacheId: string = SearchService.getUniqueId(`info/title`, params);
    const fromCache = this.responseCache.get(uniqueCacheId);
    if (fromCache) {
      return of(fromCache);
    }
    return this.httpService.get(`info/title`, params).pipe(
      tap((data: any) => this.responseCache.set(uniqueCacheId, data))
    );
  }
}
