import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-close-area',
  templateUrl: 'close-area.component.html',
  styleUrls: ['close-area.component.scss']
})
export class CloseAreaComponent {

  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();

  @Input() opened: boolean;

  close($event, modalWindow) {
    if ($event.target == modalWindow) {
      this.closeEmitter.emit();
    }
  }

}
