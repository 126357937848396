import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SsrService } from "../services";

@Directive({
  selector: '[appRouterLink]'
})
export class RouterLinkDirective implements AfterViewInit {

  @Input() appRouterLink: string = '';

  constructor(
    private el: ElementRef,
    private ssrService: SsrService
  ) {
  }

  @HostListener('click')
  onClick(): void {
    this.ssrService.goToRoute(this.appRouterLink);
  }

  ngAfterViewInit() {
    this.el.nativeElement.style.cursor = 'pointer';
  }
}
