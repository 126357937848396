import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { WindowRef } from '../services/window.service';
import { BindToComponentService } from '../services/bind-to-component.service';

@Directive({
  selector: '[appFlyBindComponentTo]',
})
export class FlyBindComponentToDirective implements OnChanges {
  @Input() aliasLink;
  @Input() opened: boolean;
  @Input() itemOptions: any[] = [];
  @Input() dLeft = 0;
  @Input() selectedCity: any;

  @Output() callback = new EventEmitter();

  constructor(
    private el: ElementRef,
    private windowRef: WindowRef,
    private bindToComponentService: BindToComponentService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemOptions && changes.itemOptions.currentValue) {
      this.onRenderDestFeatures();
    }
    if (changes.opened && !changes.opened.currentValue) {
      this.selectTemplateToBind(this.itemOptions, this.getComputedStyles(), (response) => this.responseData(response), this.opened);
    }
    if (changes.selectedCity && changes.selectedCity.currentValue) {
      this.responseData(this.selectedCity);
    }
  }

  selectTemplateToBind(itemOptions, computedStyles, responseData, opened) {
    this.bindToComponentService.autocompleteConfig = {itemOptions, computedStyles, responseData: responseData, opened};
  }

  responseData(response) {
    this.callback.emit(response);
  }

  @HostListener('window:resize')
  public onRenderDestFeatures() {
    if (!this.windowRef.isBrowser || !this.el || !this.opened) {
      return;
    }

    this.selectTemplateToBind(this.itemOptions, this.getComputedStyles(), (responce) => this.responseData(responce), this.opened);
  }

  @HostListener('window:scroll')
  onScroll() {
    this.onRenderDestFeatures();
  }

  private getComputedStyles() {
    if (!this.el || !this.windowRef.isBrowser || !this.el.nativeElement) {
      return {};
    }
    const rect = this.el.nativeElement.getBoundingClientRect();

    return {
      dfTop: `-27px`,
      dfLeft: `${rect.x}px`,
      aoLeft: `${rect.x + this.dLeft}px`,
      width: `${rect.width}px`,
      rect
    };
  }
}
