export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop'
}

export function getDeviceTypeByUA(ua: string) {
  let uaValue;
  const regexMobileDevice = /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera\ mini|avantgo|mobilesafari|docomo|KAIOS)/i;
  const regexTabletDevice = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

  if (ua && regexMobileDevice.test(ua?.toLowerCase())) {
    uaValue = DeviceType.Mobile;
  } else if (ua && regexTabletDevice.test(ua?.toLowerCase())) {
    uaValue = DeviceType.Tablet;
  } else {
    uaValue = DeviceType.Desktop;
  }
  return uaValue;
}
