import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { PlaceService } from "../../../../services/place.service";
import { typeMedia } from "../../../place/your-own-place/your-own-place.component";
import { DEFAULT_USER_IMAGE } from "../../../../../constants";
import { ContactInfoEditComponent } from "../../../contact-info/contact-info-edit/contact-info-edit.component";
import { UserSettingsNames } from "../../../../interfaces/user";
import { constructMediaURL } from "../../../../libraries";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";

export enum ProfileAboutMode {
  Full = 1,
  Short = 2,
  ShortHidden = 3
}

@Component({
  selector: 'app-profile-user-about',
  templateUrl: 'profile-user-about.component.html',
  styleUrls: ['profile-user-about.component.scss']
})
export class ProfileUserAboutComponent implements OnChanges {
  @Input() data: any;
  @Input() fields: any;
  @Input() mode = ProfileAboutMode.Full;

  @Output() saveProfileEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() resendEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(ContactInfoEditComponent)
  public contactInfoComponent: ContactInfoEditComponent;

  public aboutText: any;
  public resent = false;
  public uploadFiles = [];
  public photoAccept = this.placeService.photoAccept;
  public typeMedia = typeMedia;
  public userImage: string = DEFAULT_USER_IMAGE;
  public isContactInfoEnabled = false;

  protected readonly ProfileAboutMode = ProfileAboutMode;

  private oldUserImage: string;
  private timestamp: number;

  constructor(
    private placeService: PlaceService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      const data = changes['data'].currentValue;

      const isShowContactInfo = data.settings.find(s => s.id === UserSettingsNames.IsShowContactInfo);
      this.isContactInfoEnabled = this.mode === ProfileAboutMode.Short || !!isShowContactInfo.value;

      //check userImage
      if (this.oldUserImage && data?.picture?.thumb) {
        this.userImage = constructMediaURL(data?.picture?.thumb);
      } else if (data?.picture?.large) {
        this.userImage = constructMediaURL(data?.picture?.large);
      } else {
        this.userImage = DEFAULT_USER_IMAGE;
      }
      this.oldUserImage = this.userImage;

      if (data.about) {
        this.aboutText = data.about;
      }
      this.timestamp = Date.now();
    }
  }

  public uploadNewImage(file: any): void {
    this.uploadFiles = [];
    this.saveProfileEmitter.emit({key: 'picture', value: file});
  }

  public saveChanges(data: any, key: string): void {
    if (this.data[key] !== data) {
      this.saveProfileEmitter.emit({key, value: data});
    } else {
      this.toggle.emit({key, value: false});
    }
  }

  public saveEmail(value: string): void {
    if (this.data.email !== value) {
      this.changeEmail.emit({email: value});
    } else {
      this.toggle.emit({key: 'email', value: false});
    }
  }

  public resendMail(): void {
    this.resent = true;
    this.resendEmail.emit();
  }

  public toggleEditor(key: string, value: boolean): void {
    this.toggle.emit({key, value});
  }

  public toggleContactInfoVisibility(): void {
    this.isContactInfoEnabled = !this.isContactInfoEnabled;
    this.changeSettings.emit({showContactInfo: Number(this.isContactInfoEnabled)});
    if (this.mode === ProfileAboutMode.ShortHidden) {
      this.saveChanges({showContactInfo: Number(this.isContactInfoEnabled)}, 'settings');
    }
  }

  protected getUserLink(): string {
    const url = getLinkByObject(this.data);
    return url.indexOf('http') === -1 ? environment.baseUrl + url : url;
  }

  protected getUserLinkWithHash(): string {
    return getLinkByObject(this.data) + '?_t=' + this.timestamp;
  }
}
