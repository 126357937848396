import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseHeaderComponent } from '../shared/base-header/base-header.component';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { MetaTagsService } from '../../services/meta-tags';
import { SetCityTo } from '../../store/layer';
import { SsrService } from '../../services';
import { StaticService } from "../../services/static.service";

@Component({
  selector: 'app-copyright-infringement',
  templateUrl: './copyright-infringement.component.html',
  styleUrls: ['./copyright-infringement.component.scss'],
})
export class CopyrightInfringementComponent extends BaseHeaderComponent {

  constructor(
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    protected ssrService: SsrService,
    private metaTagService: MetaTagsService,
    public staticService: StaticService,
  ) {
    super(store$, cdRef, ssrService);
    this.createMetaTag();
    this.store$.dispatch(new SetCityTo(null));
  }

  createMetaTag() {
    this.metaTagService.resolver({
      route: 'policy-pages',
      name: 'Copyright Infringement Disclaimer',
      description: ''
    });
  }
}
