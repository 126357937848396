import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { searchData, UserService } from '../../../services/user-token.service';
import { backgroundDark } from '../../../animations/background-dark';
import { MonthService } from '../../../services/month.service';
import dayjs from 'dayjs';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { Store } from '@ngrx/store';
import { RemoveCity } from '../../../store/user-cities/user-cities.actions';
import { State } from '../../../store/reducers';
import { constructMediaURL } from '../../../libraries/form-img-src';
import { SetCityTo } from '../../../store/layer';
import { MapDateType, PlacePinType } from '../../../interfaces';
import { DestinationType } from '../../../enums/destination-type';
import { getPinCityValue, getPinObjectValue } from '../../../libraries/pin-object-helper';
import { FlyCookieUpdateDataService, SsrService } from '../../../services';
import { ChangeJourneyInterface, Journey } from "../../../interfaces/journey";
import { UserCollectionService } from "../../../services/user-collection.service";
import { UserLikesActions } from "../../../store/user-likes/user-likes.actions";
import { MovementPopupService } from "../../../services/movement-popup.service";
import { TogglePinnedPanel } from "../../../store/panels/panels.actions";
import { StaticService } from "../../../services/static.service";
import { getCountryFlag } from "../../../libraries/get-coutnry-flag";
import { PinnedModeIdx } from "../../../enums/pinned-mode.enum";

@Component({
  selector: 'app-pinned-panel-item',
  templateUrl: 'pinned-panel-item.component.html',
  styleUrls: ['pinned-panel-item.component.scss'],
  animations: [backgroundDark],
  providers: [FlyCookieUpdateDataService]
})

export class PinnedPanelItemComponent implements OnChanges {
  // TODO:code duplicates, implode in one service
  @Input() currentPlace: any;
  @Input() displayPrice = true;
  @Input() month: any;
  @Input() pinnedPlacesPanel = true;
  @Input() popupPinData;
  @Input() collections: Journey[];
  @Input() disablePopup: boolean;
  @Input() isBottomSheet: boolean;
  @Input() isFavorites: boolean;
  @Input() action: string;
  @Input() isProfile: boolean;

  @Output() emitOpenBottomSheet = new EventEmitter<any>();
  @Output() changeMode = new EventEmitter<any>();
  @Output() deletePinned = new EventEmitter<any>();
  @Output() openPlaceEmitter = new EventEmitter<any>();
  @Output() openPlaceInfoInTab = new EventEmitter<any>();
  @Output() pinRequestEmitter = new EventEmitter<any>();
  @Output() removeFromPinned = new EventEmitter<any>();
  @Output() emitCreateCollection = new EventEmitter<ChangeJourneyInterface>();
  @Output() emitChangeCollection = new EventEmitter<any>();

  @ViewChild('pinnedPanelContainer') pinnedPanelContainer: ElementRef;

  public countryName: string = null;
  public dateTile: string;
  public headPictureSrc: string = null;
  public isAlreadyBeen = false;
  public minPrice = '';
  public monthName: string;
  public placeName: string = null;
  public tooltipFullName: string;
  public isOpenPopup = false;
  public isShowPopupSelect = false;
  public isAddToCollection: boolean;
  public classNameBySide: string;
  public countryData: {
    flag: string,
    info: string
  };

  constructor(
    private userService: UserService,
    private monthService: MonthService,
    private store$: Store<State>,
    private userCollectionService: UserCollectionService,
    private cookieUpdateDataService: FlyCookieUpdateDataService,
    private movementPopupService: MovementPopupService,
    protected staticService: StaticService,
    protected ssrService: SsrService,
  ) {
    cookieUpdateDataService.cookieName = searchData;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isBottomSheet && changes['action'] && changes['action'].currentValue) {
      this.emitAction(changes['action'].currentValue);
    }

    if (!this.currentPlace) {
      return;
    }

    if (this.currentPlace
      && (
        this.currentPlace.place
        || this.currentPlace.city
        || this.currentPlace.country
        || this.currentPlace.collectionPinned
      )) {
      this.headPictureSrc = this.preparePictureUrl(this.currentPlace.oid, this.currentPlace.otype);
      this.countryName = getPinCityValue(this.currentPlace, 'country.name');
      this.placeName = getPinObjectValue(this.currentPlace, 'name');
      this.monthName = this.getMonthName() || (this.month && this.month.name) ? this.month.name : '';
      this.minPrice = this.formatPrice();

      if (this.currentPlace.country) {
        this.placeName = this.countryName + ', Country';
      }
      if (this.currentPlace.otype === DestinationType.Journey && !this.placeName) {
        this.placeName = 'Unnamed Collection';
      }
      this.getCountryData();

      if (get(this.currentPlace, 'dateFrom')) {
        const dateTitle = [];
        dateTitle.push(dayjs(this.currentPlace.dateFrom));
        if (this.currentPlace.dateTo) {
          dateTitle.push(dayjs(this.currentPlace.dateTo));
        }
        this.dateTile =
          dateTitle.length === 1 && this.currentPlace.dateType !== MapDateType.Date
            ? dateTitle[0].format('MMM. YYYY')
            : dateTitle.map(date => date.format('DD MMM')).join('-');
      } else {
        this.dateTile = dayjs()
          .startOf('month')
          .add(1, 'month')
          .format('MMM. YYYY');
      }

      this.tooltipFullName = `<span style="font-size:14px; font-weight: 500">${this.placeName}</span>`;
      if (this.countryName) {
        this.tooltipFullName += `<strong style = "font-size:12px" >${this.countryName}</strong>`;
      }

      if (this.userService.loggedIn()) {
        this.isAlreadyBeen = this.currentPlace.type === PlacePinType.AlreadyBeen;
      } else {
        this.isAlreadyBeen = this.currentPlace?._pinTypes?.indexOf(PlacePinType.AlreadyBeen) > -1;
      }
    }
  }

  doSelectDate(event$) {
    event$.preventDefault();
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
    this.routeToPlace();
  }

  getCountryData() {
    let placeInfo = '';
    switch (this.currentPlace.otype) {
      case DestinationType.City:
        if (this.currentPlace.city) {
          placeInfo = this.currentPlace.city?.name;
          placeInfo += ', ';
        }
        placeInfo += (this.currentPlace.city && this.currentPlace.city.country)
          ? (this.currentPlace.city.country?.shortName || this.currentPlace.city.country?.name)
          : '';

        this.countryData = {
          flag: this.currentPlace?.city?.country?.id,
          info: placeInfo
        };
        break;
      case DestinationType.Place:
        if (this.currentPlace.place?.city) {
          placeInfo = this.currentPlace.place?.city?.name || this.currentPlace.place.cityName;
          placeInfo += ', ';
        }
        placeInfo += (this.currentPlace.place?.city && this.currentPlace.place.city.country)
          ? (this.currentPlace.place.city.country?.shortName || this.currentPlace.place.city.country?.name)
          : this.currentPlace.place?.country && this.currentPlace.place.country.name;

        this.countryData = {
          flag: this.currentPlace.place?.city?.country?.id || this.currentPlace.place?.country?.id,
          info: placeInfo
        };
        break;
      case DestinationType.Country:
        if (this.currentPlace.country?.name) {
          placeInfo = this.currentPlace.country.name;
        }

        this.countryData = {
          flag: this.currentPlace.country?.id,
          info: placeInfo
        };
        break;
    }
  }

  preparePictureUrl(id, otype) {
    let url: string;
    const idPrefix = id && id.toString().substr(-2);
    let matches;
    switch (otype) {
      case DestinationType.Place:
        if (this.currentPlace.place?.video?.length && this.currentPlace.place.video[0]?.poster?.thumb) {
          url = constructMediaURL(this.currentPlace.place.video[0].poster.thumb);
        } else if (this.currentPlace.place?.pictures?.length && this.currentPlace.place.pictures[0].thumb) {
          url = constructMediaURL(this.currentPlace.place.pictures[0].thumb);
        } else {
          url = constructMediaURL(`/images/f/article/${idPrefix}/${id}_pictures0_1.jpg`);
        }
        break;
      case DestinationType.Restaurant:
        if (this.currentPlace.restaurant.pictures.length && this.currentPlace.restaurant.pictures[0].thumb
          && (matches = /_featured(\d+)_/.exec(this.currentPlace.restaurant.pictures[0].thumb))
        ) {
          url = constructMediaURL(`/images/f/restaurant/${idPrefix}/${id}_featured${matches[1]}_1.jpg`);
        } else {
          url = constructMediaURL(`/images/f/restaurant/${idPrefix}/${id}_featured0_1.jpg`);
        }
        break;
      case DestinationType.City:
        switch (true) {
          case !!(this.currentPlace.city?.pictures?.head && this.currentPlace.city.pictures.head.thumb):
            url = constructMediaURL(this.currentPlace.city.pictures.head.thumb);
            break;
          case !!(this.currentPlace.city?.pictures?.other?.length && this.currentPlace.city.pictures.other[0].thumb):
            url = constructMediaURL(this.currentPlace.city.pictures.other[0].thumb);
            break;
          case !!(this.currentPlace.city?.topPlace?.video?.length && this.currentPlace.city.topPlace.video[0].poster?.thumb):
            url = constructMediaURL(this.currentPlace.city.topPlace.video[0].poster.thumb);
            break;
          case !!(this.currentPlace.city?.topPlace?.pictures?.length && this.currentPlace.city.topPlace.pictures[0].thumb):
            url = constructMediaURL(this.currentPlace.city.topPlace.pictures[0].thumb);
            break;
          //unauthorized mode
          case !!(
            this.currentPlace.city?.places?.length
            && this.currentPlace.city?.places[0].video?.length
            && this.currentPlace.city.places[0].video[0].poster?.thumb
          ):
            url = constructMediaURL(this.currentPlace.city.places[0].video[0].poster.thumb);
            break;
          //unauthorized mode
          case !!(
            this.currentPlace.city?.places?.length
            && this.currentPlace.city?.places[0].pictures?.length
            && this.currentPlace.city.places[0].pictures[0].thumb
          ):
            url = constructMediaURL(this.currentPlace.city.places[0].pictures[0].thumb);
            break;
          default:
            url = constructMediaURL(`/images/f/city/${idPrefix}/${id}_frontPicture_1.jpg`);
            break;
        }
        break;
      case DestinationType.Country:
        if (this.currentPlace?.country?.pictures?.head?.thumb) {
          url = constructMediaURL(this.currentPlace.country.pictures.head.thumb);
        } else {
          url = constructMediaURL(`/images/f/country/${idPrefix}/${id}_frontPicture_1.jpg`);
        }
        break;
      case DestinationType.Journey:
        if (
          this.currentPlace.collectionPinned.places[0]?.place?.video?.length
          && this.currentPlace.collectionPinned.places[0].place?.video[0].poster?.thumb
        ) {
          url = constructMediaURL(this.currentPlace.collectionPinned.places[0].place?.video[0].poster.thumb);
        } else if (
          this.currentPlace.collectionPinned.places[0]?.place?.pictures?.length
          && this.currentPlace.collectionPinned.places[0].place?.pictures[0].thumb
        ) {
          url = constructMediaURL(this.currentPlace.collectionPinned.places[0].place?.pictures[0].thumb);
        } else {
          url = constructMediaURL(`/images/f/article/${idPrefix}/${id}_pictures0_1.jpg`);
        }
        break;
    }
    return url;
  }

  unpinPlace() {
    this.store$.dispatch(
      new RemoveCity({
        oid: this.currentPlace.oid,
        otype: this.currentPlace.otype,
        type: PlacePinType.WannaGo,
        collectionId: this.currentPlace.collectionId,
      })
    );
    this.deletePinned.emit(this.currentPlace);
  }

  unLikePlace() {
    this.store$.dispatch(new UserLikesActions.RemoveLike({oid: this.currentPlace.oid, otype: this.currentPlace.otype}));
  }

  openPlace(place) {
    this.openPlaceEmitter.emit(place);
  }

  public routeToPlace() {
    let cityTo;
    switch (this.currentPlace.otype) {
      case DestinationType.City:
        cityTo = {
          id: this.currentPlace.oid,
          name: this.currentPlace.city.name,
          coordinates: `${this.currentPlace.city.lat},${this.currentPlace.city.lng}`,
          type: this.currentPlace.otype,
          countryCode: this.currentPlace.city.country.id
        };

        this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
        this.store$.dispatch(new SetCityTo(cityTo));
        this.changeMode.emit(PinnedModeIdx.Cities);
        this.ssrService.goToRoute(`/city/${this.currentPlace.city.urlKeywords}/${this.currentPlace.oid}`);
        break;
      case DestinationType.Place:
        cityTo = {
          id: this.currentPlace.oid,
          name: this.currentPlace.place.name,
          coordinates: `${this.currentPlace.place.lat},${this.currentPlace.place.lng}`,
          type: this.currentPlace.otype,
          countryCode: this.currentPlace.place.city.country.id || this.currentPlace.place.country.id
        };
        this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
        this.store$.dispatch(new SetCityTo(cityTo));
        this.changeMode.emit(PinnedModeIdx.Places);
        this.ssrService.goToRoute(`/places/${this.currentPlace.place.urlKeywords}/${this.currentPlace.oid}`);
        break;
      case DestinationType.Country:
        this.changeMode.emit(PinnedModeIdx.Countries);
        this.ssrService.goToRoute(`/country/${this.currentPlace.country.urlKeywords}/${toLower(this.currentPlace.oid)}`);
        break;
      case DestinationType.Journey:
        this.changeMode.emit(PinnedModeIdx.Journeys);
        this.ssrService.goToRoute(`/journey/${this.currentPlace.oid}`);
        break;
      default:
        break;
    }
    this.userCollectionService.isOpenPinPanelFromBottomSheet$.next(false);
  }

  private getMonthName() {
    const flyDate = getPinCityValue(this.currentPlace, 'flights.departDate');
    return !flyDate
      ? ''
      : this.monthService.getMonthByNumber(
        Number.parseInt(dayjs(flyDate).format('M'))
      ).name;
  }

  private formatPrice() {
    const price = getPinCityValue(this.currentPlace, 'flights.minPrice');
    return !price ? '' : `$${price}`;
  }

  public emitAction(funcName) {
    this[funcName]();
  }

  public toggleEditTitlePopup() {
    if (!this.disablePopup) {
      const pinnedPanelBounding = this.pinnedPanelContainer.nativeElement.getBoundingClientRect();
      this.classNameBySide = this.movementPopupService.checkMovementPopup(pinnedPanelBounding);
      if (this.isBottomSheet) {
        this.userCollectionService.bottomSheetActions = {
          data: {
            itemId: this.currentPlace.oid,
            action: 'toggleEditTitlePopup'
          }
        };
        this.emitOpenBottomSheet.emit();
      } else {
        this.toggleOpenPopup();
      }
    }
    if (this.disablePopup) {
      if (this.isFavorites) {
        this.unLikePlace()
      } else {
        this.unpinPlace();
      }
    }
  }

  public toggleOpenPopup() {
    this.isOpenPopup = !this.isOpenPopup;
  }

  public toggleChangeCollection(event?: boolean) {
    if (event === undefined) {
      this.isShowPopupSelect = !this.isShowPopupSelect;
    } else {
      this.isShowPopupSelect = event;
    }
  }

  public toggleAddToCollection() {
    this.isAddToCollection = true;
    this.toggleChangeCollection();
  }

  public createCollection(event: ChangeJourneyInterface) {
    this.emitCreateCollection.emit(event);
  }

  public changeCollection(event) {
    this.emitChangeCollection.emit(event);
  }

  protected readonly getCountryFlag = getCountryFlag;
}
