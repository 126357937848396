import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import { getCollectionList, getFavoritesList, getPinnedList, State } from "../store/reducers";
import { map, takeUntil } from "rxjs/operators";
import {
  AddCollections,
  AddPin,
  SetCollectionsState,
  UserCollectionsActions
} from "../store/user-collections/user-collections.actions";
import { AddCity, UserCityActions } from "../store/user-cities/user-cities.actions";
import { isPlatformBrowser } from "@angular/common";
import { ChangeJourneyInterface, Journey } from "../interfaces/journey";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { FAVORITE_ID, FAVORITE_TITLE, LikeInterface } from "../interfaces/like";
import { SynchronizeLikes, UserLikesActions } from "../store/user-likes/user-likes.actions";
import { UserService } from "./user-token.service";
import { DestroyComponent } from "../heplers/destroy.component";
import { PlacePinType } from "../interfaces";
import { SsrService } from "./ssr.service";
import {
  DEFAULT_JOURNEY_ID,
  DEFAULT_TITLE_JOURNEY,
  LAST_ADD_FAVORITES,
  NGX_USER_COLLECTIONS,
  NGX_USER_LIKES
} from "../../constants";

export interface BottomSheetActionsInterface {
  data: { itemId?: number, action?: string }
}

@Injectable({
  providedIn: 'root'
})
export class UserCollectionService extends DestroyComponent {

  isErrorCollection$ = new BehaviorSubject<boolean>(false);
  isOpenPinPanelFromBottomSheet$ = new BehaviorSubject<boolean>(false);
  isShowBottomSheet$ = new BehaviorSubject<boolean>(false);
  isPinnedAnimation$ = new Subject<void>();

  public bottomSheetActions: BottomSheetActionsInterface;
  public isBrowser: boolean;
  public noAuthCollections: Journey[];
  public noAuthLikes: LikeInterface;
  public isLogin: boolean;
  public showConfirmWindow$ = new Subject<boolean>();

  constructor(
    private store$: Store<State>,
    private userService: UserService,
    private ssrService: SsrService,
    @Inject(PLATFORM_ID) platformId: Object,
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public setShowConfirmWindow(value) {
    this.showConfirmWindow$.next(value);
  }

  public checkCitiesInCookies() {
    if (this.userService.loggedIn()) {
      this.checkConfirmWindow().subscribe();
    }
  }

  public checkConfirmWindow(): Observable<boolean> {
    return combineLatest([
      this.store$.select(getCollectionList),
      this.store$.select(getFavoritesList)
    ]).pipe(
      takeUntil(this.destroy$),
      map(([collections, favorites]) => {
        const itemsCollections = collections.filter(city => !city._sync);
        const itemsFavorites = favorites && !favorites?._sync && (favorites?.places?.length || favorites?.cities?.length);
        const showConfirmWindow = itemsCollections.length > 0 || !!itemsFavorites;
        this.setShowConfirmWindow(showConfirmWindow);
        return showConfirmWindow;
      })
    );
  }

  public confirmCitiesSave($event): Observable<boolean> {
    if (this.isBrowser) {
      if ($event) {
        const ngxUserCollections = JSON.parse(localStorage.getItem(NGX_USER_COLLECTIONS));
        if (ngxUserCollections) {
          this.store$.dispatch(new AddCollections(ngxUserCollections, true));
          localStorage.removeItem(NGX_USER_COLLECTIONS);
        } else {
          this.store$.dispatch(new UserCollectionsActions.SynchronizeCollection());
        }
        const ngxUserLikes = JSON.parse(localStorage.getItem(NGX_USER_LIKES));
        if (ngxUserLikes && !ngxUserLikes?._sync && (ngxUserLikes?.places?.length || ngxUserLikes?.cities?.length)) {
          localStorage.removeItem(NGX_USER_LIKES);
          this.store$.dispatch(new SynchronizeLikes(ngxUserLikes));
        }
      } else {
        if (this.noAuthCollections && this.isLogin) {
          this.store$.dispatch(new SetCollectionsState({collections: this.noAuthCollections}, false, this.userService.loggedIn()));
          this.isLogin = false;
        }
        this.store$.dispatch(new UserCollectionsActions.ResetCollections);
        this.store$.dispatch(new UserCityActions.ResetState());
        this.store$.dispatch(new UserLikesActions.ResetLike());
      }
    }
    this.setShowConfirmWindow(false);
    return this.showConfirmWindow$;
  }

  public getFilteredCollections(): Observable<any> {
    if (this.userService.loggedIn()) {
      return combineLatest([
        this.store$.pipe(select(getCollectionList)),
        this.store$.pipe(select(getFavoritesList))
      ]).pipe(
        takeUntil(this.destroy$),
        map(([collections, favorites]) => {
          favorites = UserCollectionService.getFavoritesData(favorites);
          if (!collections.some(x => x?.isFavorite)) {
            collections = [favorites, ...collections];
          }
          return collections
        }));
    } else {
      return combineLatest([
        this.store$.pipe(select(getPinnedList)),
        this.store$.pipe(select(getCollectionList)),
        this.store$.pipe(select(getFavoritesList))
      ]).pipe(
        takeUntil(this.destroy$),
        map(([pins, collections, favorites]) => {
          if (this.ssrService.isBrowser()) {
            let collectionLocalstorage = JSON.parse(localStorage.getItem(NGX_USER_COLLECTIONS));
            favorites = UserCollectionService.getFavoritesData(favorites);
            let pinsArr: any[];
            if (pins.length) {
              if (collectionLocalstorage) {
                collectionLocalstorage = Array.isArray(collectionLocalstorage) ? collectionLocalstorage : [collectionLocalstorage];
              }
              const collectionName = collectionLocalstorage?.length ? collectionLocalstorage[0].name : DEFAULT_TITLE_JOURNEY;
              const collectionDescription = collectionLocalstorage?.length ? collectionLocalstorage[0].description : '';
              pinsArr = UserCollectionService.getCollectionStructureByName(collectionName, collectionDescription);
              if (pins.length) {
                pinsArr[0].places = pins;
                if (this.ssrService.isBrowser()) {
                  localStorage.setItem(NGX_USER_COLLECTIONS, JSON.stringify(pinsArr));
                }
              }
            } else {
              pinsArr = [...collections];
            }

            return [favorites, ...pinsArr];
          }
          return [favorites];
        }));
    }
  }

  static getFavoritesData(favorites) {
    favorites = JSON.parse(JSON.stringify(favorites));
    favorites.id = FAVORITE_ID;
    favorites.name = FAVORITE_TITLE;
    favorites.isFavorite = true;
    return favorites;
  }

  static getCollectionStructureByName(name: string = DEFAULT_TITLE_JOURNEY, description: string = '') {
    let collection = {
      id: DEFAULT_JOURNEY_ID,
      name: name,
      description: description,
      cities: [],
      places: [],
      countries: [],
      collections: [],
      isDefault: true,
    };
    return [collection];
  }

  checkLastAdd(item) {
    if (this.isBrowser && item !== undefined) {
      localStorage.setItem(LAST_ADD_FAVORITES, JSON.stringify(!!item));
    }
  }

  public changeJourney(data: ChangeJourneyInterface, isProfilePage?: boolean) {
    if (data?.selectedCollection) {
      const dataAddCity = {
        oid: data.currentPlace.oid,
        otype: data.currentPlace.otype,
        type: PlacePinType.WannaGo,
        collectionId: data.selectedCollection.id,
        isUpdate: !data.isAddToCollection,
        place: data?.currentPlace?.place,
        isProfilePage: isProfilePage,
        oldCollectionId: data.defaultCollection.id
      };
      this.store$.dispatch(new AddCity(dataAddCity));

      if (data.isAddToCollection) {
        this.store$.dispatch(new AddPin(data.currentPlace, data.selectedCollection.id));
      } else if (data.defaultCollection) {
        const dataChangePinCollection = {
          pin: data.currentPlace,
          newCollectionId: data.selectedCollection.id,
          oldCollectionId: data.defaultCollection.id
        };
        this.store$.dispatch(new UserCollectionsActions.ChangePinCollection(dataChangePinCollection));
      }
    }
  }
}
