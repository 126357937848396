<div class="home-selector">
  <div class="body home-selector">
    <div class="user-profile-autocomplete">
      <app-form-input-auto-complete [autoFocus]="true" [filterFn]="findCityFn()"
                                    (selectEmitter)="selectHomeCity($event)">
        <ng-template li-item let-item>
          <div>{{item.name}}
            <small>{{item?.country?.name}}</small>
          </div>
        </ng-template>
      </app-form-input-auto-complete>
    </div>

    <div class="user-profile-actions">
      <button class="cancel" (click)="close()">
        <span>Cancel</span>
      </button>

      <button
        [disabled]="!nextHomeCity || !nextHomeCity.id"
        class="save"
        (click)="submitHomeCity()">
        Select as home
      </button>
    </div>
  </div>
</div>
