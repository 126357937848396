import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CountryService, SsrService } from '../services';
import { Observable } from 'rxjs';
import { RouteNamesEnum } from '../app-routing.interface';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard  {

  constructor(
    private router: Router,
    private countryService: CountryService,
    private ssrService: SsrService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    switch (route.data.name) {
      case RouteNamesEnum.Country:
        let country = this.countryService.findCountryByName(route.paramMap.get('name'));
        if (country !== undefined) {
          let countryName = (country.sn !== undefined && country.sn ? country.sn : country.name).toLowerCase().replace(/ /g, '_');
          this.ssrService.redirect(301, `/country/${countryName}/${country.id.toLowerCase()}`);
        } else {
            this.router.navigate(['/404']);
        }
        break;
      default:
        break;
    }

    return false;
  }
}
