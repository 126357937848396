<ng-container *ngTemplateOutlet="pageWithStaticContent"></ng-container>

<ng-template #pageWithStaticContent>
  <app-static-content>
    <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
  </app-static-content>
</ng-template>

<ng-template #pageContainer>
  <ng-container *ngIf="placeSize">
    <div class="country__container">
      <section class="country__header">
        <img class="place__country-icon" [src]="'assets/flag-24/' + (place?.id || place?.country?.id) + '.png'" alt=""/>
        <h1 class="info-name">{{ place?.name }}</h1>
      </section>

      <section class="country__info">
        <div class="country__city-country">
          <div>
            <div class="country-currency">
              <p>Currency:</p>
              <span>{{ place?.currencySymbol }} {{ place?.currency }}</span>
            </div>
            <div class="country-item" *ngIf="place?.capitals[0]?.city?.name">
        <span>
          {{ place?.capitals.length === 1 ? "Capital:" : "Capitals:" }}
          <div class="capitals-item" *ngFor="let capital of place?.capitals; let i = index">
            <span *ngIf="i > 0">, </span>
            <a *ngIf="capital?.city?.hasPage"
               [routerLink]="'/city/' + capital?.city?.urlKeywords + '/' + capital?.city?.id">
              {{ capital?.city?.name }}
            </a>
            <span class="city-not-link-name" *ngIf="!capital?.city?.hasPage">{{
              capital?.city?.name
              }}</span>
            <span *ngIf="capital?.type">({{ capital?.type }})</span>
          </div>
        </span>
            </div>
            <div class="country-population">
              Area:<span>{{ place?.area?.total | formatNumbers: "," }} km<sup>2</sup></span>
            </div>
            <div class="country-languages-content">
              <div class="country-languages" *ngIf="place?.languages && place?.languages.length > 0">
                Languages:
                <span class="country-languages-item">
            <span *ngFor="let language of place?.languages; let i = index">
              <span *ngIf="i > 0">,</span> {{ language?.name
              }}<span *ngIf="language?.note"> ({{ language?.note }})</span>
            </span>
          </span>
              </div>

              <div class="fly-tooltip" *ngIf="place?.languages.length > 5">
                <label i18n="labels.currency">Languages</label>:
                <span *ngFor="let language of place?.languages; let i = index">
            <span *ngIf="i > 0">,</span> {{ language?.name
                  }}<span *ngIf="language?.note"> ({{ language?.note }})</span>
          </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="country__info">
        <div *ngIf="place?.citiesByPlaces?.length">
          <app-static-categories
            [categories]="place.citiesByPlaces"
            [place]="place"
            [mode]="categoryType.City"
            [staticType]="staticType"
            [className]="'cities'"
            [pageType]="staticRouteType.Country"
          ></app-static-categories>
        </div>

        <div *ngIf="place?.tags.length">
          <app-static-categories
            [categories]="place.tags"
            [place]="place"
            [mode]="categoryType.Categories"
            [staticType]="staticType"
            [className]="'tags'"
            [pageType]="staticRouteType.Country"
          ></app-static-categories>
        </div>
      </section>
    </div>


    <app-static-gallery
      *ngIf="isGalleryOpen"
      [windowWidth]="resizeWindow"
      [gallery]="place.citiesPictures"
      [activeIndex]="activeIndex"
      [place]="place"
      (closeGallery)="openImage($event)"
    ></app-static-gallery>
  </ng-container>
</ng-template>
