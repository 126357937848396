import { Action } from '@ngrx/store';

export enum MapActionsTypes {
  PanTo = '[Map] Pan to',
  SetZoom = '[Map] Set zoom',
  SetMarkers = '[Map] Set markers',
  SetNewBounds = '[Map] Set new bounds',
  SetVisibleCountries = '[Map] Set visible countries',
  SetInfoWindows = '[Map] Set info windows',
  AddUserInfoWindows = '[Map] Add user\'s info windows',
  RemoveUserInfoWindows = '[Map] Remove user\'s info windows',
  ExcludeInfoWindows = '[Map] Add user\'s info windows to exclude',
  SetInfoWindowVisible = '[Map] Set visible of info windows'
}

export class SetInfoWindowVisible implements Action {
  readonly type = MapActionsTypes.SetInfoWindowVisible;

  constructor(public payload: boolean) {
  }
}

export class AddToExcludeCity implements Action {
  readonly type = MapActionsTypes.ExcludeInfoWindows;

  constructor(public payload: { id: number[] }) {
  }
}

export class RemoveUserInfoWindows implements Action {
  readonly type = MapActionsTypes.RemoveUserInfoWindows;

  constructor(public payload: { id: number }) {
  }
}

export class AddUserInfoWindows implements Action {
  readonly type = MapActionsTypes.AddUserInfoWindows;

  constructor(public payload: any) {
  }
}

export class SetInfoWindows implements Action {
  readonly type = MapActionsTypes.SetInfoWindows;

  constructor(public payload: any) {
  }
}

export class SetMarkers implements Action {
  readonly type = MapActionsTypes.SetMarkers;

  constructor(public payload: { markers: any[] }) {
  }
}

export class SetZoom implements Action {
  readonly type = MapActionsTypes.SetZoom;

  constructor(public payload: { zoom: number }) {
  }
}

export class MapPanTo implements Action {
  readonly type = MapActionsTypes.PanTo;

  constructor(public payload: { lat: number, lng: number, zoom: number }) {
  }
}

export class SetNewBounds implements Action {
  readonly type = MapActionsTypes.SetNewBounds;

  constructor(public payload: google.maps.LatLngBounds) {
  }
}

export class SetVisibleCountries implements Action {
  readonly type = MapActionsTypes.SetVisibleCountries;

  constructor(public payload: string[]) {
  }
}

export type MapActionsUnion =
  SetZoom
  | SetMarkers
  | SetInfoWindows
  | AddUserInfoWindows
  | RemoveUserInfoWindows
  | AddToExcludeCity
  | SetInfoWindowVisible
  | SetNewBounds
  | SetVisibleCountries
  | MapPanTo;
