import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { DestroyComponent } from "../../../heplers/destroy.component";
import { Journey } from "../../../interfaces/journey";
import { UserCollectionService } from "../../../services/user-collection.service";
import { getFavoritesList } from "../../../store/reducers";
import { TogglePinnedPanel } from "../../../store/panels/panels.actions";
import { PinnedModeIdx } from "../../../enums/pinned-mode.enum";

@Component({
  selector: 'app-favorites-panel',
  templateUrl: './favorites-panel.component.html',
  styleUrls: ['./favorites-panel.component.scss']
})
export class FavoritesPanelComponent extends DestroyComponent implements OnInit {

  @Input() isProfile: boolean;
  @Output() closePinnedPanelEmit = new EventEmitter<any>();

  public defaultTab = PinnedModeIdx.Places;
  public favorites: Journey;

  constructor(
    protected store$: Store,
    protected userCollectionService: UserCollectionService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store$.pipe(
      takeUntil(this.destroy$),
      select(getFavoritesList)
    ).subscribe(favorites => {
      this.favorites = UserCollectionService.getFavoritesData(favorites);
      this.userCollectionService.checkLastAdd(this.favorites?.isFavorite);
    });
  }

  public close(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
  }
}
