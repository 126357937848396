import { Component, Input, OnInit } from '@angular/core';
import { WindowRef } from '../../../../services/window.service';
import { UserService } from '../../../../services/user-token.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-social-net-btn',
  templateUrl: 'social-net-btn.component.html',
  styleUrls: [
    '../../../../modules/user-account/components/link-social-account/link-social-account.component.scss',
    'social-net-btn.component.scss'
  ]
})
export class SocialNetBtnComponent implements OnInit {

  public facebookLabel: string;
  public googleLabel: string;
  public labels: any = {
    google: ['buttons.continue.google', 'buttons.link.google'],
    facebook: ['buttons.continue.facebook', 'buttons.link.facebook']
  };
  public socialFB: boolean;
  public socialGoogle: boolean;
  public window: any;

  @Input() auth: any;

  constructor(
    private userService: UserService,
    private windowRef: WindowRef,
  ) {
    this.window = windowRef.getNativeWindow();
  }

  ngOnInit() {
    this.socialGoogle = this.findAuthSocial('google');
    this.socialFB = this.findAuthSocial('facebook');
    let index = 1;
    if (this.auth[0]) {
      index = 0;
    }
    // i18n
    this.saveTranslationIntoProperty(this.labels['google'][index], 'googleLabel');
    this.saveTranslationIntoProperty(this.labels['facebook'][index], 'facebookLabel');
  }

  signInWithGoogle($event): void {
    $event.preventDefault();
    let google = this.getSocial('google');
    if (this.window) {
      this.setPrevUrl();
      this.window.open(google.url, '_self');
    }
  }

  signInWithFB($event): void {
    $event.preventDefault();
    let facebook = this.getSocial('facebook');
    if (this.window) {
      this.setPrevUrl();
      this.window.open(facebook.url, '_self');
    }
  }

  getSocial(social: any) {
    return this.auth[social] || this.auth.find((param) => {
      return param.name == social;
    });
  }

  findAuthSocial(social) {
    if (this.auth[0]) {
      return true;
    }

    return this.auth[social]
      ? (this.auth[social].status == 0)
      : this.auth.some((s) => {
        return s.status == 0 && s.name == social;
      });
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    // i18n
    this.userService.getTranslation(id).pipe(take(1)).subscribe(
      (res) => {
        this[whereToSave] = res;
      }
    );
  }

  private setPrevUrl(): void {
    localStorage.setItem('previousUrl', location.href);
  }
}
