import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignUpService } from '../../../../services/sign-up.service';
import { UserService } from '../../../../services/user-token.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { doAsync } from '../../../../libraries/do-async';
import { Observable } from 'rxjs/internal/Observable';
import { select, Store } from '@ngrx/store';
import { getAjaxState, getCurrentCoordinates, State } from '../../../../store/reducers';
import { delay, take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { InfoService } from '../../../../services';
import * as fromAuth from '../../../../store/auth/auth.actions';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../../../../modules/user-account/components/profile-pass-change/profile-pass-change.component.scss',
    './register.component.scss'
  ]
})
export class RegisterComponent {
  public ajaxState$: Observable<any>;
  public allowResend: boolean = true;
  public coordinates;
  public emailFocused;
  public emailResendSuccessfully: any;
  public emailSuccessfullySent: boolean = false;
  public emailUpdated;
  public generalError: any;
  public isLoading: boolean = false;
  public needResendEmail: boolean = false;
  public passFocused;
  public passUpdated;
  public passwordType: string = 'password';
  public registerForm: UntypedFormGroup;
  public resOfEmailValidation: any;
  public resOfPasswordValidation: any;
  public shouldRemember: any = 'checked';

  @Output() closeRegisterFormEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() registrationCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showUserAccount = new EventEmitter();
  @Output() visibleTermsPrivacyPolicy = new EventEmitter();
  @Output() completeEmitter = new EventEmitter<boolean>();

  @Input() auth;
  @Input() loggedIn;
  @Input() month;
  @Input() searchMode;

  constructor(
    private signUpService: SignUpService,
    private userService: UserService,
    protected store$: Store<State>,
    fb: UntypedFormBuilder,
    private infoService: InfoService,
    private route: ActivatedRoute,
  ) {
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
    this.registerForm = fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern())]],
      pass: ['', [Validators.required, Validators.minLength(6)]]
    }, {updateOn: 'blur'});

    this.store$.pipe(
      select(getCurrentCoordinates),
      take(1)
    ).subscribe((coordinates: any) => {
      this.coordinates = (coordinates)
        ? {lat: coordinates.lat, lng: coordinates.lng}
        : undefined;
    });
  }

  emailPattern() {
    return new RegExp('^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$');
  }

  registerWithEmail(registerForm: any) {
    if (registerForm.controls.pass.length < 6) {
      this.resOfPasswordValidation = 'Password should be at least 6 characters';
      return;
    }

    this.emailValidate(registerForm);
    this.passwordValidate(registerForm);

    this.generalError = null;

    if (!registerForm.valid) {
      return;
    }

    this.userService.disableSubmitForWhile(this);
    this.emailSuccessfullySent = null;
    this.isLoading = true;
    this.resOfEmailValidation = null;
    this.resOfPasswordValidation = null;

    let params = {
      email: registerForm.controls.email.value,
      password: registerForm.controls.pass.value,
      lat: this.coordinates.lat,
      lng: this.coordinates.lng,
      urlReferrer: '',
      adsChannel: this.route.snapshot.queryParams.ck ? this.route.snapshot.queryParams.ck : null,
    };

    this.signUpService.send(params).subscribe(
      (data: any) => {
        this.completeEmitter.emit(true);
        this.setRememberMe();
        this.userService.disableLoadingBar(this);
        this.userService.setToken(data);
        this.getUserInfo();
        this.registrationCompleted.emit(true);
      },
      (err) => {
        this.handleErrors(err);
      }
    );

  }

  setRememberMe() {
    this.userService.shouldRemember(this.shouldRemember);
  }

  closeRegisterForm($event) {
    $event.preventDefault();
    this.closeRegisterFormEmitter.emit();
  }

  emailValidate(f) {
    if (f.controls.email.status == 'invalid'.toUpperCase()) {
      // i18n
      let id = 'errors.wrong_email';
      if (f.controls.email.value.length == 0) this.resOfEmailValidation = 'This field is required';
      else this.saveTranslationIntoProperty(id, 'resOfEmailValidation');
    } else {
      this.resOfEmailValidation = null;
    }
  }

  passwordValidate(f) {
    if (f.controls.pass.status == 'invalid'.toUpperCase()) {
      if (f.controls.pass.value.length == 0) this.resOfPasswordValidation = 'This field is required';
      else if (f.controls.pass.value.length < 6) this.resOfPasswordValidation = 'Password should be at least 6 characters';
      else this.resOfPasswordValidation = 'Some thing wrong with your password';
    } else {
      this.resOfPasswordValidation = null;
    }
  }

  valueUpdated(propName) {
    this[`${propName}Updated`] = true;
    if (this.registerForm.controls[propName].value.length > 0) return;
    if (propName == 'email') {
      this.emailFocused = false;
    } else if (propName == 'pass') this.passFocused = false;
  }

  focus(type) {
    if (type == 'email') this.emailFocused = true;
    else if (type == 'pass') this.passFocused = true;
  }

  validationStyles(formCtrl) {
    if (!this.readVal(formCtrl)) return null;
    return {'fly-pass-valid': this.validationStyling(formCtrl), 'fly-pass-invalid': !this.validationStyling(formCtrl)};
  }

  validationStyling(formCtrl) {
    return this.registerForm.controls[formCtrl].valid;
  }

  readVal(formCtrlName) {
    return this.registerForm.controls[formCtrlName].value;
  }

  resendEmail(signInForm) {
    this.emailResendSuccessfully = null;
    this.emailValidate(signInForm);
    this.passwordValidate(signInForm);
    this.generalError = null;
    if (!signInForm.valid) return;
    this.isLoading = true;
    this.allowResend = false;

    this.signUpService
      .resend({email: signInForm.value.email})
      .subscribe(
        () => {
          this.userService.disableLoadingBar(this);
          this.emailResendSuccessfully = 'Your email has resent. Please check your mail box.';
          doAsync(_ => this.allowResend = true, 10000);
        },
        (err) => {
          this.userService.disableLoadingBar(this);
          if (err.status == 201) {
            this.needResendEmail = false;
            // i18n
            let id = 'success.mail_resend';
            this.saveTranslationIntoProperty(id, 'emailResendSuccessfully');
            this.emailResendSuccessfully = 'Your email has resent. Please check your mail box.';

            doAsync(() => {
              this.allowResend = true;
            }, 10000);
            return;
          }

          let message = err.error.message || err.error[0];
          if (err.status == 422) {
            this.caseErrorMessage(err.error[0]);
          } else if (err.status > 300) {
            this.mapResponse(message);
          }
        }
      );
  }


  handleErrors(err) {
    this.userService.disableLoadingBar(this);
    if (err && err.status == 201) {
      this.emailSuccessfullySent = true;
      this.setRememberMe();
      return;
    } else if (err.status == 422) {
      this.caseErrorMessage(err.error[0]);
    } else if (err.status == 403) {
      this.generalError = this.mapResponse(err.error.message);
      this.needResendEmail = true;
    } else if (err.status > 300) {
      let message = err.error.message || err.error[0].message;
      this.generalError = this.mapResponse(message);
    }

    this.emailSuccessfullySent = false;
  }

  caseErrorMessage(error) {
    switch (error.field) {
      case 'email':
        this.resOfEmailValidation = error.message;
        break;
      case 'password':
        this.resOfPasswordValidation = error.message;
        break;
      case 'adsChannel':
      case 'system':
      default:
        this.generalError = this.mapResponse(error);
        break;
    }
  }

  mapResponse(message) {
    return [{
      value: message
    }];
  }

  changePasswordView() {
    this.passwordType = this.passwordType == 'password' ? 'text' : 'password';
  }

  rememberUser() {
    this.shouldRemember = this.shouldRemember ? null : 'checked';
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    // i18n
    this.userService.getTranslation(id).pipe(take(1)).subscribe(
      (res) => {
        this[whereToSave] = res;
      }
    );
  }

  private getUserInfo(): void {
    this.infoService.getClientInfo(new HttpParams().append('expand', 'country,picture,settings,homeCity.country,urlSetting,createdPlacesPics'))
      .subscribe(
        (response) => {

          this.store$.dispatch(
            new fromAuth.AuthActions.RestoreCurrentHome({
              id: response.city.id,
              name: response.city.name,
              country: response.city?.country,
              population: response.city.population,
              popular: response.popular,
              coordinates: `${response.city.lat},${response.city.lng}`,
              user: response.user
            })
          );

          this.emailSuccessfullySent = true;
        }
      );
  }
}
