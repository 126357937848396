import { Injectable } from '@angular/core';
import { FlyAdComponent } from './fly-add.component';
import { FlyAutocompleteComponent } from '../../shared-components/fly-autocomplete/fly-autocomplete.component';

@Injectable()
export class FlyComponentService {

  public _config: ComponentServiceConfig;
  public callback;
  public componentsList = {
    'autocomplete': FlyAutocompleteComponent
  };

  init(callback: Function) {
    this.callback = callback;
  }

  set config(config) {
    this._config = config;
    let name = config.name;
    let data = config.data;
    this.callback(this.select(name, data));
  }

  get config() {
    return this._config;
  }

  select(name, data) {
    return new FlyAdComponent(this.componentsList[name], data);
  }
}

interface ComponentServiceConfig {
  name: string;
  data: any;
}
