import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { decodeParam } from '../libraries/formLink';
import { doAsync } from '../libraries/do-async';

@Injectable()
export class UrlParseService {

  public labelsForRoutes: any = {
    '': [],
    'auth': [],
    'landing': [],
    'places': ['name', 'id', 'review', 'reviewId'],
    'signup': [],
    'email': [],
    'country': ['name'],
    'category': ['name', 'tagId', 'countryToName', 'id'],
    'city': ['name', 'id'],
    'travel': ['name', 'id', 'typeId', 'allPlacesId'],
    'flights': ['marker', 'origin_name', 'origin_iata', 'destination_name', 'destination_iata', 'depart_date', 'return_date', 'with_request', 'adults', 'children', 'infants', 'trip_class', 'locale', 'one_way', 'ct_guests', 'ct_rooms'],
    'site-map': ['site-map'],
    'experiences': ['tagNameEncoded', 'tagId', 'id'],
    'things-to-do': ['name', 'id'],
    'list-cities': [],
    'tag-city': ['tagNameEncoded', 'tagId', 'cityToName', 'cityToId'],
    'terms-of-use': [],
    'privacy-policy': [],
    'cookie-policy': [],
    'copyright-infringement': [],
    'ur': ['id'],
    'place': [],
    'account': [],
    'search': []
  };

  constructor(
    private router: Router
  ) {
  }

  async(action: any, delay: number = 0) {
    const $this = this;
    doAsync(() => {
      action($this);
    }, delay);
  }

  readParamsFromRouter(params: string[]) {
    const url = this.router.url;
    const routePath = url.split('?');
    const firstPartOfPath: string = Array.isArray(routePath) ? routePath[0] : null;
    if (firstPartOfPath && params) {
      const splitedParams = firstPartOfPath.split('/');
      let formed: any = {};
      for (let i = 0; i < params.length; i++) {
        const h = splitedParams[i + 2];
        formed[params[i]] = decodeParam(h);
      }
      formed = Object.entries(formed)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({...acc, [k]: v}), {}); //filter empty elements
      return formed;
    }
    return {};
  }


  getLabelsForRoute = (path?: string) => {
    let routePath;
    if (!path) {
      routePath = this.getCurrentRoute();
    } else {
      routePath = path;
    }
    const labels = this.labelsForRoutes[routePath];
    if (labels) {
      return labels;
    } else {
      doAsync(() => {
      });
    }
  };

  getCurrentRoute() {
    const path = this.router.url.split(/[\/]|\?/);
    return path[1];
  }

  returnParamsFromRoute() {
    const labels = this.getLabelsForRoute();
    return this.readParamsFromRouter(labels);
  }

}
