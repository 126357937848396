import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '../services';
import { AuthActions } from '../store/auth';
import { State } from '../store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private userTokenService: UserService,
    private store$: Store<State>,
    private router: Router,
  ) {
  }

  canActivate(): boolean {
    const token = this.userTokenService.getToken();

    if (!token) {
      this.store$.dispatch(new AuthActions.SetAuthData({token: '', loggedIn: false}));
      this.userTokenService.signOut(true);
      this.router.navigate(['/']);

      return false;
    }

    return true;
  }
}
