import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from './http.service';
import { CONTACT_US_ACTION } from "../enums/contact-us-action";

@Injectable()
export class ContactUsService {

  constructor(private httpService: HttpService) {
  }

  public contactUs(contact, contactType?: string): Observable<any> {
    let route = 'contact';
    if (contactType === CONTACT_US_ACTION.leave_feedback) {
      route = 'contact/feedback';
    }
    return this.httpService.post(route, contact);
  }

}
