import { Injectable } from '@angular/core';
import { getWindow } from '../libraries/get-window';

@Injectable()
export class WindowRef {
  public window;
  public document;
  public isBrowser: boolean;
  private styleTag: HTMLStyleElement;
  private disabled: boolean = false;

  constructor() {
    try {
      this.window = getWindow();
      this.document = this.window.document;
      this.isBrowser = true;
    } catch (e) {
      this.window = null;
      this.document = null;
      this.isBrowser = false;
    }
  }

  public getNativeWindow() {
    return this.window;
  }

  public getNativeDocument() {
    return this.document;
  }

  public disable(): void {
    if (!this.document) {
      return;
    }
    if (this.disabled) {
      return;
    }
    this.disabled = false;
    this.document.body.appendChild(this.styleTag);
  }

  public enable(): void {
    if (!this.document) {
      return;
    }
    if (!this.disabled) {
      return;
    }
    this.disabled = true;
    this.document.body.removeChild(this.styleTag);
  }

}
