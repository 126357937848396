export const isEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  if (Array.isArray(obj) && obj.length == 0) {
    return true;
  }
  for (let key in obj) {
    if (obj[key] != '' && obj[key] != null) {
      return false;
    }
  }

  return true;
};
