import { Injectable, OnDestroy } from '@angular/core';
import { HttpService } from './http.service';
// import { LanguageService } from './language.service';
import { WindowRef } from './window.service';
// import { FlyCookieService } from './fly-cookie/fly-cookie.service';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { CityInterface } from '../interfaces';
import { HttpParams } from '@angular/common/http';
import { finalize, takeUntil } from 'rxjs/operators';
import { Journey } from '../interfaces/journey';
import { FAVORITE_ID } from "../interfaces/like";
import { of } from 'rxjs/internal/observable/of';

export enum UserAccountLoadStatus {
  GetPinned = 'get_pinned',
}

@Injectable()
export class UserAccountService implements OnDestroy {

  // public labels: string[] = [
  //   'systemNotificationsEmail',
  //   'systemNotificationsSystem',
  //   'companyNotificationsEmail',
  //   'companyNotificationsSystem',
  //   'notificationLanguage',
  //   'notificationTimeZone',
  //   'sleepMode',
  //   'sleepModeFrom',
  //   'sleepModeTo',
  //   'temperatureUnit'
  // ];
  public isPinLoading = false;
  public loadingStatus: UserAccountLoadStatus[] = [];
  public loadingStatusChange: Subject<UserAccountLoadStatus[]> = new Subject<UserAccountLoadStatus[]>();
  public notificationLanguage: any;
  // public notificationTimeZone: any;
  // public regExpTimeFromTo: any = new RegExp('^(0[0-9])|(1[0-9])|(2[0-3]):[0-5][0-9]$');
  // public settingLabel = 'settings';
  public settings: any;
  // public sleepModeFrom: any;
  // public sleepModeTo: any;
  public temperatureUnit: any;
  // public temperatureUnitLabel = 'temperatureUnit';
  // public userSettingsExpire = 30; // days;
  public window: any;

  private $destroyed = new Subject<void>();

  // private subject = new Subject<any>();

  constructor(
    private httpService: HttpService,
    // private cookieService: FlyCookieService,
    // private languageService: LanguageService,
    private windowRef: WindowRef,
  ) {
    this.window = windowRef.getNativeWindow();
    this.loadingStatusChange
      .pipe(takeUntil(this.$destroyed))
      .subscribe((value) => {
        this.loadingStatus = value;
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public getProfileData(params?: any) {
    return this.httpService.get(`user/profile`, params);
  }

  public deleteProfile(): Observable<void> {
    return this.httpService.delete('user/profile');
  }

  public deleteUserPlace(id: number): Observable<void> {
    return this.httpService.delete(`info/place/${id}`);
  }

  public updatePassword(params?: any) {
    return this.httpService.post(`user/password`, params);
  }

  public recoverPlace(placeId: any): Observable<any> {
    return this.httpService.put(`user/recover-place/${placeId}`);
  }

  public updateProfile(data: any, params?: any): Observable<any> {
    return this.httpService.patch('user/profile', data, null, params);
  }

  public validateProfile(data: any, params?: any): Observable<any> {
    return this.httpService.patch('user/profile-validate', data, null, params);
  }

  public updateEmail(data: any): Observable<any> {
    return this.httpService.post('user/email', data);
  }

  /**
   * @param {Object} payload
   * @param {Number} payload.id         - City id
   * @param {Number} payload.dateType       - Type of action
   *                                        TYPE_MONTH = 1
   *                                        TYPE_PERIOD = 2
   * @param {Number} payload.dateFrom   - Departure date
   * @param {Number} payload.dateTo     - Return date
   */
  public updatePin(payload: { oid: any, otype: number, dateType: number, dateFrom: string, dateTo: string }) {
    const params = {
      oid: payload.oid,
      dateType: payload.dateType,
      otype: payload.otype,
      dateFrom: payload.dateFrom,
      dateTo: payload.dateTo
    };
    return this.addPin(params);
  }

  public addPin(params?: CityInterface | CityInterface[] | any) {
    return this.httpService.post(`user/pin`, params);
  }

  public setPinCollection(oid: number, otype: number, type: number, oldCollectionId: number, newCollectionId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('type', String(type))
      .append('otype', String(otype))
      .append('collectionId', String(oldCollectionId));

    return this.httpService.patch(`user/pin/${oid}`, {newCollectionId}, {}, httpParams);
  }

  public addCollection(params?: Journey | any) {
    return this.httpService.post(`user/pin-collection`, params);
  }

  public updateCollection(collectionId: number, params?: Journey | any) {
    if (collectionId && collectionId !== FAVORITE_ID) {
      return this.httpService.put(`user/pin-collection/${collectionId}`, params);
    }
    return of(null);
  }

  public patchCollection(collectionId: number, params?: Journey | any) {
    if (collectionId && collectionId !== FAVORITE_ID) {
      return this.httpService.patch(`user/pin-collection/${collectionId}`, params);
    }
    return of(null);
  }

  public deletePin(oid: number, otype: number, type: number, collectionId?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .append('type', String(type))
      .append('otype', String(otype));

    if (collectionId) {
      httpParams = httpParams.append('collectionId', String(collectionId))
    }

    return this.httpService.delete(
      `user/pin/${oid}`,
      httpParams
    );
  }

  public deleteCollection(collectionId: number) {
    return this.httpService.delete(`user/pin-collection/${collectionId}`);
  }

  // public getUserSettings(params?: any) {
  //   return this.httpService.get(`user/settings`, params);
  // }

  public updateUserSettings(params?: any): Observable<any> {
    return this.httpService.post(`user/settings`, params);
  }

  public getPinList(params?: any) {
    this.setLoadingStatus(true, UserAccountLoadStatus.GetPinned);
    return this.httpService.get(`user/pin`, params).pipe(finalize(() => this.setLoadingStatus(false, UserAccountLoadStatus.GetPinned)));
  }

  public getPinnedCollections(params?: any) {
    this.setLoadingStatus(true, UserAccountLoadStatus.GetPinned);
    return this.httpService.get(`user/pin-collection`, params).pipe(finalize(() => this.setLoadingStatus(false, UserAccountLoadStatus.GetPinned)));
  }

  // public setUserSettingsIntoCookies(params?: any) {
  //   const settings: any = params || this.settings;
  //   for (let i = 0; i < settings.length; i++) {
  //     this.cookieService.set(`${this.settingLabel}`, JSON.stringify(settings), this.userSettingsExpire);
  //   }
  // }

  // public readUserSettingsFromCookies() {
  //   const settings = (this.cookieService.check(`${this.settingLabel}`))
  //     ? this.cookieService.get(`${this.settingLabel}`)
  //     : null;
  //
  //   if (!settings) {
  //     return false;
  //   }
  //
  //   const parsedSettings = JSON.parse(settings);
  //   this.settings = parsedSettings;
  //   return parsedSettings;
  // }

  // checkSettingsInCookies(settingsPassed?: any) {
  //   let settings = settingsPassed;
  //
  //   if (!settings) {
  //     settings = this.readUserSettingsFromCookies();
  //   }
  //
  //   const labels = this.labels;
  //   if (!settings) {
  //     return null;
  //   }
  //
  //   const length = settings.length;
  //
  //   if (length !== labels.length) {
  //     return false;
  //   }
  //
  //   for (let i = 0; i < length; i++) {
  //     const setting = settings[i];
  //     switch (setting.id) {
  //       case labels[0]: // 'systemNotificationsEmail',
  //       case labels[1]: // 'systemNotificationsSystem',
  //       case labels[2]: // 'companyNotificationsEmail',
  //       case labels[3]: // 'companyNotificationsSystem',
  //         if (!this.valueIsInArray([0, 1], setting.value)) {
  //           return null;
  //         }
  //         break;
  //       case labels[4]: // 'notificationLanguage',
  //         if (setting.value == '1') {
  //           setting.value = 'en';
  //         }
  //         break;
  //       case labels[5]: // 'notificationTimeZone',
  //
  //         break;
  //       case labels[6]: // 'sleepMode',
  //         if (!this.valueIsInArray([0, 1], setting.value)) {
  //           return null;
  //         }
  //         break;
  //       case labels[7]: // 'sleepModeFrom',
  //       case labels[8]: // 'sleepModeTo',
  //         if (!this.regExpTimeFromTo.test(setting.value)) {
  //           return null;
  //         }
  //         break;
  //       case labels[9]: // 'temperatureUnit'
  //         if (!this.valueIsInArray(['F', 'C'], setting.value.toUpperCase())) {
  //           return null;
  //         }
  //         break;
  //       default:
  //         return null;
  //     }
  //   }
  //   return settings;
  // }

  // valueIsInArray(array, value) {
  //   return array.indexOf(value) !== -1;
  // }

  // saveLanguageIntoSettings(language) {
  //   const label = this.languageService.languageLabelInCookies; // single language label;
  //
  //   const languageInCookies: boolean = this.cookieService.check(`${label}`);
  //   const settingsInCookies: boolean = this.cookieService.check(`${this.settingLabel}`);
  //
  //   if (!settingsInCookies && languageInCookies) {
  //     this.languageService.setCurrentLanguage(this.cookieService.get(`${label}`));
  //     return;
  //   }
  //
  //   if (settingsInCookies) {
  //     this.cookieService.delete(`${label}`);
  //     const settings: any[] = this.checkSettingsInCookies();
  //     if (settings) {
  //       const languageSettings: any = settings.find((property) => {
  //         return property.id === label;
  //       });
  //       languageSettings.value = language;
  //       this.languageService.setCurrentLanguage(language);
  //       this.setUserSettingsIntoCookies(settings);
  //     }
  //   }
  // }

  // save temperature units into settings;
  // saveTempUnitIntoSettings(tempUnit) {
  //   const label = this.temperatureUnitLabel; // single tempUnit label;
  //   const tempInCookies: boolean = this.cookieService.check(`${label}`);
  //   const settingsInCookies: boolean = this.cookieService.check(`${this.settingLabel}`);
  //
  //   if (!settingsInCookies && tempInCookies) {
  //     this.languageService.setCurrentLanguage(this.cookieService.get(`${label}`));
  //     return;
  //   }
  //
  //   if (settingsInCookies) {
  //     this.cookieService.delete(`${label}`);
  //     const settings: any[] = this.checkSettingsInCookies();
  //     if (settings) {
  //       const tempSettings: any = settings.find((property) => {
  //         return property.id === label;
  //       });
  //       tempSettings.value = tempUnit;
  //       this.saveTempUnitIntoCookies(tempUnit);
  //       this.setUserSettingsIntoCookies(settings);
  //     }
  //   }
  //
  // }

  // saveTempUnitIntoCookies(units: string) {
  //   this.cookieService.set(`${this.temperatureUnitLabel}`, units);
  // }

  // sendMessage(message: string) {
  //   this.subject.next({temp: message});
  // }

  // getMessage(): Observable<any> {
  //   return this.subject.asObservable();
  // }

  private setLoadingStatus(value, key) {
    this.loadingStatus[key] = value;
    this.loadingStatusChange.next(this.loadingStatus);
  }
}
