import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleFormComponent } from './schedule-form.component';
import { FormsModule } from "@angular/forms";


@NgModule({
  declarations: [
    ScheduleFormComponent
  ],
  exports: [
    ScheduleFormComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ]
})
export class ScheduleFormModule {
}
