import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HashtagInputComponent } from "./hashtag-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  declarations: [HashtagInputComponent],
  exports: [
    HashtagInputComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule
  ]
})
export class HashtagInputModule { }
