import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-static-place-item-add',
  templateUrl: './static-place-item-add.component.html',
  styleUrls: ['./static-place-item-add.component.scss'],
})
export class StaticPlaceItemAddComponent {
  @Output() addPlace = new EventEmitter<void>();

  constructor() {
  }

  emitAddPlace(): void {
    this.addPlace.emit();
  }
}
