import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { PlacePinExt } from '../../interfaces';
import { AddCollections, UserCollectionsActions } from '../../store/user-collections';
import { ChangeJourneyInterface, Journey } from '../../interfaces/journey';
import { UserService } from '../../services';
import { Subject } from 'rxjs/internal/Subject';
import { SignInButtonsComponent } from '../fly-search-panel/sign-in-buttons/sign-in-buttons.component';
import { SignUpService } from '../../services/sign-up.service';
import { UserCollectionService } from '../../services/user-collection.service';
import { AuthModalService } from '../../services/auth-modal.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from "@angular/router";
import { DestroyComponent } from "../../heplers/destroy.component";
import { StaticService } from "../../services/static.service";
import { TogglePinnedPanel } from "../../store/panels/panels.actions";
import { DEFAULT_TITLE_JOURNEY } from "../../../constants";
import { PinnedModeIdx } from "../../enums/pinned-mode.enum";

@Component({
  selector: 'app-pinned-panel',
  templateUrl: './pinned-panel.component.html',
  styleUrls: ['./pinned-panel.component.scss'],
})
export class PinnedPanelComponent extends DestroyComponent implements OnInit, OnDestroy {
  @ViewChild('signInButtons', {static: true}) signInComponent: SignInButtonsComponent;

  @Input() mode: PinnedModeIdx;

  @Output() openPlaceEmitter = new EventEmitter<any>();
  @Output() openPlaceInfoInTabEmitter = new EventEmitter<any>();
  @Output() closePinnedPanelEmit = new EventEmitter<any>();
  @Output() initPinnedPanelEmit = new EventEmitter<void>();

  public isCreateCollection = false;
  public isOpenCollection = false;
  public addToNewCollection: ChangeJourneyInterface;
  public defaultCollection: Journey;
  public allCollections: Journey[] = [];
  public selectCollectionData: Journey = null;
  public isPinnedLoading: boolean;
  public userIsLogged: boolean;
  public currentPlace: PlacePinExt;
  public defaultTitleCollection = DEFAULT_TITLE_JOURNEY;
  public selectCollectionId: number;

  protected $destroyed = new Subject<void>();

  constructor(
    protected store$: Store<State>,
    protected signUpService: SignUpService,
    protected userCollectionService: UserCollectionService,
    protected authModalService: AuthModalService,
    protected router: Router,
    protected userService: UserService,
    protected staticService: StaticService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLoggedIn();
    this.store$.dispatch(new UserCollectionsActions.LoadCollections);
    this.loadCollections();
    this.authModalService.isLoggedUser$
      .pipe(takeUntil(this.$destroyed))
      .subscribe(x => this.userIsLogged = x);
    setTimeout(() => {
      this.initPinnedPanelEmit.emit();
    }, 200)
  }

  ngOnDestroy() {
    this.staticService.isRememberCollection = false;
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public loadCollections() {
    this.userCollectionService.getFilteredCollections()
      .pipe(
        takeUntil(this.$destroyed)
      ).subscribe((collections) => {
      this.isOpenCollection = !(this.staticService.isRememberCollection && collections?.length > 1);
      if (collections.length) {
        if (this.staticService.isPinnedPlaceDisplay) {
          collections = collections.map((collection) => {
            if (!collection?.isFavorite) {
              return {...collection}
            }
          }).filter((x) => x);
        }
        this.allCollections = JSON.parse(JSON.stringify(collections));
        if (this.selectCollectionId) {
          this.allCollections = this.allCollections.map(renderCollection => ({
            ...renderCollection,
            isDefault: renderCollection.id === this.selectCollectionId
          }));
          this.defaultCollection = this.allCollections.find(renderCollection => renderCollection.id === this.selectCollectionId);
          this.selectCollectionData = this.defaultCollection;
          this.selectCollectionId = null;
        } else {
          this.defaultCollection = this.allCollections.find(renderCollection => renderCollection.isDefault);
        }
      } else {
        this.isPinnedLoading = false;
      }
    })
  }

  public deleteItem(): void {
    if (this.staticService.showPlace && this.staticService.showPlace.pinned) {
      this.staticService.showPlace.pinned = false;
    }
  }

  public close(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
    this.closePinnedPanelEmit.emit();
  }

  public openCreateCollectionField(event?: ChangeJourneyInterface) {
    this.isOpenCollection = true;
    if (this.userIsLogged || (!this.userIsLogged && this.allCollections?.length <= 1)) {
      this.isCreateCollection = !this.isCreateCollection;
    }
    this.addToNewCollection = event;
  }

  public cancelCreateCollection() {
    this.isCreateCollection = false;
    if (this.allCollections?.length > 0) {
      // only close if there are collections, otherwise - show text - about no collection
      this.isOpenCollection = false;
    }
  }

  public createCollection(event) {
    if (!this.userIsLogged) {
      event = UserCollectionService.getCollectionStructureByName(event.name, event?.description);
    }
    this.staticService.isRememberCollection = false;
    this.cancelCreateCollection();
    this.store$.dispatch(new AddCollections(event, true, !this.userIsLogged, this.addToNewCollection));
  }

  public selectCollectionItem(item) {
    this.staticService.isRememberCollection = false;
    this.isOpenCollection = true;
    this.selectCollectionData = item;
    this.selectCollectionId = item.id;
  }

  public toggleOpenCollection() {
    this.isOpenCollection = !this.isOpenCollection;
    this.isCreateCollection = false;
  }

  public isLoggedIn() {
    if (!this.userIsLogged) {
      this.userIsLogged = this.userService.loggedIn();
    }
    return this.userIsLogged;
  }

  public openPlace(place) {
    this.currentPlace = place;
  }
}
