import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Journey } from "../../../interfaces/journey";
import { constructBaseUrl, constructMediaURL } from "../../../libraries";
import { ToastrService } from "../../../services/toastr.service";
import { SsrService, UserService } from "../../../services";
import { environment } from "../../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { DEFAULT_TITLE_JOURNEY } from "../../../../constants";

export const DEFAULT_COLLECTION_IMAGE = 'default_collection_image.png';

@Component({
  selector: 'app-pinned-panel-collection',
  templateUrl: './pinned-panel-collection.component.html',
  styleUrls: ['./pinned-panel-collection.component.scss']
})
export class PinnedPanelCollectionComponent implements OnInit, OnChanges {
  @Input() isProfile: boolean;
  @Input() withLink: boolean;
  @Input() collections: Journey[];
  @Output() emitSelectItem = new EventEmitter();
  @Output() initPinnedPanelEmit = new EventEmitter<void>();

  public constructMediaURL = constructMediaURL;
  public imagesCollection = [];
  public counterCollection = [];
  public defaultCollectionImage = DEFAULT_COLLECTION_IMAGE;
  public defaultTitleCollection = DEFAULT_TITLE_JOURNEY;

  protected isUserDomain: boolean;

  constructor(
    private toastrService: ToastrService,
    ssrService: SsrService,
    protected userService: UserService,
    protected sanitizer: DomSanitizer,
  ) {
    this.isUserDomain = ssrService.getIsUserDomain();
  }

  ngOnInit() {
    this.generateCounter();
    this.photoRender();
    this.initPinnedPanelEmit.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collections) {
      this.generateCounter();
      this.photoRender();
    }
  }

  public generateCounter() {
    this.collections = this.collections.map(collection =>
      ({
        ...collection,
        id: Math.abs(collection.id),
        places: collection?.places?.filter(pin => pin && pin.place)
      })
    );
    this.collections = this.collections.map((collection) => {
      collection = JSON.parse(JSON.stringify(collection));
      collection.tooltip = `<span style="font-size:14px; font-weight: 500">${collection.name ? collection.name : DEFAULT_TITLE_JOURNEY}</span>`;
      this.counterCollection = [];
      if (collection.places) {
        const countPlace = Object.keys(collection.places).length;
        let item: string;
        if (countPlace > 1 || countPlace === 0) {
          item = 'places';
        } else {
          item = 'place';
        }
        this.counterCollection.push(countPlace + ' ' + item);
      }
      return {
        ...collection,
        counter: this.counterCollection.join(', ')
      }
    });
  }

  public selectItem(item) {
    this.emitSelectItem.emit(item);
    this.toastrService.closeTooltip$.next();
  }

  public photoRender() {
    this.collections.forEach(collection => {
      collection.places = collection.places?.filter(x => x);
      this.imagesCollection[collection.id] = [];
      if (collection.places && collection.places.length) {
        collection.places.forEach((pin, index) => {
          if (pin.place?.video?.length) {
            pin.place.video.forEach(video => {
              this.setImagesContent(collection.id, video.poster?.thumb)
            })
          }
        });
        if (this.imagesCollection[collection.id].length < 3) {
          if (collection.places && collection.places.length > 2) {
            collection.places.forEach((pin, index) => {
              if (pin && pin.place?.pictures?.length) {
                this.setImagesContent(collection.id, pin.place.pictures[0]?.thumb)
              }
            });
          } else if (collection.places && collection.places.length === 2) {
            const sortCollectionByAlphabet = collection.places.sort(function (a, b) {
              return a.place.name > b.place.name ? 1 : -1;
            });
            sortCollectionByAlphabet.forEach((collect, collectIndex) => {
              collect.place.pictures.forEach((image, imageIndex) => {
                if (collectIndex === 0 && imageIndex < 2 && image) {
                  this.setImagesContent(collection.id, image.thumb)
                }
                if (collectIndex === 1 && imageIndex === 0 && image) {
                  this.setImagesContent(collection.id, image.thumb)
                }
              });
            });
          } else if (collection.places && collection.places.length) {
            collection.places.forEach((pin, placeIndex) => {
              if (placeIndex < 3 && pin) {
                pin.place.pictures.forEach((picture, pictureIndex) => {
                  this.setImagesContent(collection.id, picture.thumb)
                })
              }
            });
          }
        }
      } else {
        this.imagesCollection[collection.id].push(DEFAULT_COLLECTION_IMAGE, DEFAULT_COLLECTION_IMAGE, DEFAULT_COLLECTION_IMAGE);
      }
    })
  }

  public setImagesContent(collectionId, thumb) {
    if (this.imagesCollection[collectionId].length < 3 && thumb) {
      this.imagesCollection[collectionId].push(thumb);
    }
  }

  protected readonly environment = environment;
  protected readonly constructBaseUrl = constructBaseUrl;
}
