<div class="settings-filters-panel__container">
  <app-from-search-field class="search-input-field bold-border"
    #cityFromField
    [value]="cityFrom$ | async"
                         (closeDropDown)="closeCityFrom($event)"
                         (selectEmitter)="setCityFrom($event)">
    <app-fly-autocomplete-component *ngIf="!dropdownClose" (selectFromCity)="setCityFrom($event)">
    </app-fly-autocomplete-component>
  </app-from-search-field>

  <!-- Select trip type -->
  <app-trip-way-selector
    [value]="tripType$ | async"
    (changeEmitter)="setTripWay($event)">
  </app-trip-way-selector>

  <div class="date-container">
    <!-- When field -->
    <app-fly-date-picker
      class="search-input-field bold-border"
      [ngClass]="{'full-width': (tripType$ | async) === 'ONE_WAY'}"
      *ngIf="(date$ | async)[0] as dateStart"
      [availableMode]="availableMode"
      [title]="'When'"
      [value]="dateStart"
      (requiredMonthChangeEmitter)="setWhen(0, $event)">
    </app-fly-date-picker>

    <!-- Return -->
    <app-fly-date-picker class="search-input-field bold-border"
      *ngIf="(date$ | async)[1] as dateEnd"
      [availableMode]="availableMode"
      [title]="'Return'"
      [minDateLimit]="formData.date[0]"
      [value]="dateEnd"
                         (requiredMonthChangeEmitter)="setWhen(1, $event)">
    </app-fly-date-picker>
  </div>

  <!-- Passenger selector -->
  <app-trip-passenger-selector
    *ngIf="(passengers$ | async) as passengersValue"
    [value]="passengersValue"
    (changeEmitter)="setPassenger($event)">
  </app-trip-passenger-selector>
</div>
