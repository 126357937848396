import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { getHomeData, getNotificationList, loggedIn, State } from '../../../store/reducers';
import { select, Store } from '@ngrx/store';
import { SetSearchPanelState } from '../../../store/layer/layer.actions';
import { SsrService, UserService } from '../../../services';
import { Subject } from 'rxjs/internal/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { AuthModalService } from '../../../services/auth-modal.service';
import { UserCollectionService } from '../../../services/user-collection.service';
import { PageTypeEnum } from "../../../enums/page-type.enum";
import { CONTACT_US_LINKS } from "../../../enums/contact-us-action";
import { constructBaseUrl, constructMediaURL, getWindow } from "../../../libraries";
import { maxMobileWidth, maxTabletWidth } from "../../../services/helper.service";
import { NotificationList } from "../../../interfaces/notifications";
import { getLinkByObject } from "../../../libraries/get-link-by-object";

@Component({
  selector: 'app-sign-in-buttons',
  templateUrl: 'sign-in-buttons.component.html',
  styleUrls: [
    '../../../modules/user-account/components/user-sidebar/user-sidebar.component.scss',
    '../../../styles/decorate-bottom-border.scss',
    'sign-in-buttons.component.scss'
  ],
})
export class SignInButtonsComponent implements OnInit, OnChanges, OnDestroy {
  private $destroyed = new Subject<void>();

  @Input() isNotSignInButton: any;
  @Input() isText: any;
  @Input() searchMode: any;
  @Input() userIsLogged: any;
  @Input() month: any;
  @Input() showLoginForm: boolean = true;
  @Input() mobileMode: boolean = false;
  @Input() tabletMode: boolean = false;
  @Input() showProfilePopup: boolean = true;
  @Input() isSignInClickDisabled: boolean = false;

  @Output() closeUserAccount: EventEmitter<boolean> = new EventEmitter();
  @Output() openUserAccount: EventEmitter<boolean> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  public checkMobile() {
    if (this.ssrService.isBrowser()) {
      const w = getWindow();
      if (w) {
        this.mobileMode = w.innerWidth <= maxMobileWidth;
        this.tabletMode = w.innerWidth > maxMobileWidth && w.innerWidth < maxTabletWidth;
      }
    }
  }

  public showRegisterForm = false;
  public showUserAccount = false;
  public isUserLogged = false;
  public showWinWelcome = false;
  public resetPass = false;
  public userActions: string;
  public textMessage: string;
  public isNotificationContentVisible = false
  public isOpenProfilePopup: boolean;
  public homeData: any;
  public contactUsOpened: string;
  public contactContentOpened = false;
  public helpContentOpened = false;
  public popupDataAuth = [
    {
      icon: 'personal-page.svg',
      icons: [],
      title: 'Personal Page',
      action: 'toggleUserAccount',
      value: true,
      pageType: PageTypeEnum.PersonalPage,
    },
    {
      icon: 'notifications.svg',
      title: 'Notifications',
      action: 'toggleUserAccount',
      value: true,
      fullImg: true,
      badgeText: 0,
      pageType: PageTypeEnum.NotificationPage
    },
    {
      icon: 'my-account.svg',
      title: 'My Account',
      action: 'toggleUserAccount',
      value: true,
      fullImg: true,
      pageType: PageTypeEnum.OtherPage
    },
    {
      icon: 'help.svg',
      title: 'Help',
      action: 'toggleUserAccount',
      upperLine: true,
      value: true,
      fullImg: true,
      pageType: PageTypeEnum.HelpPage
    }
  ];

  public popupDataNoAuth = [
    {
      icon: 'signup-icon.svg',
      title: 'Sign Up',
      action: 'toggleRegisterForm',
      value: false,
      fullImg: true,
    },
    {
      icon: 'login-icon.svg',
      title: 'Log In',
      action: 'toggleRegisterForm',
      value: true,
      fullImg: true,
    },
    {
      icon: 'help.svg',
      title: 'Help',
      action: 'toggleUserAccount',
      upperLine: true,
      value: true,
      fullImg: true,
      pageType: PageTypeEnum.HelpPage
    }
  ];
  public burgerLinks = [
    {
      name: 'Cookie Policy',
      url: '/cookie-policy'
    },
    {
      name: 'Term Of Use',
      url: '/terms-of-use'
    },
    {
      name: 'Privacy Policy',
      url: '/privacy-policy'
    }
  ];
  public burgerSubLinks = [
    {
      name: 'Site Map',
      url: '/site-map'
    },
    {
      name: 'List Of Cities',
      url: '/list-cities'
    },
  ];

  public contactUsLinks = CONTACT_US_LINKS;

  protected newNotifications: NotificationList;

  @HostListener('window:resize')
  onResize() {
    this.isOpenProfilePopup = false;
  }

  constructor(
    private store$: Store<State>,
    private cdRef: ChangeDetectorRef,
    private authModalService: AuthModalService,
    private userCollectionService: UserCollectionService,
    private userService: UserService,
    private ssrService: SsrService,
  ) {
  }

  ngOnInit(): void {
    this.userIsLogged = this.userService.loggedIn();
    this.store$.pipe(
      select(loggedIn),
      take(1)
    ).subscribe(() => {
      this.isUserLogged = this.userService.loggedIn();
      this.userCollectionService.checkCitiesInCookies();
    });

    this.store$.select(getHomeData)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(homeData => {
        this.homeData = homeData;
        this.initUserIcons();
      });

    this.authModalService.isLoggedUser$
      .pipe(takeUntil(this.$destroyed))
      .subscribe(value => {
        this.isUserLogged = value;
        if (this.isUserLogged) {
          this.userCollectionService.checkCitiesInCookies();
        }
      });

    this.authModalService.showWinWelcome$
      .pipe(takeUntil(this.$destroyed))
      .subscribe(value => {
        this.showWinWelcome = value;
        this.isUserLogged = this.userService.loggedIn();
      });

    this.store$.select(getNotificationList)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(notifications => {
        this.newNotifications = notifications;
        this.loadNotificationBadgeContent();
      });

    this.authModalService.isOpen
      .pipe(takeUntil(this.$destroyed))
      .subscribe((value: boolean) => {
        this.showRegisterForm = value;
        this.showLoginForm = false;
      });

    this.checkMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mobileMode'] && !changes['mobileMode'].currentValue) {
      this.isOpenProfilePopup = false;
    }
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public toggleRegisterForm(showLoginForm = false): void {
    this.showRegisterForm = !this.showRegisterForm;
    this.showLoginForm = showLoginForm;
    this.cdRef.detectChanges();
  }

  public closeUserModal(): void {
    this.openUserAccount.emit(false);
  }

  public onWinWelcomeClose(withRedirectToHome = false): void {
    this.resetPass = false;
    this.authModalService.showWinWelcome$.next(false);
    this.authModalService.isLoggedUser$.next(this.userService.loggedIn());
    if (withRedirectToHome) {
      this.ssrService.goToRoute('/');
    }
  }

  public resPasswordSuccess(value): void {
    this.showRegisterForm = false;
    this.userActions = 'reset';
    this.textMessage = `We sent you an email with a link to reset your password.
    Please check your email and click the link.`;
    this.resetPass = value;
    this.cdRef.detectChanges();
  }

  public registrationCompleted(): void {
    this.userActions = 'register';
    this.textMessage = `We sent you a confirmation email with a link to activate your registration. Please check your email and click the link.`;
    this.authModalService.showWinWelcome$.next(true);
  }

  public changeModal(modal): void {
    if (modal.showLoginForm) {
      this.onWinWelcomeClose();
    }

    if (!modal.showLoginForm) {
      this.registrationCompleted();
    }
  }

  public showUserProfile(isLogin = false): void {
    if (!isLogin) {
      if (this.userActions === 'register') {
        this.authModalService.isLoggedUser$.next(true);

        if (this.ssrService.isBrowser()) {
          localStorage.setItem('previousUrl', location.pathname);
        }

        switch (true) {
          case location.pathname === '/help/travel-business-page':
          case location.pathname === '/help/travel-business':
            this.ssrService.goToRoute('/account/profile');
            break;
          default:
            this.ssrService.goToRoute('/account/info');
            break;
        }
      }

      if (this.userActions === 'reset') {
        this.showRegisterForm = true;
        this.authModalService.isLoggedUser$.next(false);
        this.cdRef.detectChanges();
      }
    }
    this.onWinWelcomeClose();
  }

  public toggleUserAccount(val?: boolean, pageType: PageTypeEnum = PageTypeEnum.OtherPage): void {
    this.userIsLogged = this.userService.loggedIn();
    if (!this.userIsLogged && pageType !== PageTypeEnum.HelpPage) {
      this.showRegisterForm = true;
      this.authModalService.isLoggedUser$.next(false);
      this.cdRef.detectChanges();
    }

    if (val) {
      switch (pageType) {
        case PageTypeEnum.PersonalPage:
          this.ssrService.goToRoute(getLinkByObject(this.homeData.user));
          break;
        case PageTypeEnum.OtherPage:
          this.ssrService.goToRoute('/account');
          break;
        case PageTypeEnum.HelpPage:
          this.toggleHelpContent(true)
          break;
        case PageTypeEnum.NotificationPage:
          this.toggleNotificationContent(true)
          break;
      }

      if (this.ssrService.isBrowser()) {
        localStorage.setItem('previousUrl', location.pathname);
      }
    }

    this.toggleProfilePopup();
    this.showUserAccount = val;
    this.store$.dispatch(new SetSearchPanelState(false));
    this.closeUserAccount.emit(false);
    this.openUserAccount.emit(this.showUserAccount);
  }

  public toggleProfilePopup(value: boolean = null) {
    if (!this.userIsLogged) {
      localStorage.removeItem('nextActionAfterAuth');
    }
    this.isOpenProfilePopup = value === null ? !this.isOpenProfilePopup : value;
  }

  public togglePopup(value: boolean = null) {
    this.toggleProfilePopup(value);
  }

  public emitAction(funcName) {
    if (funcName) {
      if (funcName.value !== null && funcName.pageType !== null) {
        this[funcName.action](funcName.value, funcName.pageType);
      } else if (funcName.value !== null && funcName.pageType === null) {
        this[funcName.action](funcName.value);
      } else {
        this[funcName.action]();
      }
    }
  }

  public toggleContactUs(value: string = null): void {
    this.contactUsOpened = value;
    if (value) {
      this.toggleHelpContent(false);
    }
    this.toggleContactContent(false);
  }

  public toggleContactContent(value?: boolean): void {
    if (value !== undefined) {
      this.contactContentOpened = value;
    } else {
      this.contactContentOpened = !this.contactContentOpened;
    }
  }

  public toggleHelpContent(value: boolean): void {
    this.helpContentOpened = value;
    if (!value) {
      this.toggleContactContent(false);
    }
  }

  public checkAction() {
    if (!this.showProfilePopup && !this.userIsLogged) {
      this.toggleRegisterForm();
    } else {
      this.toggleProfilePopup();
    }
  }

  private loadNotificationBadgeContent() {
    let menuItem = this.popupDataAuth.filter(x => x.pageType === PageTypeEnum.NotificationPage);
    if (menuItem.length > 0) {
      menuItem[0].badgeText = this.newNotifications?._extra?.unreadCount;
    }
  }

  protected toggleNotificationContent(value: boolean): void {
    this.isNotificationContentVisible = value;
  }

  getUserIcon(): string {
    if (this.isUserLogged && !!this.homeData.user?.picture?.thumb) {
      return constructMediaURL(this.homeData.user?.picture?.thumb);
    } else {
      return constructBaseUrl('/assets/icons/user-profile-new.svg');
    }
  }

  hasIcon(): boolean {
    return this.isUserLogged && !!this.homeData?.user?.picture?.thumb;
  }

  private initUserIcons(): void {
    let menuItem = this.popupDataAuth.filter(x => x.pageType === PageTypeEnum.PersonalPage);
    if (menuItem.length > 0) {
      menuItem[0].icons = this.homeData?.user?.createdPlacesPics;
    }
  }
}
