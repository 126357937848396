import { Injectable } from '@angular/core';
import { CountryInterface, getCountries } from './countries.list';

interface CountryServiceData {
  bounds: string;
  id: string;
  name: string;
  sn: string;
}

@Injectable()

export class CountryService {
  public countries: any[];

  constructor() {
    this.countries = this.getAllCountries();
  }

  public getAllCountries(): CountryServiceData[] {
    return getCountries();
  }

  findCountryByName(name: String): CountryServiceData {
    const l = name
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/[`’"\']/g, '');
    return this.countries.find(c => {
      const countryName = c.name.toLowerCase().replace(/[`’"\']/g, '');
      return countryName === l;
    });
  }

  getCountriesByMapBounds(currentBounds: google.maps.LatLngBounds): CountryInterface[] {
    if (!currentBounds) {
      throw new Error('map bounds change event is not exists');
    }
    return this.countries.filter((country: CountryInterface): boolean => {
      if (country.id && country.bounds) {
        const countryCoords: string[] = country.bounds.split(',');
        const countryBounds = {
          south: Number.parseFloat(countryCoords[0]),
          west: Number.parseFloat(countryCoords[1]),
          north: Number.parseFloat(countryCoords[2]),
          east: Number.parseFloat(countryCoords[3]),
        } as google.maps.LatLngBoundsLiteral;

        return currentBounds.intersects(countryBounds);
      }
      return false;
    });
  }

}
