import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlyModalNoticeComponent } from "./fly-modal-notice.component";
import { FooterModule } from "../../modules/shared/footer/footer.module";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [FlyModalNoticeComponent],
  exports: [
    FlyModalNoticeComponent
  ],
  imports: [
    CommonModule,
    FooterModule,
    MatButtonModule
  ]
})
export class FlyModalNoticeModule { }
