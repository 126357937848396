export enum AccountRouteType {
  About = 'about',
  YourPage = 'your-page',
  YourPlaces = 'your-places',
  YourReviews = 'your-reviews',
  Settings = 'settings',
  Journeys = 'journeys',
  Favourites = 'favourites',
  Following = 'following',
  Notifications = 'notifications',
}
