import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../../../store/layer';
import { StaticRouteType } from '../enums/route-type';
import { HttpService } from '../../../services/http.service';
import { ActivatedRoute } from '@angular/router';
import { ReviewInterface } from "../../../interfaces";
import { HelperService } from "../../../services/helper.service";
import { UserUrlSettings } from "../../../interfaces/user";
import { StaticTransferStateKey } from "../../../enums/static-transfer-state-key.enum";
import { InfoService } from '../../../services/info.service';
import { SsrService } from '../../../services/ssr.service';

@Injectable()
export class StaticInitService {
  //TODO: why is FirstRun* params needed?:
  private categoryPageFirstRun = true;
  private categoryTagPageFirstRun = true;
  private cityInfoFirstRun = true;
  private countryPageFirstRun = true;
  private PlaceReviewByIdFirstRun = true;
  private responseCache = new Map();
  private travelPageFirstRun = true;
  private collectionPageFirstRun = true;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly ssrService: SsrService,
    private readonly httpService: HttpService,
    private readonly infoService: InfoService,
    private readonly helperService: HelperService,
  ) {
  }

  private static getUniqueId(endpoint: string, params?: any, ssrKey?: string): string {
    return (endpoint + JSON.stringify(params) + JSON.stringify(ssrKey)).toLocaleLowerCase();
  }

  public getUrlLinkName(url: string): string {
    if (url) {
      return (new URL(url)).hostname.replace('www.', '');
    }
    return '';
  }

  public search(params: HttpParams): Observable<any> {
    const uniqueCacheId: string = StaticInitService.getUniqueId(`search/full`, params);
    const fromCache = this.responseCache.get(uniqueCacheId);

    if (fromCache) {
      return of(fromCache);
    }

    return this.httpService
      .get(`search/full`, params, {withHeaders: true})
      .pipe(tap((data: any) => this.responseCache.set(uniqueCacheId, data)));
  }

  public getPlaceData(placeData, pageSize = null) {
    let httpParams = new HttpParams();

    httpParams = httpParams.set(
      'expand',
      'similarPlacesLimit,descriptionBlocks,pictures,iataCodeFrom,city.country,country,city.flights,tags,category,pinned,' +
      'similarPlaces.city.country,similarPlaces.user,similarPlaces.city,similarPlaces.country.flights,' +
      'similarPlaces.pictures,user.homeCity.country,similarPlaces.tags,similarPlaces.pinned,reviews.user.picture,' +
      'reviews.user.homeCity.country,reviews.pictures,reviews.video,reviewsTotalCount,canAddReview,country,date,' +
      'video,similarPlaces.video,similarPlaces.city.hasPage,tagGroup,authorialCollections,collection,' +
      'user.urlSetting,similarPlaces.user.urlSetting,reviews.user.urlSetting,user.contactInfo,user.picture'
    );
    httpParams = httpParams.set('filter[paxCount]', placeData.passengers.toString());
    httpParams = httpParams.set('filter[flightType]', transformTripTypeToApi(placeData.tripType));
    httpParams = httpParams.set('filter[dateType]', transformDateToApiDateType(placeData.date).toString());
    httpParams = httpParams.set('filter[when]', transformWhenDateToApiDateType(placeData.date));
    httpParams = httpParams.set('filter[return]', transformReturnToApiDateType(placeData.date) || '');
    if (pageSize) {
      httpParams = httpParams.set('pageSize', pageSize);
    }
    if (placeData.cityFrom.id) {
      httpParams = httpParams.set('filter[cityFrom]', placeData.cityFrom.id.toString());
    }

    return this.infoService.getPlace(placeData.placeID, httpParams)
      .pipe(
        map(response => {
            return {
              ...placeData,
              data: response
            };
          }
        )
      );
  }

  // get staticRouteType.CITY
  public getCityData(cityData, pageSize = null) {
    if (!cityData) {
      return of(null);
    }

    const ssrKey = `${StaticTransferStateKey.CityInfoKey}_${cityData.cityID}`;
    if (this.cityInfoFirstRun) {
      this.cityInfoFirstRun = false;
      const storedData = this.ssrService.getState(ssrKey);

      if (storedData) {
        this.ssrService.removeState(ssrKey);
        return of({
          ...cityData,
          data: storedData
        });
      } else {
        return this.fetchCityData(cityData, pageSize)
          .pipe(
            map(cityDataRes => {
              this.ssrService.setState(ssrKey, cityDataRes.data);
              return cityDataRes;
            })
          );
      }
    } else {
      return this.fetchCityData(cityData, pageSize);
    }
  }

  public getUserInfoData(userData, httpParams = {}, stateKey = null) {
    if (!userData) {
      return of(null);
    }
    stateKey = stateKey !== null ? stateKey : `${StaticTransferStateKey.UserInfoKey}_${UserUrlSettings.Id}_${userData.id}`;

    if (this.cityInfoFirstRun) {
      this.cityInfoFirstRun = false;
      const storedData = this.ssrService.getState(stateKey);
      if (storedData) {
        this.ssrService.removeState(stateKey);
        return of({
          ...userData,
          data: storedData
        });
      } else {
        return this.fetchUserDataById(userData, httpParams)
          .pipe(
            map((data: any) => {
              this.ssrService.setState(stateKey, data.data);
              return data;
            })
          );
      }
    } else {
      return this.fetchUserDataById(userData, httpParams);
    }
  }

  public getUserInfoDataByName(userData, httpParams = {}, stateKey = null) {
    if (!userData) {
      return of(null);
    }
    stateKey = stateKey !== null ? stateKey : `${StaticTransferStateKey.UserInfoKey}_${UserUrlSettings.Name}_${userData.userName}`;

    if (this.cityInfoFirstRun) {
      this.cityInfoFirstRun = false;
      const storedData = this.ssrService.getState(stateKey);
      if (storedData) {
        this.ssrService.removeState(stateKey);
        return of({
          ...userData,
          data: storedData
        });
      } else {
        return this.fetchUserDataByName(userData, httpParams)
          .pipe(
            map((data: any) => {
              this.ssrService.setState(stateKey, data.data);
              return data;
            })
          );
      }
    } else {
      return this.fetchUserDataByName(userData, httpParams);
    }
  }

  // get staticRouteType.COUNTRY
  public getCountryData(countryData) {
    if (this.countryPageFirstRun) {
      this.countryPageFirstRun = false;
      const ssrKey = `${StaticTransferStateKey.CountryInfoKey}_${countryData.country.id}`;
      const storedData = this.ssrService.getState(ssrKey);
      if (storedData) {
        this.ssrService.removeState(ssrKey);
        return of({
          ...countryData,
          data: storedData
        });
      } else {
        return this.fetchCountryData(countryData).pipe(
          map(data => {
            this.ssrService.setState(ssrKey, data.data);
            return data;
          })
        );
      }
    } else {
      return this.fetchCountryData(countryData);
    }
  }

  public getTravelData(httpParams: HttpParams | null, key: string) {
    if (!httpParams) {
      return of(null);
    }
    if (this.travelPageFirstRun) {
      this.travelPageFirstRun = false;
      const storedData = this.ssrService.getState(key);
      if (storedData) {
        this.ssrService.removeState(key);
        return of({
          ...httpParams,
          data: storedData
        });
      } else {
        return this.fetchTravelData(httpParams)
          .pipe(
            map(travelDataRes => {
              this.ssrService.setState(key, travelDataRes.data);
              return travelDataRes;
            })
          );
      }
    } else {
      return this.fetchTravelData(httpParams);
    }
  }

  public getJourneyData(journeyData, journeyId, pageCount) {
    if (!journeyData) {
      return of(null);
    }
    if (this.collectionPageFirstRun) {
      this.collectionPageFirstRun = false;
      return this.fetchJourneyData(journeyData, journeyId, pageCount)
        .pipe(
          map(travelDataRes => {
            return travelDataRes;
          })
        );
    } else {
      return this.fetchJourneyData(journeyData, journeyId, pageCount);
    }
  }

  public getCategoryData(categoryData, pageSize = null) {
    if (!categoryData) {
      return of(null);
    }

    let pageId;
    switch (true) {
      case categoryData.type === 'country':
        pageId = categoryData.countryId;
        break;
      case categoryData.type === 'place':
      case categoryData.type === 'city':
        pageId = categoryData.type + categoryData.routeId;
        break;
      default:
        pageId = categoryData.id;
        break;
    }
    const ssrKey = `${StaticTransferStateKey.CategoryInfoKey}_${pageId}_${categoryData.tagId}_${categoryData.isSimilarPlaces}`;
    //categoryTagPageFirstRun is for working ssr cache on pages /experiences/... page
    if (this.categoryPageFirstRun || (categoryData.type === StaticRouteType.Experiences && this.categoryTagPageFirstRun)) {
      this.categoryPageFirstRun = false;
      if (categoryData.isSimilarPlaces) {
        this.categoryTagPageFirstRun = false;
      }

      const storedData = this.ssrService.getState(ssrKey);
      if (storedData) {
        this.ssrService.removeState(ssrKey);
        return of({
          ...categoryData,
          data: storedData
        });
      } else {
        return this.fetchCategoryData(categoryData, pageSize)
          .pipe(
            map(categoryDataRes => {
              this.ssrService.setState(ssrKey, categoryDataRes.data);
              return categoryDataRes;
            })
          );
      }
    } else {
      return this.fetchCategoryData(categoryData, pageSize);
    }
  }

  public getPlaceReviewById(reviewId: number): Observable<ReviewInterface> {
    if (!reviewId) {
      return of(null);
    }

    const ssrKey = `${StaticTransferStateKey.PlaceReviewByIdKey}_${reviewId}`;

    if (this.PlaceReviewByIdFirstRun) {
      this.PlaceReviewByIdFirstRun = false;
      const storedData = this.ssrService.getState(ssrKey);

      if (storedData) {
        this.ssrService.removeState(ssrKey);
        return of(storedData);
      } else {
        return this.fetchPlaceReviewById(reviewId)
          .pipe(
            map(placeDataRes => {
              this.ssrService.setState(ssrKey, placeDataRes);
              return placeDataRes;
            })
          );
      }
    } else {
      return this.fetchPlaceReviewById(reviewId);
    }
  }

  private fetchPlaceReviewById(reviewId: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set(
      'expand',
      'user.picture,user.homeCity.country,pictures,place.descriptionFull,place.tags,place.category,place.pictures,' +
      'place.city.country,place.similarPlaces.pictures,place.similarPlaces.city.flights,' +
      'place.similarPlaces.city.country,place.similarPlaces.country,place.similarPlacesLimit,place.city.flights,place.reviewsTotalCount,' +
      'place.canAddReview,tags,place.country,place.user,place.reviews,place.city.hasPage,' +
      'place.similarPlaces.city.hasPage,place.reviews.pictures,place.reviews.video,place.video,' +
      'place.similarPlaces.video,video,user.urlSetting,place.similarPlaces.user.urlSetting,place.reviews.user.urlSetting'
    );
    return this.infoService.getReviewById(reviewId, httpParams)
  }

  // fetch staticRouteType.CITY
  private fetchCityData(cityData, pageSize = null) {
    let requestParams = new HttpParams();
    requestParams = requestParams.append(
      'expand',
      'placesLimit,similarPlacesLimit,places.user,places.tags,places.pictures,timeZone,' +
      'places.city.country,places.country,places.city.flights,places.pinned,places.city.hasPage,pictures,' +
      'tags,country,flights,pinned,iataCodeFrom,similarPlaces.pictures,similarPlaces.user,' +
      'similarPlaces.tags,similarPlaces.pinned,similarPlaces.city.country,similarPlaces.city.flights,places.video,' +
      'similarPlaces.country,similarPlaces.video,similarPlaces.city.hasPage,places.user.urlSetting,similarPlaces.user.urlSetting'
    );
    requestParams = requestParams.append('filter[cityFrom]', cityData.cityFrom.id.toString());
    requestParams = requestParams.append('filter[paxCount]', cityData.passengers.toString());
    requestParams = requestParams.append('filter[flightType]', transformTripTypeToApi(cityData.tripType));
    requestParams = requestParams.append('filter[dateType]', transformDateToApiDateType(cityData.date).toString());
    requestParams = requestParams.append('filter[when]', transformWhenDateToApiDateType(cityData.date));
    requestParams = requestParams.append('filter[return]', transformReturnToApiDateType(cityData.date));
    if (pageSize) {
      requestParams = requestParams.append('pageSize', pageSize);
    }

    return this.infoService
      .getCityById(cityData?.cityTo?.id || cityData?.cityID, requestParams)
      .pipe(map(response => ({...cityData, data: response})));
  }

  private prepareUserParams(userData, httpParams = {}): HttpParams {
    let requestParams = new HttpParams();
    requestParams = requestParams.append(
      'expand',
      'picture,iataCodeFrom,places.city.country,places.country,places.city.flights,places.pictures,' +
      'places.city.hasPage,cities.hasPage,places.tags,places.pinned,tags,pinned,placesLimit,homeCity.country,' +
      'countries,places.video,places.descriptionBlocks,urlSetting,contactInfo,followers.picture,placeCreatedCount,' +
      'followers.urlSetting,collectionCount'
    );
    requestParams = requestParams.append('filter[cityFrom]', (userData.cityFrom.id.toString()));
    requestParams = requestParams.append('filter[dateType]', transformDateToApiDateType(userData.date).toString());
    requestParams = requestParams.append('filter[paxCount]', userData.passengers.toString());
    requestParams = requestParams.append('filter[flightType]', transformTripTypeToApi(userData.tripType));
    requestParams = requestParams.append('filter[when]', transformWhenDateToApiDateType(userData.date));
    requestParams = requestParams.append('filter[return]', transformReturnToApiDateType(userData.date));
    Object.keys(httpParams).forEach(key => {
      requestParams = requestParams.append(key, httpParams[key]);
    });

    return requestParams;
  }

  private fetchUserDataById(userData, httpParams = {}) {
    if (!userData || !userData.date || !userData.cityFrom || !userData.id) {
      return of(null);
    }
    let requestParams = this.prepareUserParams(userData, httpParams);

    return this.infoService.getUserById(userData.id, requestParams)
      .pipe(
        map(response => {
            return {
              ...userData,
              data: response
            };
          }
        )
      );
  }

  private fetchUserDataByName(userData, httpParams = {}) {
    if (!userData || !userData.date || !userData.cityFrom || !userData.userName) {
      return of(null);
    }
    let requestParams = this.prepareUserParams(userData, httpParams);

    return this.infoService.getUserByName(userData.userName, requestParams)
      .pipe(
        map(response => {
            return {
              ...userData,
              id: userData.id,
              data: response
            };
          }
        )
      );
  }

  // fetch staticRouteType.COUNTRY
  private fetchCountryData(countryData) {
    let requestParams = new HttpParams();

    requestParams = requestParams
      .append(
        'expand',
        'placesLimit,similarPlacesLimit,capitals.city.hasPage,area,languages,capitals,tags,otype,' +
        'places.pictures,places.user,places.tags,places.pinned,places.city.country,places.country,' +
        'places.city.flights,places.video,places.city.hasPage,pictures,citiesByPlaces,' +
        'similarPlaces.pictures,similarPlaces.user,similarPlaces.tags,similarPlaces.pinned,similarPlaces.city.country,' +
        'similarPlaces.country,similarPlaces.city.flights,similarPlaces.video,similarPlaces.city.hasPage,' +
        'places.user.urlSetting,similarPlaces.user.urlSetting'
      )
      .append('filter[cityFrom]', countryData.cityFrom.id.toString())
      .append('filter[paxCount]', countryData.passengers.toString())
      .append('filter[flightType]', transformTripTypeToApi(countryData.tripType))
      .append('filter[dateType]', transformDateToApiDateType(countryData.date).toString())
      .append('filter[when]', transformWhenDateToApiDateType(countryData.date))
      .append('filter[return]', transformReturnToApiDateType(countryData.date) || '');

    return this.infoService
      .getCountry(countryData.country.id, requestParams)
      .pipe(map(response => ({...countryData, data: response})));
  }

  public fetchGetSimilarPlaces(categoryData, objectData, pageSize = null) {
    let requestParams = new HttpParams();
    requestParams = requestParams
      .append('expand', 'pictures,tags,user,city.country,country,city.flights,city.hasPage,pinned,video,user.urlSetting')
      .append('filter[cityFrom]', categoryData.cityFrom.id.toString())
      .append('filter[paxCount]', categoryData.passengers.toString())
      .append(
        'filter[flightType]',
        transformTripTypeToApi(categoryData.tripType)
      )
      .append(
        'filter[dateType]',
        transformDateToApiDateType(categoryData.date).toString()
      )
      .append(
        'filter[when]',
        transformWhenDateToApiDateType(categoryData.date)
      )
      .append(
        'filter[return]',
        transformReturnToApiDateType(categoryData.date) || ''
      );
    if (categoryData.page) {
      requestParams = requestParams.append('page', categoryData.page.toString());
    }
    const sourceLat = objectData.capitals && objectData.capitals[0]?.city.lat ? objectData.capitals[0].city.lat : objectData.latNorth;
    const sourceLng = objectData.capitals && objectData.capitals[0]?.city.lng ? objectData.capitals[0].city.lng : objectData.lngWest;
    switch (categoryData.type) {
      case StaticRouteType.Experiences:
        if (categoryData.countryId) {
          requestParams = requestParams.append('filter[countryId][neq]', categoryData.countryId.toLowerCase())
            .append('filter[sourceLat]', sourceLat)
            .append('filter[sourceLng]', sourceLng);
        } else {
          return of([])
        }

        if (categoryData.tagId && this.helperService.checkIsNumeric(categoryData.tagId)) {
          requestParams = requestParams.append('filter[tagId]', categoryData.tagId.toString());
        } else {
          return of([])
        }
        break;
      case StaticRouteType.CategoryCountry:
        if (!this.helperService.checkIsCountry(categoryData?.countryId)) {
          return of([]);
        }
        requestParams = requestParams.append('filter[countryId][neq]', categoryData.countryId.toLowerCase())
          .append('filter[sourceLat]', sourceLat)
          .append('filter[sourceLng]', sourceLng);
        if (categoryData.tagId && this.helperService.checkIsNumeric(categoryData.tagId)) {
          requestParams = requestParams.append('filter[tagGroupId]', categoryData.tagId.toString())
        }
        break;
      case StaticRouteType.Country:
        requestParams = requestParams.append('filter[countryId][neq]', categoryData.countryId.toLowerCase())
          .append('filter[sourceLat]', sourceLat)
          .append('filter[sourceLng]', sourceLng);
        break;
      case StaticRouteType.Journey:
        requestParams = requestParams.append('filter[collectionId][neq]', categoryData.collectionId?.toLowerCase());
        break;
      case StaticRouteType.City:
        if (objectData.id && this.helperService.checkIsNumeric(objectData.id)) {
          requestParams = requestParams.append('filter[cityId][neq]', objectData.id.toString());
        }
        requestParams = requestParams
          .append('filter[sourceLat]', objectData.lat.toString())
          .append('filter[sourceLng]', objectData.lng.toString());
        break;
      case StaticRouteType.Review:
      case StaticRouteType.Place:
        if (objectData.id && this.helperService.checkIsNumeric(objectData.id)) {
          requestParams = requestParams.append('filter[placeId][neq]', objectData.id.toString());
        }
        if (objectData.city?.id) {
          requestParams = requestParams.append('filter[cityId][neq]', objectData.city?.id.toString());
        } else if (objectData.country?.id) {
          requestParams = requestParams.append('filter[countryId][neq]', objectData.country?.id.toString());
        }
        requestParams = requestParams
          .append('filter[sourceLat]', objectData.lat.toString())
          .append('filter[sourceLng]', objectData.lng.toString());
        break;
      default:
        break;
    }
    if (pageSize) {
      requestParams = requestParams.append('pageSize', pageSize);
    }
    return this.infoService
      .getPlaceList(requestParams)
      .pipe(
        map(response => ({
          ...categoryData,
          data: response
        }))
      );
  }

  //TODO: refactor
  private fetchCategoryData(categoryData, pageSize = null) {
    let requestParams = new HttpParams();

    requestParams = requestParams
      .append(
        'expand',
        'pictures,tags,iataCodeFrom,user,city.country,country,city.flights,pinned,video,city.hasPage,user.urlSetting'
      )
      .append('filter[cityFrom]', categoryData.cityFrom.id.toString())
      .append('filter[paxCount]', categoryData.passengers.toString())
      .append(
        'filter[flightType]',
        transformTripTypeToApi(categoryData.tripType)
      )
      .append(
        'filter[dateType]',
        transformDateToApiDateType(categoryData.date).toString()
      )
      .append(
        'filter[when]',
        transformWhenDateToApiDateType(categoryData.date)
      )
      .append(
        'filter[return]',
        transformReturnToApiDateType(categoryData.date) || ''
      );

    if (categoryData.page) {
      requestParams = requestParams.append('page', categoryData.page.toString());
    }

    if (!categoryData.isSimilarPlaces) {
      switch (categoryData.type) {
        case StaticRouteType.Experiences:
          if (this.helperService.checkIsNumeric(categoryData.tagId)) {
            requestParams = requestParams
              .append('filter[tagId]', categoryData.tagId.toString());
          } else {
            return of([]);
          }
          if (this.helperService.checkIsCountry(categoryData?.id)) {
            requestParams = requestParams.append('filter[countryId]', categoryData.id.toString());
          } else {
            return of([]);
          }
          break;
        case StaticRouteType.ThingsToDo:
          if (this.helperService.checkIsNumeric(categoryData.id)) {
            requestParams = requestParams
              .append('filter[cityId]', categoryData.id.toString());
          }
          break;
        case StaticRouteType.TagCity:
          if (this.helperService.checkIsNumeric(categoryData.id)) {
            requestParams = requestParams
              .append('filter[cityId]', categoryData.id.toString());
          }
          if (this.helperService.checkIsNumeric(categoryData.tagId) && categoryData.tagId.toString() !== 'undefined') {
            requestParams = requestParams.append('filter[tagId]', categoryData.tagId.toString());
          }
          break;
        case StaticRouteType.CategoryCity:
          if (this.helperService.checkIsNumeric(categoryData.id)) {
            requestParams = requestParams
              .append('filter[cityId]', categoryData.id.toString())
          }
          if (this.helperService.checkIsNumeric(categoryData.tagId)) {
            requestParams = requestParams
              .append('filter[tagGroupId]', categoryData.tagId.toString());
          }
          break;
        case StaticRouteType.CategoryCountry:
          if (this.helperService.checkIsCountry(categoryData?.id)) {
            requestParams = requestParams
              .append('filter[countryId]', categoryData.id.toString())
          }
          if (this.helperService.checkIsNumeric(categoryData.tagId)) {
            requestParams = requestParams
              .append('filter[tagGroupId]', categoryData.tagId.toString());
          }
          break;
        case StaticRouteType.Journey:
          if (this.helperService.checkIsNumeric(categoryData.tagId)) {
            requestParams = requestParams
              .append('filter[collectionId]', categoryData.collectionId.toString());
          }
          break;
        default:
          break;
      }
    } else {
      const countryId = categoryData.countryId && categoryData.countryId.toString().toLowerCase() !== 'undefined'
        ? categoryData.countryId.toString().toLowerCase() :
        null;
      switch (categoryData.type) {
        case StaticRouteType.Country:
          if (this.helperService.checkIsCountry(countryId)) {
            requestParams = requestParams.append('filter[countryId]', countryId);
          }
          break;
        case StaticRouteType.City:
          if (this.helperService.checkIsNumeric(categoryData.routeId)) {
            requestParams = requestParams.append('filter[cityId]', categoryData.routeId.toString());
          }
          break;
        case StaticRouteType.Place:
          if (this.helperService.checkIsNumeric(categoryData.routeId)) {
            requestParams = requestParams.append('filter[placeId][neq]', categoryData.routeId.toString());
          }
          if (categoryData.cityId) {
            requestParams = requestParams.append('filter[cityId]', categoryData.cityId.toString());
          } else if (categoryData.countryId) {
            requestParams = requestParams.append('filter[countryId]', categoryData.countryId.toString());
          }
          break;
        case StaticRouteType.Experiences:
          if (categoryData.tagId && this.helperService.checkIsNumeric(categoryData.tagId)) {
            requestParams = requestParams
              .append('filter[tagId][neq]', categoryData.tagId.toString());
          }
          if (this.helperService.checkIsCountry(countryId)) {
            requestParams = requestParams
              .append('filter[countryId]', countryId);
          }
          break;
        case StaticRouteType.CategoryCountry:
          requestParams = requestParams
            .append('filter[tagGroupId][neq]', categoryData.tagId.toString());
          if (this.helperService.checkIsCountry(countryId)) {
            requestParams = requestParams
              .append('filter[countryId]', countryId);
          }
          break;
        case StaticRouteType.Journey:
          requestParams = requestParams
            .append('filter[collectionId]', categoryData.collectionId.toString());
          break;
        default:
          break;
      }
    }
    if (pageSize) {
      requestParams = requestParams.append('pageSize', pageSize);
    }
    return this.infoService
      .getPlaceList(requestParams)
      .pipe(
        map(response => ({
          ...categoryData,
          data: response
        }))
      );
  }

  private fetchTravelData(httpParams: HttpParams) {
    return this.infoService
      .getPlaceList(httpParams)
      .pipe(
        map(response => ({
          ...httpParams,
          data: response
        }))
      );
  }

  private fetchJourneyData(journeyData, journeyId, pageCount) {
    let requestParams = new HttpParams();
    if (!!journeyData && !journeyData.tagId && !journeyData.cityId && !journeyData.countryId) {
      requestParams = requestParams.set(
        'expand',
        'iataCodeFrom,pictures,user,pinned,tags,city.flights,city.country,city.hasPage,country,video,' +
        'user.urlSetting'
      );
      requestParams = requestParams.set('page', pageCount.toString());
      requestParams = requestParams.set('filter[cityFrom]', journeyData.cityFrom.id.toString());
      requestParams = requestParams.set('filter[paxCount]', journeyData.passengers.toString());
      requestParams = requestParams.set('filter[flightType]', transformTripTypeToApi(journeyData.tripType));
      requestParams = requestParams.set('filter[dateType]', transformDateToApiDateType(journeyData.date).toString());
      requestParams = requestParams.set('filter[when]', transformWhenDateToApiDateType(journeyData.date));
      requestParams = requestParams.set('filter[collectionId]', (Number(journeyId)));
      requestParams = requestParams.set('filter[return]', transformReturnToApiDateType(journeyData.date) || '');

      const queriesCollection = this.route.snapshot.queryParams;

      if (queriesCollection) {
        if (queriesCollection.cityId && this.helperService.checkIsNumeric(queriesCollection.cityId)) {
          requestParams = requestParams.set('filter[cityId]', queriesCollection.cityId);
        }
        if (queriesCollection['tagId'] && this.helperService.checkIsNumeric(queriesCollection['tagId'])) {
          requestParams = requestParams.set('filter[tagId]', queriesCollection['tagId']);
        }
        if (queriesCollection.countryId && this.helperService.checkIsCountry(queriesCollection.countryId)) {
          requestParams = requestParams.set('filter[countryId]', queriesCollection['countryId']);
        }
      }

      return this.infoService.getPlaceList(requestParams)
        .pipe(
          map(response => {
              return {
                ...journeyData,
                data: response
              };
            }
          )
        )
    }

    return of(null);
  }
}
