<div *ngIf="contactInfo && isContactInfoFilled()"
     class="contact-info__container">
  <div class="contact-info__title">Contact info:</div>
  <div class="contact-info__items">
    <div class="contact-info__item" *ngIf="contactInfo?.webSite">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-website.svg')"
           alt="contact-icon">
      <a class="contact-info__link-data"
         target="_blank"
         rel="nofollow noopener"
         [href]="contactInfo.webSite">
        {{contactInfo.webSite}}
      </a>
    </div>
    <div class="contact-info__item" *ngIf="contactInfo?.phone">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-phone.svg')"
           alt="contact-icon">
      <div class="contact-info__data">{{contactInfo.phone}}</div>
    </div>
    <div class="contact-info__item" *ngIf="contactInfo?.email">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-email.svg')"
           alt="contact-icon">
      <div class="contact-info__data">{{contactInfo.email}}</div>
    </div>
    <div class="contact-info__item" *ngIf="contactInfo?.address">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-address.svg')"
           alt="contact-icon">
      <div class="contact-info__data">{{contactInfo.address}}</div>
    </div>
    <div class="contact-info__item" *ngIf="isBusinessHoursExists">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-hours.svg')"
           alt="contact-icon">
      <div [innerHTML]="contactInfo?.businessHours | showSchedule"
           class="contact-info__data"></div>
    </div>
    <div class="contact-info__item" *ngIf="contactInfo?.whatsapp">
      <img class="contact-info__img"
           [src]="constructBaseUrl('/assets/icons/b-whatsapp.svg')"
           alt="contact-icon">
      <a class="contact-info__link-data"
         target="_blank"
         rel="nofollow noopener"
         href="https://wa.me/{{contactInfo.whatsapp}}">
        {{contactInfo.whatsapp}}
      </a>
    </div>
  </div>
</div>
