<p class="collection__title-label">Journey's name</p>
<div class="collection__title-container">
  <div class="collection__title-input">
    <input type="text" #titleRef maxlength="50" [(ngModel)]="newTitleCollection"
           (keydown.enter)="createJourney()">
    <div class="collection__title-btn">
      <div class="collection__title-ok"
           *ngIf="titleRef.value.length"
           (click)="createJourney()">{{btnName.ok}}</div>
      <div class="collection__title-cancel" (click)="cancelJourneyCreation()">f
        <img [src]="constructBaseUrl('/assets/cancel_black.svg')" alt="">
      </div>
    </div>
  </div>
  <p class="input-counter">{{titleRef.value.length}} / 50 characters</p>
</div>
