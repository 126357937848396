<div class="container" *ngIf="!isLoadForm">
  <div class="add-place__container" id="mainDiv">
    <h1 class="add-place__title" *ngIf="!isStepForm()">
      {{ getTitle() }}
    </h1>

    <form [formGroup]="placeForm" class="add-place__form" [class.step-form]="isStepForm()">

      <div #nameContainer class="add-place__form-item" [hidden]="!isBlockVisible(Step.StepOne)">
        <h3>1. Title <span class="symb">&#42;</span></h3>
        <input class="form-control"
               name="name"
               formControlName="name"
               (change)="checkValidationByName('name')"
               required/>
        <ng-container *ngTemplateOutlet="placeErrors.name.message && errorContainer;
         context: {text: placeErrors.name.message}">
        </ng-container>
      </div>

      <div [hidden]="!isBlockVisible(Step.StepOne)">
        <div class="add-place__form-item m-0">
          <h3>2. Photo/Video and text
            <span class="symb" *ngIf="!staticService.editPlace">&#42;</span>
          </h3>
          <label>
            The limit of images is 12 maximum. The limit of videos is 3 maximum.
          </label>
        </div>
        <div #descriptionContainer class="add-place__form-item bordered" *ngFor="let item of description; let i=index">
          <div class="close-button">
            <app-close-btn [disabled]="!canRemoveMediaBlock[i]"
                           (click)="canRemoveMediaBlock[i] && removeMediaBlock(i)"></app-close-btn>
          </div>
          <app-upload-form [maxLength]="maxImages"
                           [length]="limitImages"
                           [accept]="photoAccept"
                           [uploadFiles]="item.pictures"
                           [mobileView]="true"
                           [multiple]="true"
                           [addToProgress]="true"
                           [indexProgress]="i"
                           [isShowProgress]="true"
                           [type]="typeMedia.photo"
                           (errorMessageEmitter)="setErrorMessageEmitter($event, 'photo', i)"
                           (uploadFile)="uploadFile($event, i, 'photo')"
                           (deleteFileEmitter)="deleteFile($event, i, typeMedia.photo)"
                           (updateFilesEmitter)="updateFiles($event, i, typeMedia.photo)">
          </app-upload-form>
          <ng-container *ngIf="placeErrors.descriptions && placeErrors.descriptions[i]?.photo?.message">
            <ng-container *ngTemplateOutlet="errorContainer;
            context: {text: placeErrors.descriptions[i].photo.message, withBackground: true}">
            </ng-container>
          </ng-container>

          <app-upload-form [maxLength]="maxVideos"
                           [length]="limitVideos"
                           [accept]="videoAccept"
                           [uploadFiles]="item.video"
                           [mobileView]="true"
                           [multiple]="false"
                           [addToProgress]="true"
                           [indexProgress]="i"
                           [type]="typeMedia.video"
                           (errorMessageEmitter)="setErrorMessageEmitter($event, 'video', i)"
                           (uploadFile)="uploadFile($event, i, 'video')"
                           (deleteFileEmitter)="deleteFile($event, i, typeMedia.video)"
                           (updateFilesEmitter)="updateFiles($event, i, typeMedia.video)">
          </app-upload-form>
          <ng-container *ngIf="placeErrors.descriptions && placeErrors.descriptions[i]?.video?.message">
            <ng-container *ngTemplateOutlet="errorContainer;
        context: {text: placeErrors.descriptions[i].video.message, withBackground: true}">
            </ng-container>
          </ng-container>

          <ng-container *ngIf="placeErrors.descriptions && placeErrors.descriptions[i]?.media?.message">
            <ng-container *ngTemplateOutlet="errorContainer;
        context: {text: placeErrors.descriptions[i].media.message}">
            </ng-container>
          </ng-container>

          <ng-container *ngIf="isTextEditorVisible(item, i) else description_btn">
            <editor [(ngModel)]="item.text"
                    (ngModelChange)="checkDescriptionValidation(i)"
                    [ngModelOptions]="{standalone: true}"
                    (onInit)="handleDescEditorInit($event)"
                    [licenseKey]="'gpl'"
                    [id]="'wysiwyg-desc' + i"
                    [init]="{
                 height: 400,
                 menubar: false,
                 plugins: 'lists wordcount link autolink',
                 block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
                 base_url: '/tinymce',
                 suffix: '.min',
                 paste_as_text: true,
                 entity_encoding: 'raw',
                 toolbar: 'undo redo | blocks | bold italic | bullist numlist | removeformat | link unlink',
                 link_target_list: false,
                 link_title: false,
                 contextmenu: 'linkchecker image editimage table spellchecker configurepermanentpen'
               }"
            ></editor>

            <ng-container *ngIf="placeErrors.descriptions && placeErrors.descriptions[i]?.message">
              <ng-container *ngTemplateOutlet="errorContainer;
       context: {text: placeErrors.descriptions[i].message}">
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #description_btn>
            <div class="about">
              <p class="edit-item-label" (click)="item.isVisible = true">
                Add a description
              </p>
              Write a text about it ...
            </div>
          </ng-template>

        </div>
        <div class="add-place__form-item bordered btn-content"
             [class.disabled]="!canAddMediaBlock">

          <div class="add-block-content"
               (click)="canAddMediaBlock && addMediaBlock()">
            <img class="add-place__form-item__image"
                 [src]="'/assets/icons/plus_rounded.svg'"
                 alt="add icon">
            <span class="add-place__form-item__span">Add one more block</span>
          </div>

        </div>
      </div>

      <div #locationContainer class="add-place__form-item" [hidden]="!isBlockVisible(Step.StepTwo)">
        <h3>3. Locate this place <span class="symb">&#42;</span></h3>

        <div class="autocomplete">
          <app-autocomplete
            *ngIf="sessionToken"
            [city]="city"
            [lat]="lat"
            [lng]="lng"
            [cannotFindCity]="true"
            [cannotFindInput]="true"
            [showCannotFindCheckbox]="false"
            [selectCountry]="selectCountry"
            [sessionToken]="sessionToken"
            [error]="placeForm.controls['cityId'].invalid && isSubmit ? 'City is required' : ''"
            [addressType]="'geocode'"
            [isSetPlace]="isSetPlace"
            [isSetMarker]="isSetMarker"
            [isGeoPositionEnabled]="!isUpdateForm()"
            inputHelpText="Point the nearest City which this place is belongs"
            mapHeadingText="Pin on the map"
            mapHelpText="Drag the pin to location"
            (selectGoogleCity)="getGoogleCity($event)"
            (setMarkerEmit)="setMarkerEmit($event)"
            (setPlaceEmit)="setPlaceEmit($event)"
            (cannotFindCityEmit)="onCannotFindCityEmit($event)"
            (selectAutocompletePlace)="getGeocodeMarker($event)">
          </app-autocomplete>
        </div>
        <ng-container *ngTemplateOutlet="placeErrors.cityId.message && errorContainer;
         context: {text: placeErrors.cityId.message}">
        </ng-container>
      </div>

      <div #tagContainer class="add-place__form-item select-group position-relative"
           [hidden]="!isBlockVisible(Step.StepThree)">
        <h3>4. Tags</h3>
        <app-hashtag-input [existingTags]="tags"
                           (errorMessageEmitter)="setErrorMessageEmitter($event, 'tag')"
                           (setFormControlEvent)="setFormControlArray($event)"></app-hashtag-input>
        <ng-container *ngTemplateOutlet="placeErrors.tag.message && errorContainer;
         context: {text: placeErrors.tag.message}">
        </ng-container>
      </div>

      <div #journeyContainer class="add-place__form-item select-group position-relative"
           [hidden]="!isBlockVisible(Step.StepThree)">
        <h3>5. Journey</h3>
        <app-collection-input [isProfilePage]="true"
                              [collectionId]="getFormControl('collectionId')?.value"
                              (setFormControlEvent)="setFormControlArray($event)"></app-collection-input>
        <ng-container *ngTemplateOutlet="placeErrors.journey.message && errorContainer;
         context: {text: placeErrors.journey.message}">
        </ng-container>
      </div>

      <div #settingContainer [hidden]="!isBlockVisible(Step.StepThree)">
        <div class="add-place__form-item">
          <h3>6. Settings </h3>
        </div>

        <div class="settings-block">
          <div class="add-place__form-item position-relative">
            <div class="switch-element"
                 (click)="!isShowDateContainer && toggleContainer('isShowDateContainer')">
              <h3 class="switch-element"
                  [class.cursor-pointer]="!isShowDateContainer">Date</h3>
              <label class="switch-element"
                     *ngIf="!isShowDateContainer"
                     [class.cursor-pointer]="!isShowDateContainer">
                Add the date to this page
              </label>
            </div>
            <div (click)="toggleContainer('isShowDateContainer')">
              <ng-container *ngTemplateOutlet="toggleDateContainer"></ng-container>
            </div>

            <ng-container *ngIf="isShowDateContainer">
              <app-fly-date-picker class="search-input-field bold-border"
                                   [openedMonthSelection]="false"
                                   [availableMode]="datePickerMode"
                                   [title]="'Return'"
                                   [value]="datePickerValue"
                                   [withDropDown]="false"
                                   [large]="true"
                                   (requiredMonthChangeEmitter)="onSelectDate($event)"
              ></app-fly-date-picker>
            </ng-container>
          </div>

          <div class="add-place__form-item position-relative">
            <div (click)="!isReviewsDisabledContainer && toggleContainer('isReviewsDisabledContainer')">
              <h3 class="switch-element" [class.cursor-pointer]="!isReviewsDisabledContainer">Hide comments</h3>
              <label class="switch-element" [class.cursor-pointer]="!isReviewsDisabledContainer">
                Turn off reviews on this pages.
              </label>
            </div>

            <div (click)="toggleContainer('isReviewsDisabledContainer')">
              <ng-container *ngTemplateOutlet="toggleReviewsDisabledContainer"></ng-container>
            </div>
          </div>

        </div>
        <ng-container *ngTemplateOutlet="placeErrors.setting.message && errorContainer;
         context: {text: placeErrors.setting.message}">
        </ng-container>
      </div>

      <div #contactContainer [hidden]="!isBlockVisible(Step.StepThree)" class="contact-info__container">
        <div class="add-place__form-item position-relative">
          <h3>Contact info </h3>

          <div (click)="toggleContainer('isShowContactInfoContainer')">
            <ng-container *ngTemplateOutlet="toggleContactInfoContainer"></ng-container>
          </div>
        </div>

        <div class="settings-block" *ngIf="isShowContactInfoContainer && contactInfo">
          <app-contact-info-edit [contactInfo]="contactInfo"
                                 [isContactInfoEnabled]="isShowContactInfoContainer"
                                 (setContactInfoKeyValueEmitter)="setFormControl($event)"></app-contact-info-edit>
        </div>
        <ng-container *ngTemplateOutlet="placeErrors.contactInfo.message && errorContainer;
         context: {text: placeErrors.contactInfo.message}">
        </ng-container>
      </div>

      <div #policyContainer class="add-place__form-item" [hidden]="!isBlockVisible(Step.StepThree)">
        <label class="add-place__privacy-policy">
          <input (change)="checkValidationByName('checkboxCreate')"
                 class="input-checkbox"
                 type="checkbox"
                 formControlName="checkboxCreate"
                 required/>
          <span>By clicking {{ isUpdateForm() ? 'Update' : 'Create'}} Place, you agree to our</span>
          <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>
          <span>and</span>
          <a [routerLink]="['/terms-of-use']" class="margin-none" target="_blank">Terms and Conditions</a>
          <span>.</span>
          <br>
          <span>Please note that it may take some time to be uploaded and fully approved</span>
        </label>

        <ng-container *ngTemplateOutlet="placeErrors.checkboxCreate.message && errorContainer;
         context: {text: placeErrors.checkboxCreate.message}">
        </ng-container>
      </div>

      <div #publishContainer class="add-place__form-item" *ngIf="!isAccountPublic"
           [hidden]="!isBlockVisible(Step.StepThree)">
        <label class="add-place__privacy-policy">
          <input (change)="checkValidationByName('checkboxPublish')"
                 class="input-checkbox"
                 type="checkbox"
                 formControlName="checkboxPublish"
                 required/>
          <span>Make my Personal Page public.</span>
          <span>Turning this 'on' means your
            <a [appRouterLink]="getLinkByObject(staticService.userInfo)"
               style="font-weight: bold;cursor: pointer;"
               target="_blank">Personal Page</a>
            will be visible to Explorow website visitors.</span>
        </label>

        <ng-container *ngTemplateOutlet="placeErrors.checkboxPublish.message && errorContainer;
         context: {text: placeErrors.checkboxPublish.message}">
        </ng-container>
      </div>

      <div class="add-place__form-item submit" *ngIf="!isStepForm()">
        <button class="btn btn-default"
                [disabled]="isSubmit"
                (click)="onSubmit()">
          {{ isUpdateForm() ? 'Update Place' : 'Create' }}
        </button>
        <button
          class="btn btn-default discard"
          (click)="clickDiscard()">
          discard
        </button>
      </div>
    </form>
  </div>
  <div class="btn-container" *ngIf="isStepForm()">
    <ng-container [ngTemplateOutlet]="prevBtn"></ng-container>
    <ng-container [ngTemplateOutlet]="nextBtn"></ng-container>
  </div>

  <div class="popup-toastr"
       *ngIf="isShowToastr" [ngClass]="{'alert-error': errorSubmit}">
    <span *ngFor="let err of alertMessage; let i = index">{{ i + 1 }}. {{ err }}</span>
  </div>
</div>

<ng-template #nextBtn>
  <div class="next-container"
       *ngIf="!isBlockVisible(Step.StepThree)">
    <button class="btn btn-default next-btn m-0"
            [disabled]="ajaxState$ | async"
            (click)="increaseStep()">next
      <img class="next-btn__arrow" [src]="'./assets/icons/arrow-right-white.svg'" alt="">
    </button>
  </div>
  <div class="next-container"
       *ngIf="isBlockVisible(Step.StepThree)">
    <button class="btn btn-default next-btn m-0"
            [disabled]="isSubmit"
            (click)="onSubmit()">
      {{ isUpdateForm() ? 'Update Place' : 'Create' }}
    </button>
  </div>
</ng-template>

<ng-template #prevBtn>
  <div class="prev-container">
    <button class="btn btn-default prev-btn m-0" (click)="decreaseStep()"
            *ngIf="!isBlockVisible(Step.StepOne)">
      <img class="prev-btn__arrow" [src]="'./assets/icons/arrow-right-black.svg'" alt="">
    </button>
  </div>
</ng-template>

<ng-template #toggleDateContainer>
  <ng-container *ngTemplateOutlet="toggleBtnContainer; context: {isOpen: isShowDateContainer}"></ng-container>
</ng-template>

<ng-template #toggleReviewsDisabledContainer>
  <ng-container *ngTemplateOutlet="toggleBtnContainer; context: {isOpen: isReviewsDisabledContainer}"></ng-container>
</ng-template>

<ng-template #toggleContactInfoContainer>
  <ng-container *ngTemplateOutlet="toggleBtnContainer; context: {isOpen: isShowContactInfoContainer}"></ng-container>
</ng-template>

<ng-template #toggleBtnContainer let-isOpen="isOpen">
  <div class="more-arrow">
    <img [src]="isOpen ? './assets/icons/toggle-on2.png' : './assets/icons/toggle-off2.png'" alt="">
  </div>
</ng-template>

<ng-template #errorContainer let-text="text" let-withBackground="withBackground">
  <div class="alert alert-danger" [class.withBackground]="withBackground">{{text}}</div>
</ng-template>

<app-fly-modal-notice *ngIf="isModalNoticeData?.isShow"
                      [title]="isModalNoticeData?.title"
                      [text]="isModalNoticeData?.text"
                      (closeEmit)="goToProfilePlaces()"></app-fly-modal-notice>
