import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UserCollectionService } from '../../services/user-collection.service';
import { PinnedPanelComponent } from '../pinned-panel/pinned-panel.component';
import { SignUpService } from '../../services/sign-up.service';
import { UserService } from '../../services';
import { State } from '../../store/reducers';
import { AuthModalService } from '../../services/auth-modal.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from "@angular/router";
import { StaticService } from "../../services/static.service";
import { TogglePinnedPanel } from "../../store/panels/panels.actions";
import { DEFAULT_TITLE_JOURNEY } from "../../../constants";
import { PinnedModeIdx } from "../../enums/pinned-mode.enum";

@Component({
  selector: 'app-fly-bottom-sheet',
  templateUrl: './fly-bottom-sheet.component.html',
  styleUrls: ['./fly-bottom-sheet.component.scss']
})

export class FlyBottomSheetComponent extends PinnedPanelComponent implements OnChanges, OnDestroy {
  @Input() mode = PinnedModeIdx.Places;
  @Input() isCollectionInfo;
  @Output() closeBottomSheetEvent = new EventEmitter();
  public heightNumber: number;
  public firstHeight: number;
  public height: string = 'auto';
  public isShowBottomSheet;
  public isActive = false;
  public unActivatedTimer: any;
  public defaultTitleCollection = DEFAULT_TITLE_JOURNEY;
  public y = 0;

  public startY = 0;
  public isTopDeltaY: boolean;

  @ViewChild('sheetContainer', {static: true}) sheetContainer: ElementRef;
  @ViewChild('emptyPlaces', {static: true}) emptyPlaces: ElementRef;

  constructor(
    protected store$: Store<State>,
    protected userCollectionService: UserCollectionService,
    protected signUpService: SignUpService,
    protected authModalService: AuthModalService,
    protected router: Router,
    protected userService: UserService,
    protected staticService: StaticService,
  ) {
    super(store$, signUpService, userCollectionService, authModalService, router, userService, staticService);
    this.userCollectionService.isOpenPinPanelFromBottomSheet$.next(false);

    this.userCollectionService.isShowBottomSheet$
      .pipe(
        takeUntil(this.$destroyed)
      )
      .subscribe(value => {
        setTimeout(() => {
          this.isShowBottomSheet = value;
          clearTimeout(this.unActivatedTimer);
          this.toggleActiveBottomSheet(this.isShowBottomSheet);
        }, 200);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isCollectionInfo']) {
      this.height = 'auto';

      this.checkActiveForSimpleBottomSheet();
      if (changes['isCollectionInfo'].currentValue) {
        this.checkHeight();
      }
    }
  }

  onPanStart(): void {
    clearInterval(this.unActivatedTimer);
    this.startY = this.y;
  }

  onPanEnd(event: any): void {
    this.heightToString(event.deltaY, true);
    if (this.isTopDeltaY) {
      this.onSwipe(1);
    }
  }

  onPan(event: any): void {
    this.isTopDeltaY = event.deltaY < 0;
    event.preventDefault();
    this.y = this.startY + event.deltaY;
    this.heightToString(event.deltaY);
  }

  onSwipe(event) {
    if (event) {
      this.height = '85vh';
      setTimeout(() => {
        this.userCollectionService.isOpenPinPanelFromBottomSheet$.next(true);
        this.store$.dispatch(new TogglePinnedPanel({display: true}));
        this.toggleActiveBottomSheet(false);
        this.closeBottomSheetEvent.emit();
      }, 300)
    } else {
      this.toggleActiveBottomSheet(false);
      setTimeout(() => {
        this.userCollectionService.isOpenPinPanelFromBottomSheet$.next(false);
        this.closeBottomSheetEvent.emit();
      }, 300)

    }
  }

  toggleActiveBottomSheet(isActive = false) {
    this.isActive = isActive;
    this.checkActiveForSimpleBottomSheet();
  }

  checkActiveForSimpleBottomSheet() {
    clearInterval(this.unActivatedTimer);
    if (this.isActive) {
      this.unActivatedTimer = setTimeout(() => {
        this.isActive = false;
        this.userCollectionService.isShowBottomSheet$.next(false);
      }, 3000);
    }
  }

  checkHeight() {
    const setBottomSheetHeight = setInterval(() => {
      if (this.userIsLogged && this.defaultCollection) {
        this.heightNumber = this.sheetContainer.nativeElement.offsetHeight.toString();
        clearInterval(setBottomSheetHeight);
      } else if (!this.userIsLogged) {
        this.heightNumber = this.sheetContainer.nativeElement.offsetHeight.toString();
        clearInterval(setBottomSheetHeight);
      }
      this.firstHeight = this.heightNumber;

      this.heightToString();
    }, 400)
  }

  heightToString(additionalHeight = 0, isPanEnd = false) {
    const heightSum = Number(this.heightNumber) - Number(additionalHeight);
    if (isPanEnd) {
      this.heightNumber = heightSum;
      this.height = this.heightNumber + 'px';
    } else {
      this.height = heightSum + 'px';
    }

    if (this.firstHeight * 1.4 < heightSum) {
      this.onSwipe(true);
    } else if (this.firstHeight > heightSum * 1.4) {
      this.onSwipe(false);
    }
  }
}
