import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CloseService {

  public close$ = new Subject();

  constructor() {
  }
}
