import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { constructMediaURL } from "../../../libraries";
import type { VideoInterface } from "../../../interfaces";
import { ToastrService } from "../../../services/toastr.service";
import { SsrService, WindowRef } from "../../../services";
import { HelperService } from "../../../services/helper.service";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnChanges, AfterViewInit {

  @Input() video: VideoInterface;
  @Input() isAutoplay: boolean;
  @Input() isShowGalleryControl: boolean = true;
  @Input() isExpandVisible: boolean = false;

  @Output() showControlEvent = new EventEmitter<boolean>();
  @Output() endVideoEvent = new EventEmitter<void>();
  @Output() expandEmitter = new EventEmitter<void>();

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('notReadyImg') notReadyImg: ElementRef;

  public isViewInit: boolean;
  public isShowControl: boolean;
  public isLargeCurrentPositionProgress: boolean;
  public windowWidth: any;
  public timeHideControl: any;
  public notReadyMessage = 'The video is not ready to watch yet';
  public isMuteVideo: boolean;
  public progressVideo: number;
  public isCanPlay: boolean;
  public constructMediaURL = constructMediaURL;
  public isPlaying = false;

  constructor(
    private toastrService: ToastrService,
    private ssrService: SsrService,
    private windowRef: WindowRef,
    private helperService: HelperService,
  ) {
    if (this.ssrService.isBrowser()) {
      this.windowWidth = windowRef.getNativeWindow();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['video'] && changes['video'].currentValue) {
      this.video = changes['video'].currentValue;
      this.checkIfVideoIsReady();
      this.isCanPlay = false;
      this.progressVideo = 0;
    }
    if (changes['isAutoplay']) {
      this.isShowControl = !changes['isAutoplay'].currentValue;
      this.isPlaying = changes['isAutoplay'].currentValue;
    }
    if (changes['isShowGalleryControl']) {
      this.isShowGalleryControl = changes['isShowGalleryControl'].currentValue;
      this.toggleVisibleControl(this.isShowGalleryControl);
    }
  }

  ngAfterViewInit(): void {
    this.isViewInit = true;
    this.checkIfVideoIsReady();
  }

  private checkIfVideoIsReady(): void {
    if (this.notReadyImg && this.video && !this.video.isReady && this.ssrService.isBrowser()) {
      this.notReadyImg.nativeElement.src = constructMediaURL('/assets/processing.gif');
    }
  }

  showMessage(errorMessage) {
    this.toastrService.activeToastr$.next({message: errorMessage});
  }

  onPlaying() {
    this.isPlaying = true;
  };

  onPause() {
    this.isPlaying = false;
  };

  toggleControlVideo(action?: boolean) {
    if (this.isViewInit) {
      if ((action === true || action === undefined) && this.videoPlayer.nativeElement.paused && !this.isPlaying) {
        return this.videoPlayer.nativeElement.play();
      } else if (!this.videoPlayer.nativeElement.paused && this.isPlaying) {
        this.videoPlayer.nativeElement.pause();
      }
      this.toggleVisibleControl(true);
    }
  }

  toggleVisibleControl(event: boolean) {
    if (this.isShowControl !== event && this.isCanPlay) {
      clearTimeout(this.timeHideControl);
      if (this.videoPlayer.nativeElement.paused) {
        this.isShowControl = true;
      } else {
        this.isShowControl = event;
        if (event) {
          this.timeHideControl = setTimeout(() => {
            this.toggleVisibleControl(false)
          }, 1500);
        }
      }
      this.showControlEvent.emit(this.isShowControl);
    }
  }

  toggleControlVolume() {
    this.isMuteVideo = !this.isMuteVideo;
  }

  onTimeUpdate() {
    const currentTime = this.videoPlayer.nativeElement.currentTime;
    const duration = this.videoPlayer.nativeElement.duration;
    this.progressVideo = (currentTime / duration) * 100;
  }

  onTimeEnd() {
    this.isCanPlay = false;
    this.progressVideo = 0;
    this.endVideoEvent.emit();
    this.isShowControl = true;
  }

  onCanPlay() {
    this.isCanPlay = true;
    this.toggleVisibleControl(true);
  }

  onPanStart(): void {
    this.helperService.isPanRewindVideo = true;
    this.toggleControlVideo(false);
  }

  onPanEnd(): void {
    this.toggleControlVideo(true);
  }

  onPan(event: any): void {
    const videoConfig = this.videoPlayer.nativeElement.getBoundingClientRect();
    const videoStart = videoConfig.x;
    const videoWidth = videoConfig.width;
    const videoEnd = videoStart + videoWidth;

    const mouseX = event.center.x;
    if (mouseX > videoStart && mouseX < videoEnd) {
      const currentVideoLength = mouseX - videoStart;
      const timeInPercent = currentVideoLength * 100 / videoWidth;
      const duration = this.videoPlayer.nativeElement.duration;
      this.videoPlayer.nativeElement.currentTime = timeInPercent * duration / 100
      this.onTimeUpdate();
    }
  }

  setTime(event) {
    if (this.helperService.isPanRewindVideo) {
      this.helperService.isPanRewindVideo = false;
    } else {
      const videoConfig = this.videoPlayer.nativeElement.getBoundingClientRect();
      const videoWidth = videoConfig.width;
      const currentVideoLength = event.offsetX;
      const timeInPercent = currentVideoLength * 100 / videoWidth;
      const duration = this.videoPlayer.nativeElement.duration;
      this.videoPlayer.nativeElement.currentTime = timeInPercent * duration / 100
      this.onTimeUpdate();
    }
  }

  toggleCurrentPositionProgress(event) {
    this.isLargeCurrentPositionProgress = event;
  }

  public isMobileDesign(): boolean {
    return this.ssrService.isBrowser() && this.windowWidth.innerWidth <= 896;
  }

  expand(): void {
    this.expandEmitter.emit()
  }
}
