import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactInfoEnum } from "../../../../interfaces/contact-info";

@Component({
  selector: 'app-edit-contact-input',
  templateUrl: './edit-contact-input.component.html',
  styleUrls: ['./edit-contact-input.component.scss']
})
export class EditContactInputComponent implements OnInit {
  @Input() textType: string;
  @Input() data: string;
  @Input() type: ContactInfoEnum;

  @Output() setContactInfoEmitter = new EventEmitter<{ type: ContactInfoEnum, data: any }>();
  @Output() toggleContactFormEmitter = new EventEmitter<{ type: ContactInfoEnum }>();

  public oldValue: string = '';

  ngOnInit() {
    this.oldValue = this.data ? this.data : '';
  }

  setContactInfo(value: any) {
    if (this.oldValue !== value) {
      this.setContactInfoEmitter.emit({type: this.type, data: value});
      this.oldValue = value;
    } else {
      this.toggleContactForm();
    }
  }

  toggleContactForm() {
    this.toggleContactFormEmitter.emit({type: this.type});
  }
}
