import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { loggedIn, State } from "../../../store/reducers";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { StaticService } from "../../../services/static.service";
import { MetaTagsService } from "../../../services";
import { constructMediaURL } from "../../../libraries";

@Component({
  selector: 'app-landing-travel-business',
  templateUrl: './landing-travel-business.component.html',
  styleUrls: ['./landing-travel-business.component.scss']
})
export class LandingTravelBusinessComponent implements OnInit, OnDestroy {

  public isLoggedUser = false;
  private $destroyed = new Subject<void>();

  public benefits = [
    {
      actionTitle: "add a",
      title: "Travel Spot",
      text: "Attraction spots are small windows, pinned on the map. It could be any places from your tour or landmarks that are part of your trip. Clients see exactly where they'll go, instantly.",
      linkText: 'BEGIN NOW',
      video: "https://assets-as.explorow.com/video/promo/Travel-Spots.mp4"
    },
    {
      actionTitle: "make your own",
      title: "Business Page",
      text: "Stand out and bring in new customers with a dedicated business page on our interactive travel map. Connect with your target audience.",
      linkText: 'REGISTER NOW',
      video: "https://assets-as.explorow.com/video/promo/Business-Page2.mp4"
    },
    {
      actionTitle: "create a",
      title: "Guided Tour",
      text: "A guided tour is like a roadmap for your attraction spots, all connected to each other. It’s informative, it makes it easier to navigate, and inspires travelers.",
      linkText: 'START NOW',
      video: "https://assets-as.explorow.com/video/promo/Guided-Tour.mp4"
    },
  ];

  constructor(
    private store$: Store<State>,
    private readonly router: Router,
    public staticService: StaticService,
    private metaTagsService: MetaTagsService,
  ) {
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.store$.pipe(
      select(loggedIn),
      takeUntil(this.$destroyed)
    ).subscribe(value => this.isLoggedUser = value);
    this.metaTagsService.resolver({
      route: 'promo'
    });
  }

  public redirectToAccount() {
    this.router.navigate(['/account']);
  }

  protected readonly constructMediaURL = constructMediaURL;
}
