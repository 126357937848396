<ng-container
  *ngIf="notifications?.items?.length || !userNotificationService.isInitialized; then notification_list; else empty;">
</ng-container>

<ng-template #notification_list>
  <div class="modal-notification fly-beauty-scroll fix-scroll">
    <ng-scrollbar [orientation]="'vertical'">
      <h2>Notifications</h2>
      <div class="notification" *ngFor="let notification of notifications?.items">
        <img alt="photo"
             (click)="clickItem(notification)"
             [appBasesrc]="notification.userPicture?.thumb
                   ? constructMediaURL(notification.userPicture?.thumb)
                   : this.defaultUserImage">
        <div (click)="clickItem(notification)"
             [innerHTML]="notification.message"
             [style.-webkit-line-clamp]="4"></div>
      </div>
    </ng-scrollbar>
  </div>
</ng-template>

<ng-template #empty>
  <div class="empty">
    <h2>Notifications</h2>
    You're all caught up; no notifications at the moment.
  </div>
</ng-template>
