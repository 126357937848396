import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { GuideTooltipService } from "../../services/guide-tooltip.service";
import { GuideElementsEnum } from "../../enums/guide-elements.enum";
import { SsrService } from "../../services";

@Component({
  selector: 'app-guide-tooltip',
  templateUrl: 'guide-tooltip.component.html',
  styleUrls: ['guide-tooltip.component.scss']
})
export class GuideTooltipComponent implements OnDestroy, OnInit {

  @Input() pageObject: GuideElementsEnum;
  @Output() changeStateEmitter = new EventEmitter<boolean>();

  public tooltipText: string = '';
  private $destroyed = new Subject<void>();

  constructor(
    private guideTooltipService: GuideTooltipService,
    private ssrService: SsrService,
  ) {
  }

  ngOnInit() {
    if (this.ssrService.isBrowser()) {
      this.guideTooltipService.tooltipButton$
        .pipe(
          takeUntil(this.$destroyed)
        )
        .subscribe((currentElement: GuideElementsEnum | null): void => {
          if (this.guideTooltipService.isTooltipRequired && this.pageObject === currentElement) {
            this.tooltipText = this.guideTooltipService.getTooltipText();
          } else {
            this.tooltipText = '';
          }
          this.changeStateEmitter.emit(this.tooltipText !== '');
        });
    }
  }

  ngOnDestroy() {
    this.tooltipText = '';
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public hideTip(): void {
    this.tooltipText = '';
  }
}
