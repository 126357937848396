<div class="pin-it-container">
  <ng-container *ngIf="isVisibleTextBtn; else noVisibleText">
    <div class="visibleTextBtn button-pin-place tooltip-event-item"
         (click)="togglePopup()">
      <ng-container *ngTemplateOutlet="likeContainer; context: {type: typeLikeContainer.visible}"></ng-container>
      <span>{{isLiked ? 'Liked' : 'Like it'}}</span>
    </div>
  </ng-container>

  <ng-template #noVisibleText>
    <div class="pin-it static-place-item-pin"
         [class.position-relative]="isTitlePosition"
         (click)="togglePopup()">
      <ng-container *ngTemplateOutlet="likeContainer; context: {type: typeLikeContainer.noVisible}"></ng-container>
    </div>

    <ng-container *ngIf="userIsLogged; else noAuthForm">
      <div class="pin-tooltip" [class.visible]="isShowTooltip" *ngIf="defaultJourney">
        <div class="empty-places" (click)="toggleShowTooltip(false)"></div>
        <div class="tooltip-content">Removed from {{defaultJourney.name || defaultTitleCollection}}</div>
      </div>
    </ng-container>
    <ng-template #noAuthForm>
      <div class="pin-tooltip" [class.visible]="isShowTooltip">
        <div class="empty-places" (click)="toggleShowTooltip(false)"></div>
        <div class="tooltip-content">Removed from Unnamed collection</div>
      </div>
    </ng-template>
  </ng-template>

  <div class="popup-container"
       *ngIf="isShowPopup">
    <app-fly-popup-profile
      [data]="popupData"
      [isAddPopup]="true"
      [maxWidth]="true"
      [moveToRight]="movePinToRight"
      (emitAction)="emitAction($event)"></app-fly-popup-profile>
  </div>
</div>

<ng-template #likeContainer let-type="type">
  <img *ngIf="pinState && !isLiked else checkLiked"
       [src]="constructBaseUrl(type === typeLikeContainer.noVisible ? '/assets/pin/active-pin.svg' : '/assets/pin/pinit-full-title.svg')"
       [class.m-0]="type === typeLikeContainer.noVisible"
       alt="liked">
  <ng-template #checkLiked>
    <img *ngIf="isLiked else unLiked"
         [src]="constructBaseUrl(type === typeLikeContainer.noVisible ? '/assets/icons/liked-icon-on.svg' : '/assets/pin/fav-dark-on.svg')"
         alt="liked">
    <ng-template #unLiked>
      <img
        [src]="constructBaseUrl(type === typeLikeContainer.noVisible ? '/assets/icons/liked-icon-off.svg' : '/assets/pin/fav-dark-off.svg')"
        alt="unLiked">
    </ng-template>
  </ng-template>
</ng-template>
