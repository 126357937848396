import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-dest-search-dropdown',
  templateUrl: 'dest-search-dropdown.component.html',
  styleUrls: ['../../../styles/mat-styles.scss', 'dest-search-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DestSearchDropdownComponent implements OnInit, OnChanges {

  @Input() offset = 0;
  @Input() open = false;
  @Input() wrapper: Element;

  @Output() closeEmitter: EventEmitter<undefined> = new EventEmitter();

  constructor(
    private el: ElementRef,
  ) {
  }

  ngOnInit() {
    this.onResize();
  }

  ngOnChanges() {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    setTimeout(() => {
      if (this.wrapper) {
        this.el.nativeElement.style.width = this.wrapper.clientWidth - this.offset + 'px';
      }
    }, 50);
  }

  onClose() {
    this.closeEmitter.emit();
  }
}
