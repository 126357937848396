import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user-token.service';
import { AuthService } from '../../services/auth.service';
import { doAsync } from '../../libraries/do-async';
import { consoleDebug } from '../../libraries/console-debug';
import { select, Store } from '@ngrx/store';
import { getCurrentCoordinates, State } from '../../store/reducers';
import { catchError, map, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SsrService, TransferKeys } from '../../services/ssr.service';
import { Location } from '@angular/common';
import { StaticService } from "../../services/static.service";
import { BaseHeaderComponent } from "../shared/base-header/base-header.component";

@Component({
  selector: 'app-auth-external',
  templateUrl: './auth-external.component.html',
  styleUrls: [
    './auth-external.component.scss',
    '../../styles/search-panel-shared.scss',
  ]
})
export class AuthExternalComponent extends BaseHeaderComponent implements OnInit {

  public error: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    protected store$: Store<State>,
    protected ssrService: SsrService,
    private location: Location,
    private router: Router,
    public staticService: StaticService,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(store$, cdRef, ssrService);
  }

  ngOnInit() {
    if (!this.ssrService.isSSR() && this.ssrService.hasState(TransferKeys.Status)) {
      this.error = this.ssrService.getState('error');
      return;
    }

    const params = this.route.snapshot.queryParams;
    const urlReferrer: any = params.token || '';

    if (params.error) {
      this.error = 'Access denied';
      return;
    }

    const config = {
      'authClient': params.authClient,
      'code': params.code,
      'state': params.state,
      'urlReferrer': urlReferrer,
      'adsChannel': this.getAdsChannel(),
    };

    this.store$.pipe(
      select(getCurrentCoordinates),
      take(1),
      map((coordinates: any) => {
        const extraConfig = (coordinates)
          ? {lat: coordinates.lat, lng: coordinates.lng}
          : {};
        return this.authService.saveAuthExternal({
          ...config, ...extraConfig
        }).toPromise();
      }),
      catchError((err) => throwError(err))
    ).subscribe({
        next: (success) => {
          success
            .then((data) => {
              this.userService.shouldRemember(true);
              this.userService.setToken(data);
              switch (true) {
                case this.isPreviousUrlPromo():
                  doAsync(() => this.router.navigate(['/account/profile']), 500);
                  break;
                default:
                  doAsync(() => this.router.navigate(['/account'], {queryParams: {view: 'settings'}}), 500);
                  break;
              }
            })
            .catch((error) => {
              this.error = error.error.message || error.error[0].message;
              this.ssrService
                .setResponseStatusCode(400)
                .setState('error', this.error);
            });
        },
        error: (error) => {
          consoleDebug('ERROR SIGNUP', error);
        }
      }
    );
  }

  private getAdsChannel(): string | null {
    const previousUrl = localStorage.getItem('previousUrl');
    if (previousUrl) {
      try {
        return new URL(previousUrl).searchParams.get('ck');
      } catch (e) {
      }
    }

    return null;
  }

  private isPreviousUrlPromo(): boolean {
    const previousUrl = localStorage.getItem('previousUrl');

    return previousUrl
      && (previousUrl.indexOf('/help/travel-business-page') > -1 || previousUrl.indexOf('/help/travel-business') > -1);
  }
}
