import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user-token.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-unlink-social-account',
  templateUrl: 'unlink-social-account.component.html',
  styleUrls: [
    '../link-social-account/link-social-account.component.scss',
    'unlink-social-account.component.scss'
  ]
})
export class UnlinkSocialAccountComponent implements OnInit {

  @Input() auth: any[];
  @Output() unlinkEmitter: EventEmitter<any> = new EventEmitter<any>();

  public errorMessage: string;
  public logOutFB: boolean = false;
  public logOutGoogle: boolean = false;
  public socialFB: boolean;
  public socialGoogle: boolean;
  public socialKeys: string[];

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.socialKeys = Object.keys(this.auth);
    this.socialGoogle = this.findAuthSocial('google');
    this.socialFB = this.findAuthSocial('facebook');
    this.logOutFB = this.showLogOut('facebook');
    this.logOutGoogle = this.showLogOut('google');
  }

  showLogOut(social) {
    let netWork = this.getSocial(social);
    return netWork.url == null;
  }

  unlinkGoogle(): void {
    let google = this.getSocial('google');
    this.unlink(google);
  }

  unlinkFB(): void {
    let facebook = this.getSocial('facebook');
    this.unlink(facebook);
  }

  unlink(social: any) {
    let authId: string = social.id;

    this.authService.deleteAuthExternal(authId).subscribe(
      () => {
        setTimeout(() => {
          this.unlinkEmitter.emit(this);
          this.router.navigate(['/account'], {queryParams: {view: 'settings'}});
        }, 50);
      },
      (error) => {
        this.handleErrors(error);
      }
    );
  }

  handleErrors(error) {
    if (error.status === 204) {
      this.unlinkEmitter.emit(true);
      return;
    }
    this.userService.disableSubmitForWhile(this);
    this.errorMessage = this.errorGetMessage(error);

  }

  errorGetMessage(error) {
    return error.error.message || error.error[0].message;
  }

  getSocial(social: any) {
    return this.auth[social] || this.auth.find((param) => param.name === social);
  }

  findAuthSocial(social) {
    if (!this.auth) return false;
    return (this.auth[social])
      ? (this.auth[social].status > 0)
      : this.auth.some((s) => {
        return s.status > 0 && s.name === social;
      });
  }

}
