<div class="mode-selector">
  <div class="selector"
       *ngIf="availableMode.includes(DatePickerMode.Month)"
       [ngClass]="{'active':mode===DatePickerMode.Month}"
       (click)="setMode(DatePickerMode.Month)">
    <mat-icon class="icon">calendar_today</mat-icon>
    <div class="title">Just <br/> month</div>
  </div>
  <div class="selector"
       *ngIf="availableMode.includes(DatePickerMode.Date)"
       [ngClass]="{'active':mode===DatePickerMode.Date}"
       (click)="setMode(DatePickerMode.Date)">
    <mat-icon class="icon">date_range</mat-icon>
    <div class="title">Specific <br/> date</div>
  </div>
</div>
