<input #image
       id="image"
       hidden="true"
       type="file"
       [attr.multiple]="multiple ? 'true' : null"
       (click)="onClickInput($event)"
       (change)="addImage($event)"
       [accept]="accept"/>

<ng-container *ngIf="!mobileView && !isEditForm">
  <ng-container *ngTemplateOutlet="selectButton"></ng-container>
</ng-container>

<app-upload-progress *ngIf="isShowProgress" [index]="indexProgress"></app-upload-progress>

<div class="preview"
     *ngIf="mobileView || uploadFiles?.length > 0"
     [ngClass]="{'hide-section': isProfile}">
  <ul class="file-list" [class.pr-0]="mobileView">
    <ng-container *ngIf="mobileView; then mobileContainer; else defaultContainer"></ng-container>
    <ng-template #defaultContainer>
      <ng-container *ngIf="isEditForm">
        <div class="mobile-view__header">
          <div class="mobile-view__header__title">
            <img class="mobile-view__header__img"
                 src="/assets/icons/{{isPhoto ? 'photos.svg' : 'videos.svg'}}"
                 alt="Add {{isPhoto ? 'photo' : 'video'}}">
            <h4>{{isPhoto ? 'Photos' : 'Videos'}}</h4>
          </div>
          <div class="mobile-view__header__help-text">
            <p>Max. {{length}} {{isPhoto ? 'photos' : 'videos'}}</p>
          </div>
        </div>

        <ng-container *ngTemplateOutlet="selectButton"></ng-container>
      </ng-container>

      <li *ngFor="let file of uploadFiles; let i = index" [ngSwitch]="file.mimeType">
        <div class="image-preview">
          <span *ngSwitchCase="'application/msword'" (click)="toggleGallery(true, i)">
            <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
          </span>

          <span *ngSwitchCase="'application/pdf'" (click)="toggleGallery(true, i)">
            <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
          </span>

          <span *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                (click)="toggleGallery(true, i)">
            <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
          </span>

          <span *ngSwitchDefault (click)="toggleGallery(true, i)">
              <img class="preview-img"
                   [src]="constructMediaURL(file.urlThumbnail
                   ? file.urlThumbnail
                   : file.url &&
                          file.url ||
                          !isPhoto && './assets/images/default-video-thumbnail.jpg' ||
                          isPhoto && './assets/images/default.jpg')" alt="">
          </span>

          <span class="file-name">{{file.name}}</span>
        </div>

        <ng-container *ngTemplateOutlet="deleteFileContainer; context: {file: file}"></ng-container>
      </li>
    </ng-template>
    <ng-template #mobileContainer>
      <div class="mobile-view__header">
        <div class="mobile-view__header__title">
          <img class="mobile-view__header__img"
               src="/assets/icons/{{isPhoto ? 'photos.svg' : 'videos.svg'}}"
               alt="Add {{isPhoto ? 'photo' : 'video'}}">
          <h4>{{isPhoto ? 'Photos' : 'Videos'}}</h4>
        </div>
        <div class="mobile-view__header__help-text">
          <p>Max. {{length}} {{isPhoto ? 'photos' : 'videos'}}</p>
        </div>
      </div>

      <ng-container *ngTemplateOutlet="selectButton"></ng-container>

      <div class="dragndrop-tip" *ngIf="uploadFiles.length > 1">
        Hold and drag <img [src]="'/assets/icons/dragndrop.svg'" alt="dragndrop"> to reorder
      </div>

      <div class="mobile-view" [class.single-media]="uploadFiles.length===1" cdkDropListGroup>
        <ng-container *ngFor="let file of uploadFiles; let i = index" [ngSwitch]="file.mimeType">
          <div cdkDropList cdkDropListOrientation="horizontal"
               [cdkDropListData]="{item:file,index:i}"
               (cdkDropListDropped)="drop($event)">
            <div class="image-preview" cdkDrag>
              <span *ngSwitchCase="'application/msword'" (click)="toggleGallery(true, i)">
                <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
              </span>
              <span *ngSwitchCase="'application/pdf'" (click)="toggleGallery(true, i)">
                <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
              </span>

              <span *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                    (click)="toggleGallery(true, i)">
                <img class="preview-img" [src]="'./assets/icons/document-icon.png'" alt="">
              </span>

              <span class="image-single-preview" *ngSwitchDefault (click)="toggleGallery(true, i)">
                <img class="preview-img"
                     [src]="constructMediaURL(file.urlThumbnail
                     ? file.urlThumbnail
                     : file.url
                      ? file.url
                     : file?.poster?.thumb
                      ? file?.poster?.thumb
                      : file.thumb
                        && file.thumb
                        || !isPhoto && './assets/images/default-video-thumbnail.jpg'
                        || isPhoto && './assets/images/default.jpg')" alt="">
              <ng-container *ngTemplateOutlet="deleteFileContainer; context: {file: file}"></ng-container>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ul>
</div>

<app-static-gallery
  *ngIf="isOpenGallery && !isProfile"
  [gallery]="uploadFiles"
  [windowWidth]="windowSize"
  [activeIndex]="activeIndex"
  [isCopyrightVisible]="false"
  (closeGallery)="toggleGallery($event, 0)">
</app-static-gallery>

<ng-template #selectButton>
  <button class="btn" mat-flat-button color="primary"
          *ngIf="!buttonImage"
          [disabled]="!isFileLoaded || !length"
          [ngClass]="{'profile-image': isProfile}"
          (click)="image.click()">
    {{ buttonText ? buttonText : 'Select file ...'}}
  </button>
  <img class="profile-image-button" alt="pic"
       *ngIf="buttonImage"
       [src]="buttonImage"
       (click)="image.click()">
</ng-template>

<ng-template #deleteFileContainer let-file="file">
            <span class="delete-file" (click)="deleteFile(file)">
              <img alt="close" class="close-popup-img" title="close" src="/assets/cross.svg">
            </span>
</ng-template>
