import { AfterViewInit, Directive, ElementRef, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appMoreText]'
})
export class MoreTextDirective implements AfterViewInit {
  @Input() maxLine!: number;
  @Input() template: TemplateRef<any> | HTMLDivElement;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.maxLine) {
      const hostElem = this.el.nativeElement;
      const {offsetHeight} = hostElem;

      const count = MoreTextDirective.lineCount(hostElem, offsetHeight);

      if (this.maxLine < count) {
        this.initHTML(hostElem);
      } else if (this.template) {
        (this.template as any).remove();
      }
    }
  }

  public static lineCount(el: any, height: number): number {
    let lineHeight = MoreTextDirective.getStylePropertyValue(el, 'line-height');

    if (!(typeof +lineHeight === 'number' && +lineHeight > 0)) {
      lineHeight = '20';
    }
    return Math.floor(height / (+lineHeight));
  }

  private initHTML(el: any): void {
    el.style.setProperty('position', 'relative');
    el.style.setProperty('display', '-webkit-box');
    el.style.setProperty('-webkit-line-clamp', this.maxLine);
    el.style.setProperty('-webkit-box-orient', 'vertical');
    el.style.setProperty('word-break', 'break-word');
    el.style.setProperty('overflow', 'hidden');

    const t = this.template as any;
    t.style.setProperty('position', 'absolute');
    t.style.setProperty('bottom', '0');
    t.style.setProperty('right', '0');
    t.style.setProperty('padding-left', '2px');

    let bgColor = MoreTextDirective.getStylePropertyValue(el, 'background-color');

    if (bgColor === 'rgba(0, 0, 0, 0)') {
      bgColor = 'white';
    }

    t.style.setProperty('background-color', bgColor);

    this.template && el.appendChild(this.template);
  }

  private static getStylePropertyValue(el: any, property: string): string {
    // ssr compatibility
    if (typeof getComputedStyle !== 'function') {

      return 'rgba(0, 0, 0, 0)';
    }
    const styles = getComputedStyle(el);

    return styles.getPropertyValue(property);
  }
}
