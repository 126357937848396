import { Injectable } from '@angular/core';
import { StaticRouteType } from "../modules/static/enums/route-type";
import { StaticService } from "./static.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { select, Store } from "@ngrx/store";
import { getCollectionList, getSearchData } from "../store/reducers";
import { map, mergeMap, take } from "rxjs/operators";
import { StaticInitService } from "../modules/static/services/static-init.service";
import { SsrService } from "./ssr.service";
import { of } from 'rxjs/internal/observable/of';
import { HttpParams } from "@angular/common/http";
import {
  AjaxFinish,
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from "../store/layer";
import { ActivatedRoute, Router } from "@angular/router";
import { DestinationType } from "../enums/destination-type";
import { doAsync } from "../libraries";
import { PlaceNew } from "../interfaces";
import { UserCollectionService } from "./user-collection.service";
import { Subject } from 'rxjs/internal/Subject';
import { HelperService } from "./helper.service";
import { ErrorEnum } from "../enums/error.enum";
import { ToastrService } from "./toastr.service";
import { StaticTransferStateKey } from "../enums/static-transfer-state-key.enum";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class StaticContentService {

  public staticRouteType = StaticRouteType;
  private data: any;
  public info: any;
  public userData: any;
  public countries = [];
  public cities = [];
  public categories = [];
  public staticType: StaticRouteType;
  public isErrorCollection: boolean;
  public $data: Subject<any> = new Subject<any>();

  constructor(
    private staticService: StaticService,
    private store$: Store,
    private ssrService: SsrService,
    private staticInitService: StaticInitService,
    private activatedRoute: ActivatedRoute,
    private userCollectionService: UserCollectionService,
    private router: Router,
    private helperService: HelperService,
    private toastrService: ToastrService,
  ) {
    this.store$.pipe(
      untilDestroyed(this),
      select(getSearchData)
    ).subscribe(searchData => this.staticService.searchData = searchData);
  }

  //TODO: refactor
  public onScrollDown(): void {
    switch (this.staticService.staticType) {
      case this.staticRouteType.Experiences:
      case this.staticRouteType.ThingsToDo:
      case this.staticRouteType.TagCity:
      case this.staticRouteType.CategoryCity:
      case this.staticRouteType.CategoryCountry:
        if (this.staticService.mainPageCount && this.staticService.mainPageCount > this.staticService.pageCount) {
          this.getSubStaticPagesInfo(
            null,
            this.staticService.subPlaceId.toString(),
            this.staticService.tagGroupId,
            this.staticService.pageCount
          );
        } else if ((this.staticService.similarPageCount === 0 && this.staticService.pageCount === 0)
          || (this.staticService.similarPageCount && this.staticService.similarPageCount > this.staticService.pageCount)
        ) {
          if (
            this.staticService.staticType === this.staticRouteType.Experiences ||
            this.staticService.staticType === this.staticRouteType.CategoryCountry
          ) {
            this.staticService.getSubSimilarPlaces();
          } else {
            this.staticService.getSimilarPlaces();
          }
        } else {
          if (
            this.staticService.staticType === this.staticRouteType.Experiences
            || this.staticService.staticType === this.staticRouteType.CategoryCountry
          ) {
            if (this.staticService.pageCount > this.staticService.currentThirdRequestCount) {
              this.staticService.getSimilarPlacesRequest();
            }
          }
        }
        break;
      case this.staticRouteType.Place:
      case this.staticRouteType.Review:
      case this.staticRouteType.City:
      case this.staticRouteType.Journey:
      case this.staticRouteType.Country:
        if (this.staticService.staticType === StaticRouteType.Journey && this.staticService.totalCount > 0) {
          break;
        }
        if (
          this.staticService.totalCount > this.staticService.placeItemsList.length
          || this.staticService.similarPageCount && this.staticService.similarPageCount > this.staticService.pageCount
        ) {
          this.staticService.getSubSimilarPlaces();
        } else if (this.staticService.pageCount > this.staticService.currentThirdRequestCount) {
          // Load similar places after loading the all places for top block
          this.staticService.getSimilarPlacesRequest();
        }
        break;
      case this.staticRouteType.Search:
        if (this.staticService.totalCount > this.staticService.placeItemsList.length) {
          this.getSearchSimilarPlaces();
        }
        break;
      case this.staticRouteType.Travel:
        if (this.staticService.totalCount > this.staticService.placeItemsList.length) {
          this.getStaticTypesData(
            this.staticService.routeParams.id,
            this.staticService.routeParams.typeId,
            false
          );
        }
        break;
      default:
        break;
    }
  }

  protected applyCommonQueryParams(httpParams: HttpParams) {
    httpParams = httpParams.set('page', this.staticService.pageCount.toString());
    if (this.staticService.isMobileDesign()) {
      httpParams = httpParams.set('pageSize', this.staticService.placesLimitMobile);
    }
    httpParams = httpParams.set(
      'filter[cityFrom]',
      this.staticService.searchData.cityFrom ? this.staticService.searchData.cityFrom.id.toString() : ''
    );
    httpParams = httpParams.set('filter[paxCount]', this.staticService.searchData.passengers.toString());
    httpParams = httpParams.set('filter[flightType]', transformTripTypeToApi(this.staticService.searchData.tripType));
    httpParams = httpParams.set('filter[dateType]', transformDateToApiDateType(this.staticService.searchData.date).toString());
    httpParams = httpParams.set('filter[when]', transformWhenDateToApiDateType(this.staticService.searchData.date));
    httpParams = httpParams.set('filter[return]', transformReturnToApiDateType(this.staticService.searchData.date) || '');

    return httpParams;
  }

  protected fixBadOldUrls(id, typeId) {
    // fix of bad OLD urls like: /travel/outdoor_activities_worldwide/89/5%3FcityId%3D2090
    if (typeId && (typeId.toString().includes('?') || typeId.toString().includes('%'))) {
      try {
        const idWithQuery = this.staticService.decodeURI(typeId.toString());
        if (idWithQuery) {
          this.ssrService.redirect(301, `/travel/` + this.staticService.routeParams.name + `/` + id + `/` + idWithQuery);
          return;
        }
      } catch (error) {
        this.toastrService.activeToastr$.next({message: ErrorEnum.linkNotValid});
      }
    }
  }

  // INIT StaticRouteType.TRAVEL:
  public getStaticTypesData(
    id: string,
    typeId: DestinationType,
    isInitLoad: boolean
  ): void {
    this.helperService.checkIsNumeric(id);
    this.fixBadOldUrls(id, typeId);

    let travelStateKey = `${StaticTransferStateKey.TravelInfoKey}_${id}_${typeId}_${this.staticService.pageCount.toString()}`;
    let httpParams = new HttpParams();
    this.staticService.pageCount++;

    httpParams = httpParams.set(
      'expand',
      'iataCodeFrom,pictures,user,pinned,tags,city.flights,city.country,country,video,city.hasPage,user.urlSetting'
    );
    httpParams = this.applyCommonQueryParams(httpParams);

    switch (Number(typeId)) {
      case DestinationType.PoI:
        if (id && this.helperService.checkIsNumeric(id)) {
          httpParams = httpParams.set('filter[poiId]', id.toString());
        }
        break;
      case DestinationType.Destination:
        if (id && this.helperService.checkIsNumeric(id)) {
          httpParams = httpParams.set('filter[destId]', id.toString());
        }
        break;
      case DestinationType.Tag:
        if (id && this.helperService.checkIsNumeric(id)) {
          httpParams = httpParams.set('filter[tagId]', id.toString());
        }
        break;
      case DestinationType.AllPlaceCity:
        if (
          this.staticService.routeParams.id
          && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
        ) {
          httpParams = httpParams.set('filter[poiId]', this.staticService.routeParams.id.toString());
        }
        if (
          this.staticService.routeParams.allPlacesId
          && this.helperService.checkIsNumeric(this.staticService.routeParams.allPlacesId)
        ) {
          httpParams = httpParams.set('filter[cityId]', this.staticService.routeParams.allPlacesId.toString());
        }
        break;
      case DestinationType.AllPlaceCountry:
        if (
          this.staticService.routeParams.id
          && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
        ) {
          httpParams = httpParams.set('filter[poiId]', this.staticService.routeParams.id.toString());
        }
        if (
          this.staticService.routeParams.allPlacesId
          && this.helperService.checkIsCountry(this.staticService.routeParams.allPlacesId)
        ) {
          httpParams = httpParams.set('filter[countryId]', this.staticService.routeParams.allPlacesId.toString());
        }
        break;
    }

    const queries = this.activatedRoute.snapshot.queryParams;

    if (queries) {
      if (queries['cityId'] && this.helperService.checkIsNumeric(queries['cityId'])) {
        const cityIdParam = queries['cityId'].toString();
        travelStateKey += `_${cityIdParam}`;
        httpParams = httpParams.set('filter[cityId]', cityIdParam);
      }

      if (queries['tagId'] && this.helperService.checkIsNumeric(queries['tagId'])) {
        const tagIdParam = queries['tagId'].toString();
        travelStateKey += `_${tagIdParam}`;
        httpParams = httpParams.set('filter[tagId]', tagIdParam);
      }

      if (queries['countryId'] && this.helperService.checkIsCountry(queries['countryId'])) {
        const countryIdParam = queries['countryId'].toString();
        travelStateKey += `_${countryIdParam}`;
        httpParams = httpParams.set('filter[countryId]', countryIdParam);
      }
    }

    this.store$
      .pipe(
        take(1),
        select(getSearchData),
        map((searchState) => {
          this.staticService.searchData = searchState;
          return {
            ...searchState,
            id: id,
            tagId: typeId,
            isSimilarPlaces: false,
            type: this.staticType,
          };
        }),
        mergeMap(() => {
          return this.staticInitService.getTravelData(httpParams, travelStateKey);
        }),
        untilDestroyed(this),
      ).subscribe((data: any) => {
      if (isInitLoad) {
        const placeData = data.data._extra;
        this.staticService.setCurrentPlace({
          ...placeData,
          ...placeData.cities && placeData.cities[0],
          tags: placeData.tags,
        });
        this.staticService.placesData = {
          ...data.data,
          ...data.data.items[0],
          typeTravelStatic: Number(typeId)
        };
        this.staticService.placeItemsList = [];
        this.staticService.showPlace = {
          ...placeData,
          similarPlacesLimit:
            data.data._meta?.totalCount === data.data.items.length
              ? data.data._meta.perPage
              : data.data.items.length,
        };
        this.staticService.setSeoDataSubPages(placeData);
        this.store$.dispatch(new AjaxFinish({uid: null}));
        this.staticService.blockQueryParamsRequest = false;

        doAsync(() => this.staticService.isUpdateTravelParams = false, 100);
      }
      this.staticService.totalCount = data.data._meta?.totalCount || 0;
      this.staticService.placeItemsList = this.staticService.placeItemsList.concat(data.data.items);
      this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
      this.processData(data.data);
    });
  }

  public getSubStaticPagesInfo(
    name: string,
    id: string,
    tagId?: string,
    page?: number
  ): void {
    !page ? (this.staticService.pageCount = 1) : this.staticService.pageCount++;
    this.store$
      .pipe(
        take(1),
        select(getSearchData),
        map((searchState) => {
          this.staticService.searchData = searchState;
          return {
            ...searchState,
            id: id,
            page: page ? this.staticService.pageCount : null,
            tagId: tagId,
            isSimilarPlaces: false,
            type: this.staticService.staticType,
          };
        }),
        mergeMap((categoryData) => {
          const ssrCategory = this.ssrService.getState(`${StaticTransferStateKey.CategoryInfoKey}_${id}_${tagId}`);

          if (!ssrCategory) {
            return this.staticInitService.getCategoryData(categoryData, this.staticService.isMobileDesign() ? 10 : null);
          } else {
            this.ssrService.removeState(`${StaticTransferStateKey.CategoryInfoKey}_${id}_${tagId}`);
            return of({
              ...categoryData,
              data: ssrCategory
            });
          }
        }),
        untilDestroyed(this),
      ).subscribe((data) => {
      if (!data || !data.data) {
        return;
      }

      this.staticService.tagGroupId = data.tagId || tagId;
      this.staticService.subPlaceId = id;
      this.ssrService.setState(`${StaticTransferStateKey.CategoryInfoKey}_${id}_${tagId}_${name}`, data);

      if (data.data._extra && !data.data._extra.tag) {
        data.data._extra.tag =
          data.data._extra
          && data.data._extra.tags
          && data.data._extra.tags.find(t => t.id === Number(this.staticService.tagGroupId));
      }

      const placeData = data.data._extra.hasOwnProperty('city')
        ? data.data._extra.city
        : data.data._extra.country;

      this.staticService.setCurrentPlace({
        ...placeData,
        tags: data.data._extra.tags,
      });


      this.staticService.placesData = data.data;
      this.processData(data.data);
      this.staticService.totalCount = data.data._meta?.totalCount || 0;
      this.staticService.currentPage = data.data._meta?.currentPage || 1;
      this.staticService.mainPageCount = data.data._meta?.pageCount || 1;
      if (this.staticService.pageCount === data.data._meta?.pageCount) {
        this.staticService.similarPageCount = 0;
        this.staticService.mainPageCount = 0;
        this.staticService.pageCount = 0;
      }
      this.staticService.placeItemsList = !page
        ? data.data.items
        : this.staticService.placeItemsList.concat(data.data.items);

      this.staticService.showPlace = {
        ...placeData,
        similarPlacesLimit:
          data.data._meta?.totalCount === data.data.items.length
            ? data.data._meta.perPage
            : data.data.items.length,
      };
      this.staticService.setSeoDataSubPages(data.data._extra);
      this.checkOnScroll();
      this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
    });
  }

  public processData(data) {
    this.data = data;

    if (this.data._extra.hasOwnProperty('collection') && this.data._extra.collection) {
      this.userData = {
        ...this.data._extra.collection.user,
      };
    }

    if (this.data._extra.hasOwnProperty('city')) {
      this.info = {
        ...this.data._extra.city,
        tag: {
          ...this.data._extra.tag,
        },
        _extra: this.data._extra,
        category: {
          ...this.data._extra.category,
        },
        place: this.data._extra.place,
        categoryName: (this.data._extra.category && this.data._extra.category.name || this.data._extra.tag && this.data._extra.tag.name)
      };
    } else {
      this.info = {
        ...this.data._extra.country,
        tag: {
          ...this.data._extra.tag,
        },
        _extra: this.data._extra,
        category: {
          ...this.data._extra.category,
        },
        place: this.data._extra.place,
        categoryName: (this.data._extra.category && this.data._extra.category.name || this.data._extra.tag && this.data._extra.tag.name)
      };
    }

    this.countries =
      this.data._extra.countries
        ? this.data._extra.countries.map(countryItem => ({
          ...countryItem,
          name: countryItem.shortName ? countryItem.shortName : countryItem.name
        }))
        : [];
    this.cities = this.data._extra.cities;
    this.categories = this.filterCategories(this.data._extra.tags);
    this.$data.next(this.data)
  }

  protected filterCategories(tags) {
    if (!tags) {
      return [];
    }
    if (this.staticService.staticType === this.staticRouteType.Travel) {
      // To not duplicate the same tag
      tags = tags.filter(tag => tag.id !== this.info.tag.id);
    }

    return tags;
  }

  public getSearchSimilarPlaces(): void {
    this.staticService.pageCount++;
    this.retrieveSearchData(true);
  }

  // INIT StaticRouteType.SEARCH: (left side page)
  public retrieveSearchData(isSimilar?: boolean): void {
    const query = this.staticService.routeQueryParams || this.activatedRoute.snapshot.queryParams;
    const searchString = query && query.q && String(query.q);
    this.staticService.searchQuery = query.q;

    let httpParams = new HttpParams();

    httpParams = httpParams.set(
      'expand',
      'iataCodeFrom,pictures,user,pinned,tags,city.flights,city.country,country,video,city.hasPage,user.urlSetting'
    );
    httpParams = httpParams.set('filter[query]', query.q);
    if (query['cityId'] && this.helperService.checkIsNumeric(query['cityId'])) {
      httpParams = httpParams.set('filter[cityId]', query['cityId']);
    }
    if (query['countryId'] && this.helperService.checkIsCountry(query['countryId'])) {
      httpParams = httpParams.set('filter[countryId]', query['countryId']);
    }
    if (query['tagId[]'] && this.helperService.checkIsNumeric(query['tagId[]'])) {
      httpParams = httpParams.set('filter[tagId]', query['tagId[]']);
    }
    httpParams = this.applyCommonQueryParams(httpParams);

    const ssrKey = `${StaticTransferStateKey.SearchInfoKey}_${searchString}_${query['countryId'] || ''}_${query['cityId'] || ''}_${query['tagId[]'] || ''}`;

    this.store$
      .pipe(
        take(1),
        select(getSearchData),
        map((searchState) => {
          this.staticService.searchData = searchState;
          return {
            ...searchState,
            type: this.staticType,
          };
        }),
        mergeMap((categoryData) => {
          const ssrCategory = this.ssrService.getState(`${ssrKey}`);

          if (!ssrCategory) {
            return this.staticInitService.search(httpParams);
          } else {
            this.ssrService.removeState(`${ssrKey}`);
            return of({
              ...categoryData,
              ...ssrCategory
            });
          }
        }),
        untilDestroyed(this),
      ).subscribe((data) => {
      if (!isSimilar) {
        const placeData = data._extra;

        this.staticService.setCurrentPlace({
          ...placeData,
          ...data._extra.cities.length ? data._extra.cities[0] : data.items.length && data.items[0],
          tags: data._extra.tags,
        });
        this.staticService.placesData = {
          ...data,
          ...data.items[0],
        };
        this.staticService.totalCount = data._meta.totalCount;
        this.staticService.placeItemsList = data.items;
        this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);

        this.staticService.showPlace = {
          ...placeData,
          similarPlacesLimit:
            data._meta.totalCount === data.items.length
              ? data._meta.perPage
              : data.items.length,
        };

        this.staticService.setSeoDataSubPages({
          ...data._extra,
          ...data._meta,
          items: data.items,
        });

        this.store$.dispatch(new AjaxFinish({uid: null}));
        this.ssrService.setState(`${ssrKey}`, data);
        this.staticService.blockQueryParamsRequest = false;
      } else {
        this.staticService.totalCount = data._meta.totalCount;
        this.staticService.placeItemsList = this.staticService.placeItemsList.concat(data.items);
        this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
      }
      this.processData(data);
    });
  }

  // INIT StaticRouteType.COLLECTION:
  public getStaticCollectionData(id: number): void {
    let stateKey = `${StaticTransferStateKey.JourneyKey}_${id}`;
    let subscription;
    this.staticService.pageCount++;

    const queriesCollection = this.activatedRoute.snapshot.queryParams;

    if (queriesCollection) {
      if (queriesCollection.cityId) {
        stateKey += `_${queriesCollection.cityId}`;
      }
      if (queriesCollection['tagId']) {
        stateKey += `_${queriesCollection['tagId']}`;
      }
      if (queriesCollection.countryId) {
        stateKey += `_${queriesCollection['countryId']}`;
      }
    }
    const ssrCacheData = this.ssrService.getState(stateKey);

    if (!ssrCacheData) {
      subscription = this.staticInitService.getJourneyData({...this.staticService.searchData}, id, this.staticService.pageCount);
    } else {
      this.ssrService.removeState(stateKey);
      if (typeof ssrCacheData.messageCode !== 'undefined') {
        this.isErrorCollection = true;
        this.userCollectionService.isErrorCollection$.next(true);
        this.staticService.setDeactivatedPageMode(ssrCacheData.displayName, ssrCacheData.messageCode, ssrCacheData.id);
        return;
      } else {
        let collectionList = null;
        let isActualData = true;
        this.store$
          .select(getCollectionList)
          .pipe(untilDestroyed(this))
          .subscribe(collections => {
            collectionList = collections
          });
        if (collectionList.length) {
          const selectedCollection = collectionList.find(collection => collection.id === Number(id));
          if (selectedCollection.places && selectedCollection.places.length) {
            selectedCollection.places.forEach(items => {
              const isSomeData = ssrCacheData.data.items.some(ssrItem => ssrItem.id === items.place?.id);
              if (!isSomeData) {
                isActualData = false;
              }
            });
          }
        }
        if (isActualData) {
          subscription = of(ssrCacheData);
        } else {
          subscription = this.staticInitService.getJourneyData({...this.staticService.searchData}, id, this.staticService.pageCount);
        }
      }
    }

    subscription
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
          this.isErrorCollection = false;
          this.userCollectionService.isErrorCollection$.next(false);
          if (!data) {
            return;
          }
          if (ssrCacheData) {
            const collectionFromState = this.tryToGetDataFromState('collection');
            if (collectionFromState && data.data._extra['collection']) {
              collectionFromState['user'] = data.data._extra['collection']['user'];
              data.data._extra['collection'] = collectionFromState;
            }
          }
          if (this.ssrService.isSSR()) {
            this.ssrService.setState(stateKey, data);
          }
          const placeData = data.data._extra;
          const currentPlace: PlaceNew[] = data.data.items;

          this.staticService.setCurrentPlace(currentPlace);

          this.staticService.placesData = {
            ...data.data,
            ...data.data.items[0],
          };
          this.staticService.totalCount = data.data._meta?.totalCount || 0;
          this.staticService.placeItemsList = data.data.items;
          this.staticService.placeItemsList = this.staticService.placeItemsList.map(placeItem => ({
            ...placeItem,
            isCollectionPlace: true
          }));
          this.staticService.showPlace = {
            ...placeData,
            similarPlacesLimit:
              data.data._meta?.totalCount === data.data.items.length
                ? data.data._meta.perPage
                : data.data.items.length,
          };
          this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
          this.staticService.setSeoDataSubPages(placeData);
          this.store$.dispatch(new AjaxFinish({uid: null}));
          this.staticService.blockQueryParamsRequest = false;

          this.processData(data.data);
          this.checkOnScroll();
          doAsync(() => this.staticService.isUpdateTravelParams = false, 100);
        },
        (errors) => {
          this.isErrorCollection = true;
          this.userCollectionService.isErrorCollection$.next(true);
          if (errors.status === 404) {
            this.ssrService.redirect(301, '/404');
          }
          if (errors.status === 422) {
            this.router.navigate(['/service-unavailable'], {queryParams: {code: errors.status}});
          }
          if (errors.status === 410) {
            const errorData = {
              id: errors.headers.get('x-user-id'),
              displayName: errors.headers.get('x-user-name'),
              messageCode: errors.error?.code
            };
            this.ssrService.setState(stateKey, errorData);
            this.staticService.setDeactivatedPageMode(errorData.displayName, errorData.messageCode, errorData.id);
          }
        }
      );
  }

  /**
   * May contain data for instance, after redirecting from panel to the page of the same collection
   * @private
   */
  private tryToGetDataFromState(stateParam: string) {
    if (this.ssrService.isBrowser() && history.state?.[stateParam]) {
      return history.state?.[stateParam];
    }

    return null;
  }

  protected checkOnScroll() {
    if (this.staticService.placeItemsList.length < 6) {
      this.onScrollDown();
    }
  }
}
