import { makeStateKey, StateKey } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Just removes API-URI starting part from key
 * @param key
 */
export function transformStateKey<T = void>(key?: string): StateKey<T> {
  if (!key) {
    return null;
  }
  key = key.replace(environment.SSRApiUrl, '');
  key = key.replace(environment.apiUrl, '');

  return makeStateKey<T>(key);
}
