import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getAvailableMMR, getHomeData, State } from '../reducers';
import {
  PatchSearchFormState,
  SetCityTo,
  SetPointToType,
  SetTripType,
  SetWhenDate
} from './layer.actions';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import dayjs from 'dayjs';
import { combineLatest, filter as rxFilter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { FlyCookieService } from '../../services/fly-cookie/fly-cookie.service';
import { GeoPointDataResponse, SearchStateFormPatch } from '../../interfaces';
import { computeCenterOfCountry } from '../../libraries';
import * as fromLayer from '../../store/layer';
import { DestinationType } from '../../enums/destination-type';
import { ReplaceLinkService } from '../../services/replace-link.service';
import { Subject } from 'rxjs/internal/Subject';
import { HelperService } from "../../services/helper.service";
import { searchData } from "../../services";
import { TripTypeEnum } from "../../enums/trip-type.enum";

@Injectable({providedIn: 'root'})
export class DateResolverService  implements OnDestroy {
  public isFirstLoad = true;

  private $destroyed = new Subject<void>();

  constructor(
    private store$: Store<State>,
    private searchService: SearchService,
    private replaceLinkService: ReplaceLinkService,
    private helperService: HelperService,
    private cookieService: FlyCookieService
  ) {
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private detectSearchTo(route) {
    if (
      route.paramMap.has('cityNameTo') &&
      route.paramMap.has('cityIdTo') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('cityNameTo'),
        id: Number(route.paramMap.get('cityIdTo')),
        coordinate: ''
      };
      return of(cityTo);
    } else if (
      route.paramMap.has('tagName') &&
      route.paramMap.has('tagId') &&
      route.paramMap.has('countryId') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('tagName'),
        id: Number(route.paramMap.get('tagId')),
        countryCode: route.paramMap.get('countryId'),
        type: DestinationType.Tag,
      };
      return of(cityTo);
    } else if (
      route.paramMap.has('tagName') &&
      route.paramMap.has('tagId') &&
      route.paramMap.has('cityName') &&
      route.paramMap.has('cityId') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('cityName'),
        cityId: Number(route.paramMap.get('cityId')),
        id: route.paramMap.get('tagId'),
        type: DestinationType.TagCity,
      };
      return of(cityTo);
    } else if (
      route.paramMap.has('destinationName') &&
      route.paramMap.has('destinationId') &&
      route.paramMap.has('destinationTypeId') &&
      route.paramMap.has('destinationPlaceId') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('destinationName'),
        id: Number(route.paramMap.get('destinationId')),
        type: Number(route.paramMap.get('destinationTypeId')),
        coordinate: ''
      };
      if (Number(route.paramMap.get('destinationPlaceId'))) {
        cityTo['cityId'] = Number(route.paramMap.get('destinationPlaceId'));
      } else {
        cityTo['countryCode'] = route.paramMap.get('destinationPlaceId');
      }
      return of(cityTo);
    } else if (
      route.paramMap.has('countryNameTo') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('countryNameTo'),
        coordinate: ''
      };
      return of(cityTo);
    } else if (
      route.paramMap.has('destinationName') &&
      route.paramMap.has('destinationId') &&
      route.paramMap.has('destinationTypeId') &&
      route.routeConfig.path.indexOf('map') !== -1
    ) {
      const cityTo = {
        name: route.paramMap.get('destinationName'),
        type: Number(route.paramMap.get('destinationTypeId')),
        coordinate: ''
      };

      if (Number(route.paramMap.get('destinationId'))) {
        cityTo['id'] = Number(route.paramMap.get('destinationId'));
      } else {
        cityTo['countryCode'] = route.paramMap.get('destinationId');
      }

      return of(cityTo);
    }

    const searchData = this.getSearchData();

    if (!searchData) {
      return of(null);
    }

    let cityToData = searchData['cityToData'];

    if (!cityToData) {
      cityToData = searchData['poi'];
    }

    if (cityToData) {
      if (!cityToData.id) {
        delete cityToData.id;
        this.store$.dispatch(
          new SetPointToType({
            type: DestinationType.Country
          })
        );
      } else {
        this.store$.dispatch(
          new SetPointToType({
            type: DestinationType.City
          })
        );
      }

      cityToData = {
        ...cityToData,
        ...this.replaceLinkService.setCityToLinkName(cityToData)
      };

      this.store$.dispatch(new SetCityTo(cityToData));
      return of({
        name: cityToData.name,
        id: cityToData.id,
        type: cityToData.type,
        coordinates: cityToData.coordinates,
        countryCode: cityToData.countryCode ? cityToData.countryCode : '',
        cityId: cityToData.cityId ? cityToData.cityId : '',
        ...this.replaceLinkService.setCityToLinkName(cityToData)
      });
    }
    return of(null);
  }

  private detectTripType() {
    const searchData = this.getSearchData();

    if (searchData && searchData.tripType) {
      this.store$.dispatch(new SetTripType(searchData.tripType));
      this.store$.dispatch(new SetWhenDate(this.convertDate(searchData.date)));
      return of(searchData.trip);
    }
    if (searchData && searchData.date) {
      return of(
        searchData.length > 1 ? TripTypeEnum.RoundTrip : TripTypeEnum.OneWay
      );
    }

    return of(TripTypeEnum.OneWay);
  }

  detectPassengers() {
    const passengers = this.getSearchData('passenger');
    if (passengers) {
      this.store$.dispatch(new fromLayer.SetPassengers(passengers));
      return of(Number.parseInt(passengers));
    }
    return of(1);
  }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<Boolean> | Promise<Boolean> | Boolean {
    return new Promise((res: any) => {
      of(<any>{init: null})
        .pipe(
          combineLatest(
            this.detectSearchTo(route).pipe(
              map(val => {
                return {cityTo: val};
              })
            ),
            this.detectTripType().pipe(
              map(val => {
                return {tripType: val};
              })
            ),
            this.detectPassengers().pipe(
              map(val => {
                return {passengers: val};
              })
            ),
            this.detectSearchFrom().pipe(
              map(val => {
                return {cityFrom: val};
              })
            ),
            this.detectDate().pipe(
              map(val => {
                return {date: val};
              })
            ),
            this.detectMMR().pipe(
              map(val => {
                return {mmr: val};
              })
            )
          ),
          map((data: any[]) => filter(data, obj => !!Object.values(obj)[0])),
          map((data: any[]) =>
            data.reduce((result, _data) => Object.assign({}, result, _data), {})
          ),
          mergeMap(
            (data: SearchStateFormPatch): Observable<SearchStateFormPatch> => {
              if (data?.cityTo?.type === DestinationType.Tag ||
                data?.cityTo?.type === DestinationType.TagCity ||
                data?.cityTo?.type === DestinationType.City ||
                data?.cityTo?.type === DestinationType.Country ||
                data?.cityTo?.type === DestinationType.Place) {
                this.isFirstLoad = true;
              }
              if (!!this.isFirstLoad && !!data && !!data.cityTo) {
                let httpParams = new HttpParams();

                if (data.cityTo && data.cityTo.type && data.cityTo.id && data.cityFrom.id) {
                  httpParams = httpParams
                    .append('filter[cityFrom]', data.cityFrom.id.toString())
                    .append('filter[type]', data.cityTo.type)
                    .append('filter[id]', data.cityTo.id);
                } else if (data.cityTo && data.cityTo.type && data.cityTo.countryCode && data.cityFrom.id) {
                  httpParams = httpParams
                    .append('filter[cityFrom]', data.cityFrom.id.toString())
                    .append('filter[type]', data.cityTo.type)
                    .append('filter[id]', data.cityTo.countryCode);
                } else {
                  httpParams = httpParams
                    .append('filter[query]', data.cityTo.name);
                }

                if (
                  (data.cityTo.type === DestinationType.AllPlaceCountry ||
                    data.cityTo.type === DestinationType.TagCountry) &&
                  data.cityTo.countryCode) {
                  httpParams = httpParams
                    .append('filter[countryId]', data.cityTo.countryCode);
                }

                if (data.cityTo.type === DestinationType.Tag ||
                  data.cityTo.type === DestinationType.TagCity &&
                  data.cityTo.id) {
                  httpParams = httpParams
                    .append('filter[id]', data.cityTo.id)
                }

                if (data.cityTo.type === DestinationType.AllPlaceCity ||
                  data.cityTo.type === DestinationType.TagCity &&
                  data.cityTo.cityId) {
                  httpParams = httpParams
                    .append('filter[cityId]', data.cityTo.cityId);
                }

                return this.searchService.searchDest(httpParams).pipe(
                  map(
                    (
                      response: GeoPointDataResponse[]
                    ): SearchStateFormPatch => {
                      let result;
                      if (response && response.length >= 1) {
                        let found;
                        if (data.cityTo && (data.cityTo.id || +data.cityTo.type === DestinationType.Country && data.cityTo.countryCode) && data.cityTo.type) {
                          found = response.find(
                            x =>
                              +x.id === +data.cityTo.id && +x.otype === +data.cityTo.type ||
                              x.id === data.cityTo.countryCode.toUpperCase() && +data.cityTo.type === DestinationType.Country
                          );
                        } else {
                          data.cityTo.name = data.cityTo.name.toLowerCase();
                          found = response.find(x =>
                            data.cityTo?.name === x.name.toLowerCase() ||
                            (x.urlKeywords && data.cityTo?.name === x.urlKeywords.toLowerCase()) ||
                            (x.urlKeywords && data.cityTo?.name === data.urlKeywords?.toLowerCase()) ||
                            (x.cityUrlKeywords && data.cityTo?.name === data.cityUrlKeywords?.toLowerCase()) ||
                            (x.countryUrlKeywords && data.cityTo?.name === data.countryUrlKeywords?.toLowerCase()) ||
                            (x.tagUrlKeywords && data.cityTo?.name === data.tagUrlKeywords?.toLowerCase()) ||
                            (x.urlKeywords && data?.urlKeywords && data.cityTo?.name === data.urlKeywords?.toLowerCase())
                          );
                        }
                        if (!found) {
                          return null;
                        }
                        if (+found.otype === DestinationType.Country) {
                          result = {
                            ...data,
                            cityTo: {
                              name: found.name,
                              countryCode: found.id,
                              type: found.otype
                            }
                          };
                        } else {
                          result = {
                            ...data,
                            cityTo: {
                              id: +response[0].id,
                              name: response[0].name,
                              coordinates: response[0].lat && response[0].lng ? `${response[0].lat},${response[0].lng}` : null,
                              countryCode: response[0].country && response[0].country?.id || data.cityTo.countryCode || '',
                              type: found.otype,
                              zoom: response[0].zoom
                            }
                          };
                        }

                        if (
                          found.otype == DestinationType.Country ||
                          found.otype == DestinationType.TagCountry ||
                          found.otype == DestinationType.TagCity ||
                          found.otype == DestinationType.AllPlaceCity ||
                          found.otype == DestinationType.AllPlaceCountry
                        ) {
                          result.cityTo['latNorth'] = +found.latNorth;
                          result.cityTo['lngEast'] = +found.lngEast;
                          result.cityTo['latSouth'] = +found.latSouth;
                          result.cityTo['lngWest'] = +found.lngWest;
                          if (found.city && found.city.id) {
                            result.cityTo['cityId'] = found.city.id;
                          }
                          const {lat, lng} = computeCenterOfCountry(found);
                          result.cityTo['coordinates'] = `${lat},${lng}`;
                        } else {
                          result.cityTo['coordinates'] = found.lat && found.lng ? `${found.lat},${found.lng}` : null;
                        }
                        if (result && result.cityTo) {
                          result.cityTo = {
                            ...result.cityTo,
                            ...this.replaceLinkService.setCityToLinkName(result.cityTo)
                          };
                        }
                        this.store$.dispatch(new SetCityTo(result.cityTo));
                      }
                      return result;
                    }
                  ),
                );
              }
              return of(data);
            }
          ),
          tap((patchData: SearchStateFormPatch) => {
            if (!!this.isFirstLoad) {
              const cityTo = this.getSearchData('cityToData');
              if (cityTo && !cityTo.countryCode) {
                this.store$.dispatch(
                  new PatchSearchFormState({...patchData})
                );
              }
              this.isFirstLoad = false;
            }

            res(patchData);
          }),
          takeUntil(this.$destroyed)
        )
        .subscribe();
    });
  }

  private detectSearchFrom() {
    let cityFrom = this.getSearchData('cityFromData');
    if (cityFrom) {
      if (!cityFrom['id']) {
        cityFrom = DateResolverService.mapPlaceToCity(cityFrom);
      }
      this.store$.dispatch(new fromLayer.SetCityFrom(cityFrom));
    }

    const pointFrom: any = {
      id: cityFrom ? cityFrom.id : 0,
      name: cityFrom ? cityFrom.name : ''
    };
    if (
      pointFrom.name &&
      pointFrom.id
    ) {
      const httpParams = new HttpParams()
        .append(
          'filter[name]',
          pointFrom.name
        )
        .append('expand', 'country');
      return this.searchService.searchCity(httpParams).pipe(
        map((dataCity: any[]) => {
          let coordinates = '';
          let countryCode = '';

          if (dataCity[0]) {
            coordinates = dataCity[0].lat && dataCity[0].lng ? `${dataCity[0].lat},${dataCity[0].lng}` : null;
            countryCode = dataCity[0].countryCode;
          }

          return {
            ...pointFrom,
            coordinates,
            countryCode,
            type: DestinationType.City
          };
        })
      );
    } else {
      return this.store$.pipe(
        select(getHomeData),
        rxFilter(homeData => !!homeData && !!homeData.id),
        map(homeData => {
          const cityFrom = {
            id: homeData.id,
            name: homeData.name,
            coordinates: homeData.coordinates,
            countryCode: homeData.countryCode,
            type: DestinationType.City,
            iataCode: homeData.iataCode
          };
          this.store$.dispatch(new fromLayer.SetCityFrom(cityFrom));

          return cityFrom;
        })
      );
    }
  }

  private detectDate() {
    const date = this.getSearchData('date');
    if (date && date.length > 0) {
      this.store$.dispatch(new SetWhenDate(this.convertDate(date)));
      return of(this.convertDate(date));
    }
    return of(null);
  }

  private detectMMR() {
    let mmr = this.getSearchData('mmr');
    if (mmr) {
      mmr = {
        ...mmr,
        ...this.replaceLinkService.setCityToLinkName(mmr)
      };
      this.store$.dispatch(new SetCityTo(mmr));
      return this.store$.pipe(
        select(getAvailableMMR),
        rxFilter(availableMMRSections => !!availableMMRSections.length),
        map(_availableMMRSections => {
          const availableMMRSections = cloneDeep(_availableMMRSections);
          const targetMMR = mmr.name.split(',');
          return availableMMRSections.reduce((result, value) => {
            const targetSection = value.data
              .filter(mmr => targetMMR.includes(mmr['n']))
              .map(mmr => ({...mmr, selected: true}));
            if (!!targetSection.length) {
              result.push({
                ...value,
                data: targetSection
              });
            }
            return result;
          }, []);
        })
      );
    }
    return of(null);
  }

  private getSearchData(property?: string) {
    const searchDataFromCookie = this.cookieService.get(searchData);
    const searchDataInfo = searchDataFromCookie && this.helperService.isJsonString(searchDataFromCookie)
      ? JSON.parse(searchDataFromCookie)
      : null;
    if (property) {
      return searchDataInfo && searchDataInfo[property] ? searchDataInfo[property] : null;
    } else {
      return searchDataInfo;
    }
  }

  private convertDate(date: any): any {
    return date.map(item => {
      return {
        type: item.type,
        date: dayjs(item.date)
      };
    });
  }

  private static mapPlaceToCity(place: any): any {
    return {
      name: place.name,
      id: place.id,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: DestinationType.City,
      countryCode: place.country ? place.country.id : '',
      iataCode: place.iataCode
    };
  }
}
