<div class="input-field">
  <input [disabled]="disabled" *ngIf="type === 'input'" type="text" [(ngModel)]="value">

  <textarea [disabled]="disabled" *ngIf="type === 'textarea'" [(ngModel)]="value"></textarea>

  <editor
    *ngIf="type === 'wysiwyg'"
    [(ngModel)]="value"
    [init]="{
         height: 300,
         menubar: false,
         plugins: 'lists wordcount link autolink',
         block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6',
         base_url: '/tinymce',
         suffix: '.min',
         paste_as_text: true,
         entity_encoding: 'raw',
         toolbar: 'undo redo | blocks | bold italic | bullist numlist | removeformat | link unlink',
         link_target_list: false,
         link_title: false,
         contextmenu: 'linkchecker image editimage table spellchecker configurepermanentpen'
       }"
  ></editor>

  <div class="actions-buttons">
    <button class="cancel-btn" (click)="close()">Cancel</button>
    <button class="save-btn" (click)="save()">Save</button>
  </div>
</div>
