import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { DestroyComponent } from "../../../heplers/destroy.component";
import { getFollowedList } from "../../../store/reducers";
import { LoadFollowed } from "../../../store/user-following/user-following.actions";
import { ToggleFollowingPanel } from "../../../store/panels/panels.actions";
import { FollowedList, FollowerEvent } from "../../../interfaces/following";
import { environment } from "../../../../environments/environment";
import { getLinkByObject } from "../../../libraries/get-link-by-object";
import { UserPage } from "../../../interfaces/user";
import { Router } from "@angular/router";
import { DestinationType } from "../../../enums/destination-type";
import { UserEventType } from "../../../enums/user-event.type";
import { PlaceNew } from 'src/app/interfaces';
import { Journey } from 'src/app/interfaces/journey';
import dayjs from 'dayjs';
import { UserFollowingService } from "../../../services/user-following.service";
import { getUserAvatarByObject } from "../../../libraries/get-user-avatar";
import { getCountryFlag } from "../../../libraries/get-coutnry-flag";
import { SsrService } from "../../../services";

@Component({
  selector: 'app-following-panel',
  templateUrl: './following-panel.component.html',
  styleUrls: ['./following-panel.component.scss']
})
export class FollowingPanelComponent extends DestroyComponent implements OnInit {

  @Input() isProfile: boolean;

  protected followed: FollowedList;
  protected getLinkByObject = getLinkByObject;
  protected readonly getUserAvatarByObject = getUserAvatarByObject;
  protected mediaUrl: string = environment.mediaUrl;
  protected userToUnfollow: UserPage = null;

  constructor(
    protected store$: Store,
    protected router: Router,
    protected followingService: UserFollowingService,
    private ssrService: SsrService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store$.pipe(
      takeUntil(this.destroy$),
      select(getFollowedList)
    ).subscribe((followed: FollowedList) => {
      this.followed = followed;
      if (!this.followingService.isEventsLoaded) {
        this.store$.dispatch(new LoadFollowed(true));
      }
    });
  }

  protected close(): void {
    this.store$.dispatch(new ToggleFollowingPanel({display: false}));
  }

  protected getCounters(user: UserPage): string {
    let counters = '';

    if (user.placeCreatedCount > 0) {
      counters += user.placeCreatedCount + ' place' + (user.placeCreatedCount !== 1 ? 's' : '');
    }
    if (user.collectionCount > 0) {
      if (counters !== '') {
        counters += ', ';
      }
      counters += user.collectionCount + ' collection' + (user.collectionCount !== 1 ? 's' : '');
    }

    return counters
  }

  protected clickOnUser(user: UserPage): void {
    this.ssrService.goToRoute(getLinkByObject(user));
    this.close();
  }

  protected clickOnObject(followedEvent: FollowerEvent): void {
    let object: PlaceNew | Journey;
    switch (followedEvent.otype) {
      case DestinationType.Place:
        object = followedEvent.place;
        break;
      case DestinationType.Journey:
        object = followedEvent.collection;
        break;
      default:
        throw new Error('Unknown object type: ' + followedEvent.otype);
    }
    this.ssrService.goToRoute(getLinkByObject(object));
    this.close();
  }

  protected getEventTypeLabel(followedEvent: FollowerEvent): string {
    let eventTypeLabel: string;
    switch (followedEvent.eventType) {
      case UserEventType.Creation:
        eventTypeLabel = 'New';
        break;
      default:
        throw new Error('Unknown eventType: ' + followedEvent.eventType);
    }

    return eventTypeLabel;
  }

  protected getObjectTypeLabel(followedEvent: FollowerEvent): string {
    let objectTypeLabel: string;
    switch (followedEvent.otype) {
      case DestinationType.Place:
        objectTypeLabel = 'place';
        break;
      case DestinationType.Journey:
        objectTypeLabel = 'journey';
        break;
      default:
        throw new Error('Unknown object type: ' + followedEvent.otype);
    }

    return objectTypeLabel;
  }

  protected getObjectName(followedEvent: FollowerEvent): string {
    let objectName: string;
    switch (followedEvent.otype) {
      case DestinationType.Place:
        objectName = followedEvent.place?.name;
        break;
      case DestinationType.Journey:
        objectName = followedEvent.collection?.name;
        break;
      default:
        throw new Error('Unknown object type: ' + followedEvent.otype);
    }

    return objectName;
  }

  protected getObjectDate(followedEvent: FollowerEvent): string {
    let creationDate: string;
    switch (followedEvent.otype) {
      case DestinationType.Place:
        creationDate = followedEvent.place?.creationDate;
        break;
      case DestinationType.Journey:
        creationDate = followedEvent.collection?.creationDate;
        break;
      default:
        throw new Error('Unknown object type: ' + followedEvent.otype);
    }
    if (!creationDate) {
      return '';
    }

    return dayjs(creationDate).format('D MMMM');
  }

  protected toggleUnfollowPopup(value: UserPage | null): void {
    this.userToUnfollow = value;
  }

  protected readonly getCountryFlag = getCountryFlag;
}
