<div class="old-static-page">

  <app-static-header
    [data]="staticService.placesData"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="terms-of-service-modal">
    <div class="terms-of-service-container">
      <div class="terms-of-service-content fly-m-t-10px fly-beauty-scroll">
        <header>
          <h1>Website Terms and Conditions</h1>
        </header>
        <section>
          <p>Last updated: 11 November 2022</p>
          <p>Please read these terms and conditions carefully before using Our Service.</p>


          <ol class="ListStyleDecimal">

            <h2>Interpretation and Definitions </h2>

            <h3>Interpretation </h3>

            <li>The words of which the initial letter is capitalized have meanings defined under the following
              conditions. The following definitions shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </li>

            <h3>Definitions</h3>

            <li>For the purposes of these Terms and Conditions:
              <ol>
                <li><b>Affiliate</b>: means an entity that controls, is controlled by or is under common control with a
                  party, where "control" means ownership of 50% or more of the shares, equity interest or other
                  securities
                  entitled to vote for election of directors or other managing authority.
                </li>
                <li><b>Agreement</b>: The Agreement to these Terms and Conditions, between Us and You.
                </li>
                <li><b>Company</b> (referred to as either “the Company”, “We”, “Us”, "Our" in this
                  Agreement) refers to Explorow Travel Pty Ltd. We are the operators and owners of the Site.
                </li>
                <li><b>Device</b> means any device that can access the Service such as a computer, a cellphone, or a
                  digital tablet.
                </li>
                <li><b>Service</b> refers to the Website/s.
                </li>
                <li><b>Terms and Conditions</b> (also referred as 'Terms") mean these Terms and Conditions that form the
                  entire agreement between You and the Company regarding the use of the Service.
                </li>
                <li><b>Third-party Social Media Service</b> means any services or content (including data, information,
                  products, or services) provided by a third-party that may be displayed, included or made available by
                  the Service.
                </li>
                <li><b>Third party Suppliers</b> means airlines or hotels or their agents.
                </li>
                <li><b>Website</b> (also referred to as “Site”/Sites”) collectively refer to all websites operated by
                  Explorow Travel Pty Ltd. Explorow is accessible from <a href="https://explorow.com/">https://explorow.com/</a>
                </li>
                <li><b>You</b> means the individual accessing or using the Service, or other legal entity on behalf of
                  which such individual is accessing or using the Service, as applicable.
                </li>
                <li><b>Visit</b> a singular occasion of accessing the Website, also referred to as “accessing the Site”.
                </li>
              </ol>
            </li>

            <h2>Acknowledgment </h2>

            <li> These are the Terms and Conditions governing the use of this Service and the agreement that operates
              between You, the user of the Service, and Us, owner and operator of the Site. These Terms and Conditions,
              as a binding contractual agreement, sets out the rights and obligations of all users regarding the use of
              the Service.
            </li>
            <li>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
              Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use
              the Service.
            </li>
            <li>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You
              disagree with any part of these Terms and Conditions, then You may not access the Service.
            </li>
            <li>You represent that you are over the age of 18. The Company does not permit those under 18 to use the
              Service.
            </li>
            <li>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with
              the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your personal information when You use the Application or the Website
              and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy
              carefully before using Our Service.
            </li>

            <h2>Your access to the Site</h2>

            <li>As a visitor or user of this Site, you acknowledge and agree that:
              <ol>
                <li>we retain complete editorial control over the Site and may alter, amend or cease the operation
                  of the Site at any time in our sole discretion;
                </li>
                <li>the Site may be unavailable from time to time (including for maintenance purposes);
                </li>
                <li>you will keep any username and password, where applicable, to access the Site secure and
                  confidential and will not provide these details to a third party under any circumstance and you accept
                  liability for any unauthorised use of any username and password issued to you;
                </li>
                <li>you will only download, view or print a copy of the Site for personal, non-commercial use; and
                </li>
                <li>we reserve the right to do whatever we deem necessary to prevent unauthorised access or use of
                  the Site, including, but not limited to, terminating your right to use the Site, imposing Site
                  barriers, or reporting your conduct to relevant authorities.
                </li>
              </ol>
            </li>

            <h2>Using the Site</h2>

            <li>When you access the Site (‘Visit’), you are using software and website owned and/or operated by
              Explorow. For each Visit, Explorow grants you a personal non-exclusive license to use the Site for the
              duration of the Visit and for the sole purpose of making travel bookings or developing user Content. This
              licence does not permit you to make a Visit for the purpose of carrying on a business as a travel agent,
              unless you are a licensed travel agent under that legislation. Any other use, commercial or otherwise, on
              the Site without the express permission of Explorow is strictly prohibited.
            </li>
            <li>You grant to Explorow and its affiliates (including our officers, agents, partners, and employees)
              an irrevocable, royalty-free, non-exclusive, transferable right to use your user Content in a manner
              determined by Explorow, including but not limited to on-Site advertisements and in any other promotional
              initiatives. Explorow may use, display, distribute, reproduce, combine with other materials your user
              Content with no obligation to you.
            </li>
            <li>In using this Site and any associated services, you warrant that:
              <ol>
                <li>you are solely responsible for assessing the suitability of any travel booking for your needs,
                  as well as developing your user Content which is not defamatory, abusive, harassing, hateful,
                  offensive or other illegal information;
                </li>
                <li>when creating user Content, it does not infringe upon any intellectual property rights (IPR)
                  such as copyright, trademark, patent, privacy and any regulation;
                </li>
                <li>when making bookings, you will use the Site and its associated Services to make only
                  legitimate bookings for you, or for another person under their proper authorization;
                </li>
                <li>if you are making a booking for another person, you will inform such other person about the
                  Terms applicable to the booking and the fact that they are bound by these Terms;
                </li>
                <li>you will only supply information to this Site that is true, accurate, current, and complete;
                </li>
                <li>when you submit content to the Site that you own or have obtained all necessary rights to
                  grant a license to Explorow.
                </li>
              </ol>
            </li>
            <li>In using our Site, you agree that you will not:
              <ol>
                <li>modify, copy, distribute, transmit, display, perform, reproduce, publish, licence, create
                  derivative works from, transfer, or sell or resell any information, user Content, software, products,
                  or services obtained from this Site;
                </li>
                <li>make a booking on this Site that is not a genuine booking, including any booking that is
                  speculative, false, fraudulent or in anticipation of demand;
                </li>
                <li>copy, reproduce, republish, transmit or otherwise communicate to the public any content
                  provided in this Website including, without limitation, text, graphics, user Content, button,
                  downloads and software without the express written permission of Explorow, except where permitted by
                  law;
                </li>
                <li>interfere or attempt to interfere with the proper functioning of the Site, including the
                  transmission of any viruses, defects, Trojan horses or any other destructive interference;
                </li>
                <li>engage in excessive or unreasonable usage of the Site, including making excessive traffic
                  demands on the Site infrastructure;
                </li>
                <li>scrape, data-mine, extract or collect any data, user Content or information from the Site in
                  any form and by any means whatsoever;
                </li>
                <li>frame or mirror the user Content of the Site in any way;
                </li>
                <li>use any user Content from the Site for the purposes of competing with the Site, constructing
                  breach any applicable law or use this Site for any purpose that is prohibited by these Terms or
                  populating any public database of properties or property information, or engaging in direct marketing
                  or any form of mass communication;
                </li>
                <li>breach any applicable law or use this Site for any purpose that is prohibited by these Terms.
                </li>
              </ol>
            </li>
            <li>If your booking or account shows signs of fraud, suspicious activity or activity that may be in
              breach of these Terms, Explorow reserves the right to cancel bookings associated with your name, email
              address or account, and close any associated accounts or to take any necessary legal action against you.
              Explorow reserve the right to remove any user Content from the Site.
            </li>

            <h3>Third party Suppliers</h3>

            <li>When you search for travel products or services, or user Content on the Site, your search will
              display information about travel products, services and Content provided to the Site by third party
              suppliers.
            </li>
            <li>You acknowledge and agree that the Site is a referral service, and that any bookings made as a
              result of a referral will be regarded as a transaction between you and the Third Party Supplier. You agree
              to abide by any additional terms and conditions imposed by a Third Party Supplier with whom you elect to
              deal.
            </li>
            <li>Explorow is not responsible for the accuracy of information supplied by the relevant Third-Party
              Suppliers.
            </li>
            <li>You acknowledge and agree that the Company does not endorse, sponsor or approve any other content
              available on a Third Party Supplier website.
            </li>
            <li>Explorow is not responsible for any product or service booked through the Third Party Supplier or
              any cancellation or suspension of your account or transaction by a Third Party Supplier. Please contact
              the Third Party Supplier directly if you have any questions, concerns, or issues with your booking.
            </li>
            <li>If you wish to change a booking made through a Third Party Supplier or seek a refund for monies paid
              for a product or service through a Third Party Supplier referred to you by the Site, you acknowledge and
              agree that this is subject to the terms and conditions of the Third Party Supplier. Any changes to the
              booking or requests for refund for bookings made through a Third Party Supplier. In particular, you are
              advised that many airfares are non-refundable, and you must check the terms and conditions applicable to
              the relevant airfare before proceeding with the booking with a Third Party Supplier.
            </li>

            <h3>Currency, Fees, and Tax</h3>

            <li>You acknowledge and agree that your bank and credit/debit card institution may charge you a
              transaction fee or a fee for a transaction in a foreign currency. This means the amount listed on your
              credit/debit card statement may be different to the figure shown on our Site. Explorow is not responsible
              for any discrepancy resulting from a fee charged by your bank, credit or debit card institution. Please
              contact your bank or credit/debit card institution directly if you have any questions about such fees or
              about the currency exchange rate.
            </li>
            <li>In some instances, the price displayed on our Site will have been converted from a foreign currency
              based on the conversation rates applicable on the day of the Visit. Any discrepancy in price due to the
              effect of foreign currency conversion is estimated to be minor and is outside the control of the Company.
              You acknowledge and agree that currency conversion rates are variable and that any amount displayed is for
              guidance purposes only. Actual rates may vary. Explorow does not warrant or guarantee the accuracy of the
              amount converted from a foreign currency.
            </li>
            <li>A Third-Party supplier may charge a Tax (Goods and Services Tax, Value Added Tax, Sales Tax,
              Occupancy Tax, or other) on any transaction you make on their Site. You are expected to read and
              understand your own tax obligations, and you are responsible for paying such Tax through any transaction
              you make on a Third-Party Supplier Site.
            </li>

            <h3>Frequent flyer and reward programs</h3>

            <li>You acknowledge and agree that you are solely responsible for entering your correct airline or hotel
              frequent flyer or other reward program number. Explorow is not responsible if your booking is not
              registered on the relevant airline or hotel rewards program.
            </li>

            <h3>Passports, Visas and Travel Documentation</h3>

            <li>You acknowledge and agree that you are solely responsible for complying with all relevant travel
              requirements for the countries you are travelling to and from, including but not limited to obtaining the
              relevant visas and/or other travel documentation. You should contact your nearest Embassy, High Commission
              or Consulate of the country you intend to visit in advance of your travel to find out the visa and travel
              documentation requirements.
            </li>
            <li>You acknowledge and agree that you are solely responsible for ensuring that the name on your
              passport or other travel documentation matches with the name on your booking and travel insurance.
            </li>
            <li>You agree that you will contact the relevant carrier or booking service directly if, after
              purchasing products or services but before traveling, any member of your party changes their name on their
              travel documentation.
            </li>
            <li>Our Company is not liable if you are refused entry into any country, or any flight due to your
              failure to obtain and carry the appropriate travel documentation.
            </li>

            <h2>Site Content</h2>

            <h3>User-Generated Content</h3>

            <li>Explorow’s mission is to host a secure, user-friendly and informative travel website, populated with
              User Content. The company permits user content to be submitted for publication on the Site, for the
              benefit of all Site Visitors.
            </li>
            <li>By agreeing to these Terms and with these Terms, you are granting Explorow with a license and your
              permission to use your User Content.
            </li>
            <li>Before submitting content to the Site, you should read the terms of this agreement, and in
              particular, the Copyright Notice. The Copyright Notice forms part of this Agreement.
            </li>
            <li>If you do not agree to the Copyright Notice or any terms of this agreement, please do not submit Content
              to the Site.
            </li>

            <h3>Intellectual Property Rights</h3>

            <li>All intellectual property in relation to this Site belongs to Explorow or its licensors,
              advertisers, or affiliates, and is protected by law.
            </li>
            <li>You acknowledge that copyright subsists in all software, including HTML code, provided in
              association with a Visit. Where copying or transmission is expressly permitted, you must not change or
              delete any author attribution or copyright notice.
            </li>
            <li>You acknowledge and agree that you will not do anything which interferes with or breaches those laws
              or the intellectual property rights of Explorow or any third party on the Site. You must not use the
              software associated with Site except in accordance with these Terms.
            </li>
            <li>You acknowledge that you grant an Intellectual Property Rights licence on the user Content you may
              have under any applicable law, which you submit to the Site. You acknowledge that you agree to the
              Copyright Notice which forms part of this agreement.
            </li>
            <li>If you believe content on Explorow is infringing upon your copyright, please report it to us. To
              submit a copyright complaint notice, fill out our
              <a href="https://www.explorow.com/copyright-infringement"> Сopyright Сomplaint Form</a>
            </li>

            <h3>Defamatory Content</h3>

            <li>Explorow aims to develop a diverse community of users. You shall not develop, upload or distribute
              any defamatory, libelous, abusive, harassing, hateful, indecent, offensive or other illegal information.
            </li>
            <li>Explorow reserves the right to remove the user Content that contains such content, without further
              notice. If you believe any content, including user Content used in connection with Explorow service
              violates any person’s rights, please contact us via
              <a class="cursor-pointer" (click)="toggleContactUs(contactType)">form</a>.
            </li>

            <h3>Editorial, blog or social media content</h3>

            <li>Any editorial, blog or social media content on or associated with the Site is of a general nature
              and should not be relied upon by you or any other person as advice. The comments made on Site by users are
              their personal views; it does not relate to statements made by Explorow and do not represent the position
              of our Company.
            </li>

            <h3>Liability</h3>

            <li>If you are a consumer under the Australian Consumer Law, you have certain rights, including under
              the consumer guarantees. Nothing in these Terms limits, excludes or modifies any rights you have that
              cannot lawfully be limited, excluded or modified. Information about the consumer guarantees is available
              from the website of the Australian Competition and Consumer Commission.
            </li>
            <li>To the full extent permitted by law, we exclude all liability in respect of any direct,
              consequential, exemplary, incidental, special or punitive damages, and any other loss in connection with
              the Site. These include, but are not limited, to loss of profit, revenue, goodwill, reputation, capital,
              loss of data, interruption of business:
              <ol>
                <li>any technical errors, corruption of data, unauthorized access to your personal data,
                  inaccuracies in information supplied by Third Party Suppliers, or any failure to complete a booking
                  due to circumstances beyond our control;
                </li>
                <li>the quality or suitability of any travel product or service booked through referral from this
                  Site;
                </li>
                <li>any failure or delay on the part of any Third Party Supplier in providing a travel product or
                  service you booked through referral from the Site;
                </li>
                <li>any acts or omissions of Third Party Supplier or other third parties in the course of delivery
                  of any product or service you booked through a referral from this Site.
                </li>
              </ol>
            </li>
            <li>Some states do not allow the exclusion of implied warranties or limitation of liability for
              incidental or consequential damages. In relation to any liability which cannot be excluded by law or
              despite the limitation above, then to the maximum extent permitted by law, we limit our liability, in the
              aggregate, to the greater of:
              <ol>
                <li>the service fees you paid to Explorow in connection with such transaction(s) on the Site, or
                </li>
                <li>One-Hundred Dollars (USD$100.00).</li>
              </ol>
            </li>
            <li>To the full extent permitted by law, we exclude all representations, warranties or terms (whether
              express or implied) other than those expressly set out in these Terms.
            </li>
            <li>These Terms are to be read subject to any legislation which prohibits or restricts the exclusion,
              restriction or modification of any implied warranties, conditions, guarantees or obligations, in force in
              Australia. If such legislation applies, to the extent possible, we limit our liability in respect of any
              claim to, at our option:
              <ol>
                <li>the supply of the goods and/or services again; or</li>
                <li>the payment of the cost of having the goods and/or services rectified, repaired or supplied
                  again, as applicable.
                </li>
              </ol>
            </li>
            <li>Subject to the limitations in these Terms and the Australian Consumer Law, neither our Company nor
              our Partners or Affiliates will be liable for any direct, indirect, punitive, special, incidental, or
              consequential losses or damages arising from:
              <ol>
                <li>the Travel Services</li>
                <li>the use of our Service</li>
                <li>any delay or inability to use our Service, or</li>
                <li>your use of links from our Service, whether based in negligence, contract, tort, strict
                  liability, consumer protection statutes, or otherwise, and even if our Group of Companies and our
                  Partners have been advised of the possibility of such damages.
                </li>
              </ol>
            </li>

            <h3>Indemnity</h3>

            <li>You agree to indemnify and hold the Site, Explorow and our affiliates (including our officers,
              agents, partners and employees) against any and all loss, liability, claim or demand arising out of, or in
              connection with your use of and access to the Site, your breach of these Terms, or your breach of any law
              or rights of a third party.
            </li>

            <h2>Termination</h2>

            <li>These Terms terminate automatically if, for any reason, Explorow cease to operate the Site.</li>
            <li>Explorow may otherwise terminate these Terms immediately, on notice to you, if you have breached
              these Terms in any way.
            </li>
            <li>Any provision of these Terms, which expressly, or by its nature, imposes obligations beyond the
              expiration, or termination of these Terms, shall survive such expiration or termination.
            </li>

            <h2>Severability and Waiver</h2>

            <li>If a provision of these Terms is found by a Court to be invalid or unenforceable it is to be read
              down or severed to the extent necessary without affecting the validity or enforceability of the remaining
              provisions.
            </li>
            <li>Except as provided herein, the failure to exercise a right or to require performance of an
              obligation under these Terms shall not effect a party's ability to exercise such right or require such
              performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent
              breach.
            </li>

            <h2>Governing Law</h2>

            <li>The laws of the Commonwealth of Australia, excluding its conflicts of law rules, shall govern this
              Terms and Your use of the Service. Your use of the Service may also be subject to other local, state,
              national, or international laws.
            </li>

            <h2>Disputes Resolution</h2>

            <li>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
              informally by contacting the Company.
            </li>

            <h2>Translation Interpretation</h2>

            <li>These Terms and Conditions may have been translated if We have made them available to You on our
              Service. You agree that the original English text shall prevail in the case of a dispute.
            </li>

            <h2>Changes to These Terms and Conditions</h2>

            <li>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a
              revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new
              terms taking effect. What constitutes a material change will be determined at Our sole discretion.
            </li>
            <li>By continuing to access or use Our Service after those revisions become effective, You agree to be
              bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using
              the website and the Service.
            </li>

            <h2>General</h2>

            <li>Each party must at its own expense do everything reasonably necessary to give full effect to the
              Terms and the events contemplated by it.
            </li>
            <li>Our failure or delay to enforce any provision of these Terms, does not waive our right to enforce
              the same or any other provision(s) of these Terms in the future.
            </li>
            <li>These Terms constitute the entire agreement between you and us with respect to our Service. They
              supersede all prior or contemporaneous communications (whether electronic, oral, or written) between you
              and us about our Service.
            </li>
          </ol>
        </section>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactUsOpened"
                (closeContactUs)="toggleContactUs()"></app-contact-us>
