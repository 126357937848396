<div>
  <h3 class="heading-text" *ngIf="inputHeadingText">{{inputHeadingText}}</h3>
  <label class="help-text" *ngIf="inputHelpText">{{inputHelpText}}</label>
  <div *ngIf="!cannotFindCity"
       class="autocomplete__container p-bottom"
       [class.m-0]="!withMap">
    <mat-form-field subscriptSizing="dynamic">
      <input #customAddressText
             type="text"
             matInput
             autocomplete="off"
             class="hide-placeholder-focus"
             placeholder="{{home?.country? home.name +', '+ home.country.name : defaultCityPlaceHolder}}"
             [formControl]="countryCtrl"
             [matAutocomplete]="countryAuto">
      <mat-autocomplete #countryAuto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="getSelectCountry($event)">
        <mat-option *ngFor="let list of destList"
                    [value]="list"
                    [innerHTML]="list.innerHTML"></mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div>
  <label class="add-place__checkbox-label"
         *ngIf="cannotFindInput && showCannotFindCheckbox">
    <input #cannotFindCheck
           class="input-checkbox"
           type="checkbox"
           [checked]="cannotFindCity"
           (change)="showAutoSelectGoogle(cannotFindCheck.checked)"/>
    I cannot find a city on the list, I'd rather use a search form
  </label>

  <div class="autocomplete__container p-top cannotFindCity"
       [hidden]="!cannotFindCity">
    <mat-form-field subscriptSizing="dynamic">
      <input type="text"
             matInput
             autocomplete="off"
             class="hide-placeholder-focus"
             placeholder="{{home?.country? home.name +', '+ home.country.name : defaultCityPlaceHolder}}"
             #addressText
             [formControl]="googleCountryCtrl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectDataPlace($event)">
        <mat-option *ngFor="let option of listItems" [value]="option.description">
          {{option.description }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>

<div class="alert alert-danger" *ngIf="error">{{ error }}</div>

<div class="map-container">
  <h3 class="heading-text" *ngIf="mapHeadingText">{{mapHeadingText}}</h3>
  <label class="help-text" *ngIf="mapHelpText">{{mapHelpText}}</label>

  <google-map
    class="map"
    *ngIf="withMap && (mapApiLoaded$ | async)"
    [center]="{lat, lng}"
    [zoom]="zoomDefault"
    [options]="{
      disableDefaultUI: true,
      gestureHandling: 'greedy',
      zoomControl: true
    }"
    (mapInitialized)="mapReadyHandler($event)">
    <map-marker
      [options]="{
        draggable: true,
        animation: 0.0
      }"
      [position]="{lat,lng}"
      (mapDragend)="onChangeCoordinates($event)">
    </map-marker>
  </google-map>
</div>
