import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user-token.service';
import { LanguageService } from './language.service';
import { map } from 'rxjs/operators';
import { Config } from 'protractor';
import { SsrService } from './ssr.service';
import { IS_CACHE_ENABLED } from './request-interceptor.service';
import { XhrHttpClient } from "./xhr-client.service";

@Injectable()
export class HttpService {

  public apiUrl: string;

  private readonly ssrApiUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private languageService: LanguageService,
    private ssrService: SsrService,
    private xhrHttpClient: XhrHttpClient,
  ) {
    this.apiUrl = environment.apiUrl;
    this.ssrApiUrl = environment.SSRApiUrl;
  }

  private getBaseUrl() {
    return (this.ssrService.isBrowser() ? this.apiUrl : this.ssrApiUrl) + '/';
  }

  public get(route: string, params?: any, options?: any) {
    const token = this.userService.getToken() || '';
    if (!params) {
      params = new HttpParams();
    }
    params = params.append('_lang', this.languageService.curLangLabel);
    const optionsOfQuery: any = {params};
    optionsOfQuery.headers = this.createAuthorizationHeader(token);
    optionsOfQuery.observe = 'response';
    optionsOfQuery.context = new HttpContext().set(
      IS_CACHE_ENABLED,
      options && typeof options.withCache !== undefined ? options.withCache : true
    );

    const result: any = this.http.get<Config>((this.getBaseUrl()) + route, optionsOfQuery)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (options && options.withHeaders) {
            return {
              ...response.body,
              __headers: response.headers
            };
          }
          return response.body;
        })
      );
    return result;
  }

  public post(route: string, params?: any, httpQueryParams?: HttpParams, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'events';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    const token = this.userService.getToken() || '';
    const optionsOfQuery: any = {observe: 'response'};

    optionsOfQuery.headers = this.createAuthorizationHeader(token);
    optionsOfQuery.params = httpQueryParams;
    if (options) {
      optionsOfQuery.options = options;
    }
    const result: any = this.http.post(`${(this.getBaseUrl())}${route}?_lang=${this.languageService.curLangLabel}`, params, optionsOfQuery);
    return result;
  }

  public put(route: string, params?: any, httpParams?: HttpParams) {
    const token = this.userService.getToken() || '';
    const optionsOfQuery: any = {observe: 'response'};

    optionsOfQuery.headers = this.createAuthorizationHeader(token);
    optionsOfQuery.params = httpParams;
    const result: any = this.http.put(`${(this.getBaseUrl())}${route}?_lang=${this.languageService.curLangLabel}`, params, optionsOfQuery);
    return result;
  }

  public uploadPost(route: string, body?: any, params?: any) {
    const result: any = this.http.post(`${route}?_lang=${this.languageService.curLangLabel}`, body, params);
    return result;
  }

  public uploadPostWithProgress(route: string, body?: any) {
    const result: any = this.xhrHttpClient.post(
      `${route}?_lang=${this.languageService.curLangLabel}`,
      body,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
    return result;
  }

  public patch(route: string, body?: any, options?: any, params?: any) {
    const token = this.userService.getToken() || '';
    let optionsOfQuery: any = {};
    if (options) {
      optionsOfQuery = {observe: 'response', options};
    }
    optionsOfQuery.headers = this.createAuthorizationHeader(token);
    if (params) {
      optionsOfQuery.params = params;
    }

    const result: any = this.http.patch(
      `${(this.getBaseUrl())}${route}?_lang=${this.languageService.curLangLabel}`,
      body,
      optionsOfQuery
    );
    return result;
  }

  public delete(route: string, params?: any, options?: any) {
    const token = this.userService.getToken() || '';
    if (!params) {
      params = new HttpParams();
    }
    params.append('_lang', this.languageService.curLangLabel);
    const optionsOfQuery: any = {observe: 'response', options, params};
    optionsOfQuery.headers = this.createAuthorizationHeader(token);

    const result: any = this.http.delete(`${(this.getBaseUrl())}${route}`, optionsOfQuery);
    return result;
  }

  private createAuthorizationHeader(token: any, contentType = 'application/json') {
    let headers = new HttpHeaders()
      .set('Content-Type', contentType)
      .set('Accept', 'application/json; version=v1')
      .set('Accept-Language', this.languageService.setLngToHeaders);
    if (token !== '') {
      headers = headers.set('Authorization', `Bearer ${token} `);
    }
    headers = this.addEssentialSSRHeaders(headers);

    return headers;
  }

  private addEssentialSSRHeaders(headers: HttpHeaders) {
    if (this.ssrService.isSSR()) {
      const request = this.ssrService.getRequest();
      const serverRequestHeaders = request.headers;
      headers = headers.set('ssr-user-agent', serverRequestHeaders['user-agent'] || '')
        .set('pre-referer', serverRequestHeaders.referer || '')
        .set('ssr-x-forwarded-for', serverRequestHeaders['x-forwarded-for'] || '')
        .set('ssr-referer', ((request.protocol) ? request.protocol + '://' : null)
          + (serverRequestHeaders[':authority'] || serverRequestHeaders.host) + request.originalUrl);
      if (typeof serverRequestHeaders['cf-connecting-ip'] !== 'undefined') {
        headers = headers.set('ssr-cf-connecting-ip', serverRequestHeaders['cf-connecting-ip']);
      }
      if (typeof serverRequestHeaders['cf-ipcountry'] !== 'undefined') {
        headers = headers.set('ssr-cf-ipcountry', serverRequestHeaders['cf-ipcountry']);
      }
    }

    return headers;
  }

}
