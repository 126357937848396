<div class="confirm-modal">
  <div class="confirm-dialog">
    <section>
      <header>
        <h3 *ngIf="messageToRender"> {{messageToRender}} </h3>
      </header>
      <button [innerText]="'buttons.save' | translate" class="btn btn-save" (click)="saveResults()" i18n="buttons.save">
        save
      </button>

      <button [innerText]="'buttons.cancel' | translate" class="btn " (click)="decline()" i18n="buttons.cancel">
        cancel
      </button>
    </section>
  </div>
</div>
