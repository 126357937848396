export let sortGroupBy = (grouped, weight) => {
  return grouped.sort((g1, g2) => {
    if (g1[weight] === g2[weight]) {
      if (g1.name === g2.name) {
        return (g1.population < g2.population) ? -1 : 1;
      } else {
        return (g1.name < g2.name) ? -1 : 1;
      }
    } else {
      return g1[weight] > g2[weight] ? -1 : 1;
    }
  });
};
