import {
  TOOLTIP_BOTTOM_ARROW_HEIGHT,
  TOOLTIP_HEIGHT,
  TOOLTIP_HEIGHT_MARGIN_TOP,
  TOOLTIP_WIDTH
} from '../../../../constants';

export let normalizeNWPoint = function (placePoint: any) {
  return {
    x: placePoint.x - (TOOLTIP_WIDTH / 2),
    y: placePoint.y - (TOOLTIP_HEIGHT + TOOLTIP_HEIGHT_MARGIN_TOP + TOOLTIP_BOTTOM_ARROW_HEIGHT)
  };
};
