import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { select, Store } from '@ngrx/store';
import { getFavoritesList, getPinnedList, State } from '../../../../store/reducers';
import { switchMap } from 'rxjs/operators';
import { createAddCity, RemoveCity } from '../../../../store/user-cities/user-cities.actions';
import { SetSearchPanelState } from '../../../../store/layer/layer.actions';
import { MapDateType, PlaceNew, PlacePinType } from '../../../../interfaces';
import { UserAccountService, UserService } from '../../../../services';
import { Journey } from '../../../../interfaces/journey';
import { Subject } from 'rxjs/internal/Subject';
import { DestinationType } from "../../../../enums/destination-type";

@Component({
  selector: 'app-static-pin',
  templateUrl: 'static-pin.component.html',
  styleUrls: ['static-pin.component.scss']
})
export class StaticPinComponent implements OnInit, OnChanges, OnDestroy {

  @Input() buttonTmpl = false;
  @Input() isVisibleTextBtn = false;
  @Input() place: any;
  @Input() tooltips: string;
  @Input() tooltipText: string = '';

  @Output() pinPlace = new EventEmitter<PlaceNew>();

  public isWannaGoCity$: Observable<Boolean>;
  public isLiked$: Observable<Boolean>;
  public pinState: Boolean;
  public isShowTooltip: Boolean;
  public userIsLogged: Boolean;
  public defaultJourney: Journey;

  protected $destroyed = new Subject<void>();

  constructor(
    protected store$: Store<State>,
    protected userAccountService: UserAccountService,
    protected userService: UserService,
    protected cdRef: ChangeDetectorRef
  ) {
    this.userIsLogged = this.userService.loggedIn();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private init(): void {
    this.isWannaGoCity$ = this.store$.pipe(
      select(getPinnedList),
      switchMap(data => {
        let val = false;
        data.forEach(item => {
          if (this.place && item.oid === this.place.id && item.otype === this.place.otype) {
            val = true;
          }
        });
        this.pinState = val;
        return of(val);
      })
    );

    this.isLiked$ = this.store$.pipe(
      select(getFavoritesList),
      switchMap(data => {
        let val = false;
        if (this.place) {
          if (this.place.otype === DestinationType.City) {
            data?.cities?.forEach(item => {
              if (item.oid === this.place.id && item.otype === this.place.otype) {
                val = true;
              }
            });
          } else if (this.place.otype === DestinationType.Place) {
            data?.places?.forEach(item => {
              if (item.oid === this.place.id && item.otype === this.place.otype) {
                val = true;
              }
            });
          } else if (this.place.otype === DestinationType.Country) {
            data?.countries?.forEach(item => {
              if (item.oid === this.place.id && item.otype === this.place.otype) {
                val = true;
              }
            });
          } else if (this.place.otype === DestinationType.Journey) {
            data?.collections?.forEach(item => {
              if (item.oid === this.place.id && item.otype === this.place.otype) {
                val = true;
              }
            });
          }
        }
        return of(val);
      })
    );
  }

  public togglePinned() {
    if (this.userAccountService.isPinLoading) {
      return;
    }
    const nextState = !this.pinState;
    this.store$.dispatch(new SetSearchPanelState(false));

    this.place = JSON.parse(JSON.stringify(this.place));

    if (nextState) {
      this.store$.dispatch(createAddCity(
        this.place,
        PlacePinType.WannaGo,
        MapDateType.Month,
        new Date(),
        null,
        this.defaultJourney
      ));
    } else {
      this.store$.dispatch(
        new RemoveCity({
          oid: Number(this.place.id),
          otype: this.place.otype,
          type: PlacePinType.WannaGo
        })
      );
      this.toggleShowTooltip();
    }
    this.place.pinned = nextState;
    this.pinPlace.emit(this.place);
  }

  public toggleShowTooltip(isShow = true) {
    this.isShowTooltip = isShow;
    if (isShow) {
      setTimeout(() => {
        this.isShowTooltip = !isShow;
      }, 2000);
    }
  }
}
