import { Injectable, OnDestroy } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getSearchData, State } from '../reducers';
import { FlyCookieService } from '../../services/fly-cookie/fly-cookie.service';
import * as fromAuth from './auth.actions';
import { RestoreCurrentHome } from './auth.actions';
import * as fromLayer from '../layer/layer.actions';
import { InfoService, SsrService, UserAccountService, UserService } from '../../services';
import { DestinationType } from '../../enums/destination-type';
import { Subject } from 'rxjs/internal/Subject';
import { SuccessLoadNotifications } from "../user-notifications/user-notifications.actions";
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from "../layer";
import { SearchStateForm } from "../../interfaces";

@Injectable({providedIn: 'root'})
export class AuthEffects implements OnDestroy {
  private COOKIE_COORDINATES_KEY = 'current-coordinates';
  private $destroyed = new Subject<void>();
  private isUserProfileLoading: boolean;

  $setCurrentCoordinates = createEffect(() => this.actions$.pipe(
    ofType<fromAuth.RestoreCurrentCoordinates>(fromAuth.AuthActionsTypes.SetCurrentCoordinate),
    map(action => {
      this.cookieService.set(
        this.COOKIE_COORDINATES_KEY,
        JSON.stringify(action.payload),
        30,
        '/'
      );
    })
  ), {dispatch: false});


  $getBrowserGeoPosition = createEffect(() => this.actions$.pipe(
    ofType<fromAuth.GetBrowserGeoPosition>(fromAuth.AuthActionsTypes.GetBrowserGeoPosition),
    map(() => {
      this.getBrowserGeoPosition();
    })
  ), {dispatch: false});

  constructor(
    private infoService: InfoService,
    private store$: Store<State>,
    private actions$: Actions,
    private cookieService: FlyCookieService,
    private ssrService: SsrService,
    private userService: UserService,
    private userAccountService: UserAccountService,
  ) {
    this.getGeoPosition();
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  getGeoPosition$: Observable<fromAuth.AuthActionsUnion> = createEffect(() => this.actions$.pipe(
    ofType<fromAuth.CheckGeoPosition>(fromAuth.AuthActionsTypes.CheckGeoPosition),
    switchMap(() =>
      this.infoService.getClientInfoWithoutUpdating(new HttpParams().append('expand', 'country,picture,settings,homeCity.country,urlSetting,createdPlacesPics')).pipe(
        map((data: any) => {
          if (!data) {
            return null;
          }

          if (!data.isGuest) {
            this.store$.dispatch(
              new SuccessLoadNotifications({
                  items: [],
                  _meta: {},
                  _extra: {unreadCount: data.notificationUnreadCount}
                },
                !data.isGuest
              ));
          }

          return new fromAuth.AuthActions.SetSessionData({
            social: data.auth,
            home: {
              id: data.city.id,
              name: data.city.name,
              user: data.user,
              population: data.city.population,
              popular: data.popular,
              coordinates: `${data.city.lat},${data.city.lng}`,
              countryCode: data.city.country.id,
              iataCode: data.city.iataCode,
              country: data.city.country
            },
            loggedIn: !data.isGuest
          });
        }),
        catchError((error: Error) => of(new fromAuth.CheckGeoPositionFailure({error}))),
      )),
  ));

  loadUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType<fromAuth.CheckGeoPosition>(fromAuth.AuthActionsTypes.LoadUserProfile),
    map(() => {
      if (!this.userService.loggedIn() || this.isUserProfileLoading) {
        return;
      }
      this.store$
        .pipe(
          take(1),
          select(getSearchData),
          map((searchState: SearchStateForm) => {
            const httpParams = new HttpParams()
              .append('expand', 'picture,settings,homeCity.country,auth,urlSetting')
              .append('filter[cityFrom]', (searchState.cityFrom) ? searchState.cityFrom.id.toString() : '')
              .append('filter[paxCount]', searchState.passengers.toString())
              .append('filter[flightType]', transformTripTypeToApi(searchState.tripType))
              .append('filter[dateType]', transformDateToApiDateType(searchState.date).toString())
              .append('filter[when]', transformWhenDateToApiDateType(searchState.date))
              .append('filter[return]', transformReturnToApiDateType(searchState.date) || '');
            this.isUserProfileLoading = true;
            this.userAccountService.getProfileData(httpParams)
              .pipe(
                take(1),
                takeUntil(this.$destroyed)
              )
              .subscribe(
                (response: any) => {
                  this.isUserProfileLoading = false;
                  this.store$.dispatch(
                    new RestoreCurrentHome({
                      id: response.homeCity?.id,
                      name: response.homeCity?.name,
                      country: response.homeCity?.country,
                      coordinates: (response.homeCity ? `${response.homeCity.lat},${response.homeCity.lng}` : null),
                      population: response.homeCity?.population,
                      popular: response.homeCity?.popular,
                      user: response
                    })
                  );
                });
          })
        )
        .subscribe();
    })
  ), {dispatch: false});

  private getBrowserGeoPosition() {
    let watchDogTimer;

    return Promise.resolve()
      .then(
        () =>
          new Promise(resolve => {
            watchDogTimer = setTimeout(() => resolve(null), 10000);
            if (this.ssrService.isBrowser()) {
              navigator.geolocation.getCurrentPosition(
                position => resolve(position),
                () => resolve(null),
                {timeout: 10000, enableHighAccuracy: true}
              );
            } else {
              resolve(null);
            }
          })
      )
      .then((position: any) => {
        clearTimeout(watchDogTimer);
        let httpParams = new HttpParams().append('expand', 'country,picture,settings,homeCity.country,urlSetting,createdPlacesPics');
        if (position) {
          this.store$.dispatch(
            new fromAuth.AuthActions.SetCurrentCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
          );

          httpParams = httpParams.append(
            'filter[lat]',
            position.coords.latitude
          );
          httpParams = httpParams.append(
            'filter[lng]',
            position.coords.longitude
          );
        }
        this.infoService
          .getClientInfo(httpParams)
          .subscribe((data: any) => {
            // @todo @neck @iterations @check Call many time.
            if (data) {
              const home = {
                id: data.city.id,
                name: data.city.name,
                user: data.user,
                population: data.city.population,
                popular: data.popular,
                coordinates: `${data.city.lat},${data.city.lng}`,
                countryCode: data.city.country.id,
                iataCode: data.city.iataCode,
                country: data.city.country
              };

              this.store$.dispatch(
                new fromAuth.AuthActions.SetSessionData({
                  social: data.auth,
                  home: home,
                  loggedIn: !data.isGuest
                })
              );
              this.store$.dispatch(
                new fromLayer.SetCityFrom({
                  ...home,
                  type: DestinationType.City,
                  countryCode: data.city.country.id,
                })
              );

              if (!data.isGuest) {
                this.store$.dispatch(
                  new SuccessLoadNotifications({
                      items: [],
                      _meta: {},
                      _extra: {unreadCount: data.notificationUnreadCount}
                    },
                    !data.isGuest
                  ));
              }
            }
          });
      });
  }

  private getGeoPosition() {
    this.infoService.getClientInfoWithoutUpdating(
      new HttpParams().append('expand', 'country,picture,settings,homeCity.country,urlSetting,createdPlacesPics')
    )
      .pipe(takeUntil(this.$destroyed))
      .subscribe((data: any) => {
        // @todo @neck @iterations @check Call many time.
        if (data) {
          this.store$.dispatch(
            new fromAuth.AuthActions.SetSessionData({
              social: data.auth,
              home: {
                id: data.city.id,
                name: data.city.name,
                user: data.user,
                population: data.city.population,
                popular: data.popular,
                coordinates: `${data.city.lat},${data.city.lng}`,
                countryCode: data.city.country.id,
                iataCode: data.city.iataCode,
                country: data.city.country,
              },
              loggedIn: !data.isGuest
            })
          );

          if (!data.isGuest) {
            this.store$.dispatch(
              new SuccessLoadNotifications({
                  items: data.notifications,
                  _meta: {totalCount: data.notificationCount},
                  _extra: {unreadCount: data.notificationUnreadCount}
                },
                !data.isGuest
              ));
          }
        }
      });
  }
}
