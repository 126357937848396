import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { StaticService } from "./static.service";

@Injectable()
export class MapService {
  public map: any;
  public filters: any = {};
  public isMapItProcess: boolean;

  constructor(
    private httpService: HttpService,
    private staticService: StaticService,
  ) {
  }

  public searchPlaces(params?: any, options?: any) {
    return this.httpService.get(`map/place`, params, options);
  }

  public getCurrentViewportCoordinates() {
    if (this.map.getBounds()) {
      const northEast = this.map.getBounds().getNorthEast();
      const southWest = this.map.getBounds().getSouthWest();
      this.staticService.currentViewportCoordinates = {
        latNorth: northEast.lat(),
        latSouth: southWest.lat(),
        lngEast: northEast.lng(),
        lngWest: southWest.lng()
      };
    }
  }
}
