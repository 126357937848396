import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UserService } from '../../services/user-token.service';
import { consoleDebug } from '../../libraries/console-debug';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-action',
  templateUrl: 'confirm-action.component.html',
  styleUrls: ['confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnChanges {

  @Input() newMessageId: string;
  @Output() agreed: EventEmitter<any> = new EventEmitter<any>();

  public messageToRender: string;

  constructor(
    private userService: UserService
  ) {
  }

  ngOnChanges() {
    if (!this.newMessageId) return;
    let id = this.newMessageId;
    this.saveTranslationIntoProperty(id, 'messageToRender');
  }

  saveResults() {
    this.agreed.emit(true);
  }

  decline() {
    this.agreed.emit(false);
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    // i18n
    this.userService.getTranslation(id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this[whereToSave] = res;
        },
        (error) => {
          consoleDebug('error', error);
        }
      );
  }

}
