<a (mouseenter)='overImage()'
   (mouseleave)='outImage()'>
  <div class="wrapper">
    <app-close-btn *ngIf="showCloseButton"
                   class="close"
                   (click)="onClosePopup()">
    </app-close-btn>
    <div
      [appRouterLink]="getLinkByObject(place)">
      <div class="header">
        <div class="place-image">
          <img [src]="imageSrc" [alt]="place.name" [class.video]="hasVideo()">
        </div>
        <div class="place-name">
          {{ place.name }}
        </div>
      </div>

      <div class="description">
        <ng-container *ngIf="!place['placeType']  || +place['placeType'] ===  seeDoPlaceTypes.Place && locationData">
          <div class="description__location"
               *ngIf="!place['placeType']  || +place['placeType'] ===  seeDoPlaceTypes.Place">
            <img *ngIf="locationData?.countryId" class="country-icon"
                 [src]="getCountryFlag({id: locationData.countryId})"
                 [title]="locationData.countryName">
            {{ locationData.name }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</a>
