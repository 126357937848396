import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumbers'
})
export class FormatNumbersPipe implements PipeTransform {

  transform(value: any, args: string = ' '): any {
    if (value) {
      let val = value.toString().split('').reverse().join('');
      let result = '';
      for (let i = 0; i < val.length; i += 3) {
        result += `${val.slice(i, i + 3)}${args}`;
      }
      return result.split('').reverse().join('').slice(1);
    }
    return value;
  }

}
