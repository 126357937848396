import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AjaxFinish } from '../../../../store/layer';
import { delay, mergeMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getSearchData, State } from '../../../../store/reducers';
import { SsrService, UserService } from '../../../../services';
import cloneDeep from 'lodash/cloneDeep';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { PlaceNew, PlacePicture } from '../../../../interfaces';
import { StaticRouteType } from '../../enums/route-type';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StaticService } from "../../../../services/static.service";
import { ActivatedRoute } from "@angular/router";
import { StaticInitService } from "../../services/static-init.service";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { of } from 'rxjs/internal/observable/of';
import { StaticTransferStateKey } from "../../../../enums/static-transfer-state-key.enum";

@UntilDestroy()
@Component({
  selector: 'app-static-city-info',
  templateUrl: './static-city-info.component.html',
  styleUrls: ['./static-city-info.component.scss'],
})
export class StaticCityInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() place; // : Place;
  @Input() staticType: StaticRouteType;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;

  @Output() addPlace = new EventEmitter<void>();
  @Output() changeVisibleTooltipPlaceEmitter = new EventEmitter<any>();
  @Output() pinPlaceEmitter = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
    this.checkWindow();
  }

  public activeIndex: number;
  public dynamicCountOfColumns = typeof window !== 'undefined' && (window.innerWidth < 1024 || window.innerWidth > 1365) ? 3 : 2;
  public previewSliderImages: PlacePicture[] = [];
  public subscriptions: Subscription = new Subscription();
  public userIsLogged: boolean;
  public page: StaticRouteType = StaticRouteType.City;

  constructor(
    protected store$: Store<State>,
    private userService: UserService,
    @Inject(DOCUMENT) protected document: any,
    protected staticService: StaticService,
    private activatedRoute: ActivatedRoute,
    private ssrService: SsrService,
    private staticInitService: StaticInitService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.userIsLogged = this.userService.loggedIn();
  }

  private static getUTCMinutes(value: string): string {
    let time = value.toString();
    if (time) {
      const arr = time.split('.');
      if (arr.length === 2) {
        switch (arr[1]) {
          case '25':
            time = arr[0] + ':15';
            break;
          case '5':
            time = arr[0] + ':30';
            break;
          case '75':
            time = arr[0] + ':45';
            break;
          default:
            break;
        }
      }
    }
    return time;
  }

  ngOnChanges(changes: any) {
    if (changes['place'] && changes['place'].currentValue) {
      this.checkCityData(changes['place'].currentValue);
    }
    if (changes['resizeWindow'] && changes['resizeWindow'].currentValue) {
      this.resizeWindow = changes['resizeWindow'].currentValue;
      this.checkWindow();
    }
  }

  ngOnInit(): void {
    this.staticService.setStaticType(this.activatedRoute.snapshot.data['static']);
    this.cdRef.detectChanges();

    this.activatedRoute.params.pipe(
      untilDestroyed(this)
    ).subscribe((params) => {
      this.staticService.isMainInfoLoaded = false;
      this.staticService.isInit = true;
      this.staticService.routeParams = params;
      this.staticService.currentPlaceItemsList = [];
      this.getCityInfo(this.staticService.routeParams.id);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // INIT StaticRouteType.CITY:
  private getCityInfo(id: string): void {
    this.staticService.pageCount = 1;
    this.staticService.totalCount = 100;

    this.subscriptions.add(
      combineLatest([
        this.store$.pipe(select(getSearchData))
      ])
        .pipe(
          take(1),
          mergeMap(([homeData]) => {
            const ssrKey = `${StaticTransferStateKey.CityInfoKey}_${id}`;
            const ssrData = this.ssrService.getState(ssrKey);

            if (!ssrData) {
              return this.staticInitService.getCityData(
                {...homeData, cityID: id},
                this.staticService.isMobileDesign() ? this.staticService.placesLimitMobile : null
              );
            } else {
              this.ssrService.removeState(ssrKey);
              return of({
                ...this.staticService.searchData,
                data: ssrData
              });
            }
          }),
          delay(0),
        ).subscribe((response) => {
        if (!response) {
          return;
        }

        const city = cloneDeep(response.data);
        const placesCount = city.places.length;
        city.similarPlaces = city.places.concat(city.similarPlaces);

        const countries = [];
        city.similarPlaces.map((item) => {
          if (countries.join(',').length + item.name.length <= 118) {
            countries.push(item.name);
          }
        });

        // We already have data for first (pageCount=0) page from `.places` or `.similarPlaces`
        this.staticService.pageCount = 1;
        // If count of places is less than the limit it means this is all places that city has
        // Otherwise we specify totalCount = limit + 1 to make request for another page
        // and the real totalCount will be updated from _meta of request to the second page of `/info/place`
        this.staticService.totalCount =
          placesCount < city.placesLimit
            ? placesCount
            : city.placesLimit + 1;
        // Init loading similar places
        if (placesCount < city.placesLimit) {
          this.staticService.currentThirdRequestCount = 1;
          // It will be updated after the second request, it's just need to init infinite loop for city similar places
          this.staticService.pageCount = 2;
        }
        this.staticService.setSeoDataSubPages(city, countries);
        this.staticService.mapData(city);
        this.checkCityData(this.staticService.showPlace);
        this.store$.dispatch(new AjaxFinish({uid: null}));
      }, (error) => {
        if (error.status === 404) {
          this.ssrService.redirect(301, '/404');
        }
      })
    );
  }

  public checkCityData(place) {
    this.place = place;
    this.place = {
      ...place,
      isPlace: false,
    };
    this.previewSliderImages = [];

    if (this.place.pictures) {
      if (this.place.pictures['head']) {
        this.previewSliderImages.push(this.place.pictures['head']);
      }
      this.previewSliderImages = this.previewSliderImages.concat(this.place.pictures['other'].slice(0, 5));
    }
  }

  public pinPlace(event: PlaceNew): void {
    this.pinPlaceEmitter.emit(event);
  }

  public getCityUTC(): string {
    let value = null;
    if (this.place && this.place.hasOwnProperty('timeOffset') && this.place['dst'] !== null) {
      const utcValue = this.place['timeOffset'];
      if (utcValue !== null || utcValue !== 'undefined') {
        const sign = Math.sign(utcValue);
        if (sign === 1) {
          value = this.place['dst']
            ? StaticCityInfoComponent.getUTCMinutes('+' + (utcValue + 1))
            : StaticCityInfoComponent.getUTCMinutes('+' + utcValue.toString());
        } else {
          value = this.place['dst']
            ? StaticCityInfoComponent.getUTCMinutes((utcValue + 1).toString())
            : StaticCityInfoComponent.getUTCMinutes(utcValue.toString());
        }
      }
    }
    return value;
  }

  public checkWindow() {
    (this.resizeWindow < 1024 || this.resizeWindow >= 1366) ?
      this.dynamicCountOfColumns = 3 :
      this.dynamicCountOfColumns = 2;
  }
}
