export enum StaticTransferStateKey {
  CategoryInfoKey = 'category-info',
  CityInfoKey = 'city-info',
  CountryInfoKey = 'country-info',
  PlaceInfoKey = 'place-info',
  ReviewInfoKey = 'review-info',
  SearchInfoKey = 'search-info',
  TravelInfoKey = 'travel-info',
  UserInfoKey = 'user-info',
  UserJourneyKey = 'user-journey',
  JourneyKey = 'journey',
  PlaceReviewByIdKey = 'place-review-by-id-key',
}
