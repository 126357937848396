import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import chunk from 'lodash/chunk';

import { WhenDate } from "../../../../../../interfaces";
import { DatePickerMode } from "../../../../../../interfaces/datepicker-date";

dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-month-view',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss']
})
export class MonthViewComponent implements OnChanges {

  @Input() minDateLimit: WhenDate = null;
  @Input() date = dayjs();
  @Output() selectEmitter = new EventEmitter<any>();

  public months: any[];
  public prevAction = false;
  public today = dayjs();
  public year: string;

  private workingDate = dayjs();

  constructor() {
    this.today = dayjs();
  }

  ngOnChanges(): void {
    this.workingDate = this.date;
    this.gridCalculating(this.workingDate);
  }

  selectMonth(month) {
    if (!month.isBefore) {
      this.months.forEach((chunk) => chunk.forEach(item => item.selected = false));
      month.selected = true;
      this.selectEmitter.emit(month);
    }
  }

  updateYear(value: number) {
    const nextData = dayjs(this.workingDate).add(value, 'year');

    if (nextData.isBefore(this.today, 'years')) {
      this.prevAction = false;
      return;
    } else {
      this.prevAction = true;
    }

    this.workingDate = nextData;
    this.gridCalculating(nextData);
  }

  private checkIsBefore(date: Dayjs) {
    return (date.isBefore(this.today, 'month'))
      ? true
      : (this.minDateLimit && date.isSameOrBefore(this.minDateLimit.date, 'month'));
  }

  private gridCalculating(date: Dayjs) {
    this.year = date.format('YYYY');
    let startOfYear = dayjs(date).startOf('year');
    const endOfYear = dayjs(date).endOf('year');
    const monthList = [];
    while (startOfYear.isSameOrBefore(endOfYear)) {
      monthList.push({
        type: DatePickerMode.Month,
        title: startOfYear.format('MMM'),
        value: startOfYear.format('M'),
        date: dayjs(startOfYear),
        isBefore: this.checkIsBefore(startOfYear),
        selected: this.date.format('MM.YYYY') === startOfYear.format('MM.YYYY')
      });
      startOfYear = startOfYear.add(1, 'month');
    }
    this.months = chunk(monthList, 3);
  }

}
