import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../../../environments/environment';
import { InfoService, MapService, SsrService, UserService, WindowRef } from '../../../../services';
import {
  AjaxFinish,
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../../../../store/layer';
import { StaticRouteType } from '../../enums/route-type';
import { CategoryModeType, SelectMode } from '../static-categories/static-categories.component';
import { select, Store } from "@ngrx/store";
import { getHomeData, State } from '../../../../store/reducers';
import { map, mergeMap, skip, take, takeUntil } from 'rxjs/operators';
import { StaticInitService } from "../../services/static-init.service";
import { of } from "rxjs/internal/observable/of";
import { Subject } from "rxjs/internal/Subject";
import { parseCoords } from "../../../../libraries/map";
import { StaticService } from "../../../../services/static.service";
import { UserCollectionService } from "../../../../services/user-collection.service";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SeeDoMapClass } from "../../../../abstracts/right-side-map-class";
import { DOCUMENT } from "@angular/common";
import { HelperService } from "../../../../services/helper.service";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { UserPage, UserUrlSettings } from "../../../../interfaces/user";
import { Journey } from "../../../../interfaces/journey";
import cloneDeep from 'lodash/cloneDeep';
import { getUserNameByObject } from "../../../../libraries/get-user-name";
import { getCountryFlag } from "../../../../libraries/get-coutnry-flag";
import { Observable } from 'rxjs';
import { getUserAvatarByObject } from "../../../../libraries/get-user-avatar";
import { constructBaseUrl } from "../../../../libraries";
import { StaticTransferStateKey } from "../../../../enums/static-transfer-state-key.enum";

interface AllCollectionsProfilePageInterface {
  items: Journey[],
  _links: any,
  _meta: any,
  _extra: any,
}

@UntilDestroy()
@Component({
  selector: 'app-static-user-personal-info',
  templateUrl: './static-user-personal-info.component.html',
  styleUrls: ['./static-user-personal-info.component.scss'],
})
export class StaticUserPersonalInfoComponent extends SeeDoMapClass implements OnInit, OnChanges, OnDestroy {

  @ViewChild('content_tabs') contentTabs: ElementRef<HTMLDivElement>;

  protected readonly categoryType = CategoryModeType;
  protected readonly staticRouteType = StaticRouteType;
  protected readonly mediaUrl = environment.mediaUrl;
  protected placesLimit = 33;
  protected readonly scrollDistance = 1;
  protected readonly scrollUpDistance = 2;
  protected readonly throttle = 300;
  protected readonly tabsObj = {
    listPlace: {
      id: 'listPlace',
      name: 'List of Places',
    },
    journeys: {
      id: 'journeys',
      name: 'Journeys',
    }
  };
  protected readonly getLinkByObject = getLinkByObject;
  protected readonly getUserNameByObject = getUserNameByObject;
  protected readonly getCountryFlag = getCountryFlag;
  protected readonly getUserAvatarByObject = getUserAvatarByObject;
  protected readonly constructBaseUrl = constructBaseUrl;
  protected readonly selectMode = SelectMode;

  protected userProfile: UserPage;
  protected followers: UserPage[] = [];
  protected tags = [];
  protected userIsLogged: boolean;
  protected tabs: any[] = [];
  protected selectedTab: string;
  protected homeLocation = {
    country: null,
  };
  protected userHome: any;
  protected allCollections: AllCollectionsProfilePageInterface;
  protected filteredCollections: AllCollectionsProfilePageInterface;
  protected filterVisibilityDelay = 0;
  protected isErrorCollection: boolean;
  protected isEmptyCollection: boolean;
  protected isCollectionLoad = false;
  protected placeFilterData: any = {cities: [], tags: [], countries: []};
  protected isFollowersVisible: boolean = false;
  protected currentExtra: any;
  protected isUserDomain: boolean;

  private isFilteredMode: boolean = false;
  private $destroyed: Subject<void> = new Subject<void>();
  private additionParams = {};
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected pageScrollService: PageScrollService,
    protected infoService: InfoService,
    protected userService: UserService,
    protected ssrService: SsrService,
    protected store$: Store<State>,
    public staticService: StaticService,
    protected staticInitService: StaticInitService,
    protected userCollectionService: UserCollectionService,
    protected route: ActivatedRoute,
    protected mapService: MapService,
    protected cdRef: ChangeDetectorRef,
    protected windowRef: WindowRef,
    protected helperService: HelperService,
    @Inject(DOCUMENT) protected document: any,
  ) {
    super(
      infoService,
      mapService,
      cdRef,
      store$,
      pageScrollService,
      document,
      windowRef,
      staticService,
      helperService,
    );
    this.isUserDomain = ssrService.getIsUserDomain();
    this.userIsLogged = this.userService.loggedIn();
    this.store$
      .pipe(
        select(getHomeData),
        take(1)
      ).subscribe((home) => {
      this.userHome = home?.user;
      this.staticService.homeData = home;
    });

    this.userCollectionService.isErrorCollection$
      .pipe(untilDestroyed(this))
      .subscribe((isError) => {
        if (isError && this.staticService.userInfo) {
          this.staticService.getErrorMessage();
          this.getSimilarPlaces();
        }
      });
  }

  ngOnInit(): void {
    if (this.staticService.isMobileDesign()) {
      this.placesLimit = 10;
    }
    this.staticService.isInit = true;
    this.staticService.setStaticType(this.route.snapshot.data['static']);

    this.route.params.pipe(
      untilDestroyed(this)
    ).subscribe((params) => {
      this.isFollowersVisible = false;
      this.staticService.resetListParams();
      this.placeFilterData.cities = [];
      this.placeFilterData.countries = [];
      this.placeFilterData.tags = [];
      this.staticService.routeParams = params;
      this.staticService.routeQueryParams = this.route.snapshot.queryParams;
      this.currentExtra = {};
      this.initFilteredModeParam();
      this.fetchUserData();
    });

    this.route.queryParams.pipe(
      // Skip first change of url, because we don't need first page of places,
      // they are already loaded in fetchUserData => user.places
      skip(1),
      untilDestroyed(this)
    ).subscribe((queryParams) => {
      this.staticService.routeQueryParams = queryParams;
      this.filterVisibilityDelay = 0;

      this.staticService.page = 0;
      this.prepareFilterParams();
      let observable = this.getUserPlaces();

      if (this.isAllFiltersRequired()) {
        this.fetchAllPlaceFilters(this.userProfile.id);
      } else {
        observable
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            let data = {
              filteredCountries: this.currentExtra?.countries,
              filteredCities: this.currentExtra?.cities,
              filteredTags: this.currentExtra?.tags,
            }
            this.preparePlaceFilters(data);
            this.sortPlaceFilters();
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userProfile'] && changes['userProfile'].currentValue) {
      this.processProfileData(changes['userProfile'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.subscriptions.unsubscribe();
    this.mapService.filters = {};
  }

  private hasQueryFilters(): boolean {
    const queriesUserPersonalPage = this.staticService.routeQueryParams;
    return !!(queriesUserPersonalPage.cityId || queriesUserPersonalPage.tagId || queriesUserPersonalPage.countryId);
  }

  private initFilteredModeParam(): void {
    this.isFilteredMode = this.hasQueryFilters();
  }

  private processProfileData(userInfo: any): void {
    this.staticService.userInfo = userInfo;
    this.homeLocation.country = userInfo?.homeCity?.country;
    this.staticService.userPlacesLength = userInfo.places && userInfo.places.length;
    this.staticService.page = 1;

    this.getUserJourneys();

    if (userInfo.tags) {
      userInfo.tags.forEach(tag => {
        this.tags = this.tags.concat(tag);
      });
    }

    this.staticService.getErrorMessage();
  }

  private getUserPlaces(): Observable<any> {
    let additionParamsKey = ''
    Object.keys(this.additionParams).forEach(key => {
      additionParamsKey += key + '_' + this.additionParams[key];
    });
    const page = this.staticService.page++;
    const ssrStateKey = `${StaticTransferStateKey.UserInfoKey}_${StaticTransferStateKey.PlaceInfoKey}_user_${this.staticService.userInfo.id}_not_similar_page_${page}_params_${additionParamsKey}`;

    return this.getPlaces(this.processUserPlaces, this.fetchUserPlaces, ssrStateKey);
  }

  private getSimilarPlaces(): void {
    const page = this.staticService.pageSimilar++;
    const ssrStateKey = `${StaticTransferStateKey.UserInfoKey}_${StaticTransferStateKey.PlaceInfoKey}_user_${this.staticService.userInfo.id}_similar_page_${page}`;

    this.getPlaces(this.processSimilarPlaces, this.fetchSimilarPlaces, ssrStateKey);
  }

  private getPlaces(processFunc: any, fetchFunc: any, ssrStateKey: string): Observable<any> {
    if (!this.staticService?.routeParams) {
      return null;
    }
    const ssrStateData = this.ssrService.getState(ssrStateKey);

    let observable: Observable<any>;
    if (ssrStateData) {
      this.ssrService.removeState(ssrStateData);
      observable = of(ssrStateData)
    } else {
      observable = fetchFunc(this);
    }
    observable
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          this.ssrService.setState(ssrStateKey, response);
          processFunc(this, response);
        },
        error: () => {
          // Do nothing on error, otherwise in ssr-mode will be fatal error
        }
      });

    return observable;
  }

  private processUserPlaces(self: this, data: any): void {
    if (self.staticService.page === 1) {
      self.userProfile.places = [];
    }
    self.userProfile.places = self.userProfile.places.concat(data.items);
    self.staticService.setCurrentPlace(JSON.parse(JSON.stringify(self.userProfile)));
    self.staticService.userPlacesLength = data.items.length;
    self.currentExtra = data._extra;
  }

  private processSimilarPlaces(self: this, data: any): void {
    self.staticService.similarPlaces = self.staticService.similarPlaces.concat(data.items);
    self.staticService.allPlaceCount = data._meta?.totalCount;
  }

  private fetchSimilarPlaces(self: this): Observable<any> {
    let httpParams = new HttpParams();
    if (self.staticService.userInfo?.id) {
      httpParams = httpParams.set('filter[userId][neq]', self.staticService.userInfo?.id?.toString());
    }
    httpParams = httpParams.set('expand', 'pictures,user,city.country,country,tags,city.flights,pinned,video,city.hasPage,user.urlSetting');
    httpParams = httpParams.set('page', self.staticService.pageSimilar.toString());

    return self.fetchPlaces(httpParams)
  }

  private fetchUserPlaces(self: this): Observable<any> {
    let httpParams = new HttpParams();
    if (self.staticService.userInfo?.id) {
      httpParams = httpParams.set('filter[userId]', self.staticService.userInfo?.id?.toString());
    }
    httpParams = httpParams.set('expand', 'pictures,city.country,country,tags,city.flights,pinned,video,city.hasPage');
    Object.keys(self.additionParams).forEach(key => {
      httpParams = httpParams.append(key, self.additionParams[key]);
    });
    httpParams = httpParams.set('page', self.staticService.page.toString());

    return self.fetchPlaces(httpParams)
  }

  private fetchPlaces(httpParams: HttpParams): Observable<any> {
    if (!this.staticService.userInfo?.id) {
      const homeCoordinates = this.userHome?.coordinates?.split(',');
      if (homeCoordinates?.length) {
        httpParams = httpParams.set('filter[sourceLat]', homeCoordinates[0].toString());
        httpParams = httpParams.set('filter[sourceLng]', homeCoordinates[1].toString());
      }
    }
    httpParams = httpParams.set('filter[cityFrom]', this.staticService.searchData.cityFrom.id.toString());
    httpParams = httpParams.set('filter[paxCount]', this.staticService.searchData.passengers.toString());
    httpParams = httpParams.set('filter[flightType]', transformTripTypeToApi(this.staticService.searchData.tripType));
    httpParams = httpParams.set('filter[dateType]', transformDateToApiDateType(this.staticService.searchData.date).toString());
    httpParams = httpParams.set('filter[when]', transformWhenDateToApiDateType(this.staticService.searchData.date));
    httpParams = httpParams.set('filter[return]', transformReturnToApiDateType(this.staticService.searchData.date) || '');

    return this.infoService.getPlaceList(httpParams);
  }

  private resetCollectionsParams(): void {
    this.allCollections = {items: [], _links: [], _extra: [], _meta: []};
    this.filteredCollections = {items: [], _links: [], _extra: [], _meta: []};
  }

  private getUserJourneys(): void {
    let subscription: Observable<any>;
    let stateKey = `${StaticTransferStateKey.UserJourneyKey}_${this.staticService.userInfo.id}`;
    this.isCollectionLoad = false;
    this.resetCollectionsParams();
    const ssrCacheData = this.ssrService.getState(stateKey);
    if (!ssrCacheData) {
      let httpParams = new HttpParams();
      httpParams = httpParams.set(
        'expand',
        'places.place.pictures,places.place.video,dates,_extra.tags,_extra.cities,_extra.countries'
      );
      httpParams = httpParams.set('filter[userId]', this.staticService.userInfo.id.toString());
      httpParams = httpParams.set('page', '0');

      subscription = this.infoService.getJourneyList(httpParams)
        .pipe(takeUntil(this.$destroyed),
          map(response => {
            this.ssrService.setState(stateKey, response);
            return response;
          })
        )
    } else {
      this.ssrService.removeState(stateKey);
      subscription = of(ssrCacheData);
    }

    subscription
      .pipe(takeUntil(this.$destroyed))
      .subscribe({
        next: (response: any) => {
          this.ssrService.setState(stateKey, response);
          this.allCollections = cloneDeep(response);
          this.allCollections.items = this.allCollections.items.filter((item: any) => item.places.length);
          this.filteredCollections = cloneDeep(this.allCollections);
          this.isEmptyCollection = !this.allCollections.items.length;

          this.generateTabs();
          if (((this.userHome && this.userHome.id !== this.staticService.userInfo.id) || !this.userHome) && this.isEmptyCollection) {
            const tabIndex = this.tabs.indexOf(this.tabsObj.journeys);
            if (tabIndex !== -1) {
              this.tabs.splice(tabIndex, 1);
            }
          }
          this.staticService.allCollectionCount = response._meta && response._meta.totalCount;
          this.isCollectionLoad = true;
        },
        error: () => {
          this.isCollectionLoad = true;
        }
      });
  }

  protected onScrollDown(): void {
    if (this.placesLimit === this.staticService.userPlacesLength) {
      this.getUserPlaces();
    } else if (
      !this.staticService.userPlacesLength &&
      this.staticService.similarPlaces.length < this.staticService.allPlaceCount
    ) {
      this.getSimilarPlaces();
    }
  }

  protected setSelectedTab(tabId: string): void {
    if (this.selectedTab !== tabId) {
      this.selectedTab = tabId;
    }
  }

  private generateTabs(): void {
    this.tabs = [this.tabsObj.listPlace];
    if (!this.isEmptyCollection) {
      this.tabs.push(this.tabsObj.journeys);
    }
    this.setSelectedTab(this.tabs?.length ? this.tabs[0].id : this.tabsObj.listPlace.id);
  }

  private fetchUserData(): void {
    this.staticService.isDeactivatedPage = false;

    let id = this.staticService.routeParams.id ? this.staticService.routeParams.id : null;
    let userName = this.staticService.routeParams.name
      ? this.staticService.routeParams.name
      : (this.route.snapshot.data['userName'] ? this.route.snapshot.data['userName'] : null);

    this.prepareFilterParams();

    let additionParamsKey: string;
    Object.keys(this.additionParams).forEach(key => {
      additionParamsKey += key + '_' + this.additionParams[key];
    });

    let stateKey = StaticTransferStateKey.UserInfoKey + '_' + this.route.snapshot.data['paramType'] + '_' + additionParamsKey + '_';
    const isNameMode = [UserUrlSettings.Name, UserUrlSettings.Subdomain].indexOf(this.route.snapshot.data['paramType']) !== -1;
    if (isNameMode) {
      stateKey += userName;
    } else {
      stateKey += id;
    }

    this.subscriptions.add(
      this.store$.pipe(select(getHomeData))
        .pipe(
          mergeMap((home) => {
            this.staticService.homeData = home;
            if (isNameMode) {
              return this.staticInitService.getUserInfoDataByName({
                ...this.staticService.searchData,
                id,
                userName
              }, this.additionParams, stateKey);
            } else {
              return this.staticInitService.getUserInfoData({
                ...this.staticService.searchData,
                id
              }, this.additionParams, stateKey);
            }
          })
        )
        .subscribe({
          next: (data: any) => {
            const userData = data?.data;
            if (!data || !userData) {
              return;
            }
            if (userData?.messageCode !== undefined) {
              this.isErrorCollection = true;
              this.staticService.setDeactivatedPageMode(userData.displayName, userData.messageCode, userData.id);
              this.userCollectionService.isErrorCollection$.next(true);
              return;
            }
            if (!userData.urlSetting) {
              // Fallback for users without setting
              userData.urlSetting = UserUrlSettings.Id;
            }
            // If user is on old page type - redirect him to correct one.
            if (userData.urlSetting !== this.route.snapshot.data['paramType']) {
              this.ssrService.redirect(301, getLinkByObject(userData));
              return;
            }

            const coordinates = this.staticService.homeData && parseCoords(this.staticService.homeData.coordinates);
            userData.lat = (userData.places && userData.places.length > 0) ? userData.places[0].lat : coordinates.lat;
            userData.lng = (userData.places && userData.places.length > 0) ? userData.places[0].lng : coordinates.lng;

            this.staticService.setSeoDataSubPages(userData);
            this.staticService.showPlace = userData;
            this.staticService.userInfo = userData;
            this.userProfile = userData;
            this.followers = cloneDeep(userData.followers);
            this.staticService.setCurrentPlace(userData);
            this.staticService.isMainInfoLoaded = true;
            this.store$.dispatch(new AjaxFinish({uid: null}));
            this.processProfileData(userData);

            if (this.isAllFiltersRequired() || this.isFilteredMode) {
              this.fetchAllPlaceFilters(userData.id);
            } else {
              this.handlePlaceFilters(userData);
            }
            if (+userData.placeCreatedCount === 0) {
              this.getSimilarPlaces();
            }
          },
          error: (errors) => {
            if (errors.status === 404) {
              this.ssrService.redirect(301, '/404');
            }
            if (errors.status === 410) {
              const errorData = {
                id: errors.headers.get('x-user-id') ? errors.headers.get('x-user-id') : id,
                displayName: errors.headers.get('x-user-name'),
                messageCode: errors.error?.code
              }
              this.ssrService.setState(stateKey, errorData);
              this.staticService.setDeactivatedPageMode(
                errorData.displayName,
                errorData.messageCode,
                errorData.id
              );
            }
          }
        })
    );
  }

  private isAllFiltersRequired(): boolean {
    return this.userProfile.placeCreatedCount > this.placesLimit;
  }

  private prepareFilterParams(): void {
    this.additionParams = {};
    delete this.mapService.filters['cityId'];
    delete this.mapService.filters['tagId'];
    delete this.mapService.filters['countryId'];

    const queriesUserPersonalPage = this.staticService.routeQueryParams;
    if (queriesUserPersonalPage.cityId && this.helperService.checkIsNumeric(queriesUserPersonalPage.cityId)) {
      this.additionParams['filter[cityId]'] = queriesUserPersonalPage.cityId.toString();
      this.mapService.filters['cityId'] = queriesUserPersonalPage.cityId.toString();
    }
    if (queriesUserPersonalPage.tagId && this.helperService.checkIsNumeric(queriesUserPersonalPage.tagId)) {
      this.additionParams['filter[tagId]'] = queriesUserPersonalPage.tagId.toString();
      this.mapService.filters['tagId'] = queriesUserPersonalPage.tagId.toString();
    }
    if (queriesUserPersonalPage.countryId && this.helperService.checkIsCountry(queriesUserPersonalPage.countryId)) {
      this.additionParams['filter[countryId]'] = queriesUserPersonalPage.countryId.toString();
      this.mapService.filters['countryId'] = queriesUserPersonalPage.countryId.toString();
    }
    this.additionParams['pageSize'] = this.placesLimit;
  }

  private setDataToFilters(data: any): void {
    this.placeFilterData.cities = data?.cities;
    this.placeFilterData.tags = data?.tags;
    this.placeFilterData.countries = data?.countries;
  }

  private handlePlaceFilters(data: any) {
    if (!data) {
      return;
    }
    this.setDataToFilters(data);
    this.preparePlaceFilters(data);
    if (this.hasQueryFilters()) {
      this.sortPlaceFilters();
    }
  }

  private preparePlaceFilters(data: any = null): void {
    const queriesUserPersonalPage = this.staticService.routeQueryParams;
    // we can enable only country of the city if it's selected
    let cityCountry: string;
    if (queriesUserPersonalPage.cityId && !queriesUserPersonalPage.countryId && this.placeFilterData.countries.length) {
      for (let i = 0; i < this.placeFilterData.cities.length; i++) {
        if (this.placeFilterData.cities[i].id === +queriesUserPersonalPage.cityId) {
          cityCountry = this.placeFilterData.cities[i].country.id?.toLowerCase();
          break;
        }
      }
    }
    let filteredCountries = null;
    if (data.filteredCountries !== undefined) {
      filteredCountries = new Map();
      data.filteredCountries.forEach(item => {
        filteredCountries.set(item.id, true);
      });
    }
    let filteredCities = null;
    if (data.filteredCities !== undefined) {
      filteredCities = new Map();
      data.filteredCities.forEach(item => {
        filteredCities.set(item.id, true);
      });
    }
    let filteredTags = null;
    if (data.filteredTags !== undefined) {
      filteredTags = new Map();
      data.filteredTags.forEach(item => {
        filteredTags.set(item.id, true);
      });
    }

    this.placeFilterData.countries.forEach(item => {
      item.isSelected = queriesUserPersonalPage.countryId?.toLowerCase() === item.id.toLowerCase();
      // we can enable city of the country if it's selected
      item.isDisabled = !item.isSelected && (
        queriesUserPersonalPage.countryId
        || cityCountry && item.id.toLowerCase() !== cityCountry
        || filteredCountries !== null && !filteredCountries.get(item.id)
      );
    });
    this.placeFilterData.cities.forEach(item => {
      item.isSelected = +queriesUserPersonalPage.cityId === item.id;
      item.isDisabled = !item.isSelected && (
        queriesUserPersonalPage.cityId
        || queriesUserPersonalPage.countryId && queriesUserPersonalPage.countryId.toLowerCase() !== item.country?.id.toLowerCase()
        || filteredCities !== null && !filteredCities.get(item.id)
      );
    });
    this.placeFilterData.tags.forEach(item => {
      item.isSelected = +queriesUserPersonalPage.tagId === item.id;
      item.isDisabled = queriesUserPersonalPage.tagId && !item.isSelected || filteredTags !== null && !filteredTags.get(item.id);
    });
  }

  private sortPlaceFilters(): void {
    if (!this.ssrService.isBrowser()) {
      return;
    }
    this.placeFilterData.countries = this.placeFilterData.countries.toSorted(this.sortCategories);
    this.placeFilterData.cities = this.placeFilterData.cities.toSorted(this.sortCategories);
    this.placeFilterData.tags = this.placeFilterData.tags.toSorted(this.sortCategories);
  }

  private sortCategories(a, b): 1 | 0 | -1 {
    if (a.isDisabled && b.isDisabled || !a.isDisabled && !b.isDisabled) {
      return 0;
    }
    return a.isDisabled ? 1 : -1;
  }

  private prepareParamsForPlaceFilters(): HttpParams {
    let httpParams = new HttpParams();
    let expand = 'cities.country';
    if (this.hasQueryFilters()) {
      const queriesUserPersonalPage = this.staticService.routeQueryParams;

      const hasCityFilter = queriesUserPersonalPage.cityId
        && this.helperService.checkIsNumeric(queriesUserPersonalPage.cityId);
      if (hasCityFilter) {
        httpParams = httpParams.set('filter[cityId]', queriesUserPersonalPage.cityId.toString());
      }

      const hasCountryFilter = queriesUserPersonalPage.countryId
        && this.helperService.checkIsCountry(queriesUserPersonalPage.countryId);
      if (hasCountryFilter) {
        httpParams = httpParams.set('filter[countryId]', queriesUserPersonalPage.countryId.toString());
      }

      const hasTagFilter = queriesUserPersonalPage.tagId
        && this.helperService.checkIsNumeric(queriesUserPersonalPage.tagId);
      if (hasTagFilter) {
        httpParams = httpParams.set('filter[tagId]', queriesUserPersonalPage.tagId.toString());
      }
      if (!hasCityFilter && hasTagFilter) {
        expand += ',filteredCities.country';
      }
      if (!hasCountryFilter && !hasCountryFilter && hasTagFilter) {
        // if city selected then it can be only one country = city.country
        expand += ',filteredCountries';
      }
      if (!hasTagFilter) {
        expand += ',filteredTags';
      }
    }
    httpParams = httpParams.set('expand', expand);

    return httpParams;
  }

  /**
   * We need to fetch all place filters for user in order to display all content not only the data of the first page.
   * We do it in the following cases:
   * - first load of the page
   * - changed query params (filters)
   * And:
   * - total count of the places > place limit.
   * - page loaded with filters
   */
  private fetchAllPlaceFilters(id: string): void {
    this.infoService.getUserPlaceFilters(id, this.prepareParamsForPlaceFilters())
      .subscribe({
        next: (data: any) => {
          this.handlePlaceFilters(data);
        },
        error: () => {
          //do nothing while
        }
      });
  }

  moveToPlaces(): void {
    if (!this.staticService.userPlacesLength) {
      return
    }
    this.setSelectedTab(this.tabsObj.listPlace.id);
    // this is done for cases when there is a small list of collection
    // to allow place block to be rendered and scroll on proper margin
    setTimeout(() => {
      this.scrollToTabs();
    }, 300);
  }

  moveToCollections(): void {
    if (this.isEmptyCollection && this.isCollectionLoad) {
      return;
    }
    this.setSelectedTab(this.tabsObj.journeys.id);
    if (!this.isEmptyCollection) {
      this.scrollToTabs();
    }
  }

  toggleFollowersDialog(): void {
    this.isFollowersVisible = !this.isFollowersVisible;
  }

  scrollToTabs(): void {
    this.contentTabs.nativeElement.scrollIntoView({block: 'start'});
  }

  toggleFollow(isFollowed: boolean): void {
    if (isFollowed) {
      if (this.followers.some(follower => +follower.id === +this.userHome.id, this)) {
        return;
      }
      this.followers.push(this.userHome);
    } else {
      this.followers.filter(this.removeLoggedFromFollowers, this);
    }
  }

  private removeLoggedFromFollowers(value: UserPage, index: number, arr: UserPage[]): boolean {
    if (+value.id === +this.userHome.id) {
      arr.splice(index, 1);
      return true;
    }
    return false;
  }
}
