import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { constructBaseUrl } from "../libraries";

@Directive({
  selector: '[appBasesrc]'
})
export class BasesrcDirective implements AfterViewInit, OnChanges {

  @Input() appBasesrc: string = '';

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appBasesrc'] && changes['appBasesrc'].currentValue) {
      this.setImageSrc(changes['appBasesrc'].currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.setImageSrc(this.appBasesrc);
  }

  private setImageSrc(baseUrl: string): void {
    this.el.nativeElement.src = constructBaseUrl(baseUrl);
  }
}
