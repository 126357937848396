import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ChangeJourneyInterface, Journey } from '../../../../interfaces/journey';
import { Store } from '@ngrx/store';
import { State } from '../../../../store/reducers';
import { AddCollections, UserCollectionsActions } from '../../../../store/user-collections';
import { ToastrService } from '../../../../services/toastr.service';
import { SsrService } from '../../../../services';
import { takeUntil } from "rxjs/operators";
import { DestroyComponent } from "../../../../heplers/destroy.component";
import { UserCollectionService } from "../../../../services/user-collection.service";
import { MovementPopupService } from "../../../../services/movement-popup.service";
import { PinnedModeIdx } from "../../../../enums/pinned-mode.enum";

@Component({
  selector: 'app-user-collections',
  templateUrl: './user-collections.component.html',
  styleUrls: ['../../../../shared-components/pinned-panel/pinned-panel.component.scss',
    './user-collections.component.scss']
})
export class UserCollectionsComponent extends DestroyComponent implements OnInit, AfterViewInit {

  @Input() userIsLogged: boolean;
  @Input() isProfile: boolean;

  @ViewChild('pinnedPanelContainer') pinnedPanelContainer: ElementRef;

  @HostListener('window:scroll')
  onScroll() {
    this.loadMovementPinPanelData();
  }

  public isSelectCollectionItem = true;
  public collections: Journey[] = [];
  public selectCollectionData: Journey = null;
  public defaultTab = PinnedModeIdx.Places;
  public isCreateCollection = false;
  public isOpenCollection = true;
  public addToNewCollection: ChangeJourneyInterface;
  public openAfterCreation = false;

  private isViewInit: boolean;

  constructor(
    protected store$: Store<State>,
    protected toastrService: ToastrService,
    protected userCollectionService: UserCollectionService,
    protected movementPopupService: MovementPopupService,
    protected readonly ssrService: SsrService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.ssrService.isBrowser()) {
      this.loadCollections();
      this.store$.dispatch(new UserCollectionsActions.LoadCollections);
    }
  }

  ngAfterViewInit(): void {
    this.isViewInit = true;
  }

  public loadCollections(): void {
    this.userCollectionService.getFilteredCollections()
      .pipe(takeUntil(this.destroy$))
      .subscribe((collections) => {
        if (collections.length) {
          if (this.isProfile) {
            collections = collections.map((collection) => {
              if (!collection?.isFavorite) {
                return {...collection}
              }
            }).filter((x) => x);
          }
          this.collections = collections;
          if (this.openAfterCreation) {
            this.openAfterCreation = false;
            const defaultCollection = this.collections.find(collection => collection.isDefault);
            this.selectCollectionItem(defaultCollection);
          }
        }
      });
  }

  public selectCollectionItem(item): void {
    this.isSelectCollectionItem = false;
    this.isOpenCollection = true;
    this.selectCollectionData = item;
    this.toastrService.closeTooltip$.next();

    setTimeout(() => {
      this.loadMovementPinPanelData();
    }, 100)

  }

  public toggleSelectCollection() {
    this.isSelectCollectionItem = !this.isSelectCollectionItem;
  }

  public deleteCollection(): void {
    this.toggleSelectCollection();
  }

  public openCreateCollectionField(event?: ChangeJourneyInterface): void {
    if (event) {
      this.isOpenCollection = true;
      if (this.userIsLogged || (!this.userIsLogged && this.collections?.length === 1)) {
        this.isCreateCollection = !this.isCreateCollection;
      }
      this.addToNewCollection = event;
    } else {
      this.isCreateCollection = !this.isCreateCollection;
      this.toggleSelectCollection();
    }
  }

  public cancelCreateCollection() {
    this.isCreateCollection = false;
    this.isOpenCollection = false;
    this.toggleSelectCollection();
  }

  public createCollection(event) {
    if (!this.userIsLogged) {
      event = UserCollectionService.getCollectionStructureByName(event);
    }
    this.store$.dispatch(new AddCollections(event, true, !this.userIsLogged, this.addToNewCollection, this.isProfile));
    this.isOpenCollection = true;
    this.isCreateCollection = false;
    this.openAfterCreation = true;
  }

  loadMovementPinPanelData() {
    if (this.isViewInit) {
      this.movementPopupService.loadMovementPinPanelData(this.pinnedPanelContainer);
    }
  }
}
