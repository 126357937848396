<div #pinnedPanelContainer class="pinned-panel-item-container">
  <div class='description' *ngIf="headPictureSrc" (click)="doSelectDate($event)">
    <label class="place"
           appTooltip
           [label]="tooltipFullName"
           [disabled]="staticService.isMobileOrTablet()"
           [startOver]="true"
           [blueStyle]="true">
      {{ placeName }}
    </label>
  </div>

  <section *ngIf="headPictureSrc">
    <div class="unpin-place pinui"
         [class.like]="isFavorites"
         (click)="toggleEditTitlePopup()">
      <img [appBasesrc]="isFavorites ? '/assets/cross_blue.svg' : '/assets/pin/active-pin-ab-d.svg'"
           alt="like">
      <img *ngIf="isAlreadyBeen" [appBasesrc]="'/assets/accept_btn_active.png'" class="pinui already-been" alt="unpin">
    </div>

    <div class="head-picture-link">
      <div class="head-picture-link" (click)="doSelectDate($event)">
        <figure class="headPicture crop-to-fit">
          <img [appBasesrc]="headPictureSrc" alt="explorow">
        </figure>

        <div class="description bg-gradient align-bottom" *ngIf="countryData" [@backgroundDark]>
          <label class="month-price fly-pointer">
            <ng-container *ngIf="currentPlace.place; else pinCity">
            <span class="calendar">
              <img class="icon" [appBasesrc]="getCountryFlag({id: countryData.flag})" alt="">
              {{ countryData.info }}
            </span>
            </ng-container>
            <ng-template #pinCity>
            <span class="calendar">
              <img class="icon" [appBasesrc]="getCountryFlag({id: countryData.flag})" alt="">
              {{ countryData.info}}
            </span>
            </ng-template>
          </label>
        </div>
      </div>
    </div>
  </section>

  <app-fly-popup-select *ngIf="isShowPopupSelect"
                        [isProfile]="isProfile"
                        [currentPlace]="currentPlace"
                        [journeys]="collections"
                        [isAddToJourney]="isAddToCollection"
                        [classNameBySide]="classNameBySide"
                        (emitCreateJourney)="createCollection($event)"
                        (toggleShowEmitter)="toggleChangeCollection($event)"
                        (emitChangeJourney)="changeCollection($event)"></app-fly-popup-select>

  <app-fly-popup-option
    (mousedown)="$event.stopPropagation()"
    (pointerdown)="$event.stopPropagation()"
    *ngIf="isOpenPopup"
    [isProfile]="isProfile"
    [data]="popupPinData"
    [isBottomSheet]="isBottomSheet"
    [isFavorites]="isFavorites"
    [isPin]="true"
    [classNameBySide]="classNameBySide"
    (emitAction)="emitAction($event)"></app-fly-popup-option>
</div>
