<div class="wrapper">
  <app-static-header></app-static-header>

  <!-- pinned -->
  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="body-container">
    <div class="greeting-block">
      <img class="greeting-image" src="/assets/images/landing-business/guided-tour.png">
      <div class="greeting-text"><p>Grow your <br> travel business <br> with <span class="special-text">Explorow</span></p></div>
      <div class="greeting-buttons">
        <div class="sign-up">
          <p>Free travel platform</p>
          <p>based on the map</p>
        </div>
        <app-sign-in-buttons [showProfilePopup]="false"
                             [isNotSignInButton]="joinNowTemplate"></app-sign-in-buttons>
      </div>
    </div>
    <div class="benefit-block">
      <div *ngFor="let benefit of benefits; let i = index"
           class="benefit-item"
           [class.reverse]="i % 2 === 1">
        <div class="benefit-item__text"
             [class.reverse]="i % 2 === 1">
          <p class="title">{{benefit.title}}</p>
          <div class="text" [innerHTML]="benefit.text"></div>

          <ng-container *ngIf="!staticService.isMobileDesign()">
            <app-sign-in-buttons [showProfilePopup]="false"
                                 [isNotSignInButton]="linkContainer"></app-sign-in-buttons>
          </ng-container>
        </div>
        <div class="benefit-item__image">
          <img [src]="benefit.image">
        </div>
        <ng-container *ngIf="staticService.isMobileDesign()">
          <app-sign-in-buttons [showProfilePopup]="false"
                               [isNotSignInButton]="linkContainer"></app-sign-in-buttons>
        </ng-container>

        <ng-template #linkContainer>
          <ng-container *ngTemplateOutlet="benefitLink; context: {text: benefit.linkText}"></ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="invitation-container">
    <p class="invitation-text">Free and open travel website that is based on the map and sourced by users</p>

    <app-sign-in-buttons [showProfilePopup]="false"
                         [isNotSignInButton]="signUpFooterTemplate"></app-sign-in-buttons>
    <img class="invitation-image" src="/assets/images/landing-business/footer-image.gif">
  </div>
  <app-footer [hasMotto]="false"></app-footer>
</div>

<ng-template #signUpFooterTemplate>
  <button class="btn" (click)="isLoggedUser && redirectToAccount()">Sign up for free</button>
</ng-template>
<ng-template #joinNowTemplate>
  <button class="btn" (click)="isLoggedUser && redirectToAccount()">Join now</button>
</ng-template>
<ng-template #benefitLink let-text="text">
  <a class="benefit-link" (click)="isLoggedUser && redirectToAccount()">
    {{text}}
    <img class="arrow" src="/assets/icons/arrow-right-blue.svg" alt="arrow">
  </a>
</ng-template>
