import { DestinationType } from '../enums/destination-type';
import { UserUrlSettings } from "../interfaces/user";
import { environment } from "../../environments/environment";

export function getLinkByObject(object: any) {
  if (!object) {
    return '';
  }
  let url = '/';
  const countryCode = object.country ? object.country.id.toLowerCase() : '';
  const cityId = object.city ? object.city.id : null;
  const cityUrlKeywords = object.city ? object.city.urlKeywords : '';
  switch (object.otype) {
    case DestinationType.City:
      url = `/city/${object.urlKeywords}/${object.id}`;
      break;
    case DestinationType.Country:
      url = `/country/${object.urlKeywords}/${object.id.toLowerCase()}`;
      break;
    case DestinationType.Place:
      url = `/places/${object.urlKeywords}/${object.id}`;
      break;
    case DestinationType.Tag:
    case DestinationType.PoI:
    case DestinationType.Destination:
      url = `/travel/${object.urlKeywords}/${object.id}/${object.otype}`;
      break;
    case DestinationType.AllPlaceCity:
      url = `/travel/${object.urlKeywords}/${object.id}/${object.otype}/${cityId}`;
      break;
    case DestinationType.AllPlaceCountry:
      url = `/travel/${object.urlKeywords}/${object.id}/${object.otype}/${countryCode}`;
      break;
    case DestinationType.TagCity:
      url = `/tag-city/${object.metaData.tagUrlKeywords}/${object.id}/${cityUrlKeywords}/${cityId}`;
      break;
    case DestinationType.TagCountry:
      url = `/experiences/${object.metaData.tagUrlKeywords}/${object.id}/${countryCode}`;
      break;
    case DestinationType.UserProfile:
      url = getUserLink(object);
      break;
    case DestinationType.Journey:
      url = `/journey/${object.id}`;
      break;
    default:
      break;
  }
  return url;
}

function getUserLink(object: any): string {
  let url: string;
  const urlSetting = typeof object.urlSetting === 'object' && object.urlSetting !== null
    ? +object.urlSetting.value
    : +object.urlSetting;
  switch (urlSetting) {
    case UserUrlSettings.Name:
      url = `/${object.urlKeywords}`;
      break;
    case UserUrlSettings.Subdomain:
      url = environment.baseUrl.replace(/(\/\/)([^.]*)/, `$1${object.urlKeywords}`);
      break;
    case UserUrlSettings.Id:
    default:
      url = `/ur/${object.id}`;
      break;
  }
  return url;
}
