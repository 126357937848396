import { Action } from '@ngrx/store';
import { PinInterface, PlacePinExt, PlacePinType } from "../../interfaces";
import { ChangeJourneyInterface, Journey } from "../../interfaces/journey";

export enum UserCollectionsActionsType {
  AddCollection = '[UserCollections] Add collection',
  RemoveCollection = '[UserCollections] Remove collection',
  CheckRemoveCollection = '[UserCollections] Check remove collection',
  UpdateCollection = '[UserCollections] Update collection',
  LoadCollections = '[UserCollections] Load collections',
  LoadedCollections = '[UserCollections] Loaded collections',
  LoadDefaultCollection = '[UserCollections] Load default collections',
  ResetCollections = '[UserCollections] Reset collections',
  RestoreCollection = '[UserCollections] Restore collections',
  SetCollectionsState = '[UserCollections] Set state',
  MarkSyncCollection = '[UserCollections] Mark sync collection',
  SynchronizeCollection = '[UserCollections] Synchronize collection',
  ChangePinCollection = '[UserCollections] Change pin collection',
  ChangedPinCollection = '[UserCollections] Changed pin collection',
  LoadCollectionPins = '[UserCollections] Load pins collection',
  RemovePin = '[UserCollections] Remove pin',
  AddPin = '[UserCollections] Add pin',
  CheckAddPin = '[UserCollections] Check add pin',
  UpdateCollectionPlaceOrder = '[UserCollections] Update collection place order',
  UpdatePinInfo = '[UserCollections] Update pin info',
}

export class LoadCollections implements Action {
  readonly type = UserCollectionsActionsType.LoadCollections;

  constructor(public payload?: PinInterface | PinInterface[] | any, public loadImportant?: boolean) {
  }
}

export class LoadedCollections implements Action {
  readonly type = UserCollectionsActionsType.LoadedCollections;
}

export class LoadCollectionPins implements Action {
  readonly type = UserCollectionsActionsType.LoadCollectionPins;

  constructor(public payload?: PinInterface | any) {
  }
}

export class LoadDefaultCollections implements Action {
  readonly type = UserCollectionsActionsType.LoadDefaultCollection;

  constructor(public payload?: Journey | any, public isUserLogged = false) {
  }
}

export class UpdatePinCollections implements Action {
  readonly type = UserCollectionsActionsType.UpdateCollection;

  constructor(public payload: Journey | any) {
  }
}

export class SetCollectionsState implements Action {
  readonly type = UserCollectionsActionsType.SetCollectionsState;

  constructor(public payload: { collections: Journey[] }, public mergeIt = false, public isUserLogged = false) {
  }
}

export class AddCollections implements Action {
  readonly type = UserCollectionsActionsType.AddCollection;

  constructor(
    public payload: Journey | any,
    public isSynchronizePins = false,
    public isGuest = false,
    public addToNewCollection ?: ChangeJourneyInterface,
    public isProfilePage?: boolean,
    public openPanel: boolean = true,
  ) {
  }
}

export class MarkSyncCollection implements Action {
  readonly type = UserCollectionsActionsType.MarkSyncCollection;

  constructor(public payload: Journey | any) {
  }
}

export class RemoveCollections implements Action {
  readonly type = UserCollectionsActionsType.RemoveCollection;

  constructor(public payload: Journey | any) {
  }
}

export class CheckRemoveCollection implements Action {
  readonly type = UserCollectionsActionsType.CheckRemoveCollection;

  constructor(public payload: Journey | any, public isProfile?: boolean) {
  }
}

export class ChangePinCollection implements Action {
  readonly type = UserCollectionsActionsType.ChangePinCollection;

  constructor(public payload: { oldCollectionId: number; pin: PlacePinExt | PinInterface; newCollectionId: any }) {
  }
}

export class ChangedPinCollection implements Action {
  readonly type = UserCollectionsActionsType.ChangedPinCollection;

  constructor(public payload: Journey | any) {
  }
}

export class SynchronizeCollection implements Action {
  readonly type = UserCollectionsActionsType.SynchronizeCollection;

  constructor(public payload?: number) {
  }
}

export class RemovePin implements Action {
  readonly type = UserCollectionsActionsType.RemovePin;

  constructor(public payload: { oid: number, otype: number, type: PlacePinType | number, collectionId?: number }) {
  }
}

export class AddPin implements Action {
  readonly type = UserCollectionsActionsType.AddPin;

  constructor(public payload: any, public collectionId?: number) {
  }
}

export class CheckAddPin implements Action {
  readonly type = UserCollectionsActionsType.CheckAddPin;

  constructor(public payload: Journey | any) {
  }
}

export class ResetCollections implements Action {
  readonly type = UserCollectionsActionsType.ResetCollections;
}

export class RestoreCollection implements Action {
  readonly type = UserCollectionsActionsType.RestoreCollection;

  constructor(public payload: any) {
  }
}

export class UpdateCollectionPlaceOrder implements Action {
  readonly type = UserCollectionsActionsType.UpdateCollectionPlaceOrder;

  constructor(public pinOrder: PinInterface[] | any, public collectionId: number) {
  }
}

export class UpdatePinInfo implements Action {
  readonly type = UserCollectionsActionsType.UpdatePinInfo;

  constructor(public payload: any, public collectionId: number) {
  }
}

export type UserCollectionsActionsUnion =
  AddCollections
  | RemoveCollections
  | CheckRemoveCollection
  | UpdatePinCollections
  | SetCollectionsState
  | MarkSyncCollection
  | LoadDefaultCollections
  | SynchronizeCollection
  | ChangePinCollection
  | LoadCollectionPins
  | LoadedCollections
  | RemovePin
  | AddPin
  | CheckAddPin
  | ChangedPinCollection
  | ResetCollections
  | RestoreCollection
  | LoadCollections
  | UpdateCollectionPlaceOrder
  | UpdatePinInfo;

export const UserCollectionsActions = {
  AddCollections,
  UpdateCity: UpdatePinCollections,
  LoadCollections,
  ChangePinCollection,
  LoadCollectionPins,
  LoadedCollections,
  RemoveCollections,
  RemovePin,
  AddPin,
  CheckAddPin,
  ChangedPinCollection,
  ResetCollections,
  SynchronizeCollection,
  RestoreCollection,
  LoadDefaultCollections,
  UpdateCollectionPlaceOrder,
  UpdatePinInfo
};
