<form class="form">
  <div class="form-group">
    <input #input class="form-control" type="text" id="value" name="value"
           autocomplete="off"
           [formControl]="query">
  </div>
</form>

<ul class="modal-items" *ngIf="items.length>0">
  <ng-scrollbar [orientation]="'vertical'">
    <li class="item" *ngFor="let item of items;" (click)="onSelectItem(item)">
      <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: item}">
        <ng-content select="['li-item']"></ng-content>
      </ng-template>
    </li>
  </ng-scrollbar>
</ul>
