import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadProgressComponent } from './upload-progress.component';
import { FileSizePipe } from "../../../../pipes/file-size.pipe";



@NgModule({
  declarations: [
    UploadProgressComponent,
    FileSizePipe
  ],
  exports: [
    UploadProgressComponent
  ],
  imports: [
    CommonModule
  ]
})
export class UploadProgressModule { }
