<app-date-view *ngIf="mode===DatePickerMode.Date"
               [minDateLimit]="minDateLimit"
               [maxDateLimit]="maxDateLimit"
               [large]="large"
               [date]="date"
               [interval]="interval"
               (selectEmitter)="selectEmitter.emit($event)">
</app-date-view>

<app-month-view *ngIf="mode===DatePickerMode.Month"
                [minDateLimit]="minDateLimit"
                [date]="date"
                (selectEmitter)="selectEmitter.emit($event)">
</app-month-view>
