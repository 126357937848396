<div class="user-sidebar__container">
  <div class="user-sidebar__content-item" *ngFor="let item of sidebarItems">
    <span (click)="openMenuItem(item)" [ngClass]="{
        'active': getMode(item.queries),
        'mobile-link': item.isMobileLink
      }">
      {{ item.title }}
    </span>

    <div class="user-sidebar__sub-items" *ngIf="item.children">
      <div class="user-sidebar__item" *ngFor="let link of item.children; let i=index; let last=last">
        <span (click)="openMenuItem(link)" [ngClass]="{'active': mode === link.query, 'border-top-radius': i===0,  'border-bottom-radius': last}">
          {{ link.title }}
          <span class="user-sidebar__arrow" *ngIf="link.query">
            <img  alt="" src="/assets/arrow-next.svg">
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
