import {
  TOOLTIP_BOTTOM_ARROW_HEIGHT,
  TOOLTIP_HEIGHT,
  TOOLTIP_HEIGHT_MARGIN_TOP,
  TOOLTIP_WIDTH,
  TOOLTIP_WIDTH_MARGIN_RIGHT
} from '../../../../constants';


export const checkTooltipIntersections = (point, checkedPoint) => {
  return point.x <= checkedPoint.x && checkedPoint.x <= point.x + TOOLTIP_WIDTH + TOOLTIP_WIDTH_MARGIN_RIGHT &&
    point.y <= checkedPoint.y && checkedPoint.y <= point.y + TOOLTIP_HEIGHT + TOOLTIP_HEIGHT_MARGIN_TOP;
};

export const checkTooltipsArrowIntersections = (baseTooltipPoint, checkedPoint, radius) => {

  let startArrowPoint = {
    x: baseTooltipPoint.x - (TOOLTIP_BOTTOM_ARROW_HEIGHT / 2),
    y: baseTooltipPoint.y - TOOLTIP_BOTTOM_ARROW_HEIGHT
  };
  let endArrowPoint = {x: baseTooltipPoint.x + (TOOLTIP_BOTTOM_ARROW_HEIGHT / 2), y: baseTooltipPoint.y};

  if (checkedPoint.y < startArrowPoint.y) {
    if (checkedPoint.x < startArrowPoint.x)
      return (Math.pow((checkedPoint.x - startArrowPoint.x), 2) + Math.pow((checkedPoint.y - startArrowPoint.y), 2)) <= Math.pow(radius, 2);
    else if (checkedPoint.x > endArrowPoint.x)
      return (Math.pow((checkedPoint.x - endArrowPoint.x), 2) + Math.pow((checkedPoint.y - startArrowPoint.y), 2)) <= Math.pow(radius, 2);
    return (startArrowPoint.y - checkedPoint.y) <= radius;
  } else if (checkedPoint.y > endArrowPoint.y) {
    if (checkedPoint.x < startArrowPoint.x)
      return (Math.pow((checkedPoint.x - startArrowPoint.x), 2) + Math.pow((checkedPoint.y - endArrowPoint.y), 2)) <= Math.pow(radius, 2);
    else if (checkedPoint.x > endArrowPoint.x)
      return (Math.pow((checkedPoint.x - endArrowPoint.x), 2) + Math.pow((checkedPoint.y - endArrowPoint.y), 2)) <= Math.pow(radius, 2);
    return (checkedPoint.y - endArrowPoint.y) <= radius;
  } else if (checkedPoint.x < startArrowPoint.x)
    return (startArrowPoint.x - checkedPoint.x) <= radius;
  else if (checkedPoint.x > endArrowPoint.x)
    return (checkedPoint.x - endArrowPoint.x) <= radius;

  return true;
};
