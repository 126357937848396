import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { doAsync } from '../../../libraries/do-async';
import { WindowRef } from '../../../services/window.service';
import dayjs from 'dayjs';
import { WhenDate } from "../../../interfaces";
import { DatePickerMode } from "../../../interfaces/datepicker-date";

@Component({
  selector: 'app-fly-date-picker',
  templateUrl: 'fly-date-picker.component.html',
  styleUrls: [
    '../../../styles/mat-styles.scss',
    '../../../styles/search-panel-shared.scss',
    '../../../styles/fly-date-picker.scss',
    'fly-date-picker.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FlyDatePickerComponent implements OnChanges, OnInit {

  @Input() availableMode: DatePickerMode[] = [
    DatePickerMode.Month,
    DatePickerMode.Date
  ];
  @Input() minDateLimit: WhenDate = null;
  @Input() maxDateLimit: WhenDate = null;
  @Input() title = 'Date';
  @Input() value: WhenDate = {
    type: DatePickerMode.Month,
    date: dayjs()
      .startOf('month')
      .add(1, 'month')
  };
  @Input() large = false;
  @Input() withDropDown = true;
  @Input() openedMonthSelection = false;

  @Output() requiredMonthChangeEmitter: EventEmitter<any> = new EventEmitter<any>();

  public window: any;
  public datePickerControl: UntypedFormControl;
  public curMonthLabel: string;
  public coordinates = {
    top: '0px',
    left: '0px'
  };

  constructor(
    private el: ElementRef,
    private windowRef: WindowRef,
  ) {
    this.datePickerControl = new UntypedFormControl();
    this.window = windowRef.getNativeWindow();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.runRequiredChange(changes);
    this.updateDateLabel(this.value);
  }

  /**
   * @see requiredMonthChange
   * @param changes
   */
  runRequiredChange(changes: SimpleChanges) {
    for (const propName in changes) {
      if (this[`${propName}Change`]) {
        this[`${propName}Change`](changes[propName]);
      }
    }
  }

  /* run in runRequiredChange(changes: SimpleChanges) */
  requiredMonthChange(requiredMonth) {
    if (!requiredMonth) {
      return;
    }
    this.setMonthName(requiredMonth.currentValue);
  }

  setMonthName(month) {
    this.curMonthLabel = month.name;
  }

  toggleMonthSelection(open?: boolean) {
    this.onRenderDestFeatures();
    if (open) {
      this.openedMonthSelection = open;
    } else {
      this.openedMonthSelection = !this.openedMonthSelection;
    }
  }

  @HostListener('window:resize')
  public onRenderDestFeatures() {
    if (this.windowRef.isBrowser) {
      doAsync(() => {
        const dTop = 44;
        const rect = this.el.nativeElement.getBoundingClientRect();
        const fixedBoxOffsetTop =
          rect.top +
          this.window.pageYOffset -
          this.window.document.documentElement.clientTop;
        this.coordinates = {
          top: `${fixedBoxOffsetTop + dTop}px`,
          left: `${this.el.nativeElement.offsetLeft}px`
        };
      });
    }
  }

  public onSelect(event) {
    this.requiredMonthChangeEmitter.next([
      {type: event.type, date: event.date}
    ]);
    this.openedMonthSelection = false;
    this.updateDateLabel(event);
  }

  updateDateLabel(event) {
    switch (event.type) {
      case DatePickerMode.Month: {
        this.curMonthLabel = event.date.format('MMMM');
        break;
      }
      case DatePickerMode.Date: {
        this.curMonthLabel = event.date.format('D MMM YYYY');
        break;
      }
    }
  }

  ngOnInit(): void {
    if (!this.openedMonthSelection) {
      this.onSelect(this.value);
    }
  }

}
