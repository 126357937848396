<ng-container *ngTemplateOutlet="isProfile ? profileContainer : panelContainer"></ng-container>

<ng-template #panelContainer>
  <div class="close" (click)="close()">
    <figure class="close-popup">
      <img [appBasesrc]="'/assets/cross.svg'" class='close-popup-img' alt="close" title="close">
    </figure>
  </div>
  <div class="pinned__container">
    <h1>Following</h1>
    <ng-container *ngTemplateOutlet="dataContainer"></ng-container>
  </div>
</ng-template>

<ng-template #profileContainer>
  <div class="user-places__title">
    <span class="user-places__headers">
    Following
    </span>
  </div>
  <ng-container *ngTemplateOutlet="dataContainer"></ng-container>
</ng-template>

<ng-template #dataContainer>
  <div class="followed-wrapper" *ngFor="let followedItem of followed?.items">
    <div class="user-info" *ngIf="followedItem.user">
      <img class="user-photo" alt="photo"
           (click)="clickOnUser(followedItem.user)"
           [src]="getUserAvatarByObject(followedItem.user)">
      <div class="user-data" (click)="clickOnUser(followedItem?.user)">
        <div class="user-name">{{followedItem.user.name}}</div>
        <div class="user-city" *ngIf="followedItem.user.homeCityObject">
          <img class="country-icon" [src]="getCountryFlag(followedItem.user.homeCityObject.country)"
               alt="country-icon"/>
          {{followedItem.user.homeCityObject.name}}, {{followedItem.user.homeCityObject.country?.name}}
        </div>
        <div class="user-counters">{{getCounters(followedItem.user)}}</div>
      </div>
      <div class="unfollow-button" (click)="toggleUnfollowPopup(followedItem.user)">Following</div>

      <app-following-unfollow-panel
        *ngIf="userToUnfollow === followedItem.user"
        [user]="followedItem.user"
        (closePopupEmit)="toggleUnfollowPopup(null)"
      ></app-following-unfollow-panel>
    </div>
    <ng-container *ngIf="!isProfile">
      <div class="user-event"
           *ngFor="let followedEvent of followedItem?.events"
           (click)="clickOnObject(followedEvent)">
        <div class="object-info">
          <b>{{getEventTypeLabel(followedEvent)}} {{getObjectTypeLabel(followedEvent)}}</b>: {{getObjectName(followedEvent)}}
        </div>
        <div class="object-date">{{getObjectDate(followedEvent)}}</div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!followed?.items?.length" class="empty-list">
    <span>You haven't started following anyone yet</span>
    <span class="sub-title-desc">
      Just click the <span>Follow</span> button to get started
    </span>
  </div>
</ng-template>
