import { UserCollectionsActionsType, UserCollectionsActionsUnion } from "./user-collections.actions";
import { Journey } from "../../interfaces/journey";
import { DestinationType } from "../../enums/destination-type";
import { Points } from "../../interfaces/points";

import { DEFAULT_JOURNEY_ID } from "../../../constants";

export interface State {
  pinned: Journey[];
  defaultPinned?: Journey;
  showPanel: boolean;
}

const initialState: State = {
  pinned: [],
  defaultPinned: null,
  showPanel: null,
};

export function checkEmptyItemsCollection(state, isState = true) {
  state = JSON.parse(JSON.stringify(state));
  if (state) {
    if (isState) {
      if (state.pinned) {
        state.pinned = state.pinned.map(pinned => {
          if (pinned.cities && pinned.cities.length) {
            pinned.cities = pinned.cities.filter(city => city.city != null);
          } else {
            pinned.cities = [];
          }
          if (pinned.places && pinned.places.length) {
            pinned.places = pinned.places.filter(place => place && place.place != null);
          }
          if (pinned.countries && pinned.countries.length) {
            pinned.countries = pinned.countries.filter(country => country.country != null);
          } else {
            pinned.countries = [];
          }
          return pinned;
        })
      }
    } else {
      let stateDate = [];
      state = state.map(pinned => {
        if (pinned.otype === DestinationType.City && pinned.city != null ||
          pinned.otype === DestinationType.Place && pinned.place != null ||
          pinned.otype === DestinationType.Country && pinned.country != null) {
          stateDate = pinned;
        }
        return stateDate;
      })
    }
  }
  return state;
}

export function reducer(state: State = initialState, action: UserCollectionsActionsUnion): State {
  switch (action.type) {
    case UserCollectionsActionsType.UpdateCollection:
      const newPins = state.pinned.reduce((result: Journey[], item) => {
        if (item.id !== action.payload.id) {
          result.push({
            ...item,
            ...(action.payload.isDefault !== undefined) && {isDefault: !action.payload.isDefault},
          });
        } else {
          result.push({
            ...item,
            ...(action.payload.name) && {name: action.payload.name},
            ...(action.payload.description !== undefined) && {description: action.payload.description},
            ...(action.payload.isPublic !== undefined) && {isPublic: !!action.payload.isPublic},
            ...(action.payload.isDefault !== undefined) && {isDefault: !!action.payload.isDefault},
            ...(action.payload.isLineVisible !== undefined) && {isLineVisible: !!action.payload.isLineVisible}
          });
        }
        return result;
      }, []);
      return {
        ...state,
        pinned: newPins,
        defaultPinned: newPins.find(collection => collection.isDefault)
      };
    case UserCollectionsActionsType.LoadCollections:
      state = checkEmptyItemsCollection(state);
      return {
        ...state,
        defaultPinned: state.pinned.find(collection => collection.isDefault)
      };
    case UserCollectionsActionsType.LoadedCollections:
      state = checkEmptyItemsCollection(state);

      const noAuthCollectionIndex = state.pinned.findIndex(pin => pin.id === DEFAULT_JOURNEY_ID);
      const defaultCollectionLoadedCollections = state.pinned.find(collection => collection.isDefault);

      if (noAuthCollectionIndex > -1) {
        defaultCollectionLoadedCollections.cities = state.pinned[noAuthCollectionIndex].cities;
        defaultCollectionLoadedCollections.places = state.pinned[noAuthCollectionIndex].places;
        defaultCollectionLoadedCollections.countries = state.pinned[noAuthCollectionIndex].countries;
        state.pinned.splice(noAuthCollectionIndex, 1);
      }

      return {
        ...state,
        defaultPinned: defaultCollectionLoadedCollections
      };
    case UserCollectionsActionsType.ResetCollections:
      return initialState;
    case UserCollectionsActionsType.LoadDefaultCollection:
      let newDefaultCollection = action.payload;
      newDefaultCollection = JSON.parse(JSON.stringify(newDefaultCollection));
      let pinned = JSON.parse(JSON.stringify(state.pinned));
      pinned = pinned.map((pinCollection) => {
        return {
          ...pinCollection,
          isDefault: false
        }
      });
      newDefaultCollection['places'] = [];
      newDefaultCollection['cities'] = [];
      newDefaultCollection['countries'] = [];
      newDefaultCollection['_sync'] = action.isUserLogged;

      pinned.unshift(newDefaultCollection);
      return {
        ...state,
        pinned: pinned,
        defaultPinned: newDefaultCollection
      };
    case UserCollectionsActionsType.RemoveCollection:
      let newState = state.pinned.filter(item => item.id !== action.payload.id);
      if (newState.length) {
        newState = newState.map((item, key) => {
          return {
            ...item,
            isDefault: (key === 0)
          }
        });
      }
      return {
        ...state,
        pinned: newState
      };
    case UserCollectionsActionsType.AddCollection:
      if (action.isGuest) {
        return {
          ...state,
          pinned: action.payload
        };
      }
      return {
        ...state
      };
    case UserCollectionsActionsType.LoadCollectionPins:
      let collectPayload = action.payload[0];
      state = JSON.parse(JSON.stringify(state));
      switch (collectPayload.otype) {
        case DestinationType.Place:
          state.defaultPinned.places.push(collectPayload);
          break;
        case DestinationType.City:
          state.defaultPinned.cities.push(collectPayload);
          break;
        case DestinationType.Country:
          state.defaultPinned.countries.push(collectPayload);
          break;
      }
      return {
        ...state
      };
    case UserCollectionsActionsType.MarkSyncCollection:
      const targetCollectionToMark = state.pinned.find((item) => item.id === action.payload.oid);
      if (!targetCollectionToMark) {
        return {...state};
      }

      const clonedObj = {
        ...targetCollectionToMark,
        _sync: true,
      };

      return {
        ...state,
        pinned: [
          ...state.pinned.filter((item) => item.id !== targetCollectionToMark.id),
          clonedObj
        ]
      };
    case UserCollectionsActionsType.SetCollectionsState:
      let mergeArrs: Journey[];
      if (action.mergeIt) {
        const collections = state.pinned.filter((collection, index) =>
          !action.payload.collections.find(pin => pin.id === collection.id));
        mergeArrs = [...action.payload.collections, ...collections];
      } else {
        mergeArrs = action.payload && action.payload.collections && action.payload.collections;
      }
      mergeArrs = mergeArrs.map(item => ({...item, _sync: action.isUserLogged}));

      return {
        ...state,
        pinned: mergeArrs,
        defaultPinned: mergeArrs.find(item => item.isDefault)
      };
    case UserCollectionsActionsType.ChangePinCollection:
      let currentPlace: Points;
      state = JSON.parse(JSON.stringify(state));
      const defaultCollection = state.pinned.find(collection => collection.id === action.payload.oldCollectionId);

      switch (action.payload.pin.otype) {
        case DestinationType.Place:
          currentPlace = defaultCollection.places.find(place => place.oid === action.payload.pin.oid);
          break;
        case  DestinationType.City:
          currentPlace = defaultCollection.cities.find(city => city.oid === action.payload.pin.oid);
          break;
        case  DestinationType.Country:
          currentPlace = defaultCollection.countries.find(country => country.oid === action.payload.pin.oid);
          break;
      }
      const newCollectionState = state.pinned.map(collection => {
        if (collection.id === action.payload.oldCollectionId) {
          let index = -1;
          /*remove pin from old collection*/
          switch (action.payload.pin.otype) {
            case DestinationType.Place:
              index = collection.places.findIndex(place => place.oid === action.payload.pin.oid);
              if (index > -1) {
                collection.places.splice(index, 1);
              }
              break;
            case  DestinationType.City:
              index = collection.cities.findIndex(city => city.oid === action.payload.pin.oid);
              if (index > -1) {
                collection.cities.splice(index, 1);
              }
              break;
            case  DestinationType.Country:
              index = collection.countries.findIndex(country => country.oid === action.payload.pin.oid);
              if (index > -1) {
                collection.countries.splice(index, 1);
              }
              break;
          }
        }
        if (collection.id === action.payload.newCollectionId) {
          /*add pin to a new collection*/
          switch (action.payload.pin.otype) {
            case DestinationType.Place:
              collection.places.push(currentPlace);
              break;
            case  DestinationType.City:
              collection.cities.push(currentPlace);
              break;
            case  DestinationType.Country:
              collection.countries.push(currentPlace);
              break;
          }
        }
        return collection;
      });
      const defaultNewState = newCollectionState.find(collect => collect.isDefault);
      return {
        ...state,
        pinned: newCollectionState,
        defaultPinned: defaultNewState
      };
    case UserCollectionsActionsType.RemovePin:
      state = JSON.parse(JSON.stringify(state));
      const newCollectState = state.pinned.map(collection => {
        if ((action.payload?.collectionId && action.payload?.collectionId === collection.id) || !action.payload?.collectionId) {
          let index = -1;
          /*remove pin from old collection*/
          switch (action.payload.otype) {
            case DestinationType.Place:
              index = collection.places.findIndex(place => place.oid === action.payload.oid);
              if (index > -1) {
                collection.places.splice(index, 1);
              }
              break;
            case  DestinationType.City:
              index = collection.cities.findIndex(city => city.oid === action.payload.oid);
              if (index > -1) {
                collection.cities.splice(index, 1);
              }
              break;
            case  DestinationType.Country:
              index = collection.countries.findIndex(country => country.oid === action.payload.oid);
              if (index > -1) {
                collection.countries.splice(index, 1);
              }
              break;
          }
        }
        return collection;
      });
      return {
        ...state,
        pinned: newCollectState
      };
    case UserCollectionsActionsType.AddPin:
      state = JSON.parse(JSON.stringify(state));
      let defaultCollectState;
      if (action.collectionId) {
        defaultCollectState = state.pinned.find(collection => collection.id === action.collectionId);
      } else {
        defaultCollectState = state.pinned.find(collection => collection.isDefault);
      }
      if (defaultCollectState) {
        let payload_ = action.payload;
        while (payload_.payload) {
          payload_ = payload_.payload;
        }

        payload_ = Array.isArray(payload_) ? payload_ : [payload_];
        payload_.forEach(pin_ => {
          /*add pin to a new collection*/
          switch (pin_.otype) {
            case DestinationType.Place:
              if (!defaultCollectState.places.some(x => x.oid === pin_.oid)) {
                defaultCollectState.places.push(pin_);
              }
              break;
            case  DestinationType.City:
              if (!defaultCollectState.cities.some(x => x.oid === pin_.oid)) {
                defaultCollectState.cities.push(pin_);
              }
              break;
            case  DestinationType.Country:
              if (!defaultCollectState.countries.some(x => x.oid === pin_.oid)) {
                defaultCollectState.countries.push(pin_);
              }
              break;
          }
        });

        const newPinned = state.pinned.map(collect => {
          if (collect.id === defaultCollectState.id) {
            collect = defaultCollectState;
          }
          return collect;
        });
        return {
          ...state,
          pinned: newPinned,
          ...!action.collectionId && {defaultPinned: defaultCollectState}
        };
      }
      return {
        ...state
      };
    case UserCollectionsActionsType.ChangedPinCollection:
      state = JSON.parse(JSON.stringify(state));

      const newPinnedChanged = state.pinned.map(collect => {
        if (collect.id === state.defaultPinned.id) {
          collect = state.defaultPinned;
        }
        return collect;
      });

      const defaultChangedCollectState = newPinnedChanged.find(x => x.isDefault);

      return {
        ...state,
        pinned: newPinnedChanged,
        defaultPinned: defaultChangedCollectState
      };
    case UserCollectionsActionsType.RestoreCollection:
      return {
        ...state,
        pinned: action.payload
      };
    case UserCollectionsActionsType.UpdateCollectionPlaceOrder:
      const updatedOrder = state.pinned.reduce((result: Journey[], item) => {
        if (item.id !== action.collectionId) {
          result.push(item);
        } else {
          let places = [];
          let countries = [];
          let cities = [];
          action.pinOrder.forEach((pin_) => {
            switch (pin_.otype) {
              case DestinationType.Place:
                places.push(pin_);
                break;
              case  DestinationType.City:
                cities.push(pin_);
                break;
              case  DestinationType.Country:
                countries.push(pin_);
                break;
            }
          });

          result.push({...item, places: places, cities: cities, countries: countries});
        }
        return result;
      }, []);
      return {
        ...state,
        pinned: updatedOrder,
        defaultPinned: updatedOrder.find(collection => collection.isDefault)
      };
    case UserCollectionsActionsType.UpdatePinInfo:
      state = JSON.parse(JSON.stringify(state));
      const updatedState = state.pinned.map(collection => {
        if (collection.id === action.payload.collectionId) {
          collection.places.map(place => {
            if (place.oid === action.payload.id && place.otype === action.payload.otype) {
              place.place = {
                ...place.place,
                ...action.payload
              };
            }
            return place;
          });
        }
        return collection;
      });
      return {
        ...state,
        pinned: updatedState,
        defaultPinned: updatedState.find(collection => collection.isDefault)
      };
  }
  return state;
}

export const _getPinned = (state: State) => state.pinned;
export const _getDefaultPinned = (state: State) => state.defaultPinned;
