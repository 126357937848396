<div class="social-link" *ngIf="socialGoogle && googleLabel">
  <h3>Google</h3>

  <button class="btn" (click)="signInWithGoogle()">
    connect
  </button>
</div>

<div class="social-link" *ngIf="socialFB && facebookLabel">
  <h3>Facebook</h3>

  <button class="btn" (click)="signInWithFB()">
    connect
  </button>
</div>
