<div class="reset-form-container" *ngIf="!showForgotPassword">
  <form name='reset-pass-form'
        autocomplete="off"
        class="auth-form"
        [formGroup]="passChangeForm"
        (ngSubmit)="passwordsMatch && resetPassword(passChangeForm)">

    <div class="errors" *ngIf="generalError">
      <div *ngFor="let message of generalError">
        {{message.value}}
      </div>
    </div>

    <div *ngIf="passwordChangedSuccessfully" class="email-sent-successfully">
      <span> {{passwordChangedSuccessfully}} </span>
    </div>
    <div class="form-group">
      <label class="pass-label"
             for="oldPassword"
             i18n="labels.old_password"
             innerText="'labels.old_password' | translate">
        old password
      </label>

      <span class="pass-v-icon"
            *ngIf="oldPassUpdated"
            [ngClass]="validationStyles('oldPass', resOfOldPasswordValidation)">
      </span>

      <input type="password"
             (blur)="valueUpdated('oldPass')"
             id="oldPassword"
             class="form-control"
             autocomplete="off"
             formControlName="oldPass">
      <div *ngIf='resOfOldPasswordValidation' class="validation-errors"> {{resOfOldPasswordValidation}} </div>
    </div>

    <div class="form-group">
      <label class="pass-label" for="newPassword" i18n="labels.new_password"
             innerText="'labels.new_password' | translate"> new password </label>
      <span class="pass-v-icon"
            *ngIf="newPassUpdated"
            [ngClass]="validationStyles('newPass')">
      </span>

      <input
        [attr.type]="passwordType"
        (blur)="valueUpdated('newPass')"
        id='newPassword'
        class="form-control"
        name="value"
        autocomplete="new-password"
        formControlName="newPass">
    </div>

    <div class="form-group">
      <label class="pass-label" for="confNewPassword" i18n="labels.new_password"
             innerText="'labels.new_password' | translate"> confirm password </label>
      <span class="pass-v-icon"
            *ngIf="confNewPassUpdated"
            [ngClass]="validationStyles('confNewPass')"></span>

      <input [attr.type]="passwordType"
             id='confNewPassword'
             (blur)="valueUpdated('confNewPass')"
             class="form-control"
             name="value"
             autocomplete="new-password"
             formControlName="confNewPass">
      <div *ngIf='resOfNewPasswordValidation' class="validation-errors"> {{resOfNewPasswordValidation}} </div>
      <div *ngIf="passDoNotMatch" class="validation-errors"> {{passDoNotMatch}} </div>
    </div>

    <div class="form-group remember-me">
      <div>
        <input type="checkbox"
               id="showPass"
               name="rememberMe"
               value="true"
               (change)="changePasswordView()">
        <label for="showPass"
               [ngClass]="{'label-active': (passwordType === 'text')}">
          {{ (passwordType === 'text') ? 'Hide' : 'Show' }} password
        </label>
      </div>
    </div>

    <div class="fly-text-r">
      <span class="cancel-in-button" (click)="cancel()">
        Cancel
      </span>

      <button type="submit"
              class="save-in-button"
              i18n="labels.change_password"
              innerText="'labels.change_password' | translate">
        change password
      </button>
    </div>
  </form>
</div>

<div *ngIf="showForgotPassword">
  <app-reset-password-form></app-reset-password-form>
</div>
