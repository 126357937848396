declare const Date;
import { consoleDebug } from '../../../libraries/console-debug';

export const _measureTime = (label, action) => {
  let start = new Date();
  let result = action();
  let end = new Date();
  consoleDebug(label, end.getTime() - start.getTime(), 'ms');

  return result;
};
