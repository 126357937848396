import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { FlySearchPanelComponent } from '../../../shared-components/fly-search-panel/fly-search-panel.component';
import { SsrService } from '../../../services';

@Directive({
  selector: '[appHiddenSettingsSearchPanelOnScroll]'
})
export class HiddenSettingsSearchPanelDirective implements AfterViewInit, OnDestroy {
  private readonly hiddenOn: Function;

  constructor(
    private el: ElementRef,
    private component: FlySearchPanelComponent,
    private readonly ssrService: SsrService
  ) {
    if (!component.hiddenSearchPanelContentOff || !component.hiddenSearchPanelContentOn) {
      throw new Error('Component does not implement the interface');
    } else {
      this.hiddenOn = this.component.hiddenSearchPanelContentOn.bind(this.component);
      this.component.hiddenSearchPanelContentOff.bind(this.component);
    }
  }

  ngAfterViewInit(): void {
    if (this.ssrService.isBrowser()) {
      const hostElem = this.el.nativeElement;
      const el = HiddenSettingsSearchPanelDirective.getFirstScrollableEl(hostElem.parentNode);

      if (el) {
        el.addEventListener('scroll', this.scroll.bind(this));
      }
    }
  }

  private scroll(): void {
    const value = +window.scrollY;
    if (value > 0) {
      this.hiddenOn();
    }
  }

  private static getFirstScrollableEl(el) {
    let parent = el?.parentNode;

    while (parent && !HiddenSettingsSearchPanelDirective.hasScrolling(parent)) {
      parent = parent.parentNode;
    }

    return parent || window;
  }

  private static hasScrolling(el): boolean {
    if (!el || (el && !el.style) || typeof getComputedStyle !== 'function') {
      return false;
    }

    const styles = getComputedStyle(el);
    const styleValue = styles.getPropertyValue('overflow-y');

    return styleValue === 'scroll' || styleValue === 'auto';
  }

  ngOnDestroy(): void {
    if (this.ssrService.isBrowser()) {
      const hostElem = this.el.nativeElement;
      const el = HiddenSettingsSearchPanelDirective.getFirstScrollableEl(hostElem.parentNode);
      if (el) {
        el.removeEventListener('scroll', this.scroll);
      }
    }
  }
}
