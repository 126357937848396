<ng-container *ngIf="video">
  <ng-container>
    <div class="video-content">
      <div class="video-container"
           (mousemove)="toggleVisibleControl(true)"
           (mouseleave)="toggleVisibleControl(false)"
           *ngIf="video?.isReady; else NoReadyContainer">
        <div *ngIf="isCanPlay"
             class="progress-bar"
             (click)="setTime($event)"
             (mousedown)="toggleCurrentPositionProgress(true)"
             (mouseup)="toggleCurrentPositionProgress(false)"
             (panstart)="onPanStart()"
             (panend)="onPanEnd()"
             (panmove)="onPan($event)">
          <div class="progress-container">
            <span class="all-time-progress"></span>
            <span [style.width.%]="progressVideo" class="current-time-progress"></span>
            <span [style.left.%]="progressVideo-0.5"
                  [class.large]="isLargeCurrentPositionProgress"
                  class="current-position-progress"></span>
          </div>
        </div>
        <video #videoPlayer
               (canplay)="onCanPlay()"
               (play)="onPlaying()"
               (pause)="onPause()"
               (timeupdate)="onTimeUpdate()"
               (panstart)="!isMobileDesign() && onPanStart()"
               (panend)="!isMobileDesign() && onPanEnd()"
               (panmove)="!isMobileDesign() && onPan($event)"
               (ended)="onTimeEnd()"
               class="video-player"
               src="{{constructMediaURL(video.path)}}"
               [muted]="isMuteVideo"
               [autoplay]="isAutoplay"></video>
        <img (click)="expand()"
             *ngIf="isExpandVisible"
             src="/assets/icons/expand.svg"
             class="expand"
             alt="expand">
        <img (click)="toggleControlVideo()"
             *ngIf="isShowControl"
             src="/assets/icons/{{videoPlayer.paused ? 'play-button.svg' : 'pause-button.svg'}}"
             class="video-control"
             alt="video-control">
        <img (click)="toggleControlVolume()"
             *ngIf="isShowControl"
             src="/assets/icons/{{isMuteVideo ? 'mute.svg' : 'volume.svg'}}"
             class="volume-control"
             alt="volume-control">
      </div>
    </div>
  </ng-container>

  <ng-template #NoReadyContainer>
    <div class="no-ready-container" (click)="showMessage(notReadyMessage)">
      <img #notReadyImg [src]="constructMediaURL(video?.poster?.large)" alt="video">
    </div>
  </ng-template>

</ng-container>
