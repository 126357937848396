<div class='fly-datepicker-dropdown' (click)="toggleMonthSelection()" *ngIf="withDropDown">
  <app-drop-down
    [title]="curMonthLabel">
  </app-drop-down>
</div>

<div class="fly-datepicker-container" (click)="toggleMonthSelection()" *ngIf="!withDropDown">
  <img class="fly-datepicker-img" src="/assets/icons/calend.png" alt="calendar icon">
  <div class="fly-datepicker-text">
    {{curMonthLabel}}
  </div>
</div>

<div class="picker"
     [class.large]="large"
     *ngIf="openedMonthSelection">
  <app-date-picker-mode
    *ngIf="availableMode.length === 2"
    [availableMode]="availableMode"
    [to]="picker"
    [state]="value.type">
  </app-date-picker-mode>

  <app-date-picker
    #picker
    [availableMode]="availableMode"
    [minDateLimit]="minDateLimit"
    [maxDateLimit]="maxDateLimit"
    [mode]="value.type"
    [date]="value.date"
    [large]="large"
    (selectEmitter)="onSelect($event)">
  </app-date-picker>
</div>
