<div class='wrapper'>

  <div class="reset-form-container">

    <h4 class="fly-text-c fly-margin-top0" i18n="labels.auth_reset.enter_new_pass"
        [innerText]="'labels.auth_reset.enter_new_pass' | translate">
      Please enter your new password
    </h4>
    <form name='reset-pass-form' class="auth-form" [formGroup]="passChangeForm"
          (ngSubmit)="passwordsMatch && resetPassword(passChangeForm)">
      <div class="validation-errors" *ngIf="generalError">
        <div *ngFor="let message of generalError">
          {{message.value}}
        </div>
      </div>

      <div *ngIf="passwordChangedSuccessfully" class="email-sent-successfully">
        <span> {{passwordChangedSuccessfully}} </span>
      </div>

      <div class="form-group">
        <label class="pass-label" for="newPassword" i18n="labels.new_password"
               innerText="'labels.new_password' | translate"> new password </label>
        <span class="pass-v-icon" *ngIf="newPassUpdated" [ngClass]="validationStyles('newPass')"></span>

        <input [attr.type]="passwordType" (blur)="valueUpdated('newPass')" id='newPassword' class="form-control"
               autocomplete="off"
               formControlName="newPass">
      </div>

      <div class="form-group fly-m-b-10px">
        <label class="pass-label" for="confNewPassword" i18n="labels.new_password"
               innerText="'labels.new_password' | translate">
          confirm password </label>
        <span class="pass-v-icon" *ngIf="confNewPassUpdated" [ngClass]="validationStyles('confNewPass')"></span>

        <input [attr.type]="passwordType" id='confNewPassword' (blur)="valueUpdated('confNewPass')" class="form-control"
               autocomplete="off"
               formControlName="confNewPass">
        <br>
        <div *ngIf='resOfNewPasswordValidation' class="validation-errors"> {{resOfNewPasswordValidation}} </div>
        <div *ngIf="passDoNotMatch" class="validation-errors"> {{passDoNotMatch}} </div>
      </div>

      <div class="form-group remember-me fly-m-b-10px">
        <div>
          <app-fly-checkbox (stateChange)="rememberUser()" [checked]="shouldRemember"
                            [label]="'Remember me'"></app-fly-checkbox>
        </div>
        <div>
          <input type="checkbox" id="showPass" name="rememberMe" value="true" (change)="changePasswordView()">
          <label for="showPass" [ngClass]="{'label-active': (passwordType === 'text')}">{{toggle ? 'Show' : 'Hide'}}
            password</label>
        </div>
      </div>


      <div class="fly-text-r">
        <button mat-raised-button type="submit" class="reset-btn" i18n="buttons.reset"
                [innerText]="'buttons.reset' | translate | capitalize">
          reset
        </button>
      </div>
    </form>
  </div>

</div>
