<app-static-content>
  <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
</app-static-content>


<ng-template #pageContainer>
  <div class="city-content">
    <section class="city__header">
      <h1 class="info-name">
        <img class="place__city-icon" *ngIf="!staticService.isMobileOrTablet()" [src]="'/assets/icons/city-light2.svg'" alt=""/>{{ place?.name }}</h1>
      <app-static-place-item-pin *ngIf="place"
                                 [place]="place"
                                 [isTitlePosition]="true"
                                 (pinPlace)="pinPlace($event)"
      ></app-static-place-item-pin>
      <app-share-buttons></app-share-buttons>
    </section>

    <section class="city__city-extra-info">
      <div class="city__city-country">
        <div>
          <div class="country-item">
            <img
              [src]="
            'assets/flag-24/' +
            (place?.countryId || place?.country?.id) +
            '.png'
          " alt=""
            />
            <span>Country:
            <a [routerLink]="'/country/' + place?.country?.urlKeywords + '/' + place?.country?.id.toLowerCase()">
            {{ place?.country?.name ? place?.country?.name : place?.country }}
          </a>
          </span>
          </div>
          <div class="country-population" *ngIf="place?.population > 0">
            Population:<span>{{ place?.population | formatNumbers: "," }}</span>
          </div>
          <div class="country-timezone" *ngIf="getCityUTC()">
            Time Zone:<span>UTC{{ getCityUTC() }}</span>
          </div>
        </div>
      </div>
    </section>

    <app-popup-city-flights
      *ngIf="place && (place.iataCodeFrom || place.iataCode)"
      class="city__action-item city-flights"
      [ngStyle]="{'padding-top': '0'}"
      [place]="place"
      [placeInfo]="place"
      [size]="'large'"
    ></app-popup-city-flights>

    <section *ngIf="place?.tags && place?.tags.length" class="city__categories">
      <app-static-categories
        [categories]="place.tags"
        [place]="place"
        [staticType]="staticType"
        [className]="'tags'"
        [pageType]="page"
      ></app-static-categories>
    </section>
  </div>
</ng-template>
