<div class="gallery__container">
  <div class="gallery__close" (click)="close()">
    <img [src]="'/assets/cancel.svg'" alt="cancel">
    <div *ngIf="isMobileDesign()"
         class="mobile-video-player__counter">{{activeIndex + 1}}/{{galleryData.length}}</div>
  </div>

  <div class="gallery__view-content">
    <div *ngIf="!isMobileDesign() && galleryData?.length > 1" class="gallery__prev" (click)="onChangeImage(false)">
      <img [src]="'/assets/arrow/arrow-prev.svg'" alt="prev">
    </div>

    <div class="gallery__image-item" *ngIf="galleryData?.length">
      <ng-container *ngIf="isMobileDesign(); then mobileVideoPlayer; else desktopVideoPlayer"></ng-container>

      <ng-template #mobileVideoPlayer>
        <div #mobileVideoPlayerContainer>
          <div class="mobile-video-player">

            <div class="mobile-video-player__container"
                 (panstart)="onPanStart($event)"
                 (panend)="onPanEnd($event)">
              <img class="image" *ngIf="!galleryData[activeIndex]?.isVideo"
                   [src]="constructMediaURL(galleryData[activeIndex]?.large || galleryData[activeIndex]?.url)"
                   alt="">

              <app-video-player *ngIf="galleryData[activeIndex]?.isVideo"
                                [video]="galleryData[activeIndex]"
                                [isAutoplay]="true"
                                [isShowGalleryControl]="isShowGalleryControl"
                                (endVideoEvent)="endVideoEvent()"
                                (showControlEvent)="showControlEvent($event)"></app-video-player>
            </div>
            <div class="mobile-video-player__slide-dots"
                 *ngIf="galleryData?.length > 1"
                 [class.dot-in-slider]="isFullImgHeight">
              <div *ngFor="let item of galleryData; let i = index"
                   class="slide-dot"
                   [class.active]="i === activeIndex"></div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #desktopVideoPlayer>
        <img class="image" *ngIf="!galleryData[activeIndex]?.isVideo"
             [src]="constructMediaURL(galleryData[activeIndex]?.isVideo
             ? galleryData[activeIndex].poster.large
             : galleryData[activeIndex]?.large || galleryData[activeIndex]?.url)"
             alt="">

        <app-video-player *ngIf="galleryData[activeIndex]?.isVideo"
                          [video]="galleryData[activeIndex]"
                          [isAutoplay]="true"
                          [isShowGalleryControl]="isShowGalleryControl"
                          (endVideoEvent)="endVideoEvent()"
                          (showControlEvent)="showControlEvent($event)"></app-video-player>
        <div *ngIf="isCopyrightVisible && !isMobileDesign() && galleryData[activeIndex]"
             class="gallery__link-item">
          <span>Source:
            <a *ngIf="!place?.user" [href]="(galleryData[activeIndex]?.sourceUrl || place?.url)" target="_blank">
              {{ galleryData[activeIndex]?.sourceUrl ? staticInitService.getUrlLinkName(galleryData[activeIndex]?.sourceUrl) : staticInitService.getUrlLinkName(place?.url) }}
            </a>

            <a class="link-user max-width-auto"
               *ngIf="place?.user && this.userLink.indexOf('http') === -1"
               [routerLink]="[this.userLink]">
              {{ place?.user?.name }}
            </a>

            <a class="link-user max-width-auto"
               *ngIf="place?.user && this.userLink.indexOf('http') !== -1"
               [href]="this.userLink">
              {{ place?.user?.name }}
            </a>
          </span>
          <span>Images may be subject to copyright.
            <a [routerLink]="'/copyright-infringement'"
               target="_blank"
               class="copyright-link">Learn More</a>
          </span>
        </div>
      </ng-template>
    </div>

    <div *ngIf="!isMobileDesign() && galleryData?.length > 1" class="gallery__next" (click)="onChangeImage(true)">
      <img [src]="'/assets/arrow/arrow-next.svg'" alt="next">
    </div>
  </div>

  <div class="gallery__preview" *ngIf="!isMobileDesign()">
    <ng-container *ngIf="galleryData?.length">
      <ng-scrollbar [orientation]="'horizontal'">
        <div class="d-flex">
          <div class="gallery__preview-image-content"
               *ngFor="let gallery of galleryData; let i = index"
               (click)="setImage(i)">
            <div class="gallery__preview-image" [class.active]="activeIndex===i">
              <img [src]="constructMediaURL(
                 gallery?.isVideo
                 ? (gallery?.poster?.thumb || gallery?.preview?.thumb)
                 : (gallery?.thumb || gallery?.url))"
                   alt="">
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </ng-container>
  </div>

  <div class="mobile-video-player__description"
       *ngIf="countShowDescription > 2"
       [style]="{'-webkit-line-clamp': 3}">
    {{place?.description}}
  </div>
</div>
