import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageContentComponent } from './image-content.component';
import { VideoPlayerModule } from "../video-player/video-player.module";



@NgModule({
    declarations: [
        ImageContentComponent
    ],
    exports: [
        ImageContentComponent
    ],
  imports: [
    CommonModule,
    VideoPlayerModule
  ]
})
export class ImageContentModule { }
