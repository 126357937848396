import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable()
export class SignUpService {

  constructor(private httpService: HttpService) {
  }

  public send(params?: any) {
    return this.httpService.post('signup', params);
  }

  public confirm(token: string, body?: any, options?: any) {
    return this.httpService.patch(`signup/${token}`, body, options);
  }

  public resend(params?: any) {
    return this.httpService.post('signup/resend', params);
  }

  public validateEmail(token: string) {
    return this.httpService.patch(`user/email/${token}`);
  }

}


