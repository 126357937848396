import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationItem, NotificationList } from "../../../interfaces/notifications";
import { getLinkByObject } from "../../../libraries/get-link-by-object";
import { environment } from "../../../../environments/environment";
import { getNotificationList, State } from "../../../store/reducers";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { LoadNotifications } from "../../../store/user-notifications/user-notifications.actions";
import { NotificationService } from "../../../services/notification.service";
import { DEFAULT_USER_IMAGE } from "../../../../constants";
import { SsrService } from "../../../services";
import { constructMediaURL } from "../../../libraries";
import { NgScrollbar } from "ngx-scrollbar";
import { fromEvent } from "rxjs/internal/observable/fromEvent";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['notifications.component.scss']
})
export class NotificationsComponent implements OnDestroy, OnInit, AfterViewInit {

  @Input() notifications: NotificationList;
  @Output() clickOnItem = new EventEmitter<void>();
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;

  protected getLinkByObject = getLinkByObject;
  protected mediaUrl = environment.mediaUrl;
  protected defaultUserImage: string = DEFAULT_USER_IMAGE;

  private currentPage = 0;
  private $destroyed = new Subject<void>();
  private pageCount = 0;

  constructor(
    private store$: Store<State>,
    protected userNotificationService: NotificationService,
    protected ssrService: SsrService,
  ) {
  }

  ngOnInit(): void {
    if (!this.userNotificationService.isInitialized) {
      this.store$.dispatch(new LoadNotifications());
    }
    this.store$.select(getNotificationList)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(notifications => {
        //TODO: bug in SPA if go to another page and then open again list it will show only last loaded 10
        if (this.pageCount === 0 || notifications._meta.currentPage === 1) {
          this.notifications = notifications;
          this.pageCount = this.notifications._meta.pageCount;
          this.currentPage = this.notifications._meta.currentPage;
        } else {
          const items = this.notifications.items.concat(notifications.items);
          this.notifications = {
            items: items,
            _meta: notifications._meta,
            _extra: notifications._extra,
            _sync: true
          };
        }
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngAfterViewInit() {
    const scrollEvent$ = fromEvent(this.scrollbarRef.viewport.nativeElement, 'scroll');
    scrollEvent$
      .pipe(takeUntil(this.$destroyed))
      .subscribe((e: any) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
          this.loadNotifications();
        }
      });
  }

  protected loadNotifications(): void {
    if (this.pageCount === this.currentPage) {
      return;
    }
    this.currentPage++;
    this.store$.dispatch(new LoadNotifications({page: this.currentPage}));
  }

  protected clickItem(notification: NotificationItem): void {
    this.clickOnItem.emit();
    this.ssrService.goToRoute(getLinkByObject(notification));
  }

  protected readonly constructMediaURL = constructMediaURL;
}
