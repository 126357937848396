import { doAsync } from './do-async';
import { getWindow } from './get-window';

const Timeout = setTimeout(function () {
}, 0).constructor;

let $clearInterval = clearTimeout;
let $setInterval = setInterval;

export const timerObservable = (action, delay: number, interval: number, duration) => {
  if (!getWindow()) return null;
  let intervalId = null;
  doAsync(() => {
    intervalId = $setInterval(() => {
      action();
    }, interval);
  }, delay);

  doAsync(() => {
    $clearInterval(intervalId);
  }, (delay + duration));

  function clearIntervalId() {
    $clearInterval(intervalId);
  }

  return clearIntervalId;
};

export const waitObservable = (actionObs, thenDo, interval) => {
  if (!getWindow()) return null;
  let intervalId = setInterval(() => {
    let expectedResult = actionObs();
    if (expectedResult) {
      clearInterval(intervalId);
      thenDo(expectedResult);
    }
  }, interval);
  return intervalId;
};

export const clearObservables = (obs: any) => {
  if (!obs) return;
  if (Array.isArray(obs)) {
    for (let i = 0; i < obs.length; i++) clearObservable(obs[i]);
  } else clearObservable(obs);
};

export const clearObservable = (o: any) => {
  if (!o) {
    return;
  }
  if (Number.isInteger(o) || o instanceof Timeout) {
    $clearInterval(o);
  } else {
    o();
  }
};
