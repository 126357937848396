import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

import { InnerDataStorageService } from '../services';
import { doAsync, getWindow } from '../libraries';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnChanges {

  @Input() title: string;
  @Input() position: string;
  @Input() disabled: boolean;
  @Input() blueStyle: boolean;
  @Input() fontSize: string;
  @Input() label: string;
  @Input() margin: string;
  @Input() onlyLongText: boolean;
  @Input() startOver: boolean;
  @Input() absolutePosition: boolean;

  constructor(
    private el: ElementRef,
    private innerStorage: InnerDataStorageService
  ) {
  }

  checkWidthText() {
    if (this.onlyLongText) {
      const text = document.createElement('p');
      this.el.nativeElement.offsetParent.appendChild(text);
      text.style.position = 'absolute';
      text.style.top = '-100%';
      text.style.fontSize = '14px';
      text.style.fontWeight = '500';
      text.style.fontFamily = 'Montserrat, Arial, sans-serif';
      text.style.width = 'auto';
      text.style.height = 'auto';
      text.textContent = this.el.nativeElement.innerText;

      const textLength = text.clientWidth;
      const containerLength = this.el.nativeElement.clientWidth;

      const isTooltip = textLength > containerLength;
      this.el.nativeElement.offsetParent.removeChild(text);
      return isTooltip;
    }
    return true;
  }

  readCoordinates() {
    return this.el.nativeElement.getBoundingClientRect();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.disabled && change.disabled.currentValue === true && this.innerStorage.tooltipData) {
      this.mouseLeave();
    }
  }

  @HostListener('click')
  onClick() {
    doAsync(() => {
      if (this.disabled) {
        this.mouseLeave();
      }
    });
  }

  @HostListener('mouseenter')
  mouseEnter() {
    if (this.checkWidthText()) {
      if (this.disabled || !getWindow()) {
        return;
      }
      const bcr = this.readCoordinates();
      this.innerStorage.setTooltipData(
        this.title,
        bcr,
        this.position,
        this.blueStyle,
        this.fontSize,
        this.label,
        this.margin,
        this.startOver,
        this.absolutePosition,
      );
    }
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.innerStorage.clearTooltipData();
  }

}
