import { Injectable } from '@angular/core';

@Injectable()
export class ReplaceLinkService {

  constructor() {
  }

  public getLink(data: any, defaultName?: string): string {
    let linkName = defaultName;
    if (data) {
      if (data.urlKeywords) {
        linkName = data.urlKeywords;
      } else if (data.cityUrlKeywords) {
        linkName = data.cityUrlKeywords;
      } else if (data.countryUrlKeywords) {
        linkName = data.countryUrlKeywords;
      } else if (data.tagUrlKeywords) {
        linkName = data.tagUrlKeywords;
      }
    }
    return linkName;
  }

  public setCityToLinkName(data): any {
    return {
      tagUrlKeywords: (data && data.tagUrlKeywords) ? data.tagUrlKeywords : null,
      countryUrlKeywords: (data && data.countryUrlKeywords) ? data.countryUrlKeywords : null,
      cityUrlKeywords: (data && data.cityUrlKeywords) ? data.cityUrlKeywords : null,
      urlKeywords: (data && data.urlKeywords) ? data.urlKeywords : null
    };
  }
}
