import { Injectable } from '@angular/core';
import { FlightsInterface } from '../interfaces/flights';

@Injectable()
export class BindToComponentService {
  public autocompleteCallback = (config) => config;

  constructor() {
  }

  autocompleteInit(callback) {
    this.autocompleteCallback = callback;
  }

  set autocompleteConfig(config) {
    this.autocompleteCallback(config);
  }
}

export interface FlightsBindDataInterface {
  flight?: FlightsInterface;
  place?: any;
  iataCodes?: IataCodesInterface;
}

interface IataCodesInterface {
  iataCode: string | boolean;
  iataCodeFrom: string | boolean;
}
