<div class="footer-action-panel"
     [class.down]="!isDownScroll">

  <a class="panel-action" [routerLink]="'/'" *ngIf="!isUserDomain">
    <img [appBasesrc]="'/assets/icons/home-mobile.svg'" alt="home">
  </a>
  <a class="panel-action" [href]="constructBaseUrl('')" *ngIf="isUserDomain">
    <img [appBasesrc]="'/assets/icons/home-mobile.svg'" alt="home">
  </a>

  <button class="panel-action favourite" (click)="toggleFavouriteMenu()">
    <img [appBasesrc]="'/assets/icons/favorites.svg'"
         [class.animated]="isLikeAnimation"
         class="pinned-img"
         alt="Favorites">
  </button>

  <div class="empty-places" (click)="toggleFavouriteMenu()" *ngIf="isFavouriteMenuVisible"></div>
  <div class="user-sidebar__container favourite-menu" *ngIf="isFavouriteMenuVisible">
    <div class="user-sidebar__content-item">
      <div class="user-sidebar__sub-items">
        <div class="user-sidebar__item">
          <span (click)="toggleFavouriteMenu(); dispatchAction(panelAction.ToggleFavoritePanel)"
                class="border-top-radius with-icon">
            <div class="popup-row popup-row__mobile">
                <div class="popup-row-icon popup-row-icon__mobile full-img">
                  <img [appBasesrc]="'/assets/icons/savedlist.svg'" alt="Liked List">
                </div>
                <div class="popup-row-text">Liked List</div>
            </div>
            <span class="user-sidebar__arrow">
              <img alt="next" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
        </div>
        <div class="user-sidebar__item">
          <span (click)="toggleFavouriteMenu(); dispatchAction(panelAction.ToggleFollowingPanel)"
                class="border-bottom-radius with-icon">

            <div class="popup-row popup-row__mobile">
                <div class="popup-row-icon popup-row-icon__mobile full-img">
                  <img [appBasesrc]="'/assets/icons/notifications.svg'" alt="Liked List">
                </div>
                <div class="popup-row-text">Following</div>
            </div>
            <span class="user-sidebar__arrow">
              <img alt="next" [appBasesrc]="'/assets/arrow-next.svg'">
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <app-sign-in-buttons #signInButtons [isNotSignInButton]="flyAddButton"
                       [isSignInClickDisabled]="true"
                       [showProfilePopup]="!staticService.isTabletDesign()"></app-sign-in-buttons>

  <ng-template #flyAddButton>
    <app-fly-add-button [userIsLogged]="!!userIsLogged"
                        (toggleRegisterFormEmit)="toggleRegisterForm()"></app-fly-add-button>
  </ng-template>

  <button class="panel-action collections tooltip-event-item position-relative">
    <img [appBasesrc]="'/assets/icons/collections.svg'"
         [class.animated]="isPinnedAnimation"
         class="pinned-img"
         alt="Pinned"
         (click)="dispatchAction(panelAction.TogglePinnedPanel)">
    <app-guide-tooltip [pageObject]="journeyType"></app-guide-tooltip>
  </button>

  <app-sign-in-buttons [isNotSignInButton]="staticBurgerMenu"></app-sign-in-buttons>
  <ng-template #staticBurgerMenu>
    <app-static-burger-menu
      *ngIf="staticService.resizeWindow < 1024"
      class="panel-action"
      [class.hidden]="!(isOpenMap.value || staticService.isTabletDesign())"
      [searchMode]="searchMode"
      [month]="month"
      [isUserLogged]="userIsLogged"
      [position]="'above'"
      (openPinned)="openPinned()"
      [selectedCity]="selectedCity"
      [title]="'Switch to Map Mode'"
      (clearFilters)="clearFilters()"
    ></app-static-burger-menu>
  </ng-template>
</div>

<!-- MAP -->
<div class="map-badge__content" *ngIf="isMapIconShow">
  <ng-container *ngIf="!this.isOpenMap.value; then mapButton; else listButton"></ng-container>
  <ng-template #mapButton>
    <div class="map-badge__container"
         (click)="dispatchAction(panelAction.ActivateMap)">
      <img class="map-badge__img" [appBasesrc]="'/assets/icons/map.svg'" alt="Map">
      <div class="map-badge__text">Map</div>
    </div>
  </ng-template>
  <ng-template #listButton>
    <div class="map-badge__container"
         (click)="dispatchAction(panelAction.CancelMap)">
      <img class="map-badge__img" [appBasesrc]="'/assets/icons/list.svg'" alt="List">
      <div class="map-badge__text">List</div>
    </div>
  </ng-template>
</div>
