import { LikesListInterface } from "../../interfaces/like";
import { UserLikesActionsType, UserLikesActionsUnion } from "./user-likes.actions";
import { DestinationType } from "../../enums/destination-type";

export interface State {
  liked: LikesListInterface;
}

const initialState: State = {
  liked: {
    places: [],
    cities: [],
    countries: [],
    collections: [],
    _sync: true
  }
};

export function reducer(state: State = initialState, action: UserLikesActionsUnion): State {
  switch (action.type) {
    case UserLikesActionsType.SuccessLoadLikes:
      const liked_ = JSON.parse(JSON.stringify(action.payload));
      liked_._sync = action.isUserLogged;

      return {
        ...state,
        liked: liked_
      };
    case UserLikesActionsType.SuccessAddLikes:
      const addLiked = JSON.parse(JSON.stringify(state.liked));
      const addPayload_ = action.payload;

      const data = {
        oid: addPayload_.id,
        otype: addPayload_.otype,
        type: 3,
        ...(addPayload_.otype === DestinationType.Place && {place: addPayload_}),
        ...(addPayload_.otype === DestinationType.City && {city: addPayload_}),
        ...(addPayload_.otype === DestinationType.Country && {country: addPayload_}),
        ...(addPayload_.otype === DestinationType.Journey && {collectionPinned: addPayload_}),
      };

      if (data.otype === DestinationType.City && !addLiked?.cities?.some(like => like.oid === data.oid)) {
        addLiked.cities.push(data);
      }
      if (data.otype === DestinationType.Place && !addLiked?.places?.some(like => like.oid === data.oid)) {
        addLiked.places.push(data);
      }
      if (data.otype === DestinationType.Country && !addLiked?.countries?.some(like => like.oid === data.oid)) {
        addLiked.countries.push(data);
      }
      if (data.otype === DestinationType.Journey && !addLiked?.collections?.some(like => like.oid === data.oid)) {
        addLiked.collections.push(data);
      }
      addLiked._sync = action.isUserLogged;
      return {
        ...state,
        liked: addLiked
      };
    case UserLikesActionsType.SuccessRemoveLike:
      const removeLiked = JSON.parse(JSON.stringify(state.liked));
      const removePayload_ = action.payload;
      if (removePayload_.otype === DestinationType.City) {
        removeLiked.cities = removeLiked.cities.filter(like => like.oid !== removePayload_.oid);
      }
      if (removePayload_.otype === DestinationType.Place) {
        removeLiked.places = removeLiked.places.filter(like => like.oid !== removePayload_.oid);
      }
      if (removePayload_.otype === DestinationType.Country) {
        removeLiked.countries = removeLiked.countries.filter(like => like.oid !== removePayload_.oid);
      }
      if (removePayload_.otype === DestinationType.Journey) {
        removeLiked.collections = removeLiked.collections.filter(like => like.oid !== removePayload_.oid);
      }
      return {
        ...state,
        liked: removeLiked
      };
    case UserLikesActionsType.RestoreLike:
      return {
        ...state,
        liked: action.payload
      };
    case UserLikesActionsType.ResetLike:
      return initialState;
  }
  return state;
}

export const _getFavoritesList = (state: State) => state.liked;
