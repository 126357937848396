<div #pinnedPanelContainer>
  <div *ngIf="isSelectCollectionItem">

    <div *ngIf="userIsLogged" class="create-collection">
      <div class="collection-action" (click)="openCreateCollectionField()">
        <div class="add-img"></div>
        <p>New Journey</p>
      </div>
    </div>

    <app-pinned-panel-collection
      *ngIf="collections.length; else notFoundItems" [isProfile]="isProfile"
      [collections]="collections"
      (emitSelectItem)="selectCollectionItem($event)"
      (initPinnedPanelEmit)="loadMovementPinPanelData()"></app-pinned-panel-collection>
  </div>
  <div *ngIf="!isSelectCollectionItem">
    <div class="returnCollections" (click)="cancelCreateCollection()">
      <img src="/assets/icons/back-icon-blue.png" alt="">
      <p>return to journeys</p>
    </div>

    <app-pinned-panel-collection-info [class.hidden]="!isOpenCollection && !isCreateCollection"
                                      [isCreate]="isCreateCollection"
                                      [mode]="defaultTab"
                                      [isProfile]="isProfile"
                                      [selectCollectionData]="selectCollectionData"
                                      [allJourneys]="collections"
                                      (emitDeleteCollection)="deleteCollection()"
                                      (emitOpenCreateCollectionField)="openCreateCollectionField($event)"
                                      (emitCancelCreateCollection)="cancelCreateCollection()"
                                      (emitCreateCollection)="createCollection($event)"></app-pinned-panel-collection-info>

  </div>

  <ng-template #notFoundItems>
    <div class="pinned__item-content">
      <div class="pinned-desc">
        <span>You don't have any Journeys yet</span>
        <span class="sub-title-desc">
        Just click on the icon plus
          <img src="/assets/icons/plus.svg" alt="create" class="collection-btn"
               (click)="openCreateCollectionField()">
          to create one
      </span>
      </div>
    </div>
  </ng-template>

  <app-fly-tooltip></app-fly-tooltip>

</div>
