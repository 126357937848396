import { Component, Input } from '@angular/core';
import { constructBaseUrl } from "../../../../libraries";

@Component({
  selector: 'app-close-btn',
  templateUrl: 'close-btn.component.html',
  styleUrls: ['close-btn.component.scss']
})
export class CloseBtnComponent {
  @Input() disabled: boolean;
  protected readonly constructBaseUrl = constructBaseUrl;
}
