import { environment } from '../../environments/environment';
import { getWindow } from './get-window';

export const consoleDebug = (...args) => {
  if (!environment.production && getWindow()) {
    console.log(args);
  }
};

export const consoleThrowError = (error) => {
  if (!environment.production && getWindow() && !!error) {
    throw new Error(error);
  }
};
