import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule } from './modules/date-picker/date-picker.module';
import { DropDownModule } from './modules/drop-down/drop-down.module';
import { BookingPopupComponent } from './components/booking-popup/booking-popup.component';
import { FooterModule } from './footer/footer.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ImagesContentModule } from "./images-content/images-content.module";
import { RouterLinkDirective } from "../../directives/appRouterLink.directive";
import { MatButtonModule } from "@angular/material/button";

const MODULES = [
  FooterModule,
  DatePickerModule,
  DropDownModule,
  MatButtonModule,
  ImagesContentModule,
  NgxPageScrollModule,
  NgxPageScrollCoreModule,
  ImagesContentModule
];
const COMPONENTS = [BookingPopupComponent];
const DIRECTIVES = [RouterLinkDirective];

@NgModule({
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES
  ]
})
export class SharedModule {
}
