<div class="w-100" [ngClass]="{'empty-container' : openedMode === 'all'}">
  <div class="w-100" [ngClass]="{'empty-area' : openedMode === 'all'}">
    <app-close-btn *ngIf="openedMode === 'all'" (click)="close()"></app-close-btn>
    <div class="categories__list"
         #categoriesElem
         [ngClass]="{
            'third-lines': this.openedMode === 'thirdLines',
            'third-lines-height': this.openedMode === 'thirdLines',
            'close-lines-height': openedMode === 'close',
            'part-lines-height': openedMode === 'part',
            'all-lines-height': openedMode === 'all',
            'wrap-list': openedMode === 'all',
            'empty-content': openedMode === 'all'
         }"
         (window:resize)="onResize($event)">
      <div *ngFor="let item of categories" class="mx-w-93">
        <a [class]="'categories__item ' + getCategoriesClassName(item)"
           *ngIf="mode === categoryModeType.All ? getLinkCategory(item, item.mode || item.otype) : getLinkCategory(item, mode)
              && (staticType !== staticRouteType.Search
              || categories.length > 1); else notLink"
           [ngClass]="{
                'not-opacity': !isCategoriesLoad,
                'opened-item': isAllOpened,
                'disabled': item.isDisabled,
                'tag-selected': item.isSelected
             }"
           (click)="clickCategory(item, categories)"
           [routerLink]="!isClickable() && !isUserDomain && !item.isDisabled && routeToItemPlace(item, mode) || isUserDomain && '/' || null"
           [queryParams]="!isClickable() && !item.isDisabled && getParams(item, mode) || null"
        >
          <ng-container *ngTemplateOutlet="categoryTextContainer; context: {item}"></ng-container>
        </a>

        <ng-template #notLink>
          <div
            [class]="'categories__item cursor-disabled ' + getCategoriesClassName(item)"
            [ngClass]="{
                'not-opacity': !isCategoriesLoad,
                'opened-item': isAllOpened
              }">
            <ng-container *ngTemplateOutlet="categoryTextContainer; context: {item}"></ng-container>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="isShowMore && !isAllOpened && isCategoriesLoad"
         [ngStyle]="{
    'left': leftShow + 'px',
    'top': topShow ? topShow : '-5px'
  }"
         (click)="showAllCategories()"
         class="categories__overflow">
      ...
    </div>
  </div>
</div>

<ng-template #categoryTextContainer let-item="item">
  <div class="category_text">
    <img
      *ngIf="((mode !== categoryModeType.All ? className : getExtraClassName(item)) === 'countries'
      || mode === categoryModeType.Countries) && item?.id"
      class="category__country-icon"
      alt="country-icon"
      [src]="getCountryFlag(item)"/>
    <img
      *ngIf="((mode !== categoryModeType.All ? className : getExtraClassName(item)) === 'cities'
      || mode === categoryModeType.City) && item?.id"
      class="category__city-icon"
      alt="city-icon"
      [src]="constructBaseUrl('/assets/icons/city-blue.svg')"/>
    <span>{{ (mode !== categoryModeType.All ? symbol : getSymbol(getExtraClassName(item))) + (item?.shortName || item?.name || item) }}</span>
  </div>
</ng-template>
