import { Injectable } from '@angular/core';

export class TooltipData {
  title: string;
  boundInClientRect: any;
  position: string;
  blueStyle: boolean;
  fontSize: string;
  label: string | null;
  margin: string | null;
  startOver: boolean;
  absolutePosition: boolean;
}

@Injectable()
export class InnerDataStorageService {

  public tooltipData: TooltipData | null = null;

  private clearTimeoutID: number;

  constructor() {
  }

  setTooltipData(
    title: string,
    bcr: any,
    position: string,
    blueStyle = false,
    fontSize = '11px',
    label = null,
    margin = null,
    startOver = false,
    absolutePosition = false,
    timeout: number = 3000
  ) {
    this.tooltipData = {
      title,
      boundInClientRect: bcr,
      position,
      blueStyle,
      fontSize,
      label,
      margin,
      startOver,
      absolutePosition,
    };
    this.clearTimeoutID = window.setTimeout(() => {
      this.clearTooltipData();
    }, timeout);
  }

  clearTooltipData() {
    this.tooltipData = null;
    if (!!this.clearTimeoutID) {
      window.clearTimeout(this.clearTimeoutID);
    }
  }

}
