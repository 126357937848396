<div class="empty-places" [class.visible]="isTooltipVisible" (click)="toggleShowTooltip(false)"></div>
<div class="edit-button-outer">
  <img class="edit-button" src="assets/icons/my-account.svg" alt="edit" (click)="toggleShowTooltip(true)"/>
  <div class="edit-tooltip" [class.visible]="isTooltipVisible">
    <app-close-btn (click)="toggleShowTooltip(false)" *ngIf="isTooltipVisible"></app-close-btn>
    <div>Would you like to open an editing page to modify "{{ place?.name }}"?</div>
    <button type="submit" class="btn-edit" [routerLink]="'/place/edit/'+ place?.id">
      Edit
    </button>
  </div>
</div>
