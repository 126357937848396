import { clip } from "../clip";
import { mapSize } from "./map-size";

// Converts latitude to pixel coordinate (doesn't require google map lib)
export let latToPixelY = function (latIn: number, zoom: number): number {
  const lat = clip(latIn, -90, 90);
  const sinLatitude = Math.sin(lat * Math.PI / 180);
  const y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);
  const mapSizeVal = mapSize(zoom);

  return clip(y * mapSizeVal + 0.5, 0, mapSizeVal - 1);
}
