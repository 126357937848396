import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SearchService } from '../../../../services/search.service';
import { HttpParams } from '@angular/common/http';
import { UserAccountService } from '../../../../services/user-account.service';
import { catchError, map, take } from 'rxjs/operators';
import trim from 'lodash/trim';
import get from 'lodash/get';
import * as fromLayer from '../../../../store/layer';
import * as fromAuth from '../../../../store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../store/reducers';
import { parseCoords } from '../../../../libraries';
import { of } from "rxjs/internal/observable/of";
import {
  FormInputAutoCompleteComponent
} from "../../../../shared-components/form/input/auto-complete/form-input-auto-complete.component";

@Component({
  selector: 'app-user-profile',
  templateUrl: 'user-profile.component.html',
  styleUrls: ['user-profile.component.scss']
})
export class UserProfileComponent implements OnChanges {
  @Input() profile: any;

  @Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  public email: string;
  public nextHomeCity: any;

  constructor(
    private searchService: SearchService,
    private userAccountService: UserAccountService,
    private store$: Store<State>,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profile) {
      this.email = get(changes.profile, 'currentValue.email');
    }
  }

  public findCityFn() {
    return async (cityName: string) => {
      const targetValue = trim(cityName);
      if (targetValue.length > 1) {
        return this.searchService
          .searchCity(new HttpParams()
            .append('filter[name]', cityName)
            .append('expand', 'country')
          ).toPromise()
          .then((result: any) => result)
          .catch(() => []);
      } else {
        return [];
      }
    };
  }

  public selectHomeCity($event: {item: any, component: FormInputAutoCompleteComponent}) {
    $event.component.setValue($event.item.name);
    $event.component.query.setValue($event.item.name, {emitEvent: false});
    this.nextHomeCity = $event.item;
  }

  public close(): void {
    this.closeEmitter.emit();
  }

  public submitHomeCity() {
    this.userAccountService.updateUserSettings({homeCity: this.nextHomeCity.id})
      .pipe(
        take(1),
        map(() => {
          this.profile['homeCity'] = {
            id: this.nextHomeCity.id,
            name: this.nextHomeCity.name,
            country: this.nextHomeCity.country
              ? {name: this.nextHomeCity.country.name, shortName: this.nextHomeCity.country.shortName}
              : null
          };

          this.store$.dispatch(new fromLayer.SetCityFrom({
            name: this.nextHomeCity.name,
            id: this.nextHomeCity.id,
            coordinates: this.nextHomeCity.lat && this.nextHomeCity.lng ? `${this.nextHomeCity.lat},${this.nextHomeCity.lng}` : null,
            iataCode: this.nextHomeCity.iataCode
          }));

          this.store$.dispatch(
            new fromAuth.AuthActions.RestoreCurrentHome({
              id: this.nextHomeCity.id,
              name: this.nextHomeCity.name,
              population: this.nextHomeCity.popularion,
              popular: this.nextHomeCity.popular,
              coordinates: this.nextHomeCity.lat && this.nextHomeCity.lng ? `${this.nextHomeCity.lat},${this.nextHomeCity.lng}` : null,
            })
          );

          let coordinates = parseCoords(this.nextHomeCity.lat && this.nextHomeCity.lng ? `${this.nextHomeCity.lat},${this.nextHomeCity.lng}` : null);
          this.store$.dispatch(new fromAuth.SetCurrentCoordinates(coordinates));

          this.close();
        }),
        catchError((err) => {
          return of(err);
        })
      )
      .subscribe();
  }

}
