<ng-container *ngTemplateOutlet="isProfile ? profileFavoritesContainer : favoritesContainer"></ng-container>

<ng-template #favoritesContainer>
  <div class="close" (click)="close()">
    <figure class="close-popup">
      <img [appBasesrc]="'/assets/cross.svg'" class='close-popup-img' alt="close" title="close">
    </figure>
  </div>
  <div class="pinned__container">
    <ng-container *ngTemplateOutlet="dataContainer"></ng-container>
  </div>
</ng-template>

<ng-template #profileFavoritesContainer>
  <div class="user-places__title">
    <span class="user-places__headers">
    LIKED LIST
    </span>
  </div>
  <ng-container *ngTemplateOutlet="dataContainer"></ng-container>
</ng-template>

<ng-template #dataContainer>
  <app-pinned-panel-collection-info *ngIf="favorites"
                                    [mode]="defaultTab"
                                    [isFavorites]="true"
                                    [isProfile]="isProfile"
                                    [isTitleEnabled]="!isProfile"
                                    [allJourneys]="[favorites]"
                                    [selectCollectionData]="favorites"></app-pinned-panel-collection-info>
</ng-template>
