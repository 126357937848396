import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { WindowRef } from '../services/window.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { doAsync } from '../libraries/do-async';
import { FlyCookieService } from './fly-cookie/fly-cookie.service';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import { AuthActions } from '../store/auth/auth.actions';
import { NGX_LOGGED_USER, NGX_USER_CITIES, NGX_USER_COLLECTIONS, NGX_USER_LIKES } from "../../constants";
import { SsrService } from "./ssr.service";

export const usertoken = 'usertoken';
export const searchData = 'searchData';
const userid = 'userid';

@Injectable()
export class UserService {
  public environment: any;
  public isUserAuth = new BehaviorSubject<boolean>(this.loggedIn());
  public window: any;

  private rememberMe: boolean;

  constructor(
    private cookieService: FlyCookieService,
    private router: Router,
    windowRef: WindowRef,
    private translateService: TranslateService,
    private store$: Store<State>,
    private ssrService: SsrService,
  ) {
    this.environment = environment;
    this.window = windowRef.getNativeWindow();
  }

  private static setExpiresTime(headers) {
    const timeFromHeaders = headers.get('x-expires-after');

    return UserService.expiresTimeFromHeaders(timeFromHeaders);
  }

  private static expiresTimeFromHeaders(expire: any) {
    return new Date(expire);
  }

  public shouldRemember(localRm) {
    this.rememberMe = !!localRm;
  }

  public setToken(data) {
    const token = data?.token || data?.body?.token;
    if (token == null || token === '') {
      return;
    }
    const jwtBody = this.parseJwt(token);

    const userId = jwtBody.uid || null;
    const expiresTime: any = data?.headers && UserService.setExpiresTime(data.headers);
    const expires = this.rememberMe ? expiresTime : null;
    this.cookieService.set(usertoken, token, expires, '/');
    this.cookieService.set(userid, userId, expires, '/');
    this.isUserAuth.next(true);
    this.store$.dispatch(new AuthActions.SetAuthData({token, loggedIn: true}));
  }

  public navigate(url: any[], params?: any) {
    this.router.navigate(url, params);
  }

  public signOut(isNotReload?: boolean) {
    this.isUserAuth.next(false);
    this.deleteCookiesOfToken();
    if (!isNotReload) {
      this.reloadThePage();
    }
    if (this.ssrService.isBrowser()) {
      localStorage.removeItem(NGX_USER_CITIES);
      localStorage.removeItem(NGX_USER_COLLECTIONS);
      localStorage.removeItem(NGX_USER_LIKES);
      localStorage.removeItem(NGX_LOGGED_USER);
    }
    this.rememberMe = false;
  }

  public deleteCookiesOfToken() {
    this.cookieService.delete(usertoken);
    this.cookieService.delete(userid);
  }

  public loggedIn(data?: any): boolean {
    if (data && data.isGuest) {
      this.deleteCookiesOfToken();
      return false;
    }

    return !!this.cookieService.get(usertoken);
  }

  public reloadThePage() {
    if (this.window) {
      this.window.location.reload();
    }
  }

  disableLoadingBar($this) {
    doAsync(() => {
      $this.isLoading = false;
    }, 1000);
  }

  disableSubmitForWhile($this, t: number = 1000) {
    $this.allowSubmit = false;
    doAsync(() => {
      $this.allowSubmit = true;
    }, t);
  }

  getToken() {
    return this.cookieService.get(usertoken);
  }

  deleteCookie(cookie: string) {
    this.cookieService.delete(`${cookie}`);
  }

  getTranslation(id: string, params?: any) {
    return this.translateService.get(id, params);
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

}
