<div class="about__container">
  <div class="about__content">
    <div class="about__label">Password</div>

    <div class="about__item">
      <div class="about__text" *ngIf="!fields.password">
                <span class="edit-item-label" (click)="toggleEditor('password', true)">
                    Update Password
                </span>
      </div>

      <app-profile-pass-change
        *ngIf="fields.password"
        (cancelEmitter)="toggleEditor('password', false)"
        (changed)="changedPassword()">
      </app-profile-pass-change>
    </div>
  </div>

  <div class="about__content">
    <div class="about__label">Social Networks</div>

    <div class="about__item column-item">
      <app-social-accounts
        *ngIf="auth"
        [auth]="auth"
        (userUnlink)="doOnUnlink()">
      </app-social-accounts>

      <span class="description">
                Connect your social network accounts to your Explorow account simplicity and ease.
            </span>
    </div>
  </div>

  <!--div class="about__content">
    <div class="about__label">Temperature Unit</div>

    <div class="about__item column-item flex-row">
      <div class="temperature__container">

        <div class="about__text w-auto">
          <app-drop-down class="white"
                         [title]="title + ' &#176;'"
                         [options]="options" (selectEmitter)="selectTemperature($event)">
          </app-drop-down>
        </div>

        <span class="description">
                Change the temperature units, Celsius or Fahrenheit.
            </span>
      </div>
    </div>
  </div-->

  <div class="about__content">
    <div class="about__label">Account</div>

    <div class="about__item">
      <div class="about__text">
                <span class="edit-item-label" (click)="openDialog()">
                    Close Account
                </span>
      </div>
    </div>
  </div>

</div>

<app-confirm-dialog
  *ngIf="isConfirm"
  [message]="'Are you sure that you want to permanently delete your account?'"
  (action)="closeAccount($event)">
</app-confirm-dialog>
