import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngrx/store';
import { getHomeData, State } from '../../../store/reducers';
import { MonthService, SsrService, UserAccountLoadStatus, UserAccountService, UserService } from '../../../services';
import { selectNextMonth } from '../../../libraries/select-next-month';
import { take, takeUntil } from 'rxjs/operators';
import { PinInterface, PlacePinExt } from '../../../interfaces';
import { DestinationType } from '../../../enums/destination-type';
import {
  CheckRemoveCollection,
  NGX_USER_COLLECTIONS,
  UpdateCollectionPlaceOrder,
  UpdatePinCollections,
  UserCollectionsActions
} from '../../../store/user-collections';
import { ChangeJourneyInterface, Journey } from '../../../interfaces/journey';
import { BottomSheetActionsInterface, UserCollectionService } from '../../../services/user-collection.service';
import { DestroyComponent } from "../../../heplers/destroy.component";
import { FAVORITE_ID } from "../../../interfaces/like";
import { UserLikesActions } from "../../../store/user-likes/user-likes.actions";
import { StaticService } from "../../../services/static.service";
import isEqual from 'lodash/isEqual';
import { Router } from "@angular/router";
import { TogglePinnedPanel } from "../../../store/panels/panels.actions";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { constructBaseUrl } from "../../../libraries";
import { BUTTON_NAMES_JOURNEYS, DEFAULT_JOURNEY_ID, DEFAULT_TITLE_JOURNEY } from "../../../../constants";
import { PinnedModeIdx } from "../../../enums/pinned-mode.enum";
import { AfterAuthAction } from "../../../enums/after-auth.action";
import { AuthModalService } from "../../../services/auth-modal.service";

@Component({
  selector: 'app-pinned-panel-collection-info',
  templateUrl: './pinned-panel-collection-info.component.html',
  styleUrls: ['./pinned-panel-collection-info.component.scss']
})
export class PinnedPanelCollectionInfoComponent extends DestroyComponent implements OnInit, OnChanges, OnDestroy {

  @Input() mode: PinnedModeIdx;
  @Input() isCreate: boolean;
  @Input() isProfile: boolean;
  @Input() isBottomSheet: boolean;
  @Input() journey: Journey;
  @Input() selectCollectionData: Journey;
  @Input() allJourneys: Journey[];
  @Input() isFavorites: boolean;
  @Input() isTitleEnabled: boolean = true;

  @Output() deletePin = new EventEmitter<void>();
  @Output() emitDeleteCollection = new EventEmitter<any>();
  @Output() emitCreateCollection = new EventEmitter<any>();
  @Output() emitOpenCreateCollectionField = new EventEmitter<ChangeJourneyInterface>();
  @Output() emitCancelCreateCollection = new EventEmitter<any>();
  @Output() emitOpenBottomSheet = new EventEmitter<any>();
  @Output() openPlaceEmitter = new EventEmitter<any>();

  public journeyTitle: string;
  public journeyDescription: string;
  public oldJourneyTitle: string;
  public oldJourneyDescription: string;
  public defaultJourneyTitle = DEFAULT_TITLE_JOURNEY;
  public isEdit = true;
  public isOpenDescriptionField = false;
  public isOpenPopup = false;
  public readonly popupData = {
    journeyActions: [
      {
        icon: 'rename-icon.svg',
        iconTitle: 'Rename',
        action: 'toggleEditTitleCollection',
        actionTitle: 'Name',
      },
      {
        icon: 'lock_icon_closed_red.svg',
        iconTitle: 'Make public',
        isVisible: function (data): boolean {
          return data && data.isPublic === false;
        },
        action: 'publicCollection',
        actionTitle: 'Status',
      },
      {
        icon: 'lock_icon_open_green.svg',
        iconTitle: 'Make private',
        isVisible: function (data): boolean {
          return data && data.isPublic === true;
        },
        action: 'privateCollection',
        actionTitle: 'Status',
      },
      {
        icon: 'lines-off.svg',
        iconTitle: 'Show connection lines',
        isVisible: function (data) {
          return data && data.isLineVisible === false && data.places?.length > 1;
        },
        action: 'showLines',
        actionTitle: 'Lines',
      },
      {
        icon: 'lines-on.svg',
        iconTitle: 'Hide connection lines',
        isVisible: function (data) {
          return data && data.isLineVisible === true && data.places?.length > 1;
        },
        action: 'hideLines',
        actionTitle: 'Lines',
      },
      {
        icon: 'delete-icon.svg',
        iconTitle: 'Delete it',
        action: 'confirmCollectionRemoval',
        actionTitle: 'Delete',
      }
    ],
    pin: [
      {
        icon: 'delete-icon.svg',
        title: 'Remove it',
        action: 'unpinPlace'
      },
      {
        icon: 'back-icon-blue.png',
        title: 'Move to another journey',
        action: 'toggleChangeCollection'
      },
      {
        icon: 'plus_blue.png',
        title: 'Add to another journey',
        action: 'toggleAddToCollection'
      }
    ],
    like: [
      {
        icon: 'liked-icon-on.svg',
        title: 'Unlike it',
        action: 'unLikePlace',
        upperLine: false,
        color: '#da3f6f'
      }
    ],
  };
  public btnName = BUTTON_NAMES_JOURNEYS;

  public changedPinnedMode: PinnedModeIdx;
  public isPinnedLoading: boolean;
  public items: PinInterface[] = [];
  public prevItems: PinInterface[] = [];
  public month: any;
  public repeatFunc = 0;
  public subscriptions: Subscription = new Subscription();
  public userData: any;
  public collectionRoutePath: string;
  public parentLength: number;
  public sideAlign: 'left' | 'center' | 'right' | 'pin' = 'center';

  protected readonly PinnedModeIdx = PinnedModeIdx;
  protected descriptionError: string;
  protected isDragAndDropEnabled = true;

  protected isDeleteConfirmationVisible: boolean = false;

  private descEditor: any;
  private readonly maxDescriptionLength = 4000;

  private scrollableContainer: any;

  constructor(
    private store$: Store<State>,
    private monthService: MonthService,
    private userCollectionService: UserCollectionService,
    protected userAccountService: UserAccountService,
    private el: ElementRef,
    private userService: UserService,
    private ssrService: SsrService,
    protected staticService: StaticService,
    private router: Router,
    private authModalService: AuthModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.toggleEditTitleCollection();
    this.month = this.monthService.getMonthByNumber(
      selectNextMonth()
    );
    this.subscriptions.add(
      this.userAccountService.loadingStatusChange
        .subscribe(value => {
          this.isPinnedLoading = value[UserAccountLoadStatus.GetPinned];
        })
    );

    if (this.isFavorites) {
      this.store$.dispatch(new UserLikesActions.LoadLikes);
    } else {
      this.store$.dispatch(new UserCollectionsActions.LoadCollections);
    }
    this.getDefaultCollection();
    this.checkTitleCollectionEmit();
    this.isDragAndDropEnabled = !this.staticService.isMobileDesign();
    this.initScrollableContainer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode'] && changes['mode'].currentValue) {
      this.changeMode(changes['mode'].currentValue);
    }
    let currentValue = changes.allJourneys?.currentValue;
    let previousValue = changes.allJourneys?.previousValue;
    if (this.userService.loggedIn() || !isEqual(currentValue, previousValue)) {
      this.getDefaultCollection();
      this.loadPins();
    }

    if (changes['selectCollectionData'] && changes['selectCollectionData'].currentValue) {
      if (!this.isProfile) {
        this.updateDefaultCollection();
      }
    }
  }

  public getDefaultCollection() {
    if (this.userService.loggedIn()) {
      this.subscriptions.add(
        this.userCollectionService.getFilteredCollections()
          .subscribe((collections) => {
            if (collections.length) {
              this.journey = this.findDefaultCollection(collections);
              if (!this.isProfile && this.journey || this.isProfile && this.selectCollectionData) {
                this.journeyTitle = !this.isProfile ? this.journey.name : this.selectCollectionData.name;
                this.journeyDescription = !this.isProfile ? this.journey.description : this.selectCollectionData.description;
              } else {
                this.journeyTitle = this.defaultJourneyTitle;
              }

              this.oldJourneyTitle = this.journeyTitle;
              this.oldJourneyDescription = this.journeyDescription;
            }
          })
      );
    } else {
      this.journey = this.findDefaultCollection(this.allJourneys);
      this.journeyTitle = this.journey?.name;
      this.journeyDescription = this.journey?.description;
      this.oldJourneyTitle = this.journeyTitle;
      this.oldJourneyDescription = this.journeyDescription;
    }
  }

  public findDefaultCollection(collections) {
    if (collections) {
      let defaultCollection: any;
      if (this.staticService.isFavoritesPlaceDisplay || this.isFavorites) {
        if (Array.isArray(collections)) {
          defaultCollection = collections?.find(collection => collection?.isFavorite);
        }
        if (
          defaultCollection?.places?.length
          || defaultCollection?.cities?.length
          || defaultCollection?.collections?.length
        ) {
          return defaultCollection;
        }
      } else if (Array.isArray(collections)) {
        collections = collections.filter(collection => !collection?.isFavorite)
        if (collections?.length === 1) {
          defaultCollection = collections[0];
        } else {
          defaultCollection = collections?.find(collection => collection.isDefault);
        }
      }
      return defaultCollection;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.isOpenDescriptionField = false;
    this.isDragAndDropEnabled = !this.staticService.isMobileDesign();
  }

  public changeMode(mode: PinnedModeIdx): void {
    if (mode !== this.changedPinnedMode) {
      this.changedPinnedMode = mode;
    }

    this.userCollectionService.getFilteredCollections()
      .pipe(takeUntil(this.destroy$))
      .subscribe(items => {
        let defaultCollection = this.findDefaultCollection(this.isProfile ? [this.selectCollectionData] : items);
        if (defaultCollection) {
          this.setItem(defaultCollection);
          this.checkBottomSheet();
        }
      });
  }

  public loadPins() {
    this.userCollectionService.getFilteredCollections()
      .pipe(take(1))
      .subscribe(items => {
        let defaultCollection = this.findDefaultCollection(this.isProfile ? [this.selectCollectionData] : items);
        this.changeMode(this.mode);
        this.setItem(defaultCollection);
        this.checkBottomSheet();

        if (this.userService.loggedIn()) {
          this.subscriptions.add(
            this.store$.select(getHomeData).subscribe(homeData => {
              this.userData = homeData?.user;
              this.setCollectionRoute(defaultCollection);
            })
          );
        }
      });
  }

  public deleteItem(place: PlacePinExt): void {
    this.mode = PinnedPanelCollectionInfoComponent.getModeByType(place.otype);
    if (this.isProfile) {
      this.selectCollectionData[this.mode] = this.selectCollectionData[this.mode].filter(item => item.oid !== place.oid);
    }
    this.deletePin.emit();
  }

  public close(): void {
    this.store$.dispatch(new TogglePinnedPanel({display: false}));
  }

  public getMode(): PinnedModeIdx {
    return this.changedPinnedMode || this.mode || PinnedModeIdx.Places;
  }

  private static getModeByType(type: DestinationType) {
    let currentMode: PinnedModeIdx = PinnedModeIdx.Cities;

    switch (type) {
      case DestinationType.Place:
        currentMode = PinnedModeIdx.Places;
        break;
      case DestinationType.Restaurant:
        currentMode = PinnedModeIdx.Restaurants;
        break;
    }

    return currentMode;
  }

  public toggleEditJourneyDescription() {
    if (this.isBottomSheet) {
      this.userCollectionService.bottomSheetActions = {data: {action: 'toggleEditDescriptionCollection'}};
      this.emitOpenBottomSheet.emit();
    } else {
      this.isOpenDescriptionField = !this.isOpenDescriptionField;
    }
  }

  public cancelEditJourneyDescription() {
    this.toggleEditJourneyDescription();
    this.journeyDescription = this.oldJourneyDescription;
  }

  public updateJourneyDescription() {
    if (this.isProfile) {
      this.selectCollectionData.description = this.journeyDescription;
    }
    if (this.oldJourneyDescription !== this.journeyDescription) {
      this.updateCollection();
    }
    this.toggleEditJourneyDescription();
  }

  public toggleEditTitleCollection() {
    this.isEdit = !this.isEdit;

    if (!this.isEdit && !this.journeyTitle) {
      this.journeyTitle = this.defaultJourneyTitle;
    }
    if (this.isEdit && this.journeyTitle === this.defaultJourneyTitle) {
      this.journeyTitle = '';
    }
    this.isOpenPopup = false;
    this.repeatFunc = 0;
  }

  public cancelEditTitleCollection() {
    this.isEdit = !this.isEdit;
    this.journeyTitle = this.oldJourneyTitle;
  }

  public updateTitleCollection() {
    if (this.isProfile) {
      this.selectCollectionData.name = this.journeyTitle;
    }
    if (this.oldJourneyTitle !== this.journeyTitle) {
      this.updateCollection();
    }
    this.isEdit = !this.isEdit;
  }

  public toggleEditTitlePopup() {
    if (this.isBottomSheet) {
      this.userCollectionService.bottomSheetActions = {data: {action: 'toggleEditTitlePopup'}};
      this.emitOpenBottomSheet.emit();
    } else {
      if (!this.repeatFunc) {
        this.isOpenPopup = !this.isOpenPopup;
        this.repeatFunc++;
      } else {
        this.isOpenPopup = false;
        this.repeatFunc = 0;
      }
    }
    this.checkSideAlign();
  }

  public deleteCollection(isConfirmed: boolean = true): void {
    if (isConfirmed) {
      this.isOpenPopup = !this.isOpenPopup;
      this.store$.dispatch(new CheckRemoveCollection(this.journey, this.isProfile));
      this.emitDeleteCollection.emit();
    }
    this.isDeleteConfirmationVisible = false;
  }

  public emitAction(funcName) {
    this[funcName]();
  }

  public createCollection(newTitleCollection: string) {
    this.isCreate = !this.isCreate;
    this.emitCreateCollection.emit({name: newTitleCollection});
    this.setItem(null);
  }

  public cancelCreateCollection() {
    this.isCreate = false;
    this.emitCancelCreateCollection.emit();
  }

  public openCreateCollectionField(event?: ChangeJourneyInterface) {
    this.emitOpenCreateCollectionField.emit(event);
  }

  public changeCollection(place: { currentPlace: PlacePinExt, isAddToCollection?: boolean }): void {
    this.mode = PinnedPanelCollectionInfoComponent.getModeByType(place.currentPlace.otype);
    if (this.isProfile && !place.isAddToCollection) {
      this.selectCollectionData[this.mode] =
        this.selectCollectionData[this.mode].filter(item => item.oid !== place.currentPlace.oid);
    }
  }

  public updateDefaultCollection() {
    if (this.journey?.id !== this.selectCollectionData?.id
      && this.selectCollectionData?.id !== FAVORITE_ID
      && this.selectCollectionData?.id !== DEFAULT_JOURNEY_ID) {
      this.store$.dispatch(new UpdatePinCollections({
        id: this.selectCollectionData.id,
        isProfilePage: this.isProfile,
        isDefault: true,
      }));

      this.allJourneys = this.allJourneys.map((item, key) => {
        return {
          ...item,
          isDefault: this.selectCollectionData.id === item.id
        }
      });
    }
  }

  public setCollectionRoute(collection): void {
    if (collection) {
      this.collectionRoutePath = '/journey/' + collection?.id
    }
  }

  public checkBottomSheet(): void {
    if (this.isBottomSheet && this.items) {
      this.items = this.items
        .filter((item, index, arr) => (arr.length - 1 === index || arr.length - 2 === index))
        .reverse();
    } else if (!this.isBottomSheet) {

      const bottomSheet: BottomSheetActionsInterface = this.userCollectionService.bottomSheetActions;
      if (bottomSheet && bottomSheet.data) {
        if (bottomSheet.data.itemId && bottomSheet.data.action) {
          this.items = JSON.parse(JSON.stringify(this.items));
          this.items = this.items.map(item => {
            if (item.oid === bottomSheet.data.itemId) {
              item.action = bottomSheet.data.action;
            }
            return item;
          });
        }
      }
    }
  }

  public checkTitleCollectionEmit() {
    if (!this.isBottomSheet) {
      const bottomSheet: BottomSheetActionsInterface = this.userCollectionService.bottomSheetActions;
      if (bottomSheet && bottomSheet.data && !bottomSheet.data.itemId && bottomSheet.data.action) {
        this.emitAction(bottomSheet.data.action);
        this.userCollectionService.bottomSheetActions = null;
      }
    }
  }

  public openBottomSheet(): void {
    this.emitOpenBottomSheet.emit();
  }

  public publicCollection(): void {
    this.toggleVisibleCollection(true);
  }

  public privateCollection(): void {
    this.toggleVisibleCollection();
  }

  public showLines(): void {
    this.changeLinesVisibility(true);
  }

  public hideLines(): void {
    this.changeLinesVisibility(false);
  }

  public changeLinesVisibility(isVisible: boolean) {
    if (this.isProfile) {
      this.selectCollectionData.isLineVisible = isVisible;
      this.selectCollectionData.name = this.journeyTitle;
    }
    this.store$.dispatch(new UpdatePinCollections({
      id: this.isProfile ? this.selectCollectionData.id : this.journey.id,
      ...(!this.isProfile) && {isDefault: true},
      isProfilePage: this.isProfile,
      isLineVisible: isVisible
    }));
  }

  public toggleVisibleCollection(isPublic = false) {
    if (this.isProfile) {
      this.selectCollectionData.isPublic = isPublic;
      this.selectCollectionData.name = this.journeyTitle;
    }
    this.store$.dispatch(new UpdatePinCollections({
      id: this.isProfile ? this.selectCollectionData.id : this.journey.id,
      ...(!this.isProfile) && {isDefault: true},
      isProfilePage: this.isProfile,
      isPublic: isPublic
    }));
  }

  public checkSideAlign() {
    this.parentLength = document.getElementById('collectionTitleEl')?.offsetWidth;
    const text = document.createElement('p');
    this.el.nativeElement.offsetParent.appendChild(text);
    text.style.position = 'absolute';
    text.style.top = '-10000%';
    text.style.fontSize = '22px';
    text.style.fontWeight = '500';
    text.style.fontFamily = 'Montserrat, Arial, sans-serif';
    text.style.height = 'auto';
    text.textContent = this.journeyTitle;

    const textLength = text.clientWidth;
    if (textLength < 143) {
      this.sideAlign = 'left';
    } else {
      this.sideAlign = 'right';
    }
    this.el.nativeElement.offsetParent.removeChild(text);
  }

  public openPlace(event): void {
    this.openPlaceEmitter.emit(event);
  }

  private setItem(collection) {
    let allItems = [];
    if (collection) {
      allItems = allItems.concat(collection[PinnedModeIdx.Places]);
      allItems = allItems.concat(collection[PinnedModeIdx.Cities]);
      allItems = collection[PinnedModeIdx.Countries] ? allItems.concat(collection[PinnedModeIdx.Countries]) : allItems;
      allItems = collection[PinnedModeIdx.Journeys] ? allItems.concat(collection[PinnedModeIdx.Journeys]) : allItems;
      if (JSON.stringify(this.items) != JSON.stringify(allItems)) {
        this.items = allItems;
        this.prevItems = JSON.parse(JSON.stringify(allItems));
      }
    } else {
      this.items = [];
      this.prevItems = [];
    }
  }

  private updateCollection() {
    if (this.journey) {
      if (this.userService.loggedIn()) {
        this.store$.dispatch(new UpdatePinCollections({
          id: this.isProfile ? this.selectCollectionData.id : this.journey.id,
          name: this.journeyTitle,
          description: this.journeyDescription,
          isProfilePage: this.isProfile,
          isDefault: true
        }));
      } else {
        if (this.ssrService.isBrowser()) {
          this.journey.name = this.journeyTitle;
          this.journey.description = this.journeyDescription;
          localStorage.setItem(NGX_USER_COLLECTIONS, JSON.stringify(this.journey));
        }
      }
    } else {
      this.emitCreateCollection.emit({
        name: this.journeyTitle,
        description: this.journeyDescription
      });
    }
  }

  public redirectToJourney() {
    if (this.isFavorites) {
      return;
    }
    if (this.ssrService.getIsUserDomain()) {
      window.location.href = constructBaseUrl(this.collectionRoutePath);
    } else {
      // To redirect strictly if we are even on the same collection page, in order to update lines on map for instance.
      this.router.navigateByUrl('/', {skipLocationChange: true})
        .then(() => {
          this.router.navigate([this.collectionRoutePath], {state: {collection: this.journey}});
        });
    }
  }

  protected setDescription(text: string): void {
    this.journeyDescription = text;
    this.validateDescription();
  }

  protected initEditor(editor: any): void {
    this.descEditor = editor;
  }

  protected drop(event: CdkDragDrop<{ item: PinInterface; index: number }, any>): void {
    moveItemInArray(this.items, event.previousContainer.data.index, event.container.data.index);
  }

  protected updatePlaceOrder(): void {
    if (!this.journey) {
      return;
    }
    if (!this.isOrderChanged()) {
      if (this.staticService.isMobileDesign()) {
        this.isDragAndDropEnabled = false;
      }
      return;
    }
    if (this.isProfile) {
      this.selectCollectionData[this.mode] = JSON.parse(JSON.stringify(this.items));
    }
    this.prevItems = JSON.parse(JSON.stringify(this.items));
    this.store$.dispatch(new UpdateCollectionPlaceOrder(JSON.parse(JSON.stringify(this.items)), this.journey.id));
    if (this.staticService.isMobileDesign()) {
      this.isDragAndDropEnabled = false;
    }
  }

  protected isDragAndDropAvailable(): boolean {
    return this.items.length > 1 && !this.isFavorites && this.userService.loggedIn();
  }

  protected enableDragAndDrop(): void {
    this.isDragAndDropEnabled = true;
  }

  protected disableDragAndDrop(): void {
    this.isDragAndDropEnabled = false;
  }

  private validateDescription(): void {
    if (
      this.descEditor
      && this.descEditor.plugins.wordcount
      && this.descEditor.plugins.wordcount.body.getCharacterCount() > this.maxDescriptionLength
    ) {
      this.descriptionError =
        `Sorry, the text length should not exceed  ${this.maxDescriptionLength} characters limit.`;
    } else {
      this.descriptionError = '';
    }
  }

  protected scrollUp(): void {
    this.scrollableContainer.scrollBy({
      top: -this.staticService.resizeWindow,
      behavior: 'smooth'
    });
  }

  protected scrollDown(): void {
    this.scrollableContainer.scrollBy({
      top: this.staticService.resizeWindow,
      behavior: 'smooth'
    });
  }

  protected isSaveOrderDisabled(): boolean {
    return !this.isOrderChanged() || this.userAccountService.isPinLoading;
  }

  protected openAddPlaceLink(): void {
    if (this.userService.loggedIn()) {
      if (this.ssrService.getIsUserDomain()) {
        window.location.href = constructBaseUrl('/place/add');
      } else {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
          this.router.navigate(['/place/add']));
      }
    } else {
      if (this.ssrService.isBrowser()) {
        localStorage.setItem('nextActionAfterAuth', AfterAuthAction.AddPlace);
      }
      this.authModalService.open();
    }
  }

  private isOrderChanged(): boolean {
    return JSON.stringify(this.prevItems) !== JSON.stringify(this.items);
  }

  private initScrollableContainer(): void {
    if (this.ssrService.isBrowser()) {
      //TODO: Use ViewChild instead
      if (this.isProfile) {
        this.scrollableContainer = window;
      } else {
        this.scrollableContainer = document.getElementsByClassName('pinned-panel-container')[0];
      }
    }
  }

  private confirmCollectionRemoval(): void {
    this.isDeleteConfirmationVisible = true;
  }
}
