<div class="collection__container" [class.m-0]="isBottomSheet">

  <div class="collection__title__container">
    <div class="collection__title fit-text" *ngIf="!isEdit" (click)="openBottomSheet()">
      <div class="collection__title-text cursor-disabled">
        {{journeyTitle ? journeyTitle : defaultJourneyTitle}}
      </div>

      <div (click)="toggleEditJourneyTitle()">
        <ng-container *ngIf="isBottomSheet; then createJourneyContainer; else signIn"></ng-container>
        <ng-template #createJourneyContainer>
          <div class="collection__title__edit">
            <div class="collection__title__edit-icon">
              <div class="threeDots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #signIn>
          <app-sign-in-buttons [isNotSignInButton]="createJourneyContainer"
                               [showLoginForm]="false"></app-sign-in-buttons>
        </ng-template>
      </div>
    </div>

  </div>

  <div class="collection__description" *ngIf="journeyDescription?.length">
    {{journeyDescription}}
  </div>

  <a class="collection__description__action"
     (click)="toggleEditJourneyDescription()">
    {{journeyDescription?.length ? 'Edit' : 'Add a description'}}</a>
  <div class="collection__description__subtext" *ngIf="!journeyDescription?.length">Write a text about it ...</div>

  <ng-container>
    <div class="pinned__item-content"
         *ngIf="items && items.length > 0 && !isPinnedLoading; else (isPinnedLoading ? loadingItems : notFoundItems)">
      <app-pinned-panel-item
        *ngFor="let item of items"
        [month]="month"
        [isBottomSheet]="isBottomSheet"
        [currentPlace]="item"
        [collections]="allCollections"
        [disablePopup]="true"
        (changeMode)="changeMode($event)"
        (deletePinned)="deleteItem($event)"
        (openPlaceEmitter)="openPlace($event)"
      ></app-pinned-panel-item>
    </div>
  </ng-container>
</div>

<ng-template #notFoundItems>
  <div class="pinned__item-content">
    <div class="pinned-desc">
      <span>You don't have any pinned {{ getMode() }} yet</span>
      <span class="sub-title-desc">
        Just click on the blue icon <img [src]="'/assets/pin/unactive-pin.svg'" alt="pin"> to pin it
      </span>
    </div>
  </div>
</ng-template>

<ng-template #loadingItems>
  <div class="pinned__item-content">
    <div class="pinned-desc">
      <span>Loading...</span>
    </div>
  </div>
</ng-template>
