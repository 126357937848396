import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { doAsync } from '../../libraries';
import { ToastrService } from '../../services/toastr.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-fly-toastr',
  templateUrl: './fly-toastr.component.html',
  styleUrls: ['./fly-toastr.component.scss']
})
export class FlyToastrComponent implements OnInit, OnDestroy {

  public isShowToastr = false;
  @Input() timeout: number;
  @Input() isDanger = false;
  @Input() message: string;

  private $destroyed = new Subject<void>();

  constructor(private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.toastrService.activeToastr$
      .pipe(takeUntil(this.$destroyed))
      .subscribe((data) => {
        this.showAlert(data.message, data?.timeout, data?.isDanger);
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private showAlert(message: string, timeout: number = 6000, isDanger: boolean = true): void {
    this.isShowToastr = true;
    this.isDanger = isDanger;
    this.message = message;
    this.timeout = timeout;
    if (timeout > 0) {
      doAsync(() => this.isShowToastr = false, this.timeout);
    }
  }

  public closeAlert() {
    this.isShowToastr = false;
  }


}
