import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from "rxjs/internal/Subject";
import { RouterHistoryService } from "./router-history.service";
import { takeUntil } from "rxjs/operators";
import { GuideElementsEnum } from "../enums/guide-elements.enum";
import { GUIDE_STEP } from "../../constants";
import { Subscription } from "rxjs/internal/Subscription";
import { SsrService } from "./ssr.service";

@Injectable({
  providedIn: 'root'
})
export class GuideTooltipService implements OnDestroy {

  public tooltipButton$ = new Subject<GuideElementsEnum | null>();
  public isTooltipRequired: boolean;

  private readonly tooltipTexts: any = [
    {text: 'Add a travel point or attraction to the map in just a minute', element: GuideElementsEnum.AddPlace},
    {text: 'Create a Journey from the attraction points on the map', element: GuideElementsEnum.Journeys},
  ];
  private currentStep: number = -1;
  private $destroyed = new Subject<void>();
  private readonly tooltipDelay = 4000;
  private historySubscription: Subscription = new Subscription();

  constructor(
    private readonly routerHistoryService: RouterHistoryService,
    private readonly ssrService: SsrService,
  ) {
    if (this.ssrService.isBrowser()) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.historySubscription.unsubscribe();
  }

  public init(): void {
    this.currentStep = this.getCurrentStep();
    this.historySubscription.add(
      this.routerHistoryService.currentUrl$
        .pipe(
          takeUntil(this.$destroyed)
        )
        .subscribe((currentUrl: string): void => {
          this.isTooltipRequired = this.isInitStep(currentUrl) || this.currentStep !== -1;
          if (this.isTooltipRequired) {
            setTimeout(() => {
              this.next();
            }, this.tooltipDelay);
          }
        })
    );
  }

  public next(): void {
    this.currentStep = this.currentStep < this.tooltipTexts.length - 1 ? this.currentStep + 1 : -1;
    this.tooltipButton$.next(this.tooltipTexts[this.currentStep]?.element || null);
    this.historySubscription.unsubscribe();
    this.saveNextStep(this.currentStep);
  }

  public getTooltipText(): string {
    return this.tooltipTexts[this.currentStep]?.text || '';
  }

  private getCurrentStep(): number {
    const step = localStorage.getItem(GUIDE_STEP);
    return step === null ? -1 : +step;
  }

  private saveNextStep(step: number): void {
    localStorage.setItem(GUIDE_STEP, step.toString());
  }

  private isInitStep(currentUrl: string): boolean {
    const prevUrl = this.routerHistoryService.previousUrl$.getValue();
    if (this.currentStep > -1) {
      return false;
    }

    return currentUrl !== prevUrl
      && prevUrl
      && (prevUrl.startsWith('/account/profile') || prevUrl.startsWith('/account/info'));
  }
}
