<div class="empty-places" (click)="setContactInfo(titleRef.value);"></div>

<div class="edit-contact__form">
  <div class="add-place__form-item edit-contact-input">
    <input #titleRef
           appAutofocus
           [type]="textType"
           [(ngModel)]="data"
           (keydown.enter)="setContactInfo(titleRef.value)"
           class="form-control"
           maxlength="100"/>
    <div class="edit-contact__btn">
      <div *ngIf="titleRef.value.length" class="edit-contact__ok" (click)="setContactInfo(titleRef.value)">ok</div>
      <div *ngIf="titleRef.value.length" class="edit-contact__cancel" (click)="toggleContactForm()">
        <img src="/assets/cancel_black.svg" alt="cancel img">
      </div>
    </div>
  </div>
  <p class="input-counter">{{titleRef.value.length}} / 100 characters</p>
</div>
