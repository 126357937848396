import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IUploadFile } from '../../upload/interface/interface';
import { Subject } from 'rxjs/internal/Subject';
import { select, Store } from '@ngrx/store';
import { getHomeData, State } from '../../../store/reducers';
import { SearchService, SsrService } from '../../../services';
import { PlaceService } from '../../../services/place.service';
import { HttpParams } from '@angular/common/http';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { UserSettingsNames } from '../../../interfaces/user';
import { consoleDebug, constructMediaURL, doAsync, showMatch } from '../../../libraries';
import { DestinationType } from '../../../enums/destination-type';
import { Subscription } from 'rxjs/internal/Subscription';
import { typeMedia } from "../your-own-place/your-own-place.component";
import { Router } from "@angular/router";
import { HelperService } from "../../../services/helper.service";
import { getLinkByObject } from "../../../libraries/get-link-by-object";
import { ReviewStatusType } from "../../user-account/enums/place-status.enum";

@Component({
  selector: 'app-your-own-review',
  templateUrl: './your-own-review.component.html',
  styleUrls: [
    '../your-own-place/your-own-place.component.scss',
    '../../upload/upload-form/upload-form.component.scss',
    './your-own-review.component.scss']
})
export class YourOwnReviewComponent implements OnInit, AfterViewInit, OnDestroy {

  public readonly maxTextLength = 2000;
  public readonly maxImages = 12;
  public readonly maxVideos = 3;
  public address;
  public alertMessage: string[];
  public errorSubmit: boolean;
  public homeCity: any;
  public homeCityId = null;
  public isAccountPublic: any;
  public isLoadForm = true;
  public isModalNoticeData: {
    isShow: boolean,
    title: string,
    text: string
  };
  public isShowToastr = false;
  public isSubmit = false;
  public reviewId: number;
  public form: UntypedFormGroup;
  public userInfo: any;
  public selectPlace = null;
  public selectedLocationName = '';
  public showAutocomplete = false;
  public destList: any;
  public selectSearchItem: any;
  public inputSubscription$: Subscription;
  public query = new UntypedFormControl();
  public constructMediaURL = constructMediaURL;
  public videoAccept = this.placeService.videoAccept;
  public photoAccept = this.placeService.photoAccept;
  public uploadPhotoFiles: IUploadFile[] = [];
  public uploadVideoFiles: IUploadFile[] = [];
  public typeMedia = typeMedia;
  public placeErrorsArray: string[] = [];
  public placeErrors = {
    text: {
      id: 'textContainer',
      message: '',
      example: `Text must be maximum ${this.maxTextLength} characters.`
    },
    photo: {
      id: 'photoContainer',
      message: '',
      example: 'Files is required (max. ' + this.maxVideos + ').'
    },
    video: {
      id: 'videoContainer',
      message: '',
      example: 'Files is required (max. ' + this.maxVideos + ').'
    },
    checkboxCreate: {
      id: 'policyContainer',
      message: '',
      example: 'Please agree Privacy Policy and Terms and Conditions.'
    },
    checkboxPublish: {
      id: 'publishContainer',
      message: '',
      example: 'Please agree public Personal Page.'
    },
    place: {
      id: 'placeContainer',
      message: '',
      example: 'Place is required.'
    },
    place_id: {
      message: ''
    },
  };

  private descEditor: any;
  private $destroyed = new Subject<void>();

  @Input() externalPlace: any;
  @Input() m0: boolean;
  @Input() mapPage: boolean;

  @Output() removeTypeFormValue = new EventEmitter<void>();
  @Output() discard = new EventEmitter<void>();

  @ViewChild('textContainer') textContainer: ElementRef;
  @ViewChild('placeContainer') placeContainer: ElementRef;
  @ViewChild('mediaContainer') mediaContainer: ElementRef;
  @ViewChild('photoContainer') photoContainer: ElementRef;
  @ViewChild('videoContainer') videoContainer: ElementRef;

  constructor(
    private store$: Store<State>,
    private placeService: PlaceService,
    private searchService: SearchService,
    private ssrService: SsrService,
    private helperService: HelperService,
    protected router: Router,
  ) {
  }

  ngOnInit() {
    this.isLoadForm = false;
    this.createForm();

    this.store$.pipe(
      select(getHomeData),
      takeUntil(this.$destroyed)
    ).subscribe((result) => {
      if (result && result.user) {
        this.userInfo = result.user;
        if (result.user.settings) {
          const setting = result.user.settings.find(s => s.id === UserSettingsNames.IsAccountPublic);
          this.isAccountPublic = setting.value;
          this.form.patchValue({
              checkboxPublish: !!this.isAccountPublic,
            },
            {
              emitEvent: false
            });
        }

        if (result.user.homeCity) {
          this.homeCityId = result.user.homeCity.id;
          this.homeCity = result.user.homeCity;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.inputSubscription$ = this.query.valueChanges
      .pipe(
        takeUntil(this.$destroyed),
        debounceTime(500),
        map(value => value.target.value),
        filter((newValue: string) => !!newValue && newValue.length >= 3)
      )
      .subscribe((newValue: string) => {
        this.searchPlace(newValue);
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.inputSubscription$.unsubscribe();
  }

  get placeId() {
    return this.form.controls.placeId as UntypedFormControl;
  }

  get text() {
    return this.form.controls.text as UntypedFormControl;
  }

  public displayFn(value: any): string {
    return value && value.name ? value.name : '';
  }

  public createForm(): void {
    this.form = new UntypedFormGroup({
      placeId: new UntypedFormControl(this.externalPlace && this.externalPlace.id || null, Validators.required),
      text: new UntypedFormControl('', Validators.compose([
        Validators.required,
        this.maxLengthTinymceValidator(this.maxTextLength)
      ])),
      checkbox: new UntypedFormControl(''),
      checkboxCreate: new UntypedFormControl('', Validators.required),
      checkboxPublish: new UntypedFormControl(this.isAccountPublic ? true : '', Validators.requiredTrue),
    });

    this.onChangeText();
  }

  public onChangeText() {
    this.form.controls.text.valueChanges.subscribe(x => {
      this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== 'text');
      if (!x) {
        this.placeErrors.text.message = 'Text is required.';
        this.placeErrorsArray.push('text');
      } else {
        this.placeErrors.text.message = 'Text is required.';
        this.setErrorMessageEmitter('', 'text');
      }
    })
  }

  public checkValidationByName(idError: string) {
    if (this.form.controls[idError]?.invalid) {
      this.placeErrors[idError].message = this.placeErrors[idError].example;
      this.placeErrorsArray.push(idError);
      this.form.controls[idError].invalid
    } else {
      this.removeError(idError);
    }
  }

  public removeError(idError: string) {
    this.placeErrorsArray = this.placeErrorsArray.filter(x => x !== idError);
    this.setErrorMessageEmitter('', idError);
  }

  public maxLengthTinymceValidator(maxLength: number): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return this.descEditor && this.descEditor.plugins.wordcount && this.descEditor.plugins.wordcount.body.getCharacterCount() > maxLength
        ? {maxLengthTinymce: {maxLength: maxLength}}
        : null;
    };
  }

  public setErrorMessageEmitter(text: string, idError: string) {
    if (idError && this.placeErrors[idError]) {
      this.placeErrors[idError].message = text;
      this.scrollToFirstInvalidControl();
    }
  }

  public deleteFile(file: IUploadFile, type: string = this.typeMedia.photo): any {
    if (type === this.typeMedia.photo) {
      this.uploadPhotoFiles = this.uploadPhotoFiles.filter(item => file.id !== item.id);
    } else {
      this.uploadVideoFiles = this.uploadVideoFiles.filter(item => file.id !== item.id);
    }
  }

  public getSelectPlace(placeData: any): void {
    const place = placeData?.option?.value?.place;
    switch (true) {
      case place?.metaData?.isReviewsDisabled:
        this.showAlert(['Sorry, the author has disabled comments for this place']);
        break;
      case place?.ownReviewsCount > 0:
        this.showAlert(['Sorry, you have already added photo/review for this place']);
        break;
      case +place?.user?.id === +this.userInfo.id:
        this.showAlert(['Sorry, you can\'t add photo/review for the place which already created by you']);
        break;
      default:
        if (place && this.destList) {
          this.selectPlace = place;
          if (this.selectPlace?.city?.name && this.selectPlace?.country?.name) {
            this.selectedLocationName = this.selectPlace.city.name + ', ' + this.selectPlace.country.name;
          } else if (this.selectPlace?.country?.name) {
            this.selectedLocationName = this.selectPlace.country.name;
          } else {
            this.selectedLocationName = '';
          }

          this.placeId.patchValue(place.id);
          this.setErrorMessageEmitter('', 'place');
          this.isSubmit = false;
        }
        break;
    }
  }

  public onSubmit(): void {
    this.alertMessage = [];
    this.errorSubmit = false;

    if (this.checkValidation()) {
      const data = this.createRequestData();

      if (this.form.invalid) {
        this.scrollToFirstInvalidControl();
        return;
      }

      this.isSubmit = true;
      // It's disabled initially in upload form to not show two progress bars on loading files
      this.helperService.ajaxLoaderVisible$.next(true);

      this.resetPlaceErrors();

      const httpParams = new HttpParams()
        .append(
          'expand',
          'user.picture,user.homeCity.country,pictures,place.descriptionFull,place.tags,place.category,place.pictures,' +
          'place.city.country,place.similarPlaces.pictures,place.similarPlaces.city.flights,' +
          'place.similarPlaces.city.country,place.similarPlacesLimit,place.city.flights,place.reviewsTotalCount,' +
          'place.canAddReview,tags,place.country,place.similarPlaces.country,place.user,place.reviews,place.city.hasPage,' +
          'place.similarPlaces.city.hasPage,place.reviews.pictures,place.reviews.video,place.video,' +
          'place.similarPlaces.video,video'
        );
      this.placeService.createReview(data, httpParams)
        .pipe(takeUntil(this.$destroyed))
        .subscribe({
          next: (result) => {
            this.isAccountPublic = true;
            this.makePostProcessing(result.body);
          },
          error: (error) => {
            this.isSubmit = false;
            this.helperService.ajaxLoaderVisible$.next(false);
            this.errorSubmit = true;

            if (error.error && error.error.length > 0) {
              const messages = error.error.map(err => err.message);
              this.showAlert(messages);
            }

            this.setRequestErrors(error.error);
            this.scrollToFirstInvalidControl();
          }
        });
    }
  }

  private makePostProcessing(review) {
    if (review.status === ReviewStatusType.ClientStatusOnline) {
      this.goToReviewPage(review);
    } else {
      this.isModalNoticeData = {
        isShow: true,
        title: `Thank you for adding a new review!`,
        text: 'Please note that copyright and user-friendly checks will take some time. We\'ll inform you when it is approved shortly.'
      };
    }
  }

  private goToReviewPage(review) {
    this.router.navigate(
      ['/places/' + review.place?.urlKeywords + '/' + review.place?.id + '/review/' + review.id],
      {fragment: 'new', state: {review: review}}
    );
  }

  protected goToProfileReviews() {
    this.router.navigate(['/account'], {queryParams: {view: 'your-reviews'}});
  }

  public checkValidation(): boolean {
    this.alertMessage = [];
    this.placeErrorsArray = [];
    this.errorSubmit = false;
    if (this.form.controls['placeId'].invalid) {
      this.placeErrors.place.message = this.placeErrors.place.example;
      this.placeErrorsArray.push('place');
    }
    if (this.form.controls['text']?.errors?.required) {
      this.placeErrors.text.message = 'Text is required.';
      this.placeErrorsArray.push('text');
    }
    if (this.form.controls['description']?.errors?.maxLengthTinymce) {
      this.placeErrors.text.message = this.placeErrors.text.example;
      this.placeErrorsArray.push('description');
    }
    if (this.form.controls['checkboxCreate'].invalid) {
      this.placeErrors.checkboxCreate.message = this.placeErrors.checkboxCreate.example;
      this.placeErrorsArray.push('checkboxCreate');
    }
    if (this.form.controls['checkboxPublish'].invalid) {
      this.placeErrors.checkboxPublish.message = this.placeErrors.checkboxPublish.example;
      this.placeErrorsArray.push('checkboxPublish');
    }

    if (this.form.invalid || this.placeErrorsArray?.length) {
      this.scrollToFirstInvalidControl();
      return false;
    } else {
      this.resetPlaceErrors();
      return true;
    }
  }

  public clickDiscard(): void {
    this.discard.emit();
  }

  public handleDescEditorInit({editor}) {
    this.descEditor = editor;
  }

  public searchPlace(v: string): void {
    if (v.length >= 2) {
      this.placeErrors.place_id.message = v;
      const httpParams = new HttpParams()
        .append('expand', 'pictures,ownReviewsCount')
        .append('filter[type]', DestinationType.Place)
        .append('filter[query]', v);
      this.searchService.searchDest(httpParams)
        .pipe(takeUntil(this.$destroyed))
        .subscribe(
          success => {
            this.showAutocomplete = true;
            if (!success || success.length === 0) {
              this.placeErrors.place_id.message = v;
              this.destList = [];
            } else {
              this['destList'] = showMatch(success, v);
            }
          },
          error => {
            consoleDebug('error dest-search-field => 325', error);
          }
        );
    } else {
      this.destList = [];
      this.showAutocomplete = true;
      this.selectSearchItem = null;
    }
  }

  public removeSelectedPlace() {
    this.selectPlace = null;
    this.placeId.patchValue(null);
    this.isSubmit = false;
  }

  protected scrollToFirstInvalidControl() {
    this.form.markAsTouched();
    let firstErrorParam = '';

    for (const item in this.placeErrorsArray) {
      const currentKey = this.placeErrorsArray[item];
      if (this.placeErrors[currentKey]?.message) {
        firstErrorParam = this.placeErrors[currentKey].id;
        break;
      }
    }

    if (this[firstErrorParam]?.nativeElement) {
      let top = YourOwnReviewComponent.getTopOffset(this[firstErrorParam].nativeElement);


      if (this.ssrService.isBrowser()) {
        window.scroll({top: top - YourOwnReviewComponent.getAdditionalOffset(), left: 0, behavior: 'smooth'});
      }
    }
  }

  private static getTopOffset(controlEl: HTMLElement): number {
    return controlEl && controlEl.getBoundingClientRect().top + window.scrollY || 0;
  }

  private static getAdditionalOffset(): number {
    const header = document.querySelector('app-static-header .header__content');
    const labelOffset = 70;

    return labelOffset + header.clientHeight;
  }

  private showAlert(text: string[]): void {
    this.isShowToastr = true;
    this.alertMessage = text;
    doAsync(() => this.isShowToastr = false, 6000);
  }

  private createRequestData(): any {
    const {placeId, text} = this.form.value;
    return {
      text: text,
      placeId: placeId,
      pictures: this.uploadPhotoFiles.map(file => file.id || file.url),
      video: this.uploadVideoFiles.map(file => file.id || file.url),
    };
  }

  private resetPlaceErrors(): void {
    this.placeErrorsArray = [];
    for (const item in this.placeErrors) {
      this.placeErrors[item].message = '';
    }
  }

  private setRequestErrors(errors: any[]): void {
    if (errors && errors.length > 0) {
      errors.forEach(err => {
        if (typeof this.placeErrors[err.field] !== 'undefined') {
          this.placeErrors[err.field].status = true;
          this.placeErrors[err.field].message = err.message;
        }
      });
    }
  }

  protected readonly getLinkByObject = getLinkByObject;
}
