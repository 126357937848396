import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import chunk from 'lodash/chunk';

import { WhenDate } from "../../../../../../interfaces";
import { DatePickerMode } from "../../../../../../interfaces/datepicker-date";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

@Component({
  selector: 'app-date-view',
  templateUrl: './date-view.component.html',
  styleUrls: ['./date-view.component.scss']
})
export class DateViewComponent implements OnInit, OnChanges {

  @Input() date = dayjs();
  @Input() interval: any;
  @Input() minDateLimit: WhenDate = null;
  @Input() maxDateLimit: WhenDate = null;
  @Input() large = false;

  @Output() selectEmitter = new EventEmitter<any>();

  public title = '';
  public weeksLine = [];

  private today = dayjs();

  constructor() {
  }

  private checkIsBefore(date: Dayjs) {
    return (date.isBefore(this.today, 'day'))
      ? true
      : (this.minDateLimit && date.isSameOrBefore(this.minDateLimit.date, 'day'));
  }

  private checkIsAfter(date: Dayjs) {
    return (date.isAfter(this.today, 'day'))
      ? true
      : (this.maxDateLimit && date.isAfter(this.maxDateLimit.date, 'day'));
  }

  ngOnInit() {
    const initialData = (!this.date) ? dayjs() : this.date;
    this.title = initialData.format('MMMM YYYY');

    let startOfMonth = dayjs(this.date).startOf('month');
    const endOfMonth = dayjs(this.date).endOf('month');
    const dayOfWeek = startOfMonth.day();
    const monthLine = new Array(dayOfWeek);
    while (startOfMonth.isSameOrBefore(endOfMonth)) {
      const isBefore = this.checkIsBefore(startOfMonth);
      const isAfter = this.checkIsAfter(startOfMonth);
      monthLine.push({
        type: DatePickerMode.Date,
        date: dayjs(startOfMonth),
        title: startOfMonth.format('D'),
        isToday: startOfMonth.isSame(this.today, 'day'),
        ...this.minDateLimit && {isBefore},
        ...this.maxDateLimit && {isAfter},
        isInInterval: this.isInInterval(startOfMonth),
        selected: (this.date.format('DD.MM.YYYY') === startOfMonth.format('DD.MM.YYYY')),
        isAfterToday: startOfMonth.isAfter(this.today, 'day'),
      });
      startOfMonth = startOfMonth.add(1, 'day');
    }
    const chunks = chunk(monthLine, 7);
    if (chunks[chunks.length - 1].length < 7) {
      chunks[chunks.length - 1] = [...chunks[chunks.length - 1], ...(new Array(7 - chunks[chunks.length - 1].length))];
    }
    this.weeksLine = chunks;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.interval) {
      this.ngOnInit();
    }
  }

  isInInterval(date: Dayjs) {
    let result = false;
    if (this.interval && this.interval.date.length === 2) {
      result = date.isBetween(this.interval.date[0].date, this.interval.date[1].date);
    }
    return result;
  }

  offsetDate(value: number) {
    const nextData = dayjs(this.date).add(value, 'month');
    if (this.minDateLimit && nextData.isBefore(dayjs(), 'month')
      || this.maxDateLimit && nextData.isAfter(dayjs(), 'month')) {
      return;
    }
    this.date = nextData;
    this.ngOnInit();
  }

  selectDate(date) {
    if (date && !(this.minDateLimit && date.isBefore) && !(this.maxDateLimit && date.isAfter)) {
      this.weeksLine.forEach(week => week.forEach(day => {
        if (day) {
          day.selected = false;
        }
      }));
      date.selected = true;
      this.selectEmitter.emit(date);
    }
  }

}
