<app-link-social-account
  *ngIf="auth"
  [auth]="auth">
</app-link-social-account>

<app-unlink-social-account
  *ngIf="auth"
  [auth]="auth"
  (unlinkEmitter)="doOnUnlink()">
</app-unlink-social-account>
