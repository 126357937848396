import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContactInfo, ContactInfoEnum } from "../../../interfaces/contact-info";
import { KeyValueInterface } from "../../../interfaces";

@Component({
  selector: 'app-contact-info-edit',
  templateUrl: './contact-info-edit.component.html',
  styleUrls: ['./contact-info-edit.component.scss']
})
export class ContactInfoEditComponent implements OnInit, OnChanges {

  @Input() isProfilePage: boolean;
  @Input() isContactInfoEnabled: boolean;
  @Input() contactInfo: ContactInfo;

  @Output() setContactInfoKeyValueEmitter = new EventEmitter<KeyValueInterface>();
  @Output() setContactInfoEmitter = new EventEmitter<ContactInfo>();

  public contactInfoEnum = ContactInfoEnum;
  public editableContactForm = {
    webSite: false,
    address: false,
    phone: false,
    email: false,
    whatsapp: false,
    businessHours: false,
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.contactInfo?.currentValue) {
      this.contactInfo = {...changes?.contactInfo?.currentValue};

      if (this.contactInfo?.businessHours) {
        this.contactInfo.businessHours = this.contactInfo.businessHours?.filter(x => x?.length)
      }
    }
  }

  ngOnInit(): void {
    if (!this.contactInfo) {
      this.contactInfo = {};
    }
  }

  toggleContactForm(type: ContactInfoEnum) {
    if (this.isContactInfoEnabled) {
      this.editableContactForm[type] = !this.editableContactForm[type];
    }
  }

  closeAllInputs() {
    for (let key in this.editableContactForm) {
      this.editableContactForm[key] = false;
    }
  }

  setContactInfo(type: ContactInfoEnum, data: any) {
    this.contactInfo[type] = data;
    this.setContactInfoKeyValueEmitter.emit({key: type, value: data});
    this.setContactInfoEmitter.emit(this.contactInfo);
    if (!this.isProfilePage) {
      this.toggleContactForm(type);
    }
  }
}
