export const TOOLTIP_WIDTH = 152;
export const TOOLTIP_HEIGHT = 152;
export const TOOLTIP_HEIGHT_MARGIN_TOP = 0;
export const TOOLTIP_BOTTOM_ARROW_HEIGHT = 15;
export const TOOLTIP_WIDTH_MARGIN_RIGHT = 0;
export const MIN_ZOOM = 2;
export const MAX_ZOOM = 18;
export const DEFAULT_ZOOM = 4;
export const DEFAULT_USER_IMAGE = '/assets/images/user.png';
export const DEFAULT_JOURNEY_ID = -1;
export const DEFAULT_TITLE_JOURNEY = 'Unnamed Journey';
export const BUTTON_NAMES_JOURNEYS = {
  ok: 'ok',
  create: 'create',
  cancel: 'cancel'
};
export const SERVICE_UNAVAILABLE = 'service-unavailable';
export const MAX_NOTIFICATION_ROWS = 10;
export const NGX_USER_CITIES = 'ngx_user-cities';
export const NGX_USER_COLLECTIONS = 'ngx_user-collections';
export const NGX_LOGGED_USER = 'ngx_logged-user';
export const GUEST_USER = 'guest-user';
export const NGX_USER_LIKES = 'ngx_user-likes';
export const LAST_ADD_FAVORITES = 'last-add-favorites';
export const GUIDE_STEP = 'guide-step';
