<div [class.grid-image__content]="galleryMediaData?.previewSliderImages?.length === 1"
     [class.w-100]="isLoadImage && !isPortrait"
     [class.is-portrait]="isPortrait"
>
  <div class="gallery-content-background">
    <div #galleryGridContainer
         [hidden]="!(typeGallery === imagesContentType.grid && galleryMediaData?.previewSliderImages?.length > 0)"
         class="container__images gallery-grid-container"
         [class.one-image]="galleryMediaData?.previewSliderImages?.length === 1"
         [class.w-100]="isLoadImage && !isPortrait">
      <div *ngFor="let image of galleryMediaData?.previewSliderImages | slice: 0:2; let i = index"
           class="image-item grid-container-item"
           [class.w-100]="isLoadImage && !isPortrait"
           [class.one-video]="isOneVideoMode(image)"
           (mouseenter)='overImage(image, true)'
           (mouseleave)='overImage(image, false)'>
        <app-image-content [src]="generateImagePath(image, i)"
                           alt="picture"
                           [videoData]="image?.isVideo && galleryMediaData.galleryMedia[activeIndex]"
                           [blur]="image?.isVideo && galleryMediaData?.previewSliderImages?.length > 1"
                           [zoomIn]="!image?.isVideo"
                           [playBtn]="image?.isVideo"
                           [isPortrait]="image?.isPortrait"
                           [oneElement]="galleryMediaData?.previewSliderImages?.length === 1"
                           (loadEmitter)="loadEmit($event)"
                           (galleryEmitter)="openGallery()"
                           (click)="openImage(true, i, image)"></app-image-content>
      </div>

      <div class="image-item grid-container-item"
           *ngIf="galleryMediaData?.previewSliderImages?.length > 1">
        <div class="h-100">
          <div class="h-100" *ngFor="let image of galleryMediaData?.previewSliderImages | slice: 2; let i = index"
               (click)="openImage(true, i + 2)">
            <app-image-content
              [src]="constructMediaURL(isNewContent() ? image?.large : image?.small ? image?.small : image?.thumb)"
              [blur]="image?.isVideo && galleryMediaData?.previewSliderImages?.length > 1"
              [isPortrait]="image?.isPortrait"
              [zoomIn]="!image?.isVideo"
              [playBtn]="image?.isVideo"
              [oneElement]="galleryMediaData?.previewSliderImages?.length === 1"
              alt="picture"></app-image-content>

            <div class="container__image-count"
                 *ngIf="galleryMediaData?.galleryMedia?.length > 3 && i === 0">
              + {{ galleryMediaData?.galleryMedia.length - 2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="sourceContainer; context: {galleryMediaData}"></ng-container>
</div>

<div class="slider-container" *ngIf="typeGallery === imagesContentType.slider">
  <div class="mobile-video-player">
    <div class="mobile-video-player__container">
      <app-static-place-item-pin [place]="place"
                                 (pinPlace)="pinPlace($event)"
      ></app-static-place-item-pin>

      <app-image-content [src]="constructMediaURL(
      galleryMediaData?.galleryMedia && galleryMediaData?.galleryMedia[activeIndex]?.isVideo
             ? galleryMediaData.galleryMedia[activeIndex].poster.large
             : isNewContent()
              ? galleryMediaData.galleryMedia[activeIndex]?.large
              : (activeIndex === 0)
                 ? galleryMediaData.galleryMedia[activeIndex]?.medium
                   ?  galleryMediaData.galleryMedia[activeIndex]?.medium
                   : galleryMediaData.galleryMedia[activeIndex]?.large
                 : galleryMediaData.galleryMedia[activeIndex]?.small
                   ? galleryMediaData.galleryMedia[activeIndex]?.small
                   : galleryMediaData.galleryMedia[activeIndex]?.thumb)"
                         alt="picture"
                         [playBtn]="galleryMediaData.galleryMedia[activeIndex]?.isVideo"
                         [isPortrait]="false"
                         [oneElement]="galleryMediaData?.previewSliderImages?.length === 1"
                         (click)="openImage(true, activeIndex)"></app-image-content>
    </div>

    <div class="mobile-video-player__slide-dots">
      <div *ngFor="let item of galleryMediaData?.galleryMedia; let i = index"
           class="slide-dot"
           [class.active]="i === activeIndex"></div>
    </div>

    <ng-container *ngIf="galleryMediaData?.galleryMedia?.length > 1">
      <div class="gallery__prev" (click)="onChangeImage(false)">
        <div class="item-arrow item-arrow-left"></div>
      </div>
      <div class="gallery__next" (click)="onChangeImage(true)">
        <div class="item-arrow item-arrow-right"></div>
      </div>
    </ng-container>
  </div>
</div>

<app-static-gallery
  *ngIf="isGalleryOpen"
  [windowWidth]="resizeWindow"
  [gallery]="galleryMediaData.allGalleryMedia"
  [place]="place"
  [activeIndex]="activeIndex"
  (closeGallery)="openImage($event)">
</app-static-gallery>

<ng-template #sourceContainer let-galleryMediaData="galleryMediaData">
  <div *ngIf="galleryMediaData?.galleryMedia?.length"
       [style.width]="isPortrait ? sourceContainerWidth + 'px' : '100%'"
       class="container__images__link-item">
        <span>
          Source:
          <a *ngIf="place?.url && !place?.user" [href]="place?.url" target="_blank">
            {{ staticInitService.getUrlLinkName(place?.url) }}
          </a>
          <a *ngIf="place?.user" [href]="this.userLink"
             target="_blank">
            {{ place?.user?.name }}
          </a>
          &nbsp;Images may be subject to copyright.
        </span>
    &nbsp;<a [routerLink]="'/copyright-infringement'" target="_blank" class="copyright-link">Learn More</a>
  </div>
</ng-template>
