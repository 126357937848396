import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedCityInterface } from '../fly-search-panel/fly-search-panel.component';
import { FlyCookieService } from '../../services/fly-cookie';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getHomeData, State } from '../../store/reducers';
import { SsrService, UserService, usertoken } from '../../services';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SignInButtonsComponent } from "../fly-search-panel/sign-in-buttons/sign-in-buttons.component";
import { UserLikesService } from "../../services/user-likes.service";
import { DestroyComponent } from "../../heplers/destroy.component";
import { StaticService } from "../../services/static.service";
import { ToggleFavoritePanel, ToggleFollowingPanel, TogglePinnedPanel } from "../../store/panels/panels.actions";
import { UserCollectionService } from "../../services/user-collection.service";
import { environment } from "../../../environments/environment";
import { constructBaseUrl } from "../../libraries";

import { GuideElementsEnum } from "../../enums/guide-elements.enum";
import { MobilePanelAction } from "../../enums/mobile-panel-action.enum";

@Component({
  selector: 'app-mobile-action-panel',
  templateUrl: 'mobile-action-panel.component.html',
  styleUrls: ['mobile-action-panel.component.scss']
})
export class MobileActionPanelComponent extends DestroyComponent implements OnInit, OnDestroy {

  public isDownScroll = false;
  public panelAction = MobilePanelAction;
  public selectedCity: SelectedCityInterface;
  public userIsLogged: boolean = false;
  public isFavouriteMenuVisible: boolean = false;
  public isLikeAnimation: boolean;
  public isPinnedAnimation: boolean;
  public isMapIconShow: boolean;
  public homeData: any;

  protected isUserDomain: boolean;
  protected readonly environment = environment;
  protected readonly journeyType: GuideElementsEnum = GuideElementsEnum.Journeys;

  private componentDestroyed: Subject<void> = new Subject<void>();
  private countClick = 0;
  private scrollConfig = {
    lastScrollTop: 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  };
  private excludePagesUrl: string[] = [
    '/terms-of-use',
    '/cookie-policy',
    '/privacy-policy',
    '/site-map',
    '/list-cities',
    '/copyright-infringement',
    '/place/add',
    '/place/edit',
    '/landing-business',
    '/help/travel-business-page',
    '/help/travel-business',
  ];

  // burger-menu
  @Input() action: EventEmitter<MobilePanelAction>;
  @Input() data;
  @Input() isOpenMap: BehaviorSubject<boolean>;
  @Input() listeningAllMapEvents: EventEmitter<any>; // All events map // listening all map events for tooltip
  @Input() month: any;
  @Input() pinnedChangeState: EventEmitter<any>;
  @Input() place;
  @Input() scrollTop = 0;
  @Input() searchMode;
  @Input() staticTypes;

  @Output() clearDest: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('signInButtons') signInComponent: SignInButtonsComponent;

  @HostListener('click') openCarousel() {
    this.countClick++;
    if (this.countClick > 1 && this.ssrService.isBrowser()) {  // We skip two events. because these events are not caused by the user.
      localStorage.setItem('isForceAppearTooltipToFirstOpenMap', 'false');
    }
  }

  constructor(
    protected store$: Store<State>,
    private router: Router,
    private cookieService: FlyCookieService,
    private userService: UserService,
    public userLikesService: UserLikesService,
    public staticService: StaticService,
    private ssrService: SsrService,
    private userCollectionService: UserCollectionService,
  ) {
    super();
    if (ssrService.isBrowser()) {
      document.addEventListener('scroll', this.scroll.bind(this), false);
    }
    this.isUserDomain = ssrService.getIsUserDomain();
  }

  ngOnInit(): void {
    this.isMapIconShow = !this.excludePagesUrl.find(url => this.router.url.includes(url));
    if (this.ssrService.isBrowser()) {
      this.userIsLogged = !!this.cookieService.get(usertoken);
      if (this.ssrService.isBrowser()) {
        this.selectedCity = JSON.parse(localStorage.getItem('selectedCityForBurgerMenu'));
      }
    }

    this.userService.isUserAuth
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.userIsLogged = value;
      });

    this.userLikesService.isLikeAnimation$.pipe(
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.isLikeAnimation = true;
        setTimeout(() => this.isLikeAnimation = false, 1000);
      });
    this.userCollectionService.isPinnedAnimation$.pipe(
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.isPinnedAnimation = true;
        setTimeout(() => this.isPinnedAnimation = false, 1000);
      });
    this.store$.pipe(
      select(getHomeData),
      takeUntil(this.destroy$)
    ).subscribe((result) => {
      this.homeData = result;
    });
  }

  ngOnDestroy(): void {
    if (this.ssrService.isBrowser()) {
      document.removeEventListener('scroll', this.scroll);
    }
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  private scroll(): void {
    this.isDownScroll = window.innerHeight !== this.scrollConfig.height;
  }

  public dispatchAction(action: MobilePanelAction): void {
    if (this.excludePagesUrl.find(url => this.router.url.includes(url))) {
      switch (action) {
        case MobilePanelAction.TogglePinnedPanel:
          this.pinnedChangeState.emit(true);
          this.store$.dispatch(new TogglePinnedPanel({display: true}));
          break;
        case MobilePanelAction.ToggleFavoritePanel:
          this.pinnedChangeState.emit(true);
          this.store$.dispatch(new ToggleFavoritePanel({display: true}));
          break;
        case MobilePanelAction.ToggleFollowingPanel:
          this.pinnedChangeState.emit(true);
          this.store$.dispatch(new ToggleFollowingPanel({display: true}));
          break;
        case MobilePanelAction.CancelMap:
          this.router.navigateByUrl('/');
          break;
        case MobilePanelAction.NavigateAccount:
          if (this.userIsLogged) {
            this.router.navigate(['/account']);
          } else {
            this.toggleRegisterForm();
          }
          break;
        default:
          this.router.navigateByUrl('/');
          break;
      }
    } else {
      this.action.emit(action);
    }
  }

  public openPinned(): void {
    this.pinnedChangeState.emit(true);
  }

  public clearFilters(): void {
    this.clearDest.emit();
  }

  public toggleRegisterForm() {
    this.signInComponent.toggleRegisterForm();
  }

  public toggleFavouriteMenu(value?: boolean): void {
    if (value !== undefined) {
      this.isFavouriteMenuVisible = value;
    } else {
      this.isFavouriteMenuVisible = !this.isFavouriteMenuVisible;
    }
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
