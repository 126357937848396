import { UserFollowingActionsType, UserFollowingActionsUnion } from "./user-following.actions";
import { FollowedItem, FollowedList } from "../../interfaces/following";

export interface State {
  followed: FollowedList;
}

const initialState: State = {
  followed: {
    items: [],
    _sync: false,
  },
};

export function reducer(state: State = initialState, action: UserFollowingActionsUnion): State {
  switch (action.type) {
    case UserFollowingActionsType.SuccessLoadFollowed:
      const followed_ = JSON.parse(JSON.stringify(action.payload));
      followed_._sync = action.isUserLogged;

      return {
        ...state,
        followed: followed_
      };
    case UserFollowingActionsType.SuccessFollow:
      const addFollowed = JSON.parse(JSON.stringify(state.followed));
      if(addFollowed.items === undefined) {
        addFollowed.items = [];
      }
      addFollowed.items.push(action.payload);

      return {
        ...state,
        followed: addFollowed
      };
    case UserFollowingActionsType.SuccessUnfollow:
      const reducedFollowed = JSON.parse(JSON.stringify(state.followed));
      reducedFollowed.items = reducedFollowed.items.filter((item: FollowedItem) => item.user.id !== action.userId);

      return {
        ...state,
        followed: reducedFollowed
      };
    case UserFollowingActionsType.RestoreFollowed:
      return {
        ...state,
        followed: action.payload
      };
    case UserFollowingActionsType.ResetFollowed:
      return initialState;
    default:
      return state;
  }
}

export const _getFollowedList = (state: State) => state.followed;
