import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { StaticInitService } from '../../services/static-init.service';
import { constructMediaURL } from '../../../../libraries';
import { SsrService } from '../../../../services';
import { MoreTextDirective } from "../../../../directives/more-text.directive";
import { HelperService } from "../../../../services/helper.service";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { LocationStrategy } from "@angular/common";
import { StaticService } from "../../../../services/static.service";

export enum KEY_CODE {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Escape = 'Escape'
}

@Component({
  selector: 'app-static-gallery',
  templateUrl: './static-gallery.component.html',
  styleUrls: ['./static-gallery.component.scss'],
})
export class StaticGalleryComponent implements OnChanges, AfterViewInit {
  @Input() activeImage: string;
  @Input() activeIndex: number;
  @Input() gallery: any[] = [];
  @Input() place: any;
  @Input() windowWidth = 0;
  @Input() isCopyrightVisible: boolean = true;

  @Output() closeGallery: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('mobileVideoPlayerContainer') mobileVideoPlayerContainer: ElementRef;

  public constructMediaURL = constructMediaURL;
  public galleryData: any[] = [];
  public isShowControl: boolean = true;
  public isShowGalleryControl: boolean;
  public startX = 0;
  public isFullImgHeight = false;
  public countShowDescription = 0;
  public fullImgHeight: number;
  public windowHeight: number;
  public differenceToggleDots = 80;

  protected userLink: string;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.windowWidth > 896) {
      if (event.key === KEY_CODE.ArrowRight) {
        this.onChangeImage(true);
      }

      if (event.key === KEY_CODE.ArrowLeft) {
        this.onChangeImage(false);
      }
    }

    if (event.key === KEY_CODE.Escape) {
      this.close();
    }
  }

  constructor(
    public staticInitService: StaticInitService,
    private ssrService: SsrService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private location: LocationStrategy,
    public staticService: StaticService,
  ) {
    if (staticService.isMobileDesign() && ssrService.isBrowser()) {
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
        this.close();
      });
    }
  }

  ngOnChanges(changes) {
    if (changes['gallery'] && changes['gallery'].currentValue) {
      this.galleryData = changes['gallery'].currentValue;
      this.preloadImages();
    }
    if (changes['place'] && changes['place'].currentValue) {
      this.userLink = getLinkByObject(changes['place'].currentValue?.user);
    }
  }

  ngAfterViewInit(): void {
    this.checkGalleryHeight();
  }

  public onChangeImage(isForward: boolean): void {
    if (!this.galleryData?.length) {
      return;
    }
    if (isForward) {
      if (this.activeIndex === (this.galleryData.length - 1)) {
        this.activeIndex = 0;
      } else {
        this.activeIndex++;
      }
    } else {
      if (this.activeIndex === 0) {
        this.activeIndex = this.galleryData.length - 1;
      } else {
        this.activeIndex--;
      }
    }

    if (!this.galleryData[this.activeIndex]?.isVideo) {
      this.isShowControl = true;
    }
    this.checkGalleryHeight();

    this.preloadImages();
  }

  private preloadImages() {
    let nextIndex = this.activeIndex + 1;
    if (this.activeIndex === this.galleryData.length - 1) {
      nextIndex = 0;
    }
    if (!this.galleryData[nextIndex]?.isVideo) {
      this.preloadImage(nextIndex);
    }
    let prevIndex = this.activeIndex - 1;
    if (this.activeIndex === 0) {
      prevIndex = this.galleryData.length - 1;
    }
    if (!this.galleryData[prevIndex]?.isVideo) {
      this.preloadImage(prevIndex);
    }
  }

  private preloadImage(index: number) {
    (new Image()).src = constructMediaURL(this.galleryData[index]?.large || this.galleryData[index]?.url);
  }

  public setImage(i: number): void {
    this.activeIndex = i;
    this.preloadImages();
  }

  public close(): void {
    this.closeGallery.emit(false);
  }

  public isMobileDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile() || this.windowWidth <= 896;
  }

  public showControlEvent(event: boolean) {
    this.isShowControl = event;
  }

  public endVideoEvent(event = true) {
    let isFirst = true;
    for (let i = 0; i < this.galleryData.length; i++) {
      if (event && isFirst) {
        i = this.activeIndex;
      }
      let index = event && isFirst ? i + 1 : i;
      if (this.galleryData[index]) {
        this.activeIndex = index;
        this.preloadImages();
        return;
      } else if (i === this.galleryData.length - 1) {
        this.endVideoEvent(false);
        return;
      }
      isFirst = false;
    }
  }

  onPanStart(event: any): void {
    this.startX = event.deltaX;
  }

  onPanEnd(event: any): void {
    if (this.helperService.isPanRewindVideo) {
      this.helperService.isPanRewindVideo = false;
    } else {
      if (Math.abs(Math.abs(this.startX) - Math.abs(event.deltaX)) > 30) {
        if (this.startX > event.deltaX) {
          this.onChangeImage(true);
        } else if (this.startX < event.deltaX) {
          this.onChangeImage(false);
        }
      }
    }
  }

  public checkGalleryHeight() {
    if (this.ssrService.isBrowser() && this.mobileVideoPlayerContainer) {
      setTimeout(() => {
        this.windowHeight = window.innerHeight;
        this.fullImgHeight = this.mobileVideoPlayerContainer?.nativeElement?.offsetHeight;
        const offsetHeight = ((this.windowHeight - this.fullImgHeight) / 2) - 40; //total screen height - image height / 2 because space is left at the top and bottom - height of gallery points
        this.countShowDescription = MoreTextDirective.lineCount(this.mobileVideoPlayerContainer.nativeElement, offsetHeight) - 1;
        this.isFullImgHeight = this.windowHeight - this.fullImgHeight < this.differenceToggleDots;
        this.cdr.detectChanges();
      }, 50);
    }
  }

  protected readonly getLinkByObject = getLinkByObject;
}
