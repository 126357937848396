import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddCollections } from "../../../../store/user-collections";
import { Store } from "@ngrx/store";
import { State } from "../../../../store/reducers";

@Component({
  selector: 'app-create-collection-modal',
  templateUrl: './create-collection-modal.component.html',
  styleUrls: ['./create-collection-modal.component.scss']
})
export class CreateCollectionModalComponent {
  @Input() isProfilePage: boolean;

  @Output() emitCancelCreateCollection = new EventEmitter<void>();
  @Output() emitCreateCollection = new EventEmitter<string>();

  constructor(
    private store$: Store<State>,
  ) {
  }

  public createCollection(newTitleCollection) {
    this.store$.dispatch(new AddCollections({name: newTitleCollection},
      false,
      false,
      null,
      this.isProfilePage));
    this.emitCreateCollection.emit(newTitleCollection);
  }

  public cancelCreateCollection() {
    this.emitCancelCreateCollection.emit();
  }

}
