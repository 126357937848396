<h1>Copyright Notice</h1>
<ol>

  <h2>Introduction</h2>

  <li>Explorow is a company owned by Explorow Travel Pty Ltd trading as Explorow. Explorow, among other commercial
    activities, operates a User-generated Content Website.
  </li>

  <h2>Definitions</h2>

  <li><b>“Explorow”</b> refers to our website, and any sub domains registered with it, related software applications
    (“apps”), data, SMS, APS, email, chat, and telephone correspondence, buttons, widgets, and ads (collectively these
    are referred to as our <b>“Services”</b>; and the website and its mobile properties are referred to as
    <b>”websites”</b>.)
  </li>
  <li>This Copyright Notice forms a part of the Explorow website Terms of Service (the <b>“Terms”</b>). Your use of
    the
    website and/or our Services constitutes your agreement with our Terms including this Copyright Notice. This
    Copyright Notice (the <b>“Agreement”</b>) is an agreement between us and you relating to the use of your Content.
  </li>
  <li>The word <b>“you”</b> refers to the individual, company, business organisation or other legal entity using our
    Services and/or contributing Content to them. The Content that you Submit to or through the Services shall be
    referred to interchangeably as “your <b>Content</b>”, “Content of yours” and/or “Content you submit.”
  </li>
  <li>The word <b>“Content”</b> refers to any information, text, links, graphics, photos, audio, videos, data, code
    or other
    materials or arrangements of materials that you can view on, access or otherwise interact with through our Services
    and/or our Website.
  </li>
  <li>The term <b>“Intellectual Property Rights”</b> (IPR), means all IPR in the world whether currently existing or
    coming
    into existence at some future time and all rights pertaining thereto, whether recorded or registered in any manner
    or otherwise, including, but not limited, to any copyrights and related rights, industrial design rights and other
    design rights, registered designs, patents, utility models, inventions (whether or not patentable), trademarks,
    service marks, database and software rights, topography rights, trade secrets, know-how, confidential information,
    business names, trade names, brand names, domain names, user Content including, but not limited to user’s images,
    text, blog posts and comments, regular articles, graphics, and all other legal rights anywhere in the world
    protecting such intangible property including, where applicable, all renewals, extensions and applications for
    registration and the right to sue for damages for past and current infringement in respect of any of the same.
  </li>

  <h2>Acknowledgements</h2>

  <li>By accessing or using the Services, you agree to be bound by this Notice and represent that you have read and
    understood its terms.
  </li>
  <li>By submitting, uploading, adding, commenting, contributing to, or otherwise transmitting to, attaching, sending
    or giving (collectively and interchangeably referred to as “<b>Submitted</b>”/”Submitting”) any Content to the
    Website, you
    acknowledge you grant Explorow a non-exclusive, royalty-free, perpetual, transferable, irrevocable license to the
    Intellectual Property Rights (“IPR”) of the Content.
  </li>
  <li>This IPR grants us rights and permission to:
    <ol>
      <li>host, use, reproduce, modify, run, adapt, translate, distribute, publish, create derivative works from and
        publicly display and perform such Content of yours throughout the world in any media, now known or hereafter
        devised;
      </li>
      <li>make your Content available to the rest of the world and to let others do the same;
      </li>
      <li>to provide, promote, and improve the Services and to make your Content shared on the Services available to
        other companies, organisations or individuals for the syndication, broadcast, distribution, promotion or
        publication of such Content of yours on other media and services, subject to our Privacy Policy and this
        Agreement; and
      </li>
      <li>use the name and/or trademark that you submit in connection with such Content of yours.
      </li>
    </ol>
  </li>
  <li>You acknowledge that Explorow may choose to provide attribution of your Content, at our discretion.</li>
  <li>You further grant Explorow the right to pursue at law any person or entity that violates your or Explorow’s
    rights to your Content by a breach of this Agreement.
  </li>
  <li>You acknowledge and agree that your Content is non-confidential and non-proprietary.</li>
  <li>You affirm, represent, and warrant that you own or have the necessary licences, rights (including copyright
    and other proprietary rights), consents and permissions to publish and otherwise use (and for the Explorow to
    publish and otherwise use) your Content as authorised under this Agreement.
  </li>
  <li>Explorow takes no responsibility or assumes no liability for user Content that goes against acceptable
    community standards. Explorow will remove user Content that contains IPR infringements, hate speech, defamatory or
    libellous content, content that is offensive or intended to harm any individuals, misleading, false, indecent,
    fraudulent, or otherwise objectionable. No further notice will be provided of content removable. Further, Explorow
    assumes no liability or responsibility as a consequence for content that is removed.
  </li>
  <li>Explorow has no obligation to moderate, screen, edit or monitor any of the Content submitted on our website.
    However, we reserve the right, and have absolute discretion, to remove, screen, translate or edit without notice any
    Content submitted on the Services at any time and for any reason, or to have such actions performed by third parties
    on our behalf, and you are solely responsible for creating backup copies of and replacing any Content you post or
    otherwise submit to us or store on the Services at your sole cost and expense.
  </li>

  <h2>Jurisdiction</h2>

  <li>Explorow is subject to the commercial laws in force in the Commonwealth of Australia.</li>

  <h2>Submit a copyright complaint</h2>

  <li>If you believe that Explorow has breached IPR on any Content on the Website, or if you have any similar
    complaints or objections to content please contact us as soon as possible, via the form below.
  </li>
  <li>All forms received will be reviewed.</li>
  <li>During our investigation, we reserve the right to ask you to verify your identity and to seek further
    information from you to clarify your claim. We trust you understand that anonymous complaints, or complaints that do
    not contain sufficient detail, may not be able to be investigated. In such cases, we may not respond.
  </li>
  <li>We will exercise due consideration and effort to investigate complaints but cannot be held accountable if you
    have failed to provide relevant facts to us that you knew. Please be aware that any statements you make may be
    referred to in related court proceedings.
  </li>
  <li>Once an investigation is complete, any substantiated complaints are appropriately dealt with and the content
    in question will be removed within 28 days of determination.
  </li>

</ol>
