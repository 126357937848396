import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import reduce from 'lodash/reduce';
import { WhenDate } from "../../../../interfaces";
import { TripTypeEnum } from "../../../../enums/trip-type.enum";

interface BookingData {
  from: {
    name: string;
    iata: string;
  };
  to: {
    name: string;
    iata: string;
  };
  when: {
    departure: string;
    return: String;
  };
  tripType: TripTypeEnum | string;
  passengers: {
    adults: Number;
  };
}

@Component({
  selector: 'app-booking-popup',
  templateUrl: './booking-popup.component.html',
  styleUrls: ['./booking-popup.component.scss']
})
export class BookingPopupComponent implements OnInit {

  @Input() bookingData: BookingData;

  public document;
  public window: any;
  public place: any;
  public cityFrom;
  public date: WhenDate[];
  public iframeSrc: SafeResourceUrl;
  public opened: boolean;

  constructor(
    private elRef: ElementRef,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {

    const bookingLink = {
      marker: 164011,
      origin_name: this.bookingData.from.name,
      origin_iata: this.bookingData.from.iata,
      destination_name: this.bookingData.to.name,
      destination_iata: this.bookingData.to.iata,
      depart_date: this.bookingData.when.departure,
      return_date: this.bookingData.when.return,
      with_request: 'true',
      adults: this.bookingData.passengers.adults,
      children: 0,
      infants: 0,
      trip_class: 0,
      locale: 'en_us',
      one_way: (this.bookingData.tripType === TripTypeEnum.OneWay) + '',
      ct_guests: '1+passenger',
      ct_rooms: 1,
    };

    const queryParams = reduce(bookingLink, (httpParams, value, key) => {
      httpParams.push([key, (value || '').toString()].join('='));
      return httpParams;
    }, []).join('&');

    const iframeLink = [environment.flightsUrl, queryParams.toString()].join('?');
    this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(iframeLink);
  }

  public close() {
    document.body.removeChild(this.elRef.nativeElement);
  }

}
