import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesContentComponent } from "./images-content.component";
import { StaticPlaceItemPinComponent } from "../../static/components/static-place-item/static-place-item-pin/static-place-item-pin.component";
import { StaticGalleryComponent } from "../../static/components/static-gallery/static-gallery.component";
import { AppRoutingModule } from "../../../app-routing.module";
import { VideoPlayerModule } from "../../place/video-player/video-player.module";
import { ImageContentModule } from "../../place/image-content/image-content.module";
import { FlyPopupProfileModule } from "../../../shared-components/fly-popup/fly-popup-profile/fly-popup-profile.module";
import { NgScrollbarModule } from "ngx-scrollbar";

@NgModule({
  declarations: [
    ImagesContentComponent,
    StaticPlaceItemPinComponent,
    StaticGalleryComponent
  ],
  exports: [
    ImagesContentComponent,
    StaticPlaceItemPinComponent,
    StaticGalleryComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    VideoPlayerModule,
    ImageContentModule,
    FlyPopupProfileModule,
    NgScrollbarModule
  ]
})
export class ImagesContentModule {
}
