import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { LayerActionsTypes, LayerActionsUnion } from './layer.actions';
import { PoiSection } from '../../interfaces/poi';
import { SearchStateForm, State } from "../../interfaces";
import { DatePickerMode } from "../../interfaces/datepicker-date";
import { TripTypeEnum } from "../../enums/trip-type.enum";

const initialState: State = {
  notifications: [],
  ajaxState: [],
  modules: {
    search: {
      navbarState: false,
      form: {
        date: [
          {
            type: DatePickerMode.Month,
            date: dayjs()
              .startOf('month')
              .add(1, 'month')
          }
        ],
        pointFrom: null,
        pointTo: null,
        cityFrom: null,
        cityTo: null,
        tripType: TripTypeEnum.OneWay,
        passengers: 1,
        poi: [],
        type: null,
      },
      poi: []
    }
  }
};

export function reducer(
  state: State = initialState,
  action: LayerActionsUnion
) {
  switch (action.type) {
    case LayerActionsTypes.ResetSearchData: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            form: {
              ...initialState.modules.search.form,
              cityFrom: state.modules.search.form.cityFrom,
              pointFrom: state.modules.search.form.pointFrom,
            }
          }
        }
      };
    }
    case LayerActionsTypes.SetCurrentMMR: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            form: {
              ...state.modules.search.form,
              poi: action.payload.poi
            }
          }
        }
      };
    }
    case LayerActionsTypes.PatchSearchFormState: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            form: {
              ...state.modules.search.form,
              ...action.payload
            }
          }
        }
      };
    }
    case LayerActionsTypes.SetPassengers: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            form: {
              ...state.modules.search.form,
              passengers: action.payload,
            }
          }
        }
      };
    }
    case LayerActionsTypes.SetTripType: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            form: {
              ...state.modules.search.form,
              tripType: action.payload
            }
          }
        }
      };
    }
    case LayerActionsTypes.SetSearchData: {
      return {
        ...state,
        modules: {
          search: {
            ...state.modules.search,
            ...action.payload
          }
        }
      };
    }
    case LayerActionsTypes.SetSearchPanelState: {
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              navbarState: action.payload
            }
          }
        }
      );
    }
    case LayerActionsTypes.SetWhenDate:
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                date: action.payload,
              }
            }
          }
        }
      );
    case LayerActionsTypes.SetCityTo:
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                cityTo: action.payload,
              }
            }
          }
        }
      );
    case LayerActionsTypes.SetCityFrom:
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                cityFrom: action.payload,
              }
            }
          }
        }
      );
    case LayerActionsTypes.SetPointTo:
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                pointTo: action.payload,
                type: action.payload.type,
                countryCode: action.payload.countryCode || null,
              }
            }
          }
        }
      );
    case LayerActionsTypes.SetPointFrom:
      return Object.assign(
        {
          ...state
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                pointFrom: action.payload,
                type: action.payload.type,
                countryCode: action.payload.countryCode || null,
              }
            }
          }
        }
      );
    case LayerActionsTypes.SetPointToType:
      return Object.assign(
        {
          ...state,
        },
        {
          modules: {
            search: {
              ...state.modules.search,
              form: {
                ...state.modules.search.form,
                type: action.payload.type,
              }
            }
          }
        }
      );
    case LayerActionsTypes.AjaxStart:
      return {
        ...state,
        ajaxState: [...state.ajaxState, action.payload.uid]
      };
    case LayerActionsTypes.AjaxFinish:
      return {
        ...state,
        ajaxState: action.payload.uid ? state.ajaxState.filter(uid => uid !== action.payload.uid) : []
      };
    case LayerActionsTypes.NewNotifications:
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    default:
      return state;
  }
}

export const _getAjaxState = (state: State) => state.ajaxState.length > 0;
export const _getCityFrom = (state: State) =>
  state.modules.search.form.cityFrom;
export const _getCityTo = (state: State) => state.modules.search.form.cityTo;
export const _getDate = (state: State) => state.modules.search.form.date;
export const _getPassengers = (state: State) => state.modules.search.form.passengers;
export const _getTripType = (state: State) => state.modules.search.form.tripType;
export const _getSearchData = (state: State): SearchStateForm => state.modules.search.form;
export const _getAvailableMMR = (state: State): PoiSection[] =>
  state.modules.search.poi;
export const _searchUpdated = (state: State) => {
  return !isEqual(
    omit(state.modules.search.form, ['cityFrom']),
    omit(initialState.modules.search.form, ['cityFrom'])
  );
};
