import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { doAsync } from '../../../libraries';
import { CountryService } from '../../../services';
import { ICopyrightForm, IUploadFile } from '../interface/interface';
import { UploadService } from '../services/upload-service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  public alertMessage: string;
  public allCountry;
  public copyrightForm: UntypedFormGroup;
  public errorMessageLoad: string;
  public errorSubmit: boolean;
  public isShowToastr = false;
  public selectedItem;
  public uploadFiles: IUploadFile[] = [];

  constructor(
    private uploadService: UploadService,
    private countryService: CountryService,
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    this.allCountry = this.countryService.getAllCountries();
  }

  public createForm(): void {
    this.copyrightForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      userEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      rightsOwner: new UntypedFormControl('', Validators.required),
      userURL: new UntypedFormControl('', Validators.required),
      userCountry: new UntypedFormControl('', Validators.required),
      postalAddress: new UntypedFormControl('', Validators.required),
      companyName: new UntypedFormControl('', Validators.required),
      text: new UntypedFormControl('', Validators.required),
      accurateNoticeCheckbox: new UntypedFormControl('', Validators.required),
      copyrightCheckbox: new UntypedFormControl('', Validators.required),
    });
  }

  public onSubmit(): void {
    this.errorMessageLoad = '';

    const copyrightForm: ICopyrightForm = {
      text: this.copyrightForm.value.text,
      firstName: this.copyrightForm.value.firstName,
      lastName: this.copyrightForm.value.lastName,
      companyName: this.copyrightForm.value.companyName,
      email: this.copyrightForm.value.userEmail,
      countryId: this.selectedItem,
      url: this.copyrightForm.value.userURL,
      rightsOwner: this.copyrightForm.value.rightsOwner,
      postalAddress: this.copyrightForm.value.postalAddress,
    };

    this.uploadService.post(copyrightForm).subscribe(() => {
        this.copyrightForm.reset({
          userCountry: ''
        });
        this.uploadFiles = [];
        this.alertMessage = 'Form has been sent!';
        this.isShowToastr = true;
        doAsync(() => {
          this.isShowToastr = false;
        }, 3000);
      },
      (err) => {
        this.errorSubmit = true;
        this.alertMessage = err.error[0].message;
        this.isShowToastr = true;
        doAsync(() => {
          this.errorSubmit = false;
          this.isShowToastr = false;
        }, 3000);
      });
  }

  public changeSettingValue(country: any) {
    this.selectedItem = country;
  }
}
