<router-outlet></router-outlet>

<app-fly-toastr></app-fly-toastr>

<app-cookies-law-policy
  id="cookies-law-policy"
></app-cookies-law-policy>

<app-confirm-action
  *ngIf="showConfirmWindow"
  [newMessageId]="'labels.massages.marked_cities'"
  (agreed)="confirmCitiesSave($event)"
></app-confirm-action>
