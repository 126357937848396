import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownComponent } from './containers/drop-down/drop-down.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [DropDownComponent],
  exports: [DropDownComponent]
})
export class DropDownModule {
}
