import { Component, ElementRef, ViewChild } from '@angular/core';
import { MovementPopupService } from "../../../services/movement-popup.service";
import { Store } from "@ngrx/store";
import { getAjaxState, State } from "../../../store/reducers";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StaticService } from "../../../services/static.service";
import { UserCollectionService } from "../../../services/user-collection.service";
import { ToggleFavoritePanel, ToggleFollowingPanel, TogglePinnedPanel } from "../../../store/panels/panels.actions";
import { Observable } from "rxjs/internal/Observable";
import { delay } from "rxjs/operators";

import { PinnedModeIdx } from "../../../enums/pinned-mode.enum";

@UntilDestroy()
@Component({
  selector: 'app-pinned-panel-container',
  templateUrl: './pinned-panel-container.component.html',
  styleUrls: ['./pinned-panel-container.component.scss']
})
export class PinnedPanelContainerComponent {

  @ViewChild('pinnedPanelContainer') pinnedPanelContainer: ElementRef;

  public ajaxState$: Observable<any>;

  protected readonly PinnedModeIdx = PinnedModeIdx;

  constructor(
    protected store$: Store<State>,
    private movementPopupService: MovementPopupService,
    private userCollectionService: UserCollectionService,
    public staticService: StaticService,
  ) {
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
  }

  loadMovementPinPanelData() {
    this.movementPopupService.loadMovementPinPanelData(this.pinnedPanelContainer);
  }

  public togglePanel(isOpen: boolean): void {
    switch (true) {
      case this.staticService.isPinnedPlaceDisplay:
        this.togglePinnedPanel(isOpen);
        break;
      case this.staticService.isFavoritesPlaceDisplay:
        this.toggleFavoritePanel(isOpen);
        break;
      case this.staticService.isFollowingPanelVisible:
        this.toggleFollowingPanel(isOpen);
        break;
    }
    if (this.staticService.isMobileDesign()) {
      this.userCollectionService.isOpenPinPanelFromBottomSheet$.next(false);

    }
  }

  private togglePinnedPanel(isOpen: boolean): void {
    this.store$.dispatch(new TogglePinnedPanel({display: isOpen}));
  }

  private toggleFavoritePanel(isOpen: boolean): void {
    this.store$.dispatch(new ToggleFavoritePanel({display: isOpen}));
  }

  private toggleFollowingPanel(isOpen: boolean): void {
    this.store$.dispatch(new ToggleFollowingPanel({display: isOpen}));
  }
}
