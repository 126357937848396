<div class="empty__container" *ngIf="!cookiesPolicyAccepted" (click)="acceptCookiePolicy()"></div>
<section class="cookie__container" *ngIf="!cookiesPolicyAccepted">
  <div class="cookie__content">
    <span class="cookie__description">
      By using our site, you agree to cookies that improve your experience. You may also change your preferences anytime.
    </span>

    <div class="cookie__actions">
      <a class="cookie__info-btn" [appRouterLink]="'/cookie-policy'">
        Cookie Policy
      </a>

      <button class="cookie__accept-btn" (click)="acceptCookiePolicy()">
        Accept
      </button>
    </div>
  </div>
</section>
