<div class="progress-bar">
  <mat-progress-bar
    *ngIf="ajaxState$ | async"
    mode="indeterminate">
  </mat-progress-bar>
</div>

<header class="fly-text-c">
  <h1 *ngIf="showLoginForm">Log in</h1>
  <h1 *ngIf="!showLoginForm">Reset password</h1>
</header>

<div *ngIf="showLoginForm" class="alternate-theme">
  <div class="auth-form" [formGroup]="loginForm">

    <div class="validation-errors" *ngIf="generalError">
      <div *ngFor="let message of generalError">
        {{message.value}}
      </div>
    </div>

    <div class="form-group">
      <label [ngClass]="{'translate-bottom': !emailFocused}" class="pass-label" for="email" i18n="labels.email"
             innerText="'labels.email' | translate">
        e-mail </label>

      <span class="pass-v-icon" *ngIf="emailUpdated"
            [ngClass]="validationStyles('email')"></span>

      <input type="email"
             (blur)="valueUpdated('email')"
             (focus)="focus('email')"
             id="email"
             class="form-control"
             formControlName="email">
      <div *ngIf='resOfEmailValidation' class="validation-errors">{{resOfEmailValidation}}</div>
    </div>

    <div class="form-group">
      <label [ngClass]="{'translate-bottom': !passFocused}" class="pass-label" for="pass" i18n="labels.password"
             innerText="'labels.new_password' | translate">
        password </label>

      <span class="pass-v-icon" *ngIf="passUpdated" [ngClass]="validationStyles('pass')"></span>

      <input [attr.type]="passwordType"
             (blur)="valueUpdated('pass')"
             (focus)="focus('pass')"
             id='pass'
             class="form-control"
             formControlName="pass">
      <div *ngIf='resOfPasswordValidation' class="validation-errors">{{resOfPasswordValidation}}</div>
    </div>

    <div class="form-group remember-me">
      <mat-checkbox color="blue" (click)="rememberUser()" [checked]="true">
        Remember me
      </mat-checkbox>
      <div (click)="changePasswordView($event)">
        <input type="checkbox" id="showPass" name="rememberMe" value="true">
        <label for="showPass" [ngClass]="{'label-active': (passwordType === 'text')}"> {{ passwordType !== 'text' ? 'Show' : 'Hide' }} password </label>
      </div>
    </div>

    <div class="form-group">
      <button mat-raised-button type="submit" class="sign-in-button" i18n="labels.login" (click)="signInWithEmail(loginForm)"
              innerText="'labels.login' | translate">
        Login
      </button>
    </div>

    <div class="form-group remember-me" style="justify-content: center">
      <div class="create-account">
        <input class="create-account" type="checkbox" id="forgotPass" name="rememberMe" value="true"
               [attr.checked]='shouldRemember'>
        <label for="forgotPass" class="create-account" (click)="showRestorePasswordForm()"> Forgot password? </label>
      </div>
    </div>
  </div>

  <p class="policy">
    By signing up you agree to Explorow's
    <a [appRouterLink]="'/terms-of-use'" target="_blank">Terms of Service</a> <br/>
    and <a [appRouterLink]="'/privacy-policy'" target="_blank">Privacy Policy</a>
  </p>

  <app-social-net-btn *ngIf="auth" [auth]="auth"></app-social-net-btn>

</div>

<div *ngIf="!showLoginForm" class="alternate-theme">
  <app-reset-password-form
    (resPasswordSuccess)="resPasswordSuccess($event)"
  ></app-reset-password-form>
</div>

<div class="account-terms-service fly-text-c">
  <label> New to Explorow? </label>
  <div class="create-account" (click)="closeLoginForm($event)"> Create account</div>
</div>
