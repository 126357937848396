<div class="wrapper old-static-page">

  <app-static-header
    [data]="staticService.placesData"z
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="content">
    <div class="site-map-block">
      <h1>World Travel Map</h1>
      <a [routerLink]="'/'">Explorow</a>
    </div>

    <div class="site-map-block">
      <h1>Countries to Travel</h1>
      <ul class="list">
        <li *ngFor="let list of countryLists">
          <a href="/country/{{replaceLinkService.getLink(list, list.label)}}/{{list.id && list.id.toLowerCase()}}">
            {{list.name}}
          </a>
        </li>
      </ul>
    </div>

    <div class="site-map-block">
      <h1>Promotion Pages</h1>
      <a href="/help/travel-business-page">Register your travel business on Explorow</a>
    </div>

  </div>

  <app-footer></app-footer>
</div>
