<div class="empty-places" [class.visible]="isShowTooltip" (click)="toggleShowTooltip(false)"></div>
<div class="share-button-outer" title="Share" (click)="toggleShowTooltip(true)">
  <img class="share-button" src="assets/icons/share-out.svg" alt="share"/>
  <div class="share-tooltip" [class.visible]="isShowTooltip">
    <share-buttons *ngIf="ssrService.isBrowser()"
                   [theme]="'circles-dark'"
                   [include]="['whatsapp','x','reddit','facebook','telegram','copy']"
                   [show]="6"
    ></share-buttons>
  </div>
</div>
