<div class="schedule__container">
  <div class="schedule__row" *ngFor="let item of scheduleData; let i=index">
    <div class="schedule__day">
      <input
        [(ngModel)]="item.isWorkDay"
        (change)="onToggleCheckbox(item.isWorkDay, i)"
        type="checkbox"
        value="{{item.day}}"
        id="schedule-day-checkbox{{i}}">
      <label for="schedule-day-checkbox{{i}}">{{item.title}}</label>
    </div>
    <div class="schedule__time-container">
      <ng-container *ngFor="let time of item.scheduleTimes; let timeIndex=index">
        <div class="schedule__time-content">
          <div class="schedule__from-time">
            <label for="from-time">{{timeIndex === 0 ? 'from' : 'and'}}</label>
            <select name="time"
                    class="time-input"
                    id="from-time"
                    [disabled]="!item.isWorkDay"
                    [(ngModel)]="time.from">
              <option *ngFor="let hour of hours"
                      [disabled]="checkDisabledTimeInput(hour, item.scheduleTimes, timeIndex, timeTypeEnum.from)"
                      [value]="hour">{{hour}}</option>
            </select>
          </div>
          <div class="schedule__to-time">
            <label for="to-time">to</label>
            <select name="time"
                    class="time-input"
                    id="to-time"
                    [disabled]="!item.isWorkDay"
                    [(ngModel)]="time.to">
              <option *ngFor="let hour of hours"
                      [disabled]="checkDisabledTimeInput(hour, item.scheduleTimes, timeIndex, timeTypeEnum.to)"
                      [value]="hour">{{hour}}</option>
            </select>
          </div>
        </div>
      </ng-container>
    </div>
    <img src="/assets/arrow/arrow-keyboard-down.svg"
         alt="arrow"
         class="schedule__toggle-btn"
         [class.active]="item.isMoreTime"
         [class.disabled]="!item.isWorkDay"
         (click)="item.isWorkDay && toggleScheduleMode(i)">
  </div>
  <div class="actions__btn">
    <div class="action__btn-ok" (click)="onSave()">Save</div>
    <div class="action__btn-cancel" (click)="toggleContactForm()">Discard</div>
  </div>
</div>
