import { Component, Inject, Injector, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RESPONSE } from '../../../express.tokens';
import { Response } from 'express';
import { ActivatedRoute } from "@angular/router";
import { ErrorEnum } from "../../enums/error.enum";
import { SERVICE_UNAVAILABLE } from "../../../constants";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  public pageType: string;
  public title: string;
  public text: string;
  public imgSrc: string;
  public serviceUnavailable = SERVICE_UNAVAILABLE;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(Injector) private injector: Injector,
    private route: ActivatedRoute,
  ) {
    this.pageType = this.route.snapshot.data['type'];
  }

  ngOnInit() {
    if (this.pageType === this.serviceUnavailable) {
      this.text = ErrorEnum.Sorry;
      const code = this.route.snapshot.queryParams['code'] || 500;
      this.imgSrc = '/assets/icons/service-unavailable.png';
      if (Number(code) === 0) {
        this.title = ErrorEnum.InternetConnection;
      } else {
        this.title = ErrorEnum.Default;
      }
      if (!isPlatformBrowser(this.platformId)) {
        const response = this.injector.get(RESPONSE) as Response;
        response.status((code >= 400 && code < 600) || code === 0 ? code : 500);
      }
    } else {
      this.imgSrc = '/assets/icons/404-error.png';
      this.title = ErrorEnum.NotFound;
      if (!isPlatformBrowser(this.platformId)) {
        const response = this.injector.get(RESPONSE) as Response;
        response.status(404);
      }
    }
  }

}
