import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './containers/footer/footer.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CloseBtnComponent } from '../components/close-btn/close-btn.component';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatButtonModule } from "@angular/material/button";

const COMPONENTS = [ContactUsComponent, CloseBtnComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatButtonModule,
    RouterModule,
  ],
  declarations: [
    ...COMPONENTS,
    FooterComponent
  ],
  exports: [
    ...COMPONENTS,
    FooterComponent,
  ],
})
export class FooterModule {
}
