import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService implements OnDestroy {
  previousUrl$ = new BehaviorSubject<string>(null);
  currentUrl$ = new BehaviorSubject<string>(null);
  private $destroyed = new Subject<void>();

  constructor(router: Router) {
    this.currentUrl$.next(router.url);

    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.$destroyed)
      )
      .subscribe((event: NavigationEnd) => {
        this.previousUrl$.next(this.currentUrl$.value);
        this.currentUrl$.next(event.urlAfterRedirects);
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }
}
