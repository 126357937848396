<label *ngIf="text" class="help-text">{{text}}</label>

<mat-form-field subscriptSizing="dynamic">
  <mat-select (selectionChange)="selectCollection($event.value)"
              panelClass="collection-listbox"
              [(value)]="collectionId"
              placeholder="Select">
    <mat-option class="empty-option">None</mat-option>
    <mat-option class="fly-bolder" *ngFor="let collection of collections"
                [value]="collection.id">
      {{collection.name || defaultTitleCollection}}
    </mat-option>
    <mat-option class="custom-option" (click)="toggleCreateCollection()">
      <div class="collection-action">
        <div class="add-img"></div>
        <p>Create a new journey</p>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="alert alert-danger" *ngIf="required">
  Categories is required.
</div>

<ng-container *ngIf="isCreateCollection">
  <app-create-collection-modal [isProfilePage]="isProfilePage"
                               (emitCreateCollection)="createCollection($event)"
                               (emitCancelCreateCollection)="toggleCreateCollection()"></app-create-collection-modal>
</ng-container>
