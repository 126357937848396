<div class="old-static-page">

  <app-static-header
    [data]="staticService.placesData"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="terms-of-service-modal" [ngClass]="{'static-page':isStaticPage}">
    <div class="terms-of-service-container">
      <div class="terms-of-service-content fly-m-t-10px">
        <header>
          <h1>Privacy Policy</h1>
        </header>
        <section>
          <h2>Introduction</h2>
          <p>This privacy policy sets out our commitment to protecting the privacy of personal information provided to
            us, or otherwise collected by us, offline or online, including through our Websites, by you. In trusting us
            with your personal data, we commit to safeguarding your privacy and ensuring our privacy policy and
            procedures are in your best interest. </p>
          <p>In this policy, “we”, “us”, or “our” means Explorow, a company owned and operated by Explorow Travel Pty
            Ltd (“Explorow”). </p>
          <p>“You”, “your” and “user” refers to you, or your agent, representative or other person acting on your
            behalf. </p>
          <p>“Websites” collectively refer to all websites operated by across (as applicable) desktop, mobile, tablet
            and apps (including any subdomains). </p>
          <p>We operate an online service that provides users with travel information and guides, as well as referrals
            to Third-Party Suppliers of travel services. In this Policy, these are collectively referred to as our
            “Services”.</p>
          <p>This Policy describes how we collect, use, process, store, dispose of, protect and share your personal
            information. It also explains what your rights are, and how to contact us about your information. We have a
            separate Cookies statement that explains how Explorow uses cookies, and other similar tracking/retention
            technology. </p>

          <h2>Acknowledgements</h2>

          <p>By visiting the Websites and/or using our Services, you are taken to have read and agreed to the terms of
            this Privacy Policy. </p>

          <h2>Jurisdiction</h2>
          <p>Explorow complies with applicable Privacy Laws in relation to the collection and disclosure of information
            regarding individuals using and accessing this website. In this Privacy Policy, the term "Privacy Law" means
            any legislation or regulations that apply to Explorow. from time to time in force in Australia affecting
            privacy, or the collection, handling, storage, processing, use or disclosure of personal data.</p>
          <p>By providing your personal data to us, you confirm your explicit consent for us to collect and use your
            personal data. </p>

          <h2>Contact Us about your personal data</h2>

          <p>Your privacy is very important to us. If you have any questions about this policy or your privacy, or your
            information, in relation to the Websites, or if you wish to lodge a complaint about a breach of privacy, you
            may contact us via <a class="cursor-pointer" (click)="toggleContactUs(contactType)">form</a></p>

          <h2>What Personal Information we collect</h2>

          <p>The types of personal information we may collect about you include:</p>
          <ul>
            <li>your full name;</li>
            <li>your contact details, including your nominated city/town and country of residence, email address and
              telephone number;
            </li>
            <li>Information you choose to share with us, when you create a Profile on our website;</li>
            <li>information you provide to us through customer surveys;</li>
            <li>details of products and services we have provided to you and/or that you have enquired about, and our
              response to you;
            </li>
            <li>information about your access and use of our Site, including through the use of Internet cookies,
              your communications with our Site, the type of browser you are using, the type of operating system you are
              using and the domain name of your Internet service provider;
            </li>
            <li>additional personal information that you provide to us, directly or indirectly, through your use of
              our Site, associated applications, associated social media platforms and/or accounts from which you permit
              us to collect information; and
            </li>
            <li>any other personal information requested by us and/or provided by you or a third party.</li>
            <li>We also may collect information about your online activity on our website, such as pages viewed, and
              affiliate links you clicked on.
            </li>
          </ul>
          <p>• We also may collect information about your online activity on our website, such as pages viewed, and
            affiliate links you clicked on. </p>

          <h2>Why we collect the information we do</h2>

          <p>The purpose for the collection of your personal information is to provide you with the best experience
            possible on the Websites. Specifically, we may use your personal information to:</p>
          <ol>
            <li>Provide you with a great user experience on the Website;
            </li>
            <li>complete travel bookings you initiate on the Websites;
            </li>
            <li>provide you with the products and services you require;
            </li>
            <li>development of user generated Content;
            </li>
            <li>ensure that the Websites’ Content is well presented for you and for your computer;
            </li>
            <li>personalise and customise the services, experience, advertising and content available to you on the
              Websites;
            </li>
            <li>contact you to respond to your queries or assist you with any requests that you may have about any
              service, product or the Websites;
            </li>
            <li>contact you to conduct surveys, research and feedback about our products, services or the Websites;
            </li>
            <li>verify your identity or remind you of your password and username;
            </li>
            <li>help carry out our obligations arising from any agreements between you and us; and
            </li>
            <li>notify you about changes to our products and services.
            </li>
          </ol>

          <h2>How we collect your personal information</h2>

          <p>We collect your personal information when you :</p>
          <ol>
            <li>register to use the Websites through an account or profile;</li>
            <li>subscribe to receive promotions, filling in forms, applications, surveys or research, participating
              in promotions and competitions on the Websites;
            </li>
            <li>contact us for any reason;
            </li>
            <li>post user Content, by submitting any material on our Websites or social media pages;
            </li>
            <li>use our Services; and
            </li>
            <li>apply for an employment opportunity with us.
            </li>
          </ol>

          <h2>How we keep your personal information secure</h2>

          <p>We believe your personal information is important, and we respect that you have trusted us to share your
            information with us. Therefore, we have internal procedures in place to prevent unauthorised access to, and
            the misuse of, personal data.</p>
          <p>We have appropriate business systems and procedures to protect and safeguard the personal data you give us.
            These procedures are in line with the Office of the Australian Information Commission’s (the OAIC)
            Australian Privacy Principles (APP). We believe these privacy principles to be the most stringent, and
            protective in the world. </p>
          <p>Only authorised personnel are permitted to access personal data in the course of their work. We operate on
            a strictly Need-to-Know basis. Our employees, contractors, agents and service providers who provide services
            related to our information systems, are contractually obliged to respect the confidentiality of any personal
            information held by us and may only access your personal information with proper authorisation. We
            periodically review and update our security measures in light of current technologies. </p>
          <p>We will only keep your personal data for as long as is necessary to enable you to use our services or to
            provide our services to you (including maintaining your profile data), to comply with applicable laws,
            resolve any disputes and otherwise to allow us to conduct our business, including to detect and prevent
            fraud and/or other illegal activities. All personal data we keep about you as an Explorow customer is
            covered by this Privacy Policy. </p>
          <p>Just as we take the security of your data seriously, we strongly encourage you never to share your login
            details with anyone. </p>

          <h2>When we disclose or share your personal information</h2>

          <p>Generally, we will only disclose personal information to a third party (a) with your consent, (b) where it
            is deemed necessary for our Service to function effectively, eg; for a referral to an affiliate, and (c)
            where we are required to by law. We may disclose your personal information in the following
            circumstances:</p>
          <ol>
            <li>suppliers and third party vendors, such as hotel, airline, car rental, and activity providers that
              you are referred to;
            </li>
            <li>to any of our service providers, so that they can provide you with products or services on our
              behalf;
            </li>
            <li>where we are authorised or required by law to do so in relation to the IPR infringements or other
              activity that is illegal;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>our existing or potential agents or business partners;</li>
            <li>sponsors or promoters of any competition we run;</li>
            <li>anyone to whom our business or assets (or any part of them) are, or may (in good faith) be,
              transferred;
            </li>
            <li>credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay
              for goods or services we have provided to you;
            </li>
            <li>courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in
              connection with any actual or prospective legal proceedings;
            </li>
            <li>third parties, including agents or sub-contractors, who assist us in providing information,
              products, services or direct marketing to you. This may include parties located, or that store data,
              outside of Australia; and
            </li>
            <li>third parties to collect and process data, such as Google Analytics or other relevant businesses.
              This may include parties that store data outside of Australia.
            </li>
            <li>By providing us with personal information, you consent to the disclosure of your personal
              information to third parties who reside outside Australia and acknowledge that we are not required to
              ensure that those third parties comply with Australian privacy laws.
            </li>
          </ol>
          <p>We are not responsible for the way a third party collects, uses, discloses or handles personal information
            that you provide to them through their Websites. Please check directly with the third party
            organisation.</p>
          <p>We cannot guarantee the security of any information that is transmitted to or by us over the Internet. The
            transmission and exchange of information is carried out at your own risk. Although we take measures to
            safeguard against unauthorised disclosures of information, we cannot assure you that your personal
            information shared across the internet is not intercepted before it reaches us.</p>

          <h2>Cookies</h2>

          <p>Like many online businesses, we use Cookies. We encourage you to read our cookies policy in conjunction
            with this Privacy Policy. </p>

          <h2>Personal Data for Person’s under 18 years old</h2>

          <p>Explorow’s website audience is targeted towards the general population who are above 18 years old,
            therefore we do not offer services directed to minors (under 18 years old). Our Terms of Service stipulate
            that users warrant they are over the age of 18 before using the Site. </p>
          <p>If we have obtained the data of any person under the age of 18, we have done so without knowing or
            intending to. We do not knowingly collect data relating to minors. If we come to know that a person under 18
            years of age has sent us personal data, we will delete or destroy this information as soon as reasonably
            possible.</p>

          <h2> Your rights and controlling your personal information</h2>

          <p>We respect your rights in controlling how we use, collect, store and share your information. You can choose
            not to provide all requested information to us, but in general some information about you is required in
            order for you to register as a member; create a profile on our website; participate in a survey, contest, or
            sweepstakes; ask us a question; or initiate other transactions on our site. If you do not provide all or
            part of the requested information, certain activities on the website may not fully function. </p>
          <p>Below we detail how you can control your data that we collect. </p>
          <p><b>Choice and consent:</b> Please read this Privacy Policy carefully. By providing personal information to
            us, you consent to us collecting, holding, using and disclosing your personal information in accordance with
            this Privacy Policy. You do not have to provide personal information to us, however, if you do not, it may
            affect your use of this Site or the products and/or services offered on or through it. </p>
          <p><b>Information from third parties:</b> If we receive personal information about you
            from a third party, we will protect it as set out in this Privacy Policy. If you are a third party providing
            personal information about somebody else, you represent and warrant that you have such person’s consent to
            provide the personal information to us. </p>
          <p><b>Restrict:</b> You may choose to restrict the collection or use of your personal information. If you have
            previously agreed to us using your personal information for direct marketing purposes, you may change your
            mind at any time by contacting us using the details below.</p>
          <p><b>Access: </b> You may request details of the personal information that we hold about you. An
            administrative fee may be payable for the provision of such information. In certain circumstances, as set
            out in the Privacy Act 1988 (Cth), we may refuse to provide you with personal information that we hold about
            you.</p>
          <p><b>Correction:</b> If you believe that any information we hold about you is inaccurate, out of
            date, incomplete, irrelevant or misleading, please contact us using the details below. We will take
            reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.
          </p>
          <p><b>Complaints:</b> We take all complaints and concerns seriously. If you believe that we have
            breached the Australian Privacy Principles and wish to make a complaint, we want you to get in touch with us
            as soon as possible and provide us an opportunity to resolve your complaint. Please contact us using the
            details below and provide us with full details of your concern. We will promptly investigate your complaint
            and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to
            deal with your complaint.
          </p>
          <p><b>Unsubscribe: </b> To unsubscribe from our e-mail database or opt-out of communications
            (including marketing communications), please contact us using the details below or opt-out using the opt-out
            facilities provided in the communication.
          </p>

          <h2>General Data Protection Regulation Privacy Statement (“GDPR Statement”)</h2>

          <p>This GDPR Statement applies to persons in the European Economic Area (“EEA”), including those based in the
            United Kingdom. This GDPR Statement supplements our Policy; however, where the Policy conflicts with the
            GDPR Statement, the GDPR Statement will prevail as to persons in the EEA. </p>

          <h3>Your rights under GDPR</h3>

          <p>You have certain rights regarding your personal data.</p>
          <p>Your rights with respect to your own personal data include the following:</p>
          <ul>
            <li>The right to request access to your personal data. This enables you to receive a copy of the personal
              data we hold about you.
            </li>
            <li>The right to request to correct your personal data if it is inaccurate. You may also supplement any
              incomplete personal data we have, taking into account the purposes of the processing.
            </li>
            <li>The right to request deletion of your personal data if:
              <ul>
                <li>your personal data is no longer necessary for the purposes for which we collected or processed
                  them; or
                </li>
                <li>you withdraw your consent if the processing of your personal data is based on consent and no other
                  legal ground exists; or
                </li>
                <li>you object to the processing of your personal data and we do not have an overriding legitimate
                  ground for processing; or
                </li>
                <li>your personal data is unlawfully processed; or</li>
                <li>your personal data must be deleted for compliance with a legal obligation.</li>
              </ul>
            </li>
            <li>The right to object to the processing of your personal data. We will comply with your request, unless
              we have a compelling overriding legitimate interest for processing or we need to continue processing your
              personal data to establish, exercise or defend a legal claim.
            </li>
            <li>The right to restrict the processing of personal data, if:
              <ul>
                <li>the accuracy of your personal data is contested by you, for the period in which we have to verify
                  the accuracy of the personal data; or
                </li>
                <li>the processing is unlawful and you oppose the deletion of your personal data and request
                  restriction; or
                </li>
                <li>we no longer need your personal data for the purposes of processing, but your personal data is
                  required by you for legal claims; or
                </li>
                <li>you have objected to the processing, for the period in which we have to verify overriding
                  legitimate grounds.
                </li>
              </ul>
            </li>
            <li>The right to data portability. You may request that we send this personal data to a third-party, where
              feasible. You only have this right if it relates to personal data you have provided to us where the
              processing is based on consent or necessity for the performance of a contract between you and us, and the
              processing is conducted by automated means.
            </li>
            <li>You also have the right to lodge a complaint before your national data protection authority.
            </li>
          </ul>
          <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights
            described in this Statement). However, we may charge a reasonable fee if your request is clearly unfounded,
            repetitive or excessive.</p>
          <p>We may need to request specific information from you to help us confirm your identity and ensure your right
            to access your personal data (or to exercise any of your other rights). This is a security measure to ensure
            that personal data is not disclosed to any person who has no right to receive it. In an effort to speed up
            our response, we may also contact you to ask you for further information in relation to your request. You
            can exercise several of your rights through the personal information section of your account. To exercise
            your other rights you can contact us directly.</p>

          <h3>Information Uses</h3>

          <p>We will only use your personal data when the law allows us to.</p>
          <p>Pursuant to GDPR, we will use your personal data in one or more of the following circumstances:</p>
          <ul>
            <li>Where we need to perform the contract, we are about to enter into or have entered into with you</li>
            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and
              fundamental rights do not override those interests
            </li>
            <li>Where we need to comply with a legal or regulatory obligation</li>
            <li>With your consent</li>
          </ul>

          <h3>International Transfers</h3>
          <p>Your personal data may be stored or transferred to countries outside the EEA for the purposes described in
            this Statement. When we store or transfer your personal data outside the EEA, we take the following
            precautions to ensure that your personal data is properly protected.</p>
          <p>Whenever we store or transfer your personal data outside the EEA, we will do so in accordance with
            applicable law and we will ensure a similar degree of protection is afforded to it by implementing
            appropriate safeguards. Transfers of personal data are made:</p>
          <ul>
            <li>to a country recognised by the European Commission as providing an adequate level of protection; or
            </li>
            <li>to a country which does not offer adequate protection but whose transfer has been governed by the
              standard contractual clauses of the European Commission or by implementing other appropriate cross-border
              transfer solutions to provide adequate protection.
            </li>
          </ul>
          <p>By using our services, you understand that your personal data may be transferred to our facilities and
            those third parties with whom we share it as described in this Statement.</p>

          <h2>California Consumer Privacy Act Privacy Statement (“CCPA Statement”)</h2>
          <p>This CCPA Statement is provided pursuant to the California Consumer Privacy Act (“CCPA”) and applies to
            California residents and supplements our overall Policy with additional disclosures and rights.</p>

          <h3>Your rights under CCPA</h3>

          <p>As of 1st January 2020, California law permits residents of California to request certain details about how
            their personal information is shared with third parties or affiliated companies for direct marketing
            purposes.</p>

          <p>California residents may also take advantage of the following rights:</p>

          <ul>
            <li>You may request, up to two times each year, that we disclose to you the categories and specific pieces
              of personal information that we have collected about you, the categories of sources from which your
              personal information is collected, the business or commercial purpose for collecting your personal
              information, the categories of personal information that we have disclosed for a business purpose, any
              categories of personal information about you that we have sold, the categories of third parties with whom
              we have shared your personal information and the business or commercial purpose for selling your personal
              information, if applicable.
            </li>
            <li>You may request that we delete any personal information that we have collected from or about you. As
              described in more detail in our Statement, there are some reasons for which we will not be able to fully
              address your deletion request, such as if we need to complete a transaction for you, to detect and protect
              against fraudulent and illegal activity, to exercise our rights or to comply with a legal obligation. You
              can also access, update, and remove your information by visiting the Member Profile page on our website.
            </li>
            <li>You may request to opt out of our sale of your personal information to third parties for their direct
              marketing purposes. This means that, if you opt out, going forward, we will not share your information
              with such third parties to use for their purposes unless you later direct us to do so. To effect the opt
              out, please click on the Do Not Sell My Info link on our website footer or submit a request in writing to
              the contact information below.
            </li>
          </ul>
          <p>To take advantage of any of these rights, please contact us. We may need to verify your identity to enable
            us to process your request. We value your privacy and will not discriminate in response to your exercise of
            privacy rights. We will respond to your request within 45 days of receipt of your request, after proper
            verification, unless we need additional time, in which case we will let you know.</p>
          <h2>Amendments</h2>
          <p>We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy
            Policy on our Site. We recommend you check our Site regularly to ensure you are aware of our current Privacy
            Policy.</p>
          <p>If the proposed variation would result in a material change to your rights or obligations, we will let you
            know by placing a prominent notice on our Website.</p>
        </section>

      </div>

    </div>
  </div>
  <app-footer></app-footer>
</div>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactUsOpened"
                (closeContactUs)="toggleContactUs()"></app-contact-us>
