<div class="modal">
  <div class="container">
    <div class="close-button">
      <app-close-btn (click)="close()"></app-close-btn>
    </div>
    <div class="data-container">
      <div>
        <div class="title">{{title}}</div>
        <div class="text">{{text}}</div>
      </div>
      <button class="btn" mat-flat-button color="primary" (click)="close()">Continue</button>
    </div>
  </div>
</div>
