import { Action } from '@ngrx/store';

import { LatLng } from '../../interfaces';
import { UserSetting } from '../../interfaces/user';

export enum AuthActionsTypes {
  CheckGeoPosition = '[AUTH] Check geo position',
  CheckGeoPositionSuccess = '[AUTH] Check geo position success',
  CheckGeoPositionFailure = '[AUTH] Check geo position failure',
  SetAuthData = '[Auth] Set authData',
  SetSessionData = '[Auth] Set session data',
  SetSessionDataUserSettings = '[Auth] Set session data user settings',
  SignOut = '[Auth] Sign out',
  SetCurrentCoordinate = '[Auth] Set current coordinates',
  RestoreCurrentCoordinate = '[Auth] Restore current coordinates',
  RestoreCurrentHome = '[Auth] Restore current home',
  GetBrowserGeoPosition = '[Auth] Get browser geo position',
  LoadUserProfile = '[Auth] Load user profile data'
}

export class SetSessionData implements Action {
  readonly type = AuthActionsTypes.SetSessionData;

  constructor(
    public payload: {
      home: {
        id: number;
        name: string;
        user: any;
        coordinates: string;
        population: number;
        popular?: any;
        countryCode: string;
        iataCode: string;
        country?: any;
      };
      social: { name: string; url: string }[];
      loggedIn: boolean;
    }
  ) {
  }
}

export class SetSessionDataUserSettings implements Action {
  readonly type = AuthActionsTypes.SetSessionDataUserSettings;

  constructor(public payload: UserSetting[]) {
  }
}

export class SetAuthData implements Action {
  readonly type = AuthActionsTypes.SetAuthData;

  constructor(public payload: { token: string, loggedIn: boolean }) {
  }
}

export class SignOut implements Action {
  readonly type = AuthActionsTypes.SignOut;
}

export class SetCurrentCoordinates implements Action {
  readonly type = AuthActionsTypes.SetCurrentCoordinate;

  constructor(public payload: LatLng) {
  }
}

export class RestoreCurrentCoordinates implements Action {
  readonly type = AuthActionsTypes.RestoreCurrentCoordinate;

  constructor(public payload: LatLng) {
  }
}

export class RestoreCurrentHome implements Action {
  readonly type = AuthActionsTypes.RestoreCurrentHome;

  constructor(
    public payload: {
      id: number;
      name: string;
      country?: any;
      coordinates: string;
      population: number;
      popular?: any;
      user?: any
    }
  ) {
  }
}

export class GetBrowserGeoPosition implements Action {
  readonly type = AuthActionsTypes.GetBrowserGeoPosition;
}

export class CheckGeoPosition implements Action {
  readonly type = AuthActionsTypes.CheckGeoPosition;
}

export class CheckGeoPositionSuccess implements Action {
  readonly type = AuthActionsTypes.CheckGeoPositionSuccess;

  constructor(public payload: {
    social: any;
    home: {
      id: number;
      name: string;
      population: any;
      popular?: any;
      coordinates: string;
      iataCode: string;
    },
    loggedIn: boolean;
  }) {
  }
}

export class CheckGeoPositionFailure implements Action {
  readonly type = AuthActionsTypes.CheckGeoPositionFailure;

  constructor(public payload: { error: Error }) {
  }
}

export class LoadUserProfile implements Action {
  readonly type = AuthActionsTypes.LoadUserProfile;

  constructor() {
  }
}

export type AuthActionsUnion =
  | SignOut
  | SetCurrentCoordinates
  | RestoreCurrentCoordinates
  | RestoreCurrentHome
  | SetAuthData
  | SetSessionData
  | SetSessionDataUserSettings
  | CheckGeoPosition
  | CheckGeoPositionSuccess
  | CheckGeoPositionFailure
  | LoadUserProfile;

export const AuthActions = {
  SetAuthData,
  SignOut,
  SetCurrentCoordinates,
  RestoreCurrentCoordinates,
  RestoreCurrentHome,
  GetBrowserGeoPosition,
  SetSessionData,
  SetSessionDataUserSettings,
  LoadUserProfile,
};
