import { Injectable, } from '@angular/core';
import { FlyCookieService } from './fly-cookie.service';
import { HelperService } from "../helper.service";

@Injectable()
export class FlyCookieUpdateDataService {
  private _cookieName: string;

  set cookieName(value: string) {
    this._cookieName = value;
  }

  constructor(
    private cookieService: FlyCookieService,
    private helperService: HelperService,
  ) {
  }

  updateDataCookie(key: string, value: any) {
    let data: any;
    data = (this.cookieService.check(this._cookieName)
      && this.helperService.isJsonString(this.cookieService.get(this._cookieName)))
      ? JSON.parse(this.cookieService.get(this._cookieName))
      : {};
    data[key] = value;

    this.cookieService.set(this._cookieName, JSON.stringify(data), 0.25, '/');
  }
}
