<div class="social-link" *ngIf="socialGoogle || logOutGoogle">
  <h3>Google</h3>
  <button class="btn" (click)="unlinkGoogle()">
    Disconnect
  </button>
</div>

<div class="social-link" *ngIf="socialFB || logOutFB">
  <h3>Facebook</h3>
  <button class="btn" (click)="unlinkFB()">
    Disconnect
  </button>
</div>
