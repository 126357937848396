<label *ngIf="text" class="help-text">{{text}}</label>

<mat-form-field subscriptSizing="dynamic">
  <mat-chip-grid #chipGrid aria-label="Categories selection">
    <mat-chip-row
      *ngFor="let category of existingTags"
      [removable]="removable"
      (removed)="removeTag(category)">
      {{tagPrefix}}{{ category.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>

    <input class="form-control hide-placeholder-focus"
           autocorrect="off"
           autocomplete="off"
           autocapitalize="off"
           #hashtagInput
           type="text"
           [value]="inputValue"
           (paste)="pasteData($event)"
           (input)="inputData($event)"
           [matAutocomplete]="autoGroup"
           (blur)="checkCategories(true)"
           [matChipInputFor]="chipGrid">

    <mat-label class="placeholder" *ngIf="!(hashtagInput.value || existingTags?.length)">
      #beach #sunrise #adventure
    </mat-label>
  </mat-chip-grid>

  <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="selectCategories($event)">
    <mat-option *ngFor="let category of tags " [value]="category">
      <span>{{tagPrefix + category.name }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
