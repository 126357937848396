import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FlyCookieService, SsrService, UserService, usertoken } from '../../../../services';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { getAjaxState, getHomeData, State } from '../../../../store/reducers';
import { animate, state, style, transition, trigger, } from '@angular/animations';
import { delay, takeUntil } from 'rxjs/operators';
import {
  SignInButtonsComponent
} from '../../../../shared-components/fly-search-panel/sign-in-buttons/sign-in-buttons.component';
import { StaticRouteType } from '../../enums/route-type';
import { Subject } from 'rxjs';
import { UserCollectionService } from '../../../../services/user-collection.service';
import { HelperService, maxTabletWidth } from "../../../../services/helper.service";
import { UserLikesService } from "../../../../services/user-likes.service";
import { StaticService } from "../../../../services/static.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { constructBaseUrl } from "../../../../libraries";

import { GuideElementsEnum } from "../../../../enums/guide-elements.enum";
import { MobilePanelAction } from "../../../../enums/mobile-panel-action.enum";
import { PanelType } from "../../../../enums/panel-type.enum";

@UntilDestroy()
@Component({
  selector: 'app-static-header',
  templateUrl: './static-header.component.html',
  styleUrls: ['./static-header.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({opacity: 0})),
      transition(':enter', [animate(300)]),
      transition(':leave', [animate(500)]),
    ]),
  ],
})
export class StaticHeaderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: any;
  @Input() place: any;
  @Input() isOpenMap: boolean;

  @ViewChild('signInButtons') signInComponent: SignInButtonsComponent;

  @Output() openUserAccountEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() mobilePanelAction: EventEmitter<MobilePanelAction> = new EventEmitter<MobilePanelAction>();

  @HostListener('window:scroll')
  onScroll() {
    this.scrollOffset = this.staticService.window.pageYOffset;
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobileOrTablet = this.staticService.isTabletDesign();
  }

  public ajaxState$: Observable<boolean>;
  public environment: any;
  public homeData: any;
  public isLikeAnimation: boolean;
  public isPinnedAnimation: boolean;
  public isMobileMap: boolean = false;
  public isFavouritePopupVisible: boolean;
  public isMobileOrTablet: boolean = false;
  public month: any;
  public popupDataFavourite = [
    {
      icon: 'savedlist.svg',
      title: 'Liked List',
      action: 'toggleFavoritePlaces',
      fullImg: true,
    },
    {
      icon: 'notifications.svg',
      title: 'Following',
      action: 'toggleFollowing',
      fullImg: true,
    },
  ];
  public scrollOffset = 0;
  public searchMode = true;
  public showConfirmWindow = false;
  public staticRouteType = StaticRouteType;
  public userIsLogged: any = false;

  protected isUserDomain: boolean;
  protected readonly journeyType: GuideElementsEnum = GuideElementsEnum.Journeys;
  protected isGuideVisible: boolean;
  protected readonly constructBaseUrl = constructBaseUrl;

  private $destroyed = new Subject<void>();

  constructor(
    private cookieService: FlyCookieService,
    private userService: UserService,
    private store$: Store<State>,
    public helperService: HelperService,
    public userLikesService: UserLikesService,
    private userCollectionService: UserCollectionService,
    public staticService: StaticService,
    protected cdRef: ChangeDetectorRef,
    private ssrService: SsrService,
  ) {
    this.environment = environment;
    this.isMobileOrTablet = staticService.isMobileOrTablet();
    this.isUserDomain = ssrService.getIsUserDomain();
  }

  ngOnInit() {
    if (this.staticService.resizeWindow >= maxTabletWidth) {
      this.staticService.isOpenMap.next(false);
      this.scrollOffset = 0;
    }

    this.checkMapWidth();
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
    if (this.ssrService.isBrowser()) {
      this.userIsLogged = !!this.cookieService.get(usertoken);
    }
    this.checkCitiesInCookies();
    this.userService.isUserAuth
      .pipe(takeUntil(this.$destroyed))
      .subscribe(value => {
        this.userIsLogged = value;

        if (this.userIsLogged) {
          this.checkCitiesInCookies();
        }
      });

    this.userLikesService.isLikeAnimation$.pipe(
      takeUntil(this.$destroyed))
      .subscribe(() => {
        this.isLikeAnimation = true;
        setTimeout(() => this.isLikeAnimation = false, 1000);
      });

    this.userCollectionService.isPinnedAnimation$.pipe(
      takeUntil(this.$destroyed))
      .subscribe(() => {
        this.isPinnedAnimation = true;
        setTimeout(() => this.isPinnedAnimation = false, 1000);
      });

    this.store$.select(getHomeData)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(homeData => {
        this.homeData = homeData;
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['place'] && changes['place'].currentValue) {
      this.place = changes['place'].currentValue;
    }

    if (changes['windowSize']?.currentValue) {
      this.staticService.resizeWindow = changes['windowSize'].currentValue;
    }
  }

  public closeFavouritePanels(): void {
    this.staticService.togglePanel(PanelType.Favourite, false);
    this.staticService.togglePanel(PanelType.Pinned, false);
    this.staticService.togglePanel(PanelType.Following, false);
  }

  public checkCitiesInCookies() {
    if (this.userIsLogged) {
      this.userCollectionService.checkConfirmWindow().subscribe(x => this.showConfirmWindow = x);
    }
  }

  public toggleFavoritePlaces(): void {
    if (this.staticService.staticType === StaticRouteType.Account) {
      this.staticService.close();
    }
    this.staticService.isRememberCollection = true;
    this.staticService.isFavoritesPlaceDisplay = !this.staticService.isFavoritesPlaceDisplay;
    this.staticService.togglePanel(PanelType.Favourite, this.staticService.isFavoritesPlaceDisplay);
  }

  public toggleFavouritePopup(value: boolean = null) {
    this.isFavouritePopupVisible = value !== null ? value : !this.isFavouritePopupVisible;
  }

  public toggleFollowing(): void {
    if (this.staticService.staticType === StaticRouteType.Account) {
      this.staticService.close();
    }
    this.staticService.isFollowingPanelVisible = !this.staticService.isFollowingPanelVisible;
    this.staticService.togglePanel(PanelType.Following, this.staticService.isFollowingPanelVisible);
  }

  public togglePinnedPlaces(): void {
    if (this.staticService.staticType === StaticRouteType.Account) {
      this.staticService.close();
    }
    this.staticService.isRememberCollection = true;
    this.staticService.isPinnedPlaceDisplay = !this.staticService.isPinnedPlaceDisplay;
    this.staticService.togglePanel(PanelType.Pinned, this.staticService.isPinnedPlaceDisplay);
  }

  public toggleRegisterForm(val: boolean = false): void {
    this.signInComponent.toggleRegisterForm(val);
  }

  openUserAccount(event) {
    this.openUserAccountEmitter.emit(event);
  }

  public cityMonthChange(config): void {
    if (config && config.city && config.month) {
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      }
    }
  }

  public clearFilter() {
    this.staticService.clearFilter();
  }

  public changeHeadPinnedPlaceState(): void {
    this.staticService.togglePanel(PanelType.Pinned, true);
  }

  protected emitAction(funcName) {
    if (funcName) {
      if (funcName.value !== null && funcName.pageType !== null) {
        this[funcName.action](funcName.value, funcName.pageType);
      } else if (funcName.value !== null && funcName.pageType === null) {
        this[funcName.action](funcName.value);
      } else {
        this[funcName.action]();
      }
    }
  }

  protected changeGuideState(isGuideVisible: boolean): void {
    this.isGuideVisible = isGuideVisible;
  }

  private checkMapWidth() {
    this.isMobileMap = this.staticService.isMobileDesign() || this.staticService.isTabletDesign();
  }
}
