<button mat-raised-button
        class="btn {{ size }}"
        (click)="openFlightsFrame()"
        [disabled]="
    !placeInfo.isPlace && +cityFrom?.id === +placeInfo?.id ||
    placeInfo.isPlace && +cityFrom?.id === +placeInfo?.city.id
  ">
  <div class="btn-container">
    <div class="btn-icon">
      <mat-icon class="plane-icon-tickets" aria-hidden="false" aria-label="Example home icon"
                svgIcon="plane-icon-tickets"></mat-icon>
    </div>
    <div class="btn-info">
      <span>
        <span *ngIf="minTicketPrice">
          <span>  {{'buttons.flights_min_price' | translate}}</span>
          {{minTicketPrice | currency:'USD':'symbol':'2.0'}}
        </span>
        <span *ngIf="!minTicketPrice">
            <span *ngIf="placeInfo.isPlace">{{'buttons.flights_to' | translate}} {{' ' + placeInfo.city.name}}</span>
          <span *ngIf="!placeInfo.isPlace">{{'buttons.flights_to' | translate}} {{' ' + placeInfo.name}}</span>
        </span>
      </span>
    </div>
    <div class="btn-icon">
      <mat-icon aria-hidden="false" aria-label="Example home icon">chevron_right</mat-icon>
    </div>
  </div>
</button>
