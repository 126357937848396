import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { switchMap, take, takeUntil } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { select, Store } from "@ngrx/store";
import { getFollowedList, loggedIn, State } from "../../../../../store/reducers";
import { of } from "rxjs/internal/observable/of";
import { Subject } from "rxjs/internal/Subject";
import { SetSearchPanelState } from "../../../../../store/layer";
import { UserService } from "../../../../../services";
import { UserFollowingActions } from "../../../../../store/user-following/user-following.actions";
import { UserPage } from "../../../../../interfaces/user";
import { UserFollowingService } from "../../../../../services/user-following.service";
import { FollowedItem } from "../../../../../interfaces/following";
import { AuthModalService } from "../../../../../services/auth-modal.service";

@Component({
  selector: 'app-static-user-personal-info-follow',
  templateUrl: './static-user-personal-info-follow.component.html',
  styleUrls: ['./static-user-personal-info-follow.component.scss']
})
export class StaticUserPersonalInfoFollowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() user: UserPage;
  @Output() toggleFollowEmitter = new EventEmitter();

  public isFollowed$: Observable<Boolean>;
  public isFollowed: Boolean = false;
  public isUserLogged: Boolean;

  protected $destroyed = new Subject<void>();

  constructor(
    protected store$: Store<State>,
    protected userService: UserService,
    protected userFollowingService: UserFollowingService,
    private authModalService: AuthModalService,
  ) {
  }

  ngOnInit(): void {
    this.init();
    this.store$.pipe(
      select(loggedIn),
      take(1)
    ).subscribe(() => {
      this.isUserLogged = this.userService.loggedIn();
    });
    this.isFollowed$.pipe(
      takeUntil(this.$destroyed)
    )
      .subscribe(isFollowed => {
        this.isFollowed = isFollowed;
      });
    this.authModalService.isLoggedUser$
      .pipe(takeUntil(this.$destroyed))
      .subscribe(value => {
        this.isUserLogged = value;
      });
  }

  ngOnChanges(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private init(): void {
    this.isFollowed$ = this.store$.pipe(
      select(getFollowedList),
      switchMap(data => {
        let val = false;
        if (this.user) {
          data?.items?.forEach((item: FollowedItem) => {
            if (item.user.id === this.user.id) {
              val = true;
            }
          });
        }
        return of(val);
      })
    );
  }

  toggleFollow() {
    if (!this.isUserLogged) {
      this.authModalService.open();
      return;
    }

    if (this.userFollowingService.isFollowerProcessing) {
      return;
    }
    const nextState = !this.isFollowed;
    this.store$.dispatch(new SetSearchPanelState(false));

    if (nextState) {
      this.store$.dispatch(new UserFollowingActions.Follow({user: this.user}));
    } else {
      this.store$.dispatch(new UserFollowingActions.Unfollow(this.user.id));
    }
    this.toggleFollowEmitter.emit(nextState);
  }
}
