const envConfig = (typeof window !== 'undefined' ? window : global as any)['envConfig'] || {};

export const environment = {
  production: true,
  baseUrl: envConfig['baseUrl'] || '',
  apiUrl: envConfig['apiUrl'] || 'http://localhost:8080/api',
  flightsUrl: envConfig['flightsUrl'] || 'https://flights.flytosomewhere.com/flights',
  gaTrackingId: envConfig['gaTrackingId'] || null,
  gaTrackingIdBusinessLand: envConfig['gaTrackingIdBusinessLand'] || null,
  googleKey: envConfig['googleKey'] || 'AIzaSyAAcdRKwxWkvvwLyz4FR9grU8A9viBCYaM',
  mediaUrl: envConfig['mediaUrl'] || '',
  uploadUrl: envConfig['uploadUrl'] || '',
  nodeSentryUrl: envConfig['nodeSentryUrl'] || null,
  sentryEnvironment: envConfig['sentryEnvironment'] || null,
  sentryUrl: envConfig['sentryUrl'] || null,
  SSRApiUrl: envConfig['SSRApiUrl'] || 'http://localhost:8080/api',
  tmApiKey: 'nPDYkZeSMDFCzKWziNMF2aU7kDwFJVNG',
  version: envConfig['version'] || ''
};
