import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { StaticRouteType } from "../../enums/route-type";
import { Store } from "@ngrx/store";
import { State } from "../../../../store/reducers";
import { InfoService, MapService, SsrService, UserService, WindowRef } from "../../../../services";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { PlaceNew } from "../../../../interfaces";
import { StaticMapComponent } from "../static-map/static-map.component";
import { StaticHeaderComponent } from "../static-header/static-header.component";
import { SetCityTo, } from "../../../../store/layer";
import { StaticService } from "../../../../services/static.service";
import { SeeDoMapClass } from "../../../../abstracts/right-side-map-class";
import { PageScrollService } from "ngx-page-scroll-core";
import { DOCUMENT } from "@angular/common";
import { StaticContentService } from "../../../../services/static-content.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { HelperService } from "../../../../services/helper.service";
import { TogglePinnedPanel } from "../../../../store/panels/panels.actions";

@UntilDestroy()
@Component({
  selector: 'app-static-content',
  templateUrl: './static-content.component.html',
  styleUrls: [
    '../../static.component.scss',
    './static-content.component.scss']
})
export class StaticContentComponent extends SeeDoMapClass implements OnInit, OnDestroy {
  @ViewChild(StaticMapComponent, {static: false}) staticMap: StaticMapComponent;
  @ViewChild(StaticHeaderComponent, {static: true}) staticHeader: StaticHeaderComponent;

  @HostListener('window:scroll')
  onScroll() {
    this.scrollOffset = this.staticService.window.pageYOffset;
  }

  public scrollOffset = 0;
  public dynamicCountOfColumns = 2;
  public staticRouteType = StaticRouteType;
  public searchMode: any = {map: true};
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public throttle = 300;
  public isOpenMap: boolean;
  public userIsLogged: boolean;

  constructor(
    public staticService: StaticService,
    protected cdRef: ChangeDetectorRef,
    protected store$: Store<State>,
    protected infoService: InfoService,
    protected mapService: MapService,
    protected pageScrollService: PageScrollService,
    protected windowRef: WindowRef,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ssrService: SsrService,
    @Inject(DOCUMENT) protected document: any,
    private zone: NgZone,
    protected helperService: HelperService,
    public staticContentService: StaticContentService,
  ) {
    super(
      infoService,
      mapService,
      cdRef,
      store$,
      pageScrollService,
      document,
      windowRef,
      staticService,
      helperService
    );
    this.userIsLogged = this.userService.loggedIn();

    router.events
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.store$.dispatch(new TogglePinnedPanel({display: null}));
        }
      });
  }

  ngOnInit(): void {
    if (this.staticService.isMobileDesign()) {
      this.staticService.withFollowLine = this.staticService.staticType === this.staticRouteType.Journey;
    }

    this.staticService.isOpenMap
      .pipe(untilDestroyed(this))
      .subscribe(x => {
        this.isOpenMap = x
      });

    if (this.staticService.resizeWindow >= 1024) {
      this.staticService.isOpenMap.next(false);
      this.scrollOffset = 0;
    }

    if (this.staticService.isMobileOrTablet() || this.staticService.resizeWindow > 1365) {
      this.dynamicCountOfColumns = 2;
    } else {
      this.dynamicCountOfColumns = 1;
    }

    this.staticService.isLoading = true;
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.staticService.currentThirdRequestCount = 0;
        this.staticService.setStaticType(this.route.snapshot.data['static']);

        this.staticService.window?.scrollTo(0, 0);

        const showMap = this.staticService.isOpenMap.getValue()
          && this.staticService.staticType === StaticRouteType.Search
          && this.staticService.isMobileOrTablet();
        this.staticService.isOpenMap.next(showMap);
        this.staticService.isPlaceInfo = false;
        this.staticService.routeParams = params;
        this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
        this.initZoom();

        if (this.ssrService.isBrowser()) {
          localStorage.setItem('staticRoute', this.router.url);
        }
        this.clearInitSearchData();
      });

  }

  ngOnDestroy() {
    this.staticService.showPlace = null;
    this.store$.dispatch(new TogglePinnedPanel({display: null}));
  }

  public onPinItem(item: any): void {
    this.staticService.toggleBottomSheet(true, item.pinned);
  }

  public changeVisibleTooltipPlace(place: PlaceNew): void {
    if (this.staticMap && this.staticMap.openingPlace) {
      this.staticMap.openingPlace = null;
    }

    this.staticService.visibleTooltipPlace = null;

    if (this.staticService.isMobileOrTablet()) {
      this.staticService.isOpenMap.next(true);
    }

    this.zone.run(() => {
      super.changeVisibleTooltipPlace(place);
    });
  }

  public clearInitSearchData(): void {
    if (this.staticService.staticType !== StaticRouteType.Search) {
      this.store$.dispatch(new SetCityTo(null));
    }
  }

  public onLoadCountryPlaces(): void {
    switch (this.staticService.staticType) {
      case StaticRouteType.Country:
        if (this.staticService.pageCount === 0) {
          this.staticService.getSubSimilarPlaces();
        } else if (this.staticService.smallCountryData) {
          this.staticService.getSimilarPlacesRequest();
        }
        break;
    }
  }

  public isEmptyPlacesVisible() {
    return this.staticService.isMainInfoLoaded && !this.staticService.currentPlaceItemsList.length
      && [StaticRouteType.Country, StaticRouteType.City].indexOf(this.staticService.staticType) > -1;
  }

  public initZoom(): void {
    switch (this.staticService.staticType) {
      case StaticRouteType.Place:
      case StaticRouteType.Review:
        this.staticService.zoom = 14;
        break;
      default:
        if (!this.staticService.zoom) {
          this.staticService.zoom = 7;
        }
        break;
    }
  }
}
