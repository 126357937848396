export const computeCenterOfCountry = (country: any): CoordinatesInterface => {
  const lat = (+country.latNorth + +country.latSouth) / 2;
  let lng = (+country.lngEast + +country.lngWest) / 2;

  const e = +country.lngEast + 180;
  const w = +country.lngWest + 180;

  if (e < w) {
    lng = (360 - w + e) / 2 + w;
    lng = lng > 360 ? 360 - lng : lng;
    lng = lng - 180;
  }

  return {lat, lng};
};

interface CoordinatesInterface {
  lat: number;
  lng: number;
}
