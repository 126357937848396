import {
  ApplicationRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { changeVal } from '../../../libraries';
import { FlightsBindDataInterface, InfoService, SsrService } from '../../../services';
import { getCityFrom, State } from '../../../store/reducers';
import { Subscription } from 'rxjs/internal/Subscription';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { FlightFrameBuilder } from '../../../abstracts/flights-frame-builder';
import { getCityValue } from '../../../libraries/map-object-helper';
import { MapObject } from '../../../interfaces';

@Component({
  selector: 'app-popup-city-flights',
  templateUrl: 'popup-city-flights.component.html',
  styleUrls: ['popup-city-flights.component.scss'],
})
export class PopupCityFlightsComponent extends FlightFrameBuilder implements OnInit, OnChanges, OnDestroy {
  @Input() cityPageTmpl;
  @Input() place: MapObject;
  @Input() placeInfo: MapObject;
  @Input() size = 'large';

  public cityFrom: any;
  public flights: any[] = [];
  public flightsFrameFlights: FlightsBindDataInterface;
  public minTicketPrice: number | null;

  private subscriptions: Subscription = new Subscription();

  constructor(
    protected applicationRef: ApplicationRef,
    protected injector: Injector,
    protected store$: Store<State>,
    protected infoService: InfoService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
    protected ssrService: SsrService,
    protected viewContainerRef: ViewContainerRef,
  ) {
    super(applicationRef, injector, store$, infoService, matIconRegistry, domSanitizer, viewContainerRef);
    // removing error "Error retrieving icon"
    const iconName = 'plane-icon-tickets';
    if (this.ssrService.isSSR()) {
      // Register empty icons for server-side-rendering to prevent errors
      this.matIconRegistry.addSvgIconLiteral(iconName, this.domSanitizer.bypassSecurityTrustHtml('<svg></svg>'));
    } else {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/airplanes/plane-icon-tickets.svg')
      );
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store$.pipe(select(getCityFrom))
        .subscribe(
          city => {
            this.cityFrom = city;
          }
        )
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changeVal(changes.placeInfo)) {
      const placeInfo = changes.placeInfo.currentValue;

      let flight;
      if (!(flight = this.isSetFlights(placeInfo)) && !(flight = this.isSetPlaceFlights(placeInfo))) {
        flight = {departure: {}, return: {}};
      }
      this.flightsFrameFlights = {
        flight: flight,
        place: this.place,
        iataCodes: {
          iataCode: getCityValue(placeInfo, 'iataCode'),
          iataCodeFrom: placeInfo.iataCodeFrom
        }
      };
    }
    if (changeVal(changes.place)) {
      const place = changes.place.currentValue;
      const flights = getCityValue(place, 'flights');
      this.minTicketPrice = flights && flights.minPrice ? flights.minPrice : null;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  isSetPlaceFlights(place) {
    const flight = getCityValue(place, 'flights');
    if (flight && flight.departDate && flight.returnDate) {
      return {
        departure: {departureTime: flight.departDate},
        return: {departureTime: flight.returnDate}
      };
    }
    return null;
  }

  public isSetFlights(placeInfo) {
    const flight = getCityValue(placeInfo, 'flights');
    return Array.isArray(flight) && flight.length ? flight[0] : null;
  }

  public openFlightsFrame() {
    super.openFlightsFrame(this.placeInfo);
  }

}
