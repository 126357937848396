<div class="welcome-container">
  <div class="close-button">
    <app-close-btn (click)="doContinue()"></app-close-btn>
  </div>
  <div class="container">
    <header class="fly-text-c">
      <h1 *ngIf="showTitle">Welcome to Explorow</h1>
      <span class="p-text">
          <p>{{textMessage}}</p>
        </span>
      <span *ngIf="showTitle" class="wrapper-logo">
        <img src="/assets/iconset/apple-icon.png" alt="">
      </span>
    </header>
    <button class="welcome-button">
      <span class="welcome-button-wrapper" (click)="doContinue()">Continue</span>
    </button>

  </div>
</div>
