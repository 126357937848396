import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

const VERSION_PARAM_START_KEY = ['testOpt', 'closeBtn'];

@Injectable()
export class VersionService {
  private versions = {};

  constructor(
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(val => {
      let keys = Object.keys(val);
      keys.forEach(key => {
        let keyValue = '';
        if (VERSION_PARAM_START_KEY.find(x => x.indexOf(key) >= 0))
          keyValue = key;
        if (keyValue)
          this.versions[keyValue] = Number(val[keyValue]);

      });
    });

  }

  public GetVersions() {
    return this.versions;
  }

}
