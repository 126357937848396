export interface TagInterface {
  id: number,
  name: string,
  urlKeywords: string
}

export interface TagResultInterface {
  items: TagInterface[];
}

export enum TagsLimitType {
  noLimit = 0,
  limit = 1,
  limitWithSpace = 2
}
