import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-static-place-info-edit',
  templateUrl: './static-place-info-edit.component.html',
  styleUrls: ['./static-place-info-edit.component.scss']
})
export class StaticPlaceInfoEditComponent {
  @Input() place: any;

  public isTooltipVisible = false;

  toggleShowTooltip(isVisible = true): void {
    this.isTooltipVisible = isVisible;
  }
}
