import { HostListener, Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RestoreCities, SetCityState, UserCityActions } from '../user-cities/user-cities.actions';
import { finalize, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getCollectionList, getFavoritesList, getHomeData, getSearchData, State } from '../reducers';
import {
  transformDateToApiDateType,
  transformReturnToApiDateType,
  transformTripTypeToApi,
  transformWhenDateToApiDateType
} from '../layer';
import { HttpParams } from '@angular/common/http';
import { SsrService, UserAccountService, UserService } from '../../services';
import {
  AddCollections,
  CheckAddPin,
  CheckRemoveCollection,
  LoadCollections,
  RestoreCollection,
  SetCollectionsState,
  UpdateCollectionPlaceOrder,
  UserCollectionsActions,
  UserCollectionsActionsType
} from './user-collections.actions';
import isArray from 'lodash/isArray';
import { doAsync } from '../../libraries';
import { ToastrService } from '../../services/toastr.service';
import { UserCollectionService } from '../../services/user-collection.service';
import { Subject } from 'rxjs/internal/Subject';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { TogglePinnedPanel } from "../panels/panels.actions";
import { PinInterface, SearchStateForm } from "../../interfaces";
import { GUEST_USER, NGX_LOGGED_USER, NGX_USER_CITIES, NGX_USER_COLLECTIONS } from "../../../constants";

@Injectable()
export class UserCollectionsEffects implements OnDestroy {
  public resizeWindow = 0;
  public userData: any;
  public loggedLocalstorage: string;
  public pinCollectionLoaded = false;
  private pinCollectionLoading = false;
  private userCollections: any[] = [];
  private userDataId: string;
  private isFirstInit: boolean = true;
  private $destroyed = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private userService: UserService,
    private userAccountService: UserAccountService,
    private userCollectionService: UserCollectionService,
    private toastrService: ToastrService,
    private ssrService: SsrService,
  ) {

    if (ssrService.isBrowser()) {
      this.store$.pipe(select(getHomeData))
        .pipe(takeUntil(this.$destroyed))
        .subscribe(home => {
          this.userData = home?.user;
          this.userDataId = this.userData?.id?.toString();
          this.loggedLocalstorage = localStorage.getItem(NGX_LOGGED_USER);
          this.getCollection();
        });

      this.resizeWindow = window.innerWidth;
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  checkRestoreCollection() {
    if (this.ssrService.isBrowser()) {
      const localStorageCollections = JSON.parse(localStorage.getItem(NGX_USER_COLLECTIONS)) || [];

      if (this.userService.loggedIn()) {
        this.loggedLocalstorage = this.userDataId;
        if (localStorageCollections?.length) {
          if (this.loggedLocalstorage === GUEST_USER) {
            this.userCollectionService.checkConfirmWindow().subscribe();
          } else {
            localStorageCollections.forEach(storageCollection => {
              if (!this.userCollections.some(userCollection => storageCollection.id === userCollection.id)) {
                this.userCollections.push(storageCollection);
              }
            });
            const collections = this.userDataId !== this.loggedLocalstorage && this.userCollections;
            if (collections) {
              this.store$.dispatch(new RestoreCollection(collections));
              localStorage.setItem(NGX_USER_COLLECTIONS, JSON.stringify(collections));
            }
          }
          localStorage.setItem(NGX_LOGGED_USER, this.loggedLocalstorage);
        } else {
          localStorage.setItem(NGX_USER_COLLECTIONS, JSON.stringify(this.userCollections));
        }
      }
    }
  }

  getCollection() {
    return combineLatest([
      this.store$.select(getCollectionList),
      this.store$.select(getFavoritesList)
    ]).pipe(
      takeUntil(this.$destroyed)
    ).subscribe((collection) => {
      this.userCollections = [...collection];
      this.userCollections = this.userCollections.map(collection => ({
        ...collection,
        _sync: this.userService.loggedIn() && this.ssrService.isBrowser() && this.loggedLocalstorage !== GUEST_USER
      }));
      if (this.isFirstInit) {
        const pins = this.ssrService.isBrowser() && JSON.parse(localStorage.getItem(NGX_USER_CITIES)) || [];
        this.store$.dispatch(new RestoreCities(pins));
        this.isFirstInit = false;
        if (this.checkAction()) {
          this.checkRestoreCollection();
        }
      } else {
        this.loggedLocalstorage = this.userService.loggedIn() && this.userData ? this.userData?.id?.toString() : GUEST_USER;
        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_LOGGED_USER, this.loggedLocalstorage);
        }
      }
    });
  }

  checkAction(): boolean {
    let result = true;
    if (!this.userService.loggedIn()) {
      if (!this.loggedLocalstorage) {
        this.loggedLocalstorage = GUEST_USER;
      } else if (this.loggedLocalstorage
        && this.loggedLocalstorage !== GUEST_USER
        && this.loggedLocalstorage !== this.userData?.id?.toString()) {
        this.userService.signOut();
        result = false;
      }
    }
    return result;
  }

  isMobile() {
    return (!this.ssrService.isBrowser() && this.ssrService.isMobile()) || this.resizeWindow <= 896;
  }

  $addUserCollection = createEffect(() => this.actions$.pipe(
    ofType<AddCollections>(UserCollectionsActionsType.AddCollection),
    withLatestFrom(this.store$.select(getSearchData)),
    map(([action]) => {
      let payload = action.payload;
      const arrPayload = Array.isArray(payload) ? payload : [payload];
      const checkSync = arrPayload.every(obj => obj._sync);

      if (this.userService.loggedIn() && !checkSync) {
        payload = (!isArray(payload)) ? [payload] : payload;
        const data = payload
          .filter((payload_) => !payload_._sync)
          .map(payload_ => ({
            name: payload_.name,
            description: payload_.description,
            isPublic: 1,
            _sync: true
          }));

        this.userAccountService.isPinLoading = true;
        this.userAccountService.addCollection(data[0])
          .pipe(finalize(() => {
            if (!action?.isProfilePage && action?.openPanel) {
              this.store$.dispatch(new TogglePinnedPanel({display: true}));
            }
          }))
          .subscribe((collectionResponse) => {
            this.store$.dispatch(new UserCollectionsActions.LoadDefaultCollections(collectionResponse.body, this.userService.loggedIn()));
            doAsync(() => this.userAccountService.isPinLoading = false, 500);
            if (action.isSynchronizePins) {
              this.store$.dispatch(new UserCollectionsActions.SynchronizeCollection(collectionResponse.body.id));
            }
            if (action?.addToNewCollection) {
              const addToNewCollection = {...action.addToNewCollection};
              addToNewCollection.selectedCollection = collectionResponse.body;
              this.userCollectionService.changeJourney(addToNewCollection, action?.isProfilePage);
            }
          }, () => {
          });
      } else if (!checkSync) {
        const collection = UserCollectionService.getCollectionStructureByName(arrPayload[0].name);
        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_USER_COLLECTIONS, JSON.stringify(collection));
        }
        this.store$.dispatch(new RestoreCollection(collection));
        this.store$.dispatch(new UserCollectionsActions.SynchronizeCollection());
        if (!this.isMobile() && action?.openPanel) {
          this.store$.dispatch(new TogglePinnedPanel({display: true}));
        }
      }
      this.userCollectionService.checkLastAdd(false);
    })
  ), {dispatch: false});

  $synchronizeCollection = createEffect(() => this.actions$.pipe(
    ofType<AddCollections>(UserCollectionsActionsType.SynchronizeCollection),
    map(payload => {
      const arrPayload = Array.isArray(payload) ? payload : [payload];
      let payload_ = arrPayload[0].payload;
      this.store$.dispatch(new UserCityActions.SynchronizeCities(payload_));
    })), {dispatch: false});

  $updateUserCollection = createEffect(() => this.actions$.pipe(
    ofType<AddCollections>(UserCollectionsActionsType.UpdateCollection),
    map((action) => action.payload),
    withLatestFrom(this.store$.select(getSearchData)),
    map(([payload]) => {
      const arrPayload = Array.isArray(payload) ? payload : [payload];
      const checkSync = arrPayload.every(obj => obj._sync);

      const collectionId = payload.id;

      if (this.userService.loggedIn() && !checkSync) {
        payload = (!isArray(payload)) ? [payload] : payload;
        const data = payload
          .filter((payload_) => !payload_._sync)
          .map(payload_ => ({
            ...(payload_.name) && {name: payload_.name},
            ...(payload_.description !== undefined) && {description: payload_.description},
            ...(payload_.isDefault !== undefined) && {isDefault: payload_.isDefault ? 1 : 0},
            ...(payload_.isPublic !== undefined) && {isPublic: payload_.isPublic ? 1 : 0},
            ...(payload_.isLineVisible !== undefined) && {isLineVisible: payload_.isLineVisible ? 1 : 0}
          }));
        this.userAccountService.isPinLoading = true;
        this.userAccountService.updateCollection(collectionId, data[0])
          .pipe(finalize(() => {
            if (!(payload?.length && payload[0]?.isProfilePage)) {
              this.store$.dispatch(new TogglePinnedPanel({display: true}));
            }
          }))
          .subscribe(() => {
            doAsync(() => this.userAccountService.isPinLoading = false, 500);
          }, () => {
          });
      } else if (!checkSync && !(payload?.length && payload[0]?.isProfilePage)) {
        this.store$.dispatch(new TogglePinnedPanel({display: true}));
      }
      this.userCollectionService.checkLastAdd(false);
    })
  ), {dispatch: false});

  $checkAddPin$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCollections>(UserCollectionsActionsType.CheckAddPin),
    map((action) => action),
    withLatestFrom(this.store$.select(getCollectionList)),
    map(payload => {
      const arrPayload = Array.isArray(payload) ? payload : [payload];
      const payload_ = arrPayload[0];
      const collections = arrPayload[1];
      let localStorageCollections;
      if (!this.userService.loggedIn() && !collections.length) {
        // TODO: Should be refactored, in guest mode collections.length after page refresh is zero
        localStorageCollections = JSON.parse(localStorage.getItem(NGX_USER_COLLECTIONS)) || [];
      }
      if (collections.length || !this.userService.loggedIn() && localStorageCollections?.length) {
        this.store$.dispatch(new UserCollectionsActions.AddPin(payload_));
      } else {
        if (this.userService.loggedIn()) {
          this.store$.dispatch(new UserCollectionsActions.LoadCollections(payload_, true));
        } else {
          const defaultCollection = UserCollectionService.getCollectionStructureByName();
          this.store$.dispatch(new AddCollections(defaultCollection, true, true, null, null, false));
        }
      }
      this.userCollectionService.checkLastAdd(false);
    })), {dispatch: false});

  $setCollectionsState$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCollections>(UserCollectionsActionsType.SetCollectionsState),
    map((action) => {
      const mergeArrsPinned = [];
      const collections = action.payload.collections;
      if (collections.length) {
        collections.forEach(pinned => {
          if (pinned.cities) {
            pinned.cities.forEach(city => mergeArrsPinned.push(city));
          }
          if (pinned.places) {
            pinned.places.forEach(place => mergeArrsPinned.push(place));
          }
          if (pinned.countries) {
            pinned.countries.forEach(country => mergeArrsPinned.push(country));
          }
        });
        this.store$.dispatch(new SetCityState({cities: mergeArrsPinned}));
      }
      if (this.userService.loggedIn()) {
        this.pinCollectionLoaded = true;
      }
    })), {dispatch: false});

  $loadCollections$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCollections>(UserCollectionsActionsType.LoadCollections),
    map((action) => {
      if (this.userService.loggedIn() && (!this.pinCollectionLoaded && !this.pinCollectionLoading || action.loadImportant)) {
        this.pinCollectionLoading = true;
        this.store$
          .pipe(
            take(1),
            select(getSearchData),
            map((searchState: SearchStateForm) => {
                const httpParams = new HttpParams()
                  .append('filter[cityFrom]', (searchState.cityFrom) ? searchState.cityFrom.id.toString() : '')
                  .append('filter[paxCount]', searchState.passengers.toString())
                  .append('filter[flightType]', transformTripTypeToApi(searchState.tripType))
                  .append('filter[dateType]', transformDateToApiDateType(searchState.date).toString())
                  .append('filter[when]', transformWhenDateToApiDateType(searchState.date))
                  .append('filter[return]', transformReturnToApiDateType(searchState.date) || '')
                  .append(
                    'expand',
                    'places.place.pictures,places.place.city.flights,places.place.city.country,' +
                    'places.place.country,places.place.video,dates'
                  );
                this.userAccountService.getPinnedCollections(httpParams)
                  .subscribe((response) => {
                    this.pinCollectionLoading = false;
                    let collections = response.items;
                    collections = JSON.parse(JSON.stringify(collections));
                    this.store$.dispatch(new SetCollectionsState({collections}, true, this.userService.loggedIn()));
                    if (action.payload) {
                      this.store$.dispatch(new CheckAddPin(action.payload));
                    }
                    this.store$.dispatch(new UserCollectionsActions.LoadedCollections());
                    this.checkRestoreCollection();
                  });
              }
            )
          )
          .subscribe();
      }
    })
  ), {dispatch: false});

  $removeUserCity = createEffect(() => this.actions$.pipe(
    ofType<CheckRemoveCollection>(UserCollectionsActionsType.CheckRemoveCollection),
    map((action) => {
      if (this.userService.loggedIn()) {
        this.userAccountService.isPinLoading = true;
        this.userAccountService.deleteCollection(action.payload.id)
          .pipe(finalize(() => {
            if (!action?.isProfile) {
              this.store$.dispatch(new TogglePinnedPanel({display: true}));
            }
          }))
          .subscribe(() => {
            this.store$.dispatch(new UserCollectionsActions.RemoveCollections(action.payload));
            this.store$.dispatch(new UserCityActions.RemovePinsFromCollection(action.payload));
            doAsync(() => this.userAccountService.isPinLoading = false, 500);
          }, (error) => {
            this.toastrService.activeToastr$.next({message: error.error[0]?.message});
          });
      } else {
        this.store$.dispatch(new UserCollectionsActions.RemoveCollections(action.payload));
        this.store$.dispatch(new UserCityActions.RemovePinsFromCollection(action.payload));
        this.store$.dispatch(new TogglePinnedPanel({display: true}));
      }
      this.userCollectionService.checkLastAdd(false);
    })
  ), {dispatch: false});

  $resetCollections$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCollections>(UserCollectionsActionsType.ResetCollections),
    map(() => this.pinCollectionLoaded = false)), {dispatch: false});

  $updateCollectionPlaceOrder = createEffect(() => this.actions$.pipe(
    ofType<UpdateCollectionPlaceOrder>(UserCollectionsActionsType.UpdateCollectionPlaceOrder),
    map((action: UpdateCollectionPlaceOrder) => {
      if (this.userService.loggedIn()) {
        let pinOrder = action.pinOrder.map((pin: PinInterface) => ({oid: pin.oid, otype: pin.otype, type: pin.type}));
        this.userAccountService.isPinLoading = true;
        this.userAccountService.patchCollection(action.collectionId, {pinOrder})
          .subscribe(() => {
            doAsync(() => this.userAccountService.isPinLoading = false, 500);
          }, () => {
          });
      }
      //TODO: save to storage
    })
  ), {dispatch: false});
}
