import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UploadService } from "../../services/upload-service";
import { DestroyComponent } from "../../../../heplers/destroy.component";
import { takeUntil } from "rxjs/operators";
import { UploadProgressInterface } from "../../../../interfaces/upload-progress";
import { typeMedia } from "../../../place/your-own-place/your-own-place.component";

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent extends DestroyComponent implements OnInit, OnDestroy {
  @Input() index: number

  public isOpenProgressContainer: boolean;
  public uploadsProgress: [UploadProgressInterface[]];
  public typeMedia = typeMedia;
  public allUploadsProgress: any;
  public isUploaded: boolean;
  public uploadTitle: string;
  public successUploadPopupData: boolean[] = [];

  constructor(private uploadService: UploadService) {
    super();
  }

  ngOnInit(): void {
    this.uploadService.successUploadPopupData$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(success => {
      this.successUploadPopupData = success;
    });

    this.uploadService.uploadPopupData$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(uploads => {
      this.uploadsProgress = uploads;
      this.calcProgress();
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.uploadService.uploadPopupData$.next([[]]);
  }

  public toggleProgressContainer() {
    this.isOpenProgressContainer = !this.isOpenProgressContainer;
  }

  private calcProgress() {
    this.allUploadsProgress = {
      count: 0,
      loaded: 0,
      total: 0,
      progress: 0
    };
    let allProgresses = 0;

    if (this.uploadsProgress && this.uploadsProgress[this.index]) {
      this.uploadsProgress[this.index].forEach(item => {
        this.allUploadsProgress.count++;
        this.allUploadsProgress.loaded = (+this.allUploadsProgress.loaded + +item.loaded).toFixed(1);
        this.allUploadsProgress.total = (+this.allUploadsProgress.total + +item.total).toFixed(1);
        allProgresses += item.progress;
      });
    }
    if (allProgresses > 0) {
      this.allUploadsProgress.progress = allProgresses / this.allUploadsProgress.count;
    }
    this.isUploaded = this.allUploadsProgress.progress === 100;

    if (this.isUploaded || (!this.isUploaded && this.allUploadsProgress?.count === 0)) {
      this.uploadTitle = (this.allUploadsProgress.count + ' ' + (this.allUploadsProgress?.count > 1 ? 'files' : 'file')) + ' uploaded';
    } else {
      this.uploadTitle = 'Uploading ' + this.allUploadsProgress.count + ' ' + (this.allUploadsProgress?.count > 1 ? 'files' : 'file');
    }
  }
}
