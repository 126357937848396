<div class="modal" #modal
     *ngIf="opened"
     (click)="close($event, modal)"
>
  <div class="autocomplete fly-beauty-scroll"
       *ngIf="itemOptions.length"
       [ngClass]="{'fix-scroll': itemOptions.length > 4}"
  >
    <ng-scrollbar [orientation]="'vertical'">
      <div class="option"
           *ngFor="let item of itemOptions"
           [ngClass]="{'selected': item.isSelected}"
           (click)="select(item)">

        <img class="city-icon" src="/assets/icons/city-icon.png" alt=""/>
        <div [innerHTML]="item.innerHTML"></div>

      </div>
    </ng-scrollbar>
  </div>
</div>
