<ng-template #pageContainer>
  <div class="home__container">
    <div class="home__content">
      <app-static-top-places
        *ngIf="data && data.length > 0"
        [data]="data"
        [resizeWindow]="resizeWindow"
        (pin)="pinPlace($event)"
        (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)">
      </app-static-top-places>
    </div>
  </div>
</ng-template>

<app-static-content>
  <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
</app-static-content>

<div *ngIf="showSignupConfirmWin" class="win-welcome">
  <app-welcome
    [textMessage]="'Thank you for registering! Your account setup is now complete.'"
    [showTitle]="true"
    (emitContinue)="showSignupConfirmWin = false"
  ></app-welcome>
</div>
