<div class="wrapper">
  <app-static-header></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="body-container">
    <div class="greeting-block">
      <img class="greeting-image" src="/assets/images/landing-business/guided-tour.png">
      <div class="greeting-text"><p>Grow your <br> travel business <br> with <span class="special-text">Explorow</span>
      </p></div>
      <div class="greeting-subtext"><div>Explorow is a free travel platform based on the interactive map</div></div>

      <app-sign-in-buttons [showProfilePopup]="false"
                           [isNotSignInButton]="joinNowTemplate" ></app-sign-in-buttons>
    </div>
  </div>
  <div class="benefit-block">
    <div *ngFor="let benefit of benefits; let i = index"
         class="benefit-item">
      <div class="benefit-item__text">
        <p class="action-title">{{ benefit.actionTitle }}</p>
        <p class="title">{{ benefit.title }}</p>
        <div class="text" [innerHTML]="benefit.text"></div>
      </div>
      <div class="benefit-item__video">
        <video class="video-player"
               [src]="benefit.video"
               [loop]="true"
               [autoplay]="true"
               playsinline
               [muted]="true"></video>
      </div>

      <ng-container>
        <app-sign-in-buttons [showProfilePopup]="false"
                             [isNotSignInButton]="linkContainer"></app-sign-in-buttons>
      </ng-container>

      <ng-template #linkContainer>
        <ng-container *ngTemplateOutlet="benefitLink; context: {text: benefit.linkText}"></ng-container>
      </ng-template>
    </div>
  </div>
  <div class="invitation-container">
    <p class="invitation-text">Three reasons, why <span class="special-text">Explorow</span>?</p>

    <div class="main-block">
      <div class="text">
        <div class="text-block">
          <h1>1. Boost your brand ranking</h1>
          Placing your content on a relevant website is essential for driving traffic to your website and building brand
          awareness.
        </div>
        <div class="text-block">
          <h1>2. Link building</h1>
          Receive free links to your website, enhancing the "authority" of your pages in the eyes of Google. This
          results in higher rankings and increased search traffic to your dedicated pages.
        </div>
        <div class="text-block">
          <h1>3. Become visible</h1>
          Make it simple for your customers to find you. Display your locations on a map. Add photos, videos, and
          important info with just a few clicks.
        </div>
      </div>
      <img class="why-explorow" src="/assets/images/landing-business/promo-why-explorow.jpg">
    </div>

    <app-sign-in-buttons [showProfilePopup]="false"
                         [isNotSignInButton]="signupTemplate"></app-sign-in-buttons>
  </div>
  <img class="invitation-image" src="/assets/images/landing-business/footer-image-wide.svg">
  <app-footer [hasMotto]="false"></app-footer>
</div>

<ng-template #joinNowTemplate>
  <button class="btn" (click)="isLoggedUser && redirectToAccount()">Join now</button>
</ng-template>

<ng-template #signupTemplate>
  <button class="btn" (click)="isLoggedUser && redirectToAccount()">Sign up for free</button>
</ng-template>

<ng-template #benefitLink let-text="text">
  <a class="benefit-link" (click)="isLoggedUser && redirectToAccount()">
    {{ text }}
    <img class="arrow" src="/assets/icons/arrow-right-blue.svg" alt="arrow">
  </a>
</ng-template>
