<div class="wrapper">

  <div class="progress-bar">
    <mat-progress-bar
      *ngIf="ajaxState$ | async"
      mode="indeterminate">
    </mat-progress-bar>
  </div>

  <app-static-header
    *ngIf="!isTabletDesign()"
    [place]="showPlace"
    [data]="placesData"
  ></app-static-header>

  <div class="user-account__container" [class.profile]="isProfileMode()">

    <div class="user-account__content" [ngClass]="userAccountConfig">
      <h1 *ngIf="isProfileMode()">Your info</h1>

      <div class="close" (click)="close()">
        <figure class="close-popup fly-pointer">
          <img [src]="'/assets/cross.svg'" class='close-popup-img' alt="close" title="close">
        </figure>
      </div>

      <div class="user-account__sidebar"
           *ngIf="(!isTabletDesign() || isTabletDesign() && !isOpenContent) && pageType === AccountPageTypesEnum.Account">
        <app-user-sidebar
          [mode]="accountMode"
          (routeSelected)="selectedMode($event)">
        </app-user-sidebar>
      </div>

      <div class="user-account__view-content"
           *ngIf="!isTabletDesign() || isTabletDesign() && isOpenContent">
                  <span class="back-btn"
                        *ngIf="isTabletDesign() && pageType === AccountPageTypesEnum.Account"
                        (click)="selectedMode(false)">
                        <img [src]="'/assets/arrow/arrow-keyboard-down.svg'" alt=""> Back
                  </span>

        <app-profile-user-about
          *ngIf="accountMode === accountRouteType.About && pageType === AccountPageTypesEnum.Account"
          [data]="profileInformation?.profile"
          [fields]="fields"
          (saveProfileEmitter)="saveProfileData($event)"
          (resendEmail)="resendMail()"
          (toggle)="toggleEditor($event)"
          (changeSettings)="updateUserSettings($event)"
          (changeEmail)="changeEmail($event)">
        </app-profile-user-about>

        <app-profile-user-about
          *ngIf="accountMode === accountRouteType.About && isProfileMode()"
          [mode]="pageType === AccountPageTypesEnum.BusinessProfile ? ProfileAboutMode.Short : ProfileAboutMode.ShortHidden"
          [data]="profileInformation?.profile"
          [fields]="fields"
          (saveProfileEmitter)="prepareProfileData($event)"
          (toggle)="toggleEditor($event)">
        </app-profile-user-about>

        <app-profile-user-page
          *ngIf="accountMode === accountRouteType.YourPage"
          [data]="profileInformation?.profile"
          [fields]="fields"
          (visibleAlert)="showAlert($event)"
          (changeSettings)="updateUserSettings($event)"
          (toggle)="toggleEditor($event)">
        </app-profile-user-page>

        <app-profile-user-places
          *ngIf="accountMode === accountRouteType.YourPlaces"
          [places]="userPlaces"
          [isAscSort]="isAscSort"
          [isRecentlyDeletedPage]="isRecentlyDeletedPage"
          [placesOtherCount]="placesOtherCount"
          (toggleSortEmitter)="toggleSort($event)"
          (toggleRecentlyDeletedEmitter)="toggleRecentlyDeleted($event)"
          (recoverPlaceEmitter)="recoverPlace($event)"
          (loadMorePlacesEmitter)="loadMorePlaces()"
          (deletePlaceEmitter)="deletePlace($event)">
        </app-profile-user-places>

        <app-profile-user-reviews
          *ngIf="accountMode === accountRouteType.YourReviews"
          [reviews]="userReviews"
          [reviewsOtherCount]="reviewsOtherCount"
          (loadMoreReviewEmitter)="loadMoreReview()"
          (deleteReviewEmitter)="deleteReview($event)"
          (syncReviewEmitter)="syncReview($event)">
        </app-profile-user-reviews>

        <app-user-collections *ngIf="accountMode === accountRouteType.Journeys"
                              [isProfile]="true"
                              [userIsLogged]="userIsLogged"></app-user-collections>

        <app-favorites-panel *ngIf="accountMode === accountRouteType.Favourites"
                             [isProfile]="true"></app-favorites-panel>

        <app-following-panel *ngIf="accountMode === accountRouteType.Following"
                             [isProfile]="true"></app-following-panel>

        <app-profile-user-settings
          *ngIf="accountMode === accountRouteType.Settings"
          [data]="profileInformation?.profile"
          [fields]="fields"
          (changeSettings)="updateUserSettings($event)"
          (getProfile)="getProfileData()"
          (changedPasswordEmitter)="changedPassword()"
          (toggle)="toggleEditor($event)"
          (closeAccountEmitter)="closeUserAccount()">
        </app-profile-user-settings>

        <app-profile-notification-settings
          *ngIf="accountMode === accountRouteType.Notifications"
          [data]="profileInformation?.profile?.settings"
          (changeSettings)="updateUserSettings($event)">
        </app-profile-notification-settings>

        <div class="action-btn-place" *ngIf="isProfileMode()">
          <button class="btn-create"
                  type="submit"
                  [disabled]="isSubmit"
                  (click)="saveFullProfileData()">
            Save
          </button>
        </div>

      </div>
    </div>

    <app-footer></app-footer>
  </div>

  <div class="popup-toastr" *ngIf="isShowToastr" [ngClass]="{'alert-error': errorSubmit}">
    <app-close-btn (click)="closeToastr()"></app-close-btn>
    <span>{{ alertMessage }}</span>
  </div>
</div>
