<div class="empty-places" (click)="closePopup()"></div>
<div id="popup" class="popup-select">
  <div class="popup-content"
       [class.is-profile-page]="isProfile"
       [ngClass]="!!classNameBySide && classNameBySide">
    <div class="header">
      <div class="popup-row-text">{{isAddToJourney ? 'Add to another journey' : 'Save to journey'}}</div>
      <span class="close" (click)="closePopup()">&times;</span>
    </div>

    <mat-form-field subscriptSizing="dynamic">
      <input type="text"
             matInput
             autocomplete="off"
             [matAutocomplete]="collectionName"
             placeholder="Select a collection">
      <mat-autocomplete #collectionName="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="getSelectJourney($event)">
        <mat-option *ngFor="let collection of allJourneys" [value]="collection">
          {{ collection.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <input class="btn-submit" [disabled]="!selectedJourney" type="submit" value="ok" (click)="changeJourney()">

    <div class="create-collection" (click)="createJourney()">
      <img src="/assets/icons/plus.svg" alt="">
      <p>Create a new journey</p>
    </div>
  </div>
</div>
