import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-text-field',
  templateUrl: 'profile-text-field.component.html',
  styleUrls: ['profile-text-field.component.scss']
})
export class ProfileTextFieldComponent {
  @Input() type: string;
  @Input() value: any;
  @Input() disabled: boolean;

  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  public save(): void {
    this.saveEmitter.emit(this.value);
  }

  public close(): void {
    this.cancel.emit();
  }
}
