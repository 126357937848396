import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fly-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss']
})
export class FlyCheckboxComponent {

  @Output() stateChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() label;
  @Input() checked;
  @Input() disabled: boolean = false;

  onCheck(checked) {
    checked.click();
    this.stateChange.emit(checked);
  }

}
