import { Injectable } from '@angular/core';

const PopupSideClassNames = {
  toRight: 'to-right',
  toUp: 'to-up',
  toLeft: 'to-left',
  toStart: 'to-start',
  toBottom: 'to-bottom'
};

@Injectable({
  providedIn: 'root'
})
export class MovementPopupService {

  public movementPinPanelData: DOMRectReadOnly;
  private readonly popupWidth = 290;
  private readonly popupHeight = 171;

  constructor() {
  }

  public loadMovementPinPanelData(pinnedPanelContainer) {
    if (pinnedPanelContainer) {
      this.movementPinPanelData = pinnedPanelContainer?.nativeElement?.getBoundingClientRect();
    }
  }

  public checkMovementPopup(event): string {
    let classNames = '';
    if (this.movementPinPanelData) {
      if (event.right + this.popupWidth < this.movementPinPanelData.right) {
        classNames += ' ' + PopupSideClassNames.toRight;
      } else if (event.right + this.popupWidth >= this.movementPinPanelData.right) {
        classNames += ' ' + PopupSideClassNames.toLeft;
      }
      if (event.right - this.movementPinPanelData.left < this.popupWidth) {
        classNames += ' ' + PopupSideClassNames.toStart;
      }
      if (event.top + this.popupHeight < this.movementPinPanelData.bottom) {
        classNames += ' ' + PopupSideClassNames.toBottom;
      }
      if (event.top + this.popupHeight >= this.movementPinPanelData.bottom) {
        classNames += ' ' + PopupSideClassNames.toUp;
      }
    }
    return classNames
  }

}
