import { Injectable } from '@angular/core';
import { _measureTime } from './lib/_measure-time';
import { normalizePoints } from './lib/normalize-points';
import { weightBasedGrouping } from './lib/weight-based-grouping';
import { definePriorityBasedIntersections } from './lib/define-priority-based-intersections';
import { MapService } from "../map.service";

@Injectable()
export class GroupingPlacesService {

  public definePriorityBasedIntersections: any = null;
  public google: any = null;
  public normalizePoints: any = null;

  constructor(
    private mapService: MapService,
  ) {
    this.normalizePoints = normalizePoints;
    this.definePriorityBasedIntersections = definePriorityBasedIntersections;
  }

  defineMap(map: any) {
    this.mapService.map = map;
  }

  initialize(placesToGroup: any, searchedCity?, existPoints?: any[]) {
    placesToGroup.forEach(place => {
      place['showRating'] = 0;
    });
    let places = this.makeCopyFromArray(placesToGroup);
    let copyExistPoints: any[] = existPoints ? this.makeCopyFromArray(existPoints) : [];
    return _measureTime('time=', () => {
      const map = this.mapService.map;

      let priorityBasedGroups = weightBasedGrouping(places, 'priority');

      if (searchedCity) {
        const index = places.indexOf(places.find(x => x.id === searchedCity.id));
        if (index !== -1) {
          places[index].priority = priorityBasedGroups[0].priority + 1;
          priorityBasedGroups = weightBasedGrouping(places, 'priority');
        }

      }
      if (copyExistPoints && copyExistPoints.length > 0) {
        copyExistPoints.forEach(place => {
          place[0]['showRating']--;
          const comingPlace = places.find(x => x.id === place[0].id);
          if (comingPlace) {
            place[0].showRating++;
            place[0].cluster = comingPlace['cluster'];
            place[0].intersectingClusters = [];
          }
        });

        copyExistPoints = copyExistPoints.filter(x => x[0]['showRating'] > -5);

        places = places.filter(x => copyExistPoints.find(ex => ex.find(a => a.id === x.id) != null) == null);
        priorityBasedGroups = weightBasedGrouping(places, 'priority');
      }

      const indexedNormalizedPoints = this.normalizePoints(map, places.concat(copyExistPoints.map(x => x[0])));
      const definedIntersections = this.definePriorityBasedIntersections(
        map, map.zoom,
        priorityBasedGroups,
        indexedNormalizedPoints,
        copyExistPoints
      );

      const popularGroupPlaces = definedIntersections.popularGroupPlaces;
      const placesThatSuspendedForIntersections = definedIntersections.placesThatSuspendedForIntersections;

      return popularGroupPlaces.concat(placesThatSuspendedForIntersections);
    });
  }

  makeCopyFromArray(array: any[]): any[] {
    return array.map(i => i);
  }
}
