import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['../../styles/terms-of-service-privacy.scss', 'terms-of-service.component.scss']
})
export class TermsOfServiceComponent {

  @Output() closeTermsOfService: EventEmitter<any> = new EventEmitter<any>();

  public isStaticPage = false;

  constructor(private route: Router) {
    this.isStaticPage = this.route.url === '/terms-of-use';
  }

  closeWindow() {
    this.closeTermsOfService.emit(false);
  }

}
