<div class="modal">
  <div class="modal-content">
    <div class="auth-container" style="border: none;">
      <div class="progress-bar">
        <mat-progress-bar
          *ngIf="ajaxState$ | async"
          mode="indeterminate">
        </mat-progress-bar>
      </div>

      <div class="close-button">
        <app-close-btn (click)="closeWindow()"></app-close-btn>
      </div>

      <section class="auth-with-email">
        <div class="terms-of-service-container">
          <div class="terms-of-service-content fly-m-t-10px fly-beauty-scroll">
            <header class="fly-text-c">
              <h1>{{title}}</h1>
              <p>{{subText}}</p>
            </header>

            <div class="alternate-theme">
              <form name='reset-pass-form'
                    class="auth-form"
                    (ngSubmit)="contact(contactForm)"
                    [formGroup]="contactForm">

                <div class="form-group">
                  <label [ngClass]="{'translate-bottom': !nameFocused}"
                         class="pass-label"
                         for="name"
                         i18n="labels.name"
                         innerText="'labels.name' | translate">
                    name </label>

                  <input #nameInput
                         type="text"
                         id="name"
                         class="form-control"
                         formControlName="name"
                         (input)="valueUpdated('name', nameInput.value)"
                         (focus)="toggleFocus('name', true)"
                         (blur)="toggleFocus('name', false)">
                  <div *ngIf='resOfNameValidation' class="validation-errors">{{resOfNameValidation}}</div>
                  <div *ngIf="contactForm.controls.name.invalid
              && (isSubmit
              || contactForm.controls.name.dirty
              || contactForm.controls.name.touched)"
                       class="validation-errors">
                    <div *ngIf="contactForm.controls.name?.errors?.required">
                      Name is required.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label [ngClass]="{'translate-bottom': !emailFocused}" class="pass-label" for="email"
                         i18n="labels.email" innerText="'labels.email' | translate">
                    E-mail </label>

                  <input #emailInput
                         type="email"
                         id="email"
                         class="form-control"
                         formControlName="email"
                         (input)="valueUpdated('email', emailInput.value)"
                         (focus)="toggleFocus('email', true)"
                         (blur)="toggleFocus('email', false)">
                  <div *ngIf='resOfEmailValidation' class="validation-errors">{{resOfEmailValidation}}</div>
                  <div *ngIf="contactForm.controls.email.invalid
              && (isSubmit
              || contactForm.controls.email.dirty
               || contactForm.controls.email.touched)"
                       class="validation-errors">
                    <div *ngIf="contactForm.controls.email?.errors?.required">
                      E-mail is required.
                    </div>
                    <div *ngIf="contactForm.controls.email?.errors?.email">
                      Please provide a valid E-mail address.
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label [ngClass]="{'translate-bottom': !askQuestionFocused}"
                         class="pass-label"
                         for="message"
                         i18n="labels.ask"
                         [innerText]="textInput">
                    {{textInput}} </label>

                  <textarea #messageInput
                            type="text"
                            id="message"
                            class="form-control"
                            [rows]="4"
                            formControlName="message"
                            (input)="valueUpdated('message', messageInput.value)"
                            (focus)="toggleFocus('message', true)"
                            (blur)="toggleFocus('message', false)">
                        </textarea>
                  <div *ngIf='resOfMessageValidation' class="validation-errors">{{resOfMessageValidation}}</div>
                  <div *ngIf="contactForm.controls.message.invalid
              && (isSubmit
              || contactForm.controls.message.dirty
              || contactForm.controls.message.touched)"
                       class="validation-errors">
                    <div *ngIf="contactForm.controls.message?.errors?.required">
                      Message is required.
                    </div>
                  </div>
                </div>
                <span *ngIf="SuccessResponse" class="successMessage">Your question has been sent successfully!</span>
                <span *ngIf="ErrorResponse" style="color: red;">{{ErrorResponseMessage}}</span>
                <div class="form-group">
                  <button [disabled]="isSubmit && contactForm.invalid"
                          mat-raised-button
                          type="submit"
                          class="contact-us-button"
                          i18n="labels.login"
                          innerText="'labels.login' | translate">
                    Submit
                  </button>
                </div>

              </form>
            </div>
          </div>

        </div>
      </section>

    </div>
  </div>
</div>
