<div class="user-places__container">
  <div class="user-places__title">
    <span class="user-places__headers">
    LIST OF REVIEWS
    </span>
  </div>

  <div class="user-places__list">
    <div class="user-places__item" *ngFor="let review of reviews; let i = index">
      <span>{{ (i + 1) }}.</span>

      <div class="user-places__item-content">
        <div class="user-places__item-header">
          <a class="user-places__link"
             [routerLink]="'/places/' + review?.place?.urlKeywords + '/' + review?.place?.id + '/review/' + review?.id"
             [queryParams]="(+review?.status === reviewStatus.ClientStatusOnline ? {} : {preview:''})">
            <h3>{{ review?.place?.name }}</h3>
          </a>

          <a class="open-link"
             [routerLink]="'/places/' + review?.place?.urlKeywords + '/' + review?.place?.id + '/review/' + review?.id"
             [queryParams]="(+review?.status === reviewStatus.ClientStatusOnline ? {} : {preview:''})"
             target="_blank">
            <img [src]="'/assets/icons/icon-exit.png'" alt="">
          </a>
        </div>

        <div class="user-places__action-link">
                    <span class="item-id">
                        IDN-{{ review?.id }}
                    </span>

          <span class="status tooltip-event-item">
            Date added: {{review?.creationDate | date: 'dd/MM/yy'}}
            <span class="tooltip-event"> {{review?.creationDate | date: 'dd/MM/yy HH:mm'}}</span>
          </span>

          <span class="status">
            Status:
            <span class="disable"
                  *ngIf="review?.status === reviewStatus.ClientStatusDisable">
              Disabled
            </span>
            <span class="online"
                  *ngIf="review?.status === reviewStatus.ClientStatusOnline">
              Online
            </span>
            <span class="moderation"
                  *ngIf="[reviewStatus.ClientStatusOnModeration, reviewStatus.ClientStatusNewFromUser].indexOf(review?.status) > -1">
              On Moderation
            </span>
            <span class="rejected"
                  *ngIf="review?.status === reviewStatus.ClientStatusRejected">
              Rejected
            </span>
          </span>
        </div>

        <div class="user-places__actions">
          <span class="edit-item" (click)="openDialog(review)">Delete</span>
        </div>
      </div>
    </div>

    <div class="user-places__not-found" *ngIf="!reviews?.length">
      <span>You don't have any created reviews</span>
    </div>


    <div class="read-more__container"
         *ngIf="reviewsOtherCount > 0"
         (click)="loadMoreReview()">Show more ({{reviewsOtherCount}} reviews)
      <div class="read-more__arrow-down">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
             y="0px"
             viewBox="0 0 1000 1000"
             enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g><g><path d="M124.8,197.6L500,572.7l375.2-375.2L990,312.4l-490,490l-490-490L124.8,197.6z"/></g></g>
          </svg>
      </div>
    </div>

    <div class="action-btn-place"
         [ngClass]="{'empty-places': !reviews?.length}">
      <button class="btn-create" type="submit"
              [routerLink]="'/review/add'">
        {{!reviews?.length ? 'Add a Review' : 'Add an Another Review'}}
      </button>
    </div>
  </div>
</div>

<app-confirm-dialog
  *ngIf="isConfirm"
  [message]="'Are you sure that you want to delete this review?'"
  (action)="deleteReview($event)">
</app-confirm-dialog>
