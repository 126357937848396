import { Component, ViewEncapsulation } from '@angular/core';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { take, takeUntil } from "rxjs/operators";
import { UserCollectionService } from "./services/user-collection.service";
import { DestroyComponent } from "./heplers/destroy.component";
import { UserService } from "./services";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends DestroyComponent {
  public showConfirmWindow: boolean = false;

  constructor(
    private gaService: GoogleAnalyticsService,
    private userCollectionService: UserCollectionService,
    private userService: UserService,
  ) {
    super();
    this.gaService.addGAScript();
    this.userCollectionService.showConfirmWindow$
      .pipe(takeUntil(this.destroy$))
      .subscribe(showConfirmWindow => {
        this.showConfirmWindow = showConfirmWindow && this.userService.loggedIn();
      });
  }

  public confirmCitiesSave($event) {
    this.userCollectionService.confirmCitiesSave($event)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe();
  }
}
