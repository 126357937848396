import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ReviewStatusType } from "../../enums/place-status.enum";
import { Subject } from "rxjs/internal/Subject";

@Component({
  selector: 'app-profile-user-reviews',
  templateUrl: './profile-user-reviews.component.html',
  styleUrls: [
    '../profile-user-places/profile-user-places.component.scss',
    './profile-user-reviews.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileUserReviewsComponent implements OnDestroy {
  @Input() reviews: any[] = [];
  @Input() reviewsOtherCount: number = 0;

  @Output() deleteReviewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() syncReviewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMoreReviewEmitter: EventEmitter<any> = new EventEmitter<any>();

  public reviewStatus = ReviewStatusType;
  public isConfirm = false;
  public selectedItem: any;
  public page: number = 2;
  private $destroyed = new Subject<void>();

  constructor() {
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.syncReviewEmitter.emit(this.reviews);
  }

  public openDialog(item: any): void {
    this.isConfirm = true;
    this.selectedItem = item;
  }

  public deleteReview(value: boolean): void {
    if (value) {
      this.deleteReviewEmitter.emit(this.selectedItem);
      this.isConfirm = false;
    } else {
      this.isConfirm = false;
      this.selectedItem = null;
    }
  }

  public loadMoreReview() {
    this.loadMoreReviewEmitter.emit();
  }
}
