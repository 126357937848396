<div class="info-main-container" *ngIf="renderData.length > 0">
  <div class="info-main-wrapper" *ngFor="let dataItem of renderData">

    <ng-container *ngIf="dataItem?.type === REGULAR_BLOCK; else placeBlock">
      <h1 class="info-title">{{ dataItem.title }}</h1>

      <div class="info-content-wrapper">
        <div class="info-content">
          <div class="info-items" *ngFor="let place of dataItem.data">
            <a class="item-link"
               [routerLink]="getUrl(place)">
              {{ place.title }}
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #placeBlock>
      <div class="static-grid-container info-places">
        <ng-container *ngFor="let item of dataItem.data; last as last; index as index">
          <app-static-place-item
            [placeInfo]="item"
            [isLast]="last"
            [placeIndex]="index"
            (pin)="pinPlace($event)"
            (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)"
          ></app-static-place-item>
        </ng-container>
      </div>
    </ng-template>

  </div>
</div>
