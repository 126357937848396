import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestinationType } from '../../../../enums/destination-type';
import { StaticRouteType } from '../../enums/route-type';
import { CategoryModeType } from '../static-categories/static-categories.component';
import { ActivatedRoute, Router } from "@angular/router";
import { Journey } from "../../../../interfaces/journey";
import { SsrService, WindowRef } from "../../../../services";
import { StaticService } from "../../../../services/static.service";
import { select, Store } from "@ngrx/store";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { getSearchData } from "../../../../store/reducers";
import { StaticInitService } from "../../services/static-init.service";
import { UserCollectionService } from "../../../../services/user-collection.service";
import { StaticContentService } from "../../../../services/static-content.service";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { PlaceNew } from "../../../../interfaces";
import { getUserNameByObject } from "../../../../libraries/get-user-name";
import { getUserAvatarByObject } from "../../../../libraries/get-user-avatar";
import { DEFAULT_TITLE_JOURNEY } from "../../../../../constants";

@UntilDestroy()
@Component({
  selector: 'app-static-category-info',
  templateUrl: './static-category-info.component.html',
  styleUrls: [
    '../static-user-personal-info/static-user-personal-info.component.scss',
    './static-category-info.component.scss'],
})
export class StaticCategoryInfoComponent implements OnInit, OnChanges {
  @Input() mapPage: any;
  @Input() data: any;
  @Input() staticType: StaticRouteType;
  @Input() groupRouteQuery: any;
  @Input() currentPinCollection: Journey;
  @Input() searchData: any;
  @Input() collectionId: number;
  @Input() pageType: StaticRouteType;

  @Output() clickCategory = new EventEmitter<any>();
  @Output() pinPlaceEmitter = new EventEmitter<any>();

  public staticRouteType = StaticRouteType;
  public categoryType = CategoryModeType;

  protected readonly getLinkByObject = getLinkByObject;
  protected readonly getUserNameByObject = getUserNameByObject;
  protected readonly getUserAvatarByObject = getUserAvatarByObject;
  protected userLink: string;
  protected isUserLinkNamed: boolean;

  constructor(
    public staticService: StaticService,
    public staticContentService: StaticContentService,
    protected activatedRoute: ActivatedRoute,
    protected ssrService: SsrService,
    protected windowRef: WindowRef,
    protected staticInitService: StaticInitService,
    protected store$: Store,
    protected router: Router,
    protected userCollectionService: UserCollectionService,
    protected cdRef: ChangeDetectorRef,
  ) {
    this.store$.pipe(
      untilDestroyed(this),
      select(getSearchData)
    ).subscribe(searchData => this.staticService.searchData = searchData);
  }

  ngOnInit(): void {
    this.staticService.setStaticType(this.activatedRoute.snapshot.data['static']);
    this.staticType = this.staticService.staticType;

    this.staticContentService.$data
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.data = data
        this.staticService.errorMessage = '';
        if (this.staticType === StaticRouteType.Search && !this.data['items']?.length) {
          this.staticService.getErrorMessage();
        }
        if(this.staticType === StaticRouteType.Journey) {
          this.userLink = getLinkByObject(data?._extra?.collection?.user);
          this.isUserLinkNamed = this.userLink.indexOf('http') !== -1;
        }
      });

    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams
    ]).pipe(untilDestroyed(this))
      .subscribe(([params, queryParams]) => {
        this.staticService.routeQueryParams = queryParams;
        this.staticService.routeParams = params;
        this.getRouteInitData();
      });

    this.userCollectionService.isErrorCollection$
      .pipe(untilDestroyed(this))
      .subscribe((isError: boolean) => {
        if (isError && this.staticService.userInfo) {
          this.staticService.getErrorMessage();
          this.staticService.pageSimilar = 0;
          this.staticService.pageCount = 0;
          this.staticService.getSimilarPlaces();
        }
      });

    if (this.staticService.placesData) {
      this.staticContentService.$data.next(this.staticService.placesData);
      this.staticContentService.processData(this.staticService.placesData);
    }
  }

  ngOnChanges(changes: any) {
    if (changes['searchData'] && changes['searchData'].currentValue) {
      this.staticService.searchData = changes['searchData'].currentValue
    }
  }

  public getRouteInitData(): void {
    this.staticService.blockQueryParamsRequest = true;
    this.staticService.isInit = true;
    this.staticService.isMainInfoLoaded = false;

    switch (this.staticService.staticType) {
      case StaticRouteType.Experiences:
      case StaticRouteType.CategoryCity:
      case StaticRouteType.TagCity:
      case StaticRouteType.CategoryCountry:
        this.staticContentService.getSubStaticPagesInfo(
          this.staticService.routeParams.name,
          this.staticService.routeParams.id,
          this.staticService.routeParams.tagId
        );
        break;
      case StaticRouteType.ThingsToDo:
        this.staticContentService.getSubStaticPagesInfo(this.staticService.routeParams.name, this.staticService.routeParams.id);
        break;
      case StaticRouteType.Search:
        this.staticService.pageCount = 0;
        this.staticContentService.retrieveSearchData();
        break;
      case StaticRouteType.Travel:
        this.staticService.pageCount = 0;
        this.staticContentService.getStaticTypesData(
          this.staticService.routeParams.id,
          this.staticService.routeParams.typeId,
          true
        );
        break;
      case StaticRouteType.Journey:
        this.staticService.pageCount = 0;
        this.staticContentService.getStaticCollectionData(this.staticService.routeParams.id);
        break;
      default:
        break;
    }
  }

  public getPageName(): string {
    let name = '';
    if (!this.data) {
      return name;
    }

    if (this.staticService.staticType !== StaticRouteType.Search
      && this.staticService.staticType !== StaticRouteType.Travel
      && this.staticService.staticType !== StaticRouteType.Journey) {

      const placeName = this?.staticContentService?.info?.name
        ? this.staticContentService.info.name
        : this.staticContentService?.info?.place?.name;

      if (this.staticService.staticType === StaticRouteType.ThingsToDo) {
        name = `Things to do in ${placeName}`;
      } else if (
        this.staticService.staticType === StaticRouteType.TagCity
        || this.staticService.staticType === StaticRouteType.Experiences
        || this.staticService.staticType === StaticRouteType.CategoryCountry
        || this.staticService.staticType === StaticRouteType.CategoryCity
      ) {
        name = (this.staticContentService.info && this.staticContentService.info.categoryName || '') + `, ${placeName}`;
      } else {
        name = (this.staticContentService.info && this.staticContentService.info.categoryName) + ` ${placeName}`;
      }
    } else if (this.staticService.staticType === StaticRouteType.Journey) {
      const userName = ' by ' + getUserNameByObject(this.data._extra.collection?.user);
      name = this.data?._extra?.collection?.name || (DEFAULT_TITLE_JOURNEY + userName);
      const queryParams = this.activatedRoute.snapshot.queryParams;
      if (queryParams) {
        let comaCount = 0;

        if (queryParams.tagId || queryParams.cityId || queryParams.countryId) {
          name += ` - `;
        }

        if (queryParams.tagId) {
          name += comaCount > 0 ? ', ' : '';
          comaCount++;
          if (this.data._extra.tag) {
            name += `${this.data._extra.tag.name}`
          } else if (this.data._extra.tags) {
            name += `${this.data._extra.tags.find(tag => tag.id === Number(queryParams.tagId)).name}`;
          }
        }
        if (queryParams.cityId) {
          name += comaCount > 0 ? ', ' : '';
          comaCount++;
          if (this.data._extra.city) {
            name += `${this.data._extra.city.name}, ${this.data._extra.city.country.name}`;
          } else if (this.data._extra.cities) {
            name += `${this.data._extra.cities.find(city => city.id === Number(queryParams.cityId))?.name},
          ${this.data._extra.cities.find(city => city.id === Number(queryParams.cityId))?.country?.name}`;
          }
        } else if (queryParams.countryId) {
          name += comaCount > 0 ? ', ' : '';
          comaCount++;
          if (this.data._extra.city && this.data._extra?.city?.country) {
            name += `${this.data._extra.city?.country?.name}, ${this.data._extra.city.country?.country?.name}`;
          } else if (this.data._extra.countries) {
            const data = this.data._extra.countries.find(country => country.id.toLowerCase() === queryParams.countryId.toLowerCase())?.name;
            name += !!data ? `${data}` : '';
          }
        }
      }
    } else {
      name = this.getPageNameForSearchAndTravelPage();
    }
    return name;
  }

  private getPageNameForSearchAndTravelPage(): string {
    let name = '';

    if (this.data && this.data._extra) {
      const extra = this.data._extra;

      if (extra.country) {
        name = extra.country.name;
      }
      if (extra.city) {
        name = extra.city.name;
      }
      if (extra.tag) {
        name = extra.tag.name;
      }
      if (extra.dest) {
        name = extra.dest.name;
      }
      if (extra.poi) {
        name = extra.poi.name;
      }
      if (extra.city && extra.poi) {
        name = `${extra.poi.name} - ${extra.city.name}`;
      } else if (extra.tag && extra.poi) {
        name = `${extra.poi.name} - ${extra.tag.name}`;
      }
      if (this.staticService.placesData?.typeTravelStatic === DestinationType.AllPlaceCity) {
        name = `${extra.poi && extra.poi.name} in ${extra.city && extra.city.name}`;
      }
      if (this.staticService.placesData?.typeTravelStatic === DestinationType.AllPlaceCountry) {
        name = `${extra.poi && extra.poi.name} in ${extra.country && extra.country.name}`;
      }
      if (!name && this.staticService.staticType === StaticRouteType.Search) {
        const searchText = this.staticService.routeQueryParams['q']
        if (searchText) {
          name = searchText;
        }
      }
    }

    return name;
  }

  public clickCategoryEmit(event) {
    this.clickCategory.emit(event);
  }

  public isMobileDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile() || this.windowRef.getNativeWindow().innerWidth <= 896;
  }

  public isCountryFilterVisible(): boolean {
    return (
        [
          DestinationType.AllPlaceCity,
          DestinationType.AllPlaceCountry,
        ].indexOf(this.staticService.placesData?.typeTravelStatic) === -1
        &&
        [
          StaticRouteType.Experiences,
          StaticRouteType.Journey,
          StaticRouteType.TagCity,
        ].indexOf(this.staticType) === -1
        && this.staticContentService.countries.length > 0
      ) ||
      (
        this.staticType === StaticRouteType.Journey
        && this.staticContentService.countries && this.staticContentService.countries.length > 1
      )
  }

  public pinPlace(event: PlaceNew): void {
    this.pinPlaceEmitter.emit(event);
  }

  public getCollectionForPin() {
    return {...this.data?._extra?.collection, places: this.data?.items}
  }
}
