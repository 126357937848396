import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { GeoPointDataResponse } from '../interfaces';

@Injectable()
export class SearchService {

  public banRequest = false;
  public responseCache = new Map();
  public searchTrigger$ = new Subject<void>();

  constructor(private httpService: HttpService) {
  }

  public static getUniqueId(endpoint: string, params?: any): string {
    return (endpoint + JSON.stringify(params)).toLocaleLowerCase();
  }

  public searchCity(params?: any) {
    const uniqueCacheId: string = SearchService.getUniqueId(`search/city`, params);
    const fromCache = this.responseCache.get(uniqueCacheId);
    if (fromCache) {
      return of(fromCache);
    }
    return this.httpService.get(`search/city`, params).pipe(
      tap((data: any) => this.responseCache.set(uniqueCacheId, data))
    );
  }

  public searchDest(params?: any): Observable<GeoPointDataResponse[]> {
    const uniqueCacheId: string = SearchService.getUniqueId(`search/dest`, params);
    const fromCache = this.responseCache.get(uniqueCacheId);
    if (fromCache) {
      return of(fromCache);
    }
    return this.httpService.get(`search/dest`, params).pipe(
      tap((data: any) => this.responseCache.set(uniqueCacheId, data))
    );
  }
}
