export const setDisableZoomBtn = function (titleOfZoomControl?) {
  const classOfControl = 'gm-control-active';
  const buttons = document.getElementsByClassName(classOfControl);
  for (let i = 0; i < buttons.length; i++) {
    const btn = buttons.item(i);
    if (btn.getAttribute('title').indexOf(titleOfZoomControl) >= 0) {
      btn.className = classOfControl + ' disable';
    } else {
      btn.className = classOfControl;
    }
  }
};
