import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from './http.service';
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  photoAccept = '.png, .jpg, .jpeg, .webp';
  videoAccept = 'video/mp4,video/quicktime,.mov';

  constructor(
    private http: HttpService,
  ) {
  }

  public createPlace(data: any, httpParams: HttpParams): Observable<any> {
    return this.http.post(`info/place`, data, httpParams);
  }

  public updatePlace(id: string, data: any, httpParams: HttpParams): Observable<any> {
    return this.http.put(`info/place/${id}`, data, httpParams);
  }

  public createReview(data: any, httpQueryParams?: HttpParams): Observable<any> {
    return this.http.post(`info/place-review`, data, httpQueryParams);
  }
}
