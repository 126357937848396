<div class="month-selectors">
  <div (click)="offsetDate(-1)" class="arrow prev"></div>
  <div class="year">{{title}}</div>
  <div (click)="offsetDate(1)" class="arrow next"></div>
</div>
<div class="month">
  <div class="week titles">
    <div class="day">Su</div>
    <div class="day">Mo</div>
    <div class="day">Tu</div>
    <div class="day">We</div>
    <div class="day">Th</div>
    <div class="day">Fr</div>
    <div class="day">Sa</div>
  </div>
  <div class="week"
       [class.large]="large"
       *ngFor="let week of weeksLine">
    <div class="day"
         *ngFor="let day of week"
         [ngClass]="{
         'selected':day?.selected,
         'today':day?.isToday,
         'isBefore': minDateLimit && day?.isBefore || maxDateLimit && day?.isAfter || !day,
         'inInterval':day?.isInInterval,
         'isAfterToday':day?.isAfterToday,
         }"
         (click)="selectDate(day)">
      {{day?.title}}
    </div>
  </div>
</div>
