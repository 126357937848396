import { MapActionsTypes, MapActionsUnion } from './map.actions';

export interface State {
  items: any[];
  bounds: google.maps.LatLngBounds | null;
  visibleCountries: string[];
  markers: any[];
  infoWindow: {
    display: boolean;
    base: any[];
    exclude: number[];
    users: any[];
  };
  zoom: number;
}

const initialState: State = {
  items: [],
  bounds: null,
  visibleCountries: [],
  markers: [],
  infoWindow: {
    display: true,
    base: [],
    exclude: [],
    users: []
  },
  zoom: null
};

export function reducer(state: State = initialState, action: MapActionsUnion) {
  switch (action.type) {
    case MapActionsTypes.SetInfoWindowVisible:
      return {
        ...state,
        infoWindow: {
          ...state.infoWindow,
          display: action.payload
        }
      };
    case MapActionsTypes.ExcludeInfoWindows:
      return {
        ...state,
        infoWindow: {
          ...state.infoWindow,
          exclude: action.payload.id.reduce((acc, obj) => {
            if (!acc.includes(obj)) {
              acc.push(obj);
            }
            return acc;
          }, [...state.infoWindow.exclude])
        }
      };
    case MapActionsTypes.RemoveUserInfoWindows:
      return {
        ...state,
        infoWindow: {
          ...state.infoWindow,
          users: state.infoWindow.users.filter(city => city[0].id !== action.payload.id)
        }
      };
    case MapActionsTypes.AddUserInfoWindows:
      return {
        ...state,
        infoWindow: {
          ...state.infoWindow,
          users: [...state.infoWindow.users, [action.payload]]
        }
      };
    case MapActionsTypes.SetInfoWindows:
      return {
        ...state,
        infoWindow: {
          ...state.infoWindow,
          base: action.payload
        }
      };
    case MapActionsTypes.SetMarkers:
      return {
        ...state,
        markers: action.payload.markers
      };
    case MapActionsTypes.SetZoom:
      return {
        ...state,
        zoom: action.payload.zoom
      };

    case MapActionsTypes.SetNewBounds:
      return {
        ...state,
        bounds: action.payload,
      };

    case MapActionsTypes.SetVisibleCountries:
      return {
        ...state,
        visibleCountries: action.payload,
      };
    default:
      return state;
  }
}

export const _getVisibleCountries = (state: State) => state.visibleCountries;
