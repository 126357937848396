import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trip-passenger-selector',
  templateUrl: './trip-passenger-selector.component.html',
  styleUrls: ['./trip-passenger-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripPassengerSelectorComponent implements OnInit, OnChanges {

  @Output() changeEmitter = new EventEmitter<Number>();
  @Input() value: any | number = 1;

  public title = '1 passenger';

  constructor() {
  }

  ngOnInit(): void {
    this.title = `${this.value} passenger`;
  }

  updateValue(modifier: number) {
    if (modifier === -1) {
      if (this.value <= 1) {
        this.value = 1;
      } else {
        this.value--;
      }
    }
    if (modifier === 1 && this.value < 9) {
      this.value++;
    }
    this.title = `${this.value} passenger`;
    this.changeEmitter.next(this.value);
  }

  ngOnChanges(changes): void {
    this.value = changes.value.currentValue;
    this.title = `${this.value} passenger`;
  }

}
