import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile-notification-settings',
  templateUrl: 'profile-notification-settings.component.html',
  styleUrls: ['profile-notification-settings.component.scss']
})
export class ProfileNotificationSettingsComponent implements OnChanges {
  @Input() data: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();

  protected settings: Array<any> = [
    {
      name: 'followerNotificationsEmail',
      label: 'Following',
      description: 'These notifications alert you when someone you are following adds new content',
    },
    {
      name: 'promoNotificationsEmail',
      label: 'Promotional',
      description: 'These notifications about promotional content and offerings',
    }
  ];
  protected mappedData: any = {};

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.settings.forEach(item => {
        this.mappedData[item.name] = this.data.find((setting: any) => setting.id === item.name)?.value;
      });
    }
  }

  public toggleSetting(name: string, value: number): void {
    const setting = {};
    setting[name] = +!value;
    this.changeSettings.emit(setting);
  }
}
