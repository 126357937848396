import { DestinationType } from '../enums/destination-type';
import get from 'lodash/get';

export const getPinCityValue = (o: any, k: string) => {
  let res;
  switch (o.otype) {
    case DestinationType.City:
      res = get(o.city, k, null);
      break;
    case DestinationType.Place:
      res = get(o.place.city, k, null);
      break;
    case DestinationType.Restaurant:
      res = get(o.restaurant && o.restaurant.city, k, null);
      break;
    case DestinationType.Country:
      res = get(o, k, null);
      break;
  }
  return res;
};

export const getPinObjectValue = (o: any, k: string) => {
  let res;
  switch (o.otype) {
    case DestinationType.City:
      res = get(o.city, k, null);
      break;
    case DestinationType.Place:
      res = get(o.place, k, null);
      break;
    case DestinationType.Restaurant:
      res = get(o.restaurant, k, null);
      break;
    case DestinationType.Journey:
      res = get(o.collectionPinned, k, null);
      break;
  }
  return res;
};
