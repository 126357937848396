import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { getCurrentCoordinates, getHomeData, State } from '../../../store/reducers';
import { ICity } from '../your-own-place/your-own-place.component';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { Observable } from 'rxjs';
import { SearchService, SsrService } from '../../../services';
import { HttpParams } from '@angular/common/http';
import { consoleDebug, showMatch } from '../../../libraries';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import { HelperService } from "../../../services/helper.service";
import { AjaxFinish, AjaxStart } from "../../../store/layer";
import dayjs from "dayjs";
import random from "lodash/random";

declare var google;

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() addressType: string;
  @Input() city: ICity;
  @Input() lat: number;
  @Input() lng: number;
  @Input() selectCountry: any;
  @Input() sessionToken: string;
  @Input() inputHeadingText: string;
  @Input() inputHelpText: string;
  @Input() isGeoPositionEnabled: boolean;
  @Input() mapHeadingText: string;
  @Input() mapHelpText: string;
  @Input() cannotFindCity: boolean;
  @Input() withMap = true;
  @Input() error: string;
  @Input() isSetMarker: boolean;
  @Input() isSetPlace: boolean;
  @Input() cannotFindInput: boolean;
  @Input() showCannotFindCheckbox: boolean = true;

  @Output() selectAutocompletePlace: EventEmitter<any> = new EventEmitter();
  @Output() selectGoogleCity: EventEmitter<any> = new EventEmitter();
  @Output() cannotFindCityEmit: EventEmitter<any> = new EventEmitter();
  @Output() setMarkerEmit: EventEmitter<boolean> = new EventEmitter();
  @Output() setPlaceEmit: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('addressText', {static: true}) addressText: any;
  @ViewChild('customAddressText', {static: true}) customAddressText: any;

  public auto = null;
  public autocomplete: any;
  public currentCountryCode: string;
  public listItems: AutocompletePrediction[] = [];
  public map: any;
  public mapApiLoaded$: Observable<boolean>;
  public marker: any;
  public zoomLevel = 14;
  public zoomDefault = 11;
  public currentUserPosition: { lat?: number, lng?: number } = {};

  private $destroyed = new Subject<void>();
  private mapClickListener: any;
  public countryCtrl = new UntypedFormControl('');
  public googleCountryCtrl = new UntypedFormControl('');
  public home: any;
  public defaultCityPlaceHolder = 'Sydney, Australia';
  public showAutocomplete = false;
  public destList: any;

  constructor(
    private readonly googleMapsService: GoogleMapsService,
    private store$: Store<State>,
    private ssrService: SsrService,
    private zone: NgZone,
    private searchService: SearchService,
    private helperService: HelperService,
  ) {
    if (this.withMap && this.isGeoPositionEnabled) {
      this.getCurrentCoordinates();
    }
    this.mapApiLoaded$ = this.googleMapsService.isApiLoaded$;
  }

  ngOnInit(): void {
    this.countryCtrl.valueChanges
      .pipe(
        takeUntil(this.$destroyed),
        debounceTime(500),
        map(value => {
          if (!value) {
            this.selectGoogleCity.emit(null);
          }
          return value;
        }),
        filter((newValue: string) => !!newValue && newValue.length >= 2)
      )
      .subscribe((newValue: string) => {
        this.searchCity(newValue);
      });

    this.googleCountryCtrl.valueChanges
      .pipe(
        takeUntil(this.$destroyed),
        debounceTime(500),
        map(value => {
          if (!value) {
            this.selectAutocompletePlace.emit(null);
          }
          return value;
        }),
        filter((newValue: string) => (!!newValue && newValue.length >= 2)),
        takeUntil(this.$destroyed)
      ).subscribe(val => {
      this.getPlaceAutocomplete(val);
    });
    if (this.isGeoPositionEnabled) {
      this.detectGeoPosition();
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.cannotFindCity?.currentValue && changes?.city?.currentValue) {
      this.googleCountryCtrl.setValue(changes.city.currentValue?.name);
    }
    if (changes.selectCountry && changes.selectCountry.currentValue) {
      this.mapApiLoaded$
        .pipe(
          filter((value: boolean) => value),
          take(1)
        )
        .subscribe(() => {
          if (this.autocomplete && this.autocomplete.setComponentRestrictions) {
            this.autocomplete.setComponentRestrictions({country: this.selectCountry});
          }
        });
    }
    if (this.withMap) {
      if (changes?.lat?.currentValue && changes?.lng?.currentValue) {
        const latLng = new google.maps.LatLng(changes.lat.currentValue, changes.lng.currentValue);
        this.placeMarkerAndPanTo(latLng);
      }

      if (changes.city && changes.city.currentValue) {
        this.getSelectCity(changes.city.currentValue);
      }
    }
  }

  protected getSelectCity(city: ICity): void {
    const latLng = new google.maps.LatLng(city.lat, city.lng);
    this.countryCtrl.patchValue({name: city.name});
    this.placeMarkerAndPanTo(latLng);
  }

  public getCurrentCoordinates(): void {
    this.store$.pipe(
      select(getCurrentCoordinates),
      takeUntil(this.$destroyed)
    ).subscribe((result) => {
      this.lat = result.lat;
      this.lng = result.lng;
      this.setImageToCurrentPositionBtn();
    });
    this.store$.pipe(
      select(getHomeData),
      takeUntil(this.$destroyed)
    ).subscribe((result) => {
      this.home = result;
      this.currentCountryCode = result.countryCode;
    });
  }

  public placeMarkerAndPanTo(latLng): void {
    this.marker && this.marker.setPosition(latLng);
    this.map && this.map.panTo(latLng);
  }

  public getPlaceAutocomplete(value: string): void {
    this.mapApiLoaded$
      .pipe(
        filter((value: boolean) => value),
        take(1)
      )
      .subscribe(() => {
        if (value) {
          const autocompleteService = new google.maps.places.AutocompleteService();
          autocompleteService.getPlacePredictions(
            {
              input: value,
              sessionToken: this.sessionToken,
              types: [this.addressType],
            },
            (listItems) => {
              this.listItems = listItems;
            }
          );
          this.autocomplete = autocompleteService;
        } else {
          this.listItems = [];
        }
      });
  }

  public selectDataPlace(data: any): void {
    const name = data.option.value;
    const place = this.listItems.filter(item => item.description === name) as any;
    new google.maps.places.PlacesService(this.addressText?.nativeElement)
      .getDetails(
        {
          placeId: place[0].place_id,
          sessionToken: this.sessionToken
        },
        (result) => {
          const googleCityId = result.place_id;

          this.lat = result.geometry.location.lat();
          this.lng = result.geometry.location.lng();
          this.setPlaceEmit.emit(true);

          this.city = {
            name: result.formatted_address,
            lat: this.lat,
            lng: this.lng
          };
          if (this.withMap) {
            this.countryCtrl.patchValue(this.city.name);
          }

          this.selectGoogleCity.emit({googleCityId, lat: this.lat, lng: this.lng, name: this.city.name});
          this.addressText?.nativeElement?.blur();
        }
      );
  }

  onChangeCoordinates(event: any) {
    this.placeMarkerAndPanTo(event.latLng);
    this.setMarkerEmit.emit(true);
    this.lat = event.latLng.lat();
    this.lng = event.latLng.lng();
    this.setImageToCurrentPositionBtn();
    this.getReverseGeocodingData(this.lat, this.lng);
  }

  getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'latLng': latlng}, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const result = results.find(item => item.types.some(x => x === 'locality'));
        const googleCityId = result?.place_id;
        const value = result?.address_components;
        let address = [this?.city?.name] || [];
        if (!this.isSetPlace) {
          address = [];
          value?.forEach(val => {
            val.types.includes('locality') ? address.unshift(val.long_name) : false;
            val.types.includes('country') ? address.push(val.long_name) : false;
          });
        }
        this.city = {
          googleCityId,
          name: address.join(', '),
          lat,
          lng
        };

        this.googleCountryCtrl.patchValue(this.city.name);
        this.selectAutocompletePlace.emit(this.city);
      }
    });
  }

  mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.currentPositionBtn(map);
    this.mapClickListener = this.map.addListener('click', (e) => {
      this.zone.run(() => {
        this.onChangeCoordinates(e);
      });
    });
  }

  currentPositionBtn(map: google.maps.Map) {
    if (this.ssrService.isBrowser()) {
      const locationButtonContainer = document.createElement('div');
      locationButtonContainer.classList.add('custom-map-control-button-container');

      const locationButton = document.createElement('button');
      locationButton.textContent = 'Your location';
      locationButton.classList.add('custom-map-control-button');

      const locationIcon = document.createElement('img');
      locationIcon.src = this.getImageToCurrentPositionBtn();
      locationIcon.alt = 'current location icon';

      locationButtonContainer.appendChild(locationButton);
      locationButtonContainer.appendChild(locationIcon);

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(locationButtonContainer);
      locationButtonContainer.addEventListener('click', () => this.detectGeoPosition());
    }
  }

  private detectGeoPosition() {
    if (navigator.geolocation) {
      const uid = dayjs().unix() + ',' + random(0, 1000);
      this.store$.dispatch(new AjaxStart({uid}));
      this.helperService.ajaxLoaderVisible$.next(true);
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          this.store$.dispatch(new AjaxFinish({uid}));
          this.helperService.ajaxLoaderVisible$.next(false);
          this.setMarkerEmit.emit(true);
          this.lat = this.currentUserPosition.lat = position.coords.latitude;
          this.lng = this.currentUserPosition.lng = position.coords.longitude;
          this.getReverseGeocodingData(this.lat, this.lng);
          this.setImageToCurrentPositionBtn();
          this.map.setZoom(this.zoomLevel);
          this.map.setCenter(
            {
              lat: this.lat,
              lng: this.lng
            });
        },
        () => {
          this.store$.dispatch(new AjaxFinish({uid}));
          this.helperService.ajaxLoaderVisible$.next(false);
        }
      );
    }
  }

  checkCurrentPosition() {
    if (this.currentUserPosition && this.currentUserPosition.lat && this.currentUserPosition.lng) {
      return this.lat === this.currentUserPosition.lat && this.lng === this.currentUserPosition.lng;
    }
    return false;
  }

  setImageToCurrentPositionBtn() {
    if (this.ssrService.isBrowser()) {
      const locationButtonContainer = document.getElementsByClassName('custom-map-control-button-container');
      let locationIcons = null;
      if (locationButtonContainer && locationButtonContainer[0]) {
        locationIcons = locationButtonContainer[0].getElementsByTagName('img');
      }
      if (locationIcons && locationIcons[0]) {
        locationIcons[0].src = this.getImageToCurrentPositionBtn();
      }
    }
  }

  getImageToCurrentPositionBtn(): string {
    let imagePath = '';
    if (this.ssrService.isBrowser()) {
      const locationButtonContainer = document.getElementsByClassName('custom-map-control-button-container');
      const isLocationButtonContainerExist = locationButtonContainer && locationButtonContainer[0] && locationButtonContainer.length;

      if (this.checkCurrentPosition()) {
        imagePath = './assets/icons/current-location-blue.svg';
        if (isLocationButtonContainerExist && (!locationButtonContainer[0].classList.contains('mini-design'))) {
          locationButtonContainer[0].classList.add('mini-design');
        }
      } else {
        imagePath = './assets/icons/current-location-gray.svg';
      }
    }
    return imagePath;
  }

  public displayFn(value: any): string {
    return value && value.name ? value.name : '';
  }

  public searchCity(v: string): void {
    if (v.length >= 2) {
      const httpParams = new HttpParams()
        .append('expand', 'country')
        .append('filter[name]', v);
      this.searchService.searchCity(httpParams)
        .pipe(
          takeUntil(this.$destroyed),
        )
        .subscribe(
          success => {
            this.showAutocomplete = true;
            if (!success || success.length === 0) {
              this.destList = [];
            } else {
              this.destList = showMatch(success, v);
            }
          },
          error => {
            consoleDebug('error dest-search-field => 325', error);
          }
        );
    } else {
      this.destList = [];
      this.showAutocomplete = true;
    }
  }

  public getSelectCountry(countryData: any): void {
    if (countryData && countryData.option && countryData.option.value) {
      const city = countryData?.option?.value?.place;
      if (city?.country) {
        const cityCountryName = city.name + ', ' + city.country.name;

        if (!this.isSetMarker) {
          this.lat = city.lat;
          this.lng = city.lng;
          this.map.setZoom(this.zoomDefault);
        }
        this.selectAutocompletePlace.emit({
          cityId: city.id,
          name: cityCountryName,
          lat: this.lat,
          lng: this.lng
        });
        this.googleCountryCtrl.setValue({name: cityCountryName});
      }
      this.customAddressText?.nativeElement?.blur();
    }
  }

  public showAutoSelectGoogle(value) {
    this.cannotFindCity = value;
    this.cannotFindCityEmit.emit(value);
    this.googleCountryCtrl.patchValue('');
    this.countryCtrl.patchValue('');
    this.selectAutocompletePlace.emit(null);
    this.selectGoogleCity.emit(null);
  }
}
