import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { take, takeUntil } from 'rxjs/operators';
import { getHomeData, getNotificationList, getSearchData, State } from '../../../../store/reducers';
import { FlyCookieUpdateDataService, searchData, SsrService, UserService } from '../../../../services';
import { Subject } from "rxjs/internal/Subject";
import { environment } from "../../../../../environments/environment";
import { CONTACT_US_ACTION, CONTACT_US_LINKS } from "../../../../enums/contact-us-action";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { NotificationList } from "../../../../interfaces/notifications";
import { constructBaseUrl, constructMediaURL } from "../../../../libraries";
import { SearchStateForm } from "../../../../interfaces";

@Component({
  selector: 'app-static-burger-menu',
  templateUrl: './static-burger-menu.component.html',
  styleUrls: [
    '../../../user-account/components/user-sidebar/user-sidebar.component.scss',
    './static-burger-menu.component.scss'
  ],
  providers: [FlyCookieUpdateDataService]
})
export class StaticBurgerMenuComponent implements OnInit, OnDestroy {

  @Input() href = '';
  @Input() isFlightSettingEnabled = false;
  @Input() month;
  @Input() position;
  @Input() searchMode;
  @Input() selectedCity;
  @Input() title;
  @Input() isUserLogged;

  @Output() clearFilters: EventEmitter<void> = new EventEmitter();
  @Output() openMenu: EventEmitter<boolean> = new EventEmitter();
  @Output() openPinned = new EventEmitter<void>();

  public burgerLinks = [
    {
      name: 'Cookie Policy',
      url: '/cookie-policy'
    },
    {
      name: 'Term Of Use',
      url: '/terms-of-use'
    },
    {
      name: 'Privacy Policy',
      url: '/privacy-policy'
    }
  ];
  public burgerSubLinks = [
    {
      name: 'Site Map',
      url: '/site-map'
    },
    {
      name: 'List Of Cities',
      url: '/list-cities'
    },
  ];
  public contactUsOpened: string;
  public isOpenSettingsPanel = false;
  public toggleMenu = false;
  public homeData: any;
  public userInfo: any;
  public mediaUrl = environment.mediaUrl;
  public contactContentOpened = false;
  public contactUsLinks = CONTACT_US_LINKS;
  public contactType = CONTACT_US_ACTION.general_enquiries;
  public isNotificationContentVisible = false

  protected newNotifications: NotificationList;

  private settingsPaneForm$: Observable<SearchStateForm>;
  private $destroyed = new Subject<void>();

  constructor(
    private readonly router: Router,
    private store$: Store<State>,
    private cookieUpdateDataService: FlyCookieUpdateDataService,
    public userService: UserService,
    protected ssrService: SsrService,
  ) {
    cookieUpdateDataService.cookieName = searchData;
    this.settingsPaneForm$ = this.store$.pipe(select(getSearchData));
  }

  ngOnInit(): void {
    this.isUserLogged = this.userService.loggedIn();
    this.getHomeData();

    this.store$.select(getNotificationList)
      .pipe(takeUntil(this.$destroyed))
      .subscribe((notifications: NotificationList) => {
        this.newNotifications = notifications;
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public onToggleMenu(value: boolean): void {
    if (this.isUserLogged) {
      this.toggleMenu = value;
      this.contactContentOpened = false;
      this.openMenu.emit(value);
      this.isNotificationContentVisible = false;
    }
  }

  public toggleSettingsFlyPanel(value: boolean): void {
    if (!value) {
      this.toggleMenu = false;
    }
    this.isOpenSettingsPanel = value;
  }

  save(): void {
    this.toggleSettingsFlyPanel(false);
    this.settingsPaneForm$.pipe(take(1)).subscribe((form: SearchStateForm) => {
      this.cookieUpdateDataService.updateDataCookie('cityFromData', form.cityFrom);
      this.cookieUpdateDataService.updateDataCookie('tripType', form.tripType);
      this.cookieUpdateDataService.updateDataCookie('date', form.date);
      this.cookieUpdateDataService.updateDataCookie('passenger', form.passengers);
    });
  }

  public getHomeData() {
    this.store$.select(getHomeData)
      .pipe(takeUntil(this.$destroyed))
      .subscribe((home) => {
        this.userInfo = home?.user;
        this.homeData = home;
      })
  }

  public toggleContactContent(value?: boolean): void {
    if (value !== undefined) {
      this.contactContentOpened = value;
    } else {
      this.contactContentOpened = !this.contactContentOpened;
    }
  }

  public toggleContactUs(item: any = null): void {
    this.contactUsOpened = item?.action || null;
    this.toggleMenu = false;
    this.toggleContactContent(false);
    if (item?.url) {
      this.ssrService.goToRoute(item?.url);
    }
  }

  public toggleNotificationContent(value?: boolean): void {
    if (value !== undefined) {
      this.isNotificationContentVisible = value;
    } else {
      this.isNotificationContentVisible = !this.isNotificationContentVisible;
    }
  }

  protected readonly getLinkByObject = getLinkByObject;

  getUserIcon(): string {
    if (this.isUserLogged && this.homeData?.user?.picture?.thumb) {
      return constructMediaURL(this.homeData?.user?.picture?.thumb);
    } else {
      return constructBaseUrl('/assets/icons/user-profile-new.svg');
    }
  }

  hasIcon(): boolean {
    return this.isUserLogged && !!this.homeData?.user?.picture?.thumb;
  }

  protected readonly constructBaseUrl = constructBaseUrl;
  protected readonly constructMediaURL = constructMediaURL;
}
