<app-static-content>
  <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
</app-static-content>

<ng-template #pageContainer>
  <div class="user-info__container">
    <div class="user-info__content-wrapper">
      <div class="user-info__about-info">
        <img class="user-info__image"
             alt="photo"
             [appBasesrc]="getUserAvatarByObject(userProfile)">

        <ng-container *ngIf="!staticService.isDeactivatedPage">
          <div class="user-name">
            <ng-container *ngTemplateOutlet="userNameContainer"></ng-container>
          </div>

          <div class="home-container" *ngIf="homeLocation.country">
            <img class="home-icon" alt="photo" [appBasesrc]="getCountryFlag(homeLocation.country)">
            <span
              class="user-info__home-text">{{ homeLocation.country?.shortName || homeLocation.country?.name }}</span>
          </div>

          <app-static-user-personal-info-follow
            *ngIf="userProfile && (!userIsLogged || userProfile.id !== userHome?.id)"
            [user]="userProfile"
            (toggleFollowEmitter)="toggleFollow($event)"
          ></app-static-user-personal-info-follow>

          <div class="user-info__counters">
            <div (click)="moveToPlaces()">
              <div class="numbers">{{ staticService.userPlacesLength && userProfile?.placeCreatedCount }}</div>
              <div>Place{{ staticService.userPlacesLength && userProfile?.placeCreatedCount === 1 ? '' : 's' }}</div>
            </div>
            <div (click)="moveToCollections()">
              <div class="numbers">{{ !isEmptyCollection && userProfile?.collectionCount || 0 }}</div>
              <div>Journey{{ userProfile?.collectionCount === 1 && !isEmptyCollection ? '' : 's' }}</div>
            </div>
            <div (click)="toggleFollowersDialog()" *ngIf="followers.length > 0">
              <div class="numbers">{{ followers.length }}</div>
              <div>Following</div>
            </div>
          </div>

          <div class="user-info__about-text" [innerHTML]="userProfile?.about"></div>
        </ng-container>
        <span class="user-info__name wrap-lines"
              *ngIf="staticService.isDeactivatedPage">{{ staticService.errorMessage }}</span>
      </div>

      <app-contact-info-display *ngIf="!staticService.isDeactivatedPage && userProfile?.contactInfo"
                                [contactInfo]="userProfile?.contactInfo"></app-contact-info-display>

    </div>

    <ng-container *ngIf="!staticService.isDeactivatedPage; else noPlaces">
      <div class="pinned__tabs" #content_tabs>
        <div class="pinned__tab-item"
             *ngFor="let tab of tabs"
             [ngClass]="{'active': this.selectedTab === tab.id}"
             (click)="setSelectedTab(tab.id)">
          {{ tab.name }}
        </div>
      </div>

      <ng-container *ngIf="this.selectedTab === tabsObj.listPlace.id">
        <div class="user-info__categories" *ngIf="placeFilterData?.countries?.length">
          <app-static-categories
            [categories]="placeFilterData.countries"
            [staticType]="staticRouteType.UserPersonalPage"
            [className]="'countries'"
            [pageType]="staticRouteType.Account"
            [mode]="categoryType.Countries"
            [visibilityDelay]="filterVisibilityDelay"
            [selectMode]="selectMode.Single"
          ></app-static-categories>
        </div>
        <div class="user-info__categories" *ngIf="placeFilterData?.cities?.length">
          <app-static-categories
            [categories]="placeFilterData.cities"
            [mode]="categoryType.City"
            [staticType]="staticRouteType.UserPersonalPage"
            [className]="'cities'"
            [pageType]="staticRouteType.Account"
            [visibilityDelay]="filterVisibilityDelay"
            [selectMode]="selectMode.Single"
          ></app-static-categories>
        </div>
        <div class="user-info__categories" *ngIf="placeFilterData?.tags?.length">
          <app-static-categories
            [categories]="placeFilterData.tags"
            [mode]="categoryType.Categories"
            [staticType]="staticRouteType.UserPersonalPage"
            [className]="'categories'"
            [visibilityDelay]="filterVisibilityDelay"
            [pageType]="staticRouteType.UserPersonalPage"
            [selectMode]="selectMode.Single"
          ></app-static-categories>
        </div>

        <div class="user-info__places-container"
             infinite-scroll
             [infiniteScrollDistance]="scrollDistance"
             [infiniteScrollUpDistance]="scrollUpDistance"
             [infiniteScrollThrottle]="throttle"
             (scrolled)="onScrollDown()">

          <div class="user-info__places static-grid-container"
               *ngIf="userProfile?.places.length">
            <app-static-place-item
              *ngFor="let item of userProfile?.places; last as last; index as index"
              [placeInfo]="item"
              [isLast]="last"
              [placeIndex]="index"
              (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)">
            </app-static-place-item>
          </div>
          <ng-container *ngTemplateOutlet="similarPlacesTemplate"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedTab === tabsObj.journeys.id
                         && ((userHome && userHome.id === userProfile.id) || !isEmptyCollection)">
        <app-pinned-panel-collection *ngIf="filteredCollections.items?.length; else noCollections"
                                     [collections]="filteredCollections.items"
                                     [withLink]="true"
        ></app-pinned-panel-collection>
      </ng-container>
    </ng-container>
  </div>
</ng-template>


<ng-template #noPlaces>
  <div class="user-info__places-container"
       infinite-scroll
       [infiniteScrollDistance]="scrollDistance"
       [infiniteScrollUpDistance]="scrollUpDistance"
       [infiniteScrollThrottle]="throttle"
       (scrolled)="onScrollDown()">
    <ng-container *ngTemplateOutlet="similarPlacesTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #similarPlacesTemplate>
  <div class="user-info__similar-places" *ngIf="staticService.similarPlaces.length">
    <div class="places-title">You may also be interested:</div>

    <div class="user-info__places static-grid-container">
      <app-static-place-item
        *ngFor="let item of staticService.similarPlaces; last as last; index as index"
        [placeInfo]="item"
        [isLast]="last"
        [placeIndex]="index"
        (changeVisibleTooltipPlaceEvent)="changeVisibleTooltipPlace($event)">
      </app-static-place-item>
    </div>
  </div>
</ng-template>

<ng-template #noCollections>
  <div class="pinned-desc" *ngIf="isCollectionLoad">
    <span>There are no any pinned places yet</span>
  </div>
</ng-template>

<ng-template #userNameContainer>
  <ng-container *ngIf="staticService.isDeactivatedPage; then deactivatedProfileContent;
    else activatedProfileContent"></ng-container>
  <ng-template #activatedProfileContent>
    <span class="user-info__name wrap-lines" *ngIf="userProfile">
      {{ getUserNameByObject(userProfile) }}
    </span>
  </ng-template>
  <ng-template #deactivatedProfileContent>
    <span class="user-info__name wrap-lines">{{ staticService.errorMessage }}</span>
  </ng-template>
</ng-template>

<div class="w-100 empty-container" *ngIf="isFollowersVisible && followers.length">
  <div class="w-100 empty-area">
    <app-close-btn (click)="toggleFollowersDialog()"></app-close-btn>

    <div class="followers__list wrap-list empty-content all-lines-height">
      <div *ngFor="let user of followers" class="mx-w-93">
        <a class="followers__item all-mode"
           *ngIf="!isUserDomain"
           [routerLink]="getLinkByObject(user)">
          <ng-container *ngTemplateOutlet="followerContainer; context: {user: user}"></ng-container>
        </a>
        <a class="followers__item all-mode"
           *ngIf="isUserDomain"
           [href]="constructBaseUrl(getLinkByObject(user))">
          <ng-container *ngTemplateOutlet="followerContainer; context: {user: user}"></ng-container>
        </a>
      </div>
    </div>

  </div>
</div>

<ng-template #followerContainer let-user="user">
  <div class="followers__text">
    <img class="followers__avatar" alt="avatar" [appBasesrc]="getUserAvatarByObject(user)"/>
    <span>{{ getUserNameByObject(user) }}</span>
  </div>
</ng-template>
