<div class="about__container">

  <div class="about__content">
    <div class="about__label">YOUR PAGE</div>
    <div class="about__item personal-link">
      <a class="link-to link-to-page"
         target="_blank"
         [href]="getUserLinkWithHash()">
        <span>{{ getUserLink() }}</span>
        <img [src]="'/assets/icons/icon-exit.png'" alt="exit">
      </a>
      <span class="description">
        This is the address of your Personal Page. You can preview how it appears on the website.
      </span>
    </div>
  </div>

  <div class="about__content" [ngClass]="{'disabled': isAccountPublicDisabled}">
    <div class="about__label">Publishing</div>

    <div class="about__item">
      <label class="about__privacy-policy">
        <input class="input-checkbox"
               [disabled]="isAccountPublicDisabled"
               type="checkbox"
               [(ngModel)]="isAccountPrivate"
               (ngModelChange)="changeAccountVisibility($event)"/>
        <span>Make my Personal Page private.</span>
      </label>
      <span class="description">
          Turning this ON means your <a
        [href]="getLinkByObject(data)"
        style="font-weight: bold;" target="_blank">Personal Page</a> will no longer be accessible to Explorow website visitors.
      </span>
    </div>
  </div>

  <div class="about__content" [ngClass]="{'disabled-inside': isUrlSettingDisabled()}">
    <div class="about__label">Web Address</div>
    <div class="about__item drop-down-user">
      <span class="description">
        Customize Your Web Address!
      </span>
      <span class="description">
        You can personalize your web address by using your Name instead of an ID number.
      </span>

      <ng-container *ngIf="!hasUrlSettingWarning(); else withoutName">

        <span class="description">
          <b>Important:</b> This change can only be made once and will be permanent.
        </span>

        <div class="about__text url-settings">
          <div *ngFor="let option of urlSettingOptions">
            <input type="radio"
                   [disabled]="isUrlSettingDisabled()"
                   name="urlSettings"
                   [value]="option"
                   [checked]="option.selected"
                   [id]="'setting_' + option.value"
                   (click)="selectUserSetting(option)"><label for="setting_{{option.value}}"> {{option.title}}</label>
          </div>
        </div>

        <div class="action-btn-place">
          <button class="btn-create" type="submit"
                  (click)="changeUrlSetting()"
                  [disabled]="isUrlSettingDisabled() || urlBtnDisabled">
            Yes, I agree to change my Web Address
          </button>
        </div>
      </ng-container>

      <ng-template #withoutName>
        <div class="error-text inside-block">
          This option is unavailable until you fill in the field Name within the section About You.
        </div>
      </ng-template>
    </div>
  </div>
</div>
