let $Promise = Promise;
let $setTimeout = setTimeout;
export const doAsync = (action, timeout: number = 0) => {
  if (timeout === 0) {
    $Promise.resolve().then(() => action());
    return null;
  } else {
    return $setTimeout(() => {
      action();
    }, timeout);
  }
};
