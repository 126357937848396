<div class="city">
  <a [href]="link" target="_blank">
    <figure *ngIf="city" class="crop-to-fit">
      <img [src]="formMediaUrl(city)" alt="" appSrcFallback="/assets/images/default.jpg">
    </figure>
    <footer>
      <!-- city -->
      <span>{{city.name}}</span>
      <!-- country -->
      <span>{{city?.country?.name}}</span>
    </footer>
  </a>
</div>
