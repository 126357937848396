<div class="main-container">
  <app-static-header
    [place]="showPlace"
    [data]="placesData"
    (openUserAccountEmitter)="openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <app-your-own-place
    *ngIf="(pageType === addDataTypesEnum.PlaceAdd || pageType === addDataTypesEnum.PlaceEdit)
    && !isInactive"
    [typeFormValue]="typeFormValue"
    (discard)="discard()"
    (removeTypeFormValue)="removeTypeFormValue()"
  ></app-your-own-place>

  <app-your-own-review
    *ngIf="pageType === addDataTypesEnum.ReviewAdd && !isInactive"
    (discard)="discard()"
  ></app-your-own-review>

  <div class="inactive-profile" *ngIf="isInactive">
    <div class="inactive-profile__container">
      <p>
        You’re only minutes away from adding a new place. Please, confirm your registration. Check your mailbox and
        click on the verification link in the email that we have just sent you.
      </p>

      <button class="btn-primary-link" [routerLink]="'/account'">
        Go to Your Account Settings
      </button>
    </div>
  </div>
</div>
