const $r = RegExp;

export const formLink = (route: string, params: any) => {
  let url = '';
  for (let i = 0; i < params.length; i++) {
    url += `/${encodePart(params[i])}`;
  }
  return `/${route}${url}`;
};

export const getLink = (data: any, defaultName?: string) => {
  let linkName = defaultName;
  if (data) {
    if (data.hasOwnProperty('urlKeywords') && data.urlKeywords) {
      linkName = data.urlKeywords;
    } else if (data.hasOwnProperty('cityUrlKeywords') && data.cityUrlKeywords) {
      linkName = data.cityUrlKeywords;
    } else if (data.hasOwnProperty('countryUrlKeywords') && data.countryUrlKeywords) {
      linkName = data.countryUrlKeywords;
    } else if (data.hasOwnProperty('tagUrlKeywords') && data.tagUrlKeywords) {
      linkName = data.tagUrlKeywords;
    }
  }
  return linkName;
};

const encodePart = (part) => {
  return encodeParam(encodeParam(part), '[\(\)`&’"\'\.]', '');
};

const encodeParam = (str: string | number = null, delimiter1: string = '[ \/\|\?\,]', delimiter2: string = '_') => {
  if (str) {
    return str.toString().replace(new $r('(,\\s{1})', 'ig'), '_').replace(new $r(delimiter1, 'ig'), delimiter2);
  }
  return str;
};

export const decodeParam = (str: string = null, delimiter1: string = '_', delimiter2: string = ' ') => {
  if (str) {
    return str.replace(new $r(delimiter1, 'ig'), delimiter2);
  }
  return str;
};
