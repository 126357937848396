<div class="fly-modal" *ngIf="title || label" (click)="close()">

  <section #tooltip
           [ngStyle]="{
      top: top,
      left: left,
      'font-size': fontSize
    }"
           [ngClass]="{
      'animate-scale': left,
      'animate-scale-backwards': backwards,
      'blue-style': blueStyle,
      'max-w-250px': !blueStyle
    }">
    <span *ngIf="label" [innerHTML]="label"></span>
    <span *ngIf="title"> {{title | translate}} </span>
    <span class="tooltip-triangle" *ngIf="!blueStyle"></span>
  </section>

</div>
