import type { DestinationType } from "../enums/destination-type";
import type { PictureShort } from "./picture";
import type { CityNew, Country, PlaceNew } from "./place";
import type { ContactInfo } from "./contact-info";

export type { UserProfile, UserPage, UserSetting };

export enum UserUrlSettings {
  Id = 1,
  Name = 2,
  Subdomain = 3,
}

export enum UserSettingsNames {
  IsAccountPublic = 'isAccountPublic',
  IsShowContactInfo = 'showContactInfo',
  UrlSetting = 'urlSetting',
}

export enum UserStatus {
  NotActivated = 0,
  Activated = 1,
  Disabled = 2,
}

interface UserProfile {
  id: string;
  nickName: string;
  urlKeywords: string;
  webSite: string;
  email: string;
  status: UserStatus;
  about: string;
  auth?: any;
  picture?: any;
  settings?: UserSetting[];
  homeCity?: CityNew;
  homeCityObject?: CityNew;
  urlSetting?: number;
  placeCreatedCount?: number;
  collectionCount?: number;
}

interface UserSetting {
  id: string;
  label: string;
  value: string | number;
  disabled: boolean;
}

interface UserPage {
  id: string;
  about: string;
  name: string;
  urlKeywords: string;
  otype: DestinationType.UserProfile;
  urlSetting?: number;
  picture?: PictureShort;
  homeCity?: CityNew;
  homeCityObject?: CityNew;
  placeCreatedCount?: number;
  collectionCount?: number;
  places?: PlaceNew[];
  contactInfo?: ContactInfo;
  cities?: CityNew[],
  countries?: Country[]
  followers?: UserPage[]
}
