import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user-token.service';
import { doAsync } from '../../libraries/do-async';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { select, Store } from '@ngrx/store';
import { getCurrentCoordinates, State } from '../../store/reducers';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'app-auth-reset',
  templateUrl: 'auth-reset.component.html',
  styleUrls: [
    '../user-account/components/profile-pass-change/profile-pass-change.component.scss',
    'auth-reset.component.scss'
  ]
})
export class AuthResetComponent implements OnInit {

  private token: any;

  public allowSubmit = true;
  public confNewPassUpdated = false;
  public generalError: any;
  public newPassUpdated = false;
  public passChangeForm;
  public passDoNotMatch: string = null;
  public password_changed = 'success.password_changed';
  public passwordChangedSuccessfully: any;
  public passwordsMatch = false;
  public passwordType = 'password';
  public resOfNewPasswordValidation: any;
  public resOfOldPasswordValidation: any;
  public shouldRemember: any = 'checked';
  public success: string;
  public toggle = true;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private store$: Store<State>,
  ) {
    this.passChangeForm = fb.group({
      newPass: ['', [Validators.minLength(6), Validators.required]],
      confNewPass: ['', [Validators.minLength(6), Validators.required]]
    }, {updateOn: 'blur'});
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.token = params.token);
  }

  resetPassword(signInForm: any) {
    if (!signInForm.valid) {
      return;
    }
    this.userService.disableSubmitForWhile(this, 1500);
    this.resetRezPassVal();
    this.store$.pipe(
      select(getCurrentCoordinates),
      take(1),
      map((coordinates) => {
        let params = {
          password: signInForm.value.newPass.trim(),
          ...coordinates
        };
        this.authService
          .authResetToken(this.token, params)
          .subscribe(
            (success: any) => {
              this.setRememberMe();
              this.userService.deleteCookiesOfToken();

              if (success) {
                this.userService.setToken(success);
              }
              doAsync(() => {
                this.allowSubmit = true;
                this.userService.navigate(['/account']);
              });
            },
            (err) => {
              this.allowSubmit = true;
              this.handleErrors(err);
              this.userService.deleteCookiesOfToken();
              doAsync(() => this.userService.navigate(['/']), 5000);
            }
          );

      }))
      .subscribe();
  }

  valueUpdated(propName) {
    this[`${propName}Updated`] = true;
  }

  validationStyling(formCtrl) {
    return this.passChangeForm.controls[formCtrl].valid;
  }

  validationStyles(formCtrl, resOfOldPasswordValidation: string = null) {
    if (formCtrl === 'oldPass' && resOfOldPasswordValidation) {
      return {'fly-pass-invalid': true};
    }
    if (formCtrl === 'oldPass' && !resOfOldPasswordValidation) {
      return null;
    }

    if (this.readVal('newPass') === this.readVal('confNewPass')) {
      this.passwordsMatch = true;
      if (this.passDoNotMatch) doAsync(() => {
        this.passDoNotMatch = null;
      });
    }

    if (formCtrl == 'confNewPass' && this.readVal('newPass') != this.readVal('confNewPass')) {
      this.passwordsMatch = false;
      if (!this.passDoNotMatch) doAsync(() => {
        this.passDoNotMatch = 'Passwords do not match';
      });
      return {'fly-pass-invalid': true};
    }

    return {
      'fly-pass-valid': this.validationStyling(formCtrl),
      'fly-pass-invalid': !this.validationStyling(formCtrl)
    };
  }

  readVal(formCtrlName) {
    return this.passChangeForm.controls[formCtrlName].value;
  }

  resetRezPassVal() {
    this.resOfOldPasswordValidation = null;
    this.resOfNewPasswordValidation = null;
  }

  handleErrors(err: any) {
    if (err.status == 201) {
      this.saveTranslationIntoProperty(this.password_changed, 'passwordChangedSuccessfully');

      return;
    }
    if (err.status > 300) {
      this.caseErrorMessage(err.error);
    }
  }

  caseErrorMessage(error: any) {
    let message = error.message;
    this.generalError = this.mapResponse(message);
  }

  setRememberMe() {
    this.userService.shouldRemember(this.shouldRemember);
  }

  mapResponse(message) {
    return [{value: message}];
  }

  changePasswordView() {
    this.passwordType = (this.passwordType == 'password') ? 'text' : 'password';
    this.toggle = !this.toggle;
  }

  rememberUser() {
    this.shouldRemember = this.shouldRemember ? null : 'checked';
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    // i18n
    this.userService.getTranslation(id).pipe(take(1)).subscribe(
      (res) => this[whereToSave] = res
    );
  }
}
