<div class="wrapper old-static-page">
  <app-static-header
  [data]="staticService.placesData"
  (openUserAccountEmitter)="staticService.openUserAccount($event)"
></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="copyright-container">
    <div class="content">
      <app-upload></app-upload>
    </div>
  </div>
  <app-footer></app-footer>
</div>

