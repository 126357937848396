export enum StaticRouteType {
  Place = 'place',
  Review = 'review',
  City = 'city',
  Country = 'country',
  Experiences = 'experiences',
  ThingsToDo = 'things-to-do',
  TagCity = 'tag-city',
  CategoryCity = 'category-city',
  CategoryCountry = 'category-country',
  Search = 'search',
  Travel = 'travel',
  UserPersonalPage = 'user-personal-page',
  UserJourneyPersonalPage = 'user-journey-personal-page',
  HomePage = 'home',
  Journey = 'journey',
  Account = 'account'
}
