import { Component, OnChanges } from '@angular/core';
import { DestSearchFieldComponent } from '../dest-search-field/dest-search-field.component';
import { environment } from "../../../../environments/environment";
import { constructMediaURL } from "../../../libraries";

@Component({
  selector: 'app-dest-search-field-simple',
  templateUrl: 'dest-search-field-simple.component.html',
  styleUrls: [
    '../../../styles/mat-styles.scss',
    '../dest-search-field/dest-search-field.component.scss'
  ]
})
export class DestSearchFieldSimpleComponent extends DestSearchFieldComponent implements OnChanges {
  public readonly mediaUrl = environment.mediaUrl;

  ngOnChanges(changes) {
    this.destList = this.ssrService?.isBrowser() && JSON.parse(localStorage.getItem('destArray'));
    if (changes['destTo'] && changes['destTo'].currentValue) {
      this.destFormControl.setValue(changes['destTo'].currentValue.name, {emitEvent: false});
    }
  }

  protected readonly constructMediaURL = constructMediaURL;
}
