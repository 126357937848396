import { REQUEST, RESPONSE } from '../../express.tokens';
import { Response } from 'express';
import { Inject, Injectable, Injector, makeStateKey, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Request } from 'express-serve-static-core';
import { Router } from '@angular/router';
import { DeviceType, getDeviceTypeByUA } from '../libraries/define-device-type';
import { constructBaseUrl } from "../libraries";

export enum TransferKeys {
  Status = 'STATUS'
}

const reservedDomains = [
  'www', 'test-www', 'test-www2', 'test-www3', 'localhost'
];

@Injectable()
export class SsrService {

  private deviceType: string;
  private readonly host: string;
  private readonly isSsr: boolean;
  private readonly isUserDomain: boolean;

  constructor(
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(Injector) private injector: Injector,
    @Optional() @Inject(REQUEST) protected request: Request,
    private router: Router
  ) {
    this.isSsr = !isPlatformBrowser(this.platformId);
    if (this.isSsr) {
      this.host = this.getRequest().get('host');
    }
    this.isUserDomain = this.getUserDomain() !== null;
  }

  public isSSR(): boolean {
    return this.isSsr;
  }

  public isBrowser(): boolean {
    return !this.isSsr;
  }

  public setState(key: string, value: any): void {
    this.state.set(makeStateKey(key), value);
  }

  public hasState(key: string): boolean {
    return this.state.hasKey(makeStateKey(key));
  }

  public getState(key: TransferKeys | string): any {
    return this.state.get(makeStateKey(key), null);
  }

  public removeState(key: TransferKeys | string): any {
    return this.state.remove(makeStateKey(key));
  }

  public setResponseStatusCode(code: number): this {
    if (this.isSsr) {
      const response = this.injector.get(RESPONSE) as Response;
      response.status(code);
      this.setState(TransferKeys.Status, code);
    }
    return this;
  }

  public redirect(code: number, url: string) {
    if (this.isSsr) {
      const response = this.injector.get(RESPONSE) as Response;
      response.redirect(code, url);
    } else {
      this.goToRoute(url);
    }
  }

  public getHost(): string {
    return this.host;
  }

  public getPlatformHost(): string {
    return this.isSsr ? (this.getRequest().headers['host'] || this.getRequest().hostname) : window.location.hostname;
  }

  public getRequest(): Request {
    return this.injector.get(REQUEST);
  }

  public getDeviceType() {
    if (this.request) {
      const serverRequestHeaders = this.request.headers;
      this.deviceType = serverRequestHeaders['cf-devicetype'] || getDeviceTypeByUA(serverRequestHeaders['user-agent']);
    }

    return this.deviceType;
  }

  public isMobile() {
    return this.getDeviceType() === DeviceType.Mobile;
  }

  public isTablet() {
    return this.getDeviceType() === DeviceType.Tablet;
  }

  public getUserDomain(): string|null {
    const host = this.getPlatformHost();
    const domainParts = host.split('.');
    if (domainParts.length !== 3) {
      return null;
    }
    if (reservedDomains.includes(domainParts[0])) {
      return null;
    }
    const notAllowed = /[()`&’"'.!“«:\/|,+]/;
    if (notAllowed.test(domainParts[0])) {
      return null;
    }

    return domainParts[0];
  }

  public getIsUserDomain(): boolean {
    return this.isUserDomain;
  }

  public goToRoute(url: string): void {
    if (this.isUserDomain || url.indexOf('http') !== -1) {
      window.location.href = constructBaseUrl(url);
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
