import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from "@ngrx/store";
import { UserPage } from "../../../../interfaces/user";
import { Unfollow } from "../../../../store/user-following/user-following.actions";
import { environment } from "../../../../../environments/environment";
import { getUserAvatarByObject } from "../../../../libraries/get-user-avatar";

@Component({
  selector: 'app-following-unfollow-panel',
  templateUrl: './following-panel-unfollow.component.html',
  styleUrls: ['./following-panel-unfollow.component.scss']
})
export class FollowingPanelUnfollowComponent {

  @Input() user: UserPage;
  @Output() closePopupEmit = new EventEmitter<any>();

  protected mediaUrl: string = environment.mediaUrl;
  protected readonly getUserAvatarByObject = getUserAvatarByObject;

  constructor(
    protected store$: Store,
  ) {
  }

  protected closePopup(): void {
    this.closePopupEmit.emit();
  }

  protected unfollow(): void {
    this.store$.dispatch(new Unfollow(this.user.id));
    this.closePopup();
  }
}
