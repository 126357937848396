import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

import { consoleDebug } from '../libraries/console-debug';
import * as fromUserCities from './user-cities/user-cities.reducer';
import * as fromPanels from './panels/panels.reducer';
import * as fromUserCollections from './user-collections/user-collections.reducer';
import * as fromUserFavorites from './user-likes/user-likes.reducer';
import * as fromUserFollowing from './user-following/user-following.reducer';
import * as fromUserNotifications from './user-notifications/user-notifications.reducer';
import * as fromMap from './map/map.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromLayer from './layer/layer.reducer';
import { PlacePinType, State as State1 } from '../interfaces';

export interface State {
  auth: fromAuth.AuthState;
  userCities: fromUserCities.State;
  userCollections: fromUserCollections.State;
  userFavorites: fromUserFavorites.State;
  userFollowing: fromUserFollowing.State;
  userNotifications: fromUserNotifications.State;
  map: fromMap.State;
  layer: State1;
  panels: fromPanels.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  userCities: fromUserCities.reducer,
  userCollections: fromUserCollections.reducer,
  userFavorites: fromUserFavorites.reducer,
  userFollowing: fromUserFollowing.reducer,
  userNotifications: fromUserNotifications.reducer,
  map: fromMap.reducer,
  layer: fromLayer.reducer,
  panels: fromPanels.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    consoleDebug('state', state);
    consoleDebug('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromUserCities.State>('userCities');
export const getCollectionState = createFeatureSelector<fromUserCollections.State>('userCollections');
export const getFavoritesState = createFeatureSelector<fromUserFavorites.State>('userFavorites');
export const getMapState = createFeatureSelector<fromMap.State>('map');
export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getLayerState = createFeatureSelector<State1>('layer');
export const getFollowingState = createFeatureSelector<fromUserFollowing.State>('userFollowing');
export const getNotificationState = createFeatureSelector<fromUserNotifications.State>('userNotifications');
export const getPanelsState = createFeatureSelector<fromPanels.State>('panels');

export const getCollectionList = createSelector(getCollectionState, fromUserCollections._getPinned);
export const getDefaultCollection = createSelector(getCollectionState, fromUserCollections._getDefaultPinned);
export const getFavoritesList = createSelector(getFavoritesState, fromUserFavorites._getFavoritesList);
export const getPinnedList = createSelector(getLayoutState, fromUserCities._getPinned);
export const getFollowedList = createSelector(getFollowingState, fromUserFollowing._getFollowedList);
export const getNotificationList = createSelector(getNotificationState, fromUserNotifications._getNotificationList);

export const isWannaGoPlace = (id: number, otype: number) => createSelector(getPinnedList, items => {
  const targetItem = items.find(item => Number(item.oid) === Number(id) && Number(item.otype) === Number(otype));
  if (!targetItem) {
    return false;
  }
  return ((targetItem._pinTypes || []).indexOf(PlacePinType.WannaGo) > -1);
});
export const isPinnedPanelVisible = createSelector(getPanelsState, fromPanels._isPinnedPanelVisible);
export const isFavoritePanelVisible = createSelector(getPanelsState, fromPanels._isFavoritePanelVisible);
export const isFollowingPanelVisible = createSelector(getPanelsState, fromPanels._isFollowingPanelVisible);
export const wasPlaceFormOpened = createSelector(getPanelsState, fromPanels._wasPlaceFormOpened);

// Maps reducers
export const getVisibleCountries = createSelector(getMapState, fromMap._getVisibleCountries);

// Auth reducers
export const getCurrentCoordinates = createSelector(getAuthState, fromAuth._getCurrentCoordinates);
export const getPopularState = createSelector(getAuthState, fromAuth._getPopularState);
export const loggedIn = createSelector(getAuthState, fromAuth._loggedIn);
export const getHomeData = createSelector(getAuthState, fromAuth._getHomeData);

// Layer reducers
export const getAvailableMMR = createSelector(getLayerState, fromLayer._getAvailableMMR);
export const getAjaxState = createSelector(getLayerState, fromLayer._getAjaxState);
export const getSearchData = createSelector(getLayerState, fromLayer._getSearchData);
export const getDate = createSelector(getLayerState, fromLayer._getDate);
export const getPassengers = createSelector(getLayerState, fromLayer._getPassengers);
export const getTripType = createSelector(getLayerState, fromLayer._getTripType);
export const getCityTo = createSelector(getLayerState, fromLayer._getCityTo);
export const getCityFrom = createSelector(getLayerState, fromLayer._getCityFrom);
export const searchUpdated = createSelector(getLayerState, fromLayer._searchUpdated);
