import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { getFollowedList, State } from "../reducers";
import { Injectable, OnDestroy } from "@angular/core";
import { SsrService, UserService } from "../../services";
import { Subject } from 'rxjs/internal/Subject';
import {
  Follow,
  LoadFollowed,
  RestoreFollowed,
  SuccessLoadFollowed,
  SynchronizeFollowed,
  Unfollow,
  UserFollowingActions,
  UserFollowingActionsType
} from "./user-following.actions";
import { HttpParams } from "@angular/common/http";
import { UserFollowingService } from "../../services/user-following.service";
import { doAsync } from "../../libraries";
import { FollowedList } from "../../interfaces/following";

export const NGX_USER_FOLLOWED = 'ngx_user-followed';

@Injectable()
export class UserFollowingEffects implements OnDestroy {

  $loadFollowed$ = createEffect(() => this.actions$.pipe(
    ofType<LoadFollowed>(UserFollowingActionsType.LoadFollowed),
    map((action) => {
      if (this.userService.loggedIn() && !this.followedLoaded && !this.followedLoading) {
        this.followedLoading = true;
        let httpParams = new HttpParams();
        if (action.isListExtended) {
          httpParams = httpParams.append(
            'expand',
            'user.homeCityObject.country,user.picture,user.placeCreatedCount,user.collectionCount,' +
            'events.place,events.collection,user.urlSetting'
          );
        } else {
          httpParams = httpParams.append('expand', 'user');
        }
        this.userFollowingService.getFollowedList(httpParams)
          .subscribe((response: FollowedList) => {
            this.followedLoading = false;
            response._sync = true;
            this.store$.dispatch(new UserFollowingActions.SuccessLoadFollowed(response, this.userService.loggedIn(), action.isListExtended));
          });
      }
    })
  ), {dispatch: false});

  $follow$ = createEffect(() => this.actions$.pipe(
    ofType<Follow>(UserFollowingActionsType.Follow),
    map((action) => {
      if (this.userService.loggedIn()) {
        this.userFollowingService.isFollowerProcessing = true;
        const httpParams = new HttpParams()
          .append(
            'expand',
            'user.homeCityObject.country,user.picture,user.placeCreatedCount,user.collectionCount,' +
            'events.place,events.collection');
        this.userFollowingService.follow({
          userId: action.payload.user.id
        }, httpParams)
          .subscribe(response => {
            this.store$.dispatch(new UserFollowingActions.SuccessFollow(response?.body, this.userService.loggedIn()));
            doAsync(() => this.userFollowingService.isFollowerProcessing = false, 500);
          }, () => {
          });
      } else {
        this.store$.dispatch(new UserFollowingActions.SuccessFollow(action.payload, this.userService.loggedIn()));
      }
      this.userFollowingService.isFollowingAnimation$.next();
    })
  ), {dispatch: false});

  $synchronizeFollowed$ = createEffect(() => this.actions$.pipe(
    ofType<SynchronizeFollowed>(UserFollowingActionsType.SynchronizeFollowed),
    map((action) => {
      if (this.userService.loggedIn()) {
        // @ts-ignore
        action.payload?.items.forEach(
          item => this.store$.dispatch(new UserFollowingActions.Follow(item))
        );
      }
    })
  ), {dispatch: false});

  $unfollow = createEffect(() => this.actions$.pipe(
    ofType<Unfollow>(UserFollowingActionsType.Unfollow),
    map((action) => {
      if (this.userService.loggedIn()) {
        this.userFollowingService.isFollowerProcessing = true;
        this.userFollowingService.unfollow(action.userId)
          .subscribe(() => {
            this.store$.dispatch(new UserFollowingActions.SuccessUnfollow(action.userId));
            doAsync(() => this.userFollowingService.isFollowerProcessing = false, 500);
          }, () => {
          });
      } else {
        this.store$.dispatch(new UserFollowingActions.SuccessUnfollow(action.userId));
      }
    })
  ), {dispatch: false});

  $successLoadFollowed$ = createEffect(() => this.actions$.pipe(
    ofType<SuccessLoadFollowed>(UserFollowingActionsType.SuccessLoadFollowed),
    map((action: SuccessLoadFollowed) => {
      if (this.userService.loggedIn()) {
        this.followedLoaded = true;
        if (action.isListExtended) {
          this.userFollowingService.isEventsLoaded = true;
        }
      }
    })
  ), {dispatch: false});

  private followedLoaded = false;
  private followedLoading = false;
  private $destroyed = new Subject<void>();
  private usersFollowed: any;

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private userService: UserService,
    private userFollowingService: UserFollowingService,
    private ssrService: SsrService
  ) {
    if (ssrService.isBrowser()) {
      this.usersFollowed = JSON.parse(localStorage.getItem(NGX_USER_FOLLOWED)) || '';
      if (this.usersFollowed) {
        this.store$.dispatch(new RestoreFollowed(this.usersFollowed));
      }
      this.store$.pipe(
        select(getFollowedList),
        takeUntil(this.$destroyed)
      ).subscribe((followed) => {
        this.usersFollowed = followed;
        this.usersFollowed = JSON.parse(JSON.stringify(this.usersFollowed));
        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_USER_FOLLOWED, JSON.stringify(this.usersFollowed));
        }
      });
      if (this.userService.loggedIn() && !this.usersFollowed._sync) {
        //TODO: doesn't reach $loadFollowed$
        this.store$.dispatch(new LoadFollowed(true));
      }
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }
}
