import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SetCityState, UserCityActions } from '../../../../store/user-cities';
import { AccountRouteType } from '../../enums/account-route.enum';
import { Store } from '@ngrx/store';
import { State } from '../../../../store/reducers';
import { AuthService } from '../../../../services/auth.service';
import { SsrService, UserService } from '../../../../services';
import { NGX_USER_COLLECTIONS, UserCollectionsActions } from "../../../../store/user-collections";
import { UserLikesActions } from "../../../../store/user-likes/user-likes.actions";
import { UserFollowingActions } from "../../../../store/user-following/user-following.actions";
import { UserNotificationsActions } from "../../../../store/user-notifications/user-notifications.actions";
import { NGX_USER_LIKES, NGX_USER_CITIES } from "../../../../../constants";

@Component({
  selector: 'app-user-sidebar',
  templateUrl: 'user-sidebar.component.html',
  styleUrls: ['user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnChanges {
  @Input() mode: string;

  @Output() routeSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public sidebarItems = [
    {
      title: 'Profile',
      isAction: false,
      isMobileLink: false,
      queries: [AccountRouteType.About, AccountRouteType.YourPage],
      children: [
        {
          title: 'About You',
          query: AccountRouteType.About,
        },
        {
          title: 'Your Page Settings',
          query: AccountRouteType.YourPage,
        },
      ]
    },
    {
      title: 'My Places',
      isAction: false,
      isMobileLink: false,
      queries: [AccountRouteType.YourPlaces, AccountRouteType.YourReviews, AccountRouteType.Journeys],
      children: [
        {
          title: 'Places',
          query: AccountRouteType.YourPlaces,
        },
        {
          title: 'Journeys',
          query: AccountRouteType.Journeys,
        },
        {
          title: 'Reviews',
          query: AccountRouteType.YourReviews,
        },
      ]
    },
    {
      title: 'Favourites',
      isAction: false,
      isMobileLink: false,
      queries: [AccountRouteType.Favourites, AccountRouteType.Following],
      children: [
        {
          title: 'Liked List',
          query: AccountRouteType.Favourites,
        },
        {
          title: 'Following',
          query: AccountRouteType.Following,
        },
      ]
    },
    {
      title: 'Settings',
      isMobileLink: false,
      isAction: false,
      queries: [AccountRouteType.Settings, AccountRouteType.Notifications],
      children: [
        {
          title: 'Account Settings',
          query: AccountRouteType.Settings,
        },
        {
          title: 'Notifications',
          query: AccountRouteType.Notifications,
        },
        {
          title: 'Log Out',
          isMobileLink: true,
          isAction: true,
        }
      ]
    }
  ];

  constructor(
    private router: Router,
    private store$: Store<State>,
    private authService: AuthService,
    private userService: UserService,
    private ssrService: SsrService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode'] && changes['mode'].currentValue) {
      this.mode = changes['mode'].currentValue;
    }
  }

  public getMode(queries: string[]): boolean {
    if (queries && queries.length > 0) {
      return queries.some(query => query === this.mode);
    }
    return false;
  }

  public openMenuItem(item: any): void {
    if (!item.isAction) {
      this.router.navigate(['/account'], {queryParams: {view: item.query || item.queries[0]}});
      this.routeSelected.emit(true);
    } else if (item.isAction && (!item.query || item.queries)) {
      this.signOut();
    }
  }

  private signOut(): void {
    const cities = [];
    this.store$.dispatch(new SetCityState({cities}));
    this.authService.authLogout().subscribe(
      () => {
        this.deleteCookie();
      },
      () => {
        this.deleteCookie();
      }
    );

    if (this.ssrService.isBrowser()) {
      localStorage.removeItem(NGX_USER_LIKES);
      localStorage.removeItem(NGX_USER_COLLECTIONS);
      localStorage.removeItem(NGX_USER_CITIES);
    }
    this.store$.dispatch(new UserCollectionsActions.ResetCollections);
    this.store$.dispatch(new UserCityActions.ResetState());
    this.store$.dispatch(new UserLikesActions.ResetLike());
    this.store$.dispatch(new UserFollowingActions.ResetFollowed());
    this.store$.dispatch(new UserNotificationsActions.ResetNotifications());
  }

  private deleteCookie(): void {
    this.userService.deleteCookiesOfToken();
    this.userService.signOut(true);
    this.router.navigate(['/']);
  }
}
