import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from "@angular/platform-browser";
import * as Hammer from "hammerjs";

// making hammer config (3)
@Injectable() export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pan': { direction: Hammer.DIRECTION_ALL },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };
}

@NgModule({
  imports: [
    AppModule,
    HammerModule,
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    // Add browser-only providers here
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: !environment.production
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
}
