export enum DestinationType {
  City = 1,
  Country = 2,
  Place = 3,
  Tag = 4,
  PoI = 5,
  Destination = 6,
  TagCity = 7,
  TagCountry = 8,
  AllPlaceCity = 9,
  AllPlaceCountry = 10,
  Restaurant = 11,
  UserProfile = 12,
  PlaceReview = 13,
  Journey = 14
}
