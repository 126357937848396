import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UserProfile, UserSettingsNames, UserUrlSettings } from '../../../../interfaces/user';
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { DropDownValue } from "../../../shared/modules/drop-down/containers/drop-down/drop-down.component";
import { prepareUrlKeywords } from "../../../../libraries/prepare-urlkeywords";
import { constructMediaURL } from "../../../../libraries";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-profile-user-page',
  templateUrl: 'profile-user-page.component.html',
  styleUrls: ['profile-user-page.component.scss']
})
export class ProfileUserPageComponent implements OnChanges {
  @Input() data: UserProfile;
  @Input() fields: any;

  @Output() changeSettings: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
  @Output() visibleAlert: EventEmitter<any> = new EventEmitter<any>();

  public isAccountPrivate: number;
  public getLinkByObject = getLinkByObject;
  public isAccountPublicDisabled: boolean;
  public urlBtnDisabled = true;
  public urlDropdownTitle: string;
  public urlSetting: number;
  public urlSettingOptions: DropDownValue[] = [];

  protected readonly constructMediaURL = constructMediaURL;

  private selectedUrlSettingsData: any;
  private timestamp: number;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      const user: UserProfile = changes['data'].currentValue;
      const isAccountPublicSetting = user.settings.find(s => s.id === UserSettingsNames.IsAccountPublic);
      this.isAccountPublicDisabled = isAccountPublicSetting.disabled;
      this.isAccountPrivate = +isAccountPublicSetting.value === 1 ? 0 : 1;
      this.urlSetting = +user.urlSetting;
      this.urlBtnDisabled = [UserUrlSettings.Name, UserUrlSettings.Subdomain].indexOf(this.urlSetting) !== -1;
      this.initDropDownTitle(user);
      this.urlSettingOptions = [
        {
          title: this.getUrlWithId(user),
          value: UserUrlSettings.Id,
          selected: this.urlSetting === UserUrlSettings.Id
        }
      ];
      if (user.nickName) {
        if (this.urlSetting === UserUrlSettings.Name) {
          this.urlSettingOptions.push({
            // We don't have user.urlKeywords before switching url setting
            title: this.getUrlWithName(user),
            value: UserUrlSettings.Name,
            selected: this.urlSetting === UserUrlSettings.Name
          });
        }
        this.urlSettingOptions.push({
          // We don't have user.urlKeywords before switching url setting
          title: this.getUrlWithSubdomain(user),
          value: UserUrlSettings.Subdomain,
          selected: this.urlSetting === UserUrlSettings.Subdomain
        });
      }
      this.timestamp = Date.now();
    }
  }

  private initDropDownTitle(user: UserProfile): void {
    switch (this.urlSetting) {
      case UserUrlSettings.Subdomain:
        this.urlDropdownTitle = this.getUrlWithSubdomain(user);
        break;
      case UserUrlSettings.Name:
        this.urlDropdownTitle = this.getUrlWithName(user);
        break;
      case UserUrlSettings.Id:
      default:
        this.urlDropdownTitle = this.getUrlWithId(user);
        break;
    }
  }

  protected getUrlWithId(user: UserProfile): string {
    return `www.explorow.com/ur/${user?.id}`;
  }

  protected getUrlWithName(user: UserProfile): string {
    return `www.explorow.com/${user?.urlKeywords ? user?.urlKeywords : prepareUrlKeywords(user?.nickName)}`;
  }

  protected getUrlWithSubdomain(user: UserProfile): string {
    return `${user?.urlKeywords ? user?.urlKeywords : prepareUrlKeywords(user?.nickName)}.explorow.com`;
  }

  public changeUrlSetting(): void {
    if (this.isUrlSettingDisabled()) {
      return;
    }
    this.urlDropdownTitle = this.selectedUrlSettingsData.title;
    this.changeSettings.emit({urlSetting: this.selectedUrlSettingsData.value});
  }

  public selectUserSetting(item: any): void {
    this.urlDropdownTitle = item.title;
    this.urlBtnDisabled = item.value === UserUrlSettings.Id;
    this.selectedUrlSettingsData = item;
  }

  public changeAccountVisibility(value: boolean): void {
    this.isAccountPrivate = Number(value);
    this.changeSettings.emit({isAccountPublic: Number(!value)});
  }

  public isUrlSettingDisabled(): boolean {
    return !!this.isAccountPrivate
      //do not allow to change settings after changing to name/domain
      || this.urlSetting === UserUrlSettings.Name
      || this.urlSetting === UserUrlSettings.Subdomain
      || !this.data.nickName;
  }

  public hasUrlSettingWarning(): boolean {
    return !this.data?.nickName;
  }

  protected getUserLink(): string {
    const url = getLinkByObject(this.data);
    return url.indexOf('http') === -1 ? environment.baseUrl + url : url;
  }

  protected getUserLinkWithHash(): string {
    return getLinkByObject(this.data) + '?_t=' + this.timestamp;
  }
}
