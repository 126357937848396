<div class="empty-places"
     id="empty-places"
     *ngIf="isShowBottomSheet"
     (pan)="onSwipe(0)"
     (click)="onSwipe(0)"></div>

<div class="sheet-container"
     #sheetContainer
     [class.active]="isActive"
     [style.height]="height">
  <div class="swiper-content"
       *ngIf="isCollectionInfo"
       (panstart)="onPanStart()"
       (panend)="onPanEnd($event)"
       (panmove)="onPan($event)">
    <div class="swiper-line"></div>
  </div>

  <div class="sheet-body"
       (panstart)="onPanStart()"
       (panend)="onPanEnd($event)"
       (panmove)="onPan($event)">
    <ng-container *ngIf="userIsLogged; else  noAuthPins">
      <app-pinned-panel-collection-info *ngIf="isShowBottomSheet && isCollectionInfo else simpleBottomSheet"
                                        [isBottomSheet]="true"
                                        [mode]="mode"
                                        [class.hidden]="!isOpenCollection && !isCreateCollection"
                                        [isCreate]="isCreateCollection"
                                        [selectCollectionData]="selectCollectionData"
                                        [allJourneys]=allCollections
                                        (deletePin)="deleteItem()"
                                        (emitOpenCreateCollectionField)="openCreateCollectionField($event)"
                                        (emitCancelCreateCollection)="cancelCreateCollection()"
                                        (emitOpenBottomSheet)="onSwipe(1)"
                                        (openPlaceEmitter)="openPlace($event)"
                                        (emitCreateCollection)="createCollection($event)"></app-pinned-panel-collection-info>

      <ng-template #simpleBottomSheet>
        <div class="sheet-simple-body"
             *ngIf="defaultCollection && defaultCollection.name"
             (click)="onSwipe(0)">
          <img class="img" src="/assets/icons/collections.svg" alt="Pinned">
          <div class="text cut-text">Removed from
            {{defaultCollection.name || defaultTitleCollection}}
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noAuthPins>
      <app-pinned-panel-collection-info-no-auth
        *ngIf="isShowBottomSheet && isCollectionInfo else noAuthSimpleBottomSheet"
        [mode]="mode"
        [collection]="defaultCollection"
        [isBottomSheet]="true"
        (openPlaceEmitter)="openPlace($event)"
        (emitOpenBottomSheet)="onSwipe(1)">
      </app-pinned-panel-collection-info-no-auth>
    </ng-template>
    <ng-template #noAuthSimpleBottomSheet>
      <div class="sheet-simple-body" (click)="onSwipe(0)">
        <img class="img" src="/assets/icons/collections.svg" alt="Pinned">
        <div class="text cut-text">Removed from {{defaultCollection?.name || defaultTitleCollection}}</div>
      </div>
    </ng-template>
  </div>
</div>
