import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SeeDoMapClass } from '../../abstracts/right-side-map-class';
import { FlyCookieService, InfoService, MapService, SsrService, UserService, WindowRef } from '../../services';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, KeyValue } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { take } from 'rxjs/operators';
import { PlaceNew, SearchStateForm } from '../../interfaces';
import { StaticRouteType } from './enums/route-type';
import { StaticInitService } from './services/static-init.service';
import { select, Store } from '@ngrx/store';
import { isPinnedPanelVisible, State } from '../../store/reducers';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { DestinationType } from '../../enums/destination-type';
import { StaticHeaderComponent } from './components/static-header/static-header.component';
import { AuthModalService } from '../../services/auth-modal.service';
import { UserCollectionService } from '../../services/user-collection.service';
import { StaticService } from '../../services/static.service';
import { doAsync } from '../../libraries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HelperService, maxTabletWidth } from '../../services/helper.service';
import { CONTACT_US_ACTION } from "../../enums/contact-us-action";
import isEmpty from 'lodash/isEmpty';
import { TogglePinnedPanel } from "../../store/panels/panels.actions";
import { MobilePanelAction } from "../../enums/mobile-panel-action.enum";
import { PanelType } from "../../enums/panel-type.enum";
import { LAST_ADD_FAVORITES } from "../../../constants";

@UntilDestroy()
@Component({
  selector: 'app-static',
  templateUrl: './static.component.html',
  styleUrls: ['./static.component.scss'],
})
export class StaticComponent extends SeeDoMapClass implements OnInit, OnDestroy {
  @ViewChild(StaticMapComponent, {static: false}) staticMap: StaticMapComponent;
  @ViewChild(StaticHeaderComponent, {static: true}) staticHeader: StaticHeaderComponent;

  public city: any;
  public cityTo: any;
  public country: string;
  public countryId: string;
  public currentPage: number;
  public currentPlace: any; //PlaceNew[];
  public currentThirdRequestCount = 0;
  public cityFrom: any;
  public isUpdateTravelParams = false;
  public month: any;
  public nameFrom;
  public pageCount = 1;
  public placeInfo: PlaceNew;
  public dynamicCountOfColumns = 2;
  public scrollOffset = 0;
  public searchData: SearchStateForm;
  public searchMode: any = {map: true};
  public staticRouteType = StaticRouteType;
  public subscriptions: Subscription = new Subscription();
  public tagGroupId: string;
  public tooltipsEventData: any;
  public totalCount = 100;
  public zoom: number;
  public userIsLogged: boolean;
  public withFollowLine: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const widthBefore = this.staticService.resizeWindow;
    this.staticService.resizeWindow = event.target.innerWidth;
    this.cdRef.detectChanges();
    if (event.target.innerWidth >= maxTabletWidth) {
      this.staticService.isOpenMap.next(true);
      this.scrollOffset = 0;
    } else if (widthBefore >= maxTabletWidth) {
      // to close map on tablet after rotating from 1024 to less,
      // on mobiles when keyboard appears (on focus in search input) the size also changes, but we shouldn't close map
      this.staticService.isOpenMap.next(false);
    }

    if (event.target.innerWidth < 896) {
      this.withFollowLine = this.staticService.staticType === this.staticRouteType.Journey;
    }

    if (event.target.innerWidth < 1024 || event.target.innerWidth > 1365) {
      this.dynamicCountOfColumns = 3;
    } else {
      this.dynamicCountOfColumns = 2;
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    this.scrollOffset = this.window.pageYOffset;
  }

  constructor(
    public staticInitService: StaticInitService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected infoService: InfoService,
    protected cdRef: ChangeDetectorRef,
    protected ssrService: SsrService,
    protected store$: Store<State>,
    @Inject('test_versions') public versions: any,
    protected pageScrollService: PageScrollService,
    @Inject(DOCUMENT) protected document: any,
    protected cookieService: FlyCookieService,
    protected windowRef: WindowRef,
    protected mapService: MapService,
    protected userService: UserService,
    protected authModalService: AuthModalService,
    protected userCollectionService: UserCollectionService,
    public staticService: StaticService,
    protected zone: NgZone,
    protected helperService: HelperService,
  ) {
    super(
      infoService,
      mapService,
      cdRef,
      store$,
      pageScrollService,
      document,
      windowRef,
      staticService,
      helperService
    );

    this.userIsLogged = this.userService.loggedIn();
    this.subscriptions.add(
      router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.store$.dispatch(new TogglePinnedPanel({display: null}));
        }
      })
    );

    if (this.ssrService.isBrowser()) {
      localStorage.setItem(LAST_ADD_FAVORITES, JSON.stringify(true));
    }
  }

  ngOnInit() {
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.currentThirdRequestCount = 0;
        this.setStaticType(this.route.snapshot.data['static']);
      });

    this.staticService.currentPlace$
      .pipe(untilDestroyed(this))
      .subscribe(x => {
        this.currentPlace = x;
        this.cdRef.detectChanges();
      });

    this.store$
      .pipe(
        untilDestroyed(this),
        select(isPinnedPanelVisible)
      ).subscribe((value) => {
      this.staticService.isOpenPinned = !!value;
    });

    if (this.ssrService.isBrowser()) {
      switch (this.route.snapshot.queryParams['open']) {
        case 'contact':
          // Open contact form by link from email: ?open=contact
          this.staticHeader.signInComponent.toggleContactUs(CONTACT_US_ACTION.general_enquiries);
          // Remove params to not open contact us on refresh again
          this.clearQueryParams();
          break;
        case 'journey':
          this.staticService.isRememberCollection = true;
          this.clearQueryParams();
          //open list of journeys
          this.staticService.togglePanel(PanelType.Pinned, true);
          break;
        case 'last-journey':
          this.clearQueryParams();
          //open default journey
          this.staticService.togglePanel(PanelType.Pinned, true);
          break;
        default:
          break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public routeToTypeStatic(place: any): void {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;

    const currentRouteWithoutParams = urlTree.toString();

    switch (place.otype) {
      case 1:
        const cityLink = `/city/${place.urlKeywords}/${place.id}`;
        if (currentRouteWithoutParams !== cityLink) {
          this.router.navigate([cityLink]);
        }
        break;
      case 3:
        const placeLink = `/places/${place.urlKeywords}/${place.id}`;
        // including review page like /places/lake_nakuru_national_park/10108/review/90
        if (currentRouteWithoutParams.indexOf(placeLink) !== 0) {
          this.router.navigate([placeLink]);
        }
        break;
      default:
        break;
    }
  }

  public mobilePanelAction(action: MobilePanelAction): void {
    switch (action) {
      case MobilePanelAction.TogglePinnedPanel:
        this.staticService.togglePanel(PanelType.Pinned, !this.staticService.isOpenPinned);
        break;
      case MobilePanelAction.ActivateMap:
        this.staticService.isOpenMap.next(true);
        break;
      case MobilePanelAction.CancelMap:
        this.staticService.isOpenMap.next(false);
        break;
      case MobilePanelAction.NavigateAccount:
        this.userService.isUserAuth
          .pipe(take(1))
          .subscribe(value => {
            if (value) {
              this.router.navigate(['/account']);
            } else {
              this.authModalService.open();
            }
          });
        break;
    }
  }

  // INIT StaticRouteType.SEARCH: (right side page)
  public updateTooltips(event): void {
    const placeId = this.staticService?.routeParams?.id;
    let params: KeyValue<string, string>[] = [];
    const mapElement = document.getElementsByClassName(
      'static-container-map'
    )[0] as HTMLElement;

    switch (this.staticService.staticType) {
      case StaticRouteType.Place:
        params.push({key: 'filter[id]', value: placeId?.toString()});
        break;
      case StaticRouteType.Review:
        params.push({key: 'filter[id]', value: this.staticService?.routeParams?.placeId?.toString()});
        break;
      case StaticRouteType.City:
        if (this.currentPlace?.places?.length) {
          params.push({key: 'filter[cityId]', value: placeId?.toString()});
        } else {
          params.push({key: 'filter[countryId]', value: this.currentPlace?.country.id.toString()});
        }
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.Country:
        params.push({
          key: 'filter[countryId]',
          value: placeId?.toString(),
        });
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.Experiences:
        if (placeId?.toString() !== 'undefined') {
          params.push({
            key: 'filter[countryId]',
            value: placeId?.toString(),
          });
          params = this.setFilterCoordinate(params, mapElement);
        }
        if (this.staticService.placesData?._extra?.tag) {
          params.push({
            key: 'filter[tagId][]',
            value: this.staticService.placesData._extra.tag.id.toString(),
          });
          params = this.setFilterCoordinate(params, mapElement);
        }
        break;
      case StaticRouteType.ThingsToDo:
        params.push({key: 'filter[cityId]', value: placeId?.toString()});
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.TagCity:
        params.push({key: 'filter[cityId]', value: placeId?.toString()});
        if (this.staticService?.placesData?._extra?.tag?.id) {
          params.push({
            key: 'filter[tagId][]',
            value: this.staticService.placesData._extra && this.staticService.placesData._extra.tag && this.staticService.placesData._extra.tag.id.toString(),
          });
        }
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.CategoryCity:
        params.push({key: 'filter[cityId]', value: placeId?.toString()});
        params.push({
          key: 'filter[tagGroupId]',
          value: this.staticService.placesData?._extra?.category?.id?.toString(),
        });
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.CategoryCountry:
        params.push({
          key: 'filter[countryId]',
          value: placeId?.toString(),
        });
        params.push({
          key: 'filter[tagGroupId]',
          value: this.staticService.placesData?._extra?.category?.id?.toString(),
        });
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.Search:
        const query = this.staticService.routeQueryParams || this.route;
        params = this.setFilterCoordinate(params, mapElement);
        params.push({key: 'filter[query]', value: query.q});
        if (query.cityId && this.helperService.checkIsNumeric(query.cityId)) {
          params.push({key: 'filter[cityId]', value: query.cityId});
        }
        if (query['tagId[]'] && this.helperService.checkIsNumeric(query['tagId[]'])) {
          params.push({key: 'filter[tagId][]', value: query['tagId[]']});
        }
        if (query.countryId && this.helperService.checkIsCountry(query.countryId)) {
          params.push({key: 'filter[countryId]', value: query['countryId'].toString()});
        }
        break;
      case StaticRouteType.Travel:
        const queries = this.route.snapshot.queryParams;
        if (queries) {
          if (queries['cityId'] && this.helperService.checkIsNumeric(queries['cityId'])) {
            params.push({key: 'filter[cityId]', value: queries.cityId.toString()});
          }

          if (queries['tagId'] && this.helperService.checkIsNumeric(queries['tagId'])) {
            params.push({key: 'filter[tagId][]', value: queries['tagId'].toString()});
          }
          if (queries.countryId && this.helperService.checkIsCountry(queries.countryId)) {
            params.push({key: 'filter[countryId]', value: queries['countryId'].toString()});
          }
        }

        const destinationType: DestinationType = Number(this.staticService.routeParams.typeId);
        switch (destinationType) {
          case DestinationType.PoI:
            if (
              this.staticService?.routeParams?.id
              && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
            ) {
              params.push({key: 'filter[poiId]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.Destination:
            if (
              this.staticService?.routeParams?.id
              && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
            ) {
              params.push({key: 'filter[destId]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.Tag:
            if (this.helperService.checkIsNumeric(this.staticService.routeParams.id)) {
              params.push({key: 'filter[tagId][]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.AllPlaceCity:
            if (this.helperService.checkIsNumeric(this.staticService.routeParams.allPlacesId)) {
              params.push({key: 'filter[cityId]', value: this.staticService.routeParams.allPlacesId.toString()});
            }
            break;
          case DestinationType.AllPlaceCountry:
            if (this.helperService.checkIsCountry(this.staticService.routeParams.allPlacesId)) {
              params.push({key: 'filter[countryId]', value: this.staticService.routeParams.allPlacesId.toString()});
            }
            break;
        }
        params = this.setFilterCoordinate(params, mapElement);
        break;
      case StaticRouteType.Journey:
        const queriesCollection = this.staticService.routeQueryParams;

        if (!this.staticService.userInfo || typeof this.staticService.userInfo.messageCode === 'undefined') {
          params.push({key: 'filter[collectionId]', value: this.staticService.routeParams.id.toString()});
        }

        if (queriesCollection) {
          if (queriesCollection.cityId && this.helperService.checkIsNumeric(queriesCollection.cityId)) {
            params.push({key: 'filter[cityId]', value: queriesCollection.cityId.toString()});
          }

          if (queriesCollection['tagId'] && this.helperService.checkIsNumeric(queriesCollection['tagId'])) {
            params.push({key: 'filter[tagId][]', value: queriesCollection['tagId'].toString()});
          }
          if (queriesCollection.countryId && this.helperService.checkIsCountry(queriesCollection.countryId)) {
            params.push({key: 'filter[countryId]', value: queriesCollection['countryId'].toString()});
          }
        }
        params = this.setFilterCoordinate(params, mapElement);

        const destinationCollectionType: DestinationType = Number(this.staticService.routeParams.typeId);
        switch (destinationCollectionType) {
          case DestinationType.PoI:
            if (
              this.staticService?.routeParams?.id
              && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
            ) {
              params.push({key: 'filter[poiId]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.Destination:
            if (
              this.staticService?.routeParams?.id
              && this.helperService.checkIsNumeric(this.staticService.routeParams.id)
            ) {
              params.push({key: 'filter[destId]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.Tag:
            if (this.staticService?.routeParams?.id && this.helperService.checkIsNumeric(this.staticService.routeParams.id)) {
              params.push({key: 'filter[tagId][]', value: this.staticService.routeParams.id.toString()});
            }
            break;
          case DestinationType.AllPlaceCity:
            if (
              this.staticService.routeParams.allPlacesId
              && this.helperService.checkIsNumeric(this.staticService.routeParams.allPlacesId)
            ) {
              params.push({key: 'filter[cityId]', value: this.staticService.routeParams.allPlacesId.toString()});
            }
            break;
          case DestinationType.AllPlaceCountry:
            if (this.helperService.checkIsCountry(this.staticService.routeParams.allPlacesId)) {
              params.push({key: 'filter[countryId]', value: this.staticService.routeParams.allPlacesId.toString()});
            }
            break;
        }
        break;
      case StaticRouteType.UserPersonalPage:
        if (
          this.staticService.userInfo?.messageCode
          || this.staticService.userInfo && !this.staticService.userInfo.places?.length
        ) {
          params.push({key: 'filter[userId][neq]', value: this.staticService.userInfo?.id?.toString()});
        } else {
          params.push({key: 'filter[userId]', value: this.staticService.userInfo?.id?.toString()});
        }
        params = this.setFilterCoordinate(params, mapElement);
        if (!isEmpty(this.mapService.filters)) {
          if (this.mapService.filters.cityId && this.helperService.checkIsNumeric(this.mapService.filters.cityId)) {
            params.push({key: 'filter[cityId]', value: this.mapService.filters.cityId.toString()});
          }
          if (this.mapService.filters.tagId && this.helperService.checkIsNumeric(this.mapService.filters.tagId)) {
            params.push({key: 'filter[tagId][]', value: this.mapService.filters.tagId.toString()});
          }
          if (this.mapService.filters.countryId && this.helperService.checkIsCountry(this.mapService.filters.countryId)) {
            params.push({key: 'filter[countryId]', value: this.mapService.filters.countryId.toString()});
          }
          if (this.mapService.filters.viewPortWidth && this.helperService.checkIsNumeric(this.mapService.filters.viewPortWidth)) {
            params.push({key: 'filter[viewPortWidth]', value: this.mapService.filters.viewPortWidth.toString()});
            delete this.mapService.filters.viewPortWidth;
          }
          if (this.mapService.filters.viewPortHeight && this.helperService.checkIsNumeric(this.mapService.filters.viewPortHeight)) {
            params.push({key: 'filter[viewPortHeight]', value: this.mapService.filters.viewPortHeight.toString()});
            delete this.mapService.filters.viewPortHeight;
          }
        }
        break;
      default:
        break;
    }

    this.tooltipsEventData = event;
    super.updateTooltips(event, params);
  }

  setFilterCoordinate(params: KeyValue<string, string>[], mapElement: HTMLElement) {
    if (this.staticService.isInit && mapElement) {
      if (mapElement.offsetWidth > 0) {
        params.push({
          key: 'filter[viewPortWidth]',
          value: mapElement.offsetWidth.toString(),
        });
      }
      if (mapElement.offsetWidth > 0) {
        params.push({
          key: 'filter[viewPortHeight]',
          value: mapElement.offsetHeight.toString(),
        });
      }
      doAsync(() => (this.staticService.isInit = false), 3000);
    }
    return params;
  }

  setStaticType(staticType) {
    this.staticService.setStaticType(staticType);
  }

  private clearQueryParams(): void {
    // Remove params to not open popup window on refresh again
    this.router.navigate([], {
      queryParams: {
        'open': null,
      },
      queryParamsHandling: 'merge'
    });
  }

  private togglePinnedPlaces(): void {
    this.staticService.togglePanel(PanelType.Pinned, this.staticService.isPinnedPlaceDisplay);
  }
}
