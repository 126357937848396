export const getViewPortSize = function (mapClass = 'static-container-map'): {
  viewPortWidth: any,
  viewPortHeight: any
} {
  const mapElement = document.getElementsByClassName(mapClass)[0] as HTMLElement;
  let viewPortWidth, viewPortHeight;
  if (mapElement.offsetWidth > 0) {
    viewPortWidth = mapElement.offsetWidth.toString();
  }
  if (mapElement.offsetWidth > 0) {
    viewPortHeight = mapElement.offsetHeight.toString();
  }

  return {viewPortWidth, viewPortHeight};
}
