<div class="about__container">
  <div class="about__content" *ngFor="let setting of settings">
    <div class="about__label">{{setting.label}}</div>

    <div class="about__item">
      <div class="description-block">
        <img
          (click)="toggleSetting(setting.name, mappedData[setting.name])"
          [src]="mappedData[setting.name] ? './assets/icons/toggle-on2.png' : './assets/icons/toggle-off2.png'"
          alt="toggle">
        <span>{{setting.description}}</span>
      </div>
    </div>
  </div>

</div>
