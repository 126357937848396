import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { of } from 'rxjs/internal/observable/of';
import { SearchService } from './search.service';
import { tap } from 'rxjs/operators';
import { HttpParams } from "@angular/common/http";

@Injectable()
export class AuthService {
  private responseCache = new Map();

  constructor(private httpService: HttpService) {
  }

  public auth(params?: any, httpQueryParams?: HttpParams) {
    return this.httpService.post('auth', params, httpQueryParams);
  }

  public saveAuthExternal(params?: any) {
    return this.httpService.post('auth/external', params);
  }

  public getAuthExternal(withCache: boolean = true, params?: any) {
    const uniqueCacheId: string = SearchService.getUniqueId(`auth/external`, params);
    if (withCache) {
      const fromCache = this.responseCache.get(uniqueCacheId);
      if (fromCache) {
        return of(fromCache);
      }
    }
    return this.httpService.get(`auth/external`, params).pipe(
      tap((data: any) => withCache && this.responseCache.set(uniqueCacheId, data))
    );
  }

  public deleteAuthExternal(authId: string) {
    return this.httpService.delete(`auth/external/${authId}`);
  }

  public authLogout(params?: any) {
    return this.httpService.post('auth/logout', params);
  }

  public authReset(params?: any) {
    return this.httpService.post('auth/reset', params);
  }

  public authResetToken(token, params: any) {
    return this.httpService.patch(`auth/reset/${token}`, params, {withCredentials: true});
  }
}
