import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import dayjs from 'dayjs';
import { Observable } from 'rxjs/internal/Observable';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { delay, map, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { RouteNamesEnum } from '../../app-routing.interface';
import {
  FlyCookieService,
  FlyCookieUpdateDataService,
  RouterHistoryService,
  searchData,
  SearchService,
  SsrService,
  UserAccountService,
  UserService,
  usertoken
} from '../../services';
import {
  getAjaxState,
  getAvailableMMR,
  getCityFrom,
  getCityTo,
  getDate,
  getPopularState,
  getSearchData,
  getTripType,
  isPinnedPanelVisible,
  searchUpdated,
  State,
} from '../../store/reducers';
import * as fromLayer from '../../store/layer';
import {
  PoiSection,
  ResetSearchData,
  SetCityTo,
  SetPassengers,
  SetSearchCurrentMMR,
  SetTripType,
  SetWhenDate,
  TripTypeEnum
} from '../../store/layer';
import { parseCoords } from '../../libraries/map/convert-coords';
import { SetCurrentCoordinates } from '../../store/auth';
import { computeCenterOfCountry } from '../../libraries/compute-center-of-map';
import { DestinationType } from '../../enums/destination-type';
import { ReplaceLinkService } from '../../services/replace-link.service';
import { StaticRouteType } from '../../modules/static/enums/route-type';
import { SignInButtonsComponent } from './sign-in-buttons/sign-in-buttons.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { FromSearchFieldComponent } from './from-search-field/from-search-field.component';
import { City, CityInterface, SearchStateForm, WhenDate } from '../../interfaces';
import { getLinkByObject } from '../../libraries/get-link-by-object';
import { maxTabletWidth } from "../../services/helper.service";
import { CategoryModeType } from "../../modules/static/components/static-categories/static-categories.component";
import { StaticService } from "../../services/static.service";
import { DatePickerMode } from "../../interfaces/datepicker-date";
import { CityToTypeEnum } from "../../enums/city-to-type.enum";
import { MobilePanelAction } from "../../enums/mobile-panel-action.enum";

interface CityFrom {
  id: any;
  name: string;
  coordinates?: string;
  type?: number;
}

interface CityTo extends CityFrom {
}

interface CountryTo {
  name: string;
}

interface When {
  type: DatePickerMode;
  date: dayjs.Dayjs;
}

interface HiddenSettingsSearchPanel {
  hiddenSearchPanelContentOn(): void;

  hiddenSearchPanelContentOff(): void;
}

@Component({
  selector: 'app-fly-search-panel',
  templateUrl: 'fly-search-panel.component.html',
  styleUrls: [
    '../../styles/mat-styles.scss',
    '../../styles/search-panel-shared.scss',
    'fly-search-panel.component.scss'
  ],
  providers: [FlyCookieUpdateDataService],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FlySearchPanelComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, HiddenSettingsSearchPanel {
  @Input() isOpenMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() setMaxWidth: boolean = false;
  @Input() searchMode;
  @Input() isPinnedPlaceOpen;
  @Input() scrollTop = 0;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  @Input() isVisibleProgressBar = true;
  @Input() place;
  @Input() data;
  @Input() staticTypes: StaticRouteType;

  @Output() searchEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() cityMonthChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() openSelPlacesFly: EventEmitter<any> = new EventEmitter<any>();
  @Output() pinnedChangeState: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearDest: EventEmitter<any> = new EventEmitter<any>();
  @Output() destCityChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() mobilePanelAction: EventEmitter<MobilePanelAction> = new EventEmitter<MobilePanelAction>();
  @Output() setTagsEmit = new EventEmitter();

  @HostBinding('class.bg') bg = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  @ViewChild('filterSelectorElement') filterSelectorElement: any;
  @ViewChild('destField') destField: any;
  @ViewChild('signInButtons') signInComponent: SignInButtonsComponent;
  @ViewChild('cityFromField') cityFromField: FromSearchFieldComponent;

  public ajaxState$: Observable<boolean>;
  public availableMMR$: Observable<PoiSection[]>;
  public availableMode: DatePickerMode[] = [DatePickerMode.Month, DatePickerMode.Date];
  public cityFrom$: Observable<City>;
  public cityInput: string;
  public cityTo$: Observable<CityTo>;
  public currentCity: any;
  public currentCityFrom: any = {};
  public currentCityTo: any = {};
  public currentRouteUrl: string = '';
  public date$: Observable<WhenDate[]>;
  public destCityInit: DestCityInitInterface;
  public destPlaceName = '';
  public formData:
    | {
    type: CityToTypeEnum;
    passengers: number;
    tripType: TripTypeEnum;
    cityFrom: CityFrom;
    cityTo: CityTo | CountryTo | boolean | any;
    date: When[];
    poi: PoiSection[];
  }
    | any = {
    date: [
      {
        type: DatePickerMode.Month,
        date: dayjs()
          .startOf('month')
          .add(1, 'month')
      }
    ],
    cityTo: true,
    tripType: TripTypeEnum.OneWay,
    passengers: 1,
    poi: []
  };
  public formDataUpdated = false;
  public isCityFromWasChanged = false;
  public isCouldBeReverse = false;
  public isFiltersHidden: boolean = true;
  public isNativeOpenHiddenFilters: boolean = true;
  public isOpenAutocompleteDepartureCountry = false;
  public isOpenDropdownSearchField = false;
  public isPinnedPlaceOpen$: Observable<boolean>;
  public month: any;
  public nextPlace;
  public position: any;
  public searchData$: Observable<SearchStateForm>;
  public searchData: SearchStateForm;
  public selectedCity: SelectedCityInterface;
  public selectedCityId: number | string;
  public selectedMMRName: string;
  public staticUrl: string;
  public staticUrlQueryParams = {};
  public temperatureUnit: string;
  public tripType$: Observable<TripTypeEnum | string>;
  public userIsLogged: boolean;
  public staticRouteType = StaticRouteType;
  public categoryType = CategoryModeType;
  public popularTags: any;

  private $destroyed = new Subject<void>();

  constructor(
    private ssrService: SsrService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private userService: UserService,
    private userAccountService: UserAccountService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private store$: Store<State>,
    private cookieService: FlyCookieService,
    private replaceLinkService: ReplaceLinkService,
    @Inject('test_versions') public versions: any,
    private cookieUpdateDataService: FlyCookieUpdateDataService,
    routerHistoryService: RouterHistoryService,
    public staticService: StaticService,
  ) {
    cookieUpdateDataService.cookieName = searchData;
    this.cityFrom$ = this.store$.pipe(select(getCityFrom));
    this.cityTo$ = this.store$.pipe(select(getCityTo));

    if (
      this.route.snapshot.data['name'] &&
      this.route.snapshot.data['name'] === RouteNamesEnum.SearchMap ||
      this.route.snapshot.queryParams['q']
    ) {
      this.store$.dispatch(new SetCityTo({
        name: this.route.snapshot.queryParams['q'],
        id: null
      }));
    }

    if (this.route.snapshot.queryParams['query']) {
      this.store$.dispatch(new SetCityTo({
        name: this.route.snapshot.queryParams['query'],
        id: null
      }));
    }

    if (this.route.snapshot?.data?.static) {
      this.staticService.staticType = this.route.snapshot.data['static'];
    }

    this.date$ = this.store$.pipe(select(getDate));
    this.tripType$ = this.store$.pipe(select(getTripType));
    this.isPinnedPlaceOpen$ = this.store$.pipe(select(isPinnedPanelVisible));
    this.availableMMR$ = this.store$.pipe(select(getAvailableMMR));
    this.searchData$ = combineLatest(
      this.store$.select(searchUpdated),
      this.store$.select(getSearchData)
    ).pipe(
      tap(([_updated, _searchState]) => {
        this.buildForm(_updated, _searchState);
        this.currentCityFrom = _searchState.cityFrom ? _searchState.cityFrom : null;
        this.currentCityTo = _searchState.cityTo ? _searchState.cityTo : null;

        if (this.currentCityTo &&
          this.currentCityFrom &&
          this.currentCityFrom.type === this.currentCityTo.type &&
          this.currentCityTo.type === DestinationType.City) {
          this.isCouldBeReverse = true;
        }
      }),
      map(([_updated, _searchState]) => _searchState),
    );

    this.route.queryParams.subscribe(() => {
      if (
        this.route.snapshot.data['name'] &&
        this.route.snapshot.data['name'] === RouteNamesEnum.SearchMap ||
        this.route.snapshot.queryParams['q']
      ) {
        this.store$.dispatch(new SetCityTo({
          name: this.route.snapshot.queryParams['q'],
          id: null
        }));
      }
    });

    routerHistoryService.currentUrl$.pipe(takeUntil(this.$destroyed)).subscribe(url => {
      this.currentRouteUrl = url;
    });
    if (this.ssrService.isBrowser()) {
      this.staticUrl = localStorage.getItem('staticRoute');
      if (this.staticUrl) {
        let url = new URL('http://localhost' + this.staticUrl);
        url.searchParams.forEach((val, key) => {
          this.staticUrlQueryParams[key] = val;
        });
        this.staticUrl = url.pathname;
      }
    }
  }

  ngOnInit() {
    this.cityTo$
      .pipe(takeUntil(this.$destroyed), take(1))
      .subscribe(cityTo => {
        if (cityTo && (cityTo.type !== DestinationType.Place)) {
          this.searchEmit.emit();
        }
      });
    this.userIsLogged = !!this.cookieService.get(usertoken);
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
    this.userService.isUserAuth.pipe(takeUntil(this.$destroyed)).subscribe((value) => {
      if (value) {
        this.userIsLogged = value;
      }
    });

    const excludePagesUrl: string[] = [
      '/terms-of-use',
      '/cookie-policy',
      '/privacy-policy',
      '/site-map',
      '/list-cities',
    ];
    for (const exUrl of excludePagesUrl) {
      if (this.router.url.includes(exUrl)) {
        this.isFiltersHidden = false;
      }
    }

    this.store$.pipe(
      select(getPopularState),
      takeUntil(this.$destroyed)
    ).subscribe(popularTags => {

      popularTags = popularTags?.map(tag => {
        return {
          ...tag,
          mode: this.getCategoryModeByOType(tag.otype)
        }
      });

      this.popularTags = popularTags;
    });
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (changes['isVisibleProgressBar'] && changes['isVisibleProgressBar'].currentValue) {
      this.isVisibleProgressBar = changes['isVisibleProgressBar'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    // TODO: should be refactored as a redux action
    if (this.formData.cityFrom && this.formData.cityFrom.coordinates) {
      this.store$.dispatch(new SetCurrentCoordinates(parseCoords(this.formData.cityFrom.coordinates)));
    }

    this.searchService.searchTrigger$.next();
    // разобраться, зачем это надо было, в комменте к коммиту описано как фикс reset filters
    // с этим проблемы при открытии по ссылки ресторана ?restaurantId= попапа ресторана
    // this.searchService.searchTrigger$.next();
  }

  CloseDropDown({isClosed, hasError}) {
    this.isOpenAutocompleteDepartureCountry = !isClosed;
    if (isClosed && hasError) {
      this.cityFrom$.pipe(take(1)).subscribe((value) => {
        this.cityFromField.setValue(value.name);
        this.cityFromField.clearDestFilters();
      });
    }
  }

  public openPlace($event): void {
    this.openSelPlacesFly.emit($event);
  }

  exploreIt(city) {
    if (!this.searchService.banRequest) {
      this.searchService.banRequest = true;

      if (!!this.nextPlace && !!this.nextPlace.lat) {
        this.store$.dispatch(new SetSearchCurrentMMR({poi: []}));
      }

      if (city?.place) {
        const url = getLinkByObject(city.place);
        this.ssrService.goToRoute(url);
      } else {
        //wtf is this?
        if (city && typeof city === 'string' || this.formData.cityTo && this.formData.cityTo.name) {
          this.ssrService.goToRoute(`/search?q=${(typeof city === 'string') ? city : this.formData.cityTo.name}`);
        }
      }

      this.cityMonthChange(null, true);
      // TODO: wat?? что это? зачем?
      setTimeout(() => {
        this.searchService.banRequest = false;
      }, 2000);
    }
  }

  closeDropdownSearchField() {
    this.isOpenDropdownSearchField = false;
  }

  openDropdownSearchField() {
    this.isOpenDropdownSearchField = true;
    setTimeout(() => {
      this.destField?.inputField?.nativeElement?.focus();
    }, 100);
  }

  public sendCitiesConfigToDatabase(params: any) {
    const city: CityInterface = params[0];
    this.userAccountService.addPin(city).subscribe(
      () => {
        params.shift();
        if (params.length > 0) {
          this.sendCitiesConfigToDatabase(params);
        } else {
          this.cityMonthChange(null, true);
          this.userService.disableLoadingBar(this);
        }
      },
      error => {
        params.shift();
        if (error.status === 422) {
          if (params.length > 0) {
            this.sendCitiesConfigToDatabase(params);
          } else {
            this.cityMonthChange(null, false);
            this.userService.disableLoadingBar(this);
          }
          return;
        }
        this.cityMonthChange(null, false);
        this.userService.disableLoadingBar(this);
      }
    );
  }

  public toggleSearchPanelFilters(): void {
    this.isFiltersHidden = !this.isFiltersHidden;
  }

  public hiddenSearchPanelContentOn(): void {
    if (!this.isNativeOpenHiddenFilters) {
      this.isFiltersHidden = true;
    }
    this.isNativeOpenHiddenFilters = false;
  }

  public hiddenSearchPanelContentOff(): void {
    this.isFiltersHidden = false;
  }

  public cityMonthChange(cityName: any, reload: boolean) {
    this.cityInput = cityName || this.cityInput;
    this.cityMonthChangeEmitter.emit({
      city: this.selectedCity,
      month: {
        value: this.formData.date[0].date.format('M')
      },
      reload: reload,
      temperatureUnit: this.temperatureUnit
    });
  }

  setFormDataAsUpdated() {
    this.formDataUpdated = true;
    if (this.filterSelectorElement) {
      this.filterSelectorElement.setOptionState('CLEAR-FILTERS', true);
    }
  }

  setCityFrom(city: any) {
    this.setFormDataAsUpdated();
    this.store$.dispatch(new fromLayer.SetCityFrom({
      name: city.place.name,
      id: city.place.id,
      coordinates: city.place.lat && city.place.lng ? `${city.place.lat},${city.place.lng}` : null,
      iataCode: city.place.iataCode
    }));

    this.cookieUpdateDataService.updateDataCookie('cityFromData', city.place);

    set(this.formData, 'cityFrom', {
      id: city.place.id,
      name: city.place.name,
      ...city.place,
    });

    this.isCityFromWasChanged = true;
  }

  setDestTo(event: any) {
    this.selectedMMRName = null;
    this.nextPlace = null;
    this.formData.poi = [];

    this.closeDropdownSearchField();

    if (!event) {
      // new point to is null, should be cleared
      this.store$.dispatch(new ResetSearchData());

      this.destCityInit = null;
      this.formData.cityTo = null;
      this.formData.type = null;
      this.clearDest.emit();

      return;
    }

    if (typeof event === 'boolean') {
      this.destCityInit = null;
      this.formData.cityTo = true;
      this.formData.type = CityToTypeEnum.EveryWhere;
    }

    this.currentCity = event ? event.place : true;

    if (typeof event === 'boolean') {
      this.exploreIt(event);
      return;
    }

    this.nextPlace = event.place;
    const place = event.place;

    if (place) {
      if (place.otype === DestinationType.City) {
        this.processCityTo(place);
      } else if (place.otype === DestinationType.Place) {
        this.processPlaceTo(place);
      } else if (place.otype === DestinationType.Country) {
        this.processCountryTo(place);
      } else if (place.otype === DestinationType.PoI) {
        this.processPOI(place);
      } else if (place.otype === DestinationType.Tag) {
        this.processTAG(place);
      } else if (place.otype === DestinationType.Destination) {
        this.processDestination(place);
      } else if (place.otype === DestinationType.TagCity) {
        this.processTagCity(place);
      } else if (place.otype === DestinationType.TagCountry) {
        this.processTagCountry(place);
      } else if (place.otype === DestinationType.AllPlaceCity) {
        this.processAllPlaceCity(place);
      } else if (place.otype === DestinationType.AllPlaceCountry) {
        this.processAllPlaceCountry(place);
      } else if (place.otype === DestinationType.UserProfile) {
      }

      this.isCouldBeReverse = this.currentCityTo &&
        place &&
        place.otype === this.currentCityTo.type &&
        place.otype === DestinationType.City;
    } else {
      this.ngOnInit();
    }

    this.isOpenDropdownSearchField = false;
    this.exploreIt(event);
  }

  setWhen(index, when) {
    this.setFormDataAsUpdated();
    this.formData.date = [...this.formData.date];
    this.formData.date[index] = when[0];

    this.store$.dispatch(new SetWhenDate(this.formData.date));
    if (
      index === 0 &&
      this.formData.date[1] &&
      this.formData.date[0].date.isAfter(this.formData.date[1].date)
    ) {
      this.formData.date[1] = {
        ...this.formData.date[0],
        date: dayjs(this.formData.date[0].date).add(1, 'month')
      };
    }

    this.cookieUpdateDataService.updateDataCookie('date', this.formData.date);
  }

  setTripWay(event: any) {
    this.setFormDataAsUpdated();
    this.formData.tripType = event.value;

    if (this.formData.tripType === TripTypeEnum.OneWay) {
      this.availableMode = [DatePickerMode.Date, DatePickerMode.Month];
      this.cookieUpdateDataService.updateDataCookie('tripType', TripTypeEnum.OneWay);
      this.store$.dispatch(new SetTripType(TripTypeEnum.OneWay));
    } else {
      this.availableMode = [DatePickerMode.Date];
    }

    if (this.formData.tripType === TripTypeEnum.RoundTrip) {
      this.cookieUpdateDataService.updateDataCookie('tripType', TripTypeEnum.RoundTrip);
      this.store$.dispatch(new SetTripType(TripTypeEnum.RoundTrip));
      this.formData.date[0].type = DatePickerMode.Date;

      this.formData.date = [...this.formData.date];
      this.formData.date[1] = {
        type: this.formData.date[0].type,
        date: dayjs(this.formData.date[0].date).add(1, 'month')
      };
    } else {
      this.formData.date = [this.formData.date[0]];
    }

    this.cookieUpdateDataService.updateDataCookie('date', this.formData.date);
    this.store$.dispatch(new SetWhenDate(this.formData.date));
  }

  setPassenger(event: number | Number) {
    this.setFormDataAsUpdated();
    this.formData.passengers = event;
    this.cookieUpdateDataService.updateDataCookie('passenger', event);
    if (!!event && typeof event === 'number') {
      this.store$.dispatch(new SetPassengers(event));
    }
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private buildForm(updatedState: boolean, searchState: SearchStateForm) {
    this.searchData = searchState;
    this.formData = cloneDeep(searchState);

    this.formDataUpdated = updatedState;
    this.selectedCity = {
      id: get(this.formData, 'cityFrom.id'),
      name: get(this.formData, 'cityFrom.name'),
      coordinates: get(this.formData, 'cityFrom.coordinates')
        || (get(this.formData, 'cityFrom.lat') ? get(this.formData, 'cityFrom.lat') + ',' + get(this.formData, 'cityFrom.lng') : null),
      population: null
    };

    const saveOptionsSelectedCityInLocalStorage = JSON.stringify(this.selectedCity);
    if (this.ssrService.isBrowser()) {
      localStorage.setItem('selectedCityForBurgerMenu', saveOptionsSelectedCityInLocalStorage);
    }

    if (this.formData.cityTo !== true) {
      this.destCityInit = {
        id: get(this.formData, 'cityTo.id'),
        name: get(this.formData, 'cityTo.name'),
        country: {name: ''}
      };
    } else {
      this.destCityInit = null;
    }
    this.cityMonthChange(null, true);
  }

  private processCityTo(place) {
    this.formData.type = CityToTypeEnum.City;
    set(this.formData, 'cityTo', {
      id: place.id,
      name: place.name
    });
    if (!!place.lat) {
      set(this.formData, 'cityTo', {
        ...this.formData.cityTo,
        coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      });
    }
    this.destCityInit = {
      id: get(this.formData, 'cityTo.id'),
      name: get(this.formData, 'cityTo.name'),
      country: {name: ''}
    };
    const cityTo = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: place.otype,
      countryCode: place.country ? place.country.id : '',
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
    this.store$.dispatch(new SetCityTo(cityTo));
    this.setFormDataAsUpdated();
  }

  private processPlaceTo(place) {
    this.formData.type = CityToTypeEnum.City;
    set(this.formData, 'cityTo', {
      id: place.id,
      name: place.name
    });
    if (!!place.lat) {
      set(this.formData, 'cityTo', {
        ...this.formData.cityTo,
        coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      });
    }
    this.destCityInit = {
      id: get(this.formData, 'cityTo.id'),
      name: get(this.formData, 'cityTo.name'),
      country: {name: ''}
    };
    const cityTo = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: place.otype,
      countryCode: place.country ? place.country.id : '',
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
    this.store$.dispatch(new SetCityTo(cityTo));
    this.setFormDataAsUpdated();
  }

  private processCountryTo(place) {
    this.formData.type = DestinationType.Country;
    const {lat, lng} = computeCenterOfCountry(place);
    const cityTo = {
      id: place.id,
      name: place.name,
      coordinates: `${lat},${lng}`,
      type: place.otype,
      countryCode: place.id,
      latNorth: +place.latNorth,
      lngEast: +place.lngEast,
      latSouth: +place.latSouth,
      lngWest: +place.lngWest,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
    this.store$.dispatch(new SetCityTo(cityTo));
    this.formData.cityTo = {
      ...place,
      name: place.name,
      id: place.id,
      type: place.otype,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.destPlaceName = place.name;
    this.setFormDataAsUpdated();
  }

  private processPOI(place) {
    const poi = {
      id: parseInt(place.id, 10),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: place.otype,
      zoom: place.zoom,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', poi);
    this.store$.dispatch(new SetCityTo(poi));
    this.setFormDataAsUpdated();
  }

  private processTAG(place) {
    const tag = {
      id: parseInt(place.id, 10),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: place.otype,
      zoom: place.zoom,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', tag);
    this.store$.dispatch(new SetCityTo(tag));
    this.setFormDataAsUpdated();
  }

  private processDestination(place) {
    const destination = {
      id: parseInt(place.id, 10),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      type: place.otype,
      zoom: place.zoom,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.cookieUpdateDataService.updateDataCookie('cityToData', destination);
    this.store$.dispatch(new SetCityTo(destination));
    this.setFormDataAsUpdated();
  }

  private processTagCity(place) {
    const destination = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      cityId: place.city ? place.city.id : null,
      latNorth: +place.latNorth,
      lngEast: +place.lngEast,
      latSouth: +place.latSouth,
      lngWest: +place.lngWest,
      type: place.otype,
      zoom: place.zoom,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', destination);
    this.store$.dispatch(new SetCityTo(destination));
    this.setFormDataAsUpdated();
  }

  private processAllPlaceCity(place) {
    const destination = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: place.lat && place.lng ? `${place.lat},${place.lng}` : null,
      cityId: place.city ? place.city.id : null,
      latNorth: +place.latNorth,
      lngEast: +place.lngEast,
      latSouth: +place.latSouth,
      lngWest: +place.lngWest,
      type: place.otype,
      zoom: place.zoom,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.cookieUpdateDataService.updateDataCookie('cityToData', destination);
    this.store$.dispatch(new SetCityTo(destination));
    this.setFormDataAsUpdated();
  }

  private processAllPlaceCountry(place) {
    const {lat, lng} = computeCenterOfCountry(place);
    const destination = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: `${lat},${lng}`,
      type: place.otype,
      countryCode: place.country ? place.country.id : '',
      latNorth: +place.latNorth,
      lngEast: +place.lngEast,
      latSouth: +place.latSouth,
      lngWest: +place.lngWest,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };

    this.cookieUpdateDataService.updateDataCookie('cityToData', destination);
    this.store$.dispatch(new SetCityTo(destination));
    this.setFormDataAsUpdated();
  }

  private processTagCountry(place) {
    const {lat, lng} = computeCenterOfCountry(place);
    const cityTo = {
      id: Number.parseInt(place.id),
      name: place.name,
      coordinates: `${lat},${lng}`,
      type: place.otype,
      countryCode: place.country ? place.country.id : '',
      latNorth: +place.latNorth,
      lngEast: +place.lngEast,
      latSouth: +place.latSouth,
      lngWest: +place.lngWest,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.formData.cityTo = {
      ...this.formData.cityTo,
      ...this.replaceLinkService.setCityToLinkName(place)
    };
    this.cookieUpdateDataService.updateDataCookie('cityToData', cityTo);
    this.store$.dispatch(new SetCityTo(cityTo));
    this.setFormDataAsUpdated();
  }

  public getCategoryModeByOType(item) {
    switch (item) {
      case DestinationType.Place:
        return CategoryModeType.Place;
      case DestinationType.Country:
        return CategoryModeType.Countries;
      case DestinationType.Destination:
        return CategoryModeType.Categories;
      case DestinationType.City:
        return CategoryModeType.City;
      default:
        return CategoryModeType.All;
    }
  }

  public clickCategoryEmit(event: any) {
    const activeTags = this.popularTags
      .filter(tag => tag?.id === event?.selectedTag?.id)
      .map(tag => ({...tag, type: tag.otype}));
    this.setTagsEmit.emit({items: activeTags, type: CategoryModeType.Trends});
  }

  public isMobileOrTablet() {
    return this.resizeWindow < maxTabletWidth;
  }
}

interface DestCityInitInterface {
  id: string | number; // cityId
  name: string; // cityName;
  country: {
    name: string;
  };
}

export interface SelectedCityInterface {
  id: string | number;
  name: string;
  population: number | string;
  coordinates: string;
}
