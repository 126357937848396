import { Action } from '@ngrx/store';
import { PoiSection } from '../../interfaces/poi';
import { City, NotificationType, Point, PointToType, SearchStateFormPatch, WhenDate } from '../../interfaces';
import { DestinationType } from '../../enums/destination-type';
import { CityToTypeEnum } from "../../enums/city-to-type.enum";
import { TripTypeEnum } from "../../enums/trip-type.enum";

export enum LayerActionsTypes {
    NewNotifications = '[Layer] New notification',
    AjaxStart = '[Layer] Ajax start',
    AjaxFinish = '[Layer] Ajax finish',
    ResetSearchData = '[Layer] Reset search panel stated',
    SetSearchPanelState = '[Layer] Set search panel state',
    SetSearchData = '[Layer] Set search data',
    PatchSearchFormState = '[Layer] Patch search state',
    SetWhenDate = '[Layer] Set when date',
    SetCityFrom = '[Layer] Set city from',
    SetCityTo = '[Layer] Set city to',
    SwapDirections = '[Layer] Swap directions',
    SwapDirectionsFailure = '[Layer] Swap directions failure',
    SetPointFrom = '[Layer] Set point from',
    SetPointTo = '[Layer] Set point to',
    SetTripType = '[Layer] Set trip type',
    SetPointToType = '[Layer] Set pointTo type',
    SetPassengers = '[Layer] Set passengers',
    SetCurrentMMR = '[Layer] Set current poi',
}

export class ResetSearchData implements Action {
    readonly type = LayerActionsTypes.ResetSearchData;
}

export class SetSearchCurrentMMR implements Action {
    readonly type = LayerActionsTypes.SetCurrentMMR;

    constructor(public payload: { poi: PoiSection[] }) {
    }
}

export class SetPassengers implements Action {
    readonly type = LayerActionsTypes.SetPassengers;

    constructor(public payload: Number) {
    }
}

export class PatchSearchFormState implements Action {
    readonly type = LayerActionsTypes.PatchSearchFormState;

    constructor(public payload: SearchStateFormPatch) {
    }
}

export class SetTripType implements Action {
    readonly type = LayerActionsTypes.SetTripType;

    constructor(public payload: TripTypeEnum) {
    }
}

export class SetSearchData implements Action {
    readonly type = LayerActionsTypes.SetSearchData;

    constructor(public payload: {
        date: WhenDate[],
        pointFrom: City,
        pointTo: Point,
        cityFrom: City,
        cityTo: City,
        tripType: TripTypeEnum,
        type: CityToTypeEnum | DestinationType,
        passengers: Number,
    }) {
    }
}

export class SetSearchPanelState implements Action {
    readonly type = LayerActionsTypes.SetSearchPanelState;

    constructor(public payload: Boolean) {
    }
}

export class SetWhenDate implements Action {
    readonly type = LayerActionsTypes.SetWhenDate;

    constructor(public payload: WhenDate[]) {
    }
}

export class SetCityTo implements Action {
    readonly type = LayerActionsTypes.SetCityTo;

    constructor(public payload: Point) {
    }
}

export class SetCityFrom implements Action {
    readonly type = LayerActionsTypes.SetCityFrom;

    constructor(public payload: Point) {
    }
}

export class SwapDirections implements Action {
    readonly type = LayerActionsTypes.SwapDirections;
}

export class SwapDirectionsFailure implements Action {
    readonly type = LayerActionsTypes.SwapDirectionsFailure;

    constructor(public payload: Error) {
    }
}

export class SetPointTo implements Action {
    readonly type = LayerActionsTypes.SetPointTo;

    constructor(public payload: Point) {
    }
}

export class SetPointFrom implements Action {
    readonly type = LayerActionsTypes.SetPointFrom;

    constructor(public payload: Point) {
    }
}

export class SetPointToType implements Action {
    readonly type = LayerActionsTypes.SetPointToType;

    constructor(public payload: { type: CityToTypeEnum | PointToType | DestinationType | null }) {
    }
}

export class AjaxStart implements Action {
    readonly type = LayerActionsTypes.AjaxStart;

    constructor(public payload: { uid: any }) {
    }
}

export class AjaxFinish implements Action {
    readonly type = LayerActionsTypes.AjaxFinish;

    constructor(public payload: { uid: any }) {
    }
}

export class AddNotification implements Action {
    readonly type = LayerActionsTypes.NewNotifications;

    constructor(public payload: NotificationType) {
    }
}

export type LayerActionsUnion =
    AddNotification
    | SetSearchCurrentMMR
    | SetPassengers
    | SetTripType
    | AjaxStart
    | AjaxFinish
    | SetCityFrom
    | SetCityTo
    | SwapDirections
    | SwapDirectionsFailure
    | SetPointFrom
    | SetPointTo
    | SetPointToType
    | SetWhenDate
    | SetSearchPanelState
    | PatchSearchFormState
    | SetSearchData
    | ResetSearchData;
