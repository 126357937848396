<section id="mapSection" [ngClass]="{'fixed': _isMapFixed}">

  <div class="map-wrapper shadow see-do-page"
       [ngClass]="{'shadow__visible': placeGroup?.length > 0}">

    <div class="loading-map-container" *ngIf="isMovingMapAnimate || isMapPreparing">
      <img src="/assets/searching.gif" alt="Searching">
    </div>

    <!-- todo: usePanning=false -->
    <div *ngIf="isBrowser && (mapApiLoaded$ | async)" class="mini-map-container">
      <google-map
        [center]="{lat:staticService.lat, lng:staticService.lng}"
        [zoom]="staticService.zoom"
        [options]="{
          gestureHandling: 'greedy',
          minZoom: minZoom,
          disableDefaultUI: true,
          scrollwheel: false,
          styles: mapStyles,
          mapTypeId : mapTypeId,
          zoomControl: !staticService.isMobileDesign(),
          restriction: {
            latLngBounds: earthBounds,
            strictBounds: true
          },
          zoomControlOptions: {position: ControlPositionRightTop}
        }"
        (mapInitialized)="mapReady($event)"
        (zoomChanged)="onZoomChange()"
        (boundsChanged)="onBoundsChange()"
      >
        <button [hidden]="!isMobile" (click)="closeMap()" id="CloseBtn" class="close-map-btn">
          <span>close map</span>
        </button>

        <!-- tooltips should be opened when user clicks on marker -->
        <div class="tooltip" *ngFor="let place of openedPlaces">
          <app-snazzy-info-window
            *ngIf="place"
            [wrapperClass]="'app-snazzy-info-window--popup'"
            [backgroundColor]="'#fff'"
            [closeOnMapClick]="false"
            [panOnOpen]="false"
            [isOpen]="true"
            [latitude]="+place.tooltipShift"
            [longitude]="+place.lng"
            [mapInitialized]="mapInitialized$"
          >
            <ng-template>
              <app-map-tooltip-place
                [showCloseButton]="true"
                [place]="place"
                (closeTooltip)="closeTooltip(place)"
                (showPlacePopup)="showPlacePopup($event,true)">
              </app-map-tooltip-place>
            </ng-template>
          </app-snazzy-info-window>
        </div>

        <div class="tooltip" *ngFor="let place of visiblePlaces; let i = index">
          <app-snazzy-info-window
            *ngIf="place"
            [wrapperClass]="'app-snazzy-info-window--popup'"
            [backgroundColor]="'#fff'"
            [closeOnMapClick]="false"
            [panOnOpen]="false"
            [isOpen]="true"
            [latitude]="+place.tooltipShift"
            [longitude]="+place.lng"
            [mapInitialized]="mapInitialized$"
          >
            <ng-template>
              <app-map-tooltip-place
                [place]="place"
                [showCloseButton]="true"
                (closeTooltip)="closeTooltip(place)"
                (showPlacePopup)="showPlacePopup($event,true)">
              </app-map-tooltip-place>
            </ng-template>
          </app-snazzy-info-window>
        </div>

        <div class="marker" *ngFor="let place of points">
          <map-marker
            *ngIf="place"
            [icon]="icon"
            (mapClick)="openTooltip(place)"
            [position]="{lat:+place.lat,lng:+place.lng}"
          >
          </map-marker>
        </div>

        <map-polyline
          *ngIf="isPolylineVisible()"
          [path]="showPlace"
          [options]="{
            strokeOpacity: 0,
            geodesic: true,
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  strokeColor: '#ffffff',
                  scale: 6
                },
                offset: '10px',
                repeat: '20px'
              },
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  strokeColor: '#5c97e3',
                  scale: 5
                },
                offset: '10px',
                repeat: '20px'
              }
            ]
          }"
        ></map-polyline>
      </google-map>
    </div>
  </div>

</section>
