import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './containers/date-picker/date-picker.component';
import { DatePickerModeComponent } from './containers/date-picker-mode/date-picker-mode.component';
import { DateViewComponent } from './components/date-view/date-view.component';
import { MonthViewComponent } from './components/month-view/month-view.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [DatePickerComponent, DateViewComponent, DatePickerModeComponent, MonthViewComponent],
  exports: [DatePickerComponent, DatePickerModeComponent]
})
export class DatePickerModule {
}
