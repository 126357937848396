import { clip } from "../clip";
import { mapSize } from "./map-size";

// Converts pixel coordinate to latitude (doesn't require google map lib)
export const pixelYToLat = function (pixelY: number, zoom: number): number {
  const mapSizeVal = mapSize(zoom);
  const y = 0.5 - (clip(pixelY, 0, mapSizeVal - 1) / mapSizeVal);

  return 90 - 360 * Math.atan(Math.exp(-y * 2 * Math.PI)) / Math.PI;
}
