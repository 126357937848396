import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SsrService, UserAccountService, UserService } from '../../../../../services';
import { State } from '../../../../../store/reducers';
import { StaticPinComponent } from '../../static-pin/static-pin.component';
import { constructBaseUrl, getWindow } from '../../../../../libraries';
import { SetSearchPanelState } from '../../../../../store/layer';
import { takeUntil } from 'rxjs/operators';
import { UserLikesActions } from '../../../../../store/user-likes/user-likes.actions';
import { DestinationType } from '../../../../../enums/destination-type';
import { DEFAULT_TITLE_JOURNEY } from "../../../../../../constants";

@Component({
  selector: 'app-static-place-item-pin',
  templateUrl: './static-place-item-pin.component.html',
  styleUrls: ['./static-place-item-pin.component.scss'],
})
export class StaticPlaceItemPinComponent extends StaticPinComponent implements OnInit {

  @Input() isVisibleTextBtn: boolean;
  @Input() isTitlePosition = false;
  @Input() movePinToRight = true;

  public defaultTitleCollection = DEFAULT_TITLE_JOURNEY;
  public userIsLogged: boolean;
  public isBrowser: boolean;
  public mobileMode: boolean;
  public isShowPopup: boolean;
  public isLiked: Boolean = false;
  public typeLikeContainer = {
    visible: 1,
    noVisible: 0
  };
  public popupData = [
    {
      icon: 'liked-icon-off.svg',
      title: 'Like it',
      action: 'toggleLiked',
      upperLine: false,
      color: '#da3f6f'
    }
  ];

  @HostListener('window:resize', ['$event'])
  public checkMobile() {
    if (this.isBrowser) {
      const w = getWindow();
      if (w) {
        this.mobileMode = w.innerWidth < 897;
      }
    }
  }

  constructor(
    protected el: ElementRef,
    protected store$: Store<State>,
    protected userAccountService: UserAccountService,
    protected userService: UserService,
    protected ssrService: SsrService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(store$, userAccountService, userService, cdRef);
    this.userIsLogged = this.userService.loggedIn();
    this.isBrowser = this.ssrService.isBrowser();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isWannaGoCity$.pipe(takeUntil(this.$destroyed)).subscribe(isPins => {
      this.pinState = isPins;
      this.generatePopupData();
    });
    this.isLiked$.pipe(takeUntil(this.$destroyed)).subscribe(isLiked => {
      this.isLiked = isLiked;
      this.generatePopupData();
    });
  }

  public emitAction(funcName) {
    this[funcName.action](funcName.value);
    if (funcName.action !== 'togglePopup') {
      this.togglePopup();
    }
  }

  public togglePopup(value: boolean = null) {
    this.isShowPopup = value !== null ? value : !this.isShowPopup;
  }

  public toggleLiked() {
    if (this.userAccountService.isPinLoading) {
      return;
    }
    const nextState = !this.isLiked;
    this.store$.dispatch(new SetSearchPanelState(false));

    if (nextState) {
      this.store$.dispatch(new UserLikesActions.AddLikes(this.place));
    } else {
      this.store$.dispatch(new UserLikesActions.RemoveLike({oid: this.place.id, otype: this.place.otype}));
    }
  }

  private generatePopupData() {
    this.popupData = [
      {
        icon: this.isLiked ? 'liked-icon-on.svg' : 'liked-icon-off.svg',
        title: this.isLiked ? 'Remove from Liked' : 'Like it',
        action: 'toggleLiked',
        upperLine: false,
        color: '#da3f6f'
      },
    ];
    if (this.place.otype === DestinationType.Place) {
      this.popupData.push(
        {
          icon: this.pinState ? 'delete-icon.svg' : 'photos.svg',
          title: this.pinState ? 'Remove from Journey' : 'Add to Journey',
          action: 'togglePinned',
          upperLine: false,
          color: null,
        }
      );
    }
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
