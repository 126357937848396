import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
let lazySizes = null;
if (canUseDOM) {
  //checking for SSR, window is not exists on SSR side
  require('lazysizes/plugins/unveilhooks/ls.unveilhooks');
  lazySizes = require('lazysizes');
}

@Directive({
  selector: '[appLazyload]'
})
export class LazyloadDirective implements OnInit {
  // just white pic
  public tempImage: string = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  @Input() appLazyload: string;
  public img;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.img = this.appLazyload;
    // this.initLazyLoading();
    this.setAttributes();
  }

  // initLazyLoading() {
  //   if (lazySizes) {
  //     lazySizes.init();
  //   }
  // }

  setAttributes() {
    this.renderer.addClass(this.el.nativeElement, 'lazyload');
    if (this.el.nativeElement.localName === 'img') {
      this.setImgSrc();
    } else {
      this.setElementBackgroundImage();
    }
  }

  setImgSrc() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.img);
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.tempImage);
  }

  setElementBackgroundImage() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-bg', this.img);
    this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.tempImage})`);
  }
}
