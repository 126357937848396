<div class="old-static-page">

  <app-static-header
    [data]="staticService.placesData"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="terms-of-service-modal">
    <div class="terms-of-service-container">
      <div class="terms-of-service-content fly-m-t-10px">
        <header>
          <h1>Cookie Policy</h1>
        </header>
        <h2>Introduction </h2>

        <section>
          <p>
            This Policy sets out our position on how we use technologies such as cookies, local storage, pixels, web
            beacons, and flash cookies (collectively referred to from hereon as ‘Cookies’) in regard to the collection
            of your personal data, and how you can control them.
          </p>
          <p>
            We may update this policy from time to time by updating this page. You should check this page from time to
            time to ensure you are happy with any changes.
          </p>
          <p>
            “Websites” collectively refer to all websites operated by Explorow Travel Pty Ltd (“Explorow”) across (as
            applicable) desktop, mobile, tablet and apps (including any subdomains, electronic newsletter or widget that
            links to the Website).
          </p>
          <p>
            This Cookie Policy is to be read in conjunction with our Privacy Policy.
          </p>
          <h2>
            What are cookies?
          </h2>
          <p>
            A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. We may link
            the information we store in cookies to any personal information you submit while on our website. Cookies
            save data about individual visitors to the website, such as the visitor's name, password, username, screen
            preferences, the pages of a website viewed by the visitor, and the advertisements viewed or clicked by the
            visitor.
          </p>
          <p>
            By using the Websites, you agree that we can use these Cookies as described in this Cookie Policy.
          </p>
          <h2>
            Why do we use cookies?
          </h2>
          <p>Explorow uses Cookies to allow us to better understand your preferences as well as analyse the
            effectiveness of the Service. We may use a variety of Cookies for different purposes to improve your
            experience in using our Websites. Some of the purposes of different Cookies we use are set out below:</p>
          <ol class="ListStyleDecimal">
            <li>Some Cookies are essential to the Websites, to facilitate a log-in process or to enable you to use
              the Websites and its features. Essential Cookies cover actions including developing user-generated
              Content, writing blogs, comments, downloading pictures.
            </li>
            <li>We may use Cookies to allow us to remember the choices you make while browsing the Websites, and to
              provide more personalised content and features, including your cookies settings preferences. As a result,
              you will not have to re-enter details during your visit of the Website.
            <li>
            <li>We may use Cookies to receive and record information about your computer, device, and browser,
              including but not limited to your IP address, browser type, and other software or hardware information. If
              you access the Site from a mobile or other device, we may collect a unique device identifier assigned to
              that device ("UDID"), geolocation, or other information for that device. Explorow aims to provide
              customized Content across your desktop, mobile, tablet and apps.
            </li>
            <li>We, or our Third-Party service providers, may use analytics or performance Cookies to collect
              information about how you use the Websites, e.g. the most visited pages. The information allows us to
              improve the Websites according to the needs of our users to optimize the Service that Explorow provide to
              you.
            </li>
            <li>We, and our service providers, may use advertising Cookies to deliver ads that are relevant to your
              interests.
            </li>
            <li>All information derived from these Cookies may be combined with other information acquired from
              third parties, and we may share this information with third parties in a de-identified way.
            </li>
          </ol>
          <h2>Managing Cookie usage</h2>
          <p>We appreciate that some users do not want to accept Cookies onto their device. You may block the
            installation of cookies by certain settings provided in your browser software. If you choose to do this, we
            would like you to know that in this case you may not have full access to all Explorow Site functions.
          </p>
          <p>Additionally, you can prevent the compilation of data (including your IP address) through cookies and
            website use by downloading and installing the browser plug-in available through the following link
            <a href="https://tools.google.com/dlpage/gaoptout"
               rel="nofollow noopener"> https://tools.google.com/dlpage/gaoptout</a>
          </p>
          <p>If you would like to contact us about our usage of Cookies, please send a request via
            <a class="cursor-pointer" (click)="toggleContactUs(contactType)">form</a>
          </p>
        </section>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>

<app-contact-us *ngIf="contactUsOpened"
                [contactType]="contactUsOpened"
                (closeContactUs)="toggleContactUs()"></app-contact-us>
