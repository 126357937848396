<div class="confirm-modal">
  <div class="confirm-dialog">
    <section>
      <header>
        <h3 *ngIf="message"> {{ message }} </h3>
      </header>

      <button class="btn btn-save"
              (click)="save()">
        Delete it
      </button>

      <button class="btn"
              (click)="decline()">
        cancel
      </button>
    </section>
  </div>
</div>
