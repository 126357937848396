<div *ngIf="error">
  <app-static-header
    [place]="staticService.showPlace"
    [isOpenMap]="staticService.isOpenMap.value"
    [data]="staticService.placesData"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="content">
    <article>
      <h2>Error has occurred</h2>
      <div>
        <p>{{error}}</p>
        <p><a href="/">Go to the home page</a></p>
      </div>
    </article>
  </div>

  <app-footer></app-footer>
</div>
