import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from '../services/user-token.service';
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class NotificationService {

  public isInitialized = false;

  constructor(
    private httpService: HttpService,
    private userService: UserService
  ) {

    if (!this.userService.loggedIn()) return;
  }

  public getNotifications(params?: any): Observable<any> {
    return this.httpService.get('user/notification', params);
  }
}
