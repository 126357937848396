import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/operators';
import set from 'lodash/set';
import dayjs from 'dayjs';
import { getCityFrom, getDate, getPassengers, getTripType, State } from '../../../../store/reducers';
import { select, Store } from '@ngrx/store';
import * as fromLayer from '../../../../store/layer';
import {
  SetPassengers,
  SetTripType,
  SetWhenDate,
  TripTypeEnum
} from '../../../../store/layer';
import { FromSearchFieldComponent } from '../../../../shared-components/fly-search-panel/from-search-field/from-search-field.component';
import { SearchStateForm, WhenDate } from "../../../../interfaces";
import { DatePickerMode } from "../../../../interfaces/datepicker-date";

interface CityFrom {
  id: Number;
  name: string;
  coordinates?: string;
}

@Component({
  selector: 'app-static-settings-panel',
  templateUrl: './static-settings-panel.component.html',
  styleUrls: ['./static-settings-panel.component.scss']
})
export class StaticSettingsPanelComponent {
  @ViewChild('cityFromField', {static: true}) cityFromField: FromSearchFieldComponent;
  @ViewChild('filterSelectorElement') filterSelectorElement: any;

  public availableMode: DatePickerMode[] = [DatePickerMode.Month, DatePickerMode.Date];
  public cityFrom$: Observable<CityFrom>;
  public date$: Observable<WhenDate[]>;
  public dropdownClose: boolean = false;
  public formData: Partial<SearchStateForm> = {
    date: [
      {
        type: DatePickerMode.Month,
        date: dayjs()
          .startOf('month')
          .add(1, 'month')
      }
    ],
    cityTo: true,
    tripType: TripTypeEnum.OneWay,
    passengers: 1,
    poi: []
  };
  public formDataUpdated: boolean = false;
  public isCityFromWasChanged: boolean = false;
  public passengers$: Observable<Number>;
  public tripType$: Observable<TripTypeEnum | string>;

  constructor(
    private store$: Store<State>
  ) {
    this.cityFrom$ = this.store$.pipe(select(getCityFrom));
    this.date$ = this.store$.pipe(select(getDate));
    this.tripType$ = this.store$.pipe(select(getTripType));
    this.passengers$ = this.store$.pipe(select(getPassengers));
  }

  closeCityFrom({isClosed, hasError}): void {
    this.dropdownClose = isClosed;

    if (isClosed && hasError) {
      this.cityFrom$.pipe(take(1)).subscribe((value) => {
        this.cityFromField.setValue(value.name);
        this.cityFromField.clearDestFilters();
      });
    }
  }

  public setCityFrom(city: any) {
    this.setFormDataAsUpdated();

    this.store$.dispatch(new fromLayer.SetCityFrom({
      name: city.place.name,
      id: city.place.id,
      coordinates: `${city.place.lat},${city.place.lng}`,
      iataCode: city.place.iataCode
    }));

    set(this.formData, 'cityFrom', {
      id: city.place.id,
      name: city.place.name,
      ...city.place,
    });

    this.isCityFromWasChanged = true;
  }

  public setTripWay(event: any) {
    this.setFormDataAsUpdated();

    this.formData.tripType = event.value;

    if (this.formData.tripType === TripTypeEnum.OneWay) {
      this.availableMode = [DatePickerMode.Date, DatePickerMode.Month];
      this.store$.dispatch(new SetTripType(TripTypeEnum.OneWay));
    } else {
      this.availableMode = [DatePickerMode.Date];
    }

    if (this.formData.tripType === TripTypeEnum.RoundTrip) {
      this.store$.dispatch(new SetTripType(TripTypeEnum.RoundTrip));

      const dateArr = [...this.formData.date];

      dateArr[0] = {
        ...dateArr[0],
        type: DatePickerMode.Date
      };
      dateArr[1] = {
        type: DatePickerMode.Date,
        date: dayjs(dateArr[0].date).add(1, 'month')
      };

      this.formData.date = dateArr;
    } else {
      this.formData.date = [this.formData.date[0]];
    }

    this.store$.dispatch(new SetWhenDate(this.formData.date));
  }

  public setWhen(index, when) {
    this.setFormDataAsUpdated();

    this.formData.date = [...this.formData.date];
    this.formData.date[index] = when[0];

    this.store$.dispatch(new SetWhenDate(this.formData.date));

    if (
      index === 0 &&
      this.formData.date[1] &&
      this.formData.date[0].date.isAfter(this.formData.date[1].date)
    ) {
      this.formData.date[1] = {
        ...this.formData.date[0],
        date: dayjs(this.formData.date[0].date).add(1, 'month')
      };
    }
  }

  public setPassenger(event: Number) {
    this.setFormDataAsUpdated();
    this.formData.passengers = event;
    this.store$.dispatch(new SetPassengers(event));
  }

  public setFormDataAsUpdated() {
    this.formDataUpdated = true;
    if (this.filterSelectorElement) {
      this.filterSelectorElement.setOptionState('CLEAR-FILTERS', true);
    }
  }
}
