import { Component, EventEmitter, Output } from '@angular/core';
import { constructBaseUrl } from "../../../libraries";
import { BUTTON_NAMES_JOURNEYS } from "../../../../constants";

@Component({
  selector: 'app-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss']
})
export class CreateCollectionComponent {

  public newTitleCollection = '';
  public btnName = BUTTON_NAMES_JOURNEYS;

  @Output() emitCreateCollection = new EventEmitter<string>();
  @Output() emitCancelCreateCollection = new EventEmitter<void>();

  constructor() {
  }

  public createJourney() {
    this.emitCreateCollection.emit(this.newTitleCollection);
  }

  public cancelJourneyCreation() {
    this.emitCancelCreateCollection.emit();
  }

  protected readonly constructBaseUrl = constructBaseUrl;
}
