<div class="terms-of-service-modal" [ngClass]="{'static-page':isStaticPage}">
  <div class="close-button">
    <app-close-btn (click)="closeWindow()"></app-close-btn>
  </div>
  <div class="terms-of-service-container">
    <div class="terms-of-service-content fly-m-t-10px fly-beauty-scroll">

      <section>

        <header>

          <h1> Terms and Conditions </h1>

        </header>

        <p>These Terms and Conditions ("Terms") govern your use of this website ("Site") and form a binding contractual agreement between you, the user of the Site and us, TTF TRAVEL COMPANY LTD (“Explorow”), operator of the Site.</p>

        <p>Explorow reserves the right at any time, at our sole discretion, to replace, change or otherwise modify the
          Agreement
          without prior notice by posting the revised version of this Agreement on the Website, and your continued
          access
          or use of this Website and the Services on and from the date it is posted to the Website signifies your
          acceptance
          of the replacement, updated or modified Agreement.</p>

        <p>Please read the Terms carefully, and in conjunction with our [Privacy Policy (link)] If you have any
          questions about
          the Terms, please contact us at info&#64;explorow.com</p>

        <p>By using the Site you acknowledge and agree that you have had sufficient chance to read and understand the
          Terms
          and you agree to be bound by them. If you do not agree to the Terms, please do not use the Site.</p>

        <h3> Using the Site </h3>
        <ol class="terms">

          <li>When you access the Site (‘Visit’), you are using software and website owned and/or operated by Explorow.
            For each
            Visit, Explorow grants you a personal non-exclusive license to use the Site for the duration of the Visit
            and
            for the sole purpose of making travel bookings. This licence does not permit you to make a Visit for the
            purpose
            of carrying on a business as a travel agent, unless you are a licensed travel agent under that legislation.
            Any
            other use, commercial or otherwise, on the Site without the express permission of Explorow is strictly
            prohibited.
          </li>

          <li>
            <h5>In using this Site and any associated services, you warrant that you -</h5>
            <ol>
              <li>are at least 18 years of age and have the legal capacity and authority to enter into a contract;</li>
              <li>agree to be bound by these Terms;</li>
              <li>are solely responsible for assessing the suitability of any travel booking for your needs;</li>
              <li>will use the Site and its associated Services only to make legitimate bookings for you or for another
                person
                under authorisation;
              </li>
              <li>if making a booking for another person, you will inform such other person about the Terms applicable
                to the
                booking and the fact that they are bound by these Terms;
              </li>
              <li>will only supply information to this Site that is true, accurate, current and complete;</li>
            </ol>
          </li>
          <li>
            <h5>
              In using our Site, you agree that you will not:
            </h5>
            <ol>
              <li>modify, copy, distribute, transmit, display, perform, reproduce, publish, licence, create derivative
                works
                from, transfer, or sell or resell any information, software, products, or services obtained from this
                Site;
              </li>
              <li>make a booking on this Site that is not a genuine booking, including any booking that is speculative,
                false,
                fraudulent or in anticipation of demand;
              </li>
              <li>copy, reproduce, republish, transmit or otherwise communicate to the public any content provided in
                this Website
                including, without limitation, text, graphics, button, downloads and software without the express
                written
                permission of Expedia, except where permitted by law;
              </li>
              <li>interfere or attempt to interfere with the proper functioning of the Site, including the transmission
                of any
                viruses, defects, Trojan horses or any other destructive interference;
              </li>
              <li>engage in excessive or unreasonable usage of the Site, including making excessive traffic demands on
                the Site
                infrastructure;
              </li>
              <li>scrape, data-mine, extract or collect any data or information from the Site in any form and by any
                means whatsoever;
              </li>
              <li>frame or mirror the content of the Site in any way;</li>
              <li>use any content from the Site for the purposes of competing with the Site, constructing or populating
                any public
                database of properties or property information, or engaging in direct marketing or any form of mass
                communication;
              </li>
              <li>violate the rights of any person, including using another person’s name, ID or password without
                authorisation,
                or violate another person’s privacy, intellectual property rights, and any other proprietary rights; and
              </li>
              <li>breach any applicable law or use this Site for any purpose that is prohibited by these Terms.</li>
            </ol>
          </li>


          <li>If your booking or account shows signs of fraud, suspicious activity or activity that may be in breach of
            these
            Terms, Explorow reserves the right to cancel bookings associated with your name, email address or account,
            and
            close any associated accounts or to take any necessary legal action against you.
          </li>

          <h3>Third party Suppliers</h3>

          <li>When you search for a relevant travel product or service on the Site, your search will display information
            about
            relevant travel products and services provided to the Site by third party suppliers (such as airlines or
            hotels
            or their agents) (“Third Party Suppliers”).
          </li>

          <li>You acknowledge and agree that the Site is a referral service only, and that any bookings made as a result
            of the
            referral will be regarded as a transaction between you and the Third Party Supplier.
          </li>

          <li>Explorow is not responsible for the accuracy of information supplied by the relevant Third Party
            Suppliers.
          </li>

          <li>Explorow is not responsible for the product or service booked through the Third Party Supplier or any
            cancellation
            or suspension of your account or transaction by a Third Party Supplier. Please contact the Third Party
            Supplier
            directly if you have any questions, problems or issues with the booking.
          </li>

          <li>You agree to abide by any additional terms and conditions imposed by a Third Party Supplier with whom you
            elect
            to deal.
          </li>

          <li>You acknowledge and agree that the Site endorse, sponsor or approve any content available on a Third Party
            Supplier
            website.
          </li>

          <h3>Your relationship with the Site</h3>

          <li>Explorow is a referral service only, and does not operate or control the products and services displayed
            on the
            Site. Explorow is not a co-vendor with Third Party Suppliers with whom you book a product or service.
          </li>

          <li>If you wish to change a booking made through a Third Party Supplier, or seek a refund for monies paid for
            a product
            or service through a Third Party Supplier referred to you by the Site, you acknowledge and agree that this
            is
            subject to the terms and conditions of the Third Party Supplier. Any changes to the booking or requests for
            refund
            for bookings made through a Third Party Supplier. In particular, you are advised that many airfares are
            non-refundable
            and you must check the terms and conditions applicable to the relevant airfare before proceeding with the
            booking
            with a Third Party Supplier.
          </li>

          <li>
            <h5> As a visitor or user of this Site, you acknowledge and agree that:</h5>
            <ol>
              <li>we retain complete editorial control over the Site and may alter, amend or cease the operation of the
                Site
                at any time in our sole discretion;
              </li>
              <li>the Site may be unavailable from time to time (including for maintenance purposes);</li>
              <li>you will keep any username and password, where applicable, to access the Site secure and confidential
                and will
                not provide these details to a third party under any circumstance and you accept liability for any
                unauthorised
                use of any username and password issued to you;
              </li>
              <li>you will only download, view or print a copy of the Site for personal, non-commercial use; and</li>
              <li>we serve the right to do whatever we deem necessary to prevent unauthorised access or use of the Site,
                including,
                but not limited to, terminating your right to use the Site, imposing Site barriers, or reporting your
                conduct
                to relevant authorities.
              </li>
            </ol>
          </li>


          <h3>Currency, fees, and GST</h3>

          <li>You acknowledge and agree that your bank and credit/debit card institution may charge you a transaction
            fee or
            a fee for a transaction in a foreign currency. This means the amount listed on your credit/debit card
            statement
            may be different to the figure shown on our Site. Explorow is not responsible for any discrepancy resulting
            from
            a fee charged by your bank, credit or debit card institution. Please contact your bank or credit/debit card
            institution
            directly if you have any questions about such fees or about the currency exchange rate.
          </li>

          <li>In some instances, the price displayed on our Site will have been converted from a foreign currency based
            on the
            conversation rates applicable on the day of the Visit. Any discrepancy in price due to the effect of foreign
            currency conversion will be minor only. You acknowledge and agree that currency conversion rates are
            variable
            and that any amount displayed is for guidance purposes only. Actual rates may vary. Explorow does not
            warrant
            or guarantee the accuracy of the amount converted from a foreign currency.
          </li>

          <h5> Frequent flyer and reward programs </h5>

          <li>You acknowledge and agree that you are solely responsible for entering the correct airline or hotel
            frequent flyer
            or other reward program number. Explorow is not responsible if your booking is not registered on the
            relevant
            airline or hotel rewards program.
          </li>

          <h5>Passports, Visas and Travel Documentation</h5>

          <li>You acknowledge and agree that you are solely responsible for complying with all relevant travel
            requirements for
            the countries you are travelling to and from, including but not limited to obtaining the relevant visas
            and/or
            other travel documentation. You should contact your nearest embassy, high commission or consulate of the
            country
            you intend to visit in advance of your travel to find out the visa and travel documentation requirements.
          </li>

          <li>You acknowledge and agree that you are solely responsible for ensuring that the name on your passport or
            other
            travel documentation matches with the name on your booking and travel insurance.
          </li>

          <li>You agree that you will contact the relevant carrier or booking service directly if, after purchasing
            products
            or services but before traveling, any member of your party changes their name on their travel documentation.
          </li>

          <h5>`Intellectual Property Rights`</h5>

          <li>All intellectual property in relation to content on this Site belongs to Explorow or its licensors, advertisers or affiliates, and is protected international intellectual property laws.</li>

          <li>You acknowledge that copyright subsists in all software, including HTML code, provided in association with
            a Visit.
            Where copying or transmission is expressly permitted, you must not change or delete any author attribution
            or
            copyright notice.
          </li>

          <li>You acknowledge and agree that you will not do anything which interferes with or breaches those laws or
            the intellectual
            property rights of Explorow or any third party on the site. You must not use the software associated with
            Site
            except in accordance with these Terms.
          </li>

          <h3> Liability </h3>

          <li>To the full extent permitted by law, we exclude all liability in respect of any direct, consequential,
            exemplary,
            incidental, special or punitive damages, and any other loss in connection with the Site. These include, but
            are
            not limited, to loss of profit, revenue, goodwill, reputation, capital, loss of data, interruption of
            business
            etc.
          </li>
          <li>To the full extent permitted by law, we are responsible or liable (including for negligence) for
            <ul>
              <li>any technical errors, corruption of data, unauthorised access to your personal data, inaccuracies in
                information
                supplied by Third Party Suppliers, or any failure to complete a booking due to circumstances beyond our
                control;
              </li>
              <li>the quality or suitability of any travel product or service booked through referral from this Site;
              </li>
              <li>any failure or delay on the part of any Third Party Supplier in providing a travel product or service
                you booked
                through referral from the Site;
              </li>
              <li>any acts or omissions of Third Party Supplier or other third parties in the course of delivery of any
                product
                or service you booked through referral from this Site.
              </li>
            </ul>
          </li>
          <li>In relation to any liability which cannot be exclude by law or despite the limitation above, then to the
            maximum
            extent permitted by law, we limit our liability, in the aggregate, to the greater of
            <ol>
              <li>the service fees you paid to Explorow in connection with such transaction(s) on the Site, or</li>
              <li>One-Hundred Dollars (AU$100.00).</li>
            </ol>
          </li>

          <li>To the full extent permitted by law, we exclude all representations, warranties or terms (whether express
            or implied)
            other than those expressly set out in these Terms.
          </li>

          <li>These Terms are to be read subject to any legislation which prohibits or restricts the exclusion,
            restriction or
            modification of any implied warranties, conditions, guarantees or obligations, including the Competition and
            Consumer Act 2010 (Cth) and similar legislation of the States and Territories. If such legislation applies,
            to
            the extent possible, we limit our liability in respect of any claim to, at our option:
            <ol>
              <li>the supply of the goods and/or services again; or</li>
              <li>the payment of the cost of having the goods and/or services rectified, repaired or supplied again, as
                applicable.
              </li>
            </ol>
          </li>

          <h3>Indemnity</h3>

          <li>You agree to indemnify and hold the Site, Explorow and our affiliates (including our officers, agents,
            partners
            and employees) against any and all loss, liability, claim or demand arising out of, or in connection with
            your
            use of and access to the Site, your breach of these Terms, or your breach of any law or rights of a third
            party.
          </li>

          <h3> Editorial, blog or social media content</h3>

          <li>Any editorial, blog or social media content on or associated with the Site is of a general nature and
            should not
            be relied upon by you or any other person as advice.
          </li>

          <h3>Termination</h3>

          <li>These Terms terminate automatically if, for any reason, we cease to operate the Site.</li>

          <li>We may otherwise terminate these Terms immediately, on notice to you, if you have breached these Terms in
            any way.These
            Terms terminate automatically if, for any reason, we cease to operate the Site.
          </li>

          <h3>General</h3>

          <li>If a provision of these Terms are invalid or unenforceable it is to be read down or severed to the extent
            necessary
            without affecting the validity or enforceability of the remaining provisions.These Terms terminate
            automatically
            if, for any reason, we cease to operate the Site.
          </li>

          <li>Each party must at its own expense do everything reasonably necessary to give full effect to the Terms and
            the
            events contemplated by it.These Terms terminate automatically if, for any reason, we cease to operate the
            Site.
          </li>
        </ol>

      </section>
    </div>
  </div>
</div>
