import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { PlaceGalleryMedia } from '../../../interfaces';
import { StaticRouteType } from '../../static/enums/route-type';
import { constructMediaURL } from '../../../libraries';
import { ImagesContentType } from "../../../enums/images-content-type.enum";
import { SsrService } from "../../../services";
import { KEY_CODE } from "../../static/components/static-gallery/static-gallery.component";
import { HelperService } from "../../../services/helper.service";
import { StaticService } from "../../../services/static.service";
import { StaticInitService } from "../../static/services/static-init.service";
import { getLinkByObject } from "../../../libraries/get-link-by-object";

@Component({
  selector: 'app-images-content',
  templateUrl: './images-content.component.html',
  styleUrls: ['./images-content.component.scss']
})
export class ImagesContentComponent implements OnChanges {

  @Input() galleryMediaData: PlaceGalleryMedia;
  @Input() page: StaticRouteType;
  @Input() type?: StaticRouteType;
  @Input() typeGallery?: ImagesContentType;
  @Input() place; // : Place;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;

  @Output() pinPlaceEmitter = new EventEmitter<any>();

  @ViewChild('galleryGridContainer', {static: true}) galleryGridContainer: ElementRef;
  @ViewChild('imagesContainer', {static: true}) imagesContainer: ElementRef;
  @ViewChild('prevImage', {static: true}) prevImage: ElementRef;
  @ViewChild('currentImage', {static: true}) currentImage: ElementRef;
  @ViewChild('nextImage', {static: true}) nextImage: ElementRef;

  public isGalleryOpen = false;
  public activeIndex = 0;
  public lastActiveIndex = 0;
  public sourceContainerWidth: number;
  public staticRouteType = StaticRouteType;
  public imagesContentType = ImagesContentType;
  public constructMediaURL = constructMediaURL;
  public isPortrait: boolean;
  public isLoadImage: boolean;

  protected userLink: string;

  private windowWidth = 0;

  @HostListener('window:resize')
  onResize() {
    if (!this.windowWidth) {
      this.windowWidth = window.innerWidth;
      return;
    }

    this.windowWidth = window.innerWidth;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.typeGallery === this.imagesContentType.slider) {
      if (event.key === KEY_CODE.ArrowRight) {
        this.onChangeImage(true);
      }

      if (event.key === KEY_CODE.ArrowLeft) {
        this.onChangeImage(false);
      }
    }
  }

  constructor(
    private ssrService: SsrService,
    private helperService: HelperService,
    private staticService: StaticService,
    public staticInitService: StaticInitService,
  ) {
  }

  ngOnChanges(changes: any): void {
    if (changes['place'] && changes['place'].currentValue) {
      this.place = {
        ...changes['place'].currentValue,
        isPlace: false,
      };

      this.activeIndex = 0;
      if (this.staticService.staticType !== StaticRouteType.Place) {
        this.galleryMediaData = this.staticService.getGalleryByPageType(this.place);
      }
      if (this.isOneVideoMode(this.galleryMediaData.galleryMedia[this.activeIndex])) {
        this.isPortrait = this.galleryMediaData.galleryMedia[this.activeIndex]?.isPortrait;
      }
      this.userLink = getLinkByObject(changes['place'].currentValue?.user);
    }
  }

  public overImage(image, value): void {
    if (image?.isHover) {
      image.isHover = value;
    }
  }

  public openImage(value: boolean, i?: number, image?: any): void {
    if (this.isOneVideoMode(image) && !this.staticService.isMobileOrTablet()) {
      return;
    }
    this.isGalleryOpen = value;
    this.helperService.isOpenGallery$.next(value);
    if (value) {
      this.activeIndex = this.getActiveIndex(i);
    }
  }

  public pinPlace(item): void {
    this.pinPlaceEmitter.emit(item);
  }

  public isNewContent(): boolean {
    return typeof window !== 'undefined' && window.location.hash === '#new';
  }

  public generateImagePath(image: any, i: number): string {
    let result;
    if (image) {
      if (this.isNewContent()) {
        result = image.large;
      } else if (i === 0) {
        result = image.medium || image.large;
      } else if (image.small) {
        result = image.small;
      } else {
        result = image.thumb;
      }
    }
    return constructMediaURL(result);
  }

  public onChangeImage(value: boolean): void {
    if (this.galleryMediaData?.allGalleryMedia?.length) {
      this.lastActiveIndex = this.activeIndex;
      if (value) {
        if (this.activeIndex === (this.galleryMediaData?.allGalleryMedia.length - 1)) {
          this.activeIndex = 0;
        } else {
          this.activeIndex++;
        }
      } else {
        if (this.activeIndex === 0) {
          this.activeIndex = this.galleryMediaData?.allGalleryMedia.length - 1;
        } else {
          this.activeIndex--;
        }
      }
    }
  }

  public isMobileDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile() || this.windowWidth <= 896;
  }

  public loadEmit(event: any) {
    this.isLoadImage = true;
    const data = event.target || this.galleryGridContainer?.nativeElement;

    if (this.galleryMediaData?.previewSliderImages?.length === 1) {
      setTimeout(() => {
        const offsetWidth = data?.offsetWidth;
        const offsetHeight = data?.offsetHeight;
        this.isPortrait = offsetHeight > offsetWidth;
        if (this.isPortrait) {
          this.sourceContainerWidth = offsetWidth;
        }
      }, 10)
    }
  }

  private getActiveIndex(index: number) {
    if (this.galleryMediaData?.galleryMedia?.length) {
      const currentItem = this.galleryMediaData.galleryMedia[index];
      if (currentItem) {
        return this.galleryMediaData.allGalleryMedia.findIndex(gallery =>
          currentItem.index === gallery.index
          && (currentItem?.isVideo
            ? currentItem.path === gallery.path
            : currentItem.thumb === gallery.thumb)
        );
      }
    }
    return 0;
  }

  protected readonly getLinkByObject = getLinkByObject;

  protected isOneVideoMode(image: any): boolean {
    return image?.isVideo && this.galleryMediaData?.previewSliderImages?.length === 1
  }

  openGallery(): void {
    this.isGalleryOpen = true;
  }
}
