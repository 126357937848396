<div class="collection-row"
     *ngIf="!isUserDomain"
     [class.profile-item]="isProfile">
  <a class="collection-row-item"
     *ngFor="let item of collections; let i = index"
     [routerLink]="withLink && '/journey/'+item.id"
     (click)="selectItem(item)">
    <ng-container *ngTemplateOutlet="collectionContainer; context: {item: item}"></ng-container>
  </a>
</div>

<div class="collection-row"
     *ngIf="isUserDomain"
     [class.profile-item]="isProfile">
  <a class="collection-row-item"
     *ngFor="let item of collections; let i = index"
     [href]="withLink && constructBaseUrl('/journey/'+item.id) || sanitizer.bypassSecurityTrustUrl('javascript:void(0);')"
     (click)="selectItem(item)">
    <ng-container *ngTemplateOutlet="collectionContainer; context: {item: item}"></ng-container>
  </a>
</div>

<ng-template #collectionContainer let-item="item">
  <div class="collection-row-title cut-text"
       appTooltip
       [class.favorite-text]="item.isFavorite"
       [label]="item.tooltip"
       [onlyLongText]="true"
       [startOver]="true"
       [blueStyle]="true">
    {{item.name ? item.name : defaultTitleCollection}}
  </div>
  <div class="collection-row-body">
    <div class="container__images gallery-grid-container"
         [class.one-picture]="imagesCollection[item.id]?.length === 1"
         *ngIf="imagesCollection.length">
      <div class="image-item grid-container-item" *ngFor="let img of imagesCollection[item.id]"><img
        alt="picture"
        src="{{img === defaultCollectionImage ? constructBaseUrl('/assets/images/' + img) :  constructMediaURL(img)}}">
      </div>
    </div>
    <div class="date-container">
      <p class="date">{{item.dates | minMaxDate }}</p>
    </div>
    <div class="collection-row-count-pin">{{item.counter}}</div>
  </div>
</ng-template>
