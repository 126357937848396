<div class="image-container"
     [class.zoom-in]="zoomIn">

  <ng-container *ngIf="oneElement && videoData && !staticService.isMobileOrTablet(); then oneVideoMode; else normalMode"></ng-container>

  <ng-template #normalMode>
    <div *ngIf="playBtn"
         class="play-button-container"
    >
      <img src="/assets/icons/play-button.svg"
           class="video-control"
           alt="video-control">
    </div>

    <img class="main-img"
         [style.position]="blur && 'absolute'"
         [class.isPortrait]="isPortrait"
         [src]="contentSrc"
         [alt]="alt"
         loading="lazy"
         (load)="loadEmit($event)">
  </ng-template>

  <ng-template #oneVideoMode>
    <app-video-player
      [video]="videoData"
      [isShowGalleryControl]="true"
      [isExpandVisible]="true"
      (expandEmitter)="showGallery()"
    ></app-video-player>
  </ng-template>

  <ng-container *ngIf="blur">
    <img class="bg-blur-image"
         [src]="contentSrc"
         [alt]="alt"
         loading="lazy">
  </ng-container>

</div>
