import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InnerDataStorageService, TooltipData } from '../../services';
import { doAsync, waitObservable } from '../../libraries';
import { ToastrService } from '../../services/toastr.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-fly-tooltip',
  templateUrl: 'fly-tooltip.component.html',
  styleUrls: ['fly-tooltip.component.scss']
})
export class FlyTooltipComponent implements OnDestroy, OnInit {

  public backwards: boolean = false;
  public blueStyle: boolean;
  public delay: number = 250;
  public fontSize;
  public label;
  public left: any;
  public margin: number | null;
  public observableId;
  public position: string | null;
  public title: string | null; //SafeHtml |
  public top: any;
  public un: string = 'px';
  public width: string | null = null;
  public window: any;
  public startOver: boolean;
  public absolutePosition: boolean;

  @ViewChild('tooltip') tooltip;

  private $destroyed = new Subject<void>();

  constructor(
    public innerData: InnerDataStorageService,
    public el: ElementRef,
    public toastrService: ToastrService,
    public domSanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.observeMouseEnter();
    this.toastrService.closeTooltip$
      .pipe(
        takeUntil(this.$destroyed)
      )
      .subscribe(() => this.close());
  }

  ngOnDestroy() {
    this.close();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  observeMouseEnter() {
    this.observableId = waitObservable(
      () => this.innerData.tooltipData,
      (tooltipData: TooltipData) => {
        if (!this.toastrService.openTooltip) {
          this.toastrService.openTooltip = true;
          if (tooltipData.title) {
            this.title = tooltipData.title;
          }
          if (tooltipData.label) {
            this.label = this.domSanitizer.bypassSecurityTrustHtml(tooltipData.label);
          }
          this.blueStyle = tooltipData.blueStyle;
          this.startOver = tooltipData.startOver;
          this.absolutePosition = tooltipData.absolutePosition;
          this.elemWait(tooltipData);
        }
        this.observeMouseLeave();
      }, this.delay
    );
  }

  observeMouseLeave() {
    this.observableId = waitObservable(
      () => !this.innerData.tooltipData,
      () => {
        if(this.toastrService.openTooltip) {
          this.toastrService.openTooltip = false;
          this.backwards = true;
          doAsync(() => {
            this.clear();
            this.observeMouseEnter();
          }, 200);
        }
      }, this.delay
    );
  }

  elemWait(tooltipData: TooltipData) {
    doAsync(() => {
      const r = tooltipData.boundInClientRect;
      const dTop = this.blueStyle ? 0 : r.height;
      this.width = this.blueStyle ? `${r.width / 2}${this.un}` : 'auto';
      this.fontSize = tooltipData.fontSize;
      this.position = tooltipData.position;
      this.margin = tooltipData.margin ? parseInt(tooltipData.margin, 10) : null;
      this.top = `${r.top - dTop - (this.margin || 0) - 20}${this.un}`;
      if (this.startOver && this.blueStyle) {
        this.left = `${r.left}${this.un}`;
      } else if (this.startOver && !this.blueStyle && !this.absolutePosition) {
        this.left = `${r.left - 13}${this.un}`;
      } else if (this.startOver && this.absolutePosition) {
        this.left = `${r.left - 30}${this.un}`;
      } else {
        this.left = `${(r.left + (r.width / 2)) - 13}${this.un}`;
      }
    });
  }

  clear() {
    this.title = null;
    this.position = null;
    this.top = null;
    this.left = null;
    this.backwards = false;
    this.label = null;
  }

  close() {
    this.clear();
  }

}
