import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { Router } from '@angular/router';
import { RouterHistoryService, SsrService, usertoken } from '../../services';
import { constructBaseUrl, getWindow } from '../../libraries';
import { isPlatformBrowser } from '@angular/common';
import { maxMobileWidth } from "../../services/helper.service";
import { AfterAuthAction } from "../../enums/after-auth.action";
import { GuideElementsEnum } from "../../enums/guide-elements.enum";

@Component({
  selector: 'app-fly-add-button',
  templateUrl: './fly-add-button.component.html',
  styleUrls: ['./fly-add-button.component.scss']
})
export class FlyAddButtonComponent implements OnInit {

  @Input() userIsLogged: boolean;
  @Input() title: string;

  @Output() toggleRegisterFormEmit = new EventEmitter;

  public currentRoute: string;
  public mobileMode: boolean;
  public image: any
  public video: any;

  protected readonly addPlaceType: GuideElementsEnum = GuideElementsEnum.AddPlace;
  protected isGuideVisible: boolean;

  private readonly isBrowser: boolean;
  private readonly isUserDomain: boolean;

  @HostListener('window:resize', ['$event'])
  public checkMobile() {
    if (this.isBrowser) {
      const w = getWindow();
      if (w) {
        this.mobileMode = w.innerWidth <= maxMobileWidth;
      }
    }
  }

  constructor(
    private cookieService: SsrCookieService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly routerHistoryService: RouterHistoryService,
    ssrService: SsrService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isUserDomain = ssrService.getIsUserDomain();
  }

  ngOnInit(): void {
    this.currentRoute = this.routerHistoryService.currentUrl$.getValue().split('/')[1] || '';
    this.checkMobile();
  }

  public openAddLink(): void {
    if (this.userIsLogged || this.cookieService.get(usertoken)) {
      if (this.isUserDomain) {
        window.location.href = constructBaseUrl('/place/add');
      } else {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
          this.router.navigate(['/place/add']));
      }
    } else {
      if (this.isBrowser) {
        localStorage.setItem('nextActionAfterAuth', AfterAuthAction.AddPlace);
      }
      this.toggleRegisterFormEmit.emit();
    }
  }

  public emitAction(funcName): void {
    this[funcName.action](funcName.value);
  }

  protected changeGuideState(isGuideVisible: boolean): void {
    this.isGuideVisible = isGuideVisible;
  }
}
