<div class="old-static-page">

  <app-static-header
    [data]="staticService.placesData"
    (openUserAccountEmitter)="staticService.openUserAccount($event)"
  ></app-static-header>

  <app-pinned-panel-container></app-pinned-panel-container>

  <div class="wrapper">
    <div class="content" *ngIf="countries">
      <h1 class="most-popular-label">Most popular cities</h1>

      <div class="list-wrapper">
        <div class="list-container">
          <div class="interesting-cities-container"
               [ngClass]="{'left-align': popularCities.length < 5, 'center-align': popularCities.length >= 5}">
            <app-page-interesting-city class="interesting-cities" *ngFor="let city of popularCities" [city]="city"
            ></app-page-interesting-city>
          </div>
        </div>
      </div>

      <div class="cities-container">
        <h1 class="list-cities-label">List of cities</h1>

        <div *ngFor="let country of countries">
          <a class="link" href="{{country.url}}">{{country.name}}</a>
          <ul class="cities-list">
            <li *ngFor="let city of country.cities" class="city-name">
              <a class="link" href="{{city.url}}">{{city.name}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
