import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getCityTo, getVisibleCountries, State } from '../reducers';
import { Observable } from 'rxjs/internal/Observable';
import { MapActionsTypes, MapActionsUnion, SetNewBounds, SetVisibleCountries } from './map.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { CountryInterface, CountryService } from '../../services';
import difference from 'lodash/difference';
import { DestinationType } from '../../enums/destination-type';
import { Point } from "../../interfaces";

@Injectable()
export class MapEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private countryService: CountryService,
  ) {
  }


  SetNewBounds$: Observable<MapActionsUnion | void> = createEffect(() => this.actions$.pipe(
    ofType<SetNewBounds>(MapActionsTypes.SetNewBounds),
    map((action: { payload: google.maps.LatLngBounds }): string[] => {
      return this.countryService.getCountriesByMapBounds(action.payload).map((country: CountryInterface) => country.id);
    }),
    withLatestFrom(
      this.store$.pipe(select(getVisibleCountries)),
    ),
    withLatestFrom(
      this.store$.pipe(select(getCityTo)),
    ),
    map(([[newVisibleCountries, currentVisibleCountries], pointTo]: [[string[], string[]], Point]) => {
      if (!!pointTo && pointTo.type === DestinationType.Country) {
        return new SetVisibleCountries([pointTo.countryCode]);
      }
      const countries: string[] = difference(newVisibleCountries, currentVisibleCountries);
      return new SetVisibleCountries([...currentVisibleCountries, ...countries]);
    }),
  ));
}
