import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { constructBaseUrl, constructMediaURL } from "../../../libraries";

@Component({
  selector: 'app-fly-popup-profile',
  templateUrl: './fly-popup-profile.component.html',
  styleUrls: [
    '../fly-popup.component.scss',
    './fly-popup-profile.component.scss']
})
export class FlyPopupProfileComponent implements OnChanges {
  @Input() data;
  @Input() mobileMode: boolean;
  @Input() isAddPopup: boolean;
  @Input() maxWidth: boolean;
  @Input() moveToRight: boolean;
  @Input() togglePopupMethod = 'togglePopup'

  @Output() emitAction = new EventEmitter();
  public optionData: any;
  public selectedOption: string;

  constructor() {
  }

  ngOnChanges(): void {
    this.getFilteredOptions();
  }

  public clickRow(action, value = null, pageType = null) {
    this.emitAction.emit({action, value, pageType});
    if (action !== this.togglePopupMethod) {
      this.closePopup();
    }
  }

  /**
   * @see SignInButtonsComponent.togglePopup
   */
  public closePopup() {
    this.emitAction.emit({action: this.togglePopupMethod, value: false});
  }

  public getFilteredOptions() {
    this.optionData = JSON.parse(JSON.stringify(this.data));
    this.selectedOption = this.optionData[0].title;
  }

  public setActive(optionTitle: string) {
    this.selectedOption = optionTitle;
  }

  protected readonly constructBaseUrl = constructBaseUrl;
  protected readonly constructMediaURL = constructMediaURL;
}
