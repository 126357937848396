<div class="close" (click)="close()">
  <figure class="close-popup">
    <img [appBasesrc]="'/assets/cross.svg'" class='close-popup-img' alt="close" title="close">
  </figure>
</div>
<div class="pinned__container">

  <div class="create-collection">
    <div class="collection-container">
      <div class="collection-action" [class.cursor-default]="!allCollections.length"
           (click)="allCollections.length && toggleOpenCollection()">
        <div class="separator" *ngIf="allCollections.length" [class.open]="!isOpenCollection">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
               y="0px"
               viewBox="0 0 1000 1000"
               enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g><g><path d="M124.8,197.6L500,572.7l375.2-375.2L990,312.4l-490,490l-490-490L124.8,197.6z"/></g></g>
          </svg>
        </div>
        <p>Journeys</p>
      </div>

      <ng-container *ngIf="userIsLogged || (!userIsLogged && allCollections?.length < 2)">
        <ng-template [ngTemplateOutlet]="createJourneyContainer"></ng-template>
      </ng-container>

      <app-sign-in-buttons *ngIf="!this.userIsLogged && this.allCollections?.length > 1"
                           [isNotSignInButton]="createJourneyContainer"
                           [showLoginForm]="false"></app-sign-in-buttons>

      <ng-template #createJourneyContainer>
        <img class="add-img" [appBasesrc]="'/assets/icons/plus.svg'" (click)="openCreateCollectionField()"/>
      </ng-template>
    </div>
  </div>

  <app-pinned-panel-collection [collections]="allCollections"
                               (emitSelectItem)="selectCollectionItem($event)"
                               *ngIf="!isOpenCollection && allCollections.length"></app-pinned-panel-collection>

  <app-pinned-panel-collection-info [mode]="mode"
                                    [class.hidden]="!isOpenCollection && !isCreateCollection"
                                    [isCreate]="isCreateCollection"
                                    [selectCollectionData]="selectCollectionData"
                                    [allJourneys]="allCollections"
                                    (deletePin)="deleteItem()"
                                    (emitOpenCreateCollectionField)="openCreateCollectionField($event)"
                                    (emitCancelCreateCollection)="cancelCreateCollection()"
                                    (openPlaceEmitter)="openPlace($event)"
                                    (emitCreateCollection)="createCollection($event)"></app-pinned-panel-collection-info>
</div>
