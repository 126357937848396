import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { SignupComponent } from './modules/signup/signup.component';
import { AuthResetComponent } from './modules/auth-reset/auth-reset.component';
import { AuthExternalComponent } from './modules/auth-external/auth-external.component';
import { SiteMapComponent } from './shared-components/site-map/site-map.component';
import { PrivacyPolicyComponent } from './modules/shared/footer/components/privacy-policy/privacy-policy.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RouteNamesEnum } from './app-routing.interface';
import { DateResolverService } from './store/layer/date.resolver.service';
import { CookiePolicyComponent } from './modules/shared/footer/components/cookie-policy/cookie-policy.component';
import { TermOfUseComponent } from './modules/shared/footer/components/term-of-use/term-of-use.component';
import { ListCitiesComponent } from './shared-components/list-cities/list-cities.component';
import { StaticComponent } from './modules/static/static.component';
import { CopyrightInfringementComponent } from './modules/copyright-infringement/copyright-infringement.component';
import { CreatePlaceComponent } from './modules/place/create-place.component';
import { AccountPageTypesEnum, UserAccountComponent } from './modules/user-account/user-account.component';
import { AuthGuard } from './guards/auth.guard';
import { StaticRouteType } from './modules/static/enums/route-type';
import { RedirectGuard } from './guards/redirect-guard.service';
import { AddDataTypesEnum } from "./enums/add-data-types.enum";
import { IsNumGuard } from "./guards/is-num.guard";
import { StaticHomeComponent } from "./modules/static/components/static-home/static-home.component";
import {
  StaticCountryInfoComponent
} from "./modules/static/components/static-country-info/static-country-info.component";
import {
  StaticUserPersonalInfoComponent
} from "./modules/static/components/static-user-personal-info/static-user-personal-info.component";
import { StaticPlaceInfoComponent } from "./modules/static/components/static-place-info/static-place-info.component";
import { StaticCityInfoComponent } from "./modules/static/components/static-city-info/static-city-info.component";
import {
  StaticCategoryInfoComponent
} from "./modules/static/components/static-category-info/static-category-info.component";
import { LandingBusinessComponent } from "./modules/landing-business/landing-business.component";
import {
  LandingTravelBusinessComponent
} from "./modules/landing-business/landing-travel-business/landing-travel-business.component";
import { userDomainGuard } from "./guards/user-domain-detector";
import { UserUrlSettings } from "./interfaces/user";
import { SERVICE_UNAVAILABLE } from "../constants";

let routes: Routes;

routes = [
  {
    path: '',
    component: StaticComponent,
    canMatch: [userDomainGuard],
    pathMatch: 'full',
    children: [
      {
        path: '',
        component: StaticUserPersonalInfoComponent,
        data: {static: StaticRouteType.UserPersonalPage, paramType: UserUrlSettings.Subdomain},
        resolve: {state: DateResolverService},
      },
    ]
  },
  {
    path: 'place/add',
    component: CreatePlaceComponent,
    canActivate: [AuthGuard],
    data: {name: AddDataTypesEnum.PlaceAdd},
    resolve: {state: DateResolverService},
  },
  {
    path: 'review/add',
    component: CreatePlaceComponent,
    canActivate: [AuthGuard],
    data: {name: AddDataTypesEnum.ReviewAdd},
    resolve: {state: DateResolverService},
  },
  {
    path: 'place/edit/:id',
    component: CreatePlaceComponent,
    canActivate: [AuthGuard, IsNumGuard],
    data: {name: AddDataTypesEnum.PlaceEdit},
    resolve: {state: DateResolverService},
  },
  {
    path: 'account',
    component: UserAccountComponent,
    canActivate: [AuthGuard],
    data: {name: AccountPageTypesEnum.Account},
    resolve: {state: DateResolverService},
  },
  {
    path: 'account/profile',
    component: UserAccountComponent,
    canActivate: [AuthGuard],
    data: {name: AccountPageTypesEnum.BusinessProfile},
    resolve: {state: DateResolverService},
  },
  {
    path: 'account/info',
    component: UserAccountComponent,
    canActivate: [AuthGuard],
    data: {name: AccountPageTypesEnum.StandardProfile},
    resolve: {state: DateResolverService},
  },
  {path: 'signup/confirm', component: SignupComponent},
  {path: 'email/confirm', component: SignupComponent},
  {path: 'auth/reset', component: AuthResetComponent},
  {path: 'auth/external', component: AuthExternalComponent},
  {
    path: 'site-map',
    component: SiteMapComponent,
    data: {name: 'site-map'},
    resolve: {state: DateResolverService},
  },
  {
    path: 'list-cities',
    component: ListCitiesComponent,
    data: {name: 'list-cities'},
    resolve: {state: DateResolverService},
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: {name: 'cookie-policy'},
    resolve: {state: DateResolverService},
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {name: 'privacy-policy'},
    resolve: {state: DateResolverService},
  },
  {
    path: 'terms-of-use',
    component: TermOfUseComponent,
    data: {name: 'terms-of-use'},
    resolve: {state: DateResolverService},
  },
  {
    path: 'copyright-infringement',
    component: CopyrightInfringementComponent,
    data: {name: 'copyright-infringement'},
    resolve: {state: DateResolverService},
  },
  {path: SERVICE_UNAVAILABLE, component: PageNotFoundComponent, data: {type: SERVICE_UNAVAILABLE}},
  {path: '404', component: PageNotFoundComponent},
  {
    path: 'help/travel-business-page',
    component: LandingBusinessComponent,
    resolve: {state: DateResolverService},
  },
  {
    path: 'help/travel-business',
    component: LandingTravelBusinessComponent,
    resolve: {state: DateResolverService},
  },
  {
    path: 'country/:name',
    children: [],
    data: {name: RouteNamesEnum.Country},
    canActivate: [RedirectGuard]
  },
  {
    path: '',
    component: StaticComponent,
    children: [
      {
        path: '',
        component: StaticHomeComponent,
        data: {static: StaticRouteType.HomePage},
        resolve: {state: DateResolverService},
      },
      {
        path: 'places/:name/:id',
        component: StaticPlaceInfoComponent,
        data: {static: StaticRouteType.Place},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'places/:placeName/:placeId/review/:reviewId',
        component: StaticPlaceInfoComponent,
        data: {static: StaticRouteType.Review},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'country/:name/:id',
        component: StaticCountryInfoComponent,
        data: {static: StaticRouteType.Country},
        resolve: {state: DateResolverService},
      },
      {
        path: 'city/:name/:id',
        component: StaticCityInfoComponent,
        data: {static: StaticRouteType.City},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'experiences/:name/:tagId/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.Experiences},
        resolve: {state: DateResolverService}
      },
      {
        path: 'things-to-do/:name/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.ThingsToDo},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'tag-city/:name/:tagId/:cityToName/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.TagCity},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'category/city/:name/:tagId/:cityToName/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.CategoryCity},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'category/country/:name/:tagId/:countryToName/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.CategoryCountry},
        resolve: {state: DateResolverService},
      },
      {
        path: 'search',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.Search},
        resolve: {state: DateResolverService},
      },
      {
        path: 'travel/:name/:id/:typeId',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.Travel},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'travel/:name/:id/:typeId/:allPlacesId',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.Travel},
        resolve: {state: DateResolverService}
      },
      {
        path: 'journey/:id',
        component: StaticCategoryInfoComponent,
        data: {static: StaticRouteType.Journey},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        path: 'ur/:id',
        component: StaticUserPersonalInfoComponent,
        data: {static: StaticRouteType.UserPersonalPage, paramType: UserUrlSettings.Id},
        resolve: {state: DateResolverService},
        canActivate: [IsNumGuard]
      },
      {
        matcher: function (url: UrlSegment[]) {
          const notAllowed = /[()`&’"'.!“«:\/|,+]/;
          return url.length === 1 && !notAllowed.test(url[0].path) ? ({
            consumed: url,
            posParams: {name: url[0]}
          }) : null;
        },
        component: StaticUserPersonalInfoComponent,
        data: {static: StaticRouteType.UserPersonalPage, paramType: UserUrlSettings.Name},
        resolve: {state: DateResolverService}
      },
    ]
  },
  {path: '**', component: PageNotFoundComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
