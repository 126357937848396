<div class="collection__container" [class.m-0]="isBottomSheet">

  <ng-container *ngIf="journey && !isCreate && !isEdit">
    <div class="collection__title__container" *ngIf="isTitleEnabled">
      <div class="collection__title fit-text">

        <div *ngIf="!isBottomSheet"
             id="collectionTitleEl"
             class="collection__title-text"
             [ngClass]="{
              'cursor-disabled':isFavorites,
              'favorite-color':isFavorites
            }"
             (click)="redirectToJourney()">
          {{ journeyTitle ? journeyTitle : defaultJourneyTitle }}
        </div>

        <div *ngIf="isBottomSheet" class="collection__title-text" (click)="openBottomSheet()">
          {{ journeyTitle ? journeyTitle : defaultJourneyTitle }}
        </div>

        <div class="collection__title__actions" *ngIf="!isFavorites"
             [ngClass]="{
              'position-absolute': sideAlign==='left',
              'position-relative': sideAlign==='right'
            }"
        >
          <div *ngFor="let item of popupData.journeyActions"
               class="tooltip-event-item"
               [class.hidden]="item.isVisible !== undefined && !item.isVisible(journey)"
               (click)="emitAction(item.action)">
            <img [appBasesrc]="'/assets/icons/'+item.icon"/>
            {{ item.actionTitle }}
            <span class="tooltip-event">{{item.iconTitle}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="collection__description"
         *ngIf="!isOpenDescriptionField && journeyDescription?.length"
         [innerHTML]="journeyDescription">
    </div>

    <div class="collection__title-container" *ngIf="isOpenDescriptionField">
      <div class="collection__title-input m-0">
        <app-editor
          (changeEmitter)="setDescription($event)"
          (initEmitter)="initEditor($event)"
          [data]="journeyDescription">
        </app-editor>
        <div class="alert alert-danger" *ngIf="descriptionError">{{ descriptionError }}</div>
      </div>

      <div class="btn-container">
        <button class="btn-content" (click)="updateJourneyDescription()">
          Save
        </button>
        <button class="btn btn-default discard" (click)="cancelEditJourneyDescription()">
          discard
        </button>
      </div>
    </div>

    <div class="collection__description__controls" [class.edit-description]="!!journeyDescription?.length" *ngIf="!isFavorites && !isOpenDescriptionField">
      <a class="collection__description__action" (click)="toggleEditJourneyDescription()">
        {{ journeyDescription?.length ? 'Edit description' : 'Add a description' }}</a>
      <div class="collection__description__subtext" *ngIf="!journeyDescription?.length"
           (click)="toggleEditJourneyDescription()">
        Write a text about it ...
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="items && items.length > 0 && !isPinnedLoading;
                else (isPinnedLoading ? loadingItems : notFoundItems)">
    <ng-container *ngIf="!isCreate && !isEdit">
      <div class="dragndrop-tip" *ngIf="isDragAndDropAvailable() && (!staticService.isMobileDesign() || isDragAndDropEnabled )">
        Hold and drag <img [appBasesrc]="'/assets/icons/dragndrop.svg'" alt="dragndrop"> to reorder
      </div>
      <div class="dragndrop-tip mobile" *ngIf="isDragAndDropAvailable() && !isDragAndDropEnabled && staticService.isMobileDesign()"
           (click)="enableDragAndDrop()">
        <span [class.enabled]="isDragAndDropEnabled">Change Order <img [appBasesrc]="'/assets/icons/dragndrop-white.svg'" alt="dragndrop"></span>
      </div>

      <div class="btn-container"
           *ngIf="isDragAndDropAvailable() && isDragAndDropEnabled && staticService.isMobileDesign()">
        <button class="btn-content" (click)="updatePlaceOrder()" *ngIf="!isSaveOrderDisabled()">
          Save order
        </button>
        <button
          *ngIf="staticService.isMobileDesign()"
          class="btn btn-default discard"
          (click)="disableDragAndDrop()">
          discard
        </button>
      </div>

      <img [appBasesrc]="'/assets/icons/arrow-j-up.svg'" alt="up"
           class="scroll-btn top"
           (click)="scrollUp()"
           *ngIf="isDragAndDropAvailable() && isDragAndDropEnabled && staticService.isMobileDesign()">
      <img [appBasesrc]="'/assets/icons/arrow-j-down.svg'" alt="down"
           class="scroll-btn bottom"
           (click)="scrollDown()"
           *ngIf="isDragAndDropAvailable() && isDragAndDropEnabled && staticService.isMobileDesign()">

      <div class="pinned__item-content"
           [class.profile]="isProfile"
           cdkDropListGroup
           [cdkDropListGroupDisabled]="!isDragAndDropAvailable() || !isDragAndDropEnabled">
        <ng-container *ngFor="let item of items; let i = index">
          <div cdkDropList
               cdkDropListOrientation="horizontal"
               [class.single-media]="!isDragAndDropAvailable() || !isDragAndDropEnabled"
               [cdkDropListData]="{item:item,index:i}"
               (cdkDropListDropped)="drop($event)">
            <app-pinned-panel-item
              class="pinned__item-container" cdkDrag
              [month]="month"
              [isFavorites]="isFavorites"
              [action]="item?.action ? item.action : null"
              [currentPlace]="item"
              [isProfile]="isProfile"
              [isBottomSheet]="isBottomSheet"
              [collections]="allJourneys"
              [disablePopup]="isFavorites"
              [popupPinData]="!isFavorites && popupData.pin"
              (changeMode)="changeMode($event)"
              (deletePinned)="deleteItem($event)"
              (emitCreateCollection)="openCreateCollectionField($event)"
              (emitOpenBottomSheet)="openBottomSheet()"
              (emitChangeCollection)="changeCollection($event)"
              (openPlaceEmitter)="openPlace($event)"
            ></app-pinned-panel-item>
          </div>
        </ng-container>
      </div>

      <div class="btn-container order-btn-bottom" *ngIf="isDragAndDropAvailable() && isDragAndDropEnabled">
        <button class="btn-content" (click)="updatePlaceOrder()" *ngIf="!isSaveOrderDisabled()">
          Save order
        </button>
        <button
          *ngIf="staticService.isMobileDesign()"
          class="btn btn-default discard"
          (click)="disableDragAndDrop()">
          discard
        </button>
      </div>

    </ng-container>
  </ng-container>

  <div class="collection__title-container" *ngIf="isEdit">
    <div class="collection__title-input">
      <input type="text"
             #titleRef
             maxlength="50"
             [(ngModel)]="journeyTitle"
             (keydown.enter)="updateTitleCollection()">
      <div class="collection__title-btn">
        <div class="collection__title-ok"
             *ngIf="titleRef.value.length"
             (click)="updateTitleCollection()">{{ btnName.ok }}
        </div>
        <div class="collection__title-cancel" (click)="cancelEditTitleCollection()">
          <img [appBasesrc]="'/assets/cancel_black.svg'" alt="">
        </div>
      </div>
    </div>
    <p class="input-counter">{{ titleRef.value.length }} / 50 characters</p>
  </div>

  <div *ngIf="isCreate">
    <app-create-collection (emitCreateCollection)="createCollection($event)"
                           (emitCancelCreateCollection)="cancelCreateCollection()"></app-create-collection>
  </div>
</div>

<ng-template #notFoundItems>
  <div class="pinned__item-content" *ngIf="!isCreate && !isEdit">
    <div class="pinned-desc" [ngSwitch]="true">
      <ng-container *ngSwitchCase="isFavorites">
        <span>You don't have any liked places yet</span>
        <span class="sub-title-desc">
          Just click on the heart icon <img [appBasesrc]="'/assets/icons/liked-icon-off.svg'" alt="like"> to save it
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="!!journey && !isFavorites">
        <span class="empty-places">You don't have any saved places yet</span>
        <span class="sub-title-desc create-place">
           Add a travel place or attraction to the map
          <img [appBasesrc]="'/assets/icons/plus-round-gr.svg'" alt="create"
               (click)="openAddPlaceLink()">
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="!journey && getMode() === PinnedModeIdx.Journeys">
        <span>You don't have any Journeys yet</span>
        <span class="sub-title-desc">
          Just click on the icon plus
          <img [appBasesrc]="'/assets/icons/plus.svg'" alt="create" class="collection-btn"
               (click)="openCreateCollectionField()">
          to create one
        </span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span>{{ "You don't have any " + getMode() + " yet" }}</span>
        <span class="sub-title-desc">
          Just click on the blue icon <img [appBasesrc]="'/assets/pin/unactive-pin.svg'" alt="pin"> to pin it
        </span>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #loadingItems>
  <div class="pinned__item-content">
    <div class="pinned-desc">
      <span>Loading...</span>
    </div>
  </div>
</ng-template>

<app-confirm-dialog
  *ngIf="isDeleteConfirmationVisible"
  [message]="'Are you sure that you want to delete this journey?'"
  (action)="deleteCollection($event)">
</app-confirm-dialog>
