import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { EARTH_BOUNDS, explorowGoogleMapStyle, MAP_TYPE_ID } from '../../../map/map-page/map.style';
import { Subject } from 'rxjs/internal/Subject';
import { PlaceNew, PlaceWithCalculationDataNew } from '../../../../interfaces';
import { clearObservables, constructBaseUrl, doAsync, waitObservable } from '../../../../libraries';
import { GroupingPlacesService, latLngToPoint, MapService, WindowRef } from '../../../../services';
import {
  MAX_ZOOM,
  MIN_ZOOM,
  TOOLTIP_BOTTOM_ARROW_HEIGHT,
  TOOLTIP_HEIGHT,
  TOOLTIP_HEIGHT_MARGIN_TOP,
  TOOLTIP_WIDTH,
  TOOLTIP_WIDTH_MARGIN_RIGHT
} from '../../../../../constants';
import { setDisableZoomBtn } from '../../../../libraries/map/disable-zoom-buttons';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PointSizeTypeEnum } from '../../../../enums/point-size-type.enum';
import { GoogleMapsService } from '../../../../services/google-maps.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { StaticRouteType } from "../../enums/route-type";
import { StaticService } from "../../../../services/static.service";
import { DestinationType } from "../../../../enums/destination-type";
import { latToPixelY } from "../../../../libraries/map/lat-to-point";
import { pixelYToLat } from "../../../../libraries/map/point-to-lat";
import clone from 'lodash/clone';
import { shallowEqual } from "../../../../libraries/shallow-equal";
import { getViewPortSize } from "../../../../libraries/get-viewport-size";
import Icon = google.maps.Icon;

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.scss'],
})
//TODO: should be refactored, particularly process of map loader
export class StaticMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() allPlaces: PlaceNew[];
  @Input() flightPlaceInfo: any;
  @Input() idFrom: number;
  @Input() isMobile: boolean;
  @Input() isUpdateTravelParams: boolean;
  @Input() month: any;
  @Input() nameFrom: string;
  @Input() receivedCity: any;
  @Input() searchMapExtra: any;
  @Input() showPlace: any;
  @Input() userInfo: any;
  @Input() zoom: number;
  @Input() staticType: StaticRouteType;

  @Input() set isMapFixed(value: boolean) {
    if (typeof (value) !== 'boolean') {
      return;
    }
    this.isScrolling = true;
    this._isMapFixed = value;
  }

  @Input() set setVisibleOfTooltip(value: PlaceNew) {
    if (!value) {
      return;
    }
    if (this.allPlaces && value) {
      this.metaAnimateData.waitPlace.push(value);
      if (this.metaAnimateData.waitPlace.length === 1 && !this.isMovingMapAnimate) {
        this.showPlace = value;
        this.moveToNewLatLng(Number(value.lat), Number(value.lng), false);
      }
    } else {
      setTimeout(() => {
        this.dispatchUpdateTooltipsEvent();
        this.firstQueryPlace = true;
        this.showPlace = value;
        this.moveToNewLatLng(Number(value.lat), Number(value.lng), false);
      }, 10);
    }
  }

  @Output() changeVisibleTooltipPlaceEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() extendMapEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPinnedPlacesEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() popupPlaceOpenedEvent: EventEmitter<PlaceNew> = new EventEmitter<PlaceNew>();
  @Output() selectMapItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateTooltipsEvent: EventEmitter<any> = new EventEmitter<any>();

  public icon = <Icon>{
    'url': constructBaseUrl('/assets/circle.svg'),
    'anchor': {x: PointSizeTypeEnum.Standard / 2, y: PointSizeTypeEnum.Standard / 2},
    'scaledSize': {
      'height': PointSizeTypeEnum.Standard,
      'width': PointSizeTypeEnum.Standard
    }
  };
  /** @see google.maps.ControlPosition.RIGHT_TOP - not working */
  public readonly ControlPositionRightTop = 3.0;
  public firstQueryPlace = false;
  public isFirstCenterVisiblePlace = true;
  public isQueryPlaceAfterCenter = false;
  public groupedPlaces: PlaceNew[][];
  public isBrowser: boolean;
  public _isMapFixed = false;
  public isMovingMapAnimate = false;
  public isMapPreparing = false;
  public lastPlaceVisibleChanged: PlaceWithCalculationDataNew;
  public mapApiLoaded$: any;
  public mapInitialized: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public mapInitialized$ = this.mapInitialized.asObservable();
  public mapStyles: any[];
  public minZoom = MIN_ZOOM;
  public mobileMaxSize = 1023;
  public openedPlaces: PlaceWithCalculationDataNew[];
  // Tooltip for forced display on the map
  public openingPlace: any;
  public placeGroup: any[] = [];
  public points: PlaceNew[];
  public visiblePlaces: PlaceWithCalculationDataNew[];
  public oldVisiblePlaces: PlaceWithCalculationDataNew[];
  public window: any;
  public mapTypeId = MAP_TYPE_ID;
  public earthBounds = EARTH_BOUNDS;
  public readonly staticRouteType = StaticRouteType;

  private animationOfMoveEndEvent: Subject<any> = new Subject();
  private countOfSkippedEventsCausedByTheSystem = 0; // We skip events. because these events are not caused by the user.
  private destroyed$ = new Subject<void>();
  protected isInit = true;
  private isScrolling = false;
  private mapAnimateInterval: any;
  private mapObservableEvents: Subject<void> = new Subject(); // Map events
  private metaAnimateData = {
    waitPlace: []
  };
  private setDisableZoomBtn = setDisableZoomBtn;
  private isFirstZoomChange: boolean;
  private isFirstVisibleOfTooltip = true;
  private isMapReady: boolean;
  private lastCalculateId: any;
  private isSelectMapItem: boolean;
  private observables: any[] = [];
  private oldMapFilter: any = {};

  @HostListener('window:resize', ['$event'])
  public onResize() {
    doAsync(() => {
      this.setMapSize();
    }, 300);
  }

  constructor(
    windowRef: WindowRef,
    private groupingPlaces: GroupingPlacesService,
    @Inject(PLATFORM_ID) platformId: Object,
    private readonly googleMapsService: GoogleMapsService,
    private cdref: ChangeDetectorRef,
    public staticService: StaticService,
    public mapService: MapService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.mapStyles = explorowGoogleMapStyle;
    this.window = windowRef.getNativeWindow();
    this.openedPlaces = [];
    this.mapApiLoaded$ = this.googleMapsService.isApiLoaded$;
    // Show loader on initializing of map
    this.isMapPreparing = true;
  }

  ngOnInit(): void {
    if (this.staticType === StaticRouteType.HomePage) {
      this.defineVariables();
    }
    // show tooltip only for the first time
    if (this.isBrowser) {
      const isTooltipToFirstOpenMap = JSON.parse(localStorage.getItem('isForceAppearTooltipToFirstOpenMap'));
      if (isTooltipToFirstOpenMap === null) {
        localStorage.setItem('isForceAppearTooltipToFirstOpenMap', 'true');
      }
    }

    this.mapObservableEvents
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(500)
      ).subscribe(() => {
      this.countOfSkippedEventsCausedByTheSystem++;
      if (this.countOfSkippedEventsCausedByTheSystem > 2 && this.isBrowser) { // We skip events. because these events are not caused by the user.
        localStorage.setItem('isForceAppearTooltipToFirstOpenMap', 'false');
      }
      if (this.checkNotMobile() || (this.staticService.isOpenMap.value &&
        (this.countOfSkippedEventsCausedByTheSystem > 2 && this.firstQueryPlace ||
          this.countOfSkippedEventsCausedByTheSystem >= 1 && !this.firstQueryPlace))) {
        this.dispatchUpdateTooltipsEvent();
      }
    });

    this.animationOfMoveEndEvent
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => {
        if (!result.isPopUpOpen) {
          this.lastPlaceVisibleChanged = {...result.place};
          this.isMovingMapAnimate = false;
          this.changeVisiblePlaceOfGroup(this.lastPlaceVisibleChanged);
        } else {
          this.showPlacePopup(result.place);
        }
      });

    if (this.staticService.isMobileOrTablet()) {
      this.staticService.isOpenMap
        .pipe(takeUntil(this.destroyed$))
        .subscribe(result => {
          if (result) {
            this.mapObservableEvents.next();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.isBrowser) {
      localStorage.setItem('isForceAppearTooltipToFirstOpenMap', 'false');
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private isPlaceChanged(changes: SimpleChanges): boolean {
    return changes['showPlace'] && changes['showPlace']?.currentValue?.otype === DestinationType.Place
      || changes['setVisibleOfTooltip'] && changes['setVisibleOfTooltip']?.currentValue?.otype === DestinationType.Place;
  }

  private resetPreviousData(): void {
    this.openingPlace = {};
    this.openedPlaces = [];
    this.visiblePlaces = [];
    this.oldVisiblePlaces = [];
    this.groupedPlaces = [];
    this.points = [];
    this.allPlaces = [];
  }

  //TODO: should be refactored! absolutely unmaintainable code
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.staticType?.currentValue) {
      if (
        changes?.staticType?.currentValue !== StaticRouteType.UserJourneyPersonalPage
        && this.lastCalculateId != this.getPageId()
      ) {
        // Show loader on page type changes
        this.isMapPreparing = true;
        this.lastCalculateId = null;
        this.resetPreviousData();
      }
    }
    if (
      changes.allPlaces && changes.allPlaces.currentValue ||
      changes['showPlace'] && changes['showPlace'].currentValue ||
      changes['setVisibleOfTooltip'] && changes['setVisibleOfTooltip'].currentValue
    ) {
      if (this.isPlaceChanged(changes)) {
        this.openingPlace = changes['showPlace']?.currentValue || changes['setVisibleOfTooltip'].currentValue;
      }
      if (this.isSelectMapItem) {
        this.isSelectMapItem = false;
      } else {
        if (!this.lastCalculateId && !this.isPlaceChanged(changes) && this.staticType !== StaticRouteType.HomePage) {
          this.dispatchUpdateTooltipsEvent();
        } else {
          if (changes['showPlace'] && changes['showPlace'].currentValue) {
            this.showPlace = changes['showPlace'].currentValue;
            this.defineVariables();
          }
        }
      }
      this.updateTooltips();

      if (
        (changes['showPlace']?.currentValue || this.isFirstVisibleOfTooltip)
        && [StaticRouteType.Place, StaticRouteType.Review].indexOf(this.staticService.staticType) > -1 && this.isMapReady
      ) {
        this.isFirstVisibleOfTooltip = false;
        setTimeout(() => {
          this.moveToNewLatLng(Number(this.showPlace.lat), Number(this.showPlace.lng), true, true);
        }, 300);
      }
    }
    if (changes['userInfo'] && changes['userInfo'].currentValue) {
      const user = changes['userInfo'].currentValue;

      if (user.places && user.places.length > 0) {
        this.openingPlace = user.places[0];
        this.updateTooltips();
      }
    }
  }

  public getPageId(): string {
    let id;
    switch (this.staticType) {
      case StaticRouteType.Journey:
        id = this.staticService.placesData?._extra?.collection?.id;
        break;
      default:
        id = this.showPlace?.id;
        break;
    }

    return this.staticType + id;
  }

  public dispatchUpdateTooltipsEvent(): void {
    if (
      this.mapService.map
      && (this.checkNotMobile() || this.staticService.isOpenMap.value)
      && this.mapService.map.getBounds()
    ) {
      const mapCenter = this.mapService.map.getCenter();
      const currentLat = mapCenter.lat();
      const currentLng = mapCenter.lng();

      const oldMapFilter = clone(this.oldMapFilter);
      if (this.staticService.isMobileOrTablet()) {
        delete oldMapFilter['viewPortWidth'];
        delete oldMapFilter['viewPortHeight'];
      }
      const hasNewFilter = !shallowEqual(oldMapFilter, this.mapService.filters);

      if (
        this.lastCalculateId !== this.getPageId()
        || +this.staticService.oldZoom !== +this.staticService.zoom
        || +this.staticService.oldLat !== +currentLat
        || +this.staticService.oldLng !== +currentLng
        || hasNewFilter && this.staticService.isMobileOrTablet()
      ) {
        if (
          hasNewFilter
          && this.staticService.isMobileOrTablet()
          && this.oldMapFilter.viewPortWidth === undefined
          && this.oldMapFilter.viewPortHeight === undefined
        ) {
          const viewPortSize = getViewPortSize();
          if (viewPortSize.viewPortWidth) {
            this.mapService.filters['viewPortWidth'] = viewPortSize.viewPortWidth;
          }
          if (viewPortSize.viewPortHeight) {
            this.mapService.filters['viewPortHeight'] = viewPortSize.viewPortHeight;
          }
        }
        this.oldMapFilter = clone(this.mapService.filters);
        this.lastCalculateId = this.getPageId();
        this.staticService.oldZoom = this.staticService.zoom;
        this.staticService.oldLat = +currentLat;
        this.staticService.oldLng = +currentLng;
        if (!this.isQueryPlaceAfterCenter && !this.isFirstCenterVisiblePlace) {
          this.isQueryPlaceAfterCenter = true;
          return;
        }
        this.updateTooltipsEvent.emit({
          coordinates: this.staticService.currentViewportCoordinates,
          zoom: this.staticService.zoom
        });
      }
    }
  }

  public mapReady($event: google.maps.Map) {
    this.isMapReady = true;
    this.mapService.map = $event;
    this.mapService.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('ExtendBtn'));
    this.mapService.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('CloseBtn'));
    this.mapInitialized.next($event);

    if (
      this.showPlace.latSouth &&
      this.showPlace.latNorth &&
      this.showPlace.lngWest &&
      this.showPlace.lngEast
    ) {
      const bounds: google.maps.LatLngBoundsLiteral = {
        south: +this.showPlace.latSouth,
        north: +this.showPlace.latNorth,
        west: +this.showPlace.lngWest,
        east: +this.showPlace.lngEast
      };
      this.mapService.map.fitBounds(bounds);
    }

    this.setMapSize();

    if (this.staticType === StaticRouteType.HomePage) {
      this.defineVariables();
    }

    waitObservable(
      () => {
        return this.window.google;
      },
      () => {
        this.mapService.map = $event;
        this.mapInitialized.next($event);
        this.groupingPlaces.defineMap(this.mapService.map);
        this.mapObservableEvents.next();
      },
      0
    );
  }

  public showPlacePopup(place, isSelectMapItem: boolean = false): void {
    this.isSelectMapItem = isSelectMapItem;
    this.selectMapItem.emit(place);
  }

  public openTooltip(place): void {
    place.tooltipShift = this.updateTooltipShift(this.staticService.zoom, place);
    if (
      this.visiblePlaces &&
      !this.visiblePlaces.some(x => x.id === place.id) &&
      !this.openedPlaces.some(x => x.id === place.id)
    ) {
      this.visiblePlaces.push(place);
      this.openedPlaces.push(place);
    }
  }

  protected clearObservables() {
    clearObservables(this.observables);
    this.observables.splice(0);
  }

  public closeTooltip(place): void {
    if (this.visiblePlaces.includes(place)) {
      const index = this.visiblePlaces.indexOf(place);
      this.visiblePlaces.splice(index, 1);
    }

    if (this.openedPlaces.includes(place)) {
      const index = this.openedPlaces.indexOf(place);
      this.openedPlaces.splice(index, 1);
    }
  }

  public updateTooltips(): void {
    this.isMapPreparing = false;
    if (!this.allPlaces) {
      return;
    }

    if (this.lastPlaceVisibleChanged) {
      this.changeVisiblePlaceOfGroup(this.lastPlaceVisibleChanged);
      this.lastPlaceVisibleChanged = null;
      return;
    }

    this.allPlaces = this.allPlaces.map(place => ({...place, intersectingClusters: []}));
    this.clearObservables();
    this.observables.push(
      waitObservable(
        () => this.mapService.map?.getProjection(),
        () => {
          const points = this.groupingPlaces.initialize(
            this.allPlaces,
            null,
            []
          );
          if (
            this.staticType === StaticRouteType.Place &&
            this.openingPlace &&
            this.openingPlace.otype &&
            this.openingPlace.otype === DestinationType.Place
          ) {
            this.openingPlace = {
              ...this.openingPlace,
              cityPopularity: 10,
              intersectingClusters: [],
              priority: 10,
              cluster: {count: 1},
              currentClusterId: this.allPlaces[0] && this.allPlaces[0].id
            };
            this.allPlaces.unshift(this.openingPlace);
            this.allPlaces = [...new Map(this.allPlaces.map(item => [item['id'], item])).values()];
            this.openTooltip(this.openingPlace);
          }

          this.visiblePlaces = this.filterClosestPlace(this.allPlaces);
          this.oldVisiblePlaces = this.visiblePlaces;

          this.groupedPlaces = this.generateGroupedPlaces(
            points,
            this.visiblePlaces,
          );

          this.points = this.allPlaces;
        },
        100
      ));
  }

  public filterClosestPlace(points = [], importantPlace?: PlaceNew) {
    if (!this.mapService.map || !this.mapService.map.getProjection() || !google || !points || !points.length) {
      return [];
    }

    if (importantPlace?.otype !== DestinationType.Place) {
      importantPlace = null;
    }

    points = this.checkOldPoints(points);

    const checkedPoints: any[] = importantPlace ? [importantPlace] : [points[0]];
    checkedPoints[0].tooltipShift = this.updateTooltipShift(
      this.staticService.zoom,
      checkedPoints[0]
    );

    const maxLngCoordinates = latLngToPoint(
      this.mapService.map,
      new google.maps.LatLng(0, 180),
      google
    );
    points.forEach((reducedPoint: PlaceWithCalculationDataNew) => {
      const placeCoordinates = latLngToPoint(
        this.mapService.map,
        new google.maps.LatLng(+reducedPoint.lat, +reducedPoint.lng),
        google
      );

      reducedPoint.shouldDisplay = true;
      reducedPoint.x = placeCoordinates.x;
      reducedPoint.y = placeCoordinates.y;

      checkedPoints.forEach(displayedPoint => {
        // TODO: should be refactored
        if (this.isCollide(reducedPoint, displayedPoint, maxLngCoordinates.x)) {
          reducedPoint.shouldDisplay = false;
        }
      });

      if (reducedPoint.shouldDisplay) {
        reducedPoint.tooltipShift = this.updateTooltipShift(
          this.staticService.zoom,
          reducedPoint
        );
        checkedPoints.push(reducedPoint);
      }
    });

    if (checkedPoints?.length &&
      this.isFirstCenterVisiblePlace &&
      this.staticType === StaticRouteType.Journey) {
      this.isFirstCenterVisiblePlace = false;
      const bounds = new google.maps.LatLngBounds();
      checkedPoints.forEach(n => {
        bounds.extend(n);
      });
      this.mapService.map.setCenter(bounds.getCenter());
      this.cdref.detectChanges();
    }
    return checkedPoints;
  }

  public checkOldPoints(points = []) {
    if (this.oldVisiblePlaces && this.oldVisiblePlaces.length) {
      const oldPoints = points.filter(point => this.oldVisiblePlaces.some(oldPoint => oldPoint.id === point.id));
      const otherPoints = points.filter(point => !this.oldVisiblePlaces.some(oldPoint => oldPoint.id === point.id));
      points = [...oldPoints, ...otherPoints];
    }
    return points;
  }

  public generateGroupedPlaces(allPlaces: PlaceNew[], visiblePlaces: PlaceNew[]): PlaceNew[][] {
    if (!allPlaces || !visiblePlaces) {
      return [];
    }

    const resultedPlacesGroups: any[] = [];

    allPlaces = allPlaces.filter(el => !visiblePlaces.includes(el));

    // grouping places that are hidden under tooltip
    visiblePlaces.forEach((place, i) => {
      resultedPlacesGroups[i] = allPlaces.filter(tPlace =>
        this.isPointUnderGroupTooltip(tPlace[0] !== undefined ? tPlace[0] : tPlace, place)
      ).map(tPlace => tPlace[0] ? tPlace[0] : tPlace);
      resultedPlacesGroups[i].push(place);
    });

    return resultedPlacesGroups;
  }

  public changeVisiblePlaceOfGroup(place: PlaceWithCalculationDataNew) {
    if (!this.isBrowser) {
      return;
    }

    let isChanged = false;
    const placeCoordinates = latLngToPoint(
      this.mapService.map,
      new google.maps.LatLng(+place.lat, +place.lng),
      google
    );
    place['shouldDisplay'] = true;
    place['x'] = placeCoordinates.x;
    place['y'] = placeCoordinates.y;

    if (this.allPlaces && this.allPlaces.find(x => x.id === place.id && x.name === place.name)) {
      const indexOfGroup = this.groupedPlaces.indexOf(
        this.groupedPlaces.find(x => x.find(p => p && p.id === place.id) != null)
      );
      if (indexOfGroup >= 0) {
        if (this.visiblePlaces[indexOfGroup]) {
          if (this.visiblePlaces[indexOfGroup].id !== place.id) {
            if (!this.groupedPlaces[indexOfGroup].find(x => x.id === place.id)) {
              this.groupedPlaces[indexOfGroup].push(this.visiblePlaces[indexOfGroup]);
            }
            this.visiblePlaces[indexOfGroup] = place;
            this.visiblePlaces = this.filterClosestPlace(this.allPlaces, place);
          }
          isChanged = true;
        }
      }
    }

    if (!isChanged) {
      if (this.allPlaces && this.allPlaces.find(x => x.id === place.id) == null) {
        this.allPlaces.push(place);
      }

      if (this.visiblePlaces && this.visiblePlaces.find(x => x.id === place.id) == null) {
        this.visiblePlaces = this.filterClosestPlace(this.allPlaces, place);

        const fromTheSameCityPlace = this.visiblePlaces && this.visiblePlaces.find(x => x?.city?.name === place?.city?.name && x.id !== place.id);
        if (fromTheSameCityPlace) {
          this.visiblePlaces.splice(this.visiblePlaces.indexOf(fromTheSameCityPlace), 1);
        }

      }
    }
    this.groupedPlaces = this.generateGroupedPlaces(
      this.allPlaces,
      this.visiblePlaces
    );
    // Remove duplicate items
    const correctArr = [];
    if (this.groupedPlaces && this.groupedPlaces.length > 0) {
      this.groupedPlaces.forEach(group => {
        const tmpArr = [];
        group.forEach(item => {
          if (tmpArr.filter(x => x.id === item.id).length === 0) {
            tmpArr.push(item);
          }
        });
        correctArr.push(tmpArr);
      });
    }

    this.groupedPlaces = correctArr;
    this.points = this.allPlaces;
  }

  public updateTooltipShift(zoom, place): number {
    if (!zoom || !place) {
      return 0;
    }
    // Half of blue point, for cluster it's 19px/2~9px, for small point it's 14px/2=7px
    const shift = PointSizeTypeEnum.Standard / 2;
    // Change lat to pixel coordinate, then shift tooltip bottom point to this half of the point
    const shiftedPlaceYCoordinate = latToPixelY(place.lat, zoom) - shift;

    // and finally convert it back to latitude
    return pixelYToLat(shiftedPlaceYCoordinate, zoom);
  }

  public isCollide(
    pointA: PlaceWithCalculationDataNew,
    pointB: PlaceWithCalculationDataNew,
    maxX: number
  ): boolean {
    // TODO: we can take into account only main rectangle and calculate intersection of bottom triangle
    // or at least rectangle
    // considering rectangle starts from the bottom point
    const height = TOOLTIP_HEIGHT + TOOLTIP_BOTTOM_ARROW_HEIGHT;
    const shiftX = TOOLTIP_WIDTH / 2;
    const shiftY = PointSizeTypeEnum.Standard / 2;

    const aGroupExtraX = pointA.cluster?.count > 1 ? TOOLTIP_WIDTH_MARGIN_RIGHT : 0;
    const aGroupExtraY = pointA.cluster?.count > 1 ? TOOLTIP_HEIGHT_MARGIN_TOP : 0;
    let aLeft = pointA.x - shiftX;
    let aRight = aLeft + TOOLTIP_WIDTH + aGroupExtraX;
    const aBottom = pointA.y + shiftY;
    const aTop = pointA.y - height - aGroupExtraY;

    const bGroupExtraX = pointB.cluster?.count > 1 ? TOOLTIP_WIDTH_MARGIN_RIGHT : 0;
    const bGroupExtraY = pointB.cluster?.count > 1 ? TOOLTIP_HEIGHT_MARGIN_TOP : 0;
    let bLeft = pointB.x - shiftX;
    let bRight = bLeft + TOOLTIP_WIDTH + bGroupExtraX;
    const bBottom = pointB.y + shiftY;
    const bTop = pointB.y - height - bGroupExtraY - shiftY;

    // edge cases near New Zealand where left can be negative (border between 180° and -180°)
    if (aLeft < 0) {
      aLeft = maxX + aLeft;
      aRight = maxX + aRight;
    }
    if (bLeft < 0) {
      bLeft = maxX + bLeft;
      bRight = maxX + bRight;
    }
    if (aRight > maxX && bLeft >= 0 && bLeft < aRight - maxX) {
      bLeft = maxX + bLeft;
      bRight = maxX + bRight;
    }
    if (bRight > maxX && aLeft >= 0 && aLeft < bRight - maxX) {
      aLeft = maxX + aLeft;
      aRight = maxX + aRight;
    }

    // 0 starts from top left
    return (
      bLeft <= aRight &&
      bRight >= aLeft &&
      bTop <= aBottom &&
      bBottom >= aTop
    );
  }

  public isPointUnderGroupTooltip(point, tooltip): boolean {
    //TODO: edge cases -180/180

    // 0 starts from top left
    return this.isPointUnderTooltip(point, tooltip)
      || this.isPointUnderTooltip(point, {
        x: tooltip.x + TOOLTIP_WIDTH_MARGIN_RIGHT,
        y: tooltip.y - TOOLTIP_HEIGHT_MARGIN_TOP
      });
  }

  private isPointUnderTooltip(point, tooltip): boolean {
    const left = tooltip.x - TOOLTIP_WIDTH / 2;
    const right = left + TOOLTIP_WIDTH;
    const bottom = tooltip.y - PointSizeTypeEnum.Standard / 2 - TOOLTIP_BOTTOM_ARROW_HEIGHT;
    const top = bottom - TOOLTIP_HEIGHT;

    // 0 starts from top left
    return (
      point.x <= right &&
      point.x >= left &&
      point.y <= bottom &&
      point.y >= top
    );
  }

  public onZoomChange() {
    google.maps.event.trigger(this.mapService.map, "resize");
    if (this.isMovingMapAnimate || this.mapService.map.getZoom() === this.staticService.zoom) {
      return;
    }

    let lessZoom = 0;

    if (!this.isFirstZoomChange && this.staticService.zoom + 1 < this.mapService.map.getZoom()) {
      lessZoom++;
      this.isFirstZoomChange = true;
    }
    const zoom = this.mapService.map.getZoom() - lessZoom;
    this.staticService.zoom = zoom <= MAX_ZOOM ? zoom : MAX_ZOOM;
    this.cdref.detectChanges();

    if (this.staticService.zoom <= MIN_ZOOM) {
      this.setDisableZoomBtn('out');
    } else {
      this.setDisableZoomBtn();
    }
    this.openedPlaces = [];

    if (this.visiblePlaces) {
      this.visiblePlaces.forEach(place => {
        this.updateCoordinateTooltipShift(place);
      });
    }
  }

  public onBoundsChange() {
    this.isMapPreparing = false;
    if (this.isScrolling && this.isBrowser) {
      if (!this.isInit) {
        this.isScrolling = false;
        return;
      }
      this.isInit = false;
    }

    if (this.isMovingMapAnimate) {
      return;
    }

    this.mapService.getCurrentViewportCoordinates();

    this.mapObservableEvents.next();

    if (this.isInit) {
      this.isInit = false;
    }
  }

  public defineVariables() {
    if (this.showPlace && this.mapService.map) {
      if (this.staticService.staticType === StaticRouteType.UserPersonalPage) {
        const bounds = new google.maps.LatLngBounds();
        if (this.showPlace?.places) {
          this.showPlace.places.forEach(n => {
            bounds.extend(n);
          });
        }
        this.mapService.map.fitBounds(bounds);
      } else {
        if (this.showPlace.lat && this.showPlace.lng) {
          this.staticService.lat = Number(this.showPlace.lat);
          this.staticService.lng = Number(this.showPlace.lng);
          this.cdref.detectChanges();
          this.mapService.map.setCenter({lat: this.staticService.lat, lng: this.staticService.lng})
        }
      }
      this.mapObservableEvents.next();
    }
  }

  public closeMap() {
    this.staticService.isOpenMap.next(false);
  }

  public moveToNewLatLng(lat: number, lng: number, isPopUpOpen?: boolean, hasAnimate: boolean = true) {
    const currentZoom = this.staticService.zoom > 1 ? this.staticService.zoom : 3;
    this.isMovingMapAnimate = hasAnimate;
    let startValueLat = this.staticService.lat;
    let startValueLng = this.staticService.lng;

    const deltas = Math.abs(lat - startValueLat) > 1 ? 50 : 25;
    const stepLat = (lat - startValueLat) / deltas;
    const stepLng = (lng - startValueLng) / deltas;
    let i = 0;
    const that = this;

    setTimeout(function () {
      that.mapAnimateInterval = setInterval(function () {
        if (i === deltas) {
          that.staticService.lat = Number(lat);
          that.staticService.lng = Number(lng);
          that.staticService.zoom = currentZoom;
          clearInterval(that.mapAnimateInterval);

          if (that.metaAnimateData.waitPlace.length !== 0) {
            that.showPlace = that.metaAnimateData.waitPlace[0];
            if (that.metaAnimateData.waitPlace.length > 1) {
              that.metaAnimateData.waitPlace = that.metaAnimateData.waitPlace.slice(1, that.metaAnimateData.waitPlace.length);
              that.moveToNewLatLng(+that.showPlace.lat, +that.showPlace.lng, isPopUpOpen);
            } else {
              that.metaAnimateData.waitPlace = [];
              that.moveToNewLatLng(+that.showPlace.lat, +that.showPlace.lng, isPopUpOpen);
            }
            return;
          }

          that.animationOfMoveEndEvent.next({place: that.showPlace, isPopUpOpen: isPopUpOpen});
          setTimeout(() => {
            that.isMovingMapAnimate = false;
            that.updateTooltips();
          }, 100);
        } else if (i > deltas) {
          return;
        }

        that.staticService.lng = startValueLng;
        that.staticService.lat = startValueLat;
        startValueLat += stepLat;
        startValueLng += stepLng;

        i++;
      }, 5);
    }, 1000);
  }

  private updateCoordinateTooltipShift(place: any) {
    place['tooltipShift'] = this.updateTooltipShift(
      this.staticService.zoom,
      place
    );
  }

  private setMapSize() {
    const totalMargin = 40;
    const height = this.window.innerHeight - totalMargin + 'px';
    const element = this.isBrowser && document.querySelectorAll('google-map')[0];
    if (element) {
      element.removeAttribute('style');
      element.setAttribute('style', 'height:' + height);
    }
  }

  private checkNotMobile() {
    if (this.isBrowser) {
      return window.innerWidth > this.mobileMaxSize;
    }
    return false;
  }

  public isPolylineVisible(): boolean {
    return this.staticType === this.staticRouteType.Journey && this.staticService.placesData?._extra?.collection?.isLineVisible
  }
}
