import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  @Input() showTitle = true;
  @Input() textMessage: string;

  @Output() emitContinue = new EventEmitter<boolean>();

  constructor() {
  }

  doContinue(): void {
    this.emitContinue.emit();
  }
}
