<div class="progress__container" *ngIf="uploadsProgress && uploadsProgress[index]?.length">
  <div class="progress__container-big">
    <div class="uploaded-title">{{uploadTitle}}</div>
    <div class="uploaded-progress__container">
      <div class="uploaded-progress__bar">
        <div class="uploaded-progress__content">
          <span class="all-time-progress"
                [class.loader]="isUploaded && !(successUploadPopupData && successUploadPopupData[index])"></span>
          <span [style.width.%]="allUploadsProgress.progress" *ngIf="!isUploaded" class="current-time-progress"></span>
        </div>
      </div>
      <img (click)="toggleProgressContainer()"
           [class.show-arrow]="isOpenProgressContainer"
           class="uploaded-progress__arrow"
           src="/assets/arrow/arrow-keyboard-down.svg"
           alt="arrow">
    </div>
    <div class="uploaded-size">
      {{+allUploadsProgress.loaded | fileSize}} of {{+allUploadsProgress.total | fileSize}}
    </div>
  </div>
  <div *ngIf="isOpenProgressContainer" class="progress__container-small">
    <div class="progress__container-small-single" *ngFor="let item of uploadsProgress[index]">
      <img class="uploaded-image"
           [src]="item.type === typeMedia.photo ? '/assets/icons/photo-icon.svg' : '/assets/icons/video-icon.svg'"
           alt="icon">
      <div class="progress__content">
        <div class="uploaded-title">{{item.file?.name || 'file'}}</div>
        <div class="uploaded-progress__bar">
          <div class="uploaded-progress__content">
            <span class="all-time-progress"></span>
            <span *ngIf="item.progress < 100" [style.width.%]="item.progress" class="current-time-progress"></span>
          </div>
        </div>
        <div class="uploaded-size">
          {{item.progress === 100 ? 'Uploaded' : 'Uploading'}}
          · {{+item.loaded | fileSize}}
          of {{+item.total | fileSize}}
        </div>
      </div>
    </div>
  </div>
</div>
