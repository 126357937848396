import { CityNew, Country } from './place';
import { PlaceNew } from './place';
import { DestinationType } from '../enums/destination-type';
import { WhenDate } from "./datepicker-date";

export interface PlacePin {
  type?: PlacePinType;//?
  oid: number;
  otype: DestinationType;
  creationDate?: string;
  dateFrom?: string;
  dateTo?: string | null;
  dateType?: MapDateType;
  city?: CityNew | null;
  place?: PlaceNew | null;
  countries?: Country | null;
  restaurant?: any | null;
  action?: string;
}

export enum MapDateType {
  Month = 1,
  Period = 2,
  Date = 3
}

export enum PlacePinType {
  WannaGo = 1,
  AlreadyBeen = 2,
}

export interface PlacePinExt extends PlacePin {
  date?: WhenDate[]; // not used?
  label?: any;
  _pinTypes?: PlacePinType[];
  _sync?: any;
}

export interface PinInterface extends PlacePin {
  _sync: boolean;
  _shouldOpenPinTab?: boolean;
  _pinTypes?: PlacePinType[];
}
