import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { SsrService } from './ssr.service';

@Injectable({providedIn: 'root'})
export class GoogleMapsService {
  private isApiLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isApiLoaded$ = this.isApiLoaded.asObservable();

  constructor(
    httpClient: HttpClient,
    private ssrService: SsrService
  ) {
    if (this.ssrService.isBrowser()) {
      httpClient
        .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleKey}&libraries=places,geometry`, 'callback')
        .subscribe(
          {
            next: () => this.isApiLoaded.next(true),
            error: (error) => {
              throw new Error(JSON.stringify(error));
            }
          }
        );
    }
  }
}
