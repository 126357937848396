import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, takeUntil } from "rxjs/operators";
import { select, Store } from "@ngrx/store";
import { getNotificationList, State } from "../reducers";
import { Injectable, OnDestroy } from "@angular/core";
import { SsrService, UserService } from "../../services";
import { Subject } from 'rxjs/internal/Subject';
import {
  LoadNotifications,
  RestoreNotifications,
  UserNotificationsActions,
  UserNotificationsActionsType
} from "./user-notifications.actions";
import { HttpParams } from "@angular/common/http";
import { NotificationService } from "../../services/notification.service";
import { NotificationList } from "../../interfaces/notifications";
import { MAX_NOTIFICATION_ROWS } from "../../../constants";

export const NGX_USER_NOTIFICATIONS = 'ngx_user-notifications';

@Injectable()
export class UserNotificationsEffects implements OnDestroy {

  $loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNotifications>(UserNotificationsActionsType.LoadNotifications),
    map((action) => {
      if (this.userService.loggedIn() && !this.isNotificationLoading) {
        this.isNotificationLoading = true;
        const httpParams = new HttpParams()
          .append('page', action?.payload?.page ?? '1')
          .append('per-page', MAX_NOTIFICATION_ROWS);
        this.userNotificationService.getNotifications(httpParams)
          .subscribe((response) => {
            this.userNotificationService.isInitialized = true;
            this.isNotificationLoading = false;
            response._sync = this.userService.loggedIn();
            this.store$.dispatch(new UserNotificationsActions.SuccessLoadNotifications({
              items: response.items,
              _meta: response._meta,
              _extra: response._extra,
            }, this.userService.loggedIn()));
          });
      }
    })
  ), {dispatch: false});

  private isNotificationLoading = false;
  private $destroyed = new Subject<void>();
  private notifications: NotificationList;

  constructor(
    private store$: Store<State>,
    private actions$: Actions,
    private userService: UserService,
    private userNotificationService: NotificationService,
    private ssrService: SsrService
  ) {
    if (ssrService.isBrowser()) {
      this.notifications = JSON.parse(localStorage.getItem(NGX_USER_NOTIFICATIONS)) || '';
      if (this.notifications) {
        this.store$.dispatch(new RestoreNotifications(this.notifications));
      }

      this.store$.pipe(
        select(getNotificationList),
        takeUntil(this.$destroyed)
      ).subscribe((notifications) => {
        this.notifications = notifications;
        this.notifications = JSON.parse(JSON.stringify(this.notifications));
        this.notifications['_sync'] = this.userService.loggedIn();

        if (this.ssrService.isBrowser()) {
          localStorage.setItem(NGX_USER_NOTIFICATIONS, JSON.stringify(this.notifications));
        }
      });
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }
}
