import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const backgroundDark = trigger('backgroundDark', [
  transition('void => *', [
    query(':self', stagger('0ms', [
      animate('.5s ease-in', keyframes([
        style({'background-color': 'rgba(0,0,0,.04)', offset: 0}),
        style({'background-color': 'rgba(0,0,0,.04)', offset: 1.0}),
      ]))]), {optional: true}),
  ])
]);
