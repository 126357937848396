import {
  TOOLTIP_HEIGHT,
  TOOLTIP_HEIGHT_MARGIN_TOP,
  TOOLTIP_WIDTH,
  TOOLTIP_WIDTH_MARGIN_RIGHT
} from '../../../../constants';
import { getPointsIntersecting } from './isPointsIntersecting';
import { checkTooltipIntersections, checkTooltipsArrowIntersections } from './define-intersections';
import { windowIntersectsWith } from './windowIntersectsWith';
import { PointSizeTypeEnum } from "../../../enums/point-size-type.enum";

declare const Math;

export const definePriorityBasedIntersections = (map, zoom, priorityBasedGroups, indexedNormalizedPoints, existPlaces) => {

  const sortedByPriorityPlaces = (existPlaces && existPlaces.length > 0) ?
    existPlaces.filter(x => !x[0].isOpen).map(x => x[0]).concat(priorityBasedGroups) :
    priorityBasedGroups;

  const isTooltipIntersects = defineIsTooltipIntersects();
  const isTooltipArrowIntersects = defineIsTooltipArrowIntersects();
  const presentTooltipIntersection = definePresentTooltipIntersection();
  const windowIntersectsWith = defineWindowIntersectsWith();
  const isPointsIntersecting = getPointsIntersecting;
  const math = defineMath();
  const popularGroupPlaces = (existPlaces && existPlaces.length > 0) ? existPlaces.filter(x => x[0].isOpen) : [];
  if (popularGroupPlaces.length > 0) {
    popularGroupPlaces.forEach(x => {
      if (!windowIntersectsWith(x[0], map)) {
        x[0].isOpen = false;
      }
    });
  }
  const placesThatSuspendedForIntersections = [];
  const titlePlacesNW = popularGroupPlaces.filter(x => x[0].isOpen).map(x => indexedNormalizedPoints[x[0].id].cnw);
  const titlePlacesPoint = popularGroupPlaces.filter(x => x[0].isOpen).map(x => indexedNormalizedPoints[x[0].id].c);

  while (sortedByPriorityPlaces.length) {
    const place = sortedByPriorityPlaces.shift();
    const nwPoint: any = indexedNormalizedPoints[place.id].cnw;
    const point: any = indexedNormalizedPoints[place.id].c;
    let i = 0;
    if (popularGroupPlaces.length === 0) {
      place.isOpen = true;
      popularGroupPlaces.push([place]);
      titlePlacesNW.push(nwPoint);
      titlePlacesPoint.push(point);
    } else {
      if (!presentTooltipIntersection(titlePlacesNW, nwPoint, math) &&
        !presentTooltipIntersection(titlePlacesPoint, nwPoint, math) &&
        !presentTooltipIntersection(titlePlacesNW, point, math) &&
        windowIntersectsWith(place, map)) {
        place.isOpen = true;
        popularGroupPlaces.push([place]);
        titlePlacesNW.push(nwPoint);
        titlePlacesPoint.push(point);
      } else {
        i++;
        placesThatSuspendedForIntersections.push([place]);
        continue;
      }
    }

    while (i <= sortedByPriorityPlaces.length - 1) {

      const nextPointCoord = indexedNormalizedPoints[sortedByPriorityPlaces[i].id].c;
      const nextPoint = sortedByPriorityPlaces[i];

      if (
        isTooltipIntersects(nwPoint, nextPointCoord) ||
        isTooltipArrowIntersects(point, nextPointCoord, PointSizeTypeEnum.Standard / 2) ||
        isPointsIntersecting(indexedNormalizedPoints[place.id].c, nextPointCoord, PointSizeTypeEnum.Standard / 2, PointSizeTypeEnum.Standard / 2, math)
      ) {
        const currentPlace = sortedByPriorityPlaces.splice(i, 1)[0];
        popularGroupPlaces[popularGroupPlaces.length - 1][0].intersectingClusters.push(currentPlace.cluster);
        placesThatSuspendedForIntersections.push([currentPlace]);
      } else {
        const nextNW = indexedNormalizedPoints[nextPoint.id].cnw;
        if (presentTooltipIntersection(titlePlacesNW, nextNW, math) ||
          presentTooltipIntersection(titlePlacesPoint, nextNW, math)) {
          const currentPlace = sortedByPriorityPlaces.splice(i, 1);
          placesThatSuspendedForIntersections.push([currentPlace[0]]);
        } else {
          i++;
        }
      }

    }
  }
  let i = 0;
  while (placesThatSuspendedForIntersections.length > i) {
    const checkedPoint = indexedNormalizedPoints[placesThatSuspendedForIntersections[i][0].id];
    let isCanBeOpen = true;
    if (!windowIntersectsWith(placesThatSuspendedForIntersections[i][0], map)) {
      i++;
      continue;
    }

    popularGroupPlaces.filter(x => x[0].isOpen).forEach(opened => {
      const openedPoint = indexedNormalizedPoints[opened[0].id];
      if (isTooltipIntersects(openedPoint.cnw, checkedPoint.c) ||
        isTooltipIntersects(checkedPoint.cnw, openedPoint.c)
        || intersectRect(checkedPoint.cnw, openedPoint.cnw, math)
        || isPointsIntersecting(checkedPoint.c, openedPoint.c, PointSizeTypeEnum.Standard / 2, PointSizeTypeEnum.Standard / 2, math)) {
        isCanBeOpen = false;
        return;
      }

    });
    if (isCanBeOpen) {
      const toOpenPoint = placesThatSuspendedForIntersections.splice(i, 1)[0];
      toOpenPoint[0].isOpen = true;
      popularGroupPlaces.push(toOpenPoint);
    } else {
      i++;
    }

  }

  // Checking to find leak points
  popularGroupPlaces.forEach(x => {
    const tooltipsPoint = indexedNormalizedPoints[x[0].id];
    placesThatSuspendedForIntersections.forEach(point => {
      if (!windowIntersectsWith(point, map)) {
        return;
      }
      const checkedPoint = indexedNormalizedPoints[point[0].id];
      if (
        isTooltipIntersects(tooltipsPoint.cnw, checkedPoint.c) ||
        isPointsIntersecting(indexedNormalizedPoints[x[0].id].c, indexedNormalizedPoints[point[0].id].c, PointSizeTypeEnum.Standard / 2, PointSizeTypeEnum.Standard / 2, math) ||
        isTooltipArrowIntersects(tooltipsPoint.c, checkedPoint.c, PointSizeTypeEnum.Standard / 2)
      ) {
        if (
          x[0].intersectingClusters &&
          x[0].intersectingClusters.find(value => value && point[0] && point[0].cluster && value.hash === point[0].cluster.hash) == null) {
          x[0].intersectingClusters.push(point[0].cluster);
        }
      }
    });
  });

  return {popularGroupPlaces, placesThatSuspendedForIntersections};
};

let mathObject = null;

function defineMath() {
  if (mathObject) {
    return mathObject;
  }
  return mathObject = Math;
}

function presentTooltipIntersection(rects, toCheckIntersectionWith, math) {
  const isIntersectionBetween = defineIntersectsRect();
  for (let i = 0; i < rects.length; i++) {
    if (isIntersectionBetween(rects[i], toCheckIntersectionWith, math)) {
      return true;
    }
  }
  return false;
}

function intersectRect(pointNW1, pointNW2, math) {
    return (math.abs(pointNW1.x - pointNW2.x) > (TOOLTIP_WIDTH + TOOLTIP_WIDTH_MARGIN_RIGHT)) &&
      (math.abs(pointNW1.y - pointNW2.y) > (TOOLTIP_HEIGHT + TOOLTIP_HEIGHT_MARGIN_TOP));
}

function definePresentTooltipIntersection() {
  return presentTooltipIntersection;
}

function defineIntersectsRect() {
  return intersectRect;
}

function defineIsTooltipIntersects() {
  return checkTooltipIntersections;
}

function defineIsTooltipArrowIntersects() {
  return checkTooltipsArrowIntersections;
}

function defineWindowIntersectsWith() {
  return windowIntersectsWith;
}
