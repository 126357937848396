import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ErrorEnum } from "../enums/error.enum";
import { ToastrService } from "./toastr.service";

export const maxMobileWidth = 896;
export const maxTabletWidth = 1024;
export const maxStandardDesktopWidth = 1280;

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public ajaxLoaderVisible$ = new BehaviorSubject<boolean>(true);
  public isOpenGallery$ = new Subject<boolean>();
  public isPanRewindVideo = false;

  constructor(private toastrService: ToastrService) {
  }

  public checkIsNumeric(data): boolean {
    if (!isNaN(data)) {
      return true;
    } else {
      this.toastrService.activeToastr$.next({message: ErrorEnum.linkNotValid});
      return false;
    }
  }

  public checkIsCountry(id): boolean {
    if (id && id.length === 2) {
      return true;
    } else {
      this.toastrService.activeToastr$.next({message: ErrorEnum.linkNotValid});
      return false;
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


}
