import { Action } from "@ngrx/store";
import { LikeInterface, LikesListInterface } from "../../interfaces/like";
import { PlaceNew } from "../../interfaces";


export enum UserLikesActionsType {
  AddLikes = '[UserLikes] Add like',
  RemoveLike = '[UserLikes] Remove like',
  LoadLikes = '[UserLikes] Load likes',
  SuccessLoadLikes = '[UserLikes] Success load likes',
  SuccessAddLikes = '[UserLikes] Success add likes',
  SuccessRemoveLike = '[UserLikes] Success remove like',
  RestoreLike = '[UserLikes] Restore like',
  ResetLike = '[UserLikes] Reset like',
  SynchronizeLikes = '[UserLikes] Synchronize like',
}

export class LoadLikes implements Action {
  readonly type = UserLikesActionsType.LoadLikes;

  constructor(public payload?: LikeInterface | LikeInterface[] | any) {
  }
}

export class RestoreLike implements Action {
  readonly type = UserLikesActionsType.RestoreLike;

  constructor(public payload?: LikeInterface | any) {
  }
}

export class SuccessLoadLikes implements Action {
  readonly type = UserLikesActionsType.SuccessLoadLikes;

  constructor(public payload?: LikeInterface | LikeInterface[] | any, public isUserLogged = false) {
  }
}

export class AddLikes implements Action {
  readonly type = UserLikesActionsType.AddLikes;

  constructor(public payload: PlaceNew) {
  }
}

export class SynchronizeLikes implements Action {
  readonly type = UserLikesActionsType.SynchronizeLikes;

  constructor(public payload: LikesListInterface) {
  }
}

export class SuccessAddLikes implements Action {
  readonly type = UserLikesActionsType.SuccessAddLikes;

  constructor(public payload: PlaceNew, public isUserLogged: boolean = false) {
  }
}

export class RemoveLike implements Action {
  readonly type = UserLikesActionsType.RemoveLike;

  constructor(public payload: LikeInterface) {
  }
}

export class ResetLike implements Action {
  readonly type = UserLikesActionsType.ResetLike;
}

export class SuccessRemoveLike implements Action {
  readonly type = UserLikesActionsType.SuccessRemoveLike;

  constructor(public payload: LikeInterface) {
  }
}

export type UserLikesActionsUnion =
  LoadLikes |
  SuccessAddLikes |
  SuccessLoadLikes |
  SuccessRemoveLike |
  RemoveLike |
  RestoreLike |
  ResetLike |
  SynchronizeLikes |
  AddLikes;

export const UserLikesActions = {
  LoadLikes,
  AddLikes,
  RemoveLike,
  RestoreLike,
  ResetLike,
  SuccessAddLikes,
  SuccessLoadLikes,
  SynchronizeLikes,
  SuccessRemoveLike
};

