import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST, RESPONSE } from '../../../express.tokens';
import { Request, Response } from 'express';
import { environment } from "../../../environments/environment";

//TODO: can be replaced by ngx-cookie-service-ssr or otherwise, to remove extra dependency
@Injectable({
  providedIn: 'root',
})
export class FlyCookieService {
  cookies: any = {};
  document: any = {cookie: ''};

  constructor(
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Optional() @Inject(RESPONSE) private res: Response<any>
  ) {
    if (this.req !== null) {
      this.document.cookie = this.req?.headers?.cookie;
      this.cookies = this._getPairs(true);
    } else {
      this.document = document;
    }
  }

  get(name: string): string | null {
    const cookies: { [key: string]: string | null } = this._getPairs();
    if (cookies && name && typeof cookies[name] !== 'undefined') {
      return cookies[name];
    }

    return null;
  }

  check(name: string) {
    return !!this.get(name);
  }

  set(
    name: string,
    value: string,
    expiry?: Date | string | number,
    path?: string
  ): boolean {
    if (!name) {
      return false;
    }

    if (!path) {
      path = '/';
    }

    if (this.req === null) {
      let expiryStr = '';

      if (expiry) {
        if (typeof expiry === "number") {
          expiry = this.numberToDate(expiry);
        }
        if (!(expiry instanceof Date)) {
          expiry = new Date(expiry);
        }
        expiryStr = '; expires=' + expiry.toUTCString();
      }
      this.document.cookie = `${name}=${value}${expiryStr}; path=${path}; domain=${this.getCookieDomain()};`;
    } else {
      if (expiry) {
        if (!(expiry instanceof Date)) {
          expiry = new Date(expiry);
        }
        const dt = new Date();
        if (expiry.getTime() <= dt.getTime()) {
          this.delete(name, path);
        } else {
          this.cookies[name] = value;
          this.res.cookie(name, value, {
            expires: expiry,
            path,
            encode: String,
            domain: this.getCookieDomain(),
          });
        }
      } else {
        this.cookies[name] = value;
        this.res.cookie(name, value, {path, encode: String, domain: this.getCookieDomain()});
      }
    }

    return true;
  }

  private numberToDate(number: number) {
    const currentDate = new Date();
    const currentDateInMs = currentDate.valueOf();
    const expiryInMs = 1000 * 60 * 60 * 24 * number;
    return new Date(currentDateInMs + expiryInMs)
  }

  delete(name: string, path?: string): boolean {
    if (this.req !== null || !name) {
      return false;
    }

    if (!path) {
      path = '/';
    }

    const expiry = this.numberToDate(30);
    if (this.req === null) {
      this.document.cookie = `${name}=; expires=${expiry?.toUTCString()}; path=${path}; domain=${this.getCookieDomain()};`;
    } else {
      this.cookies[name] = null;
      this.res.cookie(name, null, {expires: expiry, path, encode: String, domain: this.getCookieDomain()});
    }
    return true;
  }

  _getPairs(needCheck = false): { [key: string]: string | null } {
    if ((this.req === null || needCheck) && this?.document?.cookie) {
      const parsed = this.document.cookie.split('; ');
      const cookies: { [key: string]: string | null } = {};

      parsed.forEach((element: string) => {
        if (element) {
          const pair = element.split('=');

          cookies[pair[0]] = typeof pair[1] !== 'undefined' ? pair[1] : null;
        }
      });
      return cookies;
    } else {
      return this.cookies;
    }
  }

  private getCookieDomain(): string {
    if (!environment.baseUrl) {
      return '';
    }
    const parts = environment.baseUrl.split('.');
    if (parts.length < 3) {
      return '';
    }
    return '.' + parts[1] + '.' + parts[2];
  }
}
