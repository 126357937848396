import { UserNotificationsActionsType, UserNotificationsActionsUnion } from "./user-notifications.actions";
import { NotificationList } from "../../interfaces/notifications";

import { MAX_NOTIFICATION_ROWS } from "../../../constants";

export interface State {
  notifications: NotificationList;
}

const initialState: State = {
  notifications: {
    items: [],
    _meta: {
      totalCount: 0,
      currentPage: 0,
      perPage: MAX_NOTIFICATION_ROWS,
      pageCount: 0,
    },
    _extra: {
      unreadCount: 0
    },
    _sync: true
  }
};

export function reducer(state: State = initialState, action: UserNotificationsActionsUnion): State {
  switch (action.type) {
    case UserNotificationsActionsType.SuccessLoadNotifications:
      const notifications_ = JSON.parse(JSON.stringify(action.payload));
      notifications_._sync = action.isUserLogged;
      if (notifications_._meta.currentPage === undefined) {
        notifications_._meta.currentPage = 1;
      }
      if (notifications_._meta.perPage === undefined) {
        notifications_._meta.perPage = MAX_NOTIFICATION_ROWS;
      }
      if (notifications_._meta.pageCount === undefined) {
        notifications_._meta.pageCount = Math.ceil(notifications_._meta.totalCount / notifications_._meta.perPage);
      }
      return {
        ...state,
        notifications: notifications_
      };
    case UserNotificationsActionsType.RestoreNotifications:
      return {
        ...state,
        notifications: action.payload
      };
    case UserNotificationsActionsType.ResetNotifications:
      return initialState;
  }
  return state;
}

export const _getNotificationList = (state: State) => state.notifications;
