import { Action } from '@ngrx/store';

export enum PanelsActionsType {
  TogglePinnedPanel = '[Panels] Toggle pinned panel',
  ClosePinnedPanel = '[Panels] Close pinned panel',
  ToggleFavoritePanel = '[Panels] Toggle favorite panel',
  CloseFavoritePanel = '[Panels] Close favorite panel',
  ToggleFollowingPanel = '[Panels] Toggle following panel',
  CloseFollowingPanel = '[Panels] Close following panel',
  MarkPlaceFormAsOpened = '[Panels] Mark place form as opened',
}

export class TogglePinnedPanel implements Action {
  readonly type = PanelsActionsType.TogglePinnedPanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class ClosePinnedPanel implements Action {
  readonly type = PanelsActionsType.ClosePinnedPanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class ToggleFavoritePanel implements Action {
  readonly type = PanelsActionsType.ToggleFavoritePanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class CloseFavoritePanel implements Action {
  readonly type = PanelsActionsType.CloseFavoritePanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class ToggleFollowingPanel implements Action {
  readonly type = PanelsActionsType.ToggleFollowingPanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class CloseFollowingPanel implements Action {
  readonly type = PanelsActionsType.CloseFollowingPanel;

  constructor(public payload?: { display: boolean }) {
  }
}

export class MarkPlaceFormAsOpened implements Action {
  readonly type = PanelsActionsType.MarkPlaceFormAsOpened;

  constructor() {
  }
}

export type PanelsActionsUnion =
  | TogglePinnedPanel
  | ClosePinnedPanel
  | ToggleFavoritePanel
  | CloseFavoritePanel
  | ToggleFollowingPanel
  | CloseFollowingPanel
  | MarkPlaceFormAsOpened;
