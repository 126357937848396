import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCollectionComponent } from './create-collection.component';
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    CreateCollectionComponent,
  ],
  exports: [
    CreateCollectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ]
})
export class CreateCollectionModule { }
