import { Component, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user-token.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { select, Store } from '@ngrx/store';
import { getAjaxState, isPinnedPanelVisible, State } from '../../../../store/reducers';
import { delay, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InfoService, SsrService } from '../../../../services';
import { HttpParams } from '@angular/common/http';
import * as fromAuth from '../../../../store/auth/auth.actions';
import { Subject } from "rxjs/internal/Subject";
import { SetCollectionsState } from "../../../../store/user-collections";
import { isPlatformBrowser } from "@angular/common";
import { UserCollectionService } from "../../../../services/user-collection.service";
import { ToastrService } from "../../../../services/toastr.service";
import { ErrorEnum } from "../../../../enums/error.enum";
import { SuccessLoadLikes } from "../../../../store/user-likes/user-likes.actions";
import { SuccessLoadFollowed } from "../../../../store/user-following/user-following.actions";
import { SuccessLoadNotifications } from "../../../../store/user-notifications/user-notifications.actions";
import { TogglePinnedPanel } from "../../../../store/panels/panels.actions";
import { AfterAuthAction } from "../../../../enums/after-auth.action";
import { UserProfile } from "../../../../interfaces/user";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";

interface CheckNavigation {
  isNavigate?: boolean;
  isReload?: boolean;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../../../../modules/user-account/components/profile-pass-change/profile-pass-change.component.scss',
    './login.component.scss'
  ]
})
export class LoginComponent implements OnDestroy {
  public ajaxState$: Observable<any>;
  public emailFocused = false;
  public emailUpdated;
  public generalError: any;
  public loginForm: UntypedFormGroup;
  public needResendEmail = false;
  public passFocused = false;
  public passUpdated;
  public passwordType = 'password';
  public resOfEmailValidation: any;
  public resOfPasswordValidation: any;
  public shouldRemember: any;
  private $destroyed: Subject<void> = new Subject<void>();
  public isOpenPinned: boolean;
  public isBrowser: boolean;

  @Output() closeLoginFormEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() showUserAccountEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() resPasswordSuccessEmitter = new EventEmitter<boolean>();
  @Output() completeEmitter = new EventEmitter<boolean>();

  @Input() auth;
  @Input() position: any;
  @Input() searchMode: boolean;
  @Input() showLoginForm = true;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private infoService: InfoService,
    protected store$: Store<State>,
    fb: UntypedFormBuilder,
    private router: Router,
    private userCollectionService: UserCollectionService,
    private toastrService: ToastrService,
    private ssrService: SsrService,
    @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.ajaxState$ = this.store$.select(getAjaxState).pipe(delay(0));
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern())]],
      pass: ['', [Validators.required, Validators.minLength(6)]]
    }, {updateOn: 'blur'});
    this.rememberUser();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  public resPasswordSuccess(value): void {
    this.resPasswordSuccessEmitter.emit(value);
  }

  emailPattern() {
    return new RegExp('^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$');
  }

  signInWithEmail(loginForm: any) {
    this.generalError = null;
    this.emailValidate(loginForm);
    this.passwordValidate(loginForm);
    if (!loginForm.valid) {
      return;
    }
    this.generalError = null;
    if (!loginForm.valid) {
      return;
    }
    this.userService.disableSubmitForWhile(this);
    this.resOfEmailValidation = null;
    this.resOfPasswordValidation = null;

    const params = {
      email: loginForm.controls['email'].value,
      password: loginForm.controls['pass'].value
    };

    const httpQueryParams = new HttpParams()
      .append(
        'expand',
        'user.urlSetting,' +
        'collections.cities.city.pictures,' +
        'collections.cities.city.flights,' +
        'collections.cities.city.country,' +
        'collections.places.place.pictures,' +
        'collections.places.place.country,' +
        'collections.places.place.city.flights,' +
        'collections.places.place.city.country,' +
        'collections.places.place.video,' +
        'collections.dates,' +
        'favourites.cities.city.pictures,' +
        'favourites.cities.city.topPlace.pictures,' +
        'favourites.cities.city.topPlace.video,' +
        'favourites.cities.city.flights,' +
        'favourites.cities.city.country,' +
        'favourites.places.place.pictures,' +
        'favourites.places.place.city.flights,' +
        'favourites.places.place.city.country,' +
        'favourites.places.place.country,' +
        'favourites.places.place.video,' +
        'favourites.collections.collectionPinned.places.place.pictures,' +
        'favourites.collections.collectionPinned.places.place.video,' +
        'followed.user.urlSetting,followed.user.picture,followed.user.homeCityObject.country,' +
        'followed.user.placeCreatedCount,followed.user.collectionCount,followed.user.collectionCount,' +
        'followed.events.place,followed.events.collection'
      );

    this.authService.auth(params, httpQueryParams).subscribe(
      async (success: any) => {
        this.completeEmitter.emit(true);

        this.userService.disableLoadingBar(this);
        this.setRememberMe();
        this.userService.setToken(success);
        this.userCollectionService.noAuthCollections = success.body?.collections;
        this.userCollectionService.noAuthLikes = success.body?.favourites;
        this.userCollectionService.isLogin = true;

        let hasNoAuthCollection = false;
        this.userCollectionService.checkConfirmWindow().subscribe(x => hasNoAuthCollection = x);
        if (this.isBrowser && !hasNoAuthCollection) {
          this.store$.dispatch(new SetCollectionsState({collections: this.userCollectionService.noAuthCollections}, false, this.userService.loggedIn()));
          this.store$.dispatch(new SuccessLoadLikes(this.userCollectionService.noAuthLikes, this.userService.loggedIn()));
        }
        this.store$.dispatch(new SuccessLoadFollowed({items: success.body?.followed}, this.userService.loggedIn(), true));
        this.userService.isUserAuth.next(true);

        await this.checkNavigation(success.body?.user);

        this.store$.pipe(
          take(1),
          takeUntil(this.$destroyed),
          select(isPinnedPanelVisible)
        ).subscribe(value => {
          this.isOpenPinned = value;
          if (value) {
            this.store$.dispatch(new TogglePinnedPanel({display: false}));
          }
        });

        this.showUserAccountEmitter.emit(true);
        this.fetchNewUserData();
      },
      (err) => {
        this.userService.disableLoadingBar(this);
        if (err.status === 403) {
          this.needResendEmail = true;
          this.generalError = this.mapResponse(err.error.message);
        } else if (err.status === 422) {
          this.caseErrorMessage(err.error[0]);
        } else if (err.status === 429) {
          const message = err.error.message || err.error[0].message || ErrorEnum.LimitExceeded;
          this.toastrService.activeToastr$.next({message: message, timeout: 0});
        } else if (err.status > 300) {
          const message = err.error.message || err.error[0].message;
          this.generalError = this.mapResponse(message);
        }
      }
    );
  }

  caseErrorMessage(error) {
    switch (error.field) {
      case 'email':
        this.resOfEmailValidation = error.message;
        break;

      case 'password':
        this.resOfPasswordValidation = error.message;
        break;

      case 'system':
        this.generalError = this.mapResponse(error);
        break;

      default:
        this.generalError = this.mapResponse(error);
        break;
    }
  }

  setRememberMe() {
    this.userService.shouldRemember(this.shouldRemember);
  }

  mapResponse(message) {
    return [{
      value: message
    }];
  }

  closeLoginForm($event) {
    $event.preventDefault();
    this.closeLoginFormEmitter.emit();
  }

  emailValidate(f) {
    if (f.controls.email.status === 'invalid'.toUpperCase()) {
      // i18n
      const id = 'errors.wrong_email';
      if (f.controls.email.value.length === 0) {
        this.resOfEmailValidation = 'This field is required';
      } else {
        this.saveTranslationIntoProperty(id, 'resOfEmailValidation');
      }
    } else {
      this.resOfEmailValidation = null;
    }
  }

  passwordValidate(f) {
    if (f.controls.pass.status === 'invalid'.toUpperCase()) {
      if (f.controls.pass.value.length === 0) {
        this.resOfPasswordValidation = 'This field is required';
      } else if (f.controls.pass.value.length < 6) {
        this.resOfPasswordValidation = 'Password should be at least 6 characters';
      } else {
        this.resOfPasswordValidation = 'Some thing wrong with your password';
      }
    } else {
      this.resOfPasswordValidation = null;
    }
  }

  changePasswordView($event) {
    $event.preventDefault();
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }

  rememberUser() {
    this.shouldRemember = this.shouldRemember ? null : 'checked';
  }

  valueUpdated(propName) {
    this[`${propName}Updated`] = true;
    if (this.loginForm.controls[propName].value.length > 0) {
      return;
    }
    if (propName === 'email') {
      this.emailFocused = false;
    } else if (propName === 'pass') {
      this.passFocused = false;
    }
  }

  focus(type) {
    if (type === 'email') {
      this.emailFocused = true;
    } else if (type === 'pass') {
      this.passFocused = true;
    }
  }

  validationStyles(formCtrl) {
    if (!this.readVal(formCtrl)) {
      return null;
    }
    return {
      'fly-pass-valid': this.validationStyling(formCtrl),
      'fly-pass-invalid': !this.validationStyling(formCtrl)
    };
  }

  validationStyling(formCtrl) {
    return this.loginForm.controls[formCtrl].valid;
  }

  readVal(formCtrlName) {
    return this.loginForm.controls[formCtrlName].value;
  }

  showRestorePasswordForm() {
    this.showLoginForm = !this.showLoginForm;
  }

  saveTranslationIntoProperty(id: string, whereToSave: string) {
    this.userService.getTranslation(id).pipe(take(1)).subscribe(
      (res) => {
        this[whereToSave] = res;
      }
    );
  }

  private fetchNewUserData(): void {
    this.infoService
      .getClientInfo(new HttpParams().append('expand', 'country,picture,settings,homeCity.country,urlSetting,createdPlacesPics'))
      .pipe(take(1))
      .subscribe((response) => {
        this.store$.dispatch(
          new fromAuth.AuthActions.RestoreCurrentHome({
            id: response.city.id,
            name: response.city.name,
            country: response.city?.country,
            population: response.city.population,
            popular: response.popular,
            coordinates: `${response.city.lat},${response.city.lng}`,
            user: response.user
          })
        );
        this.store$.dispatch(new fromAuth.SetCurrentCoordinates({lat: response.city.lat, lng: response.city.lng}));

        this.store$.dispatch(
          new SuccessLoadNotifications({
              items: [],
              _meta: {},
              _extra: {unreadCount: response.notificationUnreadCount}
            },
            this.userService.loggedIn()
          ));
      });
  }

  checkNavigation(user: UserProfile): Promise<boolean | CheckNavigation> {
    return new Promise(async (res) => {

      const nextActionAfterAuth = this.ssrService.isBrowser() && localStorage.getItem('nextActionAfterAuth');

      if (nextActionAfterAuth) {
        let link: string;
        switch (nextActionAfterAuth) {
          case AfterAuthAction.AddPlace:
            link = '/place/add';
            break;
          case AfterAuthAction.OpenUserPersonalPage:
            link = getLinkByObject(user);
            break;
          default:
            link = '/';
            break;
        }
        localStorage.removeItem('nextActionAfterAuth');
        await this.router.navigate([link]);
        res({isNavigate: true});
      } else if (this.searchMode && this.ssrService.isBrowser()) {
        localStorage.setItem('previousUrl', location.pathname);
        res({isReload: true});
      } else {
        res(false);
      }
    });
  }
}
