import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeJourneyInterface, Journey } from "../../../interfaces/journey";
import { PinInterface, PlacePinExt } from "../../../interfaces";
import { UserCollectionService } from "../../../services/user-collection.service";
import { DEFAULT_TITLE_JOURNEY } from "../../../../constants";

@Component({
  selector: 'app-fly-popup-select',
  templateUrl: './fly-popup-select.component.html',
  styleUrls: ['../../pinned-panel/pinned-panel-collection-info/pinned-panel-collection-info.component.scss',
    './fly-popup-select.component.scss']
})
export class FlyPopupSelectComponent implements OnInit {

  @Input() data;
  @Input() journeys: Journey[];
  @Input() currentPlace: PlacePinExt | PinInterface;
  @Input() isAddToJourney: boolean;
  @Input() isProfile: boolean;
  @Input() classNameBySide: string;

  @Output() emitChangeJourney = new EventEmitter();
  @Output() toggleShowEmitter = new EventEmitter<boolean>();
  @Output() emitCreateJourney = new EventEmitter<ChangeJourneyInterface>();

  public selectedJourney = null;
  public allJourneys: Journey[];
  public defaultJourney: Journey;

  constructor(
    private userJourneyService: UserCollectionService,
  ) {
  }

  ngOnInit(): void {
    this.defaultJourney = this.journeys.find(journey => journey.isDefault);
    this.allJourneys = this.journeys.filter(journey => !journey.isDefault);
    this.allJourneys = JSON.parse(JSON.stringify(this.allJourneys));
    this.allJourneys = this.allJourneys.map(journey => {
      if (!journey.name) {
        journey.name = DEFAULT_TITLE_JOURNEY
      }
      return journey;
    }).filter(journey => !journey?.isFavorite);
  }

  public getSelectJourney(data: any): void {
    this.selectedJourney = data.option.value;
  }

  public displayFn(value: any): string {
    return value && value.name ? value.name : '';
  }

  public closePopup() {
    this.toggleShowEmitter.emit();
  }

  public createJourney() {
    this.emitCreateJourney.emit({
      currentPlace: this.currentPlace,
      isAddToCollection: !!this.isAddToJourney,
      selectedCollection: this.selectedJourney,
      defaultCollection: this.defaultJourney,
    });
  }

  public changeJourney() {
    this.userJourneyService.changeJourney({
      currentPlace: this.currentPlace,
      isAddToCollection: !!this.isAddToJourney,
      selectedCollection: this.selectedJourney,
      defaultCollection: this.defaultJourney,
    }, this.isProfile);
    this.closePopup();

    this.emitChangeJourney.emit({currentPlace: this.currentPlace, isAddToCollection: this.isAddToJourney});
  }
}
