import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
import { HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserFollowingService {
  public isFollowerProcessing = false;
  public isEventsLoaded = false;

  isFollowingAnimation$ = new Subject<void>();

  constructor(
    private httpService: HttpService,
  ) {
  }

  public getFollowedList(params?: any) {
    return this.httpService.get(`user/follower`, params);
  }

  public follow(params?: any, httpParams?: HttpParams) {
    return this.httpService.post(`user/follower`, params, httpParams);
  }

  public unfollow(userId: string) {
    return this.httpService.delete(`user/follower/${userId}`);
  }
}
