import { normalizePoints } from './normalize-points';
import { latLngToPoint } from './lat-lng-to-point';
import { defineGoogle } from '../../../libraries/define-google';

export const windowIntersectsWith = (_place, map) => {
  const place = _place && _place[0] ? _place[0] : _place;
  const google = defineGoogle();
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast(); // LatLng of the north-east corner
  const sw = bounds.getSouthWest(); // LatLng of the south-west corner
  const nwRectObj = normalizePoints(map, [place])[place.id].c;

  const realBounds = {
    nw: latLngToPoint(map, new google.maps.LatLng(ne.lat(), sw.lng()), google),
    se: latLngToPoint(map, new google.maps.LatLng(sw.lat(), ne.lng()), google)
  };

  return nwRectObj.y > realBounds.nw.y &&
    nwRectObj.y < realBounds.se.y;
};
