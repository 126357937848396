import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlyPopupProfileComponent } from "./fly-popup-profile.component";

@NgModule({
  declarations: [FlyPopupProfileComponent],
  exports: [
    FlyPopupProfileComponent
  ],
  imports: [
    CommonModule
  ]
})
export class FlyPopupProfileModule { }
