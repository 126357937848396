import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs/internal/Subject';
import { take, takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { SsrService, UserAccountService, UserService } from '../../services';
import { UserSetting, UserStatus } from '../../interfaces/user';
import * as fromAuth from '../../store/auth/auth.actions';
import { AuthActions } from '../../store/auth/auth.actions';
import { getHomeData, isPinnedPanelVisible, State, wasPlaceFormOpened } from '../../store/reducers';
import { AddDataTypesEnum } from "../../enums/add-data-types.enum";
import { StaticService } from "../../services/static.service";
import { MarkPlaceFormAsOpened, TogglePinnedPanel } from "../../store/panels/panels.actions";
import { combineLatest } from "rxjs/internal/observable/combineLatest";

@Component({
  selector: 'app-create-place',
  templateUrl: './create-place.component.html',
  styleUrls: ['./create-place.component.scss']
})
export class CreatePlaceComponent implements OnInit, OnDestroy {

  public isInactive: boolean;
  public isOpenAccount: boolean;
  public isOpenPinned: boolean;
  public placesData: any = {};
  public pageType: AddDataTypesEnum;
  public resizeWindow = 0;
  public showPlace: any = {};
  public typeFormValue: string;
  public userIsLogged: boolean;
  public addDataTypesEnum = AddDataTypesEnum;

  private destroyed$ = new Subject<void>();
  private isUserDataRequested: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeWindow = event.target.innerWidth;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    protected store$: Store<State>,
    private userService: UserService,
    private ssrService: SsrService,
    private userAccountService: UserAccountService,
    public staticService: StaticService,
  ) {
    this.userIsLogged = this.userService.loggedIn();
    if (!this.userIsLogged) {
      this.router.navigate(['/']);
    }
    combineLatest([
      this.store$.pipe(select(getHomeData)),
      this.store$.pipe(
        take(1),
        select(wasPlaceFormOpened)
      ),
    ]).pipe(
      takeUntil(this.destroyed$)
    )
      .subscribe(([result, wasPlaceFormOpened]) => {
        if (result && result.user) {
          const user = result.user;
          this.isInactive = +user.status === UserStatus.NotActivated;
          if (this.isInactive && wasPlaceFormOpened && !this.isUserDataRequested) {
            this.isUserDataRequested = true;
            this.store$.dispatch(new AuthActions.LoadUserProfile());
          }
          if (!wasPlaceFormOpened) {
            this.store$.dispatch(new MarkPlaceFormAsOpened());
          }
        }
      });
    this.store$.pipe(
      takeUntil(this.destroyed$),
      select(isPinnedPanelVisible)
    ).subscribe(value => {
      this.isOpenPinned = value;
    });
  }

  ngOnInit(): void {
    if (this.ssrService.isBrowser()) {
      this.resizeWindow = window.innerWidth;
    }
    this.staticService.setStaticType(this.route.snapshot.data['static']);
    this.pageType = this.route.snapshot.data['name'];
    if (this.route.snapshot.params['id']) {
      this.pageToggle();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.store$.dispatch(new TogglePinnedPanel({display: null}));
  }

  private pageToggle(): void {
    this.userAccountService.updateUserSettings({
      introPageReadDate: dayjs(new Date().toISOString()).format('YYYY-MM-DD')
    }).subscribe(({body}: { body: UserSetting[] }) => {
      this.store$.dispatch(new fromAuth.AuthActions.SetSessionDataUserSettings(body));
    });
  }

  public openUserAccount(value): void {
    this.isOpenAccount = value;
  }

  public discard(): void {
    if (this.typeFormValue) {
      this.removeTypeFormValue();
    } else {
      this.location.back();
    }
  }

  public removeTypeFormValue(): void {
    this.typeFormValue = undefined;
  }
}
