import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
import { WindowRef } from "./window.service";
import { HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserLikesService {
  public window: any;

  isLikeAnimation$ = new Subject<void>();

  constructor(
    private httpService: HttpService,
    private windowRef: WindowRef,
  ) {
    this.window = windowRef.getNativeWindow();
  }

  public getLikesList(params?: any) {
    return this.httpService.get(`user/pin-favourite`, params);
  }

  public addLikes(params?: any, httpParams?: HttpParams) {
    return this.httpService.post(`user/pin-favourite`, params, httpParams);
  }

  public deleteLikes(oid: number, otype: number) {
    return this.httpService.delete(`user/pin-favourite/${oid}`, new HttpParams().append('otype', String(otype)));
  }
}
