import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpClient,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import random from 'lodash/random';
import { throwError } from 'rxjs/internal/observable/throwError';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { filter, finalize, retry, takeUntil, tap } from 'rxjs/operators';
import { UserService } from './user-token.service';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../store/map/map.reducer';
import { AjaxFinish, AjaxStart } from '../store/layer/layer.actions';
import { SsrService } from './ssr.service';
import { transformStateKey } from '../libraries/transform-state-key';
import { Subject } from 'rxjs/internal/Subject';
import { GoogleAnalyticsService } from './google-analytics.service';
import dayjs from 'dayjs';
import { ToastrService } from "./toastr.service";
import { timer } from "rxjs";
import { ErrorEnum } from "../enums/error.enum";

import { SERVICE_UNAVAILABLE } from "../../constants";

export const IS_CACHE_ENABLED = new HttpContextToken<boolean>(() => false);

@Injectable()
export class RequestInterceptor implements HttpInterceptor, OnDestroy {

  public maxRetries = 5;
  public delayMs = 300;
  public preReferer = '';
  public prevUrl = '';

  private $destroyed = new Subject<void>();

  constructor(
    public http: HttpClient,
    private userService: UserService,
    public router: Router,
    private store$: Store<State>,
    private ssrService: SsrService,
    private gaService: GoogleAnalyticsService,
    private toastrService: ToastrService,
  ) {
    if (this.ssrService.isSSR()) {
      this.preReferer = this.ssrService.getRequest().headers['referer'] || null;
    } else {
      this.preReferer = document.referrer;
    }
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.$destroyed)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.prevUrl && this.prevUrl !== event.urlAfterRedirects) {
          this.preReferer = 'https://' + this.ssrService.getPlatformHost() + this.prevUrl;
        }
        this.prevUrl = event.urlAfterRedirects;
        //Here, We want to trigger the Google Analytics page view event on route change
        this.gaService.logPageView(event.urlAfterRedirects);
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // headers are not allowed in JSONP requests
    if (request.method === 'JSONP') {
      return next.handle(request);
    }
    let options;
    if (this.preReferer && request.url.indexOf('api/') > -1) {
      options = {
        headers: request.headers
          .set('Pre-referer', this.preReferer)
      };
    } else {
      options = {
        headers: request.headers
      };
    }
    const requestClone = request.clone(options);
    const uid = dayjs().unix() + ',' + random(0, 1000);
    this.store$.dispatch(new AjaxStart({uid}));
    const key = transformStateKey<HttpResponse<object>>(requestClone.urlWithParams);

    if (this.ssrService.isBrowser()) {
      const cachedResponse = requestClone.context.get(IS_CACHE_ENABLED) ? this.ssrService.getState(key) : null;
      if (cachedResponse) {
        this.ssrService.removeState(key);
        this.store$.dispatch(new AjaxFinish({uid}));

        return of(new HttpResponse({
          body: cachedResponse.body,
          status: 200,
          statusText: 'OK (from server)',
          // headers are not transferred by current implementation.
        }));
      }
    }

    return next.handle(requestClone).pipe(
      tap((event: HttpEvent<any>) => {
        if (event['status']) {
          if (this.ssrService.isSSR()) {
            //add response to cache to extract it on the client side from html
            if (event['body']) {
              const response = {
                body: event['body']
              };
              if (response && response.body) {
                if (response.body._links) {
                  delete response.body._links;
                }
                this.ssrService.setState(key, response);
              }
            }
          }
        }
      }),
      retry({
        delay: (error: any, retryCount: number) => {
          if (retryCount < this.maxRetries && error.status === 0) {
            return timer(this.delayMs);
          }
          return this.processError(error);
        },
      }),
      finalize(() => {
        this.store$.dispatch(new AjaxFinish({uid}));
      })
    );
  }

  private signOut() {
    this.userService.deleteCookiesOfToken();
    this.userService.signOut();
  }

  private processError(err: any) {
    switch (err.status) {
      case 0: {
        this.toastrService?.activeToastr$?.next({message: ErrorEnum.InternetConnection, timeout: 0});
        break;
      }
      case 401: {
        this.signOut();
        break;
      }
      case  403: {
        break;
      }
      case 429:
      case 404:
      case 410:
      case 422:
      case undefined:
      case 'undefined': {
        break;
      }
      default: {
        this.router.navigate([SERVICE_UNAVAILABLE], {queryParams: {code: err.status}});
      }
    }
    return throwError(err);
  }

}
