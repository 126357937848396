import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fly-modal-notice',
  templateUrl: './fly-modal-notice.component.html',
  styleUrls: ['./fly-modal-notice.component.scss']
})
export class FlyModalNoticeComponent {

  @Input() title: string;
  @Input() text: string;

  @Output() closeEmit = new EventEmitter<any>();

  close() {
    this.closeEmit.emit();
  }
}
