import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { constructMediaURL } from '../../libraries';
import { SeeDoPlaceTypes } from '../../enums/see-do-place-types';
import { PlaceWithCalculationDataNew } from '../../interfaces';
import { StaticService } from "../../services/static.service";
import { getLinkByObject } from "../../libraries/get-link-by-object";
import { getCountryFlag } from "../../libraries/get-coutnry-flag";

@Component({
  selector: 'app-map-tooltip-place',
  templateUrl: './map-tooltip-place.component.html',
  styleUrls: ['./map-tooltip-place.component.scss']
})
export class MapTooltipPlaceComponent implements OnInit, OnDestroy {

  @Input() place: PlaceWithCalculationDataNew;
  @Input() showCloseButton: boolean;

  @Output() closeTooltip = new EventEmitter<any>();
  @Output() showPlacePopup = new EventEmitter<any>();

  public imageSrc: string;
  public pageLink: string;
  public placeDescription: string;
  public constructMediaURL = constructMediaURL;
  public interval: any;
  public seeDoPlaceTypes = SeeDoPlaceTypes;
  public locationData: {
    countryId: string,
    name: string,
    countryName: string
  };

  protected readonly getCountryFlag = getCountryFlag;
  protected readonly getLinkByObject = getLinkByObject;

  constructor(
    private staticService: StaticService,
  ) {
  }

  ngOnInit() {
    this.imageSrc = this.getInitialImage();
    this.pageLink = this.place.name.replace(/ /g, '_');
    this.pageLink = `/places/${this.pageLink}/${this.place.id}`;
    this.placeDescription = this.place?.description?.replace(/(?:\r\n|\r|\n)/g, ' ');

    this.locationData = {
      countryId: this.place?.city?.country?.id || this.place?.country?.id,
      name: this.getLocationName(),
      countryName: this.getCountryName()
    }
  }

  ngOnDestroy(): void {
    this.imageSrc = null;
    clearInterval(this.interval);
  }

  onClosePopup() {
    this.closeTooltip.emit();
  }

  public overImage(): void {
    if (this.hasVideo()) {
      this.imageSrc = this.getVideoPreview();
    } else {
      const maxImage = Math.min(this.place.pictures.length - 1, 3);
      if (maxImage === 0) {
        return;
      }
      let index = 1;
      this.imageSrc = this.getImage(index);

      clearInterval(this.interval);
      this.interval = setInterval(() => {
        index++;
        if (index > maxImage) {
          index = 1;
        }
        this.imageSrc = this.getImage(index);
      }, 2000);
    }
  }

  public outImage(): void {
    this.imageSrc = this.getInitialImage();
    clearInterval(this.interval);
  }

  protected hasVideo(): boolean {
    return this.place?.video?.length > 0;
  }

  private getVideoPreview() {
    return constructMediaURL(this.place?.video[0]?.preview.thumb);
  }

  private getImage(index: number) {
    return constructMediaURL(this.place.pictures && this.place.pictures[index] ? this.place.pictures[index].thumb : null);
  }

  private getInitialImage() {
    if (this.hasVideo()) {
      return this.staticService.isMobileOrTablet()
        ? this.getVideoPreview()
        : constructMediaURL(this.place.video[0].poster.thumb);
    }

    return this.getImage(0);
  }

  private getCountryName(): string {
    return (this.place?.city && this.place.city.country)
      ? this.place.city.country?.name
      : this.place?.country && this.place.country.name;
  }

  private getLocationName(): string {
    let placeInfo = '';
    const countryName = this.getCountryName();
    if (this.place?.city) {
      placeInfo = this.place?.city?.name || this.place.cityName;
    } else if (countryName) {
      placeInfo = countryName;
    }

    return placeInfo;
  }
}
