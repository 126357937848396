<div class="field-wrap">
  <div class="icon-back" (click)="back.emit()">
    <img alt="" [appBasesrc]="'/assets/search/arrow_left.svg'">
  </div>
  <input name="destForm"
         class="dest-to"
         [placeholder]="destFormPlaceholder"
         #inputField
         (focus)="focusInput()"
         (blur)="blurInput()"
         (click)="open()"
         [formControl]="destFormControl"
         autocomplete="off"
         (keyup.enter)="selectDest(destFormControl); setValue(destFormControl.value)"
         (keydown)="onKeydown($event)">

  <a class="search-places wrap search-link clear-mat-icon tooltip-event-item"
     type="button" rel="clear" href="javascript:void(0);" (click)="clear()">
    <mat-icon class="">close</mat-icon>

    <span class="tooltip-event">Clear</span>
  </a>

  <a class="search-places wrap search-link"
     (click)="selectDest(destFormControl); setValue(destFormControl.value)">
    <div class="search-icon">
      <img [appBasesrc]="'/assets/search/search_pink.svg'" alt="">
    </div>
  </a>
</div>

<div class="autocomplete fly-beauty-scroll"
     *ngIf="opened && destList"
     [ngClass]="{'fix-scroll': destList.length>4}"
     [ngStyle]="{top: coordinates.AC.top, left: coordinates.AC.left}">
  <ng-scrollbar [orientation]="'vertical'" (keydown)="onKeydown($event)">
    <div class="option not-found" *ngIf="errorText">
      <img class="not-found__icon" [appBasesrc]="'/assets/info/warning-5-24.png'" alt="">
      <div class="not-found__info">
        Sorry, we couldn't find "{{ errorText }}"
      </div>
    </div>
    <div class="option"
         *ngFor="let dest of destList; let i = index"
         (click)="selectDest(dest, inputField)"
         [ngClass]="{'active': i === selectedItemIndex}"
    >
      <ng-container [ngSwitch]="dest.place.otype">

        <div *ngSwitchCase="1" [ngClass]="!!dest.place.pictures[0]?.thumb ? 'city-icon' : 'city-icon-gray'">
          <img alt=""
               [appBasesrc]="constructMediaURL(dest.place.pictures[0]?.thumb || '/assets/icons/city-icon.png')"/>
        </div>
        <div *ngSwitchCase="3" [ngClass]="!!dest.place.pictures[0]?.thumb ? 'place-icon' : 'place-icon-gray'">
          <img alt=""
               [appBasesrc]="constructMediaURL(dest.place.pictures[0]?.thumb || '/assets/icons/place-icon.png')"/>
        </div>
        <img *ngSwitchCase="12" class="user-icon" alt=""
             [appBasesrc]="constructMediaURL(dest.place.userPictures?.thumb || '/assets/icons/user-icon.png')"/>
        <img *ngSwitchDefault class="default-icon" [appBasesrc]="'/assets/search/search_gray.svg'" alt=""/>
      </ng-container>

      <div class="search-line" [innerHTML]="dest.innerHTML"></div>
    </div>
  </ng-scrollbar>
</div>
