import { ChangeDetectorRef, Directive, HostListener, OnInit } from '@angular/core';
import { getWindow } from '../../../libraries';
import { select, Store } from '@ngrx/store';
import { isPinnedPanelVisible, State } from '../../../store/reducers';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SsrService } from '../../../services';
import { DestroyComponent } from "../../../heplers/destroy.component";
import { takeUntil } from "rxjs/operators";
import { TogglePinnedPanel } from "../../../store/panels/panels.actions";

@Directive()
export abstract class BaseHeaderComponent extends DestroyComponent implements OnInit {

  public city: any;
  public cityId;
  public isOpenMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPinnedPlaceDisplay: boolean;
  public month;
  public resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  public searchMode: any = {city: null};

  @HostListener('window:resize', ['$event'])
  public resize() {
    const w = getWindow();
    if (w) {
      this.resizeWindow = w.innerWidth;
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    const w = getWindow();
    if (w && w.pageYOffset > 0 && this.resizeWindow >= 1024) {
      if (this.isPinnedPlaceDisplay) {
        this.togglePinnedPanel(false);
      }
    }
  }

  protected constructor(
    protected store$: Store<State>,
    protected cdRef: ChangeDetectorRef,
    protected ssrService: SsrService,
  ) {
    super();
    this.store$
      .pipe(
        select(isPinnedPanelVisible),
        takeUntil(this.destroy$)
      )
      .subscribe(isShowPanel => {
        this.isPinnedPlaceDisplay = isShowPanel;
      });
  }

  ngOnInit() {
    this.isOpenMap.next(false);
  }

  public isMobileDesign(): boolean {
    return !this.ssrService.isBrowser() && this.ssrService.isMobile()
      || this.ssrService.isBrowser() && this.resizeWindow <= 896;
  }

  public togglePinnedPanel(isOpen: boolean): void {
    this.store$.dispatch(new TogglePinnedPanel({display: isOpen}));
  }
}
