export interface CountryInterface {
  'id': string;
  'name': string;
  'bounds': string;
}

// temporary for support of old links like: /country/spain and redirect them to 301 /country/spain/es
const countries: any[] = [
  {
    'id': 'AF',
    'name': 'Afghanistan',
    'bounds': '29.377472,60.478443,38.483418,74.879448'
  },
  {
    'id': 'AX',
    'name': 'Aland Islands',
    'bounds': '59.906750,19.317694,60.488861,21.011862'
  },
  {
    'id': 'AL',
    'name': 'Albania',
    'bounds': '39.644862,19.263911,42.661167,21.057433'
  },
  {
    'id': 'DZ',
    'name': 'Algeria',
    'bounds': '18.960028,-8.673868,37.093723,11.979548'
  },
  {
    'id': 'AS',
    'name': 'American Samoa',
    'bounds': '-14.382478,-171.091888,-11.049700,-169.416077'
  },
  {
    'id': 'AD',
    'name': 'Andorra',
    'bounds': '42.428493,1.407187,42.656044,1.786543'
  },
  {
    'id': 'AO',
    'name': 'Angola',
    'bounds': '-18.042076,11.679219,-4.376826,24.082119'
  },
  {
    'id': 'AI',
    'name': 'Anguilla',
    'bounds': '18.160293,-63.168090,18.276902,-62.966555'
  },
  {
    'id': 'AQ',
    'name': 'Antarctica',
    'bounds': '-89.999900,-179.999900,-60.515533,179.999900'
  },
  {
    'id': 'AG',
    'name': 'Antigua and Barbuda',
    'bounds': '16.996979,-61.906425,17.729387,-61.672421'
  },
  {
    'id': 'AR',
    'name': 'Argentina',
    'bounds': '-55.061314,-73.582970,-21.781277,-53.591835'
  },
  {
    'id': 'AM',
    'name': 'Armenia',
    'bounds': '38.830528,43.449780,41.301834,46.772435'
  },
  {
    'id': 'AW',
    'name': 'Aruba',
    'bounds': '12.411708,-70.064474,12.623718,-69.865751'
  },
  {
    'id': 'TA',
    'name': 'Ascension and Tristan da Cunha',
    'bounds': '0.000000,0.000000,0.000000,0.000000'
  },
  {
    'id': 'AU',
    'name': 'Australia',
    'bounds': '-43.643970,112.911057,-10.062805,153.639252'
  },
  {
    'id': 'AT',
    'name': 'Austria',
    'bounds': '46.372652,9.530952,49.021163,17.162069'
  },
  {
    'id': 'AZ',
    'name': 'Azerbaijan',
    'bounds': '38.389153,44.774113,41.905640,50.370083'
  },
  {
    'id': 'BS',
    'name': 'Bahamas',
    'bounds': '22.852743,-78.995911,26.919243,-74.423874'
  },
  {
    'id': 'BH',
    'name': 'Bahrain',
    'bounds': '25.796862,50.454140,26.282583,50.664471'
  },
  {
    'id': 'BD',
    'name': 'Bangladesh',
    'bounds': '20.743334,88.028336,26.631945,92.673668'
  },
  {
    'id': 'BB',
    'name': 'Barbados',
    'bounds': '13.039844,-59.648922,13.327257,-59.420376'
  },
  {
    'id': 'BY',
    'name': 'Belarus',
    'bounds': '51.256416,23.176889,56.165806,32.770805'
  },
  {
    'id': 'BE',
    'name': 'Belgium',
    'bounds': '49.497557,2.540112,51.505640,6.406623'
  },
  {
    'id': 'BZ',
    'name': 'Belize',
    'bounds': '15.889300,-89.224815,18.496557,-87.776985'
  },
  {
    'id': 'BJ',
    'name': 'Benin',
    'bounds': '6.225748,0.774575,12.418347,3.851701'
  },
  {
    'id': 'BM',
    'name': 'Bermuda',
    'bounds': '32.247551,-64.887238,32.391224,-64.647186'
  },
  {
    'id': 'BT',
    'name': 'Bhutan',
    'bounds': '26.707640,88.759720,28.323778,92.125191'
  },
  {
    'id': 'BO',
    'name': 'Bolivia',
    'bounds': '-22.896133,-69.640762,-9.680567,-57.458096'
  },
  {
    'id': 'BA',
    'name': 'Bosnia and Herzegovina',
    'bounds': '42.546112,15.718945,45.239193,19.622223'
  },
  {
    'id': 'BW',
    'name': 'Botswana',
    'bounds': '-26.907246,19.999535,-17.780813,29.360781'
  },
  {
    'id': 'BV',
    'name': 'Bouvet Island',
    'bounds': '-54.450799,3.286776,-54.388738,3.434846'
  },
  {
    'id': 'BR',
    'name': 'Brazil',
    'bounds': '-33.750706,-73.985535,5.264877,-32.392998'
  },
  {
    'id': 'IO',
    'name': 'British Indian Ocean Territory',
    'bounds': '-7.438028,71.259972,-5.268333,72.493164'
  },
  {
    'id': 'VG',
    'name': 'British Virgin Islands',
    'bounds': '18.383711,-64.713128,18.757221,-64.268768'
  },
  {
    'id': 'BN',
    'name': 'Brunei Darussalam',
    'bounds': '4.003083,114.071442,5.047167,115.359444'
  },
  {
    'id': 'BG',
    'name': 'Bulgaria',
    'bounds': '41.242084,22.371166,44.217640,28.612167'
  },
  {
    'id': 'BF',
    'name': 'Burkina Faso',
    'bounds': '9.401108,-5.518916,15.082593,2.405395'
  },
  {
    'id': 'BI',
    'name': 'Burundi',
    'bounds': '-4.465713,28.993061,-2.310123,30.847729'
  },
  {
    'id': 'KH',
    'name': 'Cambodia',
    'bounds': '10.409083,102.339996,14.686417,107.627724'
  },
  {
    'id': 'CM',
    'name': 'Cameroon',
    'bounds': '1.652548,8.494763,13.078056,16.192116'
  },
  {
    'id': 'CA',
    'name': 'Canada',
    'bounds': '41.675980,-141.000000,83.110626,-52.636291'
  },
  {
    'id': 'CV',
    'name': 'Cape Verde',
    'bounds': '14.808022,-25.358747,17.197178,-22.669443'
  },
  {
    'id': 'BQ',
    'name': 'Carribean Netherlands',
    'bounds': '12.017149,-68.416458,12.304535,-68.192307'
  },
  {
    'id': 'KY',
    'name': 'Cayman Islands',
    'bounds': '19.263029,-81.432777,19.761700,-79.727272'
  },
  {
    'id': 'CF',
    'name': 'Central African Republic',
    'bounds': '2.220514,14.420097,11.007569,27.463421'
  },
  {
    'id': 'TD',
    'name': 'Chad',
    'bounds': '7.441068,13.473475,23.450369,24.002661'
  },
  {
    'id': 'CL',
    'name': 'Chile',
    'bounds': '-55.925623,-80.785851,-17.507553,-66.417557'
  },
  {
    'id': 'CN',
    'name': 'China',
    'bounds': '15.775416,73.557693,53.560860,134.773911'
  },
  {
    'id': 'CX',
    'name': 'Christmas Island',
    'bounds': '-10.570483,105.533277,-10.412356,105.712597'
  },
  {
    'id': 'CC',
    'name': 'Cocos (Keeling) Islands',
    'bounds': '-12.208726,96.816941,-12.072459,96.929489'
  },
  {
    'id': 'CO',
    'name': 'Colombia',
    'bounds': '-4.225869,-81.728111,13.380502,-66.869835'
  },
  {
    'id': 'KM',
    'name': 'Comoros',
    'bounds': '-12.387857,43.215790,-11.362381,44.538223'
  },
  {
    'id': 'CK',
    'name': 'Cook Islands',
    'bounds': '-21.944164,-161.093658,-10.023114,-157.312134'
  },
  {
    'id': 'CR',
    'name': 'Costa Rica',
    'bounds': '8.032975,-85.950623,11.216819,-82.555992'
  },
  {
    'id': 'CI',
    'name': 'Cote d\'Ivoire',
    'bounds': '4.357067,-8.599302,10.736642,-2.494897'
  },
  {
    'id': 'HR',
    'name': 'Croatia',
    'bounds': '42.435890,13.493222,46.538750,19.427389'
  },
  {
    'id': 'CU',
    'name': 'Cuba',
    'bounds': '19.828083,-84.957428,23.226042,-74.131775'
  },
  {
    'id': 'CW',
    'name': 'Curacao',
    'bounds': '12.032745,-69.157204,12.385672,-68.733948'
  },
  {
    'id': 'CY',
    'name': 'Cyprus',
    'bounds': '34.633285,32.273083,35.701527,34.597916'
  },
  {
    'id': 'CZ',
    'name': 'Czech Republic',
    'bounds': '48.542915,12.096194,51.058887,18.860111'
  },
  {
    'id': 'KP',
    'name': 'Democratic People\'s Republic of Korea',
    'bounds': '37.673332,124.315887,43.006054,130.674866'
  },
  {
    'id': 'CD',
    'name': 'Democratic Republic of the Congo',
    'bounds': '-13.455675,12.204144,5.386098,31.305912'
  },
  {
    'id': 'DK',
    'name': 'Denmark',
    'bounds': '54.562389,8.075611,57.748417,15.158834'
  },
  {
    'id': 'DJ',
    'name': 'Djibouti',
    'bounds': '10.909917,41.773472,12.706833,43.416973'
  },
  {
    'id': 'DM',
    'name': 'Dominica',
    'bounds': '15.201690,-61.484108,15.631809,-61.244152'
  },
  {
    'id': 'DO',
    'name': 'Dominican Republic',
    'bounds': '17.543159,-72.003487,19.929859,-68.320000'
  },
  {
    'id': 'EC',
    'name': 'Ecuador',
    'bounds': '-5.016157,-81.083684,1.435235,-75.187147'
  },
  {
    'id': 'EG',
    'name': 'Egypt',
    'bounds': '21.725389,24.698111,31.667334,36.898331'
  },
  {
    'id': 'SV',
    'name': 'El Salvador',
    'bounds': '13.148679,-90.128662,14.445067,-87.692162'
  },
  {
    'id': 'GQ',
    'name': 'Equatorial Guinea',
    'bounds': '0.920860,9.346865,2.346989,11.335724'
  },
  {
    'id': 'ER',
    'name': 'Eritrea',
    'bounds': '12.359555,36.438778,18.003084,43.134640'
  },
  {
    'id': 'EE',
    'name': 'Estonia',
    'bounds': '57.509310,21.828589,59.675314,28.209038'
  },
  {
    'id': 'ET',
    'name': 'Ethiopia',
    'bounds': '3.402422,32.999939,14.893750,47.986179'
  },
  {
    'id': 'FK',
    'name': 'Falkland Islands',
    'bounds': '-52.360512,-61.345192,-51.240650,-57.712486'
  },
  {
    'id': 'FO',
    'name': 'Faroe Islands',
    'bounds': '61.391030,-7.688192,62.393888,-6.256560'
  },
  {
    'id': 'FM',
    'name': 'Federated States of Micronesia',
    'bounds': '1.026290,137.336480,10.089040,163.037170'
  },
  {
    'id': 'FJ',
    'name': 'Fiji',
    'bounds': '-20.675970,177.140385,-12.479632,-178.424438'
  },
  {
    'id': 'FI',
    'name': 'Finland',
    'bounds': '59.808777,20.556944,70.096054,31.580944'
  },
  {
    'id': 'FR',
    'name': 'France',
    'bounds': '41.365821,-5.138996,51.089001,9.559615'
  },
  {
    'id': 'GF',
    'name': 'French Guiana',
    'bounds': '2.127094,-54.542511,5.776496,-51.613949'
  },
  {
    'id': 'PF',
    'name': 'French Polynesia',
    'bounds': '-27.653572,-152.877167,-7.903573,-134.929825'
  },
  {
    'id': 'TF',
    'name': 'French Southern Territories',
    'bounds': '-49.735184,50.170258,-37.790722,77.598808'
  },
  {
    'id': 'GA',
    'name': 'Gabon',
    'bounds': '-3.978806,8.695471,2.322612,14.502347'
  },
  {
    'id': 'GM',
    'name': 'Gambia',
    'bounds': '13.064252,-16.825079,13.826571,-13.797793'
  },
  {
    'id': 'GE',
    'name': 'Georgia',
    'bounds': '41.053196,40.010139,43.586498,46.725971'
  },
  {
    'id': 'DE',
    'name': 'Germany',
    'bounds': '47.270124,5.866250,54.911348,15.041816'
  },
  {
    'id': 'GH',
    'name': 'Ghana',
    'bounds': '4.736723,-3.255420,11.173301,1.191781'
  },
  {
    'id': 'GI',
    'name': 'Gibraltar',
    'bounds': '36.109031,-5.366261,36.155439,-5.338285'
  },
  {
    'id': 'GR',
    'name': 'Greece',
    'bounds': '34.802066,19.373604,41.748500,28.247083'
  },
  {
    'id': 'GL',
    'name': 'Greenland',
    'bounds': '59.777401,-73.042030,83.627357,-11.312319'
  },
  {
    'id': 'GD',
    'name': 'Grenada',
    'bounds': '11.986893,-61.802344,12.318284,-61.576770'
  },
  {
    'id': 'GP',
    'name': 'Guadeloupe',
    'bounds': '15.867565,-61.544765,16.516848,-61.000000'
  },
  {
    'id': 'GU',
    'name': 'Guam',
    'bounds': '13.233760,144.618060,13.654402,144.956894'
  },
  {
    'id': 'GT',
    'name': 'Guatemala',
    'bounds': '13.737302,-92.236290,17.815220,-88.223198'
  },
  {
    'id': 'GG',
    'name': 'Guernsey',
    'bounds': '49.407642,-2.673195,49.731728,-2.157715'
  },
  {
    'id': 'GN',
    'name': 'Guinea',
    'bounds': '7.193553,-14.926619,12.676220,-7.641071'
  },
  {
    'id': 'GW',
    'name': 'Guinea-Bissau',
    'bounds': '10.924265,-16.717535,12.680789,-13.636522'
  },
  {
    'id': 'GY',
    'name': 'Guyana',
    'bounds': '1.175080,-61.384762,8.557567,-56.480251'
  },
  {
    'id': 'HT',
    'name': 'Haiti',
    'bounds': '18.021032,-74.478584,20.087820,-71.613358'
  },
  {
    'id': 'HM',
    'name': 'Heard Island and McDonald Islands',
    'bounds': '-53.192001,72.596535,-52.909416,73.859146'
  },
  {
    'id': 'HN',
    'name': 'Honduras',
    'bounds': '12.982411,-89.350792,16.510256,-83.155403'
  },
  {
    'id': 'HK',
    'name': 'Hong Kong',
    'bounds': '22.153250,113.837753,22.559778,114.434753'
  },
  {
    'id': 'HU',
    'name': 'Hungary',
    'bounds': '45.743610,16.111889,48.585667,22.906000'
  },
  {
    'id': 'IS',
    'name': 'Iceland',
    'bounds': '63.394393,-24.532675,66.537793,-13.494621'
  },
  {
    'id': 'IN',
    'name': 'India',
    'bounds': '6.755953,68.484018,35.995287,97.415293'
  },
  {
    'id': 'ID',
    'name': 'Indonesia',
    'bounds': '-10.941861,95.009331,5.904417,141.021805'
  },
  {
    'id': 'IR',
    'name': 'Iran',
    'bounds': '25.064083,44.047279,39.777222,63.317471'
  },
  {
    'id': 'IQ',
    'name': 'Iraq',
    'bounds': '29.069445,38.795887,37.378029,48.575916'
  },
  {
    'id': 'IE',
    'name': 'Ireland',
    'bounds': '51.451584,-10.478556,55.387917,-6.002389'
  },
  {
    'id': 'IM',
    'name': 'Isle of Man',
    'bounds': '54.055916,-4.798722,54.419724,-4.311500'
  },
  {
    'id': 'IL',
    'name': 'Israel',
    'bounds': '29.496639,34.270279,33.340137,35.876804'
  },
  {
    'id': 'IT',
    'name': 'Italy',
    'bounds': '36.644082,6.626621,47.091784,18.520381'
  },
  {
    'id': 'JM',
    'name': 'Jamaica',
    'bounds': '17.705997,-78.369006,18.524766,-76.183099'
  },
  {
    'id': 'JP',
    'name': 'Japan',
    'bounds': '24.255169,122.933653,45.522957,145.817459'
  },
  {
    'id': 'JE',
    'name': 'Jersey',
    'bounds': '49.169834,-2.260028,49.265057,-2.022083'
  },
  {
    'id': null,
    'name': 'Johnston Atoll',
    'bounds': '42.428493,1.407187,42.656044,1.786543'
  },
  {
    'id': 'JO',
    'name': 'Jordan',
    'bounds': '29.185888,34.959999,33.367668,39.301167'
  },
  {
    'id': 'KZ',
    'name': 'Kazakhstan',
    'bounds': '40.936333,46.491859,55.451195,87.312668'
  },
  {
    'id': 'KE',
    'name': 'Kenya',
    'bounds': '-4.678047,33.908859,5.019938,41.899078'
  },
  {
    'id': 'KI',
    'name': 'Kiribati',
    'bounds': '-11.446881,169.556137,4.719570,-150.215347'
  },
  {
    'id': 'XK',
    'name': 'Kosovo',
    'bounds': '41.856370,19.977482,43.268250,21.803351'
  },
  {
    'id': 'KW',
    'name': 'Kuwait',
    'bounds': '28.524611,46.555557,30.095945,48.431473'
  },
  {
    'id': 'KG',
    'name': 'Kyrgyzstan',
    'bounds': '39.172832,69.276611,43.238224,80.283165'
  },
  {
    'id': 'LA',
    'name': 'Laos',
    'bounds': '13.910027,100.093056,22.500389,107.697029'
  },
  {
    'id': 'LV',
    'name': 'Latvia',
    'bounds': '55.674777,20.971956,58.085698,28.241272'
  },
  {
    'id': 'LB',
    'name': 'Lebanon',
    'bounds': '33.053860,35.114277,34.691418,36.639194'
  },
  {
    'id': 'LS',
    'name': 'Lesotho',
    'bounds': '-30.668964,27.029068,-28.572058,29.465761'
  },
  {
    'id': 'LR',
    'name': 'Liberia',
    'bounds': '4.353057,-11.492083,8.551791,-7.365113'
  },
  {
    'id': 'LY',
    'name': 'Libya',
    'bounds': '19.508045,9.387020,33.168999,25.150612'
  },
  {
    'id': 'LI',
    'name': 'Liechtenstein',
    'bounds': '47.048428,9.471674,47.270625,9.635643'
  },
  {
    'id': 'LT',
    'name': 'Lithuania',
    'bounds': '53.901306,20.941528,56.446918,26.871944'
  },
  {
    'id': 'LU',
    'name': 'Luxembourg',
    'bounds': '49.447859,5.735699,50.182772,6.530898'
  },
  {
    'id': 'MO',
    'name': 'Macao',
    'bounds': '22.180389,113.528946,22.222334,113.565834'
  },
  {
    'id': 'MK',
    'name': 'North Macedonia',
    'bounds': '40.860195,20.464695,42.361805,23.038139'
  },
  {
    'id': 'MG',
    'name': 'Madagascar',
    'bounds': '-25.608952,43.224876,-11.945433,50.483780'
  },
  {
    'id': 'MW',
    'name': 'Malawi',
    'bounds': '-17.125000,32.673950,-9.367541,35.916821'
  },
  {
    'id': 'MY',
    'name': 'Malaysia',
    'bounds': '0.855222,99.643448,7.363417,119.267502'
  },
  {
    'id': 'MV',
    'name': 'Maldives',
    'bounds': '-0.692694,72.693222,7.091587,73.637276'
  },
  {
    'id': 'ML',
    'name': 'Mali',
    'bounds': '10.159513,-12.242614,25.000002,4.244968'
  },
  {
    'id': 'MT',
    'name': 'Malta',
    'bounds': '35.806184,14.183425,36.082153,14.576492'
  },
  {
    'id': 'MH',
    'name': 'Marshall Islands',
    'bounds': '5.587639,165.524918,14.620000,171.931808'
  },
  {
    'id': 'MQ',
    'name': 'Martinique',
    'bounds': '14.392262,-61.230118,14.878819,-60.815510'
  },
  {
    'id': 'MR',
    'name': 'Mauritania',
    'bounds': '14.715547,-17.066521,27.298073,-4.827674'
  },
  {
    'id': 'MU',
    'name': 'Mauritius',
    'bounds': '-20.525717,56.512718,-10.319255,63.500179'
  },
  {
    'id': 'YT',
    'name': 'Mayotte',
    'bounds': '-13.000132,45.037960,-12.648891,45.292950'
  },
  {
    'id': 'MX',
    'name': 'Mexico',
    'bounds': '14.532866,-118.453949,32.716759,-86.703392'
  },
  {
    'id': null,
    'name': 'Midway Islands',
    'bounds': '42.428493,1.407187,42.656044,1.786543'
  },
  {
    'id': 'MD',
    'name': 'Moldova',
    'bounds': '45.468887,26.618944,48.490166,30.135445'
  },
  {
    'id': 'MC',
    'name': 'Monaco',
    'bounds': '43.724728,7.408962,43.751967,7.439939'
  },
  {
    'id': 'MN',
    'name': 'Mongolia',
    'bounds': '41.567638,87.749664,52.154251,119.924309'
  },
  {
    'id': 'ME',
    'name': 'Montenegro',
    'bounds': '41.850166,18.461306,43.570137,20.358833'
  },
  {
    'id': 'MS',
    'name': 'Montserrat',
    'bounds': '16.674769,-62.241382,16.824060,-62.144100'
  },
  {
    'id': 'MA',
    'name': 'Morocco',
    'bounds': '27.662115,-13.168586,35.922497,-0.991750'
  },
  {
    'id': 'MZ',
    'name': 'Mozambique',
    'bounds': '-26.868685,30.217319,-10.471883,40.842995'
  },
  {
    'id': 'MM',
    'name': 'Myanmar',
    'bounds': '9.784583,92.189278,28.543249,101.176781'
  },
  {
    'id': 'NA',
    'name': 'Namibia',
    'bounds': '-28.971430,11.715630,-16.959894,25.256701'
  },
  {
    'id': 'NR',
    'name': 'Nauru',
    'bounds': '-0.552333,166.899033,-0.504306,166.945282'
  },
  {
    'id': 'NP',
    'name': 'Nepal',
    'bounds': '26.356722,80.056274,30.433390,88.199333'
  },
  {
    'id': 'NL',
    'name': 'Netherlands',
    'bounds': '50.750367,3.358378,53.515713,7.227499'
  },
  {
    'id': null,
    'name': 'Netherlands Antilles',
    'bounds': '42.428493,1.407187,42.656044,1.786543'
  },
  {
    'id': 'NC',
    'name': 'New Caledonia',
    'bounds': '-22.698000,163.564667,-19.549778,168.129135'
  },
  {
    'id': 'NZ',
    'name': 'New Zealand',
    'bounds': '-47.286026,166.715500,-34.389668,-180.000000'
  },
  {
    'id': 'NI',
    'name': 'Nicaragua',
    'bounds': '10.707543,-87.690308,15.025909,-82.738289'
  },
  {
    'id': 'NE',
    'name': 'Niger',
    'bounds': '11.696975,0.166250,23.525026,15.995643'
  },
  {
    'id': 'NG',
    'name': 'Nigeria',
    'bounds': '4.277144,2.668432,13.892007,14.680073'
  },
  {
    'id': 'NU',
    'name': 'Niue',
    'bounds': '-19.152193,-169.951004,-18.951069,-169.775177'
  },
  {
    'id': 'NF',
    'name': 'Norfolk Island',
    'bounds': '-29.063077,167.915432,-28.995171,167.997737'
  },
  {
    'id': 'MP',
    'name': 'Northern Mariana Islands',
    'bounds': '14.110230,144.886260,20.553440,146.065280'
  },
  {
    'id': 'NO',
    'name': 'Norway',
    'bounds': '57.977917,4.650167,71.188110,31.078053'
  },
  {
    'id': 'OM',
    'name': 'Oman',
    'bounds': '16.645750,51.882000,26.387972,59.836582'
  },
  {
    'id': 'PK',
    'name': 'Pakistan',
    'bounds': '23.786722,60.878613,37.097000,77.840919'
  },
  {
    'id': 'PW',
    'name': 'Palau',
    'bounds': '2.803600,131.117880,8.469660,134.723070'
  },
  {
    'id': 'PS',
    'name': 'Palestine',
    'bounds': '31.216541,34.216660,32.546387,35.573296'
  },
  {
    'id': 'PA',
    'name': 'Panama',
    'bounds': '7.197906,-83.051445,9.637514,-77.174110'
  },
  {
    'id': 'PG',
    'name': 'Papua New Guinea',
    'bounds': '-11.657861,140.842865,-1.318639,155.963440'
  },
  {
    'id': 'PY',
    'name': 'Paraguay',
    'bounds': '-27.608738,-62.647076,-19.294041,-54.259354'
  },
  {
    'id': 'PE',
    'name': 'Peru',
    'bounds': '-18.349728,-81.326744,-0.012977,-68.677986'
  },
  {
    'id': 'PH',
    'name': 'Philippines',
    'bounds': '4.642098,116.928864,21.121885,126.604974'
  },
  {
    'id': 'PN',
    'name': 'Pitcairn',
    'bounds': '-24.672565,-128.346436,-24.315865,-124.772850'
  },
  {
    'id': 'PL',
    'name': 'Poland',
    'bounds': '49.006363,14.123000,54.839138,24.150749'
  },
  {
    'id': 'PT',
    'name': 'Portugal',
    'bounds': '36.961250,-9.500527,42.154311,-6.189159'
  },
  {
    'id': 'PR',
    'name': 'Puerto Rico',
    'bounds': '17.926405,-67.942726,18.520166,-65.242737'
  },
  {
    'id': 'QA',
    'name': 'Qatar',
    'bounds': '24.482944,50.757221,26.154722,51.636639'
  },
  {
    'id': 'CG',
    'name': 'Republic of the Congo',
    'bounds': '-5.027223,11.205009,3.703082,18.649839'
  },
  {
    'id': 'RE',
    'name': 'Reunion',
    'bounds': '-21.383747,55.212192,-20.868391,55.838194'
  },
  {
    'id': 'RO',
    'name': 'Romania',
    'bounds': '43.619017,20.261995,48.265391,29.715299'
  },
  {
    'id': 'RU',
    'name': 'Russia',
    'bounds': '41.188862,19.250000,81.857361,-169.050000'
  },
  {
    'id': 'RU',
    'name': 'Russian Federation',
    'sn': 'Russia',
    'bounds': '41.188862,19.250000,81.857361,-169.050000'
  },
  {
    'id': 'RW',
    'name': 'Rwanda',
    'bounds': '-2.840230,28.861731,-1.047167,30.899747'
  },
  {
    'id': 'SH',
    'name': 'Saint Helena',
    'bounds': '-16.019543,-14.421230,-7.887815,-5.638753'
  },
  {
    'id': 'KN',
    'name': 'Saint Kitts and Nevis',
    'bounds': '17.095343,-62.869560,17.420118,-62.543266'
  },
  {
    'id': 'LC',
    'name': 'Saint Lucia',
    'bounds': '13.707269,-61.079957,14.110317,-60.873231'
  },
  {
    'id': 'MF',
    'name': 'Saint Martin',
    'bounds': '18.047172,-63.150361,18.125295,-63.010591'
  },
  {
    'id': 'PM',
    'name': 'Saint Pierre and Miquelon',
    'bounds': '46.782650,-56.407092,47.143768,-56.125330'
  },
  {
    'id': 'VC',
    'name': 'Saint Vincent and the Grenadines',
    'bounds': '12.583985,-61.460903,13.377834,-61.113880'
  },
  {
    'id': 'WS',
    'name': 'Samoa',
    'bounds': '-14.040939,-172.798599,-13.432207,-171.415741'
  },
  {
    'id': 'SM',
    'name': 'San Marino',
    'bounds': '43.893700,12.403605,43.992093,12.515849'
  },
  {
    'id': 'ST',
    'name': 'Sao Tome and Principe',
    'bounds': '0.024766,6.470170,1.701323,7.466374'
  },
  {
    'id': 'SA',
    'name': 'Saudi Arabia',
    'bounds': '15.614250,34.495693,32.158333,55.666584'
  },
  {
    'id': 'SN',
    'name': 'Senegal',
    'bounds': '12.307275,-17.535236,16.691633,-11.355887'
  },
  {
    'id': 'RS',
    'name': 'Serbia',
    'bounds': '42.232216,18.817020,46.181389,23.004997'
  },
  {
    'id': 'SC',
    'name': 'Seychelles',
    'bounds': '-9.753867,46.204769,-4.283717,56.297703'
  },
  {
    'id': 'SL',
    'name': 'Sierra Leone',
    'bounds': '6.929611,-13.307631,10.000000,-10.284238'
  },
  {
    'id': 'SG',
    'name': 'Singapore',
    'bounds': '1.258556,103.638275,1.471278,104.007469'
  },
  {
    'id': 'SX',
    'name': 'Sint Maarten',
    'bounds': '18.006632,-63.141462,18.065188,-63.010419'
  },
  {
    'id': 'SK',
    'name': 'Slovakia',
    'bounds': '47.728111,16.847750,49.603168,22.570444'
  },
  {
    'id': 'SI',
    'name': 'Slovenia',
    'bounds': '45.421813,13.375334,46.876628,16.610631'
  },
  {
    'id': 'SB',
    'name': 'Solomon Islands',
    'bounds': '-11.850555,155.508606,-6.589611,166.980865'
  },
  {
    'id': 'SO',
    'name': 'Somalia',
    'bounds': '-1.674868,40.986595,11.979166,51.412636'
  },
  {
    'id': 'ZA',
    'name': 'South Africa',
    'bounds': '-34.839828,16.458021,-22.126612,32.895973'
  },
  {
    'id': 'GS',
    'name': 'South Georgia and the South Sandwich Islands',
    'bounds': '-59.463193,-38.047951,-53.980897,-26.252070'
  },
  {
    'id': 'KR',
    'name': 'South Korea',
    'bounds': '33.195410,125.887442,38.593389,129.583016'
  },
  {
    'id': 'SS',
    'name': 'South Sudan',
    'bounds': '3.493394,24.140274,12.219149,35.940552'
  },
  {
    'id': 'ES',
    'name': 'Spain',
    'bounds': '36.000104,-9.301516,43.791357,4.327785'
  },
  {
    'id': 'LK',
    'name': 'Sri Lanka',
    'bounds': '5.916833,79.652916,9.831361,81.881279'
  },
  {
    'id': 'SD',
    'name': 'Sudan',
    'bounds': '8.684721,21.827774,22.232220,38.607498'
  },
  {
    'id': 'SR',
    'name': 'Suriname',
    'bounds': '1.831145,-58.086563,6.004546,-53.977493'
  },
  {
    'id': 'SJ',
    'name': 'Svalbard and Jan Mayen',
    'bounds': '79.220306,17.699389,80.762085,33.287334'
  },
  {
    'id': 'SZ',
    'name': 'Swaziland',
    'bounds': '-27.317101,30.794107,-25.719648,32.137260'
  },
  {
    'id': 'SE',
    'name': 'Sweden',
    'bounds': '55.337444,11.109499,69.059967,24.155245'
  },
  {
    'id': 'CH',
    'name': 'Switzerland',
    'bounds': '45.819154,5.956614,47.809868,10.493474'
  },
  {
    'id': 'SY',
    'name': 'Syrian Arab Republic',
    'bounds': '32.310665,35.727222,37.319138,42.385029'
  },
  {
    'id': 'TW',
    'name': 'Taiwan',
    'bounds': '21.896607,119.534691,25.300290,122.006740'
  },
  {
    'id': 'TJ',
    'name': 'Tajikistan',
    'bounds': '36.674137,67.387138,41.042252,75.137222'
  },
  {
    'id': 'TZ',
    'name': 'Tanzania',
    'bounds': '-11.745696,29.327168,-0.990736,40.443222'
  },
  {
    'id': 'TH',
    'name': 'Thailand',
    'bounds': '5.610000,97.345642,20.463194,105.639389'
  },
  {
    'id': 'TL',
    'name': 'Timor-Leste',
    'bounds': '-9.504650,124.044647,-8.126870,127.342117'
  },
  {
    'id': 'TG',
    'name': 'Togo',
    'bounds': '6.104417,-0.147324,11.138977,1.806693'
  },
  {
    'id': 'TK',
    'name': 'Tokelau',
    'bounds': '-9.381111,-172.500336,-8.553614,-171.211426'
  },
  {
    'id': 'TO',
    'name': 'Tonga',
    'bounds': '-21.455057,-175.682266,-15.562988,-173.907578'
  },
  {
    'id': 'TT',
    'name': 'Trinidad and Tobago',
    'bounds': '10.036105,-61.923771,11.338342,-60.517933'
  },
  {
    'id': 'TN',
    'name': 'Tunisia',
    'bounds': '30.240417,7.524833,37.543915,11.598278'
  },
  {
    'id': 'TR',
    'name': 'Turkey',
    'bounds': '35.815418,25.668501,42.107613,44.834999'
  },
  {
    'id': 'TM',
    'name': 'Turkmenistan',
    'bounds': '35.141083,52.441444,42.795555,66.684303'
  },
  {
    'id': 'TC',
    'name': 'Turks and Caicos Islands',
    'bounds': '21.422626,-72.483871,21.961878,-71.123642'
  },
  {
    'id': 'TV',
    'name': 'Tuvalu',
    'bounds': '-10.801169,176.064865,-5.641972,179.863281'
  },
  {
    'id': 'UM',
    'name': 'U.S. Minor Outlying Islands',
    'bounds': '-0.389006,-177.392029,28.219814,166.654526'
  },
  {
    'id': 'UG',
    'name': 'Uganda',
    'bounds': '-1.481531,29.573465,4.231369,35.001054'
  },
  {
    'id': 'UA',
    'name': 'Ukraine',
    'bounds': '44.390415,22.128889,52.369362,40.207390'
  },
  {
    'id': 'AE',
    'name': 'United Arab Emirates',
    'bounds': '22.633329,51.583328,26.084160,56.381660'
  },
  {
    'id': 'GB',
    'name': 'United Kingdom',
    'bounds': '49.906193,-8.623555,59.360249,1.759000'
  },
  {
    'id': 'US',
    'name': 'United States',
    'bounds': '24.544245,-124.733253,49.388611,-66.954811'
  },
  {
    'id': 'US',
    'name': 'United States of America',
    'sn': 'United States',
    'bounds': '24.544245,-124.733253,49.388611,-66.954811'
  },
  {
    'id': 'VI',
    'name': 'United States Virgin Islands',
    'bounds': '17.673931,-65.101333,18.415382,-64.565193'
  },
  {
    'id': 'UY',
    'name': 'Uruguay',
    'bounds': '-34.980816,-58.442722,-30.082224,-53.073933'
  },
  {
    'id': 'UZ',
    'name': 'Uzbekistan',
    'bounds': '37.184444,55.996639,45.575001,73.132278'
  },
  {
    'id': 'VU',
    'name': 'Vanuatu',
    'bounds': '-20.248945,166.524979,-13.073444,169.904785'
  },
  {
    'id': 'VA',
    'name': 'Vatican City',
    'bounds': '41.900280,12.445707,41.907438,12.458375'
  },
  {
    'id': 'VE',
    'name': 'Venezuela',
    'bounds': '0.626311,-73.354073,12.201903,-59.803780'
  },
  {
    'id': 'VN',
    'name': 'Vietnam',
    'bounds': '8.559611,102.148224,23.388834,109.464638'
  },
  {
    'id': null,
    'name': 'Netherlands Antilles',
    'bounds': '42.428493,1.407187,42.656044,1.786543'
  },
  {
    'id': 'WF',
    'name': 'Wallis and Futuna',
    'bounds': '-14.314560,-178.184811,-13.216758,-176.161743'
  },
  {
    'id': 'EH',
    'name': 'Western Sahara',
    'bounds': '20.774158,-17.103182,27.669674,-8.670276'
  },
  {
    'id': 'YE',
    'name': 'Yemen',
    'bounds': '12.111091,42.532539,18.999999,54.530539'
  },
  {
    'id': 'ZM',
    'name': 'Zambia',
    'bounds': '-18.079473,21.999371,-8.224360,33.705704'
  },
  {
    'id': 'ZW',
    'name': 'Zimbabwe',
    'bounds': '-22.417738,25.237028,-15.608835,33.056305'
  }
];

export let getCountries = () => {
  return countries;
};
