<div class="form-container" id="mainDiv">
  <ol>
    <h3>Notice by copyright owner, licensee or agent of claimed infringement of copyright in copyright material</h3>
    <form [formGroup]="copyrightForm">

      <li>
        <div class="form-group">
          <label>First name:</label>
          <input class="form-control" name="name" formControlName="firstName" required/>

          <div class="alert alert-danger"
               *ngIf="copyrightForm.controls['firstName'].invalid && copyrightForm.controls['firstName'].touched">
            First name cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Last name:</label>
          <input
            class="form-control"
            name="name"
            formControlName="lastName" required
          />

          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['lastName'].invalid && copyrightForm.controls['lastName'].touched">
            Last name cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Company Name:</label>
          <input
            class="form-control"
            name="name"
            formControlName="companyName" required
          />

          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['companyName'].invalid && copyrightForm.controls['companyName'].touched">
            Company name cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Name of the rights owner:</label>
          <input
            type="text"
            class="form-control"
            name="rightsOwner"
            formControlName="rightsOwner"
            required
          />

          <div
            class="alert alert-danger"
            *ngIf="
          copyrightForm.controls['rightsOwner'].invalid &&
          copyrightForm.controls['rightsOwner'].touched
          "
          >
            <small *ngIf="copyrightForm.get('rightsOwner').errors.required">Name of the rights owner cannot be
              blank.</small>
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Email address:</label>
          <input
            type="email"
            class="form-control"
            name="email"
            formControlName="userEmail" required
          />

          <div
            class="alert alert-danger"
            *ngIf="
          copyrightForm.controls['userEmail'].invalid &&
          copyrightForm.controls['userEmail'].touched
          "
          >
            <small *ngIf="copyrightForm.get('userEmail').errors.email">Specified email address is incorrect.</small>
            <small *ngIf="copyrightForm.get('userEmail').errors.required">Email address cannot be blank.</small>
          </div>
        </div>
      </li>

      <li>
        <div class="form-group select-group">
          <label>Country:</label>
          <select formControlName="userCountry"
                  (ngModelChange)="changeSettingValue($event)">
            <option value="">Select country</option>
            <option *ngFor="let country of allCountry"
                    [ngValue]="country.id"> {{country.name}}</option>
          </select>
          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['userCountry'].invalid && copyrightForm.controls['userCountry'].touched">
            Country is not selected.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Mailing (Post) address:</label>
          <input type="text"
                 class="form-control"
                 name="postalAddress"
                 formControlName="postalAddress"
                 required/>
          <div class="alert alert-danger"
               *ngIf="copyrightForm.controls['postalAddress'].invalid && copyrightForm.controls['postalAddress'].touched">
            Mailing (Post) address cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Please provide links (URLs) on Explorow that leading directly to the specific content you are
            reporting:</label>
          <input
            type="url"
            class="form-control"
            name="url"
            formControlName="userURL" required
          />
          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['userURL'].invalid && copyrightForm.controls['userURL'].touched">
            URL cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group">
          <label>Please tell us a detailed description of your work and additional information that can
            help us understand your report
            (Identify and describe the copyrighted work)</label>
          <textarea
            class="textarea"
            formControlName="text" required
          ></textarea>
          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['text'].invalid && copyrightForm.controls['text'].touched">
            Text cannot be blank.
          </div>
        </div>
      </li>

      <li>
        <div class="form-group ">
          <label>
            <input
              class="input-checkbox"
              type="checkbox"
              formControlName="accurateNoticeCheckbox"
              required/>
            The information in this notice is accurate
          </label>
          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['accurateNoticeCheckbox'].invalid && copyrightForm.controls['accurateNoticeCheckbox'].touched">
            Required field.
          </div>
          <br>
          <label>
            <input
              class="input-checkbox m-0"
              type="checkbox"
              formControlName="copyrightCheckbox"
              required/>
            I am the *owner/*exclusive licensee/*agent of the owner/*agent of the exclusive licensee of the copyright in
            the following copyright material residing on your system or network, and I believe, in good faith, that the
            storage of the material on your system or network is not authorised by the owner or any exclusive licensee
            of the copyright in that material, or by the Copyright Act 1968, and is therefore an infringement of the
            copyright in the material
          </label>
          <div
            class="alert alert-danger"
            *ngIf="copyrightForm.controls['copyrightCheckbox'].invalid && copyrightForm.controls['copyrightCheckbox'].touched">
            Required field.
          </div>
        </div>
      </li>

      <div class="form-group submit">
        <button (click)="onSubmit()"
                [disabled]="copyrightForm.invalid"
                class="btn btn-default">
          Submit
        </button>
      </div>
    </form>
  </ol>
</div>

<div class="popup-toastr" *ngIf="isShowToastr" [ngClass]="{'alert-error': errorSubmit}">
  <span>{{ alertMessage }}</span>
</div>
