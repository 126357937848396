import { PanelsActionsType, PanelsActionsUnion } from "./panels.actions";

export interface State {
  isPinnedPanelVisible: boolean;
  isFavoritePanelVisible: boolean;
  isFollowingPanelVisible: boolean;
  wasPlaceFormOpened: boolean;
}

const initialState: State = {
  isPinnedPanelVisible: null,
  isFavoritePanelVisible: null,
  isFollowingPanelVisible: null,
  wasPlaceFormOpened: null,
};

export function reducer(state: State = initialState, action: PanelsActionsUnion): State {
  switch (action.type) {
    case PanelsActionsType.TogglePinnedPanel: {
      const isPinnedPanelVisible = (action.payload && action.payload.hasOwnProperty('display'))
        ? action.payload.display
        : !state.isPinnedPanelVisible;
      return {
        ...state,
        isFavoritePanelVisible: false,
        isFollowingPanelVisible: false,
        isPinnedPanelVisible
      };
    }
    case PanelsActionsType.ClosePinnedPanel: {
      return {
        ...state,
        isFavoritePanelVisible: false,
        isFollowingPanelVisible: false,
        isPinnedPanelVisible: false
      };
    }
    case PanelsActionsType.ToggleFavoritePanel: {
      const isFavoritePanelVisible = (action.payload && action.payload.hasOwnProperty('display'))
        ? action.payload.display
        : !state.isFavoritePanelVisible;
      return {
        ...state,
        isPinnedPanelVisible: false,
        isFollowingPanelVisible: false,
        isFavoritePanelVisible
      };
    }
    case PanelsActionsType.CloseFavoritePanel: {
      return {
        ...state,
        isPinnedPanelVisible: false,
        isFollowingPanelVisible: false,
        isFavoritePanelVisible: false
      };
    }
    case PanelsActionsType.ToggleFollowingPanel: {
      const isFollowingPanelVisible = (action.payload && action.payload.hasOwnProperty('display'))
        ? action.payload.display
        : !state.isFollowingPanelVisible;
      return {
        ...state,
        isFavoritePanelVisible: false,
        isPinnedPanelVisible: false,
        isFollowingPanelVisible
      };
    }
    case PanelsActionsType.CloseFollowingPanel: {
      return {
        ...state,
        isFavoritePanelVisible: false,
        isPinnedPanelVisible: false,
        isFollowingPanelVisible: false,
      };
    }
    case PanelsActionsType.MarkPlaceFormAsOpened: {
      return {
        ...state,
        wasPlaceFormOpened: true,
      };
    }
    default:
      return state;
  }
}

export const _isPinnedPanelVisible = (state: State) => state.isPinnedPanelVisible;
export const _isFavoritePanelVisible = (state: State) => state.isFavoritePanelVisible;
export const _isFollowingPanelVisible = (state: State) => state.isFollowingPanelVisible;
export const _wasPlaceFormOpened = (state: State) => state.wasPlaceFormOpened;
