import { Injectable } from '@angular/core';
import { Subject } from "rxjs/internal/Subject";

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  public openTooltip = false;

  public closeTooltip$ = new Subject<void>();

  public activeToastr$ = new Subject<{ message: string, timeout?: number, isDanger?: boolean }>();
  constructor() { }
}
