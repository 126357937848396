<div class="wanna-go tooltip-event-item pin-it"
     *ngIf="!isVisibleTextBtn && !buttonTmpl && place"
     (click)="togglePinned()">
  <img *ngIf="(isWannaGoCity$|async) === false else pinned" [src]="'/assets/pin/pinit-empty-title.svg'" alt="unpinned">
  <ng-template #pinned>
    <img [src]="'/assets/icons/favorites.svg'" alt="pinned">
  </ng-template>
  <span class="tooltip-event not-hide">
    {{ (isWannaGoCity$|async) === false ? tooltipText : 'Pinned' }}
  </span>
</div>

<div class="pin-it-container">
  <div class="button-pin-place tooltip-event-item"
       *ngIf="!isVisibleTextBtn && buttonTmpl">
    <button mat-raised-button (click)="togglePinned()">
      <span [ngClass]="{pinned: place.pinned, unpinned: !place.pinned}"> </span>
      <span> Pin it </span>
    </button>

    <span class="tooltip-event not-hide" *ngIf="place.pinned">
    {{ (isWannaGoCity$|async) === false ? tooltipText : 'Pinned' }}
  </span>
  </div>


  <ng-container *ngIf="userIsLogged; else noAuthForm">
    <div class="pin-tooltip" [class.visible]="isShowTooltip" *ngIf="defaultJourney">
      <div class="empty-places" (click)="toggleShowTooltip(false)"></div>
      <div class="tooltip-content">Removed fom {{defaultJourney.name}}</div>
    </div>
  </ng-container>
  <ng-template #noAuthForm>
    <div class="pin-tooltip" [class.visible]="isShowTooltip">
      <div class="empty-places" (click)="toggleShowTooltip(false)"></div>
      <div class="tooltip-content">Removed from Unnamed journey</div>
    </div>
  </ng-template>
</div>

<div class="visibleTextBtn button-pin-place tooltip-event-item"
     *ngIf="isVisibleTextBtn"
     (click)="togglePinned()">
  <img *ngIf="(isWannaGoCity$|async) === false else pinned" [src]="'/assets/pin/pinit-empty-title.svg'" alt="unpinned">
  <ng-template #pinned>
    <img [src]="'/assets/pin/pinit-full-title.svg'" alt="Like it">
  </ng-template>
  <span>Like it</span>
</div>
