import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PlaceStatusType } from '../../enums/place-status.enum';
import { StaticService } from "../../../../services/static.service";

@Component({
  selector: 'app-profile-user-places',
  templateUrl: 'profile-user-places.component.html',
  styleUrls: ['profile-user-places.component.scss']
})

export class ProfileUserPlacesComponent implements OnDestroy {
  @Input() places: any[] = [];
  @Input() placesOtherCount: number = 0;
  @Input() isAscSort: boolean;
  @Input() isRecentlyDeletedPage: boolean;

  @Output() recoverPlaceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleRecentlyDeletedEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleSortEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletePlaceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadMorePlacesEmitter: EventEmitter<any> = new EventEmitter<any>();

  public placeStatus = PlaceStatusType;
  public isConfirm = false;
  public selectedItem: any;

  constructor(
    public staticService: StaticService,
  ) {
  }

  ngOnDestroy(): void {
    this.emitFunc('toggleRecentlyDeleted', false, false, this.isRecentlyDeletedPage);
  }

  public openDialog(item: any): void {
    this.isConfirm = true;
    this.selectedItem = item;
  }

  public deletePlace(value: boolean): void {
    if (value) {
      this.places = this.places.filter(p => p.id !== this.selectedItem.id);
      this.deletePlaceEmitter.emit(this.selectedItem);
      this.isConfirm = false;
    } else {
      this.isConfirm = false;
      this.selectedItem = null;
    }
  }

  public getPlaceUrl(place: any): string {
    return '/places/' + place?.urlKeywords + '/' + place?.id;
  }

  public recoverPlace(place) {
    this.recoverPlaceEmitter.emit(place);
  }

  public emitFunc(propName: string, data?: any, reloadData: boolean = true, clearData: boolean = false) {
    this[`${propName}Emitter`].emit({data, reloadData, clearData});
  }

  public loadMorePlaces() {
    this.loadMorePlacesEmitter.emit();
  }
}
