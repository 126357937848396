<ng-container *ngIf="mapPage; then pageContainer; else pageWithStaticContent"></ng-container>

<ng-template #pageWithStaticContent>
  <app-static-content>
    <ng-container pageContent *ngTemplateOutlet="pageContainer"></ng-container>
  </app-static-content>
</ng-template>

<ng-template #pageContainer>
  <section class="category__header" [class.wrapped]="staticType === staticRouteType.Journey">
    <h1 class="info-name" *ngIf="staticService.errorMessage">{{ staticService.errorMessage }}</h1>
    <h1 class="info-name" *ngIf="!staticService.errorMessage">{{ getPageName() }}
      <div class="date-container" *ngIf="data?._extra?.collection?.dates">
        <p class="date">{{data._extra.collection.dates | minMaxDate}}</p>
      </div>
    </h1>
    <app-static-place-item-pin
      *ngIf="staticType === staticRouteType.Journey && data?._extra?.collection"
      [place]="getCollectionForPin()"
      [isTitlePosition]="true"
      (pinPlace)="pinPlace($event)"
    ></app-static-place-item-pin>
    <app-share-buttons *ngIf="staticType === staticRouteType.Journey"></app-share-buttons>
  </section>

  <section class="category__tags-container">
    <div class="place-container__created-user-link"
         *ngIf="staticType === staticRouteType.Journey && staticContentService.userData">
      <a *ngIf="!this.isUserLinkNamed" [routerLink]="[this.userLink]">
        <img [src]="getUserAvatarByObject(staticContentService.userData, '/assets/icons/back-user.svg')" alt="user" class="create-by__icon"
             [class.rounded]="!!staticContentService.userData?.picture">
        {{getUserNameByObject(staticContentService.userData)}}</a>
      <a *ngIf="this.isUserLinkNamed" [href]="this.userLink">
        <img [src]="getUserAvatarByObject(staticContentService.userData, '/assets/icons/back-user.svg')" alt="user" class="create-by__icon"
             [class.rounded]="!!staticContentService.userData?.picture">
        {{getUserNameByObject(staticContentService.userData)}}</a>
    </div>

    <div class="collection__description"
         *ngIf="data?._extra?.collection?.description?.length"
         [innerHTML]="data._extra.collection.description">
    </div>

    <div class="category__container">
      <div class="category__list-categories"
           *ngIf="isCountryFilterVisible()">
        <app-static-categories
          [categories]="staticContentService.countries"
          [groupRouteQuery]="groupRouteQuery"
          [place]="staticContentService.info"
          [mode]="categoryType.Countries"
          [staticType]="staticType"
          [className]="'countries'"
          [pageType]="pageType"
          [collectionId]="collectionId"
          (clickCategoryEmit)="clickCategoryEmit($event)"
        ></app-static-categories>
      </div>

      <div class="category__list-categories"
           *ngIf="((staticType === staticRouteType.Journey) && staticContentService.cities && staticContentService.cities.length > 1) ||
       (staticContentService.cities && staticContentService.cities.length > 1)">
        <app-static-categories
          [categories]="staticContentService.cities"
          [groupRouteQuery]="groupRouteQuery"
          [place]="staticContentService.info"
          [mode]="categoryType.City"
          [staticType]="staticType"
          [className]="'cities'"
          [collectionId]="collectionId"
          [pageType]="pageType"
          [currentPinCollection]="currentPinCollection"
          (clickCategoryEmit)="clickCategoryEmit($event)"
        ></app-static-categories>
      </div>

      <div class="category__list-categories"
           *ngIf="
          staticContentService.categories
          && staticContentService.categories.length > 0
          && staticType !== staticRouteType.Experiences
          && staticType !== staticRouteType.TagCity">
        <app-static-categories
          [categories]="staticContentService.categories"
          [groupRouteQuery]="groupRouteQuery"
          [place]="staticContentService.info"
          [mode]="categoryType.Categories"
          [staticType]="staticType"
          [isThirdLines]="staticType === staticRouteType.CategoryCountry || staticType === staticRouteType.CategoryCity"
          [className]="'categories'"
          [pageType]="pageType"
          [collectionId]="collectionId"
          (clickCategoryEmit)="clickCategoryEmit($event)"
        ></app-static-categories>
      </div>
    </div>
  </section>
</ng-template>
