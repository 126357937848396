import { ChangeDetectionStrategy, Component, EventEmitter, Output, } from '@angular/core';
import { BindToComponentService } from '../../services/bind-to-component.service';
import { doAsync } from '../../libraries/do-async';
import { DestinationType } from '../../enums/destination-type';

@Component({
  selector: 'app-fly-autocomplete-component',
  templateUrl: 'fly-autocomplete.component.html',
  styleUrls: ['fly-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FlyAutocompleteComponent {
  @Output() selectFromCity: EventEmitter<any> = new EventEmitter();

  public config;
  public itemOptions: any[] = [];
  public opened = false;

  constructor(
    private bindToComponentService: BindToComponentService,
  ) {
    // get params from fly-bind-element-to
    bindToComponentService.autocompleteInit((config) => this.refreshData(config));
  }

  public close($event, modal): void {
    if ($event.target == modal) {
      this.select(null);
    }
  }

  public refreshData(config): void {
    doAsync(() => {
      this.itemOptions = config.itemOptions;
      this.config = config;
      this.opened = config.opened;
    });
  }

  public select(selected: { innerHTML: string, place: any }): void {
    if (selected && selected.place) {
      this.selectFromCity.emit({...selected, type: DestinationType.City});
    }
    this.config.responseData(selected);
    this.opened = false;
  }
}
