import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { StaticRouteType } from '../../enums/route-type';
import { of } from 'rxjs/internal/observable/of';
import { delay, map, mergeMap } from 'rxjs/operators';
import { StaticInitService } from '../../services/static-init.service';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { StaticService } from '../../../../services/static.service';
import { SsrService } from '../../../../services';
import { CategoryModeType } from '../static-categories/static-categories.component';
import { StaticTransferStateKey } from "../../../../enums/static-transfer-state-key.enum";

@UntilDestroy()
@Component({
  selector: 'app-static-country-info',
  templateUrl: './static-country-info.component.html',
  styleUrls: ['./static-country-info.component.scss'],
})
export class StaticCountryInfoComponent implements OnInit, OnChanges {

  @Input() place; // : Place;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;
  @Input() staticType: StaticRouteType;

  @Output() addPlace = new EventEmitter<void>();
  @Output() changeVisibleTooltipPlaceEmitter = new EventEmitter<any>();

  public activeIndex: number;
  public dynamicCountOfColumns = typeof window !== 'undefined' && (window.innerWidth < 1024 || window.innerWidth > 1365) ? 3 : 2;
  public isGalleryOpen = false;
  public page: StaticRouteType = StaticRouteType.Country;
  public placeSize: number;
  public staticRouteType = StaticRouteType;
  public categoryType = CategoryModeType;

  constructor(
    private route: ActivatedRoute,
    private staticService: StaticService,
    private ssrService: SsrService,
    private staticInitService: StaticInitService,
    protected cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.staticService.setStaticType(this.route.snapshot.data['static']);
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.staticService.routeParams = params;
        this.staticService.isMainInfoLoaded = false;
        this.getCountryInfo(this.staticService.routeParams.id);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['place']?.currentValue) {
      this.checkPlaceData();
    }
    if (changes['windowSize'] && changes['windowSize'].currentValue) {
      this.checkWindowSize();
    }
  }

  // INIT StaticRouteType.COUNTRY:
  private getCountryInfo(id: string): void {
    combineLatest([
      this.route.params,
      this.route.data
    ])
      .pipe(
        map(([params, data]) => {
          let country;
          if (params.countryId) {
            country = {id: params.countryId};
          } else if (params.id) {
            country = {id: params.id};
          } else {
            country = params.name;
          }
          return {
            ...this.staticService.searchData,
            name: params.name,
            country: country,
          };
        }),
        mergeMap((countryData) => {
          this.staticService.isInit = true;
          const ssrKey = `${StaticTransferStateKey.CountryInfoKey}_${id}`;
          const ssrCountry = this.ssrService.getState(ssrKey);

          if (!ssrCountry) {
            return this.staticInitService.getCountryData(countryData);
          } else {
            this.ssrService.removeState(ssrKey);
            return of({
              ...this.staticService.searchData,
              data: ssrCountry
            });
          }
        }),
        delay(0),
        untilDestroyed(this),
      )
      .subscribe({
        next: (response) => {
          if (!response) {
            return;
          }

          const country = response.data;
          const placesCount = country.places.length;
          this.staticService.placeItemsList = country.places.concat(country.similarPlaces);
          this.staticService.showPlace = this.place = country;
          // We already have data for first (pageCount=0) page from `.places`
          this.staticService.pageCount = 1;
          // If count of places is less than the limit it means this is all places that country has
          // Otherwise we specify totalCount = limit + 1 to make request for another page
          // and the real totalCount will be updated from _meta of request to the second page of `/info/place`
          this.staticService.totalCount =
            placesCount < country.placesLimit
              ? placesCount
              : country.placesLimit + 1;
          // Init loading similar places
          if (placesCount < country.placesLimit) {
            this.staticService.currentThirdRequestCount = 1;
            // It will be updated after the second request, it's just need to init infinite loop for country similar places
            this.staticService.pageCount = 2;
          }
          this.staticService.setCurrentPlace(this.staticService.placeItemsList[0]);
          this.staticService.setSeoDataSubPages(country);
          this.staticService.initInfiniteStaticPlaces(this.staticService.placeItemsList);
          this.staticService.isMainInfoLoaded = true;
          this.checkPlaceData();
          this.checkWindowSize();
          this.cdRef.reattach();
        },
        error: (error) => {
          if (error.status === 404) {
            this.ssrService.redirect(301, '/404');
          }
        }
      });
  }

  public openImage(value: boolean, i?: number): void {
    this.isGalleryOpen = value;
    if (value) {
      this.activeIndex = i;
    }
  }

  private checkPlaceData() {
    this.placeSize = this.place && Object.keys(this.place).length;
  }

  private checkWindowSize() {
    (this.staticService.resizeWindow < 1024 || this.staticService.resizeWindow >= 1366) ?
      this.dynamicCountOfColumns = 3 :
      this.dynamicCountOfColumns = 2;
  }
}
