import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { clearObservable } from '../../../libraries/timer-observable';
import { AuthService } from '../../../services/auth.service';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { AuthModalService } from "../../../services/auth-modal.service";

@Component({
  selector: 'app-registration',
  templateUrl: 'registration.component.html',
  styleUrls: ['registration.component.scss']
})
export class RegistrationComponent implements OnDestroy, OnChanges {
  public auth: any[];
  public isClose = false;
  public loggedIn: any;
  public openUserAccount = false;
  public showComponentWithName: string = null;
  public top: string = null;
  public user: any;
  public waitObservableId;
  public window: any;

  private $destroyed = new Subject<void>();

  @Input() isOpen = false;
  @Input() month: any;
  @Input() searchMode: any;
  @Input() showLoginForm = true;
  @Input() position: any;

  @Output() registrationCompletedEmitter = new EventEmitter<boolean>();
  @Output() showUserAccountEmitter = new EventEmitter<boolean>();
  @Output() regWinClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() resPasswordSuccessEmitter = new EventEmitter<boolean>();

  @ViewChild('authContainer') authContainerRef;

  @HostListener('document:mouseup')
  clickMouseUp() {
    if (this.isClose) {
      this.close();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  clickMouseDown(event) {
    this.isClose = event.target && event.target.className === 'modal';
  }

  constructor(
    private cdref: ChangeDetectorRef,
    protected authModalService: AuthModalService,
    private authService: AuthService
  ) {
  }

  public resPasswordSuccess(value): void {
    this.resPasswordSuccessEmitter.emit(value);
  }

  ngOnDestroy() {
    clearObservable(this.waitObservableId);
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.auth && changes.isOpen.currentValue) {
      this.getExternalAuthData();
    }
  }

  showUserAccount(event = false) {
    this.openUserAccount = true;
    this.showLoginForm = false;
    this.showUserAccountEmitter.emit(event);
    this.cdref.detectChanges();
  }

  changeAuthForm() {
    this.showLoginForm = !this.showLoginForm;
    this.cdref.detectChanges();
  }

  getExternalAuthData() {
    this.authService.getAuthExternal().pipe(
      take(1)
    ).subscribe(
      (data: any) => {
        this.auth = data;
      }
    );
  }

  close() {
    this.regWinClose.emit(false);
    this.isOpen = false;
  }

  hideTermsPrivacyPolicy() {
    this.showComponentWithName = null;
  }

  showTermsPrivacy(componentName: string) {
    this.showComponentWithName = componentName;
  }

  public registrationCompleted(val: boolean): void {
    this.registrationCompletedEmitter.emit(val);
  }

  completeAuth() {
    this.authModalService.close();
    this.registrationCompletedEmitter.emit(true);
  }

}
