<div class="dropdown">
  <div class="initiator" (click)="toggle()">
    <span>{{ title }}</span>
    <div class="icon" [ngClass]="{'icon--rotate': isOpen}"></div>
  </div>

  <content *ngIf="isOpen && customContent">
    <ng-content select="['content']"></ng-content>
  </content>

  <div class="options" *ngIf="isOpen && !customContent && options.length">
    <div class="option"
         *ngFor="let option of options; trackBy: trackByFn; let i=index;"
         [ngClass]="{'selected':option.selected}"
         (click)="selectItem(i)">
      <mat-icon class="icon" *ngIf="option.selected">done</mat-icon>
      {{option.title}}
    </div>
  </div>
</div>
