import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-editor',
  templateUrl: 'fly-editor.component.html',
})
export class FlyEditorComponent {

  @Input() data: string = null;
  @Input() height: number = 400;
  @Input() inputId: string = 'wysiwyg-desc';

  @Output() changeEmitter = new EventEmitter<string>();
  @Output() initEmitter = new EventEmitter<void>();


  handleDescEditorInit({editor}) {
    this.initEmitter.emit(editor)
  }

  onChange() {
    this.changeEmitter.emit(this.data)
  }
}
