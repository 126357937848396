import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WindowRef } from '../../../../services/window.service';

@Component({
  selector: 'app-social-accounts',
  templateUrl: 'social-accounts.component.html',
  styleUrls: [
    '../../../../styles/social-net.scss',
    'social-accounts.component.scss']
})
export class SocialAccountsComponent implements OnInit {

  @Input() auth: any[];
  @Output() userUnlink: EventEmitter<any> = new EventEmitter<any>();

  public socialKeys: string[];
  public window: any;

  constructor(
    private windowRef: WindowRef,
  ) {
    this.window = windowRef.getNativeWindow();
  }

  ngOnInit() {
    this.socialKeys = Object.keys(this.auth);
  }


  doOnUnlink() {
    this.userUnlink.emit(true);
  }

}
