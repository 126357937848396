import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, } from '@angular/core';
import { DropDownValue } from '../../../modules/shared/modules/drop-down/containers/drop-down/drop-down.component';

import { TripTypeEnum } from "../../../enums/trip-type.enum";

@Component({
  selector: 'app-trip-way-selector',
  templateUrl: './trip-way-selector.component.html',
  styleUrls: ['./trip-way-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripWaySelectorComponent implements OnInit, OnChanges {

  @Input() value: TripTypeEnum | string = TripTypeEnum.OneWay;
  @Output() changeEmitter = new EventEmitter();

  public options: DropDownValue[] = [
    {title: 'Round trip', value: TripTypeEnum.RoundTrip},
    {title: 'One way', value: TripTypeEnum.OneWay}
  ];
  public title = 'Round trip';

  constructor() {
  }

  ngOnInit() {
    this.updateValue();
  }

  ngOnChanges(): void {
    this.updateValue();
    this.options = [...this.options];
  }

  updateValue(): void {
    const value = this.options.find((option) => option.value === this.value);
    this.options.forEach(option => option.selected = false);
    if (value) {
      this.title = value.title;
      value.selected = true;
    }
  }

  selectWay(way: DropDownValue) {
    this.title = way.title;
    this.changeEmitter.emit(way);
  }

}
