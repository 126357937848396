import { APP_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule, TransferState } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpXhrBackend,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
  withJsonpSupport
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
/* services */
import { HttpService } from './services/http.service';
import { RequestInterceptor } from './services/request-interceptor.service';
import {
  BindToComponentService,
  CountryService,
  FlyCookieService,
  GoogleAnalyticsService,
  GroupingPlacesService,
  InfoService,
  InnerDataStorageService,
  MapService,
  MetaTagsService,
  MonthService,
  SearchService,
  SsrService,
  UrlParseService,
  UserAccountService,
  UserService,
  WindowRef
} from './services';
import { AuthService } from './services/auth.service';
import { AuthModalService } from './services/auth-modal.service';
import { SignUpService } from './services/sign-up.service';
import { LanguageService } from './services/language.service';
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { FlyComponentService } from './services/fly-component-service/fly-component.service';
import { ContactUsService } from './services/contact-us.service';
/* services */
/* material */
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
/* material */
/* directives */
import { ChangeBorderStyleDirective } from './directives/change-border-style.directive';
import { SetImgSizeDirective } from './directives/set-img-size.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { FlyAdDirective } from './directives/fly-add.directive';
import { FlyBindComponentToDirective } from './directives/fly-bind-component-to.directive';
import { HiddenSettingsSearchPanelDirective } from './modules/static/directives/hidden-settings-search-panel.directive';
import { MoreTextDirective } from './directives/more-text.directive';
import { LazyloadDirective } from './directives/lazyload.directive';
/* directives */
/* pipes */
import { FormatNumbersPipe } from './pipes/format-numbers.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TrimTextPipe } from './pipes/trim-text.pipe';
/* pipes */
/* pipes */
/* shared components */
import { FlySearchPanelComponent } from './shared-components/fly-search-panel/fly-search-panel.component';
import { MobileActionPanelComponent } from './shared-components/mobile-action-panel/mobile-action-panel.component';
import {
  DestSearchFieldComponent
} from './shared-components/fly-search-panel/dest-search-field/dest-search-field.component';
import { FlyDatePickerComponent } from './shared-components/fly-search-panel/fly-date-picker/fly-date-picker.component';
import {
  FromSearchFieldComponent
} from './shared-components/fly-search-panel/from-search-field/from-search-field.component';
import { SignInButtonsComponent } from './shared-components/fly-search-panel/sign-in-buttons/sign-in-buttons.component';
/* Popup City */
import {
  PopupCityFlightsComponent
} from './shared-components/popup-city/popup-city-flights/popup-city-flights.component';
/* Popup City */
/* shared components */
import { PageTitleComponent } from './shared-components/page-title/page-title.component';
import {
  PageInterestingCityComponent
} from './shared-components/page-interesting-city/page-interesting-city.component';
import { SiteMapComponent } from './shared-components/site-map/site-map.component';
import { ConfirmActionComponent } from './shared-components/confirm-action/confirm-action.component';
import { CookiesLawPolicyComponent } from './shared-components/cookies-law-policy/cookies-law-policy.component';
import { EventTmplComponent } from './shared-components/event-tmpl/event-tmpl.component';
import { FlyTooltipComponent } from './shared-components/fly-tooltip/fly-tooltip.component';
import { RegistrationComponent } from './shared-components/fly-search-panel/registration/registration.component';
import {
  SocialNetBtnComponent
} from './shared-components/fly-search-panel/registration/social-net-btn/social-net-btn.component';
import { RegisterComponent } from './shared-components/fly-search-panel/registration/register/register.component';
import { LoginComponent } from './shared-components/fly-search-panel/registration/login/login.component';
import { ResetPasswordFormComponent } from './shared-components/reset-password-form/reset-password-form.component';
import { TermsOfServiceComponent } from './shared-components/terms-of-service/terms-of-service.component';
import {
  HeadPinnedPlaceComponent
} from './shared-components/head-pinned-places/head-pinned-place/head-pinned-place.component';
import { FlyModalComponent } from './shared-components/fly-modal/fly-modal.component';
import { FlyAutocompleteComponent } from './shared-components/fly-autocomplete/fly-autocomplete.component';
import { CloseAreaComponent } from './shared-components/close-area/close-area.component';
import { TooltipPinPlaceComponent } from './shared-components/tooltip-pin-place/tooltip-pin-place.component';
import { FlyCheckboxComponent } from './shared-components/checkbox/checkbox.component';
/* shared components */
import { CityPageTitleComponent } from './modules/city/city-page-title/city-page-title.component';
/* SeeDo Module */
import { MapTooltipPlaceComponent } from './shared-components/map-tooltip-place/map-tooltip-place.component';
/* SeeDo Module */
/* Signup Module */
import { SignupComponent } from './modules/signup/signup.component';
/* Signup Module */
/* AuthReset Module */
import { AuthResetComponent } from './modules/auth-reset/auth-reset.component';
/* AuthReset Module */
// Static page components
import { StaticHomeComponent } from './modules/static/components/static-home/static-home.component';
import {
  StaticTopPlacesComponent
} from './modules/static/components/static-home/static-top-places/static-top-places.component';
import { StaticCategoriesComponent } from './modules/static/components/static-categories/static-categories.component';
import { StaticPlaceInfoComponent } from './modules/static/components/static-place-info/static-place-info.component';
import { StaticPlaceItemComponent } from './modules/static/components/static-place-item/static-place-item.component';
import {
  StaticPlaceItemAddComponent
} from './modules/static/components/static-place-item-add/static-place-item-add.component';
import { StaticHeaderComponent } from './modules/static/components/static-header/static-header.component';
import { StaticComponent } from './modules/static/static.component';
import { StaticMapComponent } from './modules/static/components/static-map/static-map.component';
import { StaticCityInfoComponent } from './modules/static/components/static-city-info/static-city-info.component';
import {
  StaticCountryInfoComponent
} from './modules/static/components/static-country-info/static-country-info.component';
import { StaticPinComponent } from './modules/static/components/static-pin/static-pin.component';
import { StaticPlaceAddComponent } from './modules/static/components/static-place-add/static-place-add.component';
import {
  StaticCategoryInfoComponent
} from './modules/static/components/static-category-info/static-category-info.component';
import { StaticBurgerMenuComponent } from './modules/static/components/static-burger-menu/static-burger-menu.component';
// Static page components
/* AuthExternal Module */
import { AuthExternalComponent } from './modules/auth-external/auth-external.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './store/reducers';
import { UserCitiesEffects } from './store/user-cities';
import { MapEffects } from './store/map';
import { AuthEffects } from './store/auth';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormInputAutoCompleteComponent
} from './shared-components/form/input/auto-complete/form-input-auto-complete.component';
import { SrcFallbackDirective } from './directives/src-fallback.directive';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import {
  TripWaySelectorComponent
} from './shared-components/fly-search-panel/trip-way-selector/trip-way-selector.component';
import {
  TripPassengerSelectorComponent
} from './shared-components/fly-search-panel/trip-passenger-selector/trip-passenger-selector.component';
import { SharedModule } from './modules/shared/shared.module';
import { LayerEffects } from './store/layer';
import {
  DestSearchDropdownComponent
} from './shared-components/fly-search-panel/dest-search-dropdown/dest-search-dropdown.component';
import {
  DestSearchFieldSimpleComponent
} from './shared-components/fly-search-panel/dest-search-field-simple/dest-search-field-simple.component';
import { PrivacyPolicyComponent } from './modules/shared/footer/components/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './modules/shared/footer/components/cookie-policy/cookie-policy.component';
import { TermOfUseComponent } from './modules/shared/footer/components/term-of-use/term-of-use.component';
import { ListCitiesComponent } from './shared-components/list-cities/list-cities.component';
import { VersionService } from './services/version.service';
import { ReplaceLinkService } from './services/replace-link.service';
import { StaticInitService } from './modules/static/services/static-init.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CopyrightInfringementComponent } from './modules/copyright-infringement/copyright-infringement.component';
import {
  StaticSettingsPanelComponent
} from './modules/static/components/static-settings-panel/static-settings-panel.component';
import { WelcomeComponent } from './shared-components/fly-search-panel/registration/welcome/welcome.component';
import { CreatePlaceComponent } from './modules/place/create-place.component';
import { YourOwnPlaceComponent } from './modules/place/your-own-place/your-own-place.component';
import { AutocompleteComponent } from './modules/place/autocomplete/autocomplete.component';
import { SuccessCreatedComponent } from './modules/place/success-created/success-created.component';
import { UploadFormComponent } from './modules/upload/upload-form/upload-form.component';
import { UploadComponent } from './modules/upload/upload.component';
import { FormComponent } from './modules/upload/form/form.component';
import { ContentComponent } from './modules/upload/content/content.component';
import {
  StaticUserPersonalInfoComponent
} from './modules/static/components/static-user-personal-info/static-user-personal-info.component';
import { UserAccountComponent } from './modules/user-account/user-account.component';
import { UserProfileComponent } from './modules/user-account/components/user-profile/user-profile.component';
import {
  UnlinkSocialAccountComponent
} from './modules/user-account/components/unlink-social-account/unlink-social-account.component';
import { SocialAccountsComponent } from './modules/user-account/components/social-accounts/social-accounts.component';
import {
  ProfilePassChangeComponent
} from './modules/user-account/components/profile-pass-change/profile-pass-change.component';
import {
  LinkSocialAccountComponent
} from './modules/user-account/components/link-social-account/link-social-account.component';
import { UserSidebarComponent } from './modules/user-account/components/user-sidebar/user-sidebar.component';
import {
  ProfileUserAboutComponent
} from './modules/user-account/components/profile-user-about/profile-user-about.component';
import {
  ProfileUserPageComponent
} from './modules/user-account/components/profile-user-page/profile-user-page.component';
import {
  ProfileUserPlacesComponent
} from './modules/user-account/components/profile-user-places/profile-user-places.component';
import {
  ProfileTextFieldComponent
} from './modules/user-account/components/profile-text-field/profile-text-field.component';
import {
  ProfileUserSettingsComponent
} from './modules/user-account/components/profile-user-settings/profile-user-settings.component';
import { ConfirmDialogComponent } from './modules/user-account/components/confirm-dialog/confirm-dialog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RouterHistoryService } from './services/router-history.service';
import { PinnedPanelComponent } from './shared-components/pinned-panel/pinned-panel.component';
import {
  PinnedPanelItemComponent
} from './shared-components/pinned-panel/pinned-panel-item/pinned-panel-item.component';
import { translateBrowserLoaderFactory } from './libraries/loaders/translate-browser.loader';
import {
  PinnedPanelCollectionComponent
} from './shared-components/pinned-panel/pinned-panel-collection/pinned-panel-collection.component';
import {
  PinnedPanelCollectionInfoComponent
} from './shared-components/pinned-panel/pinned-panel-collection-info/pinned-panel-collection-info.component';
import { FlyPopupSelectComponent } from './shared-components/fly-popup/fly-popup-select/fly-popup-select.component';
import {
  UserCollectionsComponent
} from './modules/user-account/components/user-collections/user-collections.component';
import { UserCollectionsEffects } from "./store/user-collections";
import {
  PinnedPanelCollectionInfoNoAuthComponent
} from './shared-components/pinned-panel/pinned-panel-collection-info-no-auth/pinned-panel-collection-info-no-auth.component';
import { FlyBottomSheetComponent } from './shared-components/fly-bottom-sheet/fly-bottom-sheet.component';
import { FlyPopupTitleComponent } from './shared-components/fly-popup/fly-popup-title/fly-popup-title.component';
import { FlyAddButtonComponent } from './shared-components/fly-add-button/fly-add-button.component';
import { YourOwnReviewComponent } from "./modules/place/your-own-review/your-own-review.component";
import {
  ProfileUserReviewsComponent
} from './modules/user-account/components/profile-user-reviews/profile-user-reviews.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsService } from './services/google-maps.service';
import { SnazzyInfoWindowComponent } from './shared-components/snazzy-info-window/snazzy-info-window.component';
import { HashtagInputModule } from "./modules/place/hashtag-input/hashtag-input.module";
import { VideoPlayerModule } from "./modules/place/video-player/video-player.module";
import { FlyToastrModule } from "./shared-components/fly-toastr/fly-toastr.module";
import { ImageContentModule } from "./modules/place/image-content/image-content.module";
import { UploadProgressModule } from "./modules/upload/upload-form/upload-progress/upload-progress.module";
import { FlyPopupProfileModule } from "./shared-components/fly-popup/fly-popup-profile/fly-popup-profile.module";
import { UserLikesEffects } from "./store/user-likes/user-likes.effects";
import { MinMaxDatePipe } from "./pipes/min-max-date.pipe";
import { CollectionInputModule } from "./modules/place/collection-input/collection-input.module";
import { CreateCollectionModule } from "./shared-components/pinned-panel/create-collection/create-collection.module";
import { StaticContentComponent } from "./modules/static/components/static-content/static-content.component";
import { ContactInfoModule } from "./modules/contact-info/contact-info.module";
import { FlyPopupOptionModule } from "./shared-components/fly-popup/fly-popup-option/fly-popup-option.module";
import {
  PinnedPanelContainerComponent
} from './shared-components/pinned-panel/pinned-panel-container/pinned-panel-container.component';
import { FavoritesPanelComponent } from "./shared-components/pinned-panel/favorites-panel/favorites-panel.component";
import { FlyModalNoticeModule } from "./shared-components/fly-modal-notice/fly-modal-notice.module";
import { LandingBusinessComponent } from "./modules/landing-business/landing-business.component";
import {
  LandingTravelBusinessComponent
} from "./modules/landing-business/landing-travel-business/landing-travel-business.component";
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { ShareButtonsComponent } from './shared-components/share-buttons/share-buttons.component';
import {
  StaticUserPersonalInfoFollowComponent
} from './modules/static/components/static-user-personal-info/static-user-personal-info-follow/static-user-personal-info-follow.component';
import { UserFollowingEffects } from "./store/user-following/user-following.effects";
import { NotificationService } from "./services/notification.service";
import { UserNotificationsEffects } from "./store/user-notifications/user-notifications.effects";
import { NotificationsComponent } from './shared-components/fly-search-panel/notifications/notifications.component';
import { FollowingPanelComponent } from "./shared-components/pinned-panel/following-panel/following-panel.component";
import { PanelsEffects } from "./store/panels/panels.effects";
import {
  FollowingPanelUnfollowComponent
} from "./shared-components/pinned-panel/following-panel/following-panel-unfollow/following-panel-unfollow.component";
import { CdkDrag, CdkDragPlaceholder, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";
import {
  ProfileNotificationSettingsComponent
} from './modules/user-account/components/profile-notification-settings/profile-notification-settings.component';
import {
  StaticPlaceInfoEditComponent
} from "./modules/static/components/static-place-info/static-place-info-edit/static-place-info-edit.component";
import { FlyEditorModule } from "./shared-components/editor/fly-editor.module";
import { BasesrcDirective } from "./directives/basesrc.directive";
import { GuideTooltipComponent } from "./shared-components/guide-tooltip/guide-tooltip.component";
import { NgScrollbarModule, provideScrollbarOptions } from "ngx-scrollbar";
import { SwiperModule } from 'swiper/angular';
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { XhrHttpClient } from "./services/xhr-client.service";

const MODULES = [SharedModule];
const COMPONENTS = [
  TripWaySelectorComponent,
  TripPassengerSelectorComponent,
  StaticComponent,
  StaticHomeComponent,
  StaticTopPlacesComponent,
  StaticCategoriesComponent,
  StaticHeaderComponent,
  StaticPlaceInfoComponent,
  StaticPlaceItemComponent,
  StaticPlaceItemAddComponent,
  StaticCityInfoComponent,
  StaticCountryInfoComponent,
  StaticCategoryInfoComponent,
  StaticPinComponent,
  StaticPlaceAddComponent,
  StaticMapComponent,
  StaticBurgerMenuComponent,
  WelcomeComponent,
  StaticUserPersonalInfoComponent,
  SuccessCreatedComponent,
  PinnedPanelComponent,
  PinnedPanelItemComponent,
  FavoritesPanelComponent,
  FollowingPanelComponent,
  FollowingPanelUnfollowComponent
];
const USER_PROFILE_COMPONENTS = [
  UserAccountComponent,
  ConfirmDialogComponent,
  UserProfileComponent,
  UnlinkSocialAccountComponent,
  SocialAccountsComponent,
  ProfilePassChangeComponent,
  LinkSocialAccountComponent,
  UserSidebarComponent,
  ProfileUserAboutComponent,
  ProfileUserPageComponent,
  ProfileUserPlacesComponent,
  ProfileTextFieldComponent,
  ProfileUserSettingsComponent,
  ProfileNotificationSettingsComponent,
];
const DIRECTIVES = [SrcFallbackDirective];
const PAGES = [PageNotFoundComponent];
const PROVIDERS = [SsrService, GoogleAnalyticsService, GoogleMapsService];

const shareIcons = [
  faWhatsapp,
  faXTwitter,
  faRedditAlien,
  faFacebookF,
  faTelegramPlane,
  faLink,
  faCheck,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...USER_PROFILE_COMPONENTS,
    AppComponent,
    StaticSettingsPanelComponent,
    CopyrightInfringementComponent,
    /* pipes */
    FormatNumbersPipe,
    CapitalizePipe,
    HeadPinnedPlaceComponent,
    TrimTextPipe,
    MinMaxDatePipe,
    /* pipes */
    /* shared components */
    ConfirmActionComponent,
    CookiesLawPolicyComponent,
    TermsOfServiceComponent,
    SignInButtonsComponent,
    FromSearchFieldComponent,
    RegisterComponent,
    LoginComponent,
    ResetPasswordFormComponent,
    EventTmplComponent,
    FlyTooltipComponent,
    FlySearchPanelComponent,
    MobileActionPanelComponent,
    DestSearchDropdownComponent,
    DestSearchFieldComponent,
    DestSearchFieldSimpleComponent,
    FlyDatePickerComponent,
    FromSearchFieldComponent,
    SignInButtonsComponent,
    FlyModalComponent,
    PageInterestingCityComponent,
    FlyAutocompleteComponent,
    SiteMapComponent,
    RegistrationComponent,
    SocialNetBtnComponent,
    CloseAreaComponent,
    PageTitleComponent,
    TooltipPinPlaceComponent,
    FlyCheckboxComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermOfUseComponent,
    ListCitiesComponent,
    /* popup city */
    PopupCityFlightsComponent,
    /* popup city */
    /* shared components */
    /* Signup module */
    SignupComponent,
    /* Signup module */
    /* AuthReset Module */
    AuthResetComponent,
    /* AuthReset Module */
    /* AuthExternal Module */
    AuthExternalComponent,
    /* AuthExternal Module */
    CityPageTitleComponent,
    /* see-do module */
    MapTooltipPlaceComponent,
    /* see-do module */
    //  directives
    ChangeBorderStyleDirective,
    SetImgSizeDirective,
    TooltipDirective,
    FlyAdDirective,
    FlyBindComponentToDirective,
    HiddenSettingsSearchPanelDirective,
    MoreTextDirective,
    ...PAGES,
    ...DIRECTIVES,
    FormInputAutoCompleteComponent,
    AutocompleteComponent,
    CreatePlaceComponent,
    YourOwnPlaceComponent,
    ContentComponent,
    FormComponent,
    UploadComponent,
    UploadFormComponent,
    PinnedPanelCollectionComponent,
    PinnedPanelCollectionInfoComponent,
    FlyPopupSelectComponent,
    UserCollectionsComponent,
    PinnedPanelCollectionInfoNoAuthComponent,
    LazyloadDirective,
    FlyBottomSheetComponent,
    FlyPopupTitleComponent,
    FlyAddButtonComponent,
    YourOwnReviewComponent,
    ProfileUserReviewsComponent,
    SnazzyInfoWindowComponent,
    StaticContentComponent,
    PinnedPanelContainerComponent,
    LandingBusinessComponent,
    LandingTravelBusinessComponent,
    ShareButtonsComponent,
    StaticUserPersonalInfoFollowComponent,
    NotificationsComponent,
    StaticPlaceInfoEditComponent,
    BasesrcDirective,
    GuideTooltipComponent,
  ],
  imports: [
    BrowserModule,
    EditorModule,
    FormsModule,
    AppRoutingModule,
    InfiniteScrollDirective,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    GoogleMapsModule,
    /*material modules*/
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    /*material modules*/
    ShareButtons,
    /* ngrx */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        //it's always false here Oo
        logOnly: environment.production,  // Restrict extension to log-only mode
        connectInZone: true
      })
      : [],
    EffectsModule.forRoot([
      LayerEffects,
      UserCitiesEffects,
      MapEffects,
      UserCollectionsEffects,
      UserLikesEffects,
      AuthEffects,
      UserFollowingEffects,
      UserNotificationsEffects,
      PanelsEffects,
    ]),
    HashtagInputModule,
    VideoPlayerModule,
    FlyToastrModule,
    ImageContentModule,
    UploadProgressModule,
    CollectionInputModule,
    CreateCollectionModule,
    FlyPopupProfileModule,
    ContactInfoModule,
    FlyPopupOptionModule,
    FlyModalNoticeModule,
    ...MODULES,
    NgOptimizedImage,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    CdkDropListGroup,
    FlyEditorModule,
    NgScrollbarModule,
    SwiperModule,
  ],
  providers: [
    provideAnimationsAsync(),
    // provideClientHydration(),
    SsrCookieService,
    HttpService,
    InfoService,
    CountryService,
    MonthService,
    MapService,
    SearchService,
    SignUpService,
    UserAccountService,
    WindowRef,
    UserService,
    UrlParseService,
    StaticInitService,
    InnerDataStorageService,
    GroupingPlacesService,
    MetaTagsService,
    LanguageService,
    FlyCookieService,
    FlyComponentService,
    AuthService,
    AuthModalService,
    BindToComponentService,
    ContactUsService,
    ReplaceLinkService,
    NotificationService,
    {
      provide: APP_ID,
      useValue: 'fly-explorow-app'
    },
    {
      provide: 'test_versions',
      useFactory: getVersionsService,
      deps: [ActivatedRoute]
    },
    {
      provide: VersionService,
      deps: [UrlParseService],
      useValue: undefined
    },
    ...PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      deps: [HttpClient, UserService, Router, Store, SsrService, GoogleAnalyticsService],
      multi: true
    },
    RouterHistoryService,
    provideScrollbarOptions({
      visibility: 'hover',
    }),
    provideHttpClient(withFetch(), withInterceptorsFromDi(), withJsonpSupport()),
    {
      provide: XhrHttpClient,
      useFactory: (xhrBackend: HttpXhrBackend) => {
        return new XhrHttpClient(xhrBackend);
      },
      deps: [HttpXhrBackend]
    },
  ],
  exports: [
    BasesrcDirective
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...shareIcons);
  }
}

export function getVersionsService(route: ActivatedRoute) {
  return new VersionService(route).GetVersions();
}
