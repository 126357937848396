import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  ContactInfoEnum,
  DAYS_OF_WEEK,
  ScheduleForm,
  ScheduleTime,
  TimeTypeEnum
} from "../../../interfaces/contact-info";

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss']
})


export class ScheduleFormComponent implements OnChanges, OnInit {
  @Input() scheduleApiData: any[];

  @Output() saveEmit = new EventEmitter<ScheduleForm[]>();
  @Output() toggleContactFormEmitter = new EventEmitter<{ type: ContactInfoEnum }>();

  public scheduleData: ScheduleForm[];
  public timeTypeEnum = TimeTypeEnum;
  public hours = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];

  private readonly initScheduleTime: ScheduleTime = {
    from: '',
    to: ''
  };
  private firstInit: boolean;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.firstInit) {
        this.onSave();
      } else {
        this.firstInit = true;
      }
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scheduleApiData']?.currentValue) {
      this.initScheduleData();
      this.scheduleData.forEach((item, index) => {
        item.isWorkDay = !!changes['scheduleApiData'].currentValue[index]?.length;
        item.isMoreTime = changes['scheduleApiData'].currentValue[index]?.length === 2;
        item.scheduleTimes = this.convertEncodeSchedule(changes['scheduleApiData'].currentValue[index]);
      });
    }
  }

  ngOnInit(): void {
    if (!this.scheduleApiData) {
      this.initScheduleData();
    }
  }

  public toggleScheduleMode(index) {
    this.scheduleData[index].isMoreTime = !this.scheduleData[index].isMoreTime;
    if (this.scheduleData[index].scheduleTimes?.length === 2) {
      this.scheduleData[index].scheduleTimes = this.convertEncodeSchedule(
        this.convertDecodeSchedule([this.scheduleData[index].scheduleTimes[0]])
      )
    } else {
      this.scheduleData[index].scheduleTimes = this.convertEncodeSchedule(
        [this.convertDecodeSchedule(this.scheduleData[index].scheduleTimes)[0], []]
      )
    }
  }

  public onToggleCheckbox(event, index) {
    if (!event) {
      this.scheduleData[index].scheduleTimes = this.convertEncodeSchedule([])
    }
    this.scheduleData[index].isMoreTime = this.scheduleData[index].scheduleTimes?.length === 2;
  }

  public toggleContactForm() {
    this.toggleContactFormEmitter.emit({type: ContactInfoEnum.businessHours});
  }

  public onSave() {
    this.scheduleApiData = [];
    this.scheduleData.forEach(x => {
      this.scheduleApiData.push(this.convertDecodeSchedule(x.scheduleTimes));
    });
    if (!this.scheduleApiData.some(x => x.length)) {
      this.scheduleApiData = null;
    }
    this.saveEmit.emit(this.scheduleApiData);
  }

  public checkDisabledTimeInput(hour: any, scheduleTimes: any, timeIndex: number, timeType: TimeTypeEnum): boolean {
    let firstData = null;
    let secondData = null;
    let thirdData = null;
    let fourData = null;
    let result = 0;

    if (timeIndex === 0) {
      if (scheduleTimes?.length > 1) {
        thirdData = scheduleTimes[timeIndex + 1][TimeTypeEnum.from];
        fourData = scheduleTimes[timeIndex + 1][TimeTypeEnum.to];
      }

      if (timeType === TimeTypeEnum.from) {
        secondData = scheduleTimes[timeIndex][TimeTypeEnum.to];
        if (secondData) {
          result += +(hour >= secondData);
        }
        if (!result && thirdData) {
          result += +(hour >= thirdData);
        }
        if (!result && fourData) {
          result += +(hour >= fourData);
        }
      }

      if (timeType === TimeTypeEnum.to) {
        firstData = scheduleTimes[timeIndex][TimeTypeEnum.from];
        if (firstData) {
          result += +(hour <= firstData);
        }
        if (!result && thirdData) {
          result += +(hour >= thirdData);
        }
        if (!result && fourData) {
          result += +(hour >= fourData);
        }
      }
    }

    if (timeIndex === 1) {
      firstData = scheduleTimes[timeIndex - 1][TimeTypeEnum.from];
      secondData = scheduleTimes[timeIndex - 1][TimeTypeEnum.to];
      thirdData = scheduleTimes[timeIndex][TimeTypeEnum.from];
      fourData = scheduleTimes[timeIndex][TimeTypeEnum.to];

      if (timeType === TimeTypeEnum.from) {
        if (firstData) {
          result += +(hour <= firstData);
        }
        if (!result && secondData) {
          result += +(hour <= secondData);
        }
        if (!result && fourData) {
          result += +(hour >= fourData);
        }
      }

      if (timeType === TimeTypeEnum.to) {
        if (firstData) {
          result += +(hour <= firstData);
        }
        if (!result && secondData) {
          result += +(hour <= secondData);
        }
        if (!result && thirdData) {
          result += +(hour <= thirdData);
        }
      }
    }
    return !!result;
  }

  private initScheduleData() {
    this.scheduleData = [
      {
        day: DAYS_OF_WEEK.monday.id,
        title: DAYS_OF_WEEK.monday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.tuesday.id,
        title: DAYS_OF_WEEK.tuesday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.wednesday.id,
        title: DAYS_OF_WEEK.wednesday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.thursday.id,
        title: DAYS_OF_WEEK.thursday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.friday.id,
        title: DAYS_OF_WEEK.friday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.saturday.id,
        title: DAYS_OF_WEEK.saturday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
      {
        day: DAYS_OF_WEEK.sunday.id,
        title: DAYS_OF_WEEK.sunday.title,
        isWorkDay: false,
        isMoreTime: false,
        scheduleTimes: this.convertEncodeSchedule([])
      },
    ];
  }

  private convertEncodeSchedule(data: any[]) {
    let scheduleTimes: ScheduleTime[] = [];
    if (data?.length) {
      data.forEach(item => {
        let scheduleTime: ScheduleTime = JSON.parse(JSON.stringify(this.initScheduleTime));
        if (item) {
          item.forEach((time, index) => {
            scheduleTime[index === 0 ? 'from' : 'to'] = time
          });
        }
        scheduleTimes.push(scheduleTime)
      })
    } else {
      scheduleTimes.push(this.initScheduleTime)
    }
    return JSON.parse(JSON.stringify(scheduleTimes));
  }

  private convertDecodeSchedule(data: any[]) {
    let scheduleTimes = [];
    if (data?.length) {
      data.forEach(item => {
        let scheduleTime = [];
        if (!!item.from) {
          scheduleTime.push(item.from);
        }
        if (!!item.to) {
          scheduleTime.push(item.to);
        }
        if (scheduleTime?.length) {
          scheduleTimes.push(scheduleTime);
        }
      })
    }
    return scheduleTimes;
  }
}
