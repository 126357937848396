import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestroyComponent } from "../../../heplers/destroy.component";
import { takeUntil } from "rxjs/operators";
import { UserCollectionsActions } from "../../../store/user-collections";
import { select, Store } from "@ngrx/store";
import { getCollectionList, State } from "../../../store/reducers";

import { DEFAULT_TITLE_JOURNEY } from "../../../../constants";

@Component({
  selector: 'app-collection-input',
  templateUrl: './collection-input.component.html',
  styleUrls: ['./collection-input.component.scss']
})
export class CollectionInputComponent extends DestroyComponent implements OnInit {

  @Input() required: boolean;
  @Input() isProfilePage: boolean;
  @Input() text: string;
  @Input() collectionId: number;

  @Output() setFormControlEvent = new EventEmitter<{ key: string, value: number }[]>();

  public addedCollectionName: string;
  public collections: any[] = [];
  public isCreateCollection: boolean;
  public defaultTitleCollection = DEFAULT_TITLE_JOURNEY;

  constructor(
    protected store$: Store<State>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store$.dispatch(new UserCollectionsActions.LoadCollections);

    this.store$
      .pipe(
        select(getCollectionList),
        takeUntil(this.destroy$)
      ).subscribe(collections => {
      if (collections?.length) {
        this.collections = collections;
        if (this.addedCollectionName) {
          const addedCollection = this.collections.find(collection => collection.name === this.addedCollectionName);
          if (addedCollection) {
            this.selectCollection(addedCollection.id)
          }
        }
      }
    });
  }

  public selectCollection(item: number): void {
    this.collectionId = item;
    this.sendEvent();
  }

  public toggleCreateCollection() {
    this.isCreateCollection = !this.isCreateCollection;
  }

  public createCollection(event) {
    this.addedCollectionName = event;
    this.toggleCreateCollection();
  }

  private sendEvent() {
    this.setFormControlEvent.emit([
      {
        key: 'collectionId',
        value: this.collectionId
      }
    ]);
  }

}
