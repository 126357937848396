import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import type { CountryInfo, ReviewInterface, ReviewInterfacePageInterface, VideoInterface, } from '../interfaces';
import { HttpService } from './http.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { TagResultInterface } from "../interfaces/tag";
import { HttpParams } from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class InfoService {
  public cities: any[];
  private $currentClient: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private responseCache = new Map();
  cachedTags: {
    [key: string]: TagResultInterface
  } = {};

  constructor(
    private httpService: HttpService
  ) {
  }

  private static getUniqueId(endpoint: string, params?: any): string {
    return (endpoint + JSON.stringify(params)).toLocaleLowerCase();
  }

  private getWithCache(url: string, params?: any) {
    const uniqueCacheId: string = InfoService.getUniqueId(url, params);
    const fromCache = this.responseCache.get(uniqueCacheId);
    if (fromCache) {
      return of(fromCache);
    }
    return this.httpService.get(url, params).pipe(
      tap((data: any) => this.responseCache.set(uniqueCacheId, data)),
    );
  }

  public getClientInfoWithoutUpdating(params?: any) {
    if (this.$currentClient.value) {
      return this.$currentClient;
    } else {
      this.getClientInfoWithResponse(params);
    }
    return this.$currentClient;
  }

  public getClientInfo(params: any) {
    return this.httpService.get(`info`, params, {withCache: false});
  }

  private getClientInfoWithResponse(params?: any) {
    return this.httpService.get(`info`, params, {withCache: false}).pipe(
      take(1),
      tap((res: any) => {
        this.$currentClient.next(res);
      }),
    ).subscribe();
  }

  // staticRouteType.CITY
  public getCityById(cityId, params?: any): Observable<any> {
    return this.getWithCache(`info/city/${cityId}`, params);
  }

  public getPlaceList(params?: any): Observable<any> {
    return this.httpService.get(`info/place`, params, {withCache: false});
  }

  public getPlace(placeId: string, params?: any): Observable<any> {
    return this.httpService.get(`info/place/${placeId}`, params);
  }

  public getCountryList(params?: any): Observable<any> {
    return this.getWithCache(`info/country`, params);
  }

  // staticRouteType.COUNTRY
  public getCountry(id, params?: any): Observable<CountryInfo> {
    return this.getWithCache(`info/country/${id}`, params);
  }

  public getJourneyList(params?: any): Observable<any> {
    return this.httpService.get(`info/pin-collection`, params);
  }

  public getTag(filter: {
                  name?: string;
                },
                useCash = false
  ): Observable<TagResultInterface> {
    Object.entries(filter)
      .forEach(([key, value]) => {
        if (value === null) {
          delete filter[key];
        }
      });
    const filterStr = JSON.stringify(filter);

    if (useCash && this.cachedTags.hasOwnProperty(filterStr)) {
      return of(this.cachedTags[filterStr]);
    }

    const httpParams = new HttpParams()
      .append('filter[name]', filter?.name);
    return this.httpService.get(`info/tag`, httpParams)
      .pipe(map((result: TagResultInterface) => {
        if (useCash) {
          this.cachedTags[filterStr] = result;
        }
        return result;
      }));
  }

  // REVIEW PLACE
  public getReviewById(reviewId: number, params?: any): Observable<ReviewInterface> {
    return this.httpService.get(`info/place-review/${reviewId}`, params);
  }

  public getReviewList(params?: any): Observable<ReviewInterfacePageInterface> {
    return this.httpService.get(`info/place-review`, params);
  }

  public deleteUserReview(id: number): Observable<void> {
    return this.httpService.delete(`info/place-review/${id}`);
  }

  public getUserById(id: string, params?: any) {
    return this.httpService.get(`info/user/${id}`, params);
  }

  public getUserByName(name: string, params?: any) {
    return this.httpService.get(`info/user-by-name/${name}`, params);
  }

  public getUserPlaceFilters(id: string, params?: any): any {
    return this.httpService.get(`info/user-place-filters/${id}`, params);
  }

  public getPlaceVideo(placeId: string): Observable<VideoInterface[][]> {
    return this.httpService.get(`info/place-video/${placeId}`)
      .pipe(
        catchError(this.handleError<VideoInterface[][]>('getPlaceVideo', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
