<div class="content"
     [class.max-width]="setMaxWidth">
  <div class="header close" *ngIf="searchData$ | async as searchData">

    <div class="search">
      <div class="search-wrapper">
        <div #inputWraper class="wrapper" [class.hidden-filters]="isFiltersHidden">

          <div class="search-form">
            <app-fly-autocomplete-component
              *ngIf="isOpenAutocompleteDepartureCountry && !isFiltersHidden"
              (selectFromCity)="setCityFrom($event)"
            ></app-fly-autocomplete-component>

            <app-dest-search-dropdown
              *ngIf="isOpenDropdownSearchField"
              [wrapper]="inputWraper"
              [offset]="135"
              [open]="isOpenDropdownSearchField"
              (closeEmitter)="closeDropdownSearchField()">

              <div top-place class="fly-m-b-5px"></div>

              <content>
                <app-dest-search-field-simple
                  class="search-input-field fly-m-b-5px"
                  #destField
                  [label]="'labels.everywhere'"
                  [cityFrom]="selectedCity"
                  [destPlaceConfig]="destCityInit"
                  [showOnMapFirst]="true"
                  [destTo]="cityTo$ | async"
                  (destChanges)="setDestTo($event)"
                  (back)="closeDropdownSearchField()">
                </app-dest-search-field-simple>
              </content>
            </app-dest-search-dropdown>

            <div class="selectors">
              <app-dest-search-field
                class="search-input-field bold-border"
                [label]="'labels.everywhere'"
                [destTo]="cityTo$ | async"
                [destPlaceConfig]="destCityInit"
                [showOnMapFirst]="true"
                (focusEmitter)="openDropdownSearchField()"
                (destChanges)="setDestTo($event)">
              </app-dest-search-field>

              <div class="burger-menu" [ngStyle]="{pointerEvents: 'none'}">
                <button class="search-places wrap"
                        [ngClass]="{ 'test2Class': isOpenMap.value }"
                        i18n="buttons.explore_it">
                  <div class="search-icon">
                    <img [appBasesrc]="'/assets/search/search_panel_icon.svg'" alt=""/>
                  </div>
                  <div class="search-underline" *ngIf="isOpenMap.value"></div>
                </button>

              </div>
            </div>
          </div>

          <ng-container *ngIf="setMaxWidth">
            <div class="trends" *ngIf="isFiltersHidden">
              <p class="trends-text">Trends:</p>
              <app-static-categories [categories]="popularTags"
                                     [className]="'categories'"
                                     [staticType]="staticTypes"
                                     [mode]="categoryType.Trends"
                                     [isShowMoreDots]="false"
                                     (clickCategoryEmit)="clickCategoryEmit($event)"
                                     [pageType]="staticRouteType.Search"
              ></app-static-categories>
            </div>
            <ng-container *ngTemplateOutlet="!isFiltersHidden && filter; context: {setMaxWidth: setMaxWidth}">
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="progress-bar" *ngIf="isVisibleProgressBar">
      <mat-progress-bar
        *ngIf="ajaxState$ | async"
        mode="indeterminate"
      ></mat-progress-bar>
    </div>
  </div>

  <div>
    <app-fly-modal-component></app-fly-modal-component>
  </div>

  <app-fly-tooltip></app-fly-tooltip>
</div>

<div *ngIf="isMobileOrTablet"
     class="mobile-action-panel">
  <app-mobile-action-panel
    [isOpenMap]="isOpenMap"
    [place]="place"
    [scrollTop]="scrollTop"
    (clearDest)="clearDest.emit()"
    [month]="month"
    [data]="data"
    [searchMode]="searchMode"
    [staticTypes]="staticService.staticType"
    [pinnedChangeState]="pinnedChangeState"
    [action]="mobilePanelAction"
  ></app-mobile-action-panel>
</div>

<ng-template #filter let-setMaxWidth="setMaxWidth">
  <div class="filters" [class.on-top]="!setMaxWidth">
    <div class="filters__left-side">
      <!-- From field -->
      <app-from-search-field class="search-input-field bold-border"
                             #cityFromField
                             (closeDropDown)="CloseDropDown($event)"
                             [value]="cityFrom$ | async"
                             (selectEmitter)="setCityFrom($event)"
      ></app-from-search-field>

      <!-- When field -->
      <app-fly-date-picker class="search-input-field bold-border"
                           *ngIf="(date$ | async)[0] as dateStart"
                           [availableMode]="availableMode"
                           [title]="'When'"
                           [value]="dateStart"
                           (requiredMonthChangeEmitter)="setWhen(0, $event)"
      ></app-fly-date-picker>

      <!-- Return -->
      <app-fly-date-picker class="search-input-field bold-border"
                           *ngIf="(date$ | async)[1] as dateEnd"
                           [availableMode]="availableMode"
                           [title]="'Return'"
                           [minDateLimit]="formData.date[0]"
                           [value]="dateEnd"
                           (requiredMonthChangeEmitter)="setWhen(1, $event)"
      ></app-fly-date-picker>

      <!-- Select trip type -->
      <app-trip-way-selector
        [value]="tripType$ | async"
        (changeEmitter)="setTripWay($event)"
      ></app-trip-way-selector>

      <!-- Passenger selector -->
      <app-trip-passenger-selector
        [value]="formData.passengers"
        (changeEmitter)="setPassenger($event)"
      ></app-trip-passenger-selector>
      <ng-content select="[filters]"></ng-content>
    </div>
    <div class="search-panel-subfilters"
         (click)="toggleSearchPanelFilters()">
              <span class="delete-file">
                <img alt="close"
                     title="close"
                     [appBasesrc]="'/assets/cross.svg'"
                     class="close-popup-img">
              </span>
    </div>

    <div class="filters__right-side"></div>
  </div>
</ng-template>
