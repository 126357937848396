import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getSearchData, State } from '../../../../store/reducers';
import { SeeDoMapClass } from '../../../../abstracts/right-side-map-class';
import { constructMediaURL } from '../../../../libraries';
import { InfoService, MapService, SsrService, UserService, usertoken, WindowRef } from '../../../../services';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, mergeMap, take } from 'rxjs/operators';
import { AjaxFinish } from '../../../../store/layer';
import { environment } from '../../../../../environments/environment';
import { StaticInitService } from '../../services/static-init.service';
import type { ReviewInterfacePageInterface, VideoInterface } from '../../../../interfaces';
import { StaticRouteType } from '../../enums/route-type';
import { MoreTextDirective } from '../../../../directives/more-text.directive';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, Subject } from 'rxjs';
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { ImagesContentType } from "../../../../enums/images-content-type.enum";
import { StaticService } from "../../../../services/static.service";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { HelperService } from "../../../../services/helper.service";
import { UserCollectionService } from "../../../../services/user-collection.service";
import { getLinkByObject } from "../../../../libraries/get-link-by-object";
import { getUserNameByObject } from "../../../../libraries/get-user-name";
import { getUserAvatarByObject } from "../../../../libraries/get-user-avatar";
import { StaticTransferStateKey } from "../../../../enums/static-transfer-state-key.enum";

@UntilDestroy()
@Component({
  selector: 'app-static-place-info',
  templateUrl: './static-place-info.component.html',
  styleUrls: ['./static-place-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StaticPlaceInfoComponent extends SeeDoMapClass implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() place: any;
  @Input() type: StaticRouteType;
  @Input() resizeWindow = typeof window !== 'undefined' && window.innerWidth ? window.innerWidth : 0;

  @Output() changeVisibleTooltipPlaceEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() doBookingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() pin: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() galleryOpenEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('mainContainer') mainContainer: ElementRef;
  @ViewChild('reviewsContainerElem') reviewsContainerElem: ElementRef;
  @ViewChildren('descriptionContainer', {read: ViewContainerRef}) descriptionContainer: QueryList<ViewContainerRef>;

  public activeIndex: number;
  public constructMediaURL = constructMediaURL;
  public isGalleryOpen = false;
  public month: any;
  public subscriptions: Subscription = new Subscription();
  public userIsLogged: boolean;
  public page: StaticRouteType = StaticRouteType.Place;
  public mediaUrl = environment.mediaUrl;
  public isLimitTextArray = [];
  public maxLimitTextArray = 6;
  public staticRouteType = StaticRouteType;
  public galleryImages: any[] = [];
  public pageReview = 2;
  public countPageReview: number;
  public pageSizeReview = 10;
  public isLoadButton: boolean;
  public reviewsOtherCount: number;
  public isShowAddPlace: boolean;
  public canAddReview: boolean;
  public originalPlacePictures: any[] = [];
  public imagesContentType = ImagesContentType;
  public staticType: StaticRouteType = StaticRouteType.Place;
  public deactivatePage: boolean = true;

  protected readonly getLinkByObject = getLinkByObject;
  protected readonly getUserNameByObject = getUserNameByObject;
  protected readonly getUserAvatarByObject = getUserAvatarByObject;
  protected userLink: string;
  protected isUserLinkNamed: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    public staticInitService: StaticInitService,
    protected store$: Store<State>,
    protected infoService: InfoService,
    protected cdRef: ChangeDetectorRef,
    protected pageScrollService: PageScrollService,
    @Inject(DOCUMENT) protected document: any,
    protected windowRef: WindowRef,
    protected mapService: MapService,
    private userService: UserService,
    private ssrService: SsrService,
    private activatedRoute: ActivatedRoute,
    private cookieService: SsrCookieService,
    public staticService: StaticService,
    protected helperService: HelperService,
    protected userCollectionService: UserCollectionService,
  ) {
    super(
      infoService,
      mapService,
      cdRef,
      store$,
      pageScrollService,
      document,
      windowRef,
      staticService,
      helperService
    );
    this.userIsLogged = this.userService.loggedIn();
  }

  public ngOnInit(): void {
    this.setStaticType(this.activatedRoute.snapshot.data['static']);

    this.cdRef.detectChanges();

    this.activatedRoute.params.pipe(
      untilDestroyed(this)
    ).subscribe((params) => {
      this.staticService.resetListParams();
      this.staticService.routeParams = params;

      switch (this.staticType) {
        case StaticRouteType.Place:
          this.getPlaceInfo(this.staticService.routeParams.id);
          break;
        case StaticRouteType.Review:
          this.getPlaceReviewById(this.staticService.routeParams.reviewId);
          break;
      }
      this.checkPageType();
    });

    this.userCollectionService.isErrorCollection$
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x && this.staticService.userInfo) {
          this.staticService.getErrorMessage();
        }
      });
  }

  public ngOnChanges(changes: any) {
    if (changes['place'] && changes['place'].currentValue) {
      this.checkPlaceData(changes['place'].currentValue);
      this.userLink = getLinkByObject(changes['place'].currentValue?.user);
      this.isUserLinkNamed = this.userLink.indexOf('http') !== -1;
    }

    if (changes['type'] && changes['type'].currentValue) {
      this.setStaticType(changes['type'].currentValue);

      this.cdRef.detectChanges();
      this.checkPageType();
    }
    this.checkLoadButton();
  }

  public ngAfterViewInit(): void {
    if (this.descriptionContainer) {
      this.descriptionContainer.changes.subscribe(value => {
        const descElemArr = value._results.map(description => description.element.nativeElement);
        this.checkLengthReview(false, descElemArr);
      });
      this.checkLengthReview(true);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public openPlace() {
    this.changeVisibleTooltipPlaceEmitter.emit(this.place);
  }

  public pinPlace(event): void {
    this.pin.emit(event);
  }

  public openImage(value: boolean, i?: number, pictures = this.place.pictures): void {
    this.isGalleryOpen = value;
    this.galleryOpenEmitter.emit(value);
    this.galleryImages = pictures;
    if (value) {
      this.activeIndex = i;
    }
  }

  public toggleAddReview() {
    if ((this.userIsLogged || this.cookieService.get(usertoken)) && this.canAddReview) {
      this.isShowAddPlace = !this.isShowAddPlace;

      if (this.isShowAddPlace) {
        this.setTopToScroll(this.mainContainer);
      }
    }
  }

  /**
   * Contains data after creation or update, in order to not load it again with GET request
   * @private
   */
  private tryToGetDataFromState(stateParam: string) {
    if (this.ssrService.isBrowser() && history.state?.[stateParam]) {
      return history.state?.[stateParam];
    }

    return null;
  }

  // INIT StaticRouteType.PLACE:
  private getPlaceInfo(id: string): void {
    const stateKey = `${StaticTransferStateKey.PlaceInfoKey}_${id}`;
    this.staticService.pageCount = 1;
    this.staticService.totalCount = 100;

    this.subscriptions.add(
      combineLatest([
        this.store$.pipe(select(getSearchData))
      ])
        .pipe(
          take(1),
          mergeMap(([homeData]) => {
            const ssrCacheData = this.ssrService.getState(stateKey);
            if (!ssrCacheData || this.staticService.editPlace) {
              this.staticService.editPlace = false;
              const placeData = {...homeData, placeID: id};

              const placeFromState = this.tryToGetDataFromState('place');
              if (placeFromState) {
                return of({
                  ...placeData,
                  data: placeFromState
                })
              }

              return this.staticInitService.getPlaceData(
                placeData,
                this.staticService.isMobileDesign() ? this.staticService.placesLimitMobile : null
              );
            } else {
              if (typeof ssrCacheData.messageCode !== 'undefined') {
                this.ssrService.removeState(stateKey);
                this.deactivatePage = true;
                this.staticService.setDeactivatedPageMode(ssrCacheData.displayName, ssrCacheData.messageCode, id);
                return of(null);
              } else {
                this.deactivatePage = false;
                return of({
                  ...this.staticService.searchData,
                  data: ssrCacheData
                });
              }
            }
          })
        ).subscribe({
        next: async (response) => {
          if (!response) {
            return;
          }
          this.deactivatePage = false;

          let video = JSON.parse(JSON.stringify(response.data.video));
          let descriptionBlocks = JSON.parse(JSON.stringify(response.data.descriptionBlocks));
          if (this.ssrService.isBrowser()) {
            // Get the recent data about video
            // SSR-cache can contain outdated data if the video is already ready to not show processing image
            const hasNotReadyVideo = response.data.video?.some(item => item.isReady === false);
            if (hasNotReadyVideo) {
              let video_ = await this.getVideoData(id);
              if (video_.length > 0) {
                video = [];
                video_.forEach((blockData: VideoInterface[], blockNumber: number) => {
                  descriptionBlocks[blockNumber].video = blockData;
                  blockData.forEach((item) => {
                    video.push(item);
                  });
                });
              }
            }
          }

          const place = {...response.data, video: video, descriptionBlocks: descriptionBlocks};

          this.staticService.setSeoDataSubPages(place);
          this.ssrService.setState(stateKey, place);
          this.staticService.mapData(place);
          this.checkPlaceData(this.staticService.showPlace);
          this.checkSimilarPlaces(place);
          let allGalleries = [];
          if (this.place) {
            this.place.descriptionBlocks.forEach((item, index) => {
              item['galleries'] = [];
              item['galleries'] = this.staticService.getGalleryByPageType(item, index);
              allGalleries = allGalleries.concat(item['galleries']['galleryMedia']);
              this.originalPlacePictures = this.originalPlacePictures.concat(item['galleries']);
            });
            this.place.descriptionBlocks.forEach(item => {
              item['galleries']['allGalleryMedia'] = allGalleries;
            });
          }
          this.userLink = getLinkByObject(this.place?.user);
          this.isUserLinkNamed = this.userLink.indexOf('http') !== -1;

          this.store$.dispatch(new AjaxFinish({uid: null}));
        },
        error: (error) => {
          if (error.status === 404) {
            this.ssrService.redirect(301, '/404');
          }
          if (error.status === 410) {
            const errorData = {
              displayName: error.headers.get('x-user-name'),
              messageCode: error.error?.code
            }
            this.ssrService.setState(stateKey, errorData);
            this.staticService.setDeactivatedPageMode(errorData.displayName, errorData.messageCode, id);
          }
        }
      })
    );
  }

  private getVideoData(placeId: string): Promise<VideoInterface[][]>  {
    return lastValueFrom(this.infoService.getPlaceVideo(placeId).pipe(take(1)));
  }

  // INIT StaticRouteType.Review:
  private getPlaceReviewById(reviewId: string): void {
    const dataFromState = this.tryToGetDataFromState('review');
    let data;
    if (dataFromState) {
      data = of(dataFromState)
    } else {
      data = this.staticInitService.getPlaceReviewById(Number(reviewId));
    }

    this.subscriptions.add(
      data.subscribe(
        (response) => {
          if (!response || !response.place) {
            return;
          }
          this.deactivatePage = false;
          const responseCustom = JSON.parse(JSON.stringify(response));
          const placeInfo = responseCustom.place;
          delete responseCustom.place;
          placeInfo['reviews'] = [responseCustom];
          this.staticService.setSeoDataSubPages(placeInfo);
          this.staticService.mapData(placeInfo);
          this.checkPlaceData(this.staticService.showPlace);
          this.checkSimilarPlaces(placeInfo);
          this.store$.dispatch(new AjaxFinish({uid: null}));
        },
        (error) => {
          if (error.status === 404) {
            this.ssrService.redirect(301, '/404');
          }
          if (error.status === 410) {
            this.ssrService.redirect(301, error['headers'].get('x-alternative-url'));
          }
        })
    );
  }

  private checkPageType() {
    if (this.staticService.staticType === StaticRouteType.Review) {
      this.pageReview = 1;
      this.pageSizeReview = 5;
    } else {
      this.pageReview = 2;
      this.pageSizeReview = 10;
    }
    this.checkLoadButton();
  }

  private checkSimilarPlaces(place) {
    const placesCount = place.similarPlaces?.length;
    // We already have data for first (pageCount=0) page from `.similarPlaces`
    this.staticService.pageCount = 1;
    // If count of places is less than the limit it means this is all places that are related to this place
    // Otherwise we specify totalCount = limit + 1 to make request for another page
    // and the real totalCount will be updated from _meta of request to the second page of `/info/place`
    this.staticService.totalCount =
      placesCount < place.similarPlacesLimit
        ? placesCount
        : place.similarPlacesLimit + 1;
    // Force loading of bottom extra places because on scroll will not be triggered in case of small amount of places
    if (placesCount < 6) {
      this.staticService.getSimilarPlacesRequest();
    }
  }

  private checkPlaceData(place) {
    this.place = place;
    this.canAddReview = this.place.canAddReview && !this.place.isReviewsDisabled;

    this.place?.reviews?.forEach(review => {
      review.gallery = [];
      if (review?.video?.length) {
        review.video.forEach(video => {
          Object.assign(video.poster, {
            isPortrait: video.isPortrait,
            isReady: video.isReady,
          });

          Object.assign(video, {
            isVideo: true,
          });
          review.gallery = review.gallery.concat(video);
        });
      }
      if (review?.pictures?.length) {
        review.gallery = review.gallery.concat(review.pictures);
      }
    });

    if (this.staticService.staticType && this.place) {
      this.checkPicturesPlace(this.staticService.staticType);
      if (this.staticService.staticType === StaticRouteType.Review) {
        let reviewTags = [];
        this.place.reviews.forEach(review => {
          reviewTags = reviewTags.concat(review.tags)
        });
        this.place.tags = this.place.tags.concat(reviewTags);
      }
    }
  }

  private checkLengthReview(firstTime = false, descElemArr = null) {
    if (this.reviewsContainerElem && firstTime) {
      descElemArr = this.reviewsContainerElem.nativeElement.querySelectorAll('.description-container');
    }
    if (descElemArr) {
      descElemArr.forEach((descElem, index) => {
        if (this.ssrService.isBrowser()) {
          let text = document.createElement('p');
          while (descElem.firstChild) {
            if (descElem.firstChild.innerHTML) {
              text.innerHTML += descElem.firstChild.innerHTML;
            } else {
              let paragraphElement = document.createElement('p');
              paragraphElement.innerText = descElem.firstChild.textContent;

              text.innerHTML += paragraphElement.innerHTML;
            }
            descElem.removeChild(descElem.firstChild);
          }

          descElem.appendChild(text);
          const {offsetHeight} = descElem;
          const count = MoreTextDirective.lineCount(descElem, offsetHeight);
          this.isLimitTextArray[index] = count > this.maxLimitTextArray;
        }
      });
    }
    this.cdRef.detectChanges();
  }

  public loadMoreReview() {
    if (this.isLoadButton) {
      const placeId = this.place && this.place.id;
      let reviewId = null;
      if (this.staticService.staticType === StaticRouteType.Review) {
        reviewId = this.activatedRoute.snapshot.params.reviewId;
      }
      if (this.countPageReview && this.countPageReview >= this.pageReview || !this.countPageReview) {
        this.subscriptions.add(
          this.getPlaceReview(placeId, this.pageReview, this.pageSizeReview, this.staticService.staticType).subscribe(reviews => {
            this.isLimitTextArray = [];
            if (reviews._meta) {
              this.countPageReview = reviews._meta?.pageCount;
              this.pageReview = reviews._meta?.currentPage;
            }
            this.pageReview++;

            if (this.staticService.staticType === StaticRouteType.Review) {
              reviews.items = reviews.items.filter(item => item.id !== Number(reviewId));
            }
            this.place.reviews = this.place.reviews.concat(reviews.items);
            this.checkLoadButton();
            this.checkPicturesPlace(this.staticService.staticType);
          })
        );
      }
    }
  }

  private getPlaceReview(placeId: number, page: number, pageSize: number = 10, type: StaticRouteType): Observable<ReviewInterfacePageInterface> {
    if (!placeId) {
      return of(null);
    }

    const ssrKey = `${StaticTransferStateKey.PlaceInfoKey}_${placeId}_page_${page}_type_${type}`;

    const storedData = this.ssrService.getState(ssrKey);

    if (storedData) {
      this.ssrService.removeState(ssrKey);
      return of(storedData);
    } else {
      return this.fetchPlaceReview(placeId, page, pageSize)
        .pipe(
          map(placeDataRes => {
            this.ssrService.setState(ssrKey, placeDataRes);
            return placeDataRes;
          })
        );
    }
  }

  private fetchPlaceReview(placeId: number, page: number, pageSize: number = 10) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('expand', 'user.picture,user.homeCity.country,pictures');
    httpParams = httpParams.set('filter[placeId]', placeId);
    httpParams = httpParams.set('pageSize', pageSize);
    httpParams = httpParams.set('page', page);
    return this.infoService.getReviewList(httpParams);
  }

  private checkLoadButton() {
    if (this.place && this.place.reviews) {
      this.reviewsOtherCount = this.place.reviewsTotalCount - this.place.reviews.length;
      const countPageReview = this.countPageReview && this.pageReview <= this.countPageReview || !this.countPageReview;
      this.isLoadButton = this.reviewsOtherCount > 0 && countPageReview;
    }
  }

  private setTopToScroll(value: ElementRef) {
    if (value && this.ssrService.isBrowser()) {
      const headerHeight = 50;
      setTimeout(() => {
        window.scroll({
          top: Number(value.nativeElement.scrollHeight + headerHeight),
          left: 0,
          behavior: 'smooth'
        });
      }, 300);
    }
  }

  private checkPicturesPlace(type: string = StaticRouteType.Place) {
    let reviewPictures = [];
    if (this.place) {
      let reviewVideos = [];
      this.place?.reviews?.forEach(review => {
        review.video?.forEach(video => {
          video.poster.isVideo = true;
          reviewVideos = reviewVideos.concat(video);
        });
        reviewPictures = reviewPictures.concat(review.pictures);
      });

      if (type === StaticRouteType.Place) {
        reviewPictures = [...this.originalPlacePictures, ...reviewPictures];
      } else if (type === StaticRouteType.Review) {
        reviewPictures = [...reviewPictures, ...this.originalPlacePictures];
      }

      this.place = Object.assign({}, this.place);
    }
  }

  private setStaticType(staticType) {
    this.staticType = staticType;
    this.staticService.setStaticType(staticType);
  }
}
